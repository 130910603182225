import React from 'react';
import { Segment, Card } from 'semantic-ui-react';

import './habilities.css';
import './myProfile.scss';
import './profile.scss';

import scoreManager from '../../managers/ScoreManager';
import CompSubject from './compSubject';

import atomo from '../../images/Chemistry/Common/atomo.png';
import tabelaPeriodica from '../../images/Chemistry/Common/tabelaPeriodica.png';
import ligacoesQuimicas from '../../images/Chemistry/Common/ligacoesQuimicas.png';

function Chemistry() {
  // var sm = scoreManager.objSpecificScore.physics;

  return (
    <>
      <div className="profileSubItem">
        <br></br>
        <h1 className="presentationItem">Química</h1>
        <Segment
          style={{
            overflow: 'auto',
            maxHeight: '60vh',
            color: 'white',
            backgroundColor: '#303841',
          }}
        >
          <Card.Group itemsPerRow={4}>
            <CompSubject name="O Átomo" image={atomo} />
            <CompSubject name="Tabela Periódico" image={tabelaPeriodica} />
            <CompSubject name="Ligações Químicas" image={ligacoesQuimicas} />
          </Card.Group>
        </Segment>
      </div>
    </>
  );
}

export default Chemistry;

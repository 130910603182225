import React, { useState } from 'react';
import ScalePlot from './posGraph';
import StarMessage from '../../../../components/starMessage';

function PosGraph(props) {
  const [isMinimized, setIsMinimized] = useState(false);
  
  return (
    <div
      className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`}
      key={Math.random()}
    >
      {/* <div className="minMaxBar">
        <Icon className="icon" name='caret down' onClick={minimizeBar}/>
        <Icon className="icon" name='caret up' onClick={maximizeBar}/>
      </div> */}
      {!isMinimized && (
        <>
          <h1 className="presentationItem">Gráfico da Posição</h1>
          <p className="presentationItem">
            Para um objeto <b className="presentationItem">sem aceleração</b>, porém com{' '}
            <b className="presentationItem">velocidade constante diferente de zero</b>, percebemos
            que sua{' '}
            <b className="presentationItem">
              posição vai variando ao longo do tempo de uma maneira linear
            </b>
            , de acordo com a própria velocidade.
            <br></br>É o caso do nosso carro de cor vermelha.
            <br></br>
            <br></br>
            Já quando observamos a presença de uma{' '}
            <b className="presentationItem">aceleração constante</b>, a{' '}
            <b className="presentationItem">velocidade irá aumentar de maneira linear</b>, o que
            implica no aumento do deslocamento.
            <br></br>
            Esse <b className="presentationItem">deslocamento segue um comportamento quadrático</b>;
          </p>
          <div className="aclGraphMruvContainer">
            <ScalePlot plotSizeX={600} plotSizeY={300} />
          </div>

          <StarMessage
            show={true}
            message={'Comparação dos carros'}
            subjectScoring="physics"
            typeScoring="kinematics"
            arrayScoring={'arrStarMRUV'}
            indexScoring={0}
            xpAward={30}
          />
        </>
      )}
    </div>
  );
}

export default PosGraph;

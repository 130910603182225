import React from 'react';
import { List, Image } from 'semantic-ui-react';
import './kinematicsComp.scss';
import iconBlack from '../../../../images/Physics/Kinematics/iconBlack.PNG';
import iconBlue from '../../../../images/Physics/Kinematics/iconBlue.PNG';
import iconGreen from '../../../../images/Physics/Kinematics/iconGreen.PNG';
import iconPink from '../../../../images/Physics/Kinematics/iconPink.PNG';
import iconRed from '../../../../images/Physics/Kinematics/iconRed.PNG';
import iconYellow from '../../../../images/Physics/Kinematics/iconYellow.PNG';

import gameManager from '../../../../managers/GameManager';

function ClassificationTable(props) {
  const SIZEICONS = '6.5%';

  function sortCars() {
    let dist = gameManager.objSpecificGame.physics.kinematics.classification.map((e) => Number(e));
    let listCars = [
      { name: 'Arara Real', dist: dist[0], logo: iconBlue },
      { name: 'Jacaré Impiedoso', dist: dist[1], logo: iconGreen },
      { name: 'Boto Veloz', dist: dist[2], logo: iconPink },
      { name: 'Falcão Negro', dist: dist[3], logo: iconBlack },
      { name: 'Guará Rubro', dist: dist[4], logo: iconRed },
      { name: 'Capivara Furiosa', dist: dist[5], logo: iconYellow },
    ];

    listCars.sort((a, b) => {
      return a.dist > b.dist ? -1 : 1;
    });

    return listCars.map((car, index) => {
      return (
        <List.Item>
          <List.Content floated="right">
            {props.numCars >= index + 1 ? index + 1 : 'Eliminado'}
          </List.Content>
          <Image src={car.logo} width={SIZEICONS} rounded />
          <List.Content>{car.name}</List.Content>
        </List.Item>
      );
    });
  }

  return (
    <>
      {props.condition && (
        <p className="kinematics__classificationTable">
          <br></br>
          Tabela de Classificação
          <List celled animated>
            {sortCars()}
          </List>
        </p>
      )}
    </>
  );
}

export default ClassificationTable;

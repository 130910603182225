/* eslint-disable react/display-name */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import { Image } from 'semantic-ui-react';
import PlayDialogue from '../../../Common/compPlayDialogue';
import cinematics_manager_black from '../../../../images/Physics/Kinematics/cinematics_manager_black.PNG';
import cinematics_manager_blue from '../../../../images/Physics/Kinematics/cinematics_manager_blue.PNG';
import cinematics_manager_green from '../../../../images/Physics/Kinematics/cinematics_manager_green.PNG';
import cinematics_manager_pink from '../../../../images/Physics/Kinematics/cinematics_manager_pink.PNG';
import cinematics_manager_red from '../../../../images/Physics/Kinematics/cinematics_manager_red.PNG';
import cinematics_manager_yellow from '../../../../images/Physics/Kinematics/cinematics_manager_yellow.PNG';
import fig1 from '../../../../images/Physics/Kinematics/exChallengeTorricelliA.PNG';
import fig2 from '../../../../images/Physics/Kinematics/exChallengeTorricelliB.PNG';

function ChallengeExplanation(props) {
  const [animationStep, setAnimationStep] = useState(0);
  const additionalInfo = JSON.parse(sessionStorage.getItem('additional_info'));

  function getImage() {
    switch (additionalInfo.choosed_team) {
      case 'Arara Real':
        return cinematics_manager_blue;
      case 'Jacaré Impiedoso':
        return cinematics_manager_green;
      case 'Boto Veloz':
        return cinematics_manager_pink;
      case 'Falcão Negro':
        return cinematics_manager_black;
      case 'Guará Rubro':
        return cinematics_manager_red;
      case 'Capivara Furiosa':
        return cinematics_manager_yellow;
      default:
        break;
    }
  }

  useEffect(() => {
    if (animationStep === 11) {
      props.setNextItem('Tempo');
    }
  }, [animationStep]);

  const arrPage = [
    <PlayDialogue
      key={0}
      buttons="Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text={
        'Essa é nossa última corrida e precisamos de vencer a equipe ' +
        additionalInfo.teams[1] +
        ' para nos tornarmos campeões. Você está preparado para o desafio?'
      }
      image={getImage()}
      extraContent={''}
    />,

    <PlayDialogue
      key={1}
      buttons="Anterior/Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text="Nessa corrida, temos uma pista reta de 2000 metros de distância. Devido às condições dos pneus, sabemos que conseguimos alcançar nossa velocidade máxima de 324 km/h em um espaço de 500 metros. Você é capaz de nos dizer qual é a aceleração que devemos impor ao carro sem desgastar nossos pneus?"
      image={getImage()}
      extraContent={''}
    />,

    <PlayDialogue
      key={2}
      buttons="Anterior/Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text=""
      image={getImage()}
      extraContent={
        <>
          <p>Para melhor exemplificar a situação observe o mapa abaixo:</p>
          <Image src={fig1} centered fluid />
        </>
      }
    />,

    <PlayDialogue
      key={3}
      buttons="Anterior/Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text="Após alcançarmos a velocidade máxima, devemos percorrer a maior distância possível nessa velocidade, com o objetivo de vencermos a corrida."
      image={getImage()}
      extraContent={''}
    />,

    <PlayDialogue
      key={4}
      buttons="Anterior/Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text="Porém, devido ao desenho da pista, precisamos ultrapassar a linha de chegada já parando o carro, com 0 km/h. Assim, temos que descobrir em qual local da pista devemos começar a frear o carro, sabendo que nossos freios suportam uma desaceleração máxima de 15 m/s²."
      image={getImage()}
      extraContent={''}
    />,

    <PlayDialogue
      key={3}
      buttons="Anterior/Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text=""
      image={getImage()}
      extraContent={
        <>
          <p>Para visualizar melhor a situação observe o mapa:</p>
          <Image src={fig2} centered fluid />
        </>
      }
    />,

    <PlayDialogue
      key={4}
      buttons="Anterior/Começar Desafio"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      setChallengeStarted={props.setChallengeStarted}
      setIsChallengeOpened={props.setIsChallengeOpened}
      text="Está preparado para a corrida? Vamos em busca dessa vitória!"
      image={getImage()}
      extraContent={''}
      challenge = {props.challenge}
    />,
  ];

  return arrPage[animationStep];
}

export default ChallengeExplanation;

import * as BABYLON from '@babylonjs/core';
import '@babylonjs/loaders';
import '@babylonjs/core/Meshes/thinInstanceMesh';

function Scenario(scene) {

  var light = new BABYLON.HemisphericLight('hemiLight', new BABYLON.Vector3(-1, 1, 0), scene);


  // Skybox
  var skybox = BABYLON.MeshBuilder.CreateBox('skyBox', { size: 7500.0 }, scene);
  var skyboxMaterial = new BABYLON.StandardMaterial('skyBox', scene);
  skyboxMaterial.backFaceCulling = false;
  skyboxMaterial.reflectionTexture = new BABYLON.CubeTexture(
    './scenes/Física/Gravitação/skybox/nebula',
    scene
  );
  skyboxMaterial.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
  skyboxMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0);
  skyboxMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
  skybox.material = skyboxMaterial;
  skybox.position = new BABYLON.Vector3(0, 0, 0);

  BABYLON.SceneLoader.ImportMesh(
    '',
    './scenes/Física/Gravitação/planetLaunchBase.glb',
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let base = meshes[0];
      animationGroups.forEach((x) => {
        x.start(true);
      });
      base.active = true;
      base.position = new BABYLON.Vector3(0, 0, 0);
      // base.rotation = new BABYLON.Vector3(-Math.PI / 2, Math.PI / 2, 0);
      base.scaling = new BABYLON.Vector3(2.5, 2.5, 2.5);
      // base.scaling = new BABYLON.Vector3(0.1, 0.1, 0.1);

      // base.initPosition = listRocket.position;

      scene.base = base;
    }
  );
  // scene.createDefaultEnvironment({ skyboxSize: 5000 });

}

export default Scenario;

import React, { useState } from 'react';
import { Icon, Button, Image } from 'semantic-ui-react';
import { MathComponent } from 'mathjax-react';
import acelaracaoVelocidadeInicial from '../../../../images/Physics/Kinematics/acelaracaoVelocidadeInicial.PNG';

function VelocityOnTime(props) {
  const [isMinimized, setIsMinimized] = useState(false);
  const [nPageState, setPageState] = useState(0);


  function Arrows(props) {
    const ArrButtons = [
      <Button
        icon
        labelPosition="left"
        onClick={() => {
          props.setPageState((prevState) => prevState - 1);
        }}
        key={1}
      >
        <Icon name="left arrow" />
        Anterior
      </Button>,
      <Button
        icon
        labelPosition="right"
        onClick={() => {
          props.setPageState((prevState) => prevState + 1);
        }}
        key={2}
      >
        Próximo
        <Icon name="right arrow" />
      </Button>,
    ];

    return (
      <div className="presentationItem arrowBtnContainer">
        {ArrButtons.filter((item, nIndex) => props.arrsToShow[nIndex])}
      </div>
    );
  }

  const arrPages = [
    <div
      className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`}
      key={Math.random()}
    >
      <h1 className="presentationItem">Velocidade e Aceleração</h1>
      <p className="presentationItem">
        É importante saber que a aceleração trata de{' '}
        <b className="presentationItem">
          qualquer variação de velocidade, não necessariamente partindo de um objeto parado{' '}
        </b>
        .<br></br>
        <br></br>
        Assim, podemos encontrar uma situação em que um carro parte do repouso com uma aceleração de
        3 m/s² e alcança uma velocidade final de 20 m/s; Da mesma forma, podemos considerar uma
        <b className="presentationItem"> situação diferente</b>, em que a análise do movimento
        começa com um carro com velocidade inicial de 20 m/s e tem a mesma aceleração de 3 m/s²,
        porém, chegando à uma velocidade maior ao fim da aceleração.
      </p>

      <Image src={acelaracaoVelocidadeInicial} width="85%" centered />

      <Arrows setPageState={setPageState} arrsToShow={[false, true]} />
    </div>,

    <div
      className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`}
      key={Math.random()}
    >
      {!isMinimized && (
        <>
          <h1 className="presentationItem">Velocidade e Aceleração</h1>
          <p className="presentationItem">
            Para a situação anterior, em que um carro viaja a 20 m/s e inicia uma aceleração de 3
            m/s²; qual será sua velocidade após 5 segundos?
            <br></br>
            Através da própria fórmula da aceleração, conseguimos chegar na resposta:
            <MathComponent tex={String.raw`a =  \frac{\Delta V}{\Delta t}`} />
            <MathComponent
              tex={String.raw`a =  \frac{V_{Final} - V_{Inicial}}{t_{Final} - t_{Inicial}}`}
            />
          </p>
          <p className="presentationItem">
            Como no instante inicial temos o tempo igual a 0:
            <MathComponent tex={String.raw`a =  \frac{V_{Final} - V_{Inicial}}{t_{Final}}`} />
            <MathComponent tex={String.raw`a \cdot t_{Final} =  V_{Final} - V_{Inicial}`} />
            <MathComponent tex={String.raw`V_{Final} =  V_{Inicial} + a \cdot t_{Final} `} />
          </p>

          <p className="presentationItem">
            Com essa fórmula, fica fácil fazermos:
            <MathComponent tex={String.raw`V_{Final} =  20 m/s + 3 m/s^2 \cdot 5 s `} />
            {/* <MathComponent tex={String.raw`V_{Final} =  20 m/s + 15 m/s`}/> */}
            <MathComponent tex={String.raw`V_{Final} =  35 m/s`} />
          </p>
          <Arrows setPageState={setPageState} arrsToShow={[true, false]} />
        </>
      )}
    </div>,
  ];

  return arrPages[nPageState];
}

export default VelocityOnTime;

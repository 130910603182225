import React, { useState, useEffect, Component } from 'react';
import { Button, Popup, Icon } from 'semantic-ui-react';
import { MathComponent } from 'mathjax-react';
import Anime, { anime } from 'react-anime';
import earth from "../../../../images/earthT.png";
import stone from "../../../../images/stone.png";

class OrbitalVelocity extends Component{

  constructor(props){
    super(props);
  }

  animate(){
    this.animation = [
      anime({
        targets: '#stone-earth',
        translateX: [
          {value: anime.path('#line-test')('x'), duration: 1000, endDelay: 2000},
          {value: anime.path('#line-test-2')('x'), duration: 1500, endDelay: 2000},
          {value: anime.path('#line-test-3')('x'), duration: 3000, endDelay: 2000},
          {value: anime.path('#line-test-4')('x'), duration: 4000},
          {value: anime.path('#line-test-5')('x'), duration: 5000},
          {value: anime.path('#line-test-5')('x'), duration: 5000},
          {value: anime.path('#line-test-5')('x'), duration: 5000, endDelay: 2000}
        ],
        translateY: [
          {value: anime.path('#line-test')('y'), duration: 1000, endDelay: 2000},
          {value: anime.path('#line-test-2')('y'), duration: 1500, endDelay: 2000},
          {value: anime.path('#line-test-3')('y'), duration: 3000, endDelay: 2000},
          {value: anime.path('#line-test-4')('y'), duration: 4000},
          {value: anime.path('#line-test-5')('y'), duration: 5000},
          {value: anime.path('#line-test-5')('y'), duration: 5000},
          {value: anime.path('#line-test-5')('y'), duration: 5000, endDelay: 2000}
        ],
        // rotate: {value: '2turn', duration: 4500},
        easing: 'linear',
        loop: true
      }),
      anime({
        targets: '#line-test',
        strokeDashoffset: [anime.setDashoffset, 0],
        duration: 1000,
        endDelay: 31500,
        // stroke: {value: ['none', 'blue'], duration: 0},
        loop: true,
        easing: 'linear'
      }),
      anime({
        targets: '#line-test-2',
        strokeDashoffset: [anime.setDashoffset, 0],
        delay: 3000,
        duration: 1500,
        endDelay: 28000,
        // stroke: {value: ['none', 'blue'], duration: 0},
        loop: true,
        easing: 'linear'
      }),
      anime({
        targets: '#line-test-3',
        strokeDashoffset: [anime.setDashoffset, 0],
        delay: 6500,
        duration: 3000,
        endDelay: 23000,
        // stroke: {value: ['none', 'blue'], duration: 0},
        loop: true,
        easing: 'linear'
      }),
      anime({
        targets: '#line-test-4',
        strokeDashoffset: [anime.setDashoffset, 0],
        delay: 11500,
        duration: 4000,
        endDelay: 17000,
        // stroke: {value: ['none', 'blue'], duration: 0},
        loop: true,
        easing: 'linear'
      }),
      anime({
        targets: '#line-test-5',
        strokeDashoffset: [anime.setDashoffset, 0],
        delay: 15500,
        duration: 5000,
        endDelay: 12000,
        // stroke: {value: ['none', 'blue'], duration: 0},
        loop: true,
        easing: 'linear'
      })
    ]
  }

  componentDidMount(){
    this.animate();
  }

  componentWillUnmount(){
    this.animation.forEach(item => item.pause());
  }

  render() {

    return (
      <div style={{width: '100%', height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <div style={{width: '300px', height: '200px', position: 'relative'}}>
          <img width='75px' src={earth} style={{position: 'absolute', left: '30px', bottom: '50px'}}/>
          <img id='stone-earth' width='10px' src={stone} style={{position: 'absolute', left: '0', top: '0'}}/>
          {/* <img width='75px' src={earth} style={{position: 'absolute', right: '30px', bottom: '50px'}}/> */}
          <svg width="300" height="200">
            <path id='line-test' stroke='blue' fill ='none' d='M 90 80 Q 140 50 100 100 ' />
            <path id='line-test-2' stroke='blue' fill ='none' d='M 90 80 Q 160 130 95 120 ' />
            <path id='line-test-3' stroke='blue' fill ='none' d='M 90 80 Q 160 190 60 150 ' />
            <path id='line-test-4' stroke='blue' fill ='none' d='M 90 80 Q 150 145 85 160 Q 30 170 25 120 Q 15 65 70 60 ' />
            <path id='line-test-5' stroke='blue' fill ='none' d='M 70 60 A 50 50 0 1 1 70 160 A 50 50 0 1 1 70 60 ' />
          </svg>
        </div>
      </div>
    )
  }
}

function OrbitalVelocityPage(props) {
  const [step, setStep] = useState(0);
  const [isMinimized, setIsMinimized] = useState(false);

  const standardObj = [
    {
      name: 'Velocidade Orbital',
      step: 1,
      comp: (
        <>
          <h1 className="presentationItem">Velocidade Orbital</h1>

          <p className="presentationItem">
            Na situação anterior, suponha que ao invés de jogar a pedra para o alto, <b className="presentationItem">você deseje jogá-la para frente</b>. Quanto mais forte a pedra fosse lançada, mais longe ela cairia. Como nosso planeta é circular, <b className="presentationItem">caso a pedra fosse lançada com uma velocidade muito grande, ela não cairia, e sim iria ficar orbitando em nosso planeta</b>. Isso é o que ocorre quando lançamos nossos satélites artificiais.
          </p>
          <OrbitalVelocity/>
          <div className="presentationItem arrowBtnContainer">
            <Button
              icon
              labelPosition="right"
              onClick={() => {
                setStep(step + 1);
              }}
            >
              Próximo
              <Icon name="right arrow" />
            </Button>
          </div>
        </>
      ),
    },
    {
      name: 'Velocidade de Escape',
      step: 2,
      comp: (
        <>
          <h1 className="presentationItem">Velocidade Orbital</h1>
          <p className="presentationItem">
            Para esse lançamento, percebemos <b className="presentationItem">duas forças atuando sobre a pedra</b>: a <b className="presentationItem">força gravitacional</b>, que puxa a pedra para o centro da Terra e a <b className="presentationItem">força centrípeta</b>, já que a pedra descreve um movimento circular em volta do planeta.
          </p>
          <p className="presentationItem">
            Nesse caso, podemos fazer:
            <MathComponent tex={String.raw`F_{c} = F_{g}`} />
            <MathComponent tex={String.raw`\frac{m * v^{2}}{R} = \frac{G * m * M}{R^{2}}`} />
            <MathComponent tex={String.raw`v^{2} = \frac{G * M}{R}`} />
            Assim, temos como velocidade orbital:
            <MathComponent tex={String.raw`v = \sqrt{\frac{G * M}{R}}`} />
          </p>

          <span className="white presentationItem">Unidades</span>
          <Popup
            wide='very'
            inverted
            content={
              <div>

                <p className="presentationItem">
                  <br></br>A constante de Gravidatação Universal representada pela letra{' '}
                  <b className="presentationItem">
                    <i>G</i>
                  </b>
                </p>

                <p className="presentationItem">
                  <br></br>A massa do planeta é representada pela letra{' '}
                  <b className="presentationItem">
                    <i>M</i>
                  </b>{' '}
                  e a unidade padrão no Sistema Internacional é{' '}
                  <b className="presentationItem">
                    'kilogramas' (representada por <i>kg</i> ).
                  </b>
                </p>

                <p className="presentationItem">
                  <br></br>A massa do objeto é representada pela letra{' '}
                  <b className="presentationItem">
                    <i>m</i>
                  </b>{' '}
                  e a unidade padrão no Sistema Internacional é{' '}
                  <b className="presentationItem">
                    'kilogramas' (representada por <i>kg</i> ).
                  </b>
                </p>

                <p className="presentationItem">
                  <br></br>O raio do planeta é representada por{' '}
                  <b className="presentationItem">
                    <i>R</i>
                  </b>{' '}
                  e a unidade padrão no Sistema Internacional é{' '}
                  <b className="presentationItem">
                    'metros' (representada por <i>m</i> ).
                  </b>
                </p>

                <p className="presentationItem">
                  <br></br>A velocidade de escape do planeta é representada por{' '}
                  <b className="presentationItem">
                    <i>v</i>
                  </b>{' '}
                  e a unidade padrão no Sistema Internacional é{' '}
                  <b className="presentationItem">
                    'metros por segundo' (representada por <i>m/s</i> ).
                  </b>
                </p>

              </div>
            }
            trigger={<Icon name="question circle outline" />}
          />
          <div className="presentationItem arrowBtnContainer">
            <Button
              icon
              labelPosition="left"
              onClick={() => {
                setStep(step - 1);
              }}
            >
              <Icon name="left arrow" />
              Anterior
            </Button>
          </div>
        </>
      ),
    }
  ];

  useEffect(()=>{

    
  })

  return (
    <div className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`}>
      {/* <div className="minMaxBar">
        <Icon className="icon" name='caret down' onClick={minimizeBar}/>
        <Icon className="icon" name='caret up' onClick={maximizeBar}/>
      </div> */}
      {!isMinimized && standardObj[step].comp}
    </div>
  );
}

export default OrbitalVelocityPage;

import React, { useState } from 'react';
import { MathComponent } from 'mathjax-react';
import elipse from "../../../../images/secondKeplerLaw.png";

function SecondKeplerLawPage(props) {
  const [keplerLaw, setKeplerLaw] = useState(0);
  const [isMinimized, setIsMinimized] = useState(false);

  const standardObj = [
    {
      name: '2ª Lei de Kepler',
      keplerLaw: 1,
      comp: (
        <>
          <h1 className="presentationItem">2ª Lei de Kepler - Lei das Áreas (1609)</h1>

          <p className="presentationItem">
            Kepler observou que os planetas se moviam com diferentes velocidades ao longo de sua
            própria órbita.{' '}
            <b className="presentationItem">
              {' '}
              Quanto mais próximo do Sol, maior é a velocidade do planeta
            </b>
            , e quanto mais distante, mais lento este se locomove.
          </p>
          <p className="presentationItem">
            Através de suas observações, formulou a Segunda Lei de Kepler, ou Lei das Áreas. Esta
            afirma que{' '}
            <b className="presentationItem">
              um planeta varre áreas iguais (A) de sua órbita em tempos iguais (t)
            </b>
            . A formulação dessa lei pode ser descrita por:
            <MathComponent tex={String.raw`\frac{A_{1}}{\Delta t} = \frac{A_{2}}{\Delta t}`} />
          </p>
          <div style={{width: "100%", textAlign:"center"}}>
            <img src={elipse} width="38%"/>
          </div>
          {/* <svg  height="300" width="700">
              <circle style={{position: 'relative'}} id="planetaKepler2" cx="200" cy="100" r="8" stroke="white" strokeWidth="1" fill="white"/>
              <circle style={{position: 'relative'}} id="planeta2Kepler2" cx="350" cy="110" r="8" stroke="yellow" strokeWidth="1" fill="yellow"/>
          </svg> */}
        </>
      ),
    }
  ];

  return (
    <div className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`}>
      {/* <div className="minMaxBar">
        <Icon className="icon" name='caret down' onClick={minimizeBar}/>
        <Icon className="icon" name='caret up' onClick={maximizeBar}/>
      </div> */}
      {!isMinimized && standardObj[keplerLaw].comp}
    </div>
  );
}

export default SecondKeplerLawPage;

import React, { useState } from 'react';
import { List, Image, Dropdown, Button } from 'semantic-ui-react';

import './habilities.css';
import './myProfile.scss';
import './profile.scss';

import enfermeiro from '../../images/Profession/enfermeiro.png';
import engEletricista from '../../images/Profession/engEletricista.png';
import engMecanico from '../../images/Profession/engMecanico.png';
import mecanico from '../../images/Profession/mecanico.png';
import medico from '../../images/Profession/medico.png';
import programador from '../../images/Profession/programador.png';

function YourCareer() {
  const careerOptions = [
    {
      value: 'Enfermeiro',
      image: { avatar: true, src: enfermeiro },
      explanation:
        'O enfermeiro é o profissional da área da saúde que realiza o acompanhamento e cuidado dos pacientes, prestando assistência e garantindo a aplicação correta do tratamento médico.',
      requirements:
        'Para exercer a profissão de enfermeiro é necessário o diploma de bacharel em Enfermagem em curso reconhecido pelo MEC, o qual demora cerca de 5 anos ou mais para ser concluído.',
    },
    {
      value: 'Engenheiro Eletricista',
      image: { avatar: true, src: engEletricista },
      explanation:
        'O engenheiro eletricista é responsável pela projeção e desenvolvimento de sistemas de geração, transmissão e distribuição elétrica. Também trabalha na projeção e desenvolvimento de máquinas e produtos eletrônicos.',
      requirements:
        'Para exercer a profissão de engenheiro eletricista é necessário o diploma de bacharel em Engenharia Elétrica em curso reconhecido pelo MEC, o qual demora cerca de 5 anos ou mais para ser concluído.',
    },
    {
      value: 'Engenheiro Mecânico',
      image: { avatar: true, src: engMecanico },
      explanation:
        'O engenheiro mecânico é responsável pela projeção e desenvolvimento de sistemas mecânicos, tais quais como motores, veículos, máquinas e sistemas dinâmicos.',
      requirements:
        'Para exercer a profissão de engenheiro mecânico é necessário o diploma de bacharel em Engenharia Mecânica em curso reconhecido pelo MEC, o qual demora cerca de 5 anos ou mais para ser concluído.',
    },
    {
      value: 'Mecânico',
      image: { avatar: true, src: mecanico },
      explanation:
        'O mecânico faz a manutenção corretiva e preventiva em máquinas e veículos, realizando a troca e limpeza de componentes, consertos, revisão e regulagem de motores e suspensões.',
      requirements:
        'Para exercer a profissão é necessário se ter habilidades práticas, conhecimentos técnicos e noções de física e matemática.',
    },
    {
      value: 'Médico',
      image: { avatar: true, src: medico },
      explanation:
        'O médico é o responsável pelo diagnóstico, combate e tratamento de doenças e enfermidades que os pacientes vêem a ter. Também é responsável pela realização de cirurgias e aplicação de medicamentos.',
      requirements:
        'Para exercer a profissão de médico é necessário o diploma de bacharel em Medicina em curso reconhecido pelo MEC, o qual demora cerca de 6 anos para ser concluído. Existem também especializações, que podem demorar mais tempo para serem realizadas.',
    },
    {
      value: 'Programador',
      image: { avatar: true, src: programador },
      explanation:
        'O programador atua na área de Tecnologia da Informação, sendo responsável pela projeção e desenvolvimento de softwares, aplicativos, jogos e sistemas computacionais.',
      requirements:
        'Para exercer a profissão é necessário se ter habilidades práticas em linguagens de programação. Para alguns casos, pode ser necessária a realização de algum curso superior, como Engenharia de Software, Ciência da Computação, Sistema da Informação, entre outros.',
    },
  ];

  const careerOptionsDropdown = [];
  careerOptions.forEach((option) => {
    careerOptionsDropdown.push({
      key: option.value,
      text: option.value,
      value: option.value,
      image: option.image,
    });
  });

  const [career, setCareer] = useState(careerOptions[0].value);
  const [explanation, setExplanation] = useState(careerOptions[0].explanation);
  const [image, setImage] = useState(careerOptions[0].image.src);
  const [requirements, setRequirements] = useState(careerOptions[0].requirements);

  const handleDropdown = (event, data) => {
    setCareer(data.value);

    let filterExplanation = careerOptions.filter((el) => el.value === data.value);

    setExplanation(filterExplanation[0].explanation);
    setImage(filterExplanation[0].image.src);
    setRequirements(filterExplanation[0].requirements);
    // setDoRedirect(true);
  };

  return (
    <>
      <div className="profileSubItem">
        <div>
          <h1 className="presentationItem">Sua carreira</h1>
        </div>
        <div className="selectCareer__firstSquare">
          <p className="presentationItem">Selecione sua carreira</p>
          <Dropdown
            placeholder="Selecione sua carreira"
            fluid
            search
            selection
            options={careerOptionsDropdown}
            onChange={handleDropdown}
          />
          <br></br>
          <br></br>
          <h1 className="presentationItem">{career}</h1>
          <br></br>
          <p className="presentationItem">{explanation}</p>
          <p className="presentationItem">{requirements}</p>

          <div className="selectCareer__buttomAvatar">
            <Button fluid>Confirmar carreira</Button>
          </div>
        </div>

        <div className="selectCareer__secondSquare">
          {/* <img className="selectCareer__squareImg" src={image} /> */}
          <br></br>
          <br></br>
          <Image src={image} fluid />
        </div>

        {/* <div className="selectCareer__buttomAvatar">
          <Button fluid>Confirmar carreira</Button>
        </div> */}
      </div>
    </>
  );
}

export default YourCareer;

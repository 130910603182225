import React, { useState, useEffect, Component } from 'react';
import Anime, { anime } from 'react-anime';
import earthAntennas from "../../../../images/earthAntennas.png";
import sateliteBlue from "../../../../images/sateliteBlue.png";
import sateliteYellow from "../../../../images/sateliteYellow.png";
import StarMessage from '../../../../components/starMessage';

class SatelitesAnimation extends Component{
  
  constructor(props){
    super(props);
  }

  animate(){
    this.animation = [
      anime({
        targets: '#earth-antennas',
        rotate: '1turn',
        loop: true,
        easing: 'linear',
        duration: 3000
      }),
      anime({
        targets: '#satelite-blue',
        translateX: anime.path('#inner-orbit')('x'),
        translateY: anime.path('#inner-orbit')('y'),
        rotate: anime.path('#inner-orbit')('angle'),
        easing: 'linear',
        loop: true,
        duration: 2000
      }),
      anime({
        targets: '#satelite-yellow',
        translateX: anime.path('#outer-orbit')('x'),
        translateY: anime.path('#outer-orbit')('y'),
        rotate: anime.path('#outer-orbit')('angle'),
        easing: 'linear',
        loop: true,
        duration: 3000
      })
    ]
  }

  componentDidMount(){
    this.animate();
  }

  componentWillUnmount(){
    this.animation.forEach(item => item.pause());
  }

  render(){
    return(
      <div style={{width: '100%', height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <div style={{width: '300px', height: '200px', position: 'relative'}}>
          <svg width="150" height="150" >
            <circle id="inner-orbit" cx="75" cy="75" r="60" stroke="aquamarine" stroke-width="1" fill="none" />
            <circle id="outer-orbit" cx="75" cy="75" r="80" stroke="none" stroke-width="2" fill="none" />
          </svg>
          <img id="earth-antennas" width='75px' src={earthAntennas} style={{position: 'absolute', left: '45px', bottom: '90px', transformOrigin: '40% 50%'}}/>
          <img id="satelite-blue" width='18px' style={{position: 'absolute', left: '-8px', top: '-8px'}} src={sateliteBlue} />
          <img id="satelite-yellow" width='18px' style={{position: 'absolute', left: '-8px', top: '-8px'}} src={sateliteYellow} />
          <h1 className="secondaryFont" style={{color: 'yellow', position: 'absolute', fontSize: '13px', right: '-50px', top: '0px'}}>Satélite geoestacionário</h1>
          <h1 className="secondaryFont" style={{color: 'aquamarine', position: 'absolute', fontSize: '13px', right: '16px', top: '66px'}}>Satélite orbital</h1>
        </div>
      </div>
    )
  }
}

function SatellitePage(props) {
  const [step, setStep] = useState(0);
  const [isMinimized, setIsMinimized] = useState(false);

  function minimizeBar() {
    setIsMinimized(true);
  }

  function maximizeBar() {
    setIsMinimized(false);
  }

  const standardObj = [
    {
      name: 'Satélites',
      step: 1,
      comp: (
        <>
          <h1 className="presentationItem">Satélites</h1>

          <p className="presentationItem">
            Em Astronomia, dizemos que <b className="presentationItem">um satélite é um corpo que orbita outro astro</b>. O maior exemplo que temos é a Lua, que orbita a Terra à uma distância de 384000 km. Costumamos dizer <b className="presentationItem">a Lua é um satélite natural da Terra</b>, visto sua origem.
            <br></br>
            Com a tecnologia da velocidade orbital, a <b className="presentationItem">humanidade lançou satélites artificiais</b> na órbita de nosso planeta com diferentes objetivos, como a realização de medidas meteorológicas, medidas de posição de objetos na Terra (GPS), fornecimento de internet e telecomunicações.
            <br></br>
            <b className="presentationItem">Satélites geoestacionários</b> têm o período orbital igual à 24h, ou seja, estão sempre em cima do mesmo ponto, como é o caso dos satélites de comunicação e metereológicos. Já os <b className="presentationItem">satélites orbitais</b> giram em um período orbital geralmente menor do que 24h, como é o caso dos GPSs.
          </p>
          <SatelitesAnimation/>
        </>
      ),
    },
  ];

  return (
    <div className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`}>
      {/* <div className="minMaxBar">
        <Icon className="icon" name='caret down' onClick={minimizeBar}/>
        <Icon className="icon" name='caret up' onClick={maximizeBar}/>
      </div> */}
      {!isMinimized && standardObj[step].comp}
      <StarMessage
        show={true}
        message={'Satélites'}
        subjectScoring="physics"
        typeScoring="gravitation"
        arrayScoring={'arrStartEscapeSpeed'}
        indexScoring={0}
        xpAward={30}
      />
    </div>
  );
}

export default SatellitePage;

import React, { useState } from 'react';
import ScalePlot from "./aclGraph";

function AclGraph(props) {

  const [isMinimized, setIsMinimized] = useState(false);

  return (
    <div className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`} key={Math.random()}>
      {/* <div className="minMaxBar">
        <Icon className="icon" name='caret down' onClick={minimizeBar}/>
        <Icon className="icon" name='caret up' onClick={maximizeBar}/>
      </div> */}
      {!isMinimized && 
      <>
        <h1 className="presentationItem">Gráfico da Aceleração</h1>
        <p className="presentationItem">
        Para os carros do exemplo anterior, podemos observar os gráficos da aceleração ao longo do tempo. 
        <br></br>
        <br></br>
        Nestes, percebemos que a aceleração do carro verde permanece a mesma ao longo do tempo, pois esta não mudou. 
        <br></br>
        <br></br>
        Junto a isso, observamos a aceleração nula do carro vermelho:
        </p>
        <div className="aclGraphMruvContainer">
          <ScalePlot plotSizeX={600} plotSizeY={300} />
        </div>
      </>}
    </div>
  );
}

export default AclGraph;

import React, { useState, useEffect } from 'react';
import { Button, Icon, List, Popup } from 'semantic-ui-react';
import { MathComponent } from 'mathjax-react';
import anime from 'animejs/lib/anime.es.js';
import speedometer from '../../../../images/speedometer.png';
import speedometerPointer from '../../../../images/speedometerPointer.png';

function AcelerationPage(props) {
  const [acelerationStep, setAcelerationStep] = useState(0);
  const [isMinimized, setIsMinimized] = useState(false);

  const paddingLeftStyle = {
    left: '-3%',
    top: '6%',
    position: 'Relative',
    color: 'red',
  };

  const equationLeftStyle = {
    left: '120%',
    // top: '-6%',
    position: 'Relative',
    color: 'white',
  };

  const paddingRightStyle = {
    left: '3%',
    top: '-6%',
    position: 'Relative',
    color: 'green',
  };

  const equationRightStyle = {
    left: '-120%',
    // top: '-6%',
    position: 'Relative',
    color: 'white',
  };

  const standardObj = [
    {
      name: 'Aceleração',
      acelerationStep: 1,
      comp: (
        <>
          <h1 className="presentationItem">Aceleração</h1>
          <p className="presentationItem">
            Quando um objeto tem uma velocidade igual à zero, dizemos que este está parado. Podemos
            perceber que sua posição não muda ao longo do tempo.
          </p>
          <br></br>
          <p className="presentationItem">
            Agora imagine que temos um carro parado, em uma linha de largada e queremos iniciar o
            seu movimento. Vamos supor que para esse carro, iremos atingir uma velocidade máxima de
            30 m/s. Porém, como funciona essa mudança de velocidade?
          </p>
          <div className="presentationItem aceleration speedometers">
            <h3 style={paddingLeftStyle}>
              <List divided relaxed>
                <List.Item>
                  <List.Icon style={{filter: 'brightness(2.0)'}} name="times" size="big" verticalAlign="top" color="red" />
                  <List.Content></List.Content>
                </List.Item>
              </List>
            </h3>
            <h1 id="vel-speedometer-1">30 km/h</h1>
            <div className="speedometer">
              <img height="80%" src={speedometer} alt="velocimetro" />
              <img
                id="speedometer-pointer-1"
                height="25%"
                src={speedometerPointer}
                alt="ponteiro-velocimetro"
                className="pointer"
              />
            </div>
            <div className="speedometer">
              <img height="80%" src={speedometer} alt="velocimetro" />
              <img
                id="speedometer-pointer-2"
                height="25%"
                src={speedometerPointer}
                alt="ponteiro-velocimetro"
                className="pointer"
              />
            </div>
            <h1 id="vel-speedometer-2">30 km/h</h1>
            <h3 style={paddingRightStyle}>
              <List divided relaxed>
                <List.Item>
                  <List.Icon name="check" size="big" verticalAlign="top" color="green" />
                  <List.Content></List.Content>
                </List.Item>
              </List>
            </h3>
          </div>

          <p className="presentationItem">
            Percebemos que a situação da direita é a mais comum, é o que comumente ocorre no mundo
            real: a velocidade não muda de uma hora para outra, pelo contrário, vai se alterando aos
            poucos, com o passar do tempo. À esse fenômeno damos o nome de{' '}
            <b className="presentationItem">aceleração</b>.
          </p>

          <div className="presentationItem arrowBtnContainer">
            <Button
              icon
              labelPosition="right"
              onClick={() => {
                setAcelerationStep(acelerationStep + 1);
              }}
            >
              Próximo
              <Icon name="right arrow" />
            </Button>
          </div>
        </>
      ),
    },
    {
      name: 'Aceleração',
      acelerationStep: 2,
      comp: (
        <>
          <h1 className="presentationItem">Aceleração</h1>
          <p className="presentationItem">
            Na última página vimos que a{' '}
            <b className="presentationItem">velocidade varia conforme o passar do tempo</b>. Vamos
            analisar a situação abaixo:
          </p>
          <br></br>
          <div className="presentationItem aceleration speedometers">
            <h3 style={paddingLeftStyle}>
              <List divided relaxed>
                <List.Item>
                  <List.Content>Lento</List.Content>
                </List.Item>
              </List>
            </h3>
            <h1 id="vel-speedometer-1-2">30 km/h</h1>
            <div className="speedometer">
              <img height="80%" src={speedometer} alt="velocimetro" />
              <img
                id="speedometer-pointer12"
                height="25%"
                src={speedometerPointer}
                alt="ponteiro-velocimetro"
                className="pointer"
              />
            </div>
            <div className="speedometer">
              <img height="80%" src={speedometer} alt="velocimetro" />
              <img
                id="speedometer-pointer22"
                height="25%"
                src={speedometerPointer}
                alt="ponteiro-velocimetro"
                className="pointer"
              />
            </div>
            <h1 id="vel-speedometer-2-2">30 km/h</h1>
            <h3 style={paddingRightStyle}>
              <List divided relaxed>
                <List.Item>
                  <List.Content style={{filter: 'brightness(1.75)'}}>Rápido</List.Content>
                </List.Item>
              </List>
            </h3>
          </div>
          <p className="presentationItem">
            Percebemos que em ambas as situações a velocidade variou de 0 à 30 m/s, porém, enquanto
            a da esquerda demorou cerca de 12 segundos, a da direita demorou 3 segundos.
          </p>
          <p className="presentationItem">
            Como o velocímetro da direita durou{' '}
            <b className="presentationItem">
              menos tempo para chegar à mesma velocidade, percebemos que sua aceleração é maior
            </b>
            .
          </p>
          <br></br>

          {/* <div className="averageSpeed container">
            <img id="moving-car-blue" className="presentationItem movingCar" src={movingCarBlue} />
          </div> */}

          <div className="presentationItem arrowBtnContainer">
            <Button
              icon
              labelPosition="left"
              onClick={() => {
                setAcelerationStep(acelerationStep - 1);
              }}
            >
              <Icon name="left arrow" />
              Anterior
            </Button>
            <Button
              icon
              labelPosition="right"
              onClick={() => {
                setAcelerationStep(acelerationStep + 1);
              }}
            >
              Próximo
              <Icon name="right arrow" />
            </Button>
          </div>
        </>
      ),
    },
    {
      name: 'Aceleração',
      acelerationStep: 3,
      comp: (
        <>
          <h1 className="presentationItem">Aceleração</h1>
          <p className="presentationItem">
            Como se trata da{' '}
            <b className="presentationItem">variação da velocidade ao longo do tempo</b>, podemos
            fazer uma divisão simples da Velocidade pelo Tempo. Assim, temos como fórmula da
            aceleração:
            <MathComponent tex={String.raw`a =  \frac{\Delta V}{\Delta t}`} />
            Para os dois casos estudados anteriormente, temos:
          </p>
          <div className="presentationItem aceleration speedometers3">
            <div className="speedometerContainer3">
              <img className="speedometer31" src={speedometer} alt="velocimetro" />
              <img className="speedometer32" src={speedometer} alt="velocimetro" />
              <img
                id="speedometer-pointer-1-3"
                src={speedometerPointer}
                alt="ponteiro"
                className="pointer"
              />
              <img
                id="speedometer-pointer-2-3"
                src={speedometerPointer}
                alt="ponteiro"
                className="pointer"
              />
            </div>
            <div className="speedometerText31">
              <h1 className="velSpeedometer13">Velocidade Inicial: 0 m/s</h1>
              <h1 className="velSpeedometer13" id="vel-speedometer-1-3">
                30 m/s
              </h1>
              <h1 className="velSpeedometer13" id="vel-speedometer-time-1-3">
                Tempo: 0 s
              </h1>
              <br></br>
              <br></br>
              <p style={equationLeftStyle}>
                <MathComponent tex={String.raw`a =  \frac{30 m/s}{12 s} = 2.5 m/s²`} />
              </p>
            </div>
            <div className="speedometerText32">
              <h1 className="velSpeedometer23">Velocidade Inicial: 0 m/s</h1>
              <h1 className="velSpeedometer23" id="vel-speedometer-2-3">
                30 m/s
              </h1>
              <h1 className="velSpeedometer23" id="vel-speedometer-time-2-3">
                Tempo: 0 s
              </h1>
              <br></br>
              <br></br>
              <p style={equationRightStyle}>
                <MathComponent tex={String.raw`a =  \frac{30 m/s}{3 s} = 10 m/s²`} />
              </p>
            </div>
          </div>
          <div>
          <p className="presentationItem">
            <br></br>
            <br></br>
            <br></br>
            <Popup
                wide
                inverted
                content={
                  <p className="presentationItem">
                    <br></br>A aceleração é comumente representado pela letra{' '}
                    <b className="presentationItem">
                      <i>a</i>
                    </b>{' '}
                    e a unidade padrão de tempo no Sistema Internacional é{' '}
                    <b className="presentationItem">'metros por segundo ao quadrado'</b> (representada
                    por{' '}
                    <b className="presentationItem">
                      <i>m/s² </i>
                    </b>
                    ).<br></br>
                    Outra unidade bastante utilizada é km/h².
                  </p>
                }
                trigger={<Icon name="question circle outline" />}
              />
            </p>
          </div>

          <br></br>

          <div className="presentationItem arrowBtnContainer">
            <Button
              icon
              labelPosition="left"
              onClick={() => {
                setAcelerationStep(acelerationStep - 1);
              }}
            >
              <Icon name="left arrow" />
              Anterior
            </Button>
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    // Aceleração - Parte 1
    if (acelerationStep === 0) {
      anime({
        targets: '#speedometer-pointer-1',
        rotate: ['-100', '-10'],
        loop: acelerationStep === 0,
        duration: 1,
        easing: 'linear',
        delay: 1000,
        endDelay: 1000,
      });
      anime({
        targets: '#speedometer-pointer-2',
        rotate: ['-100', '-10'],
        loop: acelerationStep === 0,
        duration: 6000,
        easing: 'linear',
        endDelay: 500,
      });
      anime({
        targets: '#vel-speedometer-1',
        innerText: {
          value: ['0 m/s', '30 m/s'],
        },
        round: 1,
        loop: acelerationStep === 0,
        duration: 1,
        easing: 'linear',
        delay: 1000,
        endDelay: 1000,
      });
      anime({
        targets: '#vel-speedometer-2',
        innerText: {
          value: ['0 m/s', '30 m/s'],
        },
        round: 1,
        loop: acelerationStep === 0,
        duration: 6000,
        easing: 'linear',
        endDelay: 500,
      });
    }

    if (acelerationStep === 1) {
      // Aceleração - parte 2
      anime({
        targets: '#speedometer-pointer12',
        rotate: ['-100', '-10'],
        loop: acelerationStep === 1,
        duration: 12000,
        easing: 'linear',
        endDelay: 500,
      });
      anime({
        targets: '#speedometer-pointer22',
        rotate: ['-100', '-10'],
        loop: acelerationStep === 1,
        duration: 1500,
        easing: 'linear',
        endDelay: 500,
      });
      anime({
        targets: '#vel-speedometer-1-2',
        innerText: {
          value: ['0 m/s', '30 m/s'],
        },
        round: 1,
        loop: acelerationStep === 1,
        duration: 12000,
        easing: 'linear',
        endDelay: 500,
      });
      anime({
        targets: '#vel-speedometer-2-2',
        innerText: {
          value: ['0 m/s', '30 m/s'],
        },
        round: 1,
        loop: acelerationStep === 1,
        duration: 1500,
        easing: 'linear',
        endDelay: 500,
      });
    }

    if (acelerationStep === 2) {
      // Aceleração - parte 3
      anime({
        targets: '#speedometer-pointer-1-3',
        rotate: ['-100', '-10'],
        loop: true,
        duration: 12000,
        easing: 'linear',
        endDelay: 3000,
      });
      anime({
        targets: '#speedometer-pointer-2-3',
        rotate: ['-100', '-10'],
        loop: true,
        duration: 3000,
        easing: 'linear',
        endDelay: 12000,
      });
      anime({
        targets: '#vel-speedometer-1-3',
        innerText: {
          value: ['0 m/s', '30 m/s'],
        },
        round: 1,
        loop: true,
        duration: 12000,
        easing: 'linear',
        endDelay: 3000,
      });
      anime({
        targets: '#vel-speedometer-2-3',
        innerText: {
          value: ['0 m/s', '30 m/s'],
        },
        round: 1,
        loop: true,
        duration: 3000,
        easing: 'linear',
        endDelay: 12000,
      });
      anime({
        targets: '#vel-speedometer-time-1-3',
        innerText: {
          value: ['0 s', '12 s'],
        },
        round: 1,
        loop: true,
        duration: 12000,
        easing: 'linear',
        endDelay: 3000,
      });
      anime({
        targets: '#vel-speedometer-time-2-3',
        innerText: {
          value: ['0 s', '3 s'],
        },
        round: 1,
        loop: true,
        duration: 3000,
        easing: 'linear',
        endDelay: 12000,
      });
    }
  });
  return (
    <div className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`}>
      {/* <div className="minMaxBar">
        <Icon className="icon" name="caret down" onClick={minimizeBar} />
        <Icon className="icon" name="caret up" onClick={maximizeBar} />
      </div> */}
      {!isMinimized && standardObj[acelerationStep].comp}
    </div>
  );
}

export default AcelerationPage;

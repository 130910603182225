import React, { useEffect } from 'react';
import orangeSvg from '../images/spinnerLaranja.svg';
import blueSvg from '../images/spinnerAzul.svg';
import logoSvg from '../images/Logos/oficialSpinnerLogoWhite.png';
import './loadingSpinner.scss';
import anime from 'animejs/lib/anime.es.js';

function LoadingSpinner(props) {
  useEffect(() => {
    anime({
      targets: '#orange-spinner-svg',
      rotate: '1turn',
      loop: true,
      easing: 'linear',
      // easing: 'easeInOutCubic',
      duration: 600,
    });
    anime({
      targets: '#blue-spinner-svg',
      rotate: '1turn',
      // rotate: '2turn',
      loop: true,
      easing: 'linear',
      // easing: 'easeInOutCubic',
      duration: 1500,
    });
    anime({
      targets: '#logo-spinner-svg',
      scale: '1.2',
      loop: true,
      direction: 'alternate',
      easing: 'linear',
      duration: 400,
    });
  });

  return (
    <>
      {props.loading && (
        <div className={`loadingSpinner__page ${props.className ?? ''}`}>
          <img
            id="orange-spinner-svg"
            className="loadingSpinner__img"
            src={orangeSvg}
            alt="orange"
          />
          <img id="blue-spinner-svg" className="loadingSpinner__img" src={blueSvg} alt="blue" />
          <img id="logo-spinner-svg" className="loadingSpinner__img" src={logoSvg} alt="logo" />
        </div>
      )}
    </>
  );
}

export default LoadingSpinner;

import React, { useState, useEffect } from 'react';
import { Button, List, Icon, Input } from 'semantic-ui-react';
import { MathComponent } from 'mathjax-react';
import StarMessage from '../../../../components/starMessage';
import useSound from 'use-sound';
import correctSound from '../../../../sounds/correctAnswer.mp3';
import wrongSound from '../../../../sounds/wrongAnswer.mp3';

// import anime from "animejs/lib/anime.es.js";

function UnravelingTorricelliPage(props) {
  const [torricelliStep, setTorricelliStep] = useState(0);
  const [torricelliRevealStep, setTorricelliReveal] = useState(0);
  const [isMinimized, setIsMinimized] = useState(false);

  const [eq1, setEq1] = useState('');
  const [eq2a, setEq2a] = useState('');
  const [eq2b, setEq2b] = useState('');
  const [eq3a, setEq3a] = useState('');
  const [eq3b, setEq3b] = useState('');
  const [eq3c, setEq3c] = useState('');

  const [playCorrect] = useSound(correctSound);
  const [playWrong] = useSound(wrongSound);


  function getCorrect() {
    return (
      <>
        <List divided relaxed>
          <List.Item>
            <List.Icon name="check" size="big" verticalAlign="top" color="green" />
            <List.Content>
              <p className="presentationItem">Parabéns, você acertou!</p>
            </List.Content>
          </List.Item>
        </List>
      </>
    );
  }

  function getWrong() {
    return (
      <>
        <List divided relaxed>
          <List.Item>
            <List.Icon name="times" size="big" verticalAlign="top" color="red" />
            <List.Content>
              <p className="presentationItem">Que pena, essa não é a resposta correta!</p>
            </List.Content>
          </List.Item>
        </List>
      </>
    );
  }

  /*
   * Detecção das respostas corretas para a reprodução dos sons de acerto/erro
   */
  useEffect(() => {
    if (eq1) {
      if (eq1 === 'a') {
        playCorrect();
      } else {
        playWrong();
      }
    }
  }, [eq1]);

  useEffect(() => {
    if (eq2a && eq2a === 'V') {
      playCorrect();
    } else {
      playWrong();
    }
  }, [eq2a]);

  useEffect(() => {
    if (eq2b && eq2b === 'a') {
      playCorrect();
    } else {
      playWrong();
    }
  }, [eq2b]);

  useEffect(() => {
    if (eq3a && eq3a === 'V0') {
      playCorrect();
    } else {
      playWrong();
    }
  }, [eq3a]);

  useEffect(() => {
    if (eq3b && eq3b === 'V') {
      playCorrect();
    } else {
      playWrong();
    }
  }, [eq3b]);

  useEffect(() => {
    if (eq3c && eq3c === '2') {
      playCorrect();
    } else {
      playWrong();
    }
  }, [eq3c]);

  useEffect(() => {
    if (torricelliRevealStep == 6) {
      // playCorrect()
    }
  }, [torricelliRevealStep]);

  const standardObj = [
    {
      name: 'Desvendando Torricelli',
      torricelliStep: 1,
      comp: (
        <>
          <h1 className="presentationItem">Desvendando Torricelli</h1>
          <br></br>
          <br></br>
          <br></br>

          {torricelliRevealStep === 0 && (
            <>
              <p className="presentationItem">
                Mas como Torriccelli conseguiu combinar duas equações em uma só?
                <br></br>
                <br></br>
                Pode parecer difícil, mas como tudo em física, é preciso apenas equações e
                raciocínio. Vamos fazer juntos esta demonstração, mostrando a você cada uma das
                etapas enquanto você preenche o que estiver faltando. Tenho certeza de que você
                consegue nos ajudar nesse desafio!
              </p>
              <div className="presentationItem arrowBtnContainer">
                <Button
                  icon
                  labelPosition="right"
                  onClick={() => {
                    setTorricelliReveal(torricelliRevealStep + 1);
                  }}
                >
                  Próximo
                  <Icon name="right arrow" />
                </Button>
              </div>
            </>
          )}

          {torricelliRevealStep === 1 && (
            <>
              <p className="presentationItem">
                Vamos começar com a Equação Horária da Velocidade:
                <MathComponent tex={String.raw`V = V_{0} + a*t`} />
              </p>
              <div className="presentationItem arrowBtnContainer">
                <Button
                  icon
                  labelPosition="left"
                  onClick={() => {
                    setTorricelliReveal(torricelliRevealStep - 1);
                  }}
                >
                  <Icon name="left arrow" />
                  Anterior
                </Button>
                <Button
                  icon
                  labelPosition="right"
                  onClick={() => {
                    setTorricelliReveal(torricelliRevealStep + 1);
                  }}
                >
                  Próximo
                  <Icon name="right arrow" />
                </Button>
              </div>
            </>
          )}

          {torricelliRevealStep === 2 && (
            <>
              <p className="presentationItem">
                Se isolarmos a variável de tempo na equação horária da velocidade, temos:
                <MathComponent tex={String.raw`t = \frac{V - V_{0}}{\star}`} />
              </p>

              <p className="presentationItem">
                Percebemos alguns símbolos no local das variáveis.
                <br></br>
                <br></br>
                Podemos substituir a estrela por: <a> </a>
                <input style={{ width: '3vw' }} onChange={(event) => setEq1(event.target.value)} />
              </p>

              {eq1 === 'a' && <p className="presentationItem">{getCorrect()}</p>}

              {eq1 && eq1 !== 'a' && <p className="presentationItem">{getWrong()}</p>}

              <div className="presentationItem arrowBtnContainer">
                <Button
                  icon
                  labelPosition="left"
                  onClick={() => {
                    setTorricelliReveal(torricelliRevealStep - 1);
                  }}
                >
                  <Icon name="left arrow" />
                  Anterior
                </Button>
                {eq1 === 'a' && (
                  <Button
                    icon
                    labelPosition="right"
                    onClick={() => {
                      setTorricelliReveal(torricelliRevealStep + 1);
                    }}
                  >
                    Próximo
                    <Icon name="right arrow" />
                  </Button>
                )}
              </div>
            </>
          )}

          {torricelliRevealStep === 3 && (
            <>
              <p className="presentationItem">
                Agora, vamos nos lembrar da equação horária da posição:
                <MathComponent tex={String.raw`S = S_{0} + V_{0}*t + \frac{a*t^2}{2}`} />
              </p>

              <div className="presentationItem arrowBtnContainer">
                <Button
                  icon
                  labelPosition="left"
                  onClick={() => {
                    setTorricelliReveal(torricelliRevealStep - 1);
                  }}
                >
                  <Icon name="left arrow" />
                  Anterior
                </Button>
                <Button
                  icon
                  labelPosition="right"
                  onClick={() => {
                    setTorricelliReveal(torricelliRevealStep + 1);
                  }}
                >
                  Próximo
                  <Icon name="right arrow" />
                </Button>
              </div>
            </>
          )}

          {torricelliRevealStep === 4 && (
            <>
              <p className="presentationItem">
                Como não queremos mais depender do tempo na equação horária da posição, iremos
                substituir seu valor pelo encontrado passos atrás:
                <MathComponent
                  tex={String.raw`S = S_{0} + V_{0}*\frac{(\star_{1} - V_{0})}{a} + \frac{\star_{2} * (\frac{V - V_{0}}{a})^2}{2}`}
                />
              </p>

              <p className="presentationItem">
                Percebemos alguns símbolos no local das variáveis.
                <br></br>
                <br></br>
                Podemos substituir a estrela 1 por: <a> </a>
                <input style={{ width: '3vw' }} onChange={(event) => setEq2a(event.target.value)} />
                <br></br>
                <br></br>
                Podemos substituir a estrela 2 por: <a> </a>
                <input style={{ width: '3vw' }} onChange={(event) => setEq2b(event.target.value)} />
              </p>

              {eq2a === 'V' && eq2b === 'a' && <p className="presentationItem">{getCorrect()}</p>}

              {eq2a && eq2b && (eq2a !== 'V' || eq2b !== 'a') && (
                <p className="presentationItem">{getWrong()}</p>
              )}

              <div className="presentationItem arrowBtnContainer">
                <Button
                  icon
                  labelPosition="left"
                  onClick={() => {
                    setTorricelliReveal(torricelliRevealStep - 1);
                  }}
                >
                  <Icon name="left arrow" />
                  Anterior
                </Button>
                {eq2a === 'V' && eq2b === 'a' && (
                  <Button
                    icon
                    labelPosition="right"
                    onClick={() => {
                      setTorricelliReveal(torricelliRevealStep + 1);
                    }}
                  >
                    Próximo
                    <Icon name="right arrow" />
                  </Button>
                )}
              </div>
            </>
          )}

          {torricelliRevealStep === 5 && (
            <>
              <p className="presentationItem">
                Elevando o segundo termo em parênteses ao quadrado e aplicando a propriedade
                distributiva, temos:
                <MathComponent
                  tex={String.raw`S = S_{0} + \frac{V_{0}*V - \star_{1}^2}{a} + \frac{(V^2 + V_{0}^2 - 2*\star_{2}*V_{0})}{\star_{3}*a}`}
                />
              </p>

              <p className="presentationItem">
                Percebemos alguns símbolos no local das variáveis.
                <br></br>
                <br></br>
                Podemos substituir a estrela 1 por: <a> </a>
                <input style={{ width: '3vw' }} onChange={(event) => setEq3a(event.target.value)} />
                <br></br>
                <br></br>
                Podemos substituir a estrela 2 por: <a> </a>
                <input style={{ width: '3vw' }} onChange={(event) => setEq3b(event.target.value)} />
                <br></br>
                <br></br>
                Podemos substituir a estrela 3 por: <a> </a>
                <input style={{ width: '3vw' }} onChange={(event) => setEq3c(event.target.value)} />
              </p>

              {eq3a === 'V0' && eq3b === 'V' && eq3c === '2' && (
                <p className="presentationItem">{getCorrect()}</p>
              )}

              {eq3a && eq3b && eq3c && (eq3a !== 'V0' || eq3b !== 'V' || eq3c !== '2') && (
                <p className="presentationItem">{getWrong()}</p>
              )}

              <div className="presentationItem arrowBtnContainer">
                <Button
                  icon
                  labelPosition="left"
                  onClick={() => {
                    setTorricelliReveal(torricelliRevealStep - 1);
                  }}
                >
                  <Icon name="left arrow" />
                  Anterior
                </Button>
                {eq3a === 'V0' && eq3b === 'V' && eq3c === '2' && (
                  <Button
                    icon
                    labelPosition="right"
                    onClick={() => {
                      setTorricelliReveal(torricelliRevealStep + 1);
                    }}
                  >
                    Próximo
                    <Icon name="right arrow" />
                  </Button>
                )}
              </div>
            </>
          )}

          {torricelliRevealStep === 6 && (
            <>
              <p className="presentationItem">
                Assim, basta manipularmos alguns termos e chegamos a equação final:
                <MathComponent tex={String.raw`V^2 = V_{0}^2 + 2*a*\Delta S`} />
                Admita. Foi mais fácil do que você pensava!
              </p>
              {console.log('oi')}
              <StarMessage
                show={true}
                message={'Desvendando Torricelli'}
                subjectScoring="physics"
                typeScoring="kinematics"
                arrayScoring={'arrStartTorricelli'}
                indexScoring={0}
                xpAward={60}
              />

              <div className="presentationItem arrowBtnContainer">
                <Button
                  icon
                  labelPosition="left"
                  onClick={() => {
                    setTorricelliReveal(torricelliRevealStep - 1);
                  }}
                >
                  <Icon name="left arrow" />
                  Anterior
                </Button>
              </div>
            </>
          )}

          {/* <p className="presentationItem">        
            Se isolarmos a variável de tempo na equação horária da velocidade, temos:
            <MathComponent tex={String.raw`t = \frac{V - V_{0}}{a}`}/>
            <Input focus placeholder='Search...' />
          </p>
          

          <p className="presentationItem">        
            Como não queremos mais depender do tempo, vamos substituí-lo na equação horária da posição:
            <MathComponent tex={String.raw`S = S_{0} + V_{0}*\frac{(V - V_{0})}{a} + \frac{a * (\frac{V - V_{0}}{a})^2}{2}`}/>
          </p>

          <p className="presentationItem">        
            Elevando o segundo termo em parênteses ao quadrado e aplicando a propriedade distributiva, temos: 
            <MathComponent tex={String.raw`S = S_{0} + \frac{V_{0}*V - V_{0}^2}{a} + \frac{(V^2 + V_{0}^2 - 2*V*V_{0})}{2*a}`}/>
          </p>

          <p className="presentationItem">        
            Assim, basta manipularmos alguns termos e chegamos a equação final:
            <MathComponent tex={String.raw`V^2 = V_{0}^2 + 2*a*\Delta S`}/>
            Admita. Foi mais fácil do que você pensava!
          </p> */}
        </>
      ),
    },
  ];

  return (
    <div className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`}>
      {/* <div className="minMaxBar">
        <Icon className="icon" name='caret down' onClick={minimizeBar}/>
        <Icon className="icon" name='caret up' onClick={maximizeBar}/>
      </div> */}
      {!isMinimized && standardObj[torricelliStep].comp}
    </div>
  );
}

export default UnravelingTorricelliPage;

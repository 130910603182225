import React, { useState } from 'react';
import Anime, { anime } from 'react-anime';
import StarMessage from '../../../../components/starMessage';
import earth from "../../../../images/earthT.png";
import man from "../../../../images/manGravity.png";
import sateliteBlue from "../../../../images/sateliteBlue.png";
import sateliteGreen from "../../../../images/sateliteBlue.png";
import "../CSSs/physicsGravitation.scss";
import { useEffect, Component } from 'react';

class EarthAndSatelites extends Component{

  constructor(props){
    super(props);
  }

  animate(){
    this.animation = [
      anime({
        targets: ['#line-2', '#line-3'],
        rotate: '360deg',
        loop: true,
        easing: 'linear',
        duration: 4000 
      }),
      anime({
        targets: ['#satelite-blue'],
        translateX: anime.path('#orbit-blue')('x'),
        translateY: anime.path('#orbit-blue')('y'),
        rotate: anime.path('#orbit-blue')('angle'),
        loop: true,
        easing: 'linear',
        duration: 4000 
      }),
      anime({
        targets: ['#satelite-green'],
        translateX: anime.path('#path')('x'),
        translateY: anime.path('#path')('y'),
        rotate: anime.path('#path')('angle'),
        loop: true,
        direction: 'reverse',
        easing: 'linear',
        duration: 4000 
      })
    ]
  }

  componentDidMount(){
    this.animate();
  }

  componentWillUnmount(){
    this.animation.forEach(item => item.pause());
  }

  render(){
    return(
      <div style={{width: '100%', height: '300px', position: 'absolute', display: 'flex', justifyContent: 'center'}}>
        <div className="physicsGravitation__animationContainer">
          <img src={earth} className="physicsGravitation__animationContainer"/>
          <img id='satelite-blue' src={sateliteBlue} style={{width: '10px', position: 'absolute', width: '30px', left: '-13px', top: '-13px'}}/>
          <img id='satelite-green' src={sateliteGreen} style={{width: '10px', position: 'absolute', width: '30px', left: '-13px', top: '-13px'}}/>
          <img src={man} style={{width: '30px', position: 'absolute', left: '294px', bottom: '136px'}}/>
          <svg height="300" width="500" style={{zIndex:'2', position: 'absolute', left: 0}}>
            <circle id='orbit-blue' cx="245" cy="155" r="90" stroke="none" stroke-width="1" fill="none" />
            <path id="path" d="M 245 15 A 70 70 90 0 0 245 295 A 70 70 90 0 0 245 15" stroke="none" fill="none" strokeWidth="1"/>
            {/* <circle id='orbit-green' cx="245" cy="155" r="145" stroke="red" stroke-width="1" fill="none"/> */}
            <line id='line-1' x1="245" y1="155" x2="295" y2="155" stroke="rgb(255,0,0)" strokeWidth="2" />
            <line id='line-2' x1="245" y1="155" x2="250" y2="20" stroke="rgb(0,255,0)" strokeWidth="2" style={{transformOrigin: '245px 155px'}} />
            <line id='line-3' x1="245" y1="155" x2="325" y2="155" stroke="aquamarine" strokeWidth="2" style={{transformOrigin: '245px 155px'}}/>
            <text x="245" y="180" stroke="rgb(255,0,0)">6378 km - g= 9,80 m/s²</text>
            <text x="140" y="275" stroke="rgb(0,255,0)">50000 km acima da Terra - 9,67 m/s²</text>
            <text x="140" y="250" stroke="aquamarine">30000 km acima da Terra - 9,72 m/s²</text>
          </svg>
        </div>
      </div>
    )
  }
}

function GravityAndDistance(props) {
  const [step, setStep] = useState(0);
  const [isMinimized, setIsMinimized] = useState(false);
  const [mass, setMass] = useState(50);
  const [planetWeight, setPlanetWeight] = useState({planet: 'Lua', weight:(1.62*mass)})

  const standardObj = [
    {
      name: 'Gravidade e Aceleração',
      step: 1,
      comp: (
        <>
          <h1 className="presentationItem">Gravidade por distância</h1>

          <p className="presentationItem" style={{paddingBottom: '1%'}}>
            Percebemos que a aceleração da gravidade de um planeta depende da <b className="presentationItem">massa desse planeta</b> e da <b className="presentationItem">distância que se encontra determinado objeto de seu centro</b>.
          </p>

          <p className="presentationItem" style={{paddingBottom: '1%'}}>
            Como a Terra tem uma massa de 6e24 kg, e um raio de 6378 km, o valor da gravidade em sua superfície será de <b className="presentationItem">9,80 m/s²</b> .
          </p>
          <p className="presentationItem" style={{paddingBottom: '1%'}}>
            Como temos satélites artificiais orbitando a Terra em diferentes alturas, estes estarão sujeitos à diferentes valores de gravidade exercido por nosso planeta:
          </p>

          <EarthAndSatelites/>
        </>
      ),
    },
  ];

  useEffect(()=>{

  })

  return (
    <div className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`}>
      {/* <div className="minMaxBar">
        <Icon className="icon" name='caret down' onClick={minimizeBar}/>
        <Icon className="icon" name='caret up' onClick={maximizeBar}/>
      </div> */}
      {!isMinimized && standardObj[step].comp}
    </div>
  );
}

export default GravityAndDistance;

import React from 'react';
import MissionComponent from '../../../../components/MapComponents/missionComponent';

function HistoryPage(props) {
  return (
    <>
      <MissionComponent
        numberMissions={5}
        subject="physics"
        typeScoring="gravitation"
        arrayScoring="arrStarGravityAcceleration"
        missionName="Realizando o pouso"
        missionDescription="Descrição: Estamos pousando no planeta Quest. Ajude controlando os motores através do uso da aceleração da gravidade local."
        missionVectorNumber={4}
        submission={[
          {
            missionName: 'O peso em Júpiter',
            missionDescription: 'Descrição: Descubra no Centro de Pesquisas qual seria seu peso caso estivesse no planeta Júpiter.',
            missionVectorNumber: 0,
          },
          {
            missionName: 'Grandeza Peso',
            missionDescription:
              'Descrição: No treinamento de astronautas, pesquise sobre as técnicas de observação.',
            missionVectorNumber: 1,
          },
          {
            missionName: 'Peso e massa',
            missionDescription:
              'Descrição: No treinamento de astronautas, pesquise sobre os planetas.',
            missionVectorNumber: 2,
          },
          {
            missionName: 'Gravidade em Marte',
            missionDescription:
              'Descrição: No treinamento de astronautas, investigue seu caderno de anotações.',
            missionVectorNumber: 3,
          },
        ]}
      />
    </>
  );
}

export default HistoryPage;

import studentConfig from '../configurations/studentConfigurations';
import * as R from 'ramda';
import BaseObserver from '../libs/classUtils/BaseObserver';
import firebase from 'firebase/app';

type SpecificScore = ReturnType<typeof createDefaultData>['objSpecificScore'];

interface ScoreManagerBaseObj {
  strID: string;
  nRespect: number;
  dtLastUpdate: Date;
  objSpecificScore: SpecificScore;
}

type ScoreCallBackFunction = (objScoreManager: ScoreManager) => '@REMOVE' | void;

type GenericFuncUnion<V> = V | ((nValue: V) => V);

function createDefaultData(id: string) {
  return {
    strID: id,
    nRespect: 0,
    dtLastUpdate: new Date(),
    objSpecificScore: {
      physics: {
        level: 1,
        xp: 0,
        kinematics: {
          bSticker() {
            return this.nAcquiredStars() === this.nTotalStars;
          },
          bFinished() {
            return this.arrStartTorricelli[3] === true;
          },
          nAcquiredStars() {
            return (
              R.sum(this.arrStarAverageSpeed.map(Number)) +
              R.sum(this.arrStarAceleration.map(Number)) +
              R.sum(this.arrStarMRUV.map(Number)) +
              R.sum(this.arrStartTorricelli.map(Number))
            );
          },
          nTotalStars: 18,
          nScore: 0,
          // averageSpeed: {
          //   arrStars: [false, false, false, false, true],
          //   accessMuseum: false,
          //   accessOffice: false
          // },
          arrStarAverageSpeed: [false, false, false, false, true],
          arrStarAceleration: [false, false, false, true],
          arrStarMRUV: [false, false, false, false, true],
          arrStartTorricelli: [false, false, false, true],
          additionalInfo: {
            teams: ['Arara Real', 'Jacaré Impiedoso', 'Boto Veloz', 'Falcão Negro', 'Guará Rubro', 'Capivara Furiosa'],
            acessOffice: ['false', 'false', 'false', 'false'],
            acessMuseum: ['false', 'false', 'false', 'false'],
          },
        },
        gravitation: {
          bSticker() {
            return this.nAcquiredStars() === this.nTotalStars;
          },
          bFinished() {
            return this.arrStartEscapeSpeed[3] === true;
          },
          nAcquiredStars() {
            return (
              R.sum(this.arrStarKeplersLaws.map(Number)) +
              R.sum(this.arrStarLawOfUniversalGravitation.map(Number)) +
              R.sum(this.arrStarGravityAcceleration.map(Number)) +
              R.sum(this.arrStartEscapeSpeed.map(Number))
            );
          },
          nTotalStars: 18,
          nScore: 0,
          arrStarKeplersLaws: [false, false, false, false, true],
          arrStarLawOfUniversalGravitation: [false, false, false, true],
          arrStarGravityAcceleration: [false, false, false, false, true],
          arrStartEscapeSpeed: [false, false, false, true],
          additionalInfo: {
            specie: 'Octawabeter',
            acessOffice: ['false', 'false', 'false', 'false'],
            acessMuseum: ['false', 'false', 'false', 'false'],
          },
        },
      },
    },
  };
}

class ScoreManager extends BaseObserver<ScoreCallBackFunction> {
  dtLastUpdate: Date;
  strID: string;
  nRespect: number;
  objSpecificScore: SpecificScore;
  user: firebase.User | null;
  constructor({ strID, nRespect, dtLastUpdate, objSpecificScore }: ScoreManagerBaseObj) {
    super();
    this.dtLastUpdate = dtLastUpdate;
    this.nRespect = nRespect;
    this.strID = strID;
    this.objSpecificScore = objSpecificScore;
    this.user = null;
  }

  updateRespect(nOrFnRespect: GenericFuncUnion<number>) {
    if (typeof nOrFnRespect === 'function') {
      this.nRespect = nOrFnRespect(this.nRespect);
    } else {
      this.nRespect = nOrFnRespect;
    }
    this.nRespect = Math.max(0, this.nRespect);
    this.notifyAll();
    this.updateDatabase();
  }

  updateKinematicsScore(nOrFnScore: GenericFuncUnion<number>) {
    if (typeof nOrFnScore === 'function') {
      this.objSpecificScore.physics.kinematics.nScore = nOrFnScore(
        this.objSpecificScore.physics.kinematics.nScore
      );
    } else {
      this.objSpecificScore.physics.kinematics.nScore = nOrFnScore;
    }
    this.notifyAll();
    this.updateDatabase();
  }

  updateXP(strSubjectChallenge: 'physics', nValue: number) {
    this.objSpecificScore[strSubjectChallenge].xp += nValue;
    this.objSpecificScore[strSubjectChallenge].level =
      Math.floor(this.objSpecificScore[strSubjectChallenge].xp / 1000) + 1;
    this.notify('xp/level');
    this.updateDatabase();
  }

  updateStars(
    strSubjectChallenge: 'physics',
    strTypeChallenge: 'kinematics',
    strArrayChallenge:
      | 'arrStarAverageSpeed'
      | 'arrStarAceleration'
      | 'arrStarMRUV'
      | 'arrStartTorricelli',
    nIndex: number
  ) {
    this.objSpecificScore[strSubjectChallenge][strTypeChallenge][strArrayChallenge][nIndex] = true;
    this.notify('stars');
    this.updateDatabase();
  }

  updateAdditionalInfo(
    strSubjectChallenge: 'physics',
    strTypeChallenge: 'kinematics',
    info: string,
    value: string
  ) {
    (this.objSpecificScore[strSubjectChallenge][strTypeChallenge].additionalInfo as any)[
      info
    ] = value;
    this.notify('stars');
    this.updateDatabase();
  }

  readFromDatabase() {
    if (!this.userID) {
      return null;
    }
    return firebase
      .database()
      .ref('users/' + this.userID)
      .once('value')
      .then((snap) => {
        const dbScore = snap.val() as SpecificScore;
        if (dbScore) {
          this.objSpecificScore = R.mergeDeepLeft(this.objSpecificScore, dbScore) as SpecificScore;
          return this.notifyAll();
        }
        return this.createScoreInDatabase();
      })
      .catch((error) => console.error(error));
  }

  updateDatabase() {
    if (!this.userID) {
      return;
    }
    firebase
      .database()
      .ref('users/' + this.userID)
      .set(takeOffFunctions(this.objSpecificScore))
      .then(() => {
        this.readFromDatabase();
      })
      .catch((error) => console.error(error));
  }

  createScoreInDatabase() {
    if (!this.userID) {
      return;
    }
    return firebase
      .database()
      .ref('users/')
      .set({ [this.userID]: takeOffFunctions(this.objSpecificScore) })
      .then(() => {
        this.readFromDatabase();
      })
      .catch((error) => console.error(error));
  }

  get userID() {
    return this.user?.uid ?? '';
  }
}

const objLoadedData = createDefaultData(studentConfig.id);
const scoreManager = new ScoreManager(objLoadedData);
(window as any).score = scoreManager;

export default scoreManager;

function takeOffFunctions(objBase: any) {
  return JSON.parse(JSON.stringify(objBase));
}

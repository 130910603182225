import React, { useState } from 'react';
import moonEarth from "../../../../images/moonEarth.png";
import manApple from "../../../../images/manApple.png";

function GConstantPage(props) {
  const [step, setStep] = useState(0);
  const [isMinimized, setIsMinimized] = useState(false);

  const standardObj = [
    {
      name: 'A Constante G',
      step: 1,
      comp: (
        <>
          <h1 className="presentationItem">A Constante G</h1>

          <p className="presentationItem">
            A <b className="presentationItem">constante G</b> que aparece na expressão matemática descoberta por Newton é denominada de <b className="presentationItem">Constante de Gravitação Universal</b>.
          </p>
          <p className="presentationItem">
            O seu valor é extremamente pequeno: 0, 000 000 000 066 7 ou 6,67-¹¹.
          </p>
          <p className="presentationItem">
            Assim, observamos que da mesma forma que os planetas e astros se atraem, nós estamos sujeitos ao mesmo fenômeno, e atraímos também objetos como maçãs, livros, ou mesmo outras pessoas. Porém, como a <b className="presentationItem">constante G é tão baixa comparada às nossas massas</b>, esse fenômeno é <b className="presentationItem">praticamente imperceptível e só é observado em objetos de escala astronômica</b>.
          </p>
          <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
          <p style={{color: 'white'}}>Em situações de distância razoável:</p>
          </div>
          <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
          <div style={{textAlign: 'center'}}>
          <img width='45%' src={manApple} />
          <p style={{color: 'white'}}>Pequena massa<br></br>Pequena força de atração</p>
          </div>
          <div style={{textAlign: 'center'}}>
          <img width='45%' src={moonEarth} />
          <p style={{color: 'white'}}>Grande massa<br></br>Grande força de atração</p>
          </div>
          </div>
        </>
      ),
    },
  ];

  return (
    <div className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`}>
      {/* <div className="minMaxBar">
        <Icon className="icon" name='caret down' onClick={minimizeBar}/>
        <Icon className="icon" name='caret up' onClick={maximizeBar}/>
      </div> */}
      {!isMinimized && standardObj[step].comp}
    </div>
  );
}

export default GConstantPage;

import React from 'react';
import TipsComponent from '../../../../components/MapComponents/tipsComponent';

import tip3 from '../../../../images/Physics/Kinematics/tip3.PNG';
import tip4 from '../../../../images/Physics/Kinematics/tip4.PNG';
import tip7 from '../../../../images/Physics/Kinematics/tip7.PNG';

function Solution(props) {
  return (
    <>
      <TipsComponent
        numberTips={3}
        presentation1="Bem vindo à Oficina, um local onde conseguimos reparar algumas arestas em seu aprendizado e dar algumas dicas para você conseguir ganhar mais uma corrida!"
        presentation2="Aqui temos que trabalhar duro, então algumas dicas necessitarão de um esforço seu. Vamos lá!"
        subject="physics"
        typeScoring="kinematics"
        arrayScoring="arrStartTorricelli"
        tips={[
          {
            number: 0,
            image: tip7,
            intro1: 'Observando o acelerador você encontra uma dica',
            intro2: null,
            icon: 'cogs',
            title: 'Acelerador',
            dica1: true,
            dica1HasCondition: false,
            dica1ValidateCondition: null,
            dica1ConditionType: null,
            dica1MessageCondition: null,
            dica1ResponsePt1:
              'Pelo Acelerador você identifica qual a fórmula deve utilizar para ganhar esse desafio.',
            dica1ResponsePt2:
              'Mesmo com o desafio em duas etapas, em ambos os casos utilizaremos a Equação de Torricelli, com as variáveis da velocidade inicial, velocidade final, distância percorrida e aceleração:',
            dica1ResponsePt3: null,
            dica1ResponsePt4: null,
            dica1ResponseEquation1: String.raw`V^2 = V_{0}^2 + 2*a*\Delta S`,
            dica1ResponsePt5: null,
            dica2: false,
          },
          {
            number: 1,
            image: tip3,
            intro1: 'Que campeonato fantástico estamos fazendo, não? Lhe darei mais uma ajuda.',
            intro2: null,
            icon: 'wrench',
            title: 'Mecânico Luiz Câmera',
            dica1: true,
            dica1HasCondition: true,
            dica1ValidateCondition: 'arrStars[1] === true',
            dica1ConditionType: 'mission',
            dica1MessageCondition:
              'Essa dica está bloqueada para você! Para desbloqueá-la, realize a missão "Passando no radar".',
            dica1ResponsePt1:
              'Para o primeiro desafio temos a velocidade inicial igual à 0 e a velocidade final igual à 324 km/h, a qual podemos converter para 90 m/s.',
            dica1ResponsePt2: 'Com uma distância de 500 metros para ser percorrida, temos:',
            dica1ResponsePt3: null,
            dica1ResponsePt4: null,
            dica1ResponseEquation1: String.raw`V^2 = V_{0}^2 + 2*a*\Delta S`,
            dica1ResponseEquation2: String.raw`90^2 = 0^2 + 2*a*500`,
            dica1ResponseEquation3: String.raw`a = \frac{8100}{1000}`,
            dica1ResponseEquation4: String.raw`a = 8,1 m/s^2`,
            dica1ResponsePt5: null,
            dica1QuestionTittle:
              '(FPS-PE) Um automóvel percorre uma rodovia com velocidade inicialmente constante igual a 80 km/h. O motorista do veículo avista um radar e reduz sua velocidade para 60 km/h, percorrendo nesse trajeto uma distância igual a 20 m. O módulo da desaceleração sofrida pelo automóvel nesse percurso foi de aproximadamente:',
            dica1OptionA: '5,4 m/s²',
            dica1OptionB: '7,5 m/s²',
            dica1OptionC: '2,5 m/s²',
            dica1OptionD: '11 m/s²',
            dica1OptionE: '15 m/s²',
            dica1CorrectAnswer: 'a',
            dica1MissionName: 'Passando no radar',
            dica1Subject: 'physics',
            dica1TypeScoring: 'kinematics',
            dica1ArrayScoring: 'arrStartTorricelli',
            dica1IndexScoring: 1,
            dica1XPAward: 30,
            dica2: false,
          },
          {
            number: 2,
            image: tip4,
            intro1:
              'Você está quase se tornando um campeão da Fórmula Quest! Vamos, vou lhe ajudar.',
            intro2: null,
            icon: 'clipboard',
            title: 'Eng. Thais Condida',
            dica1: true,
            dica1HasCondition: true,
            dica1ValidateCondition: 'arrStars[2] === true',
            dica1ConditionType: 'mission',
            dica1MessageCondition:
              'Essa dica está bloqueada para você! Para desbloqueá-la, realize a missão "Passando na ponte".',
            dica1ResponsePt1:
              'Para sabermos a distância para início da frenagem, temos a velocidade inicial de 324 km/h (ou 90 m/s) e a velocidade final igual à zero.',
            dica1ResponsePt2:
              'Também sabemos que a frenagem máxima é de - 15 m/s². Assim, conseguiremos parar o carro em uma distância de:',
            dica1ResponsePt3: null,
            dica1ResponsePt4: null,
            dica1ResponseEquation1: String.raw`V^2 = V_{0}^2 + 2*a*\Delta S`,
            dica1ResponseEquation2: String.raw`0^2 = 90^2 + 2*(-15)*\Delta S`,
            dica1ResponseEquation3: String.raw`\Delta S = \frac{-8100}{2*(-15)}`,
            dica1ResponseEquation4: String.raw`\Delta S = 270m`,
            dica1ResponsePt5:
              'Assim, devemos acionar os freios faltando 270 metros para o fim da pista.',
            dica1QuestionTittle:
              '(Mackenzie-SP) Um trem de 120 m de comprimento se desloca com velocidade escalar de 20 m/s. Esse trem, ao iniciar a travessia de uma ponte, freia uniformemente, saindo completamente da mesma 10 s após com velocidade escalar de 10 m/s. O comprimento da ponte é:',
            dica1OptionA: '150 m',
            dica1OptionB: '120 m',
            dica1OptionC: '90 m',
            dica1OptionD: '60 m',
            dica1OptionE: '30 m',
            dica1CorrectAnswer: 'e',
            dica1MissionName: 'Passando na ponte',
            dica1Subject: 'physics',
            dica1TypeScoring: 'kinematics',
            dica1ArrayScoring: 'arrStartTorricelli',
            dica1IndexScoring: 2,
            dica1XPAward: 30,
            dica2: false,
          },
        ]}
      />
    </>
  );
}

export default Solution;

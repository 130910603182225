import React from 'react';
import RememberVariablesComponent from '../../../../components/MapComponents/rememberVariablesComponent';

function RememberVariables(props) {
  return (
    <RememberVariablesComponent
      paragraph1="Com o planeta natal à 100M km do sol e período orbital, descubra qual a menor distância entre este planeta e um outro, que em 40 dias percorre 15% de seu período orbital."
      paragraph2="Calcule a velocidade média de uma nave que percorreria essa distância em um prazo de 40 dias."
      paragraph3={null}
      sub={null}
    />
  );
}

export default RememberVariables;

import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

import './gravitationComp.scss';

function DirectionRocket(props) {

  return (
    <>
      {props.condition && (
        <div className="gravitation__buttonsSquare">
          <div className="gravitation__firstOption">
            <Button
              circular
              icon='arrow right'
              onClick={() => {
                props.function('Força de 8000N e direção de 0º');
              }}
            >
            </Button>
            <p>
              Força de 8000N e direção de 0º
            </p>
          </div>

          <div className="gravitation__secondOption">
            <Button
              circular
              icon='arrow right'
              onClick={() => {
                props.function('Força de 800N e direção de -45º');
              }}
            >
            </Button>
            <p>
              Força de 800N e direção de -45º
            </p>
          </div>

          <div className="gravitation__thirdOption">
            <Button
              circular
              icon='arrow right'
              onClick={() => {
                props.function('Força de 8000N e direção de -45º');
              }}
            >
            </Button>
            <p>
              Força de 8000N e direção de -45º
            </p>
          </div>

          <div className="gravitation__fourthOption">
            <Button
              circular
              icon='arrow right'
              onClick={() => {
                props.function('Força de 16000N e direção de -70º');
              }}
            >
            </Button>
            <p>
              Força de 16000N e direção de -70º
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default DirectionRocket;

import SceneComponent from 'babylonjs-hook';
import BabylonjsFunctions from './BabylonjsFunctions';

import React, { useEffect, useState } from 'react';
import ControlPanel from '../../../Babylon React Components/ControlPanel';
import ResponseButton from '../../../Babylon React Components/compResponseButton';
import ClassificationTable from '../Gerais/compClassificationTable';
import NumberLap from '../Gerais/compNumberLap';
import AskMessage from '../../../Babylon React Components/compAskMessage';
import LoadingSpinner from '../../../loadingSpinner';
import FinalPanel from '../Gerais/compFinalPanel';
import manager from '../../../../images/Physics/Kinematics/face_manager.PNG';

import gameManager from '../../../../managers/GameManager';

function VelocidadeScene(props) {
  let [onSceneReady, onRender] = BabylonjsFunctions();

  // Atualização do tempo necessária para atualização dos valores na gameManager
  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 100);
    return () => {
      clearInterval(interval);
    };
  }, []);

  let buttons = [];
  if (
    gameManager.objSpecificGame.physics.kinematics.message ===
    'Deseja trocar os pneus na segunda volta?'
  ) {
    buttons = [
      { text: 'Usar pneu Resistente', icon: 'sync' },
      { text: 'Não realizar troca', icon: 'times' },
    ];
  } else if (
    gameManager.objSpecificGame.physics.kinematics.message ===
    'Deseja trocar os pneus na terceira volta?'
  ) {
    buttons = [
      { text: 'Usar pneu Intermediário', icon: 'sync' },
      { text: 'Usar pneu Macio', icon: 'sync' },
      { text: 'Não realizar troca', icon: 'times' },
    ];
  } else if (
    gameManager.objSpecificGame.physics.kinematics.message ===
    'Deseja trocar os pneus na quarta volta?'
  ) {
    buttons = [
      { text: 'Usar pneu Ultramacio', icon: 'sync' },
      { text: 'Não realizar troca', icon: 'times' },
    ];
  }

  let nameIcon = 'play';
  let nameButton = 'Começar';
  gameManager.objSpecificGame.physics.kinematics.running === '1'
    ? (nameIcon = 'pause')
    : (nameIcon = 'play');
  gameManager.objSpecificGame.physics.kinematics.running === '1'
    ? (nameButton = 'Pausar')
    : (nameButton = 'Começar');

  return (
    <div>
      <SceneComponent antialias onSceneReady={onSceneReady} onRender={onRender} id="my-canvas" />
      <LoadingSpinner
        loading={gameManager.objSpecificGame.physics.kinematics.isLoading === 'true'}
        className="restricted"
      />
      <ControlPanel
        condition={
          gameManager.objSpecificGame.physics.kinematics.showFinalPanel !== 'true' &&
          gameManager.objSpecificGame.physics.kinematics.isLoading !== 'true'
        }
        icon={nameIcon}
        button={nameButton}
        restartFunction={() => {
          gameManager.updateInfo('physics', 'kinematics', 'running', '1');
          gameManager.updateInfo('physics', 'kinematics', 'restart', '1');
        }}
        playPauseFunction={() => {
          gameManager.updateInfo(
            'physics',
            'kinematics',
            'running',
            (1 - parseInt(gameManager.objSpecificGame.physics.kinematics.running)).toString()
          );
        }}
      />
      <ClassificationTable
        condition={
          gameManager.objSpecificGame.physics.kinematics.showFinalPanel !== 'true' &&
          gameManager.objSpecificGame.physics.kinematics.isLoading !== 'true'
        }
        numCars={6}
      />
      <FinalPanel
        condition={
          gameManager.objSpecificGame.physics.kinematics.showFinalPanel === 'true' &&
          gameManager.objSpecificGame.physics.kinematics.isLoading !== 'true'
        }
        challengeName="Sua primeira corrida"
        func={props.map}
        starIndex={4}
        xpAward={30}
      />
      <NumberLap
        condition={
          gameManager.objSpecificGame.physics.kinematics.showFinalPanel !== 'true' &&
          gameManager.objSpecificGame.physics.kinematics.isLoading !== 'true'
        }
      />
      <AskMessage
        condition={
          gameManager.objSpecificGame.physics.kinematics.showMessage === 'true' &&
          gameManager.objSpecificGame.physics.kinematics.isLoading !== 'true'
        }
        image={manager}
        name="Marcelo Cura"
        message={gameManager.objSpecificGame.physics.kinematics.message}
        position='top'
      />
      <ResponseButton
        condition={
          gameManager.objSpecificGame.physics.kinematics.showButtons === 'true' &&
          gameManager.objSpecificGame.physics.kinematics.isLoading !== 'true'
        }
        function={(value) => {
          gameManager.updateInfo('physics', 'kinematics', 'responseValue', '' + value);
        }}
        buttons={buttons}
      />
    </div>
  );
}

export default VelocidadeScene;

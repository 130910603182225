import studentConfig from '../configurations/studentConfigurations';
import BaseObserver from '../libs/classUtils/BaseObserver';

type SpecificGame = ReturnType<typeof createDefaultData>['objSpecificGame'];

interface GameManagerBaseObj {
  objSpecificGame: SpecificGame;
}

type GameCallBackFunction = (objGameManager: GameManager) => '@REMOVE' | void;

type GenericFuncUnion<V> = V | ((nValue: V) => V);

function createDefaultData(id: string) {
  return {
    objSpecificGame: {
      physics: {
        kinematics: {
          running: '0',
          restart: '0',
          dist: '0',
          carPos1: 'Guará Rubro',
          message: '',
          showMessage: 'false',
          showButtons: 'false',
          showSlider: 'false',
          responseValue: '0',
          classification: ['0', '0', '0', '0', '0', '0'],
          showFinalPanel: 'false',
          finalMessage: '',
          win: 'false',
          lapsText: 'Volta 1/5',
          timeCount: '00:00',
          isLoading: 'true',
          missionMessage: 'Inicial',
          missionIndexScoring: '0',
          missionXpAward: '0'
        },
        gravitation: {
          running: '0',
          restart: '0',
          message: '',
          showMessage: 'false',
          showButtons: 'false',
          showSlider: 'false',
          responseValue: '0',
          showFinalPanel: 'false',
          physicsInfo: '',
          finalMessage: '',
          win: 'false',
          timeCount: '00:00',
          isLoading: 'true',
          missionMessage: 'Inicial',
          missionIndexScoring: '0',
          missionXpAward: '0'
        },
      },
    },
  };
}

class GameManager extends BaseObserver<GameCallBackFunction> {
  objSpecificGame: SpecificGame;
  constructor({ objSpecificGame }: GameManagerBaseObj) {
    super();
    this.objSpecificGame = objSpecificGame;
  }

  updateInfo(strSubjectChallenge: string, strTypeChallenge: string, info: string, value: string) {
    (this.objSpecificGame as any)[strSubjectChallenge][strTypeChallenge][info] = value;
    this.notifyAll();
  }
}

const objLoadedData = createDefaultData(studentConfig.id);
const gameManager = new GameManager(objLoadedData);
(window as any).score = gameManager;

export default gameManager;

function takeOffFunctions(objBase: any) {
  return JSON.parse(JSON.stringify(objBase));
}

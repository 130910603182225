import React, { useState } from 'react';
import Anime, { anime } from 'react-anime';
import StarMessage from '../../../../components/starMessage';
import earth from "../../../../images/earthT.png";
import moon from "../../../../images/moon.png";

class OrbitAndMoonTide extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      animationStarted: false,
      animationType: 1
    }
    this.animation1 = null;
  }

  animate1() {

    this.animation1 =
      [
        anime({
          targets: '#moon-gravitation',
          // translateX: '200',
          translateX: anime.path('#orbit-gravity')('x'),
          translateY: anime.path('#orbit-gravity')('y'),
          rotate: anime.path('#orbit-gravity')('angle'),
          easing: 'linear',
          duration: 5000,
          direction: 'reverse',
          loop: true,
          autoplay: true
        }),
        anime({
          targets: '#tide',
          rotate: '1turn',
          easing: 'linear',
          duration: 5000,
          direction: 'reverse',
          loop: true,
          autoplay: true
        })
      ]
  }

  componentDidMount() {

    this.animate1()
  }

  componentWillUnmount() {

    (this.animation1 || []).forEach(item => item.pause())

  }

  render() {
    return (
      <>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <div style={{ width: '400px', height: '400px', position: 'relative'}}>
            <svg className="orbitSvgGravity" style={{ position: 'absolute', left: '0%', bottom: '0' }} id="orbit-svg-helio" height="400" width="400">
              <circle id="orbit-gravity" cx="200" cy="200" r='100' stroke="white" strokeWidth="3" fill="none" />
              <ellipse style={{transformOrigin: '50% 50%'}} id='tide' cx="200" cy="200" rx='55' ry='40' fill='dodgerblue' />
            </svg>
            <img width='30px' style={{ left: '-15px', top: '-15px', position: 'absolute' }} id="moon-gravitation" src={moon} />
            <img width='80px' style={{ left: '160px', top: '160px', position: 'absolute' }} src={earth} />
          </div>
        </div>
      </>
    )
  }
}

function TidePage(props) {
  const [step, setStep] = useState(0);
  const [isMinimized, setIsMinimized] = useState(false);

  const standardObj = [
    {
      name: 'As Marés',
      step: 1,
      comp: (
        <>
          <h1 className="presentationItem">As Marés</h1>

          <p className="presentationItem">
            Um fenômeno interessante que ocorre devido à força gravitacional é a <b className="presentationItem">ocorrênicas das marés</b>. Da mesma forma que a Terra atrai a Lua, a Lua atrai o planeta Terra e isso fica mais evidente quando observamos a massa de água que cobre a superfície terrestre. Enquanto a lua gira em torno do nosso planeta, o nível dos oceanos é alterado, já que <b className="presentationItem">o montante de água é atraído pela força gravitacional de nosso satélite</b>.
          </p>

          <OrbitAndMoonTide />

          <StarMessage
            show={true}
            message={'A Lei de Gravitação Universal'}
            subjectScoring="physics"
            typeScoring="gravitation"
            arrayScoring={'arrStarLawOfUniversalGravitation'}
            indexScoring={0}
            xpAward={30}
          />

        </>
      ),
    },
  ];

  return (
    <div className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`}>
      {/* <div className="minMaxBar">
        <Icon className="icon" name='caret down' onClick={minimizeBar}/>
        <Icon className="icon" name='caret up' onClick={maximizeBar}/>
      </div> */}
      {!isMinimized && standardObj[step].comp}
    </div>
  );
}

export default TidePage;

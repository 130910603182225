import * as BABYLON from '@babylonjs/core';
import gameManager from '../../../../managers/GameManager';

const DELTA_TIME = 1 / 30;
const TOTAL_DIST = 4500;
const EXPLOSION_DIST = 350;
const TOTAL_TIME = 15;
const V_MAX = 300 / 3.6;
const CORRECT_ACCELERATION = V_MAX / TOTAL_TIME;

function Loop(props) {
  let scene = props.scene;
  if (scene.isReady() && gameManager.objSpecificGame.physics.kinematics.isLoading === 'true') {
    gameManager.updateInfo('physics', 'kinematics', 'isLoading', 'false');
    gameManager.updateInfo('physics', 'kinematics', 'missionMessage', 'Acelerando no Deserto');
    gameManager.updateInfo('physics', 'kinematics', 'missionIndexScoring', '3');
    gameManager.updateInfo('physics', 'kinematics', 'missionXpAward', '30');
  }
  playSounds(scene);
  scene.running = Number(gameManager.objSpecificGame.physics.kinematics.running);
  scene.restart = Number(gameManager.objSpecificGame.physics.kinematics.restart);
  scene.countStart = 0;

  if (scene.running !== undefined && scene.countStart !== undefined) {
    simulationFlow(scene);
    restartFlow(scene);
  }
}

export default Loop;

/**
 * Função para rotacionar as rodas de todos os carros
 */
function rotateRodas(value, scene) {
  scene.rodas.forEach((e) => {
    e.rotation.y = value;
  });
}

/**
 * Função que reinicia a corrida
 */
function restartFlow(scene) {
  let GUI = scene.GUI;
  if (scene.restart === 3) {
    gameManager.updateInfo('physics', 'kinematics', 'running', '0');
    gameManager.updateInfo('physics', 'kinematics', 'restart', '0');

    gameManager.updateInfo('physics', 'kinematics', 'showMessage', 'false');
    gameManager.updateInfo('physics', 'kinematics', 'showSlider', 'false');
    gameManager.updateInfo('physics', 'kinematics', 'showButtons', 'false');
    gameManager.updateInfo('physics', 'kinematics', 'showFinalPanel', 'false');

    scene.responses = [0, 0];
    scene.countStart = 0;
    scene.timeLeft = TOTAL_TIME;
    (scene.cars || []).forEach((car) => {
      car.explode = false;
    });
  }
  if (scene.restart <= 2 && scene.restart > 0) {
    gameManager.updateInfo('physics', 'kinematics', 'running', '1');
    gameManager.updateInfo('physics', 'kinematics', 'restart', '' + Number(scene.restart + 1));

    scene.timeLeft = TOTAL_TIME;
    (scene.cars || []).forEach((car) => {
      car.explode = false;
      car.dist = car.initDist;
      car.position = car.initPosition;
      console.log("Init Position: " + car.initPosition);
      car.rotation = new BABYLON.Vector3(0, -Math.PI / 2, 0);
      car.vel = car.initVel;
      car.acc = car.initAcc;
      car.track = car.initTrack;
      car.desiredTrack = car.track;
      car.active = true;
      if (car.isStudent) scene.cameraOrientation = car.rotation.y;
    });
  }
}

/**
 * Função que determina o fluxo das escolhas do aluno na corrida
 */
function simulationFlow(scene) {
  updateClassificationTable(scene);
  if (scene.running === 1 && scene.countStart >= 0) {
    updateQuests(scene);
    updateFinalPanel(scene);
    if (scene.running === 1 || scene.restart !== 0) {
      scene.timeLeft = scene.timeLeft > 0 ? scene.timeLeft - DELTA_TIME : 0;
      carsMovement(scene);
      carsMovement(scene);
      cameraMovement(scene);
      createExplosion(scene);

      updateTimePanel(scene);

      rotateRodas(
        (scene.rodas[0].rotation.y -
          5 * scene.cars.find((e) => e.isStudent).vel * DELTA_TIME * 0.2) %
        (2 * Math.PI),
        scene
      );
    }
  }
}

/**
 * Atualiza a pergunta
 */
function updateQuests(scene) {
  if (scene.responses[0] === 0) {
    gameManager.updateInfo('physics', 'kinematics', 'showMessage', 'true');
    gameManager.updateInfo('physics', 'kinematics', 'showSlider', 'true');
    gameManager.updateInfo(
      'physics',
      'kinematics',
      'message',
      'Qual a aceleração do carro para começar a corrida com alguma vantagem sobre os adversários?'
    );
    if (
      Number(gameManager.objSpecificGame.physics.kinematics.responseValue) > 0 &&
      Number(gameManager.objSpecificGame.physics.kinematics.responseValue) < 10
    ) {
      scene.responses[0] = Number(gameManager.objSpecificGame.physics.kinematics.responseValue);
      gameManager.updateInfo('physics', 'kinematics', 'showMessage', 'false');
      gameManager.updateInfo('physics', 'kinematics', 'showSlider', 'false');
      gameManager.updateInfo('physics', 'kinematics', 'responseValue', '0');
    } else {
      scene.running = 0;
    }
  }
}

/**
 * Função para a movimentação da camera
 */
function cameraMovement(scene) {
  let car = scene.cars.find((e) => e.isStudent);
  if (car.isStudent) {
    scene.cameraOrientation = controllerKRotation(car.rotation.y, scene.cameraOrientation, 0.05);
    scene.camera.position = new BABYLON.Vector3(
      car.position.x - 8 * Math.cos(scene.cameraOrientation),
      car.position.y + 5,
      car.position.z + 8 * Math.sin(scene.cameraOrientation)
    );
    scene.camera.setTarget(
      new BABYLON.Vector3(
        car.position.x + 2.5 * Math.cos(scene.cameraOrientation),
        0.9,
        car.position.z - 2.5 * Math.sin(scene.cameraOrientation)
      )
    );
  }
}

/**
 * Função para criar uma explosão durante a corrida
 */
function createExplosion(scene) {
  let car = scene.cars.find((e) => e.isStudent);
  if (car.explode && car.active && car.dist - car.initDist > EXPLOSION_DIST * 0.98) {
    BABYLON.ParticleHelper.CreateAsync('explosion', scene).then((set) => {
      let pos = new BABYLON.Vector3(car.position.x, car.position.y, car.position.z);
      set.systems.forEach((s) => {
        s.emitter = pos.clone();
        s.disposeOnStop = true;
      });
      set.systems[2].worldOffset.y = pos.y;
      set.start();
    });
    car.active = false;
  }
}

/**
 * Função responsável para a movimentação de todos os carros do desafio
 */
function carsMovement(scene) {
  (scene.cars || []).forEach((car, indexCar) => {
    if (!car.active) return;
    car.acc = car.isStudent
      ? scene.responses[0] > CORRECT_ACCELERATION + 0.1
        ? 8
        : scene.responses[0]
      : car.acc;
    car.vel += (car.acc * DELTA_TIME) / 2;
    car.vel = Math.max(Math.min(car.vel, V_MAX), 0.00001);
    car.dist += (car.vel * DELTA_TIME) / 2;
    if (car.acc > CORRECT_ACCELERATION + 0.1) car.explode = true;

    scene.cars.forEach((element, elementIndex) => {
      if (elementIndex !== indexCar && car.changeTrack === 0) {
        if (
          element.dist % TOTAL_DIST > car.dist % TOTAL_DIST &&
          element.dist % TOTAL_DIST < (car.dist % TOTAL_DIST) + 25 &&
          element.track === car.track &&
          car.vel > element.vel
        ) {
          car.desiredTrack = car.track <= 0.5 ? 1 : 0;
        }
      }
    });

    car.changeTrack =
      car.desiredTrack > car.track ? 0.005 : car.desiredTrack < car.track ? -0.005 : 0;
    car.track = car.track + car.changeTrack;
    if (Math.abs(car.track - car.desiredTrack) < 0.0001) car.track = Math.round(car.track);

    let poitsCar = [];
    poitsCar.push(new BABYLON.Vector3(car.position.x, car.position.y, car.position.z));
    if (car.explode) {
      let i1 = Math.floor(
        (((car.dist - car.initDist) % EXPLOSION_DIST) * (scene.pointsExplosion.length - 1)) /
        EXPLOSION_DIST
      );
      let i1_ =
        ((((car.dist - car.initDist) % EXPLOSION_DIST) * (scene.pointsExplosion.length - 1)) /
          EXPLOSION_DIST) %
        (i1 > 0 ? i1 : 1);

      let pointxi1 =
        scene.pointsExplosion[i1].x +
        (scene.pointsExplosion[i1 + 1].x - scene.pointsExplosion[i1].x) * i1_;
      let pointzi1 =
        scene.pointsExplosion[i1].z +
        (scene.pointsExplosion[i1 + 1].z - scene.pointsExplosion[i1].z) * i1_;

      car.position.x = pointxi1;
      car.position.z = pointzi1;
    } else {
      let isNegative = car.dist < 0 ? TOTAL_DIST : 0;
      let i1 = Math.floor(
        (((isNegative + car.dist) % TOTAL_DIST) * (scene.points.length - 1)) / TOTAL_DIST
      );
      let i2 = Math.floor(
        (((isNegative + car.dist) % TOTAL_DIST) * (scene.points2.length - 1)) / TOTAL_DIST
      );
      let i1_ =
        ((((isNegative + car.dist) % TOTAL_DIST) * (scene.points.length - 1)) / TOTAL_DIST) %
        (i1 > 0 ? i1 : 1);
      let i2_ =
        ((((isNegative + car.dist) % TOTAL_DIST) * (scene.points2.length - 1)) / TOTAL_DIST) %
        (i2 > 0 ? i2 : 1);

      let pointxi1 = scene.points[i1].x + (scene.points[i1 + 1].x - scene.points[i1].x) * i1_;
      let pointzi1 = scene.points[i1].z + (scene.points[i1 + 1].z - scene.points[i1].z) * i1_;
      let pointxi2 = scene.points2[i2].x + (scene.points2[i2 + 1].x - scene.points2[i2].x) * i2_;
      let pointzi2 = scene.points2[i2].z + (scene.points2[i2 + 1].z - scene.points2[i2].z) * i2_;

      car.position.x = pointxi1 + (pointxi2 - pointxi1) * car.track;
      car.position.z = pointzi1 + (pointzi2 - pointzi1) * car.track;
    }
    poitsCar.push(new BABYLON.Vector3(car.position.x, car.position.y, car.position.z));

    if ((car.vel > 0 && scene.restart === 0) || scene.restart === undefined) {
      let normal = new BABYLON.Path3D(poitsCar).getNormals()[0];
      let theta = Math.acos(BABYLON.Vector3.Dot(new BABYLON.Vector3(0, 0, -1), normal));
      let dir = BABYLON.Vector3.Cross(new BABYLON.Vector3(0, 0, -1), normal).y;
      let newRotation = dir !== 0 ? (dir * theta) / Math.abs(dir) : -theta;

      // if(car.isStudent){
      //   let diffCar = Math.abs(car.rotation.y - newRotation) > 0.01 ? Math.sign(car.rotation.y - newRotation)*0.035 : 0;
      //   let valueVolante = scene.volante[0].rotation.y + diffCar - scene.volante[0].rotation.y*0.035;
      //   let newValueVolante = Math.abs(valueVolante) < 0.8 ? valueVolante : scene.volante[0].rotation.y;
      //   rotateVolante( newValueVolante, scene);
      // }
      car.rotation.y = controllerKRotation(car.rotation.y, newRotation, 0.1);
    }
  });
}

/**
 * Função para suavizar a rotação do carro e da câmera
 */
function controllerKRotation(value, desiredValue, k) {
  if (desiredValue < 0 && value > 0 && Math.abs(desiredValue - value) > 2) {
    value = value - 2 * Math.PI;
  }
  if (desiredValue > 0 && value < 0 && Math.abs(desiredValue - value) > 2) {
    value = value + 2 * Math.PI;
  }
  return value + k * (desiredValue - value);
}

/////////////////////////////// Métodos para atualização GUI ///////////////////////////////

/**
 * Função para atualizar os dados do painel final
 */
function updateFinalPanel(scene) {
  let car = scene.cars.find((e) => e.isStudent);
  let maxDist = scene.cars.reduce((acc, v) => {
    if (v.dist > acc) return v.dist;
    else {
      return acc;
    }
  }, 0);

  let finish;
  let win;
  if (car.dist > TOTAL_DIST * 1.025 || (scene.timeLeft <= 0 && car.dist < maxDist)) {
    let orderedCars = scene.cars.sort((a, b) => (a.dist > b.dist ? -1 : 1));
    let studentPos = orderedCars.indexOf(scene.cars.find((e) => e.isStudent)) + 1;

    if (studentPos === 1) {
      finish = 'Parabéns! Você conseguiu um início de corrida fantástico!';
      win = 'true';
    } else {
      finish =
        'Que pena! A aceleração do carro não foi suficiente para ter uma vantagem sobre os adversários.';
      win = 'false';
    }
  }
  if (car.explode && !car.active) {
    finish = 'Que pena! Você acelerou demais o carro e escorregou na pista.';
    win = 'false';
  }

  if (finish) {
    gameManager.updateInfo('physics', 'kinematics', 'showFinalPanel', 'true');
    gameManager.updateInfo('physics', 'kinematics', 'finalMessage', finish);
    gameManager.updateInfo('physics', 'kinematics', 'win', win);
    scene.running = 0;
  }
}

/**
 * Função para atualizar o painel de tempo
 */
function updateTimePanel(scene) {
  let time = Math.floor(TOTAL_TIME - scene.timeLeft);
  let seconds = time % 60 > 9 ? (time % 60).toFixed(0) : '0' + (time % 60).toFixed(0);
  let minutes = Math.floor(time / 60) > 9 ? Math.floor(time / 60) : '0' + Math.floor(time / 60);
  gameManager.updateInfo('physics', 'kinematics', 'timeCount', minutes + ':' + seconds);
}

/**
 * Função para atualizar os dados da tabela de classificação
 */
function updateClassificationTable(scene) {
  let listCars = [
    { name: 'Arara Real' },
    { name: 'Jacaré Impiedoso' },
    { name: 'Boto Veloz' },
    { name: 'Falcão Negro' },
    { name: 'Guará Rubro' },
    { name: 'Capivara Furiosa' },
  ];

  let dists = listCars.map((car) => {
    let foundCar = scene.cars.find((e) => e.equipe == car.name);
    return foundCar && foundCar.equipe ? foundCar.dist : -1;
  });

  gameManager.updateInfo('physics', 'kinematics', 'classification', dists);
}

function playSounds(scene) {
  if (Number(gameManager.objSpecificGame.physics.kinematics.running) === 1 && scene.running === 0) {
    if (!scene.soundRaceMusic.isPlaying) {
      scene.soundRaceMusic.play();
    }
  } else if (
    Number(gameManager.objSpecificGame.physics.kinematics.running) === 0 &&
    scene.running === 1
  ) {
    scene.soundRaceMusic.stop();
  }
}

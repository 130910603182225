import React, { useEffect, useState } from 'react';
import arrows from '../../../../images/spinningArrows.png';
import anime from 'animejs/lib/anime.es.js';
import scoreManager from '../../../../managers/ScoreManager.ts';
import StarMessage from '../../../../components/starMessage';
import { MathComponent } from 'mathjax-react';

function UnityConversion(props) {
  const [isMinimized, setIsMinimized] = useState(false);


  function spinArrows() {
    anime({
      targets: '#spinning-arrows',
      rotate: '-360',
      loop: true,
      duration: 1000,
      easing: 'easeOutQuart',
    });
  }

  useEffect(() => {
    spinArrows();
    // scoreManager.updateStars("physics", "kinematics", "arrStarAverageSpeed", 0);
    // scoreManager.updateXP("physics", 30);
  }, []);

  return (
    <div
      className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`}
      key={Math.random()}
    >
      {/* <div className="minMaxBar">
                <Icon className="icon" name='caret down' onClick={minimizeBar}/>
                <Icon className="icon" name='caret up' onClick={maximizeBar}/>
            </div> */}
      {!isMinimized && (
        <>
          <h1 className="presentationItem">Conversão de unidades</h1>
          <p className="presentationItem">
            Para se medir a velocidade, as duas unidades mais utilizadas são o km/h e o m/s, essa
            última o padrão no SI. Mas como entender a relação entre elas?
            <br></br>
            Em um km, temos o equivalente a 1000 metros, enquanto em uma hora temos 60 minutos, ou
            3600 segundos. Tendo esses valores em mente, fica fácil fazer a conversão:
            <br></br>
            <MathComponent
              tex={String.raw`1*\frac{km}{h} = \frac{1000 m}{3600 s} = \frac{1}{3.6}*\frac{m}{s}`}
            />
            <br></br>
            <MathComponent
              tex={String.raw`1*\frac{m}{s} = \frac{\frac{1 km}{1000}}{\frac{1 h}{3600}} = \frac{3600 km}{1000 h} = 3.6*\frac{km}{h}`}
            />
            <br></br>
            Assim, percebemos que para converter{' '}
            <b className="presentationItem">km/h para m/s, basta dividir o seu valor por 3.6</b>. Já
            para passar de{' '}
            <b className="presentationItem">
              m/s para km/h, precisamos multiplicar o valor por 3.6
            </b>
            .
          </p>
          <div className="presentationItem unityConversionBoard">
            <span className="mainFont">20 KM/H</span>
            <div className="unityConversionColumn">
              <span className="secondaryFont">/3,6</span>
              <img
                id="spinning-arrows"
                width="20%"
                style={{ padding: '2% 0' }}
                src={arrows}
                alt="arrows"
              />
              <span className="secondaryFont">*3,6</span>
            </div>
            <span className="mainFont">72 M/S</span>
          </div>

          <StarMessage
            show={true}
            message={'Descubra novos conhecimentos'}
            subjectScoring="physics"
            typeScoring="kinematics"
            arrayScoring={'arrStarAverageSpeed'}
            indexScoring={0}
            xpAward={30}
          />
        </>
      )}
    </div>
  );
}

export default UnityConversion;

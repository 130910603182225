import * as BABYLON from '@babylonjs/core';
import '@babylonjs/loaders';
import { SkyMaterial } from '@babylonjs/materials/sky';
import '@babylonjs/core/Meshes/thinInstanceMesh';

import { AdvancedDynamicTexture } from '@babylonjs/gui/2D';
import { VPanel, HPanel } from '../../../Babylon GUI/LibDivs';
import * as Presets from '../../../Babylon GUI/Presets';

function Scenario(scene) {
  BABYLON.SceneLoader.ImportMesh(
    '',
    './scenes/Física/Cinemática/Velocidade/challenge01Track_B.glb',
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let terrain = meshes[0];
      animationGroups.forEach((x) => {
        x.start(true);
      });
      terrain.setAbsolutePosition = new BABYLON.Vector3(0, 0, 0);
      terrain.position = new BABYLON.Vector3(28, 0, 56); // para a track final
      terrain.rotation = new BABYLON.Vector3(0, Math.PI, 0);
      terrain.scaling = new BABYLON.Vector3(1, 1, -1);
    }
  );

  var box;
  scene.skyMaterial = new SkyMaterial('sky', scene);
  scene.skyMaterial.turbidity = 20;
  scene.skyMaterial.luminance = 1;
  scene.skyMaterial.inclination = scene.sunInclination;
  scene.skyMaterial.azimuth = -0.23;
  scene.skyMaterial.rayleigh = 3;
  var box = BABYLON.Mesh.CreateBox('SkyBox', 5000, scene, false, BABYLON.Mesh.BACKSIDE);
  box.material = scene.skyMaterial;
  box.position = new BABYLON.Vector3(0, 100, 0);

  scene.createDefaultEnvironment({ skyboxSize: 5000 });

  // BABYLON.SceneLoader.ImportMesh(
  //   '',
  //   './scenes/Física/Cinemática/Velocidade/pinetree.glb',
  //   '',
  //   scene,
  //   function (meshes, particleSystems, skeleton, animationGroups) {
  //     let tree = BABYLON.Mesh.MergeMeshes([meshes[1]], true, true, undefined, false, true);
  //     tree.alwaysSelectAsActiveMesh = true;
  //     tree.position = new BABYLON.Vector3(0, 0, 0);
  //     let positions = [
  //       { x: -35, z: 20 },
  //       { x: 80, z: 15 },
  //       { x: 110, z: 66 },
  //     ];
  //     var bufferMatrices = new Float32Array(16 * positions.length);
  //     positions.forEach((e, index) => {
  //       let scale = 1; //0.4 + 0.6 * Math.random();
  //       var matrix = BABYLON.Matrix.Compose(
  //         new BABYLON.Vector3(scale, scale, scale),
  //         new BABYLON.Quaternion.RotationYawPitchRoll(0, 0, 0),
  //         new BABYLON.Vector3(e.x, 0, e.z)
  //       );
  //       matrix.copyToArray(bufferMatrices, 16 * index);
  //     });
  //     tree.thinInstanceSetBuffer('matrix', bufferMatrices, 16);

  //     // let advancedTexture = new AdvancedDynamicTexture.CreateFullscreenUI('UI', true, scene);
  //     // scene.position = new VPanel({
  //     //   ...Presets.background,
  //     //   scene: scene,
  //     //   width: 0.15,
  //     //   height: 0.15,
  //     // });
  //     // advancedTexture.addControl(scene.position.panel);
  //     // scene.position.addText({ ...Presets.mText, text: 'teste' });
  //     // scene.teste = tree;
  //     // var utilLayer = new BABYLON.UtilityLayerRenderer(scene);
  //     // var gizmo = new BABYLON.PositionGizmo(utilLayer);
  //     // gizmo.attachedMesh = scene.teste;
  //     // gizmo.updateGizmoRotationToMatchAttachedMesh = false;
  //     // gizmo.updateGizmoPositionToMatchAttachedMesh = true;
  //   }
  // );

  addGrandstand(scene, 150, 50, Math.PI / 2, 'North');
  addGrandstand(scene, 150, 100, Math.PI / 2, 'North');
  addGrandstand(scene, 20, -25, Math.PI, 'East');

  /**
   * Arquibancada 2
   */

  // BABYLON.SceneLoader.ImportMesh(
  //   '',
  //   './scenes/Física/Cinemática/Aceleração/grandstand.glb',
  //   '',
  //   scene,
  //   function (meshes, particleSystems, skeleton, animationGroups) {
  //     let arquibancada = meshes[0];
  //     arquibancada.rotation = new BABYLON.Vector3(0, Math.PI / 2, 0);
  //     arquibancada.position = new BABYLON.Vector3(150, 0, 100);
  //     arquibancada.scaling = new BABYLON.Vector3(0.5, 0.5, -0.5);
  //   }
  // );

  // /**
  //  * Arquibancada 3
  //  */

  // BABYLON.SceneLoader.ImportMesh(
  //   '',
  //   './scenes/Física/Cinemática/Aceleração/grandstand.glb',
  //   '',
  //   scene,
  //   function (meshes, particleSystems, skeleton, animationGroups) {
  //     let arquibancada = meshes[0];
  //     arquibancada.rotation = new BABYLON.Vector3(0, Math.PI, 0);
  //     arquibancada.position = new BABYLON.Vector3(20, 0, -25);
  //     arquibancada.scaling = new BABYLON.Vector3(0.5, 0.5, -0.5);
  //   }
  // );
}

function addGrandstand(scene, posX, posZ, orientation, localization) {
  /**
   * Arquibancada 1
   */

  BABYLON.SceneLoader.ImportMesh(
    '',
    './scenes/Física/Cinemática/Aceleração/grandstand.glb',
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let arquibancada = meshes[0];
      arquibancada.rotation = new BABYLON.Vector3(0, orientation, 0);
      arquibancada.position = new BABYLON.Vector3(posX, 0, posZ);
      arquibancada.scaling = new BABYLON.Vector3(0.5, 0.5, -0.5);
    }
  );

  BABYLON.SceneLoader.ImportMesh(
    '',
    './scenes/Física/Cinemática/Aceleração/yellowEgg.glb',
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let egg = BABYLON.Mesh.MergeMeshes(
        meshes.filter((e, index) => index > 0),
        true,
        true,
        undefined,
        false,
        true
      );
      egg.rotation = new BABYLON.Vector3(0, orientation, 0);
      animationGroups.forEach((x) => {
        x.start(true);
      });
      egg.alwaysSelectAsActiveMesh = true;
      if (localization === 'North') {
        egg.position = new BABYLON.Vector3(posX + 1, 4.8, posZ - 22.2);
      } else {
        egg.position = new BABYLON.Vector3(posX - 22.5, 4.8, posZ - 2);
      }

      egg.scaling = new BABYLON.Vector3(0.5, 0.5, 0.5);

      var bufferMatrices = new Float32Array(16 * 7 * 4);
      let cont = 0;
      for (var x = 0; x < 7; x++) {
        for (var y = 0; y < 4; y++) {
          var matrix = BABYLON.Matrix.Translation(-2.71 * x, -1.85 * y, -4.22 * y);
          matrix.copyToArray(bufferMatrices, cont);
          cont += 16;
        }
      }
      egg.thinInstanceSetBuffer('matrix', bufferMatrices, 16);
    }
  );

  BABYLON.SceneLoader.ImportMesh(
    '',
    './scenes/Física/Cinemática/Aceleração/greenEgg.glb',
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let egg = BABYLON.Mesh.MergeMeshes(
        meshes.filter((e, index) => index > 0),
        true,
        true,
        undefined,
        false,
        true
      );
      egg.rotation = new BABYLON.Vector3(0, orientation, 0);
      animationGroups.forEach((x) => {
        x.start(true);
      });
      egg.alwaysSelectAsActiveMesh = true;

      if (localization === 'North') {
        egg.position = new BABYLON.Vector3(posX + 1, 4.8, posZ - 10.23);
      } else {
        egg.position = new BABYLON.Vector3(posX - 10.23, 4.8, posZ - 2);
      }
      egg.scaling = new BABYLON.Vector3(0.5, 0.5, 0.5);

      var bufferMatrices = new Float32Array(16 * 7 * 4);
      let cont = 0;
      for (var x = 0; x < 7; x++) {
        for (var y = 0; y < 4; y++) {
          var matrix = BABYLON.Matrix.Translation(-2.71 * x, -1.85 * y, -4.22 * y);
          matrix.copyToArray(bufferMatrices, cont);
          cont += 16;
        }
      }
      egg.thinInstanceSetBuffer('matrix', bufferMatrices, 16);
    }
  );

  BABYLON.SceneLoader.ImportMesh(
    '',
    './scenes/Física/Cinemática/Aceleração/redEgg.glb',
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let egg = BABYLON.Mesh.MergeMeshes(
        meshes.filter((e, index) => index > 0),
        true,
        true,
        undefined,
        false,
        true
      );
      egg.rotation = new BABYLON.Vector3(0, orientation, 0);
      animationGroups.forEach((x) => {
        x.start(true);
      });
      egg.alwaysSelectAsActiveMesh = true;

      if (localization === 'North') {
        egg.position = new BABYLON.Vector3(posX + 1, 4.8, posZ + 2.23);
      } else {
        egg.position = new BABYLON.Vector3(posX + 2.23, 4.8, posZ - 2);
      }

      egg.scaling = new BABYLON.Vector3(0.5, 0.5, 0.5);

      var bufferMatrices = new Float32Array(16 * 7 * 4);
      let cont = 0;
      for (var x = 0; x < 7; x++) {
        for (var y = 0; y < 4; y++) {
          var matrix = BABYLON.Matrix.Translation(-2.71 * x, -1.85 * y, -4.22 * y);
          matrix.copyToArray(bufferMatrices, cont);
          cont += 16;
        }
      }
      egg.thinInstanceSetBuffer('matrix', bufferMatrices, 16);
    }
  );

  BABYLON.SceneLoader.ImportMesh(
    '',
    './scenes/Física/Cinemática/Aceleração/blueEgg.glb',
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let egg = BABYLON.Mesh.MergeMeshes(
        meshes.filter((e, index) => index > 0),
        true,
        true,
        undefined,
        false,
        true
      );
      egg.rotation = new BABYLON.Vector3(0, orientation, 0);
      animationGroups.forEach((x) => {
        x.start(true);
      });
      egg.alwaysSelectAsActiveMesh = true;

      if (localization === 'North') {
        egg.position = new BABYLON.Vector3(posX + 1, 4.8, posZ + 13.73);
      } else {
        egg.position = new BABYLON.Vector3(posX + 14.23, 4.8, posZ - 2);
      }

      egg.scaling = new BABYLON.Vector3(0.5, 0.5, 0.5);

      var bufferMatrices = new Float32Array(16 * 7 * 4);
      let cont = 0;
      for (var x = 0; x < 7; x++) {
        for (var y = 0; y < 4; y++) {
          var matrix = BABYLON.Matrix.Translation(-2.71 * x, -1.85 * y, -4.22 * y);
          matrix.copyToArray(bufferMatrices, cont);
          cont += 16;
        }
      }
      egg.thinInstanceSetBuffer('matrix', bufferMatrices, 16);
    }
  );
}

export default Scenario;

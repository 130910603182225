/* eslint-disable react/display-name */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import PlayDialogue from '../../../Common/compPlayDialogue';
import species_octa from '../../../../images/Physics/Gravitation/species_octa.PNG';
import species_boter from '../../../../images/Physics/Gravitation/species_boter.PNG';
import species_levitoner from '../../../../images/Physics/Gravitation/species_levitoner.PNG';
import species_emierres from '../../../../images/Physics/Gravitation/species_emierres.PNG';

function ChallengeExplanation(props) {
  const [animationStep, setAnimationStep] = useState(0);
  const additionalInfo = JSON.parse(sessionStorage.getItem('additional_info'));

  function getImage() {
    switch (additionalInfo.specie) {
      case 'Octawabeter':
        return species_octa;
      case 'Olhoboter':
        return species_boter;
      case 'Levitoner':
        return species_levitoner;
      case 'Emierres':
        return species_emierres;
      default:
        break;
    }
  }

  useEffect(() => {
    if (animationStep === 11) {
      props.setNextItem('Tempo');
    }
  }, [animationStep]);

  const arrPage = [
    <PlayDialogue
      key={0}
      buttons="Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text={
        'Estamos chegando na órbita do planeta Quest e precisamos relizar o pouso de nossa nave com a maior segurança possível.'
      }
      image={getImage()}
      extraContent={''}
    />,

    <PlayDialogue
      key={1}
      buttons="Anterior/Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text="Nossos engenheiros calcularam que o planeta tem uma massa de 6E24 kg e raio de 6000 km, além de sabermos que a Constante de Gravitação Universal tem um valor de 6,67E-11. Também sabemos que nossa nave pesa 3000000 kg."
      image={getImage()}
      extraContent={''}
    />,

    <PlayDialogue
      key={2}
      buttons="Anterior/Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text="Ao entrar na atmosfera do planeta, precisamos acionar os motores com uma força igual ao peso da nave na superfície do planeta Quest. Dessa forma, garantimos um pouso suave, sem grandes oscilações e impactos indesejados."
      image={getImage()}
      extraContent={''}
    />,

    <PlayDialogue
      key={5}
      buttons="Anterior/Começar Desafio"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      setChallengeStarted={props.setChallengeStarted}
      setIsChallengeOpened={props.setIsChallengeOpened}
      text="Está preparado? Sem medo, guie nossa nave e nos ajude a pousar nesse lindo planeta!"
      image={getImage()}
      extraContent={''}
      challenge = {props.challenge}
    />,
  ];

  return arrPage[animationStep];
}

export default ChallengeExplanation;

import React from 'react';
import { Button, Icon, Image, List, Comment } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import './kinematicsComp.scss';
import iconBlack from '../../../../images/Physics/Kinematics/iconBlack.PNG';
import iconBlue from '../../../../images/Physics/Kinematics/iconBlue.PNG';
import iconGreen from '../../../../images/Physics/Kinematics/iconGreen.PNG';
import iconPink from '../../../../images/Physics/Kinematics/iconPink.PNG';
import iconRed from '../../../../images/Physics/Kinematics/iconRed.PNG';
import iconYellow from '../../../../images/Physics/Kinematics/iconYellow.PNG';
import manager from '../../../../images/Physics/Kinematics/face_manager.PNG';
import flagFormulaQuest from '../../../../images/Physics/Kinematics/flagFormulaQuest.png';
import StarMessage from '../../../starMessage';

import gameManager from '../../../../managers/GameManager';

function FinalPanel(props) {
  const SIZEICONS = '6.5%';
  const fontColor = {
    color: 'white',
    fontSize: '0.8vw',
  };

  let history = useHistory();

  function sortCars() {
    let dist = gameManager.objSpecificGame.physics.kinematics.classification.map((e) => Number(e));
    let listCars = [
      { name: 'Arara Real', dist: dist[0], logo: iconBlue },
      { name: 'Jacaré Impiedoso', dist: dist[1], logo: iconGreen },
      { name: 'Boto Veloz', dist: dist[2], logo: iconPink },
      { name: 'Falcão Negro', dist: dist[3], logo: iconBlack },
      { name: 'Guará Rubro', dist: dist[4], logo: iconRed },
      { name: 'Capivara Furiosa', dist: dist[5], logo: iconYellow },
    ];
    listCars.sort((a, b) => {
      return a.dist > b.dist ? -1 : 1;
    });

    return (
      <>
        <div className="kinematics__finalPanel">
          <Image src={flagFormulaQuest} className="kinematics__finalPanel__image" />

          <p className="kinematics__finalPanel__classificationTable">
            Resultado Final:
            <br></br>
            <List celled animated>
              <List.Item>
                <List.Content floated="right">
                  {<Icon inverted color="yellow" name="trophy" />}
                </List.Content>
                <Image src={listCars[0].logo} width={SIZEICONS} rounded />
                <List.Content>{listCars[0].name}</List.Content>
              </List.Item>
            </List>
            <List celled animated>
              <List.Item>
                <List.Content floated="right">
                  {<Icon inverted color="grey" name="trophy" />}
                </List.Content>
                <Image src={listCars[1].logo} width={SIZEICONS} rounded />
                <List.Content>{listCars[1].name}</List.Content>
              </List.Item>
            </List>
            <List celled animated>
              <List.Item>
                <List.Content floated="right">
                  {<Icon inverted color="brown" name="trophy" />}
                </List.Content>
                <Image src={listCars[2].logo} width={SIZEICONS} rounded />
                <List.Content>{listCars[2].name}</List.Content>
              </List.Item>
            </List>
          </p>

          <div className="kinematics__finalPanel__message">
            <Comment.Group size="big">
              <Comment>
                <Comment.Avatar as="a" src={manager} />
                <Comment.Content>
                  <Comment.Author style={fontColor}>MARCELO CURA:</Comment.Author>
                  <Comment.Text style={fontColor}>
                    {' '}
                    {gameManager.objSpecificGame.physics.kinematics.finalMessage}
                  </Comment.Text>
                </Comment.Content>
              </Comment>
            </Comment.Group>
          </div>

          <div className="kinematics__finalPanel__messageStar">
            {gameManager.objSpecificGame.physics.kinematics.win === 'true' && (
              <List divided relaxed>
                <List.Item>
                  <List.Icon name="star" size="large" verticalAlign="middle" color="yellow" />
                  <List.Content>
                    <p style={fontColor}>
                      Estrela Desbloqueada:
                      <br></br>
                      Parabéns, você conquistou a estrela do desafio '{props.challengeName}'
                    </p>
                  </List.Content>
                </List.Item>
              </List>
            )}
          </div>

          {gameManager.objSpecificGame.physics.kinematics.win === 'true' && (
            <div className="kinematics__finalPanel__button">
              <Button
                icon
                labelPosition="left"
                onClick={() => {
                  // window.location.reload(false);
                  history.push(props.func);
                  window.location.reload(false);
                }}
              >
                <Icon name="play" />
                Finalizar desafio
              </Button>
            </div>
          )}

          {gameManager.objSpecificGame.physics.kinematics.win === 'false' && (
            <div className="kinematics__finalPanel__button">
              <Button
                icon
                labelPosition="left"
                onClick={() => {
                  gameManager.updateInfo('physics', 'kinematics', 'running', '1');
                  gameManager.updateInfo('physics', 'kinematics', 'restart', '1');
                }}
              >
                <Icon name="play" />
                Reiniciar desafio
              </Button>
            </div>
          )}

          {gameManager.objSpecificGame.physics.kinematics.win === 'true' && (
            <StarMessage
              show={true}
              message={gameManager.objSpecificGame.physics.kinematics.missionMessage}
              subjectScoring={'physics'}
              typeScoring={'kinematics'}
              indexScoring={props.starIndex}
              xpAward={props.xpAward}
            />
          )}
        </div>
      </>
    );
  }

  return <>{props.condition && sortCars()}</>;
}

export default FinalPanel;

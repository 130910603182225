import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import LoadingSpinner from '../components/loadingSpinner';
import Navbar from '../components/navbar';
import SvgsPresentation from './Common/svgsPresentation';
import CompSubjetPresentation from './Common/compSubjectPresentation.jsx';
import api from '../services/api';

function ModulesPresentation() {
  const { id } = useParams();
  const [moduleInfo, setModuleInfo] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      await api
        .post('getModulePresentationInfo', {
          module_id: id,
          client_id: localStorage.getItem('client_id'),
        })
        .then((res) => {
          setModuleInfo({
            title: res.data.title,
            text1: res.data.text1,
            text2: res.data.text2,
            text3: res.data.text3,
            text4: res.data.text4,
            image: res.data.image,
            background: res.data.background,
            color: res.data.color,
            style_image: res.data.style_image,
          });
        })
        .catch((err) => {
          console.log(err.message);
        });
    };
    fetchData();
  }, []);

  return (
    <>
      {/* {!moduleInfo.title && <LoadingSpinner loading={true} className="restricted" />} */}
      {moduleInfo.title && (
        <div>
          <Navbar />
          <CompSubjetPresentation
            title={moduleInfo.title}
            text1={moduleInfo.text1}
            text2={moduleInfo.text2}
            text3={moduleInfo.text3}
            text4={moduleInfo.text4}
            image1={<SvgsPresentation id={id} />}
            image2={moduleInfo.image}
            route={'/mapa-de-fases/' + id}
            background={moduleInfo.background}
            color={moduleInfo.color}
            styleImg2={moduleInfo.style_image}
          />
        </div>
      )}
    </>
  );
}

export default ModulesPresentation;

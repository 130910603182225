import React from 'react';

import * as BABYLON from '@babylonjs/core';
import SceneComponent from 'babylonjs-hook';
import '@babylonjs/loaders';

import '../App.css';

import PointsTraj from './PointsTraj';

function SelectCar(props) {
  // let time = 0;
  // let object_;

  let points = [];
  let normals;
  let path;
  let color;
  let equipName;
  let path_name_button;

  const onSceneReady = (scene) => {
    //camera 1
    let camera1 = new BABYLON.ArcRotateCamera(
      'Camera1',
      (-4 * Math.PI) / 8,
      (5 * Math.PI) / 8,
      30,
      new BABYLON.Vector3(0, 5, 0),
      scene
    );
    camera1.lowerBetaLimit = (Math.PI / 2) * 0.1;
    camera1.upperBetaLimit = (Math.PI / 2) * 0.9;
    camera1.lowerRadiusLimit = 15;
    camera1.upperRadiusLimit = 15;
    camera1.target = new BABYLON.Vector3(0, 5, 0);

    // Two Viewports
    //camera1.viewport = new BABYLON.Viewport(0, 0, 0.2, 1);

    scene.activeCameras.push(camera1);

    // camera.useAutoRotationBehavior = true;
    // camera.autoRotationBehavior.idleRotationWaitTime  = 0;
    // camera.autoRotationBehavior.idleRotationSpeed = 0.15;
    // camera.autoRotationBehavior.idleRotationSpinupTime = 0.01;

    const canvas = scene.getEngine().getRenderingCanvas();
    camera1.attachControl(canvas, true);

    let light = new BABYLON.SpotLight(
      '*spot00',
      new BABYLON.Vector3(50, 50, 0),
      new BABYLON.Vector3(-1, -1, 0),
      5,
      30,
      scene
    );
    light.intensity = 0.9;
    let light1 = new BABYLON.HemisphericLight('light', new BABYLON.Vector3(0, 1, 0), scene);
    light1.position = new BABYLON.Vector3(0, 5, 0);
    light1.intensity = 0.5;

    let light2 = new BABYLON.DirectionalLight(
      'DirectionalLight',
      new BABYLON.Vector3(0, 1, 0),
      scene
    );
    light2.intensity = 0.02;
    light2.diffuse = new BABYLON.Color3(204, 153, 255);

    points = [];
    for (var i = 0; i < PointsTraj.length; i++) {
      points.push(
        new BABYLON.Vector3(-PointsTraj[i][1] * 0.6 + 190, 1, -PointsTraj[i][0] * 0.585 + 230)
      );
    }

    var path3d = new BABYLON.Path3D(points);
    normals = path3d.getNormals();

    switch (props.car) {
      case 'red':
        path = './scenes/CarRed.glb';
        color = 'red';
        equipName = 'Guará Rubro';
        path_name_button = 'textures/selectCar_red.PNG';
        // equipDescription = "A equipe Guará Rubro tem uma mentalidade de CORAGEM, não tendo medo de arriscar com confiança para vencer e conquistar seus objetivos";
        break;
      case 'black':
        path = './scenes/CarBlack.glb';
        color = 'black';
        equipName = 'Falcão Negro';
        path_name_button = 'textures/selectCar_black.PNG';
        // equipDescription = "A equipe do Falcão Negro tem CONFIANÇA que irá conseguir vencer, de um jeito ou de outro. Seus membros sempre encaram o que vem pela frente com positividade";
        break;
      case 'green':
        path = './scenes/CarGreen.glb';
        color = 'green';
        equipName = 'Jacaré Impiedoso';
        path_name_button = 'textures/selectCar_green.PNG';
        // equipDescription = "Formada pelos pilotos mais inteligentes e preciso escrever";
        break;
      case 'blue':
        path = './scenes/CarBlue.glb';
        color = 'blue';
        equipName = 'Arara Real';
        path_name_button = 'textures/selectCar_blue.PNG';
        // equipDescription = "'Tudo que vale a pena é difícil de conquistar', dizem os membros da Arara Real. Eles não desistem ao se deparar com obstáculos, sendo a DETERMINAÇÃO sua principal característica";
        break;
      case 'yellow':
        path = './scenes/Caryellow.glb';
        color = 'yellow';
        equipName = 'Capivara Furiosa';
        path_name_button = 'textures/selectCar_yellow.PNG';
        // equipDescription = "Os membros de Mico Leão d'Ouro acreditam que as coisas levam tempo para aconteceram, sendo necessário PERSISTÊNCIA. Se têm vontade de algo, continuam tentando, uma hora vai acontecer";
        break;
      case 'pink':
        path = './scenes/CarPink.glb';
        color = 'pink';
        equipName = 'Boto Veloz';
        path_name_button = 'textures/selectCar_pink.PNG';
        // equipDescription = "É na CRIATIVIDADE que a equipe de Boto Veloz se diferencia. Seus membros sempre mostram grandes ideias e inovam ao buscar soluções para seus desafios";
        break;
      default:
        path = './scenes/CarRed.glb';
    }

    BABYLON.SceneLoader.ImportMesh('', path, '', scene, function () {
      //car1 = meshes[0];
      let car = scene.getMeshByName('__root__');
      car.position = new BABYLON.Vector3(0, 3, 0);
      car.rotation = new BABYLON.Vector3(0, (2 * Math.PI) / 8, 0);
    });

    camera1.useAutoRotationBehavior = true;
    camera1.autoRotationBehavior.idleRotationWaitTime = 0;
    camera1.autoRotationBehavior.idleRotationSpeed = -0.55;
    camera1.autoRotationBehavior.idleRotationSpinupTime = 0.01;
    //cannon1.position = new BABYLON.Vector3(0, 10.44, 0);

    // var advancedTexture = new AdvancedDynamicTexture.CreateFullscreenUI("UI");

    // var panel = new StackPanel();
    // var panel = new Rectangle();
    // panel.height = "55%";
    // panel.width = "90%";
    // //panel.paddingRight = "5px";
    // panel.paddingBottom = "2%";
    // panel.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER;
    // panel.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    // //panel.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    // panel.cornerRadius = 20;
    // panel.color = "white";
    // panel.thickness = 2;
    // panel.background = "black";
    // panel.alpha = 0.8;

    // advancedTexture.addControl(panel);
    // panel.isVisible = true;

    // var text = new TextBlock();
    // text.text = equipDescription;
    // text.color = "white";
    // text.fontSize = 14;
    // text.top = -30;
    // text.paddingLeft = "3%";
    // text.paddingRight = "3%";
    // text.textWrapping = true;
    // panel.addControl(text);

    // var button = Button.CreateImageOnlyButton("button", path_name_button);
    // button.width = "80%";
    // button.height = "8%";
    // button.color = "white";
    // button.background = color;
    // button.top = 220;
    // //panel.addControl(button);
    // advancedTexture.addControl(button);

    scene.clearColor = new BABYLON.Color3.FromHexString('#1B1818');
    //var layer = new BABYLON.Layer('','https://i.imgur.com/mBBxGJH.jpg', scene, true);
  };

  /**
   * Will run on every frame render.  We are spinning the skull on y-axis.
   */
  const onRender = () => {};

  return (
    <div id="scene">
      <SceneComponent antialias onSceneReady={onSceneReady} onRender={onRender} id="selectCar" />
    </div>
  );
}

export default SelectCar;

import React from 'react';
import Navbar from '../../components/navbar';
import { Button } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import './training.scss';
import mrQuestChar from '../../images/Logos/mrQuestChar.png';


function Training() {

  let history = useHistory();

  return (
    <div>
        <Navbar />
        <div className='trainingPresentation__mainBckgd' >
            <div className='trainingPresentation__presentationContainer' >
                <div className='trainingPresentation__textContainer' >
                    <h1 className='trainingPresentation__textContainer__title'>Treinamento</h1>  
                    <br></br>
                    <p className='trainingPresentation__textContainer__paragraph'>Bem-vindo à nossa seção de Treinamento!</p>
                    <p className='trainingPresentation__textContainer__paragraph'>Aqui, você encontrará variados desafios, onde estará treinando a teoria de várias disciplinas enquanto acumula pontos de experiência!</p>
                    <img className="trainingPresentation__mrQuestChar" src={mrQuestChar} alt="mrQuestChar" />
                    <div className="presentationItem arrowBtnContainer">
                      <Button className='trainingPresentation__textContainer__buttonStart'
                        onClick={()=>{history.push('/treinamento')}}
                      >
                        Começar Treinamento
                      </Button>
                      <Button className='trainingPresentation__textContainer__buttonBack'
                        onClick={()=>{history.push('/disciplinas')}}
                      >
                        Retornar
                      </Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Training;

import React, { useState, useEffect, Component } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import Anime, { anime } from 'react-anime';
import grass from "../../../../images/grassGravitation.png";
import apple from "../../../../images/appleGravitation.png";
import earth from "../../../../images/earth.png";
import moon from "../../../../images/moonSpeedForce.png";
import appleForce from "../../../../images/appleForce.png";

// import anime from "animejs/lib/anime.es.js";

class OrbitAndMoonMoving extends Component{

  constructor(props){
    super(props)

    this.state = {
      animationStarted: false,
      animationType: 1 
    }
    this.animation1 = null;
  }

  animate1(){

    this.animation1 = 
      anime({
        targets: '#moon-gravitation',
        // translateX: '200',
        translateX: anime.path('#orbit-gravity')('x'),
        translateY: anime.path('#orbit-gravity')('y'),
        rotate: anime.path('#orbit-gravity')('angle'),
        easing: 'linear',
        duration: 5000,
        direction: 'reverse',
        loop: true,
        autoplay: true
      })
  }

  componentDidMount(){      

    this.animate1()
  }

  componentWillUnmount(){

    this.animation1.pause()

  }

  render(){
    return(
      <>
      <svg className="orbitSvgGravity" style={{position: 'relative', left: '35%', bottom: '5%'}} id="orbit-svg-helio" height="350" width="350">
      <circle id="orbit-gravity" cx="160" cy="160" r='100' stroke="white" strokeWidth="3" fill="none"/>
      </svg>
      <img width='70px' style={{left:'125px', top:'139px', position:'absolute'}} id="moon-gravitation" src={moon}/>
      <img width='80px' style={{left:'285px', top:'282px', position:'absolute'}} src={earth}/>
      </>
    )
  }
}

function GravityPage(props) {
  const [step, setStep] = useState(0);
  const [isMinimized, setIsMinimized] = useState(false);


  const standardObj = [
    {
      name: 'A Gravidade',
      step: 1,
      comp: (
        <>
          <h1 className="presentationItem">A Gravidade</h1>

          <p className="presentationItem">
            <b className="presentationItem">Isaac Newton</b> foi um astrônomo, físico e matemático inglês, conhecido como um dos maiores expoentes da ciência devido à seus trabalhos nas área da gravitação universal, dinâmica dos corpos, cálculo diferencial e ótica.
          </p>
          <p className="presentationItem">
            Diz a lenda que certa vez, deitado às sombras de uma árvore, uma maçã caiu sobre sua cabeça e Newton indagou <b className="presentationItem">"por qual motivo essa maçã, e todas as outras coisas caem ao chão, ao invés de permanecerem flutuando?"</b>
          </p>
          <p className="presentationItem">
            Essa indagação viria ser a base para uma das maiores descobertas da história da humanidade. E você, consegue responder essa questão?
          </p>
          <img style={{bottom: '0px', right: '0px', position: 'absolute', zIndex:"-1"}} src={grass} width="370px"/>
          <img id="apple-gravitation" style={{bottom: '185px', right: '90px', position: 'absolute', zIndex:"0"}} src={apple} width="35px"/>

          <div className="presentationItem arrowBtnContainer">
            <Button
              icon
              labelPosition="right"
              onClick={() => {
                setStep(step + 1);
              }}
            >
              Próximo
              <Icon name="right arrow" />
            </Button>
          </div>
        </>
      ),
    },
    {
      name: 'A Gravidade',
      step: 2,
      comp: (
        <>
          <h1 className="presentationItem">A gravidade</h1>
          <p className="presentationItem">
            Ao tentar responder essa pergunta, Newton percebeu que <b className="presentationItem">a Lua não cai sobre a Terra</b>. Mas com seus conhecimentos de Movimentação Circular (os mesmos que você estudou em nosso módulo de Movimento Circular), Newton observou que <b className="presentationItem">a Lua gira em torno da Terra com uma velocidade V</b>, e que o único motivo para a Lua não 'ir embora' é uma <b className="presentationItem">força de atração que nosso planeta exerce sobre o satélite</b>. Essa força de atração é <b className="presentationItem">a mesma que atrai uma maçã para o solo</b>.
          </p>
          <div style={{width: '100%', textAlign: 'center'}}>
            <div style={{width: '400px'}}>
              <OrbitAndMoonMoving/>
              <div style={{width:'412px', height:'291px', position:'absolute', right:0, bottom: '5%'}}>
                <img id='apple-force-1' style={{width:'40px', position: 'absolute', left:'132px', top: '-30px'}} src={appleForce}/>
                <img id='apple-force-2' style={{width:'40px', position: 'absolute', left:'257px', top: '80px', transform: 'rotate(90deg)'}} src={appleForce}/>
                <img id='apple-force-2' style={{width:'40px', position: 'absolute', left:'10px', top: '80px', transform: 'rotate(-90deg)'}} src={appleForce}/>
                <img id='apple-force-2' style={{width:'40px', position: 'absolute', left:'132px', top: '200px', transform: 'rotate(180deg)'}} src={appleForce}/>
                <img style={{width: '80px', position: 'absolute', left:'112px', top:'70px'}} src={earth}/>
              </div>
            </div>
          </div>
          <div className="presentationItem arrowBtnContainer">
            <Button
              icon
              labelPosition="left"
              onClick={() => {
                setStep(step - 1);
              }}
            >
              <Icon name="left arrow" />
              Anterior
            </Button>

            <Button
              icon
              labelPosition="right"
              onClick={() => {
                setStep(step + 1);
              }}
            >
              Próximo
              <Icon name="right arrow" />
            </Button>
          </div>
        </>
      ),
    },
    {
      name: 'A Gravidade',
      step: 3,
      comp: (
        <>
          <h1 className="presentationItem">A Gravidade</h1>
          <p className="presentationItem">
            Da mesma forma que <b className="presentationItem">a Terra atrai a Lua e uma maçã, o Sol atrai a Terra</b>. Para desenvolver uma explicação matemática para esse fenômeno, Newton percebeu que os objetos maiores e mais pesados atraíam os menores, já que a Lua orbita a Terra, e esta orbita o Sol.
            <br></br><br></br>
            Assim, Newton percebeu que <b className="presentationItem">a massa dos objetos</b> deveria ser um fator importante para explicar esse fenômeno. Porém, porque a Lua gira em torno da Terra e não do Sol, já que este é muito maior e mais pesado?
            <br></br><br></br>
            A explicação para isso é que a Lua está mais perto da Terra do que do Sol, assim, <b className="presentationItem">a distância entre os corpos</b> também é um fator importante para explicar esse fenômeno.
          </p>

          <div className="presentationItem arrowBtnContainer">
            <Button
              icon
              labelPosition="left"
              onClick={() => {
                setStep(step - 1);
              }}
            >
              <Icon name="left arrow" />
              Anterior
            </Button>
          </div>
        </>
      ),
    },
  ];

  useEffect(()=>{
    anime({
      targets: '#apple-gravitation',
      translateY: '160px',
      loop: true,
      easing: 'easeOutBounce',
      delay: 2000,
      endDelay: 2000,
    })

    anime({
      targets: '#apple-force-1',
      translateY: '50px',
      loop: true,
      easing: 'linear',
      duration: 1500
    })

    anime({
      targets: ['#apple-force-2'],
      translateY: '50px',
      loop: true,
      easing: 'linear',
      duration: 1500
    })
  })

  return (
    <div className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`}>
      {/* <div className="minMaxBar">
        <Icon className="icon" name='caret down' onClick={minimizeBar}/>
        <Icon className="icon" name='caret up' onClick={maximizeBar}/>
      </div> */}
      {!isMinimized && standardObj[step].comp}
    </div>
  );
}

export default GravityPage;

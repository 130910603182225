import React, { useState, useEffect } from 'react';
import anime from 'animejs/lib/anime.es.js';
import { Segment, Button, List } from 'semantic-ui-react';
import { Link, useParams } from 'react-router-dom';
import Navbar from '../components/navbar';
import LoadingSpinner from '../components/loadingSpinner';
import api from '../services/api';

import StageMap from '../components/StageMap';
import './ModulesStageMap.css';

function ModulesStageMap() {
  const { id } = useParams();

  const [moduleInfo, setModuleInfo] = useState({});
  const [stagesArray, setStagesArray] = useState([]);
  const [stage, setStage] = useState(1);
  var [isLoaded, setIsLoaded] = useState(false); // Gambiarra pesada

  useEffect(() => {
    const fetchData = async () => {
      await api
        .post('getModuleStageMapInfo', {
          module_id: id,
          client_id: localStorage.getItem('client_id'),
        })
        .then((res) => {
          setModuleInfo(res.data);
          setStagesArray(
            res.data.challenges.map((challenge, challengeIndex) => {
              return {
                ...challenge,
                isLocked: !challenge.is_unlocked,
                image:
                  res.data.challenges.length > challengeIndex + 1
                    ? res.data.path_image
                    : res.data.trophy_image,
              };
            })
          );
        })
        .catch((err) => {
          console.log(err.message);
        });
    };
    fetchData();
  }, []);

  useEffect(() => {
    // Gambiarra pesada
    if (!isLoaded) {
      window.scroll(0, 0);
      setIsLoaded(true);
    }
    anime({});
  });

  return (
    <>
      <Navbar />
      {!(moduleInfo.title && stagesArray.length > 0) && (
        <LoadingSpinner loading={true} className="restricted" />
      )}
      {moduleInfo.title && stagesArray.length > 0 && (
        <div
          className="stageMapPageBackground"
          style={{
            'background-image': `linear-gradient(rgba(${moduleInfo.color}), rgba(${moduleInfo.color})),
             url(data:image/png;base64,${moduleInfo.background})`,
          }}
        >
          <StageMap
            stages={stagesArray.sort((a, b) => (a.order < b.order ? -1 : 1))}
            stageFunction={setStage}
            startImg={moduleInfo.start_image}
            title={moduleInfo.title}
          />
          <div className="currentStage">
            <h1 className="stageMapHeader">{moduleInfo.name}</h1>
            <div>
              {stagesArray.find((e) => e.order === stage) && (
                <Segment className="stagesSegment">
                  <h1>{stagesArray.find((e) => e.order === stage).name}</h1>
                  <p>{stagesArray.find((e) => e.order === stage).description}</p>
                  <div className="currentStageImage">
                    <img
                      className="kinematicsMedia"
                      src={`data:image/png;base64,${
                        stagesArray.find((e) => e.order === stage).inner_image
                      }`}
                      alt="track"
                    />
                  </div>
                  {stage === stagesArray.length ? (
                    <div>
                      <List animated divided>
                        <List.Item>
                          <List.Icon name="flag" color="black" />
                          <List.Content>Veja suas conquistas:</List.Content>
                        </List.Item>

                        <List.Item>
                          <List.Icon name="star" color="yellow" />
                          <List.Content>0/0 estrelas</List.Content>
                        </List.Item>

                        <List.Item>
                          <List.Icon name="fonticons" color="purple" />
                          <List.Content>0 figurinhas</List.Content>
                        </List.Item>
                      </List>
                    </div>
                  ) : (
                    <Button
                      className="currentStageBtn"
                      as={Link}
                      to={stagesArray.find((e) => e.order === stage).url}
                    >
                      Começar desafio
                    </Button>
                  )}
                </Segment>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ModulesStageMap;

import React from 'react';
import RememberVariablesComponent from '../../../../components/MapComponents/rememberVariablesComponent';

function RememberVariables(props) {
  return (
    <RememberVariablesComponent
      paragraph1="Com o planeta pesando 6E24 kg e raio de 6000 km, nossa nave com massa de 3000000 kg e a Constante de Gravitação Universal com valor de 6,67E-11, calcule o peso da nave na superfície do paneta."
      paragraph2={null}
      paragraph3={null}
      sub={null}
    />
  );
}

export default RememberVariables;

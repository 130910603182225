import React, { useState } from 'react';
import './pentagon.css';

function Pentagon(props) {
  const points = {
    0: {
      Paciência: '100 100',
      Persistência: '100 100',
      Impulso: '100 100',
      Rapidez: '100 100',
      Dedicação: '100 100',
    },
    5: {
      Paciência: '100 0',
      Persistência: '5 69',
      Impulso: '41 181',
      Rapidez: '159 181',
      Dedicação: '195 69',
    },
    4: {
      Paciência: '100 20',
      Persistência: '24 75',
      Impulso: '53 165',
      Rapidez: '147 165',
      Dedicação: '176 75',
    },
    3: {
      Paciência: '100 40',
      Persistência: '43 81',
      Impulso: '65 149',
      Rapidez: '135 149',
      Dedicação: '157 81',
    },
    2: {
      Paciência: '100 60',
      Persistência: '62 88',
      Impulso: '76 132',
      Rapidez: '124 132',
      Dedicação: '138 88',
    },
    1: {
      Paciência: '100 80',
      Persistência: '81 94',
      Impulso: '88 116',
      Rapidez: '112 116',
      Dedicação: '119 94',
    },
  };
  const [attr, _] = useState({
    Paciência: props.patience,
    Persistência: props.persistence,
    Impulso: props.impulse,
    Rapidez: props.fastness,
    Dedicação: props.devotion,
  });

  return (
    <div className="pentagonContainer">
      {/* <p className="secondaryFont patience">Paciência</p>
            <p className="secondaryFont persistence">Persistência</p>
            <p className="secondaryFont impulse">Impulso</p>
            <p className="secondaryFont fastness">Rapidez</p>
            <p className="secondaryFont devotion">Dedicação</p> */}
      <svg style={{ position: 'absolute' }} width="200" height="200" fill="#3ec1d3">
        <path id="myPath" d="M 100 0 L 5 69 L 41 181 L 159 181 L 195 69 L 100 0" />
      </svg>
      <svg style={{ position: 'absolute' }} width="200" height="200" fill="#64cddb">
        <path d="M 100 20 L 24 75 L 53 165 L 147 165 L 176 75 L 100 20" />
      </svg>
      <svg style={{ position: 'absolute' }} width="200" height="200" fill="#8bd9e4">
        <path d="M 100 40 L 43 81 L 65 149 L 135 149 L 157 81 L 100 40" />
      </svg>
      <svg style={{ position: 'absolute' }} width="200" height="200" fill="#b1e6ed">
        <path d="M 100 60 L 62 88 L 76 132 L 124 132 L 138 88 L 100 60" />
      </svg>
      <svg style={{ position: 'absolute' }} width="200" height="200" fill="#d8f2f6">
        <path d="M 100 80 L 81 94 L 88 116 L 112 116 L 119 94 L 100 80 " />
      </svg>
      <svg style={{ position: 'absolute' }} width="200" height="200" fill="black">
        <path d="M 100 100 L 100 100 L 100 100 L 100 100 L 100 100 L 100 100 " />
      </svg>
      <svg
        style={{ position: 'absolute' }}
        width="200"
        height="200"
        stroke="#ff5722"
        strokeWidth="3"
        fill="none"
      >
        <path
          d={
            'M ' +
            points[attr['Paciência']]['Paciência'] +
            ' L ' +
            points[attr['Persistência']]['Persistência'] +
            ' L ' +
            points[attr['Impulso']]['Impulso'] +
            ' L ' +
            points[attr['Rapidez']]['Rapidez'] +
            ' L ' +
            points[attr['Dedicação']]['Dedicação'] +
            ' L ' +
            points[attr['Paciência']]['Paciência']
          }
        />
      </svg>
      <span className="secondaryFont patience" fill="#ffffff">
        Dedicação
      </span>
      <span className="secondaryFont persistence" fill="#ffffff">
        Persistência
      </span>
      <span className="secondaryFont impulse" fill="#ffffff">
        Impulso
      </span>
      <span className="secondaryFont fastness" fill="#ffffff">
        Rapidez
      </span>
      <span className="secondaryFont devotion" fill="#ffffff">
        Paciência
      </span>
    </div>
  );
}

export default Pentagon;

import React from 'react';
import TipsComponent from '../../../../components/MapComponents/tipsComponent';

import tip1 from '../../../../images/Physics/Gravitation/tip1.PNG';
import tip4 from '../../../../images/Physics/Gravitation/tip4.PNG';
import tip6 from '../../../../images/Physics/Gravitation/tip6.PNG';
import tip7 from '../../../../images/Physics/Gravitation/tip7.PNG';

function Solution(props) {
  return (
    <>
      <TipsComponent
        numberTips={4}
        presentation1="Bem vindo à Academia de Astronautas, um local onde conseguimos reparar algumas arestas em seu aprendizado e dar algumas dicas para você conseguir realizar nossa viagem."
        presentation2="Aqui temos que trabalhar duro, então algumas dicas necessitarão de um esforço seu. Vamos lá!"
        subject="physics"
        typeScoring="gravitation"
        arrayScoring="arrStartEscapeSpeed"
        tips={[
          {
            number: 0,
            image: tip1,
            intro1: 'Aprendendo a utilizar as vestimentas de astronauta, você encontra uma dica.',
            intro2: null,
            icon: 'angle right',
            title: 'Uso do macacão',
            dica1: true,
            dica1HasCondition: false,
            dica1ValidateCondition: null,
            dica1ConditionType: null,
            dica1MessageCondition: null,
            dica1ResponsePt1:
              'Como primeiro passo do desafio, podemos descobrir qual a velocidade de escape necessária para que o foguete saia de órbita. Assim, podemos fazer:',
            dica1ResponsePt2: null,
            dica1ResponsePt3: null,
            dica1ResponsePt4: null,
            dica1ResponseEquation1: String.raw`v = \sqrt{\frac{2 * G * M}{R}}`,
            dica1ResponseEquation2: String.raw`v = \sqrt{\frac{2 * 6,67E-11 * 6E24}{6000000}}`,
            dica1ResponseEquation3: String.raw`v = 11549.5`,
            dica1ResponsePt5: "Assim, chegamos à uma velocidade de escape de 11.5 km/s. Como sabemos que a órbita do satélite se encontra a 36000000 km de distância, conseguimos descobrir que o foguete percorrerá essa distância em um tempo de cerca de 3100 segundos.",
            dica2: false,
          },
          {
            number: 1,
            image: tip4,
            intro1: 'Aprendendo a utilizar as botas de propulsão, você encontra uma dica.',
            intro2: null,
            icon: 'angle right',
            title: 'Botas de propulsão',
            dica1: true,
            dica1HasCondition: true,
            dica1ValidateCondition: 'arrStars[1] === true',
            dica1ConditionType: 'mission',
            dica1MessageCondition:
              'Essa dica está bloqueada para você! Para desbloqueá-la, realize a missão "Velocidade de escape".',
            dica1ResponsePt1:
              'O próximo passo é o cálculo da velocidade orbital em que nosso satélite se encontra. Assim, podemos fazer:',
            dica1ResponsePt2: null,
            dica1ResponsePt3: null,
            dica1ResponsePt4: null,
            dica1ResponseEquation1: String.raw`g = \frac{G*m_{planeta}}{d}`,
            dica1ResponseEquation2: String.raw`g = \frac{6,67*10^{-11}*6*10^{24}}{6000000+36000000}`,
            dica1ResponseEquation3: String.raw`g = 3086,8 m/s`,
            dica1ResponseEquation4: null,
            dica1ResponsePt5:
              "Assim, descobrimos que a velocidade orbital desse satélite é de 3,1 km/s.",
            dica1QuestionTittle:
              '(UEL-PR) Nem sempre é possível escapar da influência gravitacional de um planeta. No caso da Terra, a velocidade mínima de escape para um corpo de massa m é da ordem de 11,2 km/s. Em relação a essa velocidade, é correto afirmar que ela:',
            dica1QuestionImage: null,
            dica1OptionA:
              'independe da massa do corpo, mas depende da massa da Terra.',
            dica1OptionB:
              'independe da massa da Terra, mas depende da massa do corpo.',
            dica1OptionC:
              'depende da massa da Terra e da massa do corpo.',
            dica1OptionD:
              'independe da massa da Terra e da massa do corpo.',
            dica1OptionE:
              'depende da massa do corpo e da massa do Sol.',
            dica1CorrectAnswer: 'a',
            dica1MissionName: 'Velocidade de escape',
            dica1Subject: 'physics',
            dica1TypeScoring: 'gravitation',
            dica1ArrayScoring: 'arrStartEscapeSpeed',
            dica1IndexScoring: 1,
            dica1XPAward: 30,
            dica2: false,
          },
          {
            number: 2,
            image: tip6,
            intro1:
              'Aprendendo a utilizar o capacete espacial, você encontra uma dica.',
            intro2: null,
            icon: 'angle right',
            title: 'Capacete espacial',
            dica1: true,
            dica1HasCondition: true,
            dica1ValidateCondition: 'arrStars[2] === true',
            dica1ConditionType: 'mission',
            dica1MessageCondition:
              'Essa dica está bloqueada para você! Para desbloqueá-la, realize a missão "Velocidade de escape de um buraco negro".',
            dica1ResponsePt1:
              'A partir da velocidade orbital, podemos descobrir qual a velocidade angular do satélite:',
            dica1ResponsePt2: null,
            dica1ResponsePt3: null,
            dica1ResponsePt4: null,
            dica1ResponseEquation1: String.raw`\omega = \frac{v}{R}`,
            dica1ResponseEquation2: String.raw`\omega = \frac{3100}{6000000+36000000}`,
            dica1ResponseEquation3: String.raw`\omega = 0,000073`,
            dica1ResponsePt5:
              'Assim, descobrimos que o satélite tem uma velocidade angular de 0,000073 rad/s, ou de 15,16 graus/hora.',
            dica1QuestionTittle:
              '(ITA-SP) O raio do horizonte de eventos de um buraco negro corresponde à esfera dentro da qual nada, nem mesmo a luz, escapa da atração gravitacional por ele exercida. Por coincidência, esse raio pode ser calculado não relativisticamente como o raio para o qual a velocidade de escape é igual à velocidade da luz. Qual deve ser o raio do horizonte de eventos de um buraco negro com uma massa igual à massa da Terra? (Dado que a massa da Terra é de 6E24kg e a velocidade da luz no vácuo é de 3,0E8m/s)',
            dica1OptionA: '9 um',
            dica1OptionB: '9 mm',
            dica1OptionC: '30 cm',
            dica1OptionD: '90 cm',
            dica1OptionE: '3 km',
            dica1CorrectAnswer: 'b',
            dica1MissionName: 'Velocidade de escape de um buraco negro',
            dica1Subject: 'physics',
            dica1TypeScoring: 'gravitation',
            dica1ArrayScoring: 'arrStartEscapeSpeed',
            dica1IndexScoring: 2,
            dica1XPAward: 30,
            dica2: false,
          },
          {
            number: 3,
            image: tip7,
            intro1: 'Pela antena de comunicação você recebe uma dica.',
            intro2: null,
            icon: 'angle right',
            title: 'Antena de comunicação',
            dica1: true,
            dica1HasCondition: true,
            dica1ValidateCondition: 'arrStars[3] === true',
            dica1ConditionType: 'mission',
            dica1MessageCondition:
              'Essa dica está bloqueada para você! Para desbloqueá-la, realize a missão "Variando a massa".',
            dica1ResponsePt1:
              'Como temos uma velocidade angular de 15,16 graus/hora, precisamos descobrir qual será o deslocamento angular do satélite até que o foguete alcance sua órbita, o que será feito em 3100 segundos. Isso pode ser feito fazendo a "regra de 3":',
            dica1ResponsePt2: null,
            dica1ResponsePt3: null,
            dica1ResponsePt4: null,
            dica1ResponseEquation1: String.raw`deslocamento = 15,16 * \frac{3100}{3600}`,
            dica1ResponseEquation2: String.raw`deslocamento = 13,05`,
            dica1ResponseEquation3: null,
            dica1ResponseEquation4: null,
            dica1ResponsePt5:
              'Logo, descobrimos que no tempo que o foguete e lançado e alcança a órbita, o nosso satélite irá se locomover por 13 graus. Como sua posição inicial é de -30 graus, devemos "mirar" o foguete na direção de -17 graus.',
            dica1QuestionTittle:
              '(PUC RJ) Um planeta, de massa m, realiza um órbita circular de raio R com uma velocidade tangencial de módulo V ao redor de uma estrela de massa M.Se a massa do planeta fosse 2m, qual deveria ser o raio da órbita, em termos de R, para que a velocidade ainda fosse V?',
            dica1OptionA: '0;',
            dica1OptionB: 'R/2;',
            dica1OptionC: 'R;',
            dica1OptionD: '2R;',
            dica1OptionE: '4R;',
            dica1CorrectAnswer: 'C',
            dica1MissionName: 'Variando a massa',
            dica1Subject: 'physics',
            dica1TypeScoring: 'gravitation',
            dica1ArrayScoring: 'arrStartEscapeSpeed',
            dica1IndexScoring: 3,
            dica1XPAward: 30,
            dica2: false,
          },
        ]}
      />
    </>
  );
}

export default Solution;

import React, { useState } from 'react';
import { Card, Image, Form, Input, Button, Segment, Grid } from 'semantic-ui-react';
import questSmall from '../images/Logos/mrQuest_logoSmall.png';
import LoadingSpinner from '../components/loadingSpinner';
import { authenticate } from '../services/auth';
import { useHistory } from "react-router-dom";
import './home.css';

import api from '../services/api';

function Register() {
  const [bShowEnroll, fnFlip] = useState(true);
  return (
    <div className="flexContainer homeBackground">
      <HomeCard
        bIsEnroll={bShowEnroll}
        fnFlip={() => fnFlip((prev) => !prev)}
        className={'login-card'}
      />
    </div>
  );
}

function HomeCard({ bIsEnroll, fnFlip, className }) {
  let history = useHistory();
  const [pageLoading, setPageLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [birthDate, setBithDate] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const notField = (value) => {
    if(!value || value === ""){
      return true;
    }
    return false;
  }

  const handleRegister = async () => {
    setPageLoading(true);
    if([email, password, confirmPassword, name, surname, birthDate, zipcode].some(e => notField(e))){
      setPageLoading(false);
      return;
    }
    
    if(password !== confirmPassword){
      setErrorMessage("Senha deve ser igual à confirmação de senha");
      setPageLoading(false);
      return;
    }

    await api
    .post('users', {
      email: email,
      password: password,
      name: name,
      surname: surname,
      birth_date: birthDate,
      zip_code: zipcode,
    })
    .then((res) => {
      setPageLoading(false);
      setErrorMessage("");
      history.push('/');
    })
    .catch((err) => {
      setPageLoading(false);
      if(err.response && err.response.data && err.response.data.message){
        setErrorMessage(err.response.data.message);
      }
    });
  };

  return (
    <>
      <LoadingSpinner loading={pageLoading} />
      <Card className={'cardBackground ' + className} style={{'min-width':'60vw'}}>
        <Image src={questSmall} className="homeLogo" style={{width: '20vw'}} centered></Image>
        <Card.Content>
          <Form className="homeLoginForm">
          <Segment placeholder>
            <Grid columns={2} relaxed='very' stackable>
              <Grid.Column>
              <Form.Field>
                <label className="homeLabel">Nome</label>
                <Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  id="inputName"
                  className="form-control"
                  placeholder="Nome"
                  required
                  autoFocus
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                />
              </Form.Field>
              <Form.Field>
                <label className="homeLabel">Sobrenome</label>
                <Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  id="inputSurname"
                  className="form-control"
                  placeholder="Sobrenome"
                  required
                  autoFocus
                  value={surname}
                  onChange={(event) => {
                    setSurname(event.target.value);
                  }}
                />
              </Form.Field>
              <Form.Field>
              <label className="homeLabel">Data de nascimento</label>
              <Input
                className="home inputField"
                fluid
                icon="calendar"
                iconPosition="left"
                id="inputBirthDate"
                placeholder="Data de nascimento"
                type="date"
                required
                value={birthDate}
                onChange={(event) => {
                  setBithDate(event.target.value);
                }}
              />
            </Form.Field>
            <Form.Field>
              <label className="homeLabel">CEP</label>
              <Input
                className="home inputField"
                fluid
                icon="lock"
                iconPosition="left"
                id="inputZipcode"
                placeholder="CEP"
                required
                value={zipcode}
                onChange={(event) => {
                  setZipcode(event.target.value);
                }}
              />
            </Form.Field>
              </Grid.Column>

              <Grid.Column verticalAlign='middle'>
              <Form.Field>
              <label className="homeLabel">Login</label>
              <Input
                fluid
                icon="user"
                iconPosition="left"
                id="inputEmail"
                className="form-control"
                placeholder="Email"
                required
                autoFocus
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
            </Form.Field>
            <Form.Field>
              <label className="homeLabel">Senha</label>
              <Input
                className="home inputField"
                fluid
                icon="lock"
                iconPosition="left"
                id="inputPassword"
                placeholder="Senha"
                type="password"
                required
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />
            </Form.Field>
            <Form.Field>
              <label className="homeLabel">Confirmar senha</label>
              <Input
                className="home inputField"
                fluid
                icon="lock"
                iconPosition="left"
                id="inputConfirmPassword"
                placeholder="Confirmar senha"
                type="password"
                required
                value={confirmPassword}
                onChange={(event) => {
                  setConfirmPassword(event.target.value);
                }}
              />
            </Form.Field>
            <Form.Field>
            <label className="errorLabel" >{errorMessage}</label>
            <Button
              style={{ marginTop: '5%', backgroundColor: '#37adbd' }}
              fluid
              color="blue"
              // inverted
              onClick={handleRegister}
            >
              Cadastre-se
            </Button>
            </Form.Field>
              </Grid.Column>
            </Grid>
          </Segment>

          </Form>
        </Card.Content>
      </Card>
    </>
  );
}

export default Register;

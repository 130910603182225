import * as BABYLON from '@babylonjs/core';
import gameManager from '../../../../managers/GameManager';

const DELTA_TIME = 1 / 600;

var rocketForcePosition;
var rocketDestinyPosition = { "x": 180, "y": 0, "z": 0 };
let rocketFinalAngle = 0;
let scalVel = 0;

const ROCKET_INIT_POSITION = new BABYLON.Vector3(-160, 0, 0);
const CAMERA_INIT_POSITION = new BABYLON.Vector3(-155, 6, -5);

let moonLine = null;
let planetLine = null;
let rocketForceLine = null;
let rocketDestinyLine = null;


function Loop(props) {
  let scene = props.scene;
  if (scene.isReady() && gameManager.objSpecificGame.physics.gravitation.isLoading === 'true') {
    gameManager.updateInfo('physics', 'gravitation', 'isLoading', 'false');
    gameManager.updateInfo('physics', 'gravitation', 'responseValue', '0');

    gameManager.updateInfo('physics', 'gravitation', 'missionMessage', 'Desviando da lua');
    gameManager.updateInfo('physics', 'gravitation', 'missionIndexScoring', '3');
    gameManager.updateInfo('physics', 'gravitation', 'missionXpAward', '30');
  }

  // playSounds(scene);
  // scene.soundRaceMusic.play();
  scene.running = Number(gameManager.objSpecificGame.physics.gravitation.running);
  scene.restart = Number(gameManager.objSpecificGame.physics.gravitation.restart);
  scene.countStart = 0;

  if (scene.running !== undefined && scene.countStart !== undefined) {
    simulationFlow(scene);
    restartFlow(scene);
  }
}

export default Loop;

/**
 * Função que reinicia o desafio
 */
function restartFlow(scene) {
  if (scene.restart === 3) {
    gameManager.updateInfo('physics', 'gravitation', 'running', '0');
    gameManager.updateInfo('physics', 'gravitation', 'restart', '0');

    gameManager.updateInfo('physics', 'gravitation', 'showMessage', 'false');
    gameManager.updateInfo('physics', 'gravitation', 'showSlider', 'false');
    gameManager.updateInfo('physics', 'gravitation', 'showButtons', 'false');
    gameManager.updateInfo('physics', 'gravitation', 'showFinalPanel', 'false');

    scene.responses = [0, 0];

    scene.countStart = 0;
    scene.time = 0;
  }
  if (scene.restart <= 2 && scene.restart > 0) {
    gameManager.updateInfo('physics', 'gravitation', 'running', '1');
    gameManager.updateInfo('physics', 'gravitation', 'restart', '' + Number(scene.restart + 1));
    gameManager.updateInfo('physics', 'gravitation', 'responseValue', '0');
    scene.time = 0;

    (scene.planets || []).forEach((planet) => {
      planet.position = planet.initPosition;
      planet.angle = planet.initAngle;
      planet.period = planet.initPeriod;
    });

    scene.rocket.position.x = ROCKET_INIT_POSITION.x;
    scene.rocket.position.y = ROCKET_INIT_POSITION.y;
    scene.rocket.position.z = ROCKET_INIT_POSITION.z;

    scene.rocket.rotation = new BABYLON.Vector3(-Math.PI / 2, Math.PI / 2, 0);

    scene.camera.position.x = CAMERA_INIT_POSITION.x;
    scene.camera.position.y = CAMERA_INIT_POSITION.y;
    scene.camera.position.z = CAMERA_INIT_POSITION.z;

    scene.camera.setTarget(
      new BABYLON.Vector3(
        scene.rocket.position.x,
        scene.rocket.position.y,
        scene.rocket.position.z
      )
    );

    gameManager.updateInfo('physics', 'gravitation', 'physicsInfo', '');

    if (moonLine !== null) {
      moonLine.dispose();
      moonLine = null;
    }
    if (planetLine !== null) {
      planetLine.dispose();
      planetLine = null;
    }
    if (rocketForceLine !== null) {
      rocketForceLine.dispose();
      rocketForceLine = null;
    }
    if (rocketDestinyLine !== null) {
      rocketDestinyLine.dispose();
      rocketDestinyLine = null;
    }


  }
}

/**
 * Função que determina o fluxo das escolhas do aluno na corrida
 */
function simulationFlow(scene) {
  if (scene.running === 1 && scene.countStart >= 0) {
    updateQuests(scene);
    updateFinalPanel(scene);
    if (scene.running === 1 || scene.restart !== 0) {
      scene.time += DELTA_TIME;
      planetsMovement(scene);
    }
  }
}


function planetsMovement(scene) {
  let rocketVel;

  rocketVel = (112 * DELTA_TIME) / 10;

  if (gameManager.objSpecificGame.physics.gravitation.responseValue === '0') {
    scene.rocket.position.x -= 1 * rocketVel;


  } else {
    scene.rocket.position.x -= scalVel * rocketVel * (Math.abs(rocketDestinyPosition.x) - Math.abs(scene.rocket.position.x));
    scene.rocket.position.z += scalVel * rocketVel * (Math.abs(rocketDestinyPosition.z) - Math.abs(scene.rocket.position.z));
    if (scene.rocket.rotation.y < rocketFinalAngle) {
      scene.rocket.rotation.y += 0.05;
    }
  }

  scene.camera.position.x -= 0.6 * rocketVel;
  scene.camera.setTarget(
    new BABYLON.Vector3(
      scene.rocket.position.x,
      scene.rocket.position.y,
      scene.rocket.position.z
    )
  );

}

/////////////////////////////// Métodos para atualização GUI ///////////////////////////////

function updateFinalPanel(scene) {

  let finish = null;
  let win;

  if (scene.rocket.position.z >= 4) {
    if (gameManager.objSpecificGame.physics.gravitation.responseValue === 'Força de 8000N e direção de -45º') {
      finish =
        'Parabéns! Você soube direcionar nosso foguete! Fugimos da gravidade da lua e fomos rumo ao planeta Quest!';
      win = 'true';
    } else {
      finish = 'Que pena, você não soube guiar nossa nave e perdemos o rumo do planeta Quest.';
      win = 'false';
    }

  }

  if (finish) {
    gameManager.updateInfo('physics', 'gravitation', 'showFinalPanel', 'true');
    gameManager.updateInfo('physics', 'gravitation', 'finalMessage', finish);
    gameManager.updateInfo('physics', 'gravitation', 'win', win);
    scene.running = 0;
    gameManager.updateInfo('physics', 'gravitation', 'physicsInfo', '');
  }
}


/**
 * Atualiza a pergunta
 */
function updateQuests(scene) {
  // if (scene.rocket.position.x <= -100) {
  if (scene.rocket.position.x <= -176) {
    if (scene.responses[0] === 0) {
      gameManager.updateInfo('physics', 'gravitation', 'showMessage', 'true');
      gameManager.updateInfo('physics', 'gravitation', 'showSlider', 'true');
      gameManager.updateInfo(
        'physics',
        'gravitation',
        'message',
        'Qual a força e direção devemos aplicar ao foguete para que a força resultante nos leve rumo ao planeta Quest?'
      );

      let endingPlanetPosition = scene.planets.find((e) => e.isEnding).initPosition;
      let moonPosition = scene.planets.find((e) => e.isMoon).initPosition;
      if (planetLine === null) planetLine = drawLine(scene.rocket.position, endingPlanetPosition, new BABYLON.Color4(0.07, 0.13, 0.61));  // força entre foguete e planeta
      if (moonLine === null) moonLine = drawLine(scene.rocket.position, moonPosition, new BABYLON.Color4(0.32, 0.32, 0.32, 0.27));    // força entre foguete e lua
      gameManager.updateInfo('physics', 'gravitation', 'physicsInfo', 'Ch2 - Força do planeta e da lua');

      if (gameManager.objSpecificGame.physics.gravitation.responseValue === 'Força de 8000N e direção de 0º') {
        scene.responses[0] = Number(gameManager.objSpecificGame.physics.gravitation.responseValue);
        gameManager.updateInfo('physics', 'gravitation', 'showMessage', 'false');
        gameManager.updateInfo('physics', 'gravitation', 'showSlider', 'false');

        rocketForcePosition = { "x": -185, "y": 0, "z": 10 }
        if (rocketForceLine === null) rocketForceLine = drawLine(scene.rocket.position, rocketForcePosition, new BABYLON.Color4(1, 0, 0, 1));
        rocketDestinyPosition = { "x": -185, "y": 0, "z": 5 }
        if (rocketDestinyLine === null) rocketDestinyLine = drawLine(scene.rocket.position, rocketDestinyPosition, new BABYLON.Color4(1, 1, 1));
        rocketFinalAngle = 2.05;
        gameManager.updateInfo('physics', 'gravitation', 'physicsInfo', 'Força de 8000N e direção de 0º');
        scalVel = 0.1;

      } else if (gameManager.objSpecificGame.physics.gravitation.responseValue === 'Força de 800N e direção de -45º') {
        scene.responses[0] = Number(gameManager.objSpecificGame.physics.gravitation.responseValue);
        gameManager.updateInfo('physics', 'gravitation', 'showMessage', 'false');
        gameManager.updateInfo('physics', 'gravitation', 'showSlider', 'false');

        rocketForcePosition = { "x": -170, "y": 0, "z": 4 }
        if (rocketForceLine === null) rocketForceLine = drawLine(scene.rocket.position, rocketForcePosition, new BABYLON.Color4(1, 0, 0, 1));
        rocketDestinyPosition = { "x": -185, "y": 0, "z": 5 }
        if (rocketDestinyLine === null) rocketDestinyLine = drawLine(scene.rocket.position, rocketDestinyPosition, new BABYLON.Color4(1, 1, 1));
        rocketFinalAngle = 2.05;
        gameManager.updateInfo('physics', 'gravitation', 'physicsInfo', 'Força de 800N e direção de -45º');
        scalVel = 0.1;

      } else if (gameManager.objSpecificGame.physics.gravitation.responseValue === 'Força de 8000N e direção de -45º') {
        scene.responses[0] = Number(gameManager.objSpecificGame.physics.gravitation.responseValue);
        gameManager.updateInfo('physics', 'gravitation', 'showMessage', 'false');
        gameManager.updateInfo('physics', 'gravitation', 'showSlider', 'false');

        rocketForcePosition = { "x": -170, "y": 0, "z": 4 }
        if (rocketForceLine === null) rocketForceLine = drawLine(scene.rocket.position, rocketForcePosition, new BABYLON.Color4(1, 0, 0, 1));
        rocketDestinyPosition = { "x": endingPlanetPosition.x, "y": endingPlanetPosition.y, "z": endingPlanetPosition.z }
        if (rocketDestinyLine === null) rocketDestinyLine = drawLine(scene.rocket.position, rocketDestinyPosition, new BABYLON.Color4(1, 1, 1));
        rocketFinalAngle = 2.35;
        gameManager.updateInfo('physics', 'gravitation', 'physicsInfo', 'Força de 8000N e direção de -45º');
        scalVel = 0.01;

      } else if (gameManager.objSpecificGame.physics.gravitation.responseValue === 'Força de 16000N e direção de -70º') {
        scene.responses[0] = Number(gameManager.objSpecificGame.physics.gravitation.responseValue);
        gameManager.updateInfo('physics', 'gravitation', 'showMessage', 'false');
        gameManager.updateInfo('physics', 'gravitation', 'showSlider', 'false');

        rocketForcePosition = { "x": -170, "y": 0, "z": 0 }
        if (rocketForceLine === null) rocketForceLine = drawLine(scene.rocket.position, rocketForcePosition, new BABYLON.Color4(1, 0, 0, 1));
        rocketDestinyPosition = { "x": -175, "y": 0, "z": 5 }
        if (rocketDestinyLine === null) rocketDestinyLine = drawLine(scene.rocket.position, rocketDestinyPosition, new BABYLON.Color4(1, 1, 1));
        rocketFinalAngle = 3.25;
        gameManager.updateInfo('physics', 'gravitation', 'physicsInfo', 'Força de 16000N e direção de -70º');
        scalVel = 0.15;

      } else {
        scene.running = 0;
      }
    }
  }
}

function playSounds(scene) {
  if (Number(gameManager.objSpecificGame.physics.kinematics.running) === 1 && scene.running === 0) {
    if (!scene.soundRaceMusic.isPlaying) {
      scene.soundRaceMusic.play();
    }
  } else if (
    Number(gameManager.objSpecificGame.physics.kinematics.running) === 0 &&
    scene.running === 1
  ) {
    scene.soundRaceMusic.stop();
  }
}


function drawLine(point1, point2, color) {
  const linePoints = [
    new BABYLON.Vector3(point1.x, point1.y, point1.z),
    new BABYLON.Vector3(point2.x, point2.y, point2.z),

  ]
  const lineColors = [
    color,
    color,
    color,
    color
  ]
  const options = {
    points: linePoints,
    updatable: true,
    colors: lineColors
  }
  return BABYLON.MeshBuilder.CreateLines("lines", options);
}




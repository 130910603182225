import React, { useEffect, useState } from 'react';
import anime from 'animejs/lib/anime.es.js';
import { Icon, Popup } from 'semantic-ui-react';

function TimePage(props) {
  const [isMinimized, setIsMinimized] = useState(false);

  function counter() {
    anime({
      targets: '#thirty-seconds',
      innerText: {
        value: ['0 segundos', '3000 segundos'],
      },
      round: 1,
      easing: 'linear',
      duration: 3000000,
    });
  }

  useEffect(() => {
    counter();
  }, []);

  return (
    <div
      className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`}
      key={Math.random()}
    >
      {/* <div className="minMaxBar">
        <Icon className="icon" name='caret down' onClick={minimizeBar}/>
        <Icon className="icon" name='caret up' onClick={maximizeBar}/>
      </div> */}
      {!isMinimized && (
        <>
          <h1 className="presentationItem">Tempo</h1>
          <br></br>
          <p className="presentationItem">
            O planeta Terra demora 365 dias para dar uma volta em torno do Sol, ou o que chamamos de
            1 ano. Através dessa medida, podemos chegar à diferentes escalas como ano, dia, hora,
            minuto, segundo, entre tantas outras. Assim, entendemos o{' '}
            <b className="presentationItem">tempo</b> como a medida de{' '}
            <b className="presentationItem">duração de eventos</b>.<br></br>
            <br></br>
            Através do tempo, conseguimos também sequenciar eventos, como dizer que o dia de ontem
            veio antes do dia de hoje. Assim, temos uma noção de <b>passado, presente e futuro</b>.
            <br></br>
            <br></br>
            Para se ter uma ideia da ação desta medida no contexto físico, podemos observar um
            contador em segundos, que indica o tempo decorrido desde que você abriu essa tela:
          </p>
          <h1 id="thirty-seconds" className="highlightText presentationItem"></h1>

          <span className="white presentationItem">Unidades</span>
          <Popup
            wide
            inverted
            content={
              <p className="presentationItem">
                <br></br>O tempo é comumente representado pela letra{' '}
                <b className="presentationItem">
                  <i>t</i>
                </b>{' '}
                e a unidade padrão de tempo no Sistema Internacional é{' '}
                <b className="presentationItem">
                  segundos (representada por <i>s</i> )
                </b>
                .<br></br>
                Outras unidades bastante utilizadas são horas (h), minutos (m), dias, anos etc...
                <br></br>
                <br></br>O símbolo{' '}
                <b className="presentationItem">
                  <i>Δt</i>
                </b>{' '}
                representa a <b className="presentationItem">variação do tempo</b>. Por exemplo,
                para um evento que se iniciou no dia 1 de março (às 00:01h) e finalizou ao dia 3 de
                março (às 00:01h), temos um Δt de 2 dias, ou 48h.
              </p>
            }
            trigger={<Icon name="question circle outline" />}
          />
        </>
      )}
    </div>
  );
}

export default TimePage;

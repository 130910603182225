import React, { useEffect } from 'react';
import View from '../../images/biologyInConstruction.gif';
import { Image } from 'semantic-ui-react';
import './inConstruction.scss';

function BiologyInConstruction() {
  useEffect(() => {});

  const carStyle = {
    position: 'absolute',
    right: '300px',
  };

  const trackStyle = {
    position: 'absolute',
    right: '80px',
    top: '4px',
  };

  return (
    <>
      <Image src={View} size="big" centered className="inConstruction__image" />
      <p className="inConstruction__paragraph">
        Este desafio está em construção.
        <br></br>
        Que tal aprender outra matéria enquanto desenvolvemos um incrível game para você?
      </p>
    </>
  );
}

export default BiologyInConstruction;

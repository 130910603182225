let PointsTraj2 = [[376.0042004585266,30],
[377.0018479153514,30],
[378.0071104168892,30],
[379.00869923084974,30],
[379.9974559418624,30],
[380.99528504384216,30],
[382.0008883094415,30],
[382.9904641881585,30],
[384.00345689058304,30],
[385.0098861810984,30],
[385.99878635804635,30],
[387.0059681907296,30],
[388.00378709926736,30],
[388.99838936737797,30],
[389.99783144064713,30],
[390.9989775343711,30],
[391.9988962066127,30],
[393.00827306194697,30],
[393.9979779227142,30],
[395.0066565563902,30],
[395.9909791602986,30],
[396.99134097248316,30],
[398.0070531925885,30],
[399.00779058237094,30],
[399.9917279453948,30],
[401.00270310974156,30],
[401.99461042135954,30],
[402.99690275384637,30],
[404.00902861508075,30],
[404.99887556886824,30],
[405.99694000929594,30],
[407.00271980090474,30],
[407.99957980898034,30],
[409.0029472574097,30],
[409.9960176348686,30],
[410.99446812547103,30],
[411.9978429140756,30],
[413.005686185541,30],
[414.00092440079607,30],
[414.9996110357897,30],
[416.0013123270328,30],
[417.0055945110362,30],
[417.99523475777823,30],
[419.0033524351893,30],
[419.99592534081603,30],
[421.00617225475435,30],
[422,30],
[422.99382774524565,30],
[424.00407465918397,30],
[424.9966475648107,30],
[426.00476524222177,30],
[426.9944054889638,30],
[427.9986876729672,30],
[429.0003889642103,30],
[429.99907559920393,30],
[430.994313814459,30],
[432.0021570859244,30],
[433.00553187452897,30],
[434.0039823651314,30],
[434.9970527425903,30],
[436.00042019101966,30],
[436.99728019909526,30],
[438.00305999070406,30],
[439.00112443113176,30],
[439.99097138491925,30],
[441.00309724615363,30],
[442.00538957864046,30],
[442.99729689025844,30],
[444.0082720546052,30],
[444.99220941762906,30],
[445.9929468074115,30],
[447.00865902751684,30],
[448.0090208397014,30],
[448.9933434436098,30],
[450.0020220772858,30],
[450.99172693805303,30],
[452.0011037933873,30],
[453.0010224656289,30],
[454.00216855935287,30],
[455.00161063262203,30],
[455.99621290073264,30],
[456.9940318092704,30],
[458.00121364195365,30],
[459.0006311834877,30],
[459.99654310941696,30],
[461.0095358118415,30],
[461.9991116905585,30],
[463.00471495615784,30],
[464.0025440581376,30],
[464.99130076915026,30],
[465.9928895831108,30],
[466.9981520846486,30],
[468.00659158825874,30.000000171363354],
[468.9971332550049,30.00395917892456],
[469.99755585193634,30.016045778989792],
[471.00088226795197,30.036576479673386],
[472.0067219734192,30.065872073173523],
[472.98961067199707,30.103190898895264],
[473.99955570697784,30.150773018598557],
[474.9985508918762,30.20734965801239],
[475.9987258911133,30.273786544799805],
[476.9876790046692,30.349441409111023],
[477.98911714553833,30.4364994764328],
[478.9787793159485,30.533230662345886],
[479.9798049926758,30.64228630065918],
[480.96848726272583,30.761481165885925],
[481.96738481521606,30.893943667411804],
[482.95335817337036,31.037022471427917],
[483.9373904466629,31.1925251185894],
[484.92986726760864,31.362749934196472],
[485.8978443145752,31.542270183563232],
[486.89440965652466,31.74162471294403],
[487.86586105823517,31.950779169797897],
[488.8433003425598,32.17667376995087],
[489.8157277107239,32.4175146818161],
[490.77277421951294,32.670989871025085],
[491.73389434814453,32.94277763366699],
[492.69810676574707,33.233684062957764],
[493.6456298828125,33.538299560546875],
[494.5949101448059,33.86313045024872],
[495.5268268585205,34.202224254608154],
[496.45913887023926,34.56265211105347],
[497.39080476760864,34.94526946544647],
[498.30371856689453,35.34329032897949],
[499.2145709991455,35.764663219451904],
[500.10595703125,36.2020263671875],
[500.9938397407532,36.663926005363464],
[501.8771176338196,37.15126693248749],
[502.73946046829224,37.65580451488495],
[503.595703125,38.18701171875],
[504.43025636672974,38.736035227775574],
[505.24312019348145,39.30287504196167],
[506.04798126220703,39.89794731140137],
[506.8303852081299,40.51146650314331],
[507.60323762893677,41.15449035167694],
[508.3528518676758,41.81660270690918],
[509.0792279243469,42.49780356884003],
[509.7940831184387,43.210126757621765],
[510.47358894348145,43.92982816696167],
[511.14077949523926,44.68130445480347],
[511.78393745422363,45.45252180099487],
[512.4030628204346,46.24348020553589],
[512.9884343147278,47.04050624370575],
[513.5598950386047,47.870617747306824],
[514.0983963012695,48.70614433288574],
[514.6136593818665,49.56075942516327],
[515.1056842803955,50.434463024139404],
[515.5667190551758,51.31196403503418],
[516.0126614570618,52.22352111339569],
[516.4214172363281,53.12230682373047],
[516.8150806427002,54.05514860153198],
[517.1738605499268,54.97332715988159],
[517.517548084259,55.92556178569794],
[517.8340840339661,56.878440737724304],
[518.124589920044,57.831042766571045],
[518.3901672363281,58.78246307373047],
[518.640652179718,59.76793944835663],
[518.862922668457,60.73327445983887],
[519.0664410591125,61.71401298046112],
[519.2478947639465,62.69121730327606],
[519.4112973213196,63.68324935436249],
[519.5540237426758,64.67060661315918],
[519.6793866157532,65.67222678661346],
[519.7854351997375,66.66805350780487],
[519.8731708526611,67.6572642326355],
[519.9448781013489,68.65964114665985],
[519.9995808601379,69.65432727336884],
[520.0385724306107,70.6510896384716],
[520.0625019073486,71.66022443771362],
[520.0713195800781,72.64928436279297],
[520.066011428833,73.64994764328003],
[520.0468646287918,74.6511490046978],
[520.0147705078125,75.64132690429688],
[520.0255131721497,76.64906132221222],
[520.0539875030518,77.64709520339966],
[520.0691223144531,78.65583038330078],
[520.0701313018799,79.65296220779419],
[520.0567942857742,80.64913919568062],
[520.0282669067383,81.65449714660645],
[519.9846774339676,82.64748266339302],
[519.9246292114258,83.64860725402832],
[519.8480153083801,84.64695370197296],
[519.7553730010986,85.63213396072388],
[519.6443176269531,86.62384796142578],
[519.5138478279114,87.62127315998077],
[519.3660144805908,88.60443544387817],
[519.1974048614502,89.59219026565552],
[519.006977558136,90.58368217945099],
[518.7977981567383,91.55977058410645],
[518.5653858184814,92.53843355178833],
[518.3135070800781,93.50110626220703],
[518.0369534492493,94.46516907215118],
[517.7402057647705,95.41264390945435],
[517.417314529419,96.3603024482727],
[517.0734882354736,97.29076433181763],
[516.7020230293274,98.22018158435822],
[516.3017773628235,99.14761650562286],
[515.8790745735168,100.05660450458527],
[515.4339146614075,100.94714558124542],
[514.9580383300781,101.83411407470703],
[514.4675769805908,102.68744325637817],
[513.9365711212158,103.55078554153442],
[513.3909802436829,104.38048851490021],
[512.8123097419739,105.2046777009964],
[512.2198486328125,105.99588012695312],
[511.59351348876953,106.78091621398926],
[510.9431457519531,107.54621124267578],
[510.2687454223633,108.2917652130127],
[509.5821499824524,109.00564301013947],
[508.87231636047363,109.70043230056763],
[508.12661933898926,110.38742113113403],
[507.3829345703125,111.03274536132812],
[506.6033864021301,111.67026913166046],
[505.81440019607544,112.27838146686554],
[505.002956867218,112.86804687976837],
[504.1836242675781,113.42957305908203],
[503.3276538848877,113.98266267776489],
[502.47989082336426,114.49917650222778],
[501.61119270324707,114.99849367141724],
[500.73762941360474,115.47215569019318],
[499.8438720703125,115.92922973632812],
[498.9467182159424,116.36185503005981],
[498.0300979614258,116.77849006652832],
[497.1115231513977,117.17186081409454],
[496.1920552253723,117.54283916950226],
[495.254536151886,117.89898979663849],
[494.31751251220703,118.23388862609863],
[493.36312532424927,118.55452454090118],
[492.4105954170227,118.8550273180008],
[491.45115101337433,119.13902094960213],
[490.4952244758606,119.4042454957962],
[489.5134963989258,119.65910530090332],
[488.5567784309387,119.89119160175323],
[487.5644841194153,120.11571323871613],
[486.59910249710083,120.31902420520782],
[485.61959505081177,120.51073181629181],
[484.6259617805481,120.69083607196808],
[483.64026069641113,120.855797290802],
[482.6633930206299,121.00635576248169],
[481.6736011505127,121.14629793167114],
[480.67088508605957,121.27562379837036],
[479.6784710884094,121.3917533159256],
[478.685467839241,121.49656942486763],
[477.68040657043457,121.59148073196411],
[476.6993908882141,121.67365562915802],
[475.69498360157013,121.74744537472725],
[474.70369386672974,121.81035149097443],
[473.6890125274658,121.86487245559692],
[472.7008401155472,121.90868046879768],
[471.70225191116333,121.94393265247345],
[470.7060852050781,121.97034454345703],
[469.69976472854614,121.9884158372879],
[468.7094807624817,121.9980012178421],
[467.69870471954346,122],
[466.6981816738844,122],
[465.70033173263073,122],
[464.706013455987,122],
[463.6987591981888,122],
[462.7064375486225,122],
[461.69849949725904,122],
[460.70770503766835,122],
[459.70687566511333,122],
[458.70560648222454,122],
[457.6961245369166,122],
[456.7012632060796,122],
[455.69324272847734,122],
[454.69328138045967,122],
[453.7057970871683,122],
[452.69775390625,122],
[451.7084363910835,122],
[450.70299463532865,122],
[449.70225872285664,122],
[448.7083793634083,122],
[447.70353756845,122],
[446.7090074811131,122],
[445.7064098119736,122],
[444.69696692191064,122],
[443.7022496846039,122],
[442.70328272879124,122],
[441.7012165307533,122],
[440.6972015667707,122],
[439.69238831312396,122],
[438.7084153427277,122],
[437.7054147720337,122],
[436.70504386140965,122],
[435.70845308713615,122],
[434.69661436975,122],
[433.69113192893565,122],
[432.6932281255722,122],
[431.7041253205389,122],
[430.7055749192368,122],
[429.69952643080615,122],
[428.7070427450817,122],
[427.6922180217225,122],
[426.69504422857426,122],
[425.6991411447525,122],
[424.70726680755615,122],
[423.7053358759731,122],
[422.698026413098,122],
[421.6905239671469,122],
[420.70390252699144,122],
[419.69843214191496,122],
[418.6992849856615,122],
[417.7037597987801,122],
[416.70221140421927,122],
[415.69597326405346,122],
[414.7009963989258,122],
[413.70654575526714,122],
[412.708046913147,122],
[411.7084711790085,121.98772776126862],
[410.7064890861511,121.95178079605103],
[409.69371795654297,121.9255142211914],
[408.69255018234253,121.90984201431274],
[407.7029857635498,121.90476417541504],
[406.70389354228973,121.91051828861237],
[405.69604671001434,121.92781031131744],
[404.7008341550827,121.95663797855377],
[403.69791984558105,121.99796485900879],
[402.70817172527313,122.05131304264069],
[401.7117966413498,122.11814177036285],
[400.70961713790894,122.19920206069946],
[399.72170054912567,122.29328501224518],
[398.72909820079803,122.4026209115982],
[397.73266553878784,122.52799081802368],
[396.73327445983887,122.67019081115723],
[395.7498736381531,122.82699060440063],
[394.7646988630295,123.00170195102692],
[393.77865493297577,123.19515097141266],
[392.8098076581955,123.40430128574371],
[391.84131956100464,123.63331079483032],
[390.8741283416748,123.88303565979004],
[389.90918815135956,124.1543470621109],
[388.9474695920944,124.44813120365143],
[387.9899597167969,124.76528930664062],
[387.0528544187546,125.1010776758194],
[386.1212958097458,125.46146166324615],
[385.1963038444519,125.84737253189087],
[384.29307639598846,126.25315535068512],
[383.38398694992065,126.69264841079712],
[382.49806582927704,127.1532951593399],
[381.6353130340576,127.63509559631348],
[380.7702625989914,128.15386068820953],
[379.92982816696167,128.69510126113892],
[379.1020197868347,129.2674012184143],
[378.2995676994324,129.86285257339478],
[377.52247190475464,130.48145532608032],
[376.75986659526825,131.13281977176666],
[376.0233688354492,131.80802154541016],
[375.31297862529755,132.5070606470108],
[374.62869596481323,133.2299370765686],
[373.96117401123047,133.9876480102539],
[373.3294848203659,134.75854456424713],
[372.7239031791687,135.5532784461975],
[372.14442908763885,136.37184965610504],
[371.59889590740204,137.2018791437149],
[371.0712585449219,138.06777954101562],
[370.58389842510223,138.93139231204987],
[370.1211543083191,139.81748056411743],
[369.6893844604492,140.71231842041016],
[369.2815012931824,141.62896585464478],
[368.9031447172165,142.553041100502],
[368.5479564666748,143.49827003479004],
[368.2258070707321,144.4346023797989],
[367.9254221916199,145.39080667495728],
[367.6510543823242,146.35123443603516],
[367.3977656364441,147.33090829849243],
[367.17271757125854,148.29730463027954],
[366.9674105644226,149.28172540664673],
[366.78771209716797,150.2504653930664],
[366.6264605522156,151.23604822158813],
[366.48365592956543,152.2384738922119],
[366.36327934265137,153.22231483459473],
[366.26009941101074,154.22185707092285],
[366.1755007505417,155.21883356571198],
[366.1085785627365,156.2124181985855],
[366.0576466321945,157.2206026315689],
[366.02371394634247,158.20524561405182],
[366.00438690185547,159.2227554321289],
[365.99050855636597,160.21459341049194],
[365.95324409008026,161.21647584438324],
[365.92809295654297,162.2072982788086],
[365.91490936279297,163.2077865600586],
[365.91448295116425,164.21721971035004],
[365.92722272872925,165.2146315574646],
[365.9537944793701,166.22000694274902],
[365.9940752983093,167.21286535263062],
[366.04928481578827,168.21268594264984],
[366.1187572479248,169.19948387145996],
[366.2060669660568,170.21118104457855],
[366.3066996335983,171.19012105464935],
[366.4268969297409,172.19238317012787],
[366.56308448314667,173.18004548549652],
[366.7182312011719,174.17098999023438],
[366.8899658918381,175.1467889547348],
[367.08544921875,176.14208984375],
[367.2948532104492,177.10408782958984],
[367.5298647880554,178.08389043807983],
[367.78332328796387,179.04685020446777],
[368.06007850170135,180.00913178920746],
[368.3611010313034,180.96984899044037],
[368.68186461925507,181.9125417470932],
[369.02821159362793,182.8524684906006],
[369.40114545822144,183.78871297836304],
[369.79515838623047,184.7057113647461],
[370.21025037765503,185.60346364974976],
[370.6608424186706,186.50998830795288],
[371.12633121013636,187.38231885433197],
[371.62940931320185,188.26151418685913],
[372.1473841667175,189.10651540756226],
[372.6964424848556,189.94372880458832],
[373.2686691284179,190.7597885131836],
[373.8734049797058,191.5667586326599],
[374.4923247098923,192.34018552303314],
[375.1444720029831,193.1038669347763],
[375.8100848197937,193.8346610069275],
[376.5096435546875,194.5550537109375],
[377.2330889701843,195.25363683700562],
[377.9804210662842,195.93041038513184],
[378.73977649211884,196.57561337947845],
[379.5223002433777,197.1996626853943],
[380.31542158126825,197.7934432029724],
[381.1310038566589,198.36671590805054],
[381.9690470695495,198.91948080062866],
[382.82955121994013,199.4517378807068],
[383.6845836639404,199.9478054046631],
[384.56069517135614,200.42462682724],
[385.45788574218744,200.8822021484375],
[386.3611773252487,201.31361424922943],
[387.2695664167404,201.71977937221527],
[388.1976965665817,202.10792005062103],
[389.1296081542969,202.47201538085938],
[390.06433057785034,202.81295156478882],
[391.01749992370605,203.1370449066162],
[391.9553050994873,203.43398094177246],
[392.92751681804657,203.720095038414],
[393.8825054168701,203.98074913024902],
[394.85408198833466,204.22625768184662],
[395.8241318464279,204.45256745815277],
[396.81017196178436,204.66427743434906],
[397.79350090026855,204.8578701019287],
[398.7732467651367,205.03414154052734],
[399.76782035827637,205.19687461853027],
[400.75767028331757,205.3433278799057],
[401.74195754528046,205.47426736354828],
[402.7399538755417,205.59268987178802],
[403.73129081726074,205.69659996032715],
[404.7151622772217,205.78673362731934],
[405.72211477160454,205.8660896718502],
[406.7208080291748,205.93239402770996],
[407.7211664915085,205.98688161373138],
[408.7216383218765,206.00108790397644],
[409.7142444849014,206.00618624687195],
[410.7173709869385,206.01566696166992],
[411.7125641107559,206.0294749736786],
[412.7177006006241,206.04798007011414],
[413.71461260318756,206.07097125053406],
[414.72088050842285,206.09897994995117],
[415.71862757205963,206.1316363811493],
[416.7078537940979,206.16894054412842],
[417.7056884765625,206.211669921875],
[418.71167528629303,206.26007342338562],
[419.7085328102112,206.31345653533936],
[420.7129235267639,206.37285137176514],
[421.70787286758423,206.4373960494995],
[422.7097259759903,206.50829577445984],
[423.70182037353516,206.5845184326172],
[424.70017862319946,206.6674451828003],
[425.68845558166504,206.7558708190918],
[426.6980381011963,206.8529167175293],
[427.6813541650772,206.95416903495789],
[428.6849765777588,207.0645866394043],
[429.6775184869766,207.18104815483093],
[430.6589798927307,207.3035535812378],
[431.6590429544449,207.43615412712097],
[432.6473331451416,207.57517623901367],
[433.6383361816406,207.72283935546875],
[434.6172122955322,207.87711715698242],
[435.61221170425415,208.04284191131592],
[436.5943603515625,208.215576171875],
[437.5774122476578,208.39793848991394],
[438.56080877780914,208.59023356437683],
[439.54398345947266,208.7927703857422],
[440.5131822824478,209.00293040275574],
[441.4943790435791,209.22678756713867],
[442.4608236551285,209.45869135856628],
[443.4376926422119,209.70515060424805],
[444.3990122079849,209.96009135246277],
[445.369140625,210.23046875],
[446.3229013681412,210.50977396965027],
[447.2838134765625,210.805419921875],
[448.239061832428,211.114275932312],
[449.1766757965088,211.4327507019043],
[450.1188735961914,211.7689666748047],
[451.05345118045807,212.11945986747742],
[451.9903964996338,212.4889030456543],
[452.9179172515869,212.87360763549805],
[453.82512855529785,213.26939010620117],
[454.73195123672485,213.68562602996826],
[455.63696908950806,214.1230878829956],
[456.5197892189026,214.57265758514401],
[457.3988742828369,215.04450607299802],
[458.2727451324463,215.53944015502927],
[459.13989114761347,216.05828380584714],
[459.9818496704101,216.59086608886716],
[460.8150272369384,217.1484794616699],
[461.62197875976557,217.72039794921872],
[462.43338775634766,218.33036804199216],
[463.2016396522522,218.94363689422605],
[463.97110748291016,219.59672546386716],
[464.7111077308655,220.26588726043698],
[465.4347634315491,220.9642057418823],
[466.1278290748596,221.67920970916745],
[466.80228424072266,222.4246063232422],
[467.4450058937073,223.18731212615967],
[468.0559940338135,223.96732711791992],
[468.64548683166504,224.7793083190918],
[469.20208168029785,225.60923385620114],
[469.72577857971186,226.45710372924802],
[470.22504281997675,227.33854198455808],
[470.6823563575744,228.22262096405026],
[471.1067719459533,229.12464427947995],
[471.4982895851135,230.04461193084714],
[471.85690927505493,230.98252391815183],
[472.17710304260254,231.9211540222168],
[472.46556329727173,232.8770933151245],
[472.7222900390625,233.850341796875],
[472.9434757232666,234.82273483276367],
[473.13407135009766,235.8118133544922],
[473.2913918495178,236.79880046844482],
[473.4171051979065,237.78278636932373],
[473.514452457428,238.782244682312],
[473.5823745727539,239.7775115966797],
[473.62301111221313,240.7876615524292],
[473.6363525390625,241.772216796875],
[473.62450647354126,242.77051067352295],
[473.5874729156494,243.78254318237305],
[473.52828454971313,244.7661771774292],
[473.4459228515625,245.762451171875],
[473.3403878211975,246.77136516571045],
[473.216145157814,247.76012110710144],
[473.07190668582916,248.74966311454773],
[472.90838050842285,249.73960494995117],
[472.7284504175186,250.71825289726257],
[472.52866983413696,251.7077283859253],
[472.316942691803,252.673357963562],
[472.08671045303345,253.64908123016357],
[472.01052090199664,254.64177502179518],
[472.02676025032997,255.63237527012825],
[472.0431642928161,256.63302186178043],
[472.0595844355994,257.6346505715628],
[472.07596015930176,258.6335697174072],
[472.09245301410556,259.63963386043906],
[472.1089490018785,260.6458891145885],
[472.12510895729065,261.6316463947296],
[472.14143296098337,262.6274106199853],
[472.1579805966685,263.63681639677816],
[472.1744239110849,264.6398585761781],
[472.1908624363132,265.6426086151041],
[472.2072037454127,266.6394284701746],
[472.22360379621387,267.6398315690458],
[472.24000437404175,268.6402668165465],
[472.25635094055906,269.6374073741026],
[472.27285335818306,270.6440548491664],
[472.2892146494123,271.64209361415124],
[472.3053892341559,272.6287432835088],
[472.32188977015903,273.63527597970096],
[472.33814125927165,274.62661681557074],
[472.3546800024924,275.6354801520356],
[472.37091302179033,276.6256943292101],
[472.3873982190271,277.63129136065254],
[472.40382708302786,278.63345206469967],
[472.4201796801426,279.6309604886992],
[472.4367457596163,280.6414913365952],
[472.45289363242773,281.6265115780916],
[472.4695428334526,282.6421128406073],
[472.485739586431,283.63011477229156],
[472.5021029151976,284.62827782705426],
[472.51862702877406,285.6362487552178],
[472.5349776418516,286.63363615294656],
[472.55147177319304,287.63977816477563],
[472.56777000427246,288.6339702606201],
[472.5841956933655,289.63593729529384],
[472.6004048010727,290.6246928654364],
[472.61706765781855,291.6411271269317],
[472.6333275343977,292.63297959826104],
[472.6496881254643,293.6309756533228],
[472.6659710407257,294.6242334842682],
[472.6823434481703,295.62295033838745],
[472.69880081532756,296.6268497349811],
[472.71533860972704,297.6356551933495],
[472.7315980570638,298.62748148089304],
[472.747925954689,299.6234832360278],
[472.76431805332686,300.6234012529385],
[472.7807701037018,301.62697632580966],
[472.79727785653813,302.63394924882596],
[472.8134765625,303.6220703125],
[472.8300819914439,304.63500147807645],
[472.846368116072,305.62845508039027],
[472.86269145831466,306.6241789571941],
[472.8790480400421,307.62193044256856],
[472.8954338831245,308.6214668705943],
[472.911845009432,309.622545575352],
[472.9282774408348,310.6249238909222],
[472.94472719920304,311.62835915138567],
[472.96119030640693,312.63260869082296],
[472.97766278431664,313.63742984331475],
[472.99395755221576,314.6314106851614],
[473.0102537465282,315.6254785382189],
[473.0267305679663,316.6305646459441],
[473.0432009359938,317.63525709562236],
[473.0596608724809,318.6393132213343],
[473.0757411281884,319.6202088194914],
[473.0921687190421,320.62229186156765],
[473.1085740323688,321.6230159744955],
[473.1249530900386,322.6221384923556],
[473.1413019139218,323.61941674922855],
[473.1579786537841,324.63669788082916],
[473.17425418185303,325.6295050930348],
[473.19048745334294,326.6197346539193],
[473.20703364646033,327.6290524340802],
[473.2235272778853,328.63516395100305],
[473.23996409834217,329.6378099988724],
[473.2563398585553,330.6367313718729],
[473.272650309249,331.6316688641891],
[473.28889120114764,332.62236327000574],
[473.3054088918143,333.629942400672],
[473.32184499801224,334.63254487874656],
[473.338194987271,335.62989422352985],
[473.35445432712004,336.62171395432233],
[473.3709613360479,337.62864149892266],
[473.3873642417384,338.62921874604217],
[473.40365821621526,339.623151189131],
[473.42017427505925,340.63063077861443],
[473.4365666493177,341.6305656083787],
[473.45283020293573,342.6226423790795],
[473.4692875444889,343.6265402138233],
[473.4855999489664,344.621596886951],
[473.502083628111,345.6271013147707],
[473.518405046314,346.62270782515407],
[473.5348734264262,347.6272790119983],
[473.55116096138954,348.62081864476204],
[473.5675692658624,349.6217252176066],
[473.58408065813273,350.6289201460968],
[473.60037678584195,351.62298393635865],
[473.6167463026941,352.621524464339],
[473.63316917419434,353.6233196258545],
[473.64962432317407,354.62708371361805],
[473.66608959312,355.6314651803186],
[473.68226540608885,356.61818977141957],
[473.69868533830595,357.61980563666293],
[473.71504232037114,358.6175815426395],
[473.731568866664,359.6257008665052],
[473.7479650562382,360.6258684305285],
[473.7642006234382,361.6162380297319],
[473.78072298987536,362.6241023823968],
[473.7972230017185,363.6306031048298],
[473.8136408366263,364.63209103420377],
[473.82991273136577,365.62467661331175],
[473.84638425707817,366.6294396817684],
[473.8625319004059,367.6144459247589],
[473.8790224583936,368.62036996200914],
[473.89546214474103,369.62319082920294],
[473.91180633514887,370.6201864440809],
[473.92822926910594,371.6219854154624],
[473.94468361180043,372.62570031982614],
[473.96094601228833,373.61770674958825],
[473.9775390625,374.6298828125],
[473.99390256777406,375.6280566342175],
[474.03744077682495,376.6118698120117],
[474.0839538574219,377.61669921875],
[474.110843539238,378.61678886413574],
[474.1171875,379.625],
[474.10157346725464,380.6262741088867],
[474.06310164928436,381.61976432800293],
[473.9998850822449,382.61742401123047],
[473.9113464355469,383.60498046875],
[473.79330241680145,384.60595512390137],
[473.64671409130096,385.59379386901855],
[473.46829211711884,386.5791301727295],
[473.25863456726074,387.5487976074219],
[473.0107250213623,388.5236511230469],
[472.7247157096863,389.49010467529297],
[472.40587615966797,390.4256591796875],
[472.037814617157,391.3698043823242],
[471.633939743042,392.2802429199219],
[471.1845073699951,393.1752624511719],
[470.69772148132324,394.0351257324219],
[470.16226530075073,394.87664031982416],
[469.5757555961609,395.69756317138666],
[468.9487147331238,396.4803390502929],
[468.2811427116394,397.2249679565429],
[467.57303953170776,397.93144989013666],
[466.82440519332886,398.59978485107416],
[466.051420211792,399.2177429199218],
[465.2395091056824,399.79906463623047],
[464.388671875,400.34375],
[463.52672374248505,400.83679008483887],
[462.62819623947144,401.2954025268554],
[461.72317838668823,401.70671844482416],
[460.8046398162842,402.0778503417968],
[459.85327100753784,402.4180831909179],
[458.9024075269699,402.7176036834716],
[457.9437747001648,402.9823532104492],
[456.96765089035034,403.21691131591797],
[455.9871826171875,403.419921875],
[455.0043640136719,403.59326171875],
[454.02114057540894,403.73876190185547],
[453.0132579803467,403.8610534667969],
[452.03426694869995,403.9556198120117],
[451.0330810546875,404.029296875],
[450.0376510620117,404.0809326171875],
[449.0212097167969,404.11279296875],
[448.02740371227264,404.12476921081543],
[447.0291212797165,404.118803024292],
[446.02718925476074,404.0956726074219],
[445.0224223136902,404.05614471435547],
[444.0312418937683,404.00194549560547],
[443.0310501307249,404.04844749346375],
[442.04363913834095,404.09781804308295],
[441.04246026463807,404.1478769867681],
[440.0368101205677,404.1981594939716],
[439.0349887739867,404.24825056130067],
[438.03320650570095,404.29833967471495],
[437.03253173828125,404.34837341308594],
[436.04946257174015,404.397526871413],
[435.0481236409396,404.447593817953],
[434.04810468875803,404.4975947655621],
[433.0433794353157,404.5478310282342],
[432.0528625100851,404.59735687449574],
[431.04077291302383,404.6479613543488],
[430.05206013307907,404.69739699334605],
[429.04859395325184,404.7475703023374],
[428.04725384898484,404.79763730755076],
[427.050895499764,404.8474552250118],
[426.04758681356907,404.89762065932155],
[425.0542529821396,404.947287350893],
[424.0585936754942,404.9970703162253],
[423.06280517578125,405.04685974121094],
[422.0690837651491,405.09654581174254],
[421.06511768628843,405.1467441156856],
[420.0678359027952,405.19660820486024],
[419.06528712809086,405.24673564359546],
[418.07437522895634,405.2962812385522],
[417.06981519795954,405.346509240102],
[416.06913369125687,405.39654331543716],
[415.0625773947686,405.44687113026157],
[414.0678637716919,405.4966068114154],
[413.0766191482544,405.5461690425873],
[412.0705262813717,405.5964736859314],
[411.0821002405137,405.6458949879743],
[410.07513904571533,405.69624304771423],
[409.08632636629045,405.7456836816855],
[408.0741141419858,405.7962942929007],
[407.0723148584366,405.84638425707817],
[406.08027866669,405.8959860666655],
[405.0906483978033,405.94546758010983],
[404.08808612823486,405.99559569358826],
[403.08020877838135,405.94431099295616],
[402.078125,405.89208984375],
[401.08460330963135,405.8492334783077],
[400.0850076675415,405.8153129518032],
[399.07972717285156,405.79075384140015],
[398.08352756500244,405.77612695097923],
[397.09640884399414,405.77143228054047],
[396.0901575088501,405.77696976065636],
[395.09352111816406,405.7930235862732],
[394.09266662597656,405.8200430870056],
[393.0880060195923,405.8584788739681],
[392.09350967407227,405.9080318212509],
[391.09576416015625,405.9696102142334],
[390.0951929092407,406.0436771810055],
[389.10535049438477,406.1294788122177],
[388.11325454711914,406.2283948659897],
[387.10650634765625,406.3424434661865],
[386.1113624572754,406.46918427944183],
[385.12782287597656,406.6086173057556],
[384.13111877441406,406.76481008529663],
[383.14662170410156,406.93435430526733],
[382.17433166503906,407.1172499656677],
[381.19040298461914,407.31857430934906],
[380.21929931640625,407.53392601013184],
[379.23780822753906,407.7690749168396],
[378.269775390625,408.0189437866211],
[377.31520080566406,408.2835326194763],
[376.3518409729004,408.5696710348129],
[375.402587890625,408.8712387084961],
[374.44586181640625,409.19579124450684],
[373.50390625,409.5364990234375],
[372.57672119140625,409.8933620452881],
[371.6540231704712,410.2707119882107],
[370.7363214492798,410.6691058576107],
[369.8241310119629,411.0891069173813],
[368.9375,411.521484375],
[368.04713439941406,411.9809355735779],
[367.163498878479,412.4633270204067],
[366.29636001586914,412.96376168727875],
[365.4366607666015,413.4879126548767],
[364.59381484985346,414.0304969549179],
[363.76782226562494,414.59151458740234],
[362.95868301391596,415.17096555233],
[362.1663970947265,415.7688498497009],
[361.3746490478515,416.3984808921814],
[360.6164283752441,417.0336240530014],
[359.8594703674316,417.7013067007065],
[359.1353149414062,418.3737087249756],
[358.4131469726562,419.0794429779053],
[357.7230567932129,419.7891038656235],
[357.0356788635254,420.53288972377777],
[356.37965393066406,421.27980947494507],
[355.74048233032227,422.0451625585556],
[355.1181640625,422.8289489746094],
[354.51269912719727,423.6311687231064],
[353.9364356994629,424.43416917324066],
[353.36431884765625,425.2728633880615],
[352.82069396972656,426.1115622520447],
[352.30450820922846,426.94911420345306],
[351.7928199768066,427.8227437734604],
[351.307876586914,428.6944670677185],
[350.8383827209472,429.58308827877045],
[350.3942604064941,430.46830117702484],
[349.96490859985346,431.36966931819916],
[349.55032730102533,432.2871927022934],
[349.159439086914,433.199471950531],
[348.78265762329096,434.1271804571152],
[348.41998291015625,435.0703182220459],
[348.07936096191406,436.00641775131226],
[347.752197265625,436.95723724365234],
[347.44580459594727,437.89961659908295],
[347.15223693847656,438.856023311615],
[346.8714942932129,439.8264573812485],
[346.60995864868164,440.7867406606674],
[346.3636713027954,441.74812254309654],
[346.12928771972656,442.7225241661072],
[345.90954971313477,443.6973651647568],
[345.701416015625,444.68489837646484],
[345.50978088378906,445.65931844711304],
[345.32916259765625,446.645788192749],
[345.1638832092285,447.6178764104843],
[345.0070276260376,448.6147573888302],
[344.8646583557129,449.59632432460785],
[344.730712890625,450.6026840209961],
[344.61041259765625,451.592809677124],
[344.5014657974243,452.57977399230003],
[344.4021339416504,453.577254652977],
[344.31361389160156,454.57098150253296],
[344.2344398498535,455.5749305486679],
[344.16647720336914,456.5599836111069],
[344.10653018951416,457.56938299536705],
[344.05701065063477,458.55902898311615],
[344.01550674438477,459.57302129268646],
[343.98365783691406,460.566415309906],
[343.9601173400879,461.568896651268],
[343.94505405426025,462.56507071852684],
[343.93804931640625,463.57005882263184],
[343.93902587890625,464.5681972503662],
[343.94761753082275,465.5590854585171],
[343.9637794494629,466.5582536458969],
[343.98751163482666,467.5657018125057],
[343.96967458724976,468.5641003847122],
[343.90525579452515,469.5640422105789],
[343.8279433250427,470.54915630817413],
[343.734010219574,471.55646526813507],
[343.626172542572,472.5477839708328],
[343.5020446777344,473.54102325439453],
[343.36083984375,474.5352783203125],
[343.2046813964844,475.51233673095703],
[343.0271816253662,476.50616121292114],
[342.8336410522461,477.481538772583],
[342.6203055381775,478.4548064470291],
[342.3863310813904,479.42499363422394],
[342.130859375,480.39111328125],
[341.85301780700684,481.35216188430786],
[341.55191946029663,482.3071194887161],
[341.2266631126404,483.25494968891144],
[340.87633323669434,484.1945996284485],
[340.5,485.125],
[340.10289573669434,486.0315136909485],
[339.6720333099365,486.940514087677],
[339.2191219329834,487.8241581916809],
[338.7298583984375,488.7073059082031],
[338.21722984313965,489.5635838508606],
[337.67340993881226,490.4046460390091],
[337.10555267333984,491.2180652618408],
[336.5051498413086,492.0147113800049],
[335.8623104095459,492.8038640022278],
[335.21178245544434,493.542866230011],
[334.5174255371094,494.2727737426758],
[333.7969570159912,494.9726529121399],
[333.05037689208984,495.6425037384033],
[332.28830099105835,496.27388012409205],
[331.5008144378662,496.87603425979614],
[330.6766052246094,497.4566116333008],
[329.83794927597046,497.999920964241],
[328.9738826751709,498.51400804519653],
[328.0967617034912,498.9924283027649],
[327.2076163291931,499.4363662004471],
[326.29443359375,499.8526611328125],
[325.3705806732178,500.23603105545044],
[324.43705892562866,500.58762753009796],
[323.4808349609375,500.91311645507807],
[322.5306029319763,501.2041875123977],
[321.5589656829834,501.47064256668085],
[320.5958762168884,501.7056189775467],
[319.6126403808594,501.9174270629883],
[318.6404318809509,502.10060799121857],
[317.6492977142334,502.2620244026184],
[316.6554183959961,502.39962959289545],
[315.65968084335327,502.51444351673126],
[314.6797103881836,502.60608100891113],
[313.6661071777344,502.67969512939453],
[312.6699733734131,502.73209047317505],
[311.67538595199585,502.7656098604202],
[310.6653060913086,502.7813129425049],
[309.6759810447693,502.7795754671097],
[308.67223167419434,502.7612500190735],
[307.6726989746094,502.7271194458008],
[306.6781554222107,502.6780720949173],
[305.6702175140381,502.6136212348938],
[304.68766927719116,502.53705990314484],
[303.6927185058594,502.4462356567383],
[302.6853652000427,502.34114849567413],
[301.7058334350586,502.2267475128174],
[300.714852809906,502.0991803407669],
[299.7135993987322,502.0280446410179],
[298.7170550376177,502.1251000761986],
[297.73240596055984,502.22019362449646],
[296.73472434282303,502.31573271751404],
[295.73647052049637,502.4105088710785],
[294.73764449357986,502.5045220851898],
[293.7382462620735,502.597772359848],
[292.7382758259773,502.6902596950531],
[291.75024349987507,502.7808422446251],
[290.76165318489075,502.870680809021],
[289.75998044013977,502.960898399353],
[288.75773549079895,503.05035305023193],
[287.7674570828676,503.13794082403183],
[286.77662068605423,503.22478461265564],
[285.7726734280586,503.3119695186615],
[284.7681539654732,503.39839148521423],
[283.7881965637207,503.48191833496094],
[282.7825469970703,503.56683349609375],
[281.7889065295458,503.6499385237694],
[280.7947080731392,503.7322995662689],
[279.787356197834,503.8149449825287],
[278.8046371936798,503.89478969573975],
[277.7961552143097,503.9759283065796],
[276.81233435869217,504.0543038845062],
[275.8027222752571,504.13393568992615],
[274.81779956817627,504.21084213256836],
[273.80705738067627,504.28896713256836],
[272.8210328221321,504.36440443992615],
[271.80916053056717,504.4410226345062],
[270.8220341205597,504.5149908065796],
[269.834363758564,504.5882337093353],
[268.820803463459,504.6626012325287],
[267.83203125,504.734375],
[266.8300280123949,504.8063296675682],
[265.82746678590775,504.8775403499603],
[264.8370487689972,504.9471197128296],
[263.8460868000984,505.0159738063812],
[262.8418657332659,505.08497136831284],
[261.83708667755127,505.15322494506836],
[260.84447890520096,505.21988463401794],
[259.8513271808624,505.2858190536499],
[258.8576315045357,505.35102820396423],
[257.850641682744,505.41633409261703],
[256.8558511286974,505.4800834059715],
[255.84775239229202,505.54391074180603],
[254.8518669605255,505.6062002182007],
[253.85543757677078,505.6677644252777],
[252.85846424102783,505.7286033630371],
[251.86094695329666,505.7887170314789],
[250.86288571357727,505.848105430603],
[249.86428052186966,505.90676856040955],
[248.86513137817383,505.96470642089844],
[247.86543828248978,506.0219190120697],
[246.8652012348175,506.07840633392334],
[245.87725420296192,506.13345807790756],
[244.87593622505665,506.18850415945053],
[243.88692212104797,506.24213314056396],
[242.8845232129097,506.29573798179626],
[241.8815803527832,506.34861755371094],
[240.8780935406685,506.400771856308],
[239.87406277656555,506.4522008895874],
[238.88237066566944,506.5022591948509],
[237.89014846086502,506.5516107082367],
[236.8844997882843,506.6008825302124],
[235.87830716371536,506.6494290828705],
[234.89739114046097,506.6960332393646],
[233.89012455940247,506.7431478500366],
[232.88231402635574,506.789537191391],
[231.89982157945633,506.8340394496918],
[230.89093708992004,506.87899684906006],
[229.89445348083973,506.922666490078],
[228.8974397778511,506.96562933921814],
[227.88693737983704,507.0084295272827],
[226.90182209014893,507.04943466186523],
[225.8902457356453,507.0908029079437],
[224.9040840268135,507.13041281700134],
[223.8914337158203,507.17034912109375],
[222.90422558784485,507.20856380462646],
[221.90350122749805,507.2465791106224],
[220.90224677324295,507.2838876247406],
[219.90046222507954,507.32048934698105],
[218.8981475830078,507.35638427734375],
[217.90833020210266,507.3911199569702],
[216.9049622565508,507.4256104826927],
[215.9010642170906,507.4593942165375],
[214.90968400239944,507.4920461177826],
[213.9047326594591,507.52442544698715],
[212.89925122261047,507.55609798431396],
[211.9063081741333,507.58666610717773],
[210.9128487110138,507.61654567718506],
[209.90579076111317,507.6461161971092],
[208.89820271730423,507.67497992515564],
[207.9031804203987,507.7027757167816],
[206.90764170885086,507.72988295555115],
[205.91158658266068,507.75630164146423],
[204.91501504182816,507.7820317745209],
[203.9179270863533,507.80707335472107],
[202.90719290077686,507.8317422270775],
[201.90906532108784,507.8553976416588],
[200.91042132675648,507.87836450338364],
[199.91126091778278,507.90064281225204],
[198.91158409416676,507.922232568264],
[197.9113908559084,507.9431337714195],
[196.9106812030077,507.9633464217186],
[195.90945513546467,507.9828705191612],
[194.9208968281746,508.0014626979828],
[193.9186447262764,508.01961874961853],
[192.91587620973587,508.0370862483978],
[191.912591278553,508.0538651943207],
[190.9087899327278,508.0699555873871],
[189.90447217226028,508.08535742759705],
[188.91286285221577,508.0998815894127],
[187.92075061798096,508.1137351989746],
[186.91489720344543,508.12708950042725],
[185.90852737426758,508.13975524902344],
[184.91489298641682,508.15157932043076],
[183.92075568437576,508.16273283958435],
[182.9128502011299,508.1733510494232],
[181.90442830324173,508.18328070640564],
[180.92204761505127,508.19228744506836],
[179.91260647773743,508.2008581161499],
[178.91594119369984,508.20864099264145],
[177.91877299547195,508.2157533168793],
[176.90779620409012,508.2222764492035],
[175.9229278564453,508.22796630859375],
[174.91093182563782,508.2331304550171],
[173.91174526512623,508.2375516295433],
[172.91205579042435,508.2413022518158],
[171.91186340153217,508.24438232183456],
[170.9111680984497,508.2467918395996],
[169.90996988117695,508.24853080511093],
[168.9082687497139,508.24959921836853],
[167.91943073272705,508.24999618530273],
[166.90335774421692,508.24972438812256],
[165.91352730989456,508.24879813194275],
[164.92320728302002,508.24721908569336],
[163.9056122303009,508.24491786956787],
[162.91429978609085,508.24201560020447],
[161.9224977493286,508.2384605407715],
[160.9033806324005,508.2341299057007],
[159.91058617830276,508.2292516231537],
[158.91730213165283,508.2237205505371],
[157.9100957661867,508.2174486517906],
[156.91582591831684,508.21060317754745],
[155.90762042999268,508.20299911499023],
[154.91236478090286,508.1948392391205],
[153.91661953926086,508.18602657318115],
[152.92038470506668,508.17656111717224],
[151.91018767654896,508.1663016676903],
[150.91296704113483,508.1555218100548],
[149.91525681316853,508.1440891623497],
[148.91705699265003,508.13200372457504],
[147.90486842393875,508.11908888816833],
[146.90568280220032,508.1056890487671],
[145.9060075879097,508.09163641929626],
[144.9058427810669,508.07693099975586],
[143.9051883816719,508.0615727901459],
[142.91757662594318,508.0457825064659],
[141.9159496575594,508.0291275382042],
[140.91383309662342,508.0118197798729],
[139.92477893829346,507.9941062927246],
[138.92168980836868,507.9755017757416],
[137.91811108589172,507.95624446868896],
[136.91404277086258,507.9363343715668],
[135.90948486328125,507.915771484375],
[134.91802234947681,507.8948468565941],
[133.91249187290668,507.8729872107506],
[132.92006993293762,507.8507833480835],
[131.9135668873787,507.82762694358826],
[130.920185521245,507.804143846035],
[129.9263277053833,507.78002548217773],
[128.91836911439896,507.7549283504486],
[127.90992093086243,507.7291784286499],
[126.91462071239948,507.7031368613243],
[125.91884404420853,507.6764600276947],
[124.90894031524658,507.6487693786621],
[123.92586135864258,507.62120056152344],
[122.9149916768074,507.59222197532654],
[121.9173026829958,507.5629953742027],
[120.91913723945618,507.5331335067749],
[119.92049534618855,507.50263637304306],
[118.9213770031929,507.4715039730072],
[117.92178221046925,507.4397363066673],
[116.92171096801758,507.40733337402344],
[115.9211632758379,507.37429517507553],
[114.9201391339302,507.3406217098236],
[113.93236094713211,507.30678725242615],
[112.91666150093079,507.2713689804077],
[111.92794346809387,507.2362813949585],
[110.92502005398273,507.20007556676865],
[109.92162019014359,507.1632344722748],
[108.93149882555008,507.1262757778168],
[107.92715258896351,507.088172852993],
[106.92232990264893,507.04943466186523],
[105.93080520629883,507.01060485839844],
[104.93881702423096,506.9711570739746],
[103.93257804214954,506.93053048849106],
[102.92586261034012,506.8892686367035],
[101.93247109651566,506.8479497432709],
[100.93861609697342,506.8060128688812],
[99.94429761171341,506.76345801353455],
[98.93569596111774,506.7196812033653],
[97.94044406712055,506.67588180303574],
[96.93089604377747,506.6308431625366],
[95.93471074104309,506.5857992172241],
[94.93806195259094,506.5401372909546],
[93.94094967842102,506.493857383728],
[92.94337391853333,506.44695949554443],
[91.94533467292786,506.3994436264038],
[90.94683194160461,506.35130977630615],
[89.9478657245636,506.30255794525146],
[88.94843602180481,506.25318813323975],
[87.94854283332825,506.203200340271],
[86.94818615913391,506.1525945663452],
[85.9473659992218,506.1013708114624],
[84.94608235359192,506.04952907562256],
[83.94534301757812,505.9921684265137],
[82.97195434570312,505.84467697143555],
[81.97903633117676,505.6778972148895],
[80.9947681427002,505.4952132701874],
[80.02001953125,505.29608154296875],
[79.03125,505.07421875],
[78.06689453125,504.83709716796875],
[77.10376739501953,504.5782117843628],
[76.14374542236328,504.29638957977295],
[75.18875122070312,503.99042892456055],
[74.24075317382812,503.6590995788574],
[73.3120288848877,503.3052260875702],
[72.3936767578125,502.9238739013672],
[71.47821807861328,502.5092191696167],
[70.57807159423828,502.0639982223511],
[69.6954345703125,501.5868377685547],
[68.84943389892578,501.0867795944214],
[68.0078125,500.5419921875],
[67.1905517578125,499.9609832763672],
[66.40003204345703,499.34226512908936],
[65.63867950439453,498.6843214035034],
[64.92182159423828,497.9985685348511],
[64.2374267578125,497.2715301513672],
[63.5880126953125,496.50163269042963],
[62.9864501953125,495.70182800292963],
[62.4327392578125,494.87211608886713],
[61.9268798828125,494.01249694824213],
[61.4688720703125,493.12297058105463],
[61.0587158203125,492.2035369873047],
[60.70246124267578,491.2714109420776],
[60.39759063720703,490.32816600799555],
[60.13721466064453,489.3571119308471],
[59.9249267578125,488.37699890136713],
[59.75830078125,487.3893432617187],
[59.63495635986328,486.39563274383545],
[59.5538330078125,485.4175262451172],
[59.50934600830078,484.416522026062],
[59.50128936767578,483.41374492645264],
[59.5274658203125,482.4105682373047],
[59.585723876953125,481.4083366394043],
[59.67395782470703,480.4083662033081],
[59.78862190246582,479.42337107658386],
[59.93034553527832,478.43196177482605],
[60.08388555049896,477.44265937805176],
[60.25209188461304,476.46619415283203],
[60.45285940170288,475.47119903564453],
[60.67979192733765,474.4982376098633],
[60.932889461517334,473.5473098754883],
[61.22371232509613,472.5822010040283],
[61.54280662536621,471.6408996582031],
[61.899333357810974,470.7004871368408],
[62.28558111190796,469.7851028442383],
[62.712196707725525,468.87307929992676],
[63.17001914978027,467.9873352050781],
[63.665130615234375,467.11767578125],
[64.19220542907715,466.2749328613281],
[64.76494359970093,465.4400100708008],
[65.36394345760345,464.6425189971924],
[65.99567222595215,463.8725891113281],
[66.66814184188843,463.1216506958008],
[67.35731649398804,462.41541290283203],
[68.09601402282715,461.7202453613281],
[68.8498764038086,461.0684814453125],
[69.63570213317871,460.4436340332031],
[70.4336485862732,459.85962677001953],
[71.26205444335938,459.30126953125],
[72.12091970443726,458.76856231689453],
[72.9881911277771,458.27356719970703],
[73.8616943359375,457.814453125],
[74.76275777816772,457.37854766845703],
[75.69138145446777,456.9658508300781],
[76.61027944087982,456.590913772583],
[77.55465841293335,456.23743438720703],
[78.48523473739624,455.9182815551758],
[79.43929433822632,455.61890411376953],
[80.40309941768646,455.34305000305176],
[81.37568509578705,455.0899066925049],
[82.35610008239746,454.8586730957031],
[83.32873570919037,454.65152168273926],
[84.30673635005951,454.464204788208],
[85.2892050743103,454.2959671020508],
[86.27525854110718,454.1460647583008],
[87.27986669540405,454.0117874145508],
[88.2707748413086,453.8966064453125],
[89.26269471645355,453.79759407043457],
[90.25479733943939,453.7140522003174],
[91.24626731872559,453.6452941894531],
[92.25350606441498,453.5898151397705],
[93.25904309749603,453.54821968078613],
[94.26209020614624,453.5198440551758],
[95.2618727684021,453.50403594970703],
[96.25762975215912,453.50015449523926],
[97.24861371517181,453.50757026672363],
[98.25280702114105,453.52610969543457],
[99.25124883651733,453.5551223754883],
[100.2432188987732,453.59400177001953],
[101.24743795394897,453.64319610595703],
[102.24424302577972,453.7014636993408],
[103.2528305053711,453.7696533203125],
[104.25307989120483,453.8461380004883],
[105.2443116903305,453.9303455352783],
[106.24149134755135,453.9778798222542],
[107.23524501919746,453.8833196759224],
[108.22189596295357,453.78371888399124],
[109.21718513965607,453.67734837532043],
[110.22076332569122,453.563960313797],
[111.21677315235138,453.44520115852356],
[112.20521461963654,453.3210709095001],
[113.18608772754669,453.1915695667267],
[114.18968629837036,453.0523958206177],
[115.17021206021309,452.90974789857864],
[116.15783503651619,452.7592366337776],
[117.15218937397003,452.60060238838196],
[118.12359285354614,452.4385805130005],
[119.11601448059082,452.26567459106445],
[120.09989213943481,452.0867052078247],
[121.07522583007812,451.90167236328125],
[122.07032120227814,451.7048637866974],
[123.04234007000923,451.50457841157913],
[124.01945689320564,451.29501086473465],
[125.00128364562988,451.0758857727051],
[125.97379592061043,450.8501504063606],
[126.93699371814728,450.6178047657013],
[127.91761210560799,450.3720219731331],
[128.88838386535645,450.1192512512207],
[129.84930899739265,449.85949259996414],
[130.81334760785103,449.5890434384346],
[131.78008949756622,449.307612657547],
[132.7237548828125,449.022705078125],
[133.6823925971985,448.7226228713989],
[134.63035774230957,448.41496658325195],
[135.5799119770527,448.0955379605293],
[136.51851093769073,447.7683346271515],
[137.4581298828125,447.428955078125],
[138.41016387939453,447.0725860595703],
[139.32701122760773,446.71704602241516],
[140.25568974018097,446.34410214424133],
[141.1953125,445.953125],
[142.10004425048828,445.5631866455078],
[143.01512157917023,445.15479016304016],
[143.9177746772766,444.7375764846802],
[144.8295612335205,444.3010444641113],
[145.72831010818481,443.8552598953247],
[146.61402130126953,443.4002227783203],
[147.49700525403023,442.93035012483597],
[148.36663925647736,442.4510033130646],
[149.23291778564453,441.9563751220703],
[150.1051959991455,441.4402046203613],
[150.9534960091114,440.91991740465164],
[151.7973189651966,440.38355284929276],
[152.63617706298828,439.8307647705078],
[153.45121955871582,439.2739753723144],
[154.2699853181839,438.6940281391143],
[155.07378435134888,438.1034593582153],
[155.879976272583,437.48878860473627],
[156.65350925922394,436.87674069404596],
[157.42876255512238,436.24011349678034],
[158.18837654590607,435.5923874378204],
[158.94835090637207,434.9191169738769],
[159.67634630203247,434.2489519119262],
[160.40401470661163,433.55275464057917],
[161.1153564453125,432.84497070312494],
[161.80306366086006,432.13331395387644],
[162.48905992507935,431.39464282989496],
[163.15142166614532,430.65209889411926],
[163.797456741333,429.8979682922363],
[164.44038581848145,429.1158332824707],
[165.05342018604279,428.3382828235626],
[165.66264641284943,427.53222966194147],
[166.24268007278442,426.73125934600824],
[166.81820344924927,425.9012880325317],
[167.36523640155792,425.0768978595733],
[167.90705692768097,424.2230083942413],
[168.42108917236328,423.37519836425776],
[168.9187947511673,422.51580166816706],
[169.4102383852005,421.6261608600616],
[169.87494325637817,420.74334430694574],
[170.32332146167755,419.8489410877228],
[170.7463504076004,418.96234822273254],
[171.16242265701294,418.04501819610596],
[171.56216824054718,417.1161015033722],
[171.9375993013382,416.19572949409485],
[172.29739105701447,415.26425862312317],
[172.641543507576,414.32168889045715],
[172.96308135986328,413.3888702392578],
[173.2762951850891,412.4243440628052],
[173.56756699085236,411.4700462818146],
[173.8438720703125,410.505126953125],
[174.09956550598145,409.5513801574707],
[174.34361079335213,408.576466858387],
[174.5705237686634,407.6022952198982],
[174.78079223632812,406.62921142578125],
[174.97924995422363,405.6348457336426],
[175.1593724489212,404.6532680988312],
[175.32397201657295,403.6735742688179],
[175.47522163391113,402.6844062805176],
[175.61157655715942,401.6975679397583],
[175.73489379882812,400.70147705078125],
[175.84393718838692,399.7081564068794],
[175.94025146961212,398.70580220222473],
[175.9964819289744,397.7080001048744],
[175.98448138311505,396.7119547985494],
[175.972488489002,395.7165445871651],
[175.96049881866202,394.72140194894746],
[175.94829784851754,393.7087214269559],
[175.93628229340538,392.71143035264686],
[175.92434265644988,391.7204404853401],
[175.91213024919853,390.70681068347767],
[175.90013541886583,389.71123976586387],
[175.8881857804954,388.71941978111863],
[175.87602377991425,387.70997373288264],
[175.8641042523086,386.7206529416144],
[175.8521230132319,385.726210098248],
[175.83992272183968,384.7135859126938],
[175.8279668960531,383.72125237240834],
[175.81587578350445,382.71769003086956],
[175.80390416117007,381.72404537711554],
[175.79185935568967,380.72432652224234],
[175.77976441878127,379.72044675884536],
[175.76764138555154,378.714235000778],
[175.75551130789245,377.70743855507317],
[175.74364905944094,376.72287193359807],
[175.73156886666402,375.72021593311365],
[175.71953901899542,374.7217385766198],
[175.70744298950467,373.71776812888766],
[175.6954273148076,372.720467129031],
[175.68337009322568,371.7197177377311],
[175.67128096527904,370.7163201181602],
[175.65931099767477,369.72281280700554],
[175.6471875029083,368.7165627413897],
[175.63520432433143,367.7219589195083],
[175.62322795862565,366.7279205659288],
[175.6111178848878,365.72278444568656],
[175.5990271648543,364.7192546829074],
[175.58696336890625,363.7179596192191],
[175.57493379824882,362.7195052546522],
[175.5629454914597,361.72447579115396],
[175.5508494832393,360.72050710886106],
[175.53880544211916,359.7208516958899],
[175.52666145836702,358.71290104446234],
[175.5145796169527,357.71010820707306],
[175.5027267783371,356.7263226019786],
[175.49062651169243,355.72200047047136],
[175.47844069683924,354.7105778376572],
[175.4664998715898,353.7194893419537],
[175.45448230373586,352.72203121007624],
[175.4423901161308,351.7183796388572],
[175.43039255139865,350.7225817660883],
[175.41832649918342,349.72109943222404],
[175.406362949092,348.72812477463594],
[175.3941669130072,347.71585377959855],
[175.3822498084046,346.72673409758136],
[175.3701041240265,345.71864229420044],
[175.35807388591365,344.7201325308333],
[175.34599030320805,343.71719516626854],
[175.3340289592743,342.72440361976624],
[175.32184499801224,341.7131348350158],
[175.3099634039463,340.72696252754395],
[175.29786277787844,339.72261056391017],
[175.28571930975886,338.71470270998543],
[175.2737116641365,337.71806812332943],
[175.26166586618638,336.71826689346926],
[175.24958363175392,335.7154414355755],
[175.23764511051832,334.72454417302015],
[175.2254956235911,333.7161367580602],
[175.2134941705517,332.7200161557921],
[175.20146395882057,331.72150858210716],
[175.18940662965178,330.72075026109815],
[175.1773238242995,329.71787741685785],
[175.16521718401782,328.71302627347904],
[175.15326953260228,327.72137120598927],
[175.14130191392178,326.7280588555077],
[175.1291341571241,325.71813504130023],
[175.11713106755542,324.7218786070998],
[175.10511274106193,323.7243575081402],
[175.09308074650016,322.7257019595136],
[175.08103665272665,321.72604217631215],
[175.06898202859793,320.72550837362814],
[175.05691844297053,319.7242307665538],
[175.04484746470098,318.72233957018125],
[175.03277066264582,317.7199649996028],
[175.02068960566157,316.7172372699106],
[175.00878896191716,315.72948383912444],
[174.99670410686667,314.72644086993387],
[174.98461967968615,313.72343341395026],
[174.97253724923212,312.72059168626583],
[174.96045838436112,311.7180459019728],
[174.94838465392968,310.7159262761634],
[174.93631762679433,309.71436302392976],
[174.92425887181162,308.7134863603642],
[174.91220995783806,307.71342650055885],
[174.9001724537302,306.714313659606],
[174.88814792834455,305.7162780525978],
[174.87613795053767,304.71944989462645],
[174.86414408916607,303.7239594007842],
[174.8521679130863,302.7299367861633],
[174.84002998185042,301.72248849358493],
[174.8279135338962,300.71682331338525],
[174.81600052986323,299.7280439786482],
[174.80393159053074,298.7263220140512],
[174.79188903374597,297.72678980091587],
[174.77969539997866,296.7147181982291],
[174.76771098724475,295.7200119413146],
[174.7557579053173,294.72790614133555],
[174.7436601414447,293.72379173991067],
[174.73159805706382,292.7226387362971],
[174.71957336802006,291.72458954566537],
[174.70758779015887,290.72978658318607],
[174.69546769443605,289.7238186381919],
[174.6833914280287,288.721488526382],
[174.6713607836,287.7229450387995],
[174.6593775538131,286.7283369664874],
[174.64727094507543,285.723488441261],
[174.63521685201704,284.72299871741416],
[174.623217146378,283.7270231493749],
[174.61110349593127,282.7215901622958],
[174.59904967663897,281.7211231610345],
[174.58705764161004,280.7257842536337],
[174.5749618043019,279.7218297570571],
[174.56293354923127,278.7234845861958],
[174.55080932084093,277.7171736297969],
[174.53892335021374,276.7306380677401],
[174.52678405446932,275.7230765209533],
[174.51472484344413,274.72216200586263],
[174.50274792843265,273.72807805991033],
[174.49069540139226,272.72771831555747],
[174.47857308340735,271.72156592281044],
[174.4665446618592,270.72320693431266],
[174.45445618306985,269.71986319479765],
[174.4424690381502,268.72493016646604],
[174.430432083499,267.72586293041695],
[174.41835205684038,266.72322071775125],
[174.40623595148645,265.71758397337544],
[174.39424019629223,264.7219362922551],
[174.38221996871926,263.7242574036982],
[174.37018289882053,262.72518060210405],
[174.35813689319184,261.72536213492276],
[174.34609014168382,260.7254817597568],
[174.33405112419587,259.7262433082569],
[174.32188977015903,258.7168509231997],
[174.31003170245822,257.7326313040321],
[174.29779995809076,256.7173965215334],
[174.2857540365876,255.7175850367712],
[174.27389791607857,254.7335270345211],
[174.26172180892172,253.7229101405028],
[174.2497616209439,252.73021453834372],
[174.23777453129878,251.73528609779896],
[174.22553768754005,250.7196280658245],
[174.2135610320256,249.72556565812556],
[174.20161854857724,248.73433953191125],
[174.18950679601403,247.72906406916445],
[174.1774866580963,246.73139262199402],
[174.16536993905902,245.72570494189858],
[174.1534091880385,244.73296260719508],
[174.14123356869823,243.72238620195276],
[174.12930054945173,242.73194560449338],
[174.11727431957843,241.7337685250095],
[174.1050629405654,240.7202240669285],
[174.09311625966802,239.72864955244586],
[174.0810354209898,238.72593994215276],
[174.06898951960466,237.72613012718648],
[174.05689978942974,236.72268252266804],
[174.044814501598,235.71960363263497],
[174.03283922519768,234.72565569140716],
[174.02077567437664,233.72438097326085],
[174.0086982329376,232.72195333382115],
[173.97630310058594,231.73455810546875],
[173.88281917572021,230.74241256713867],
[173.78029817342758,229.73901057243347],
[173.67023634910583,228.7409315109253],
[173.55263370275497,227.74817538261414],
[173.427490234375,226.7607421875],
[173.29256796836853,225.76257610321045],
[173.1498556137085,224.7699089050293],
[172.9993531703949,223.78274059295654],
[172.84106063842773,222.8010711669922],
[172.674978017807,221.82490062713623],
[172.49536681175232,220.82300853729248],
[172.3134527206421,219.85801315307617],
[172.11749935150146,218.86765670776367],
[171.91324520111084,217.88315963745117],
[171.70069026947021,216.90452194213867],
[171.4798345565796,215.93174362182617],
[171.25067806243896,214.96482467651367],
[171.00944465398788,213.98879504203796],
[170.75964903831482,213.01880931854248],
[170.50129121541977,212.05486750602722],
[170.23437118530273,211.0969696044922],
[169.95458388328552,210.13051891326904],
[169.6659688949585,209.1702995300293],
[169.36852622032166,208.21631145477295],
[169.062255859375,207.2685546875],
[168.74715781211853,206.32702922821045],
[168.41825622320175,205.37761187553406],
[168.08025741577148,204.4346160888672],
[167.73316138982773,203.49804186820984],
[167.37696814537048,202.567889213562],
[167.00615668296814,201.63041973114014],
[166.63163322210312,200.71320605278015],
[166.24221795797348,199.7888686656952],
[165.84343189001083,198.87114596366882],
[165.43527501821518,197.96003794670105],
[165.0115373134613,197.04229259490967],
[164.5845010280609,196.14451122283936],
[164.14809393882751,195.25334453582764],
[163.6956905722618,194.3558337688446],
[163.23363876342773,193.4651336669922],
[162.76884365081787,192.5940055847168],
[162.28058981895447,191.7041654586792],
[161.79677748680115,190.84646129608154],
[161.28894710540771,189.97043991088867],
[160.77865308523178,189.11379313468933],
[160.25871062278748,188.2639570236206],
[159.72137355804443,187.40876388549805],
[159.18199437856674,186.5726478099823],
[158.63296675682068,185.74334239959717],
[158.06612300872803,184.90897750854492],
[157.49765866994858,184.09339213371277],
[156.91954588890076,183.28461742401123],
[156.3231954574585,182.4710807800293],
[155.7256459593773,181.67602610588074],
[155.1184480190277,180.8877820968628],
[154.49259090423584,180.09507369995117],
[153.86595624685287,179.3205497264862],
[153.22967314720154,178.55283641815186],
[152.57430934906006,177.78095626831055],
[151.92816162109375,177.037841796875],
[151.25322127342224,176.27978038787842],
[150.58805632591248,175.5500898361206],
[149.90353101491928,174.81642985343933],
[149.20935726165771,174.08958053588867],
[148.51580429077148,173.3799285888672],
[147.80247348546982,172.666601896286],
[147.09004110097885,171.970276594162],
[146.36823791265488,171.28056597709656],
[145.6370639204979,170.59747004508972],
[144.8965191245079,169.92098879814148],
[144.15769958496094,169.26092529296875],
[143.39855098724365,168.59757614135742],
[142.6414012312889,167.95045161247253],
[141.87515425682068,167.30974864959717],
[141.09981006383896,166.67546725273132],
[140.31536865234375,166.047607421875],
[139.53374075889587,165.43539714813232],
[138.74328517913818,164.82941818237305],
[137.94400191307068,164.22967052459717],
[137.13589096069336,163.6361541748047],
[136.31895232200623,163.0488691329956],
[135.49318599700928,162.46781539916992],
[134.6840124130249,161.91032028198242],
[133.84085822105408,161.3415403366089],
[133.01482367515564,160.79594707489014],
[132.1804883480072,160.2562131881714],
[131.32462018728256,159.71404337882996],
[130.48691534996033,159.1943235397339],
[129.6276776790619,158.67216777801514],
[128.76013922691345,158.1558713912964],
[127.89804881811142,157.6533648967743],
[127.02791500091553,157.15653610229492],
[126.16373991966248,156.6731367111206],
[125.29177474975586,156.1952362060547],
[124.3977660536766,155.7152602672577],
[123.52447414398193,155.25593185424805],
[122.62913870811462,154.79452800750732],
[121.74064153432846,154.34593272209167],
[120.84460353851318,153.90266036987305],
[119.94102472066879,153.46471095085144],
[119.02990508079529,153.03208446502686],
[118.12636542320251,152.61174297332764],
[117.21553015708923,152.196551322937],
[116.31276124715805,151.79330134391785],
[115.3874563574791,151.38832354545593],
[114.470458984375,150.9951171875],
[113.54640716314316,150.60689043998718],
[112.63114112615585,150.23009753227234],
[111.69309997558594,149.85174560546875],
[110.76408171653748,149.4846601486206],
[109.82824611663818,149.12238693237305],
[108.90190356969833,148.77104830741882],
[107.96897673606873,148.4243574142456],
[107.02946561574936,148.0823142528534],
[106.08337020874023,147.7449188232422],
[105.13069051504135,147.412171125412],
[104.18831247091293,147.08978724479675],
[103.23957914113998,146.7718894481659],
[102.30160140991211,146.4640350341797],
[101.34027099609375,146.155029296875],
[100.38992112874985,145.85590815544128],
[99.4334409236908,145.56111431121826],
[98.4708303809166,145.27064776420593],
[97.5197576880455,144.98967242240906],
[96.54499793052673,144.707781791687],
[95.59988397359848,144.4402358531952],
[94.63108295202255,144.1717746257782],
[93.66746819019318,143.91388940811157],
[92.69054940342903,143.65744936466217],
[91.7219409942627,143.39798736572266],
[90.76164296269417,143.13550341129303],
[89.80965530872345,142.8699975013733],
[88.83707359433174,142.59315931797028],
[87.87332153320312,142.3131103515625],
[86.9183991253376,142.02985060214996],
[85.97230637073517,141.74338006973267],
[85.00720098614693,141.4450021982193],
[84.05146026611328,141.14321899414062],
[83.10508421063423,140.83803045749664],
[82.1545617878437,140.52493917942047],
[81.20030605792999,140.20379495620728],
[80.2559657394886,139.87904512882233],
[79.32154083251953,139.55068969726562],
[78.37113240361214,139.2093254327774],
[77.43120586872101,138.8641495704651],
[76.50176122784615,138.51516211032867],
[75.5577689409256,138.1526436805725],
[74.63720703125,137.791015625],
[73.7029767036438,137.41553688049316],
[72.78010749816895,137.03592681884766],
[71.86859941482544,136.65218544006348],
[70.9449177980423,136.25404977798462],
[70.033203125,135.8515625],
[69.11054229736328,135.43423461914062],
[68.21176818013191,135.01763117313385],
[67.30298614501953,134.58584594726562],
[66.4071092903614,134.14936792850494],
[65.5133173763752,133.7027212381363],
[64.6220942735672,133.2457299232483],
[63.73392975330353,132.77821588516235],
[62.859644055366516,132.305655002594],
[61.978920459747314,131.81661415100098],
[61.11274445056915,131.32228326797485],
[60.26111602783203,130.82266235351562],
[59.404741287231445,130.30594635009766],
[58.54465878009796,129.77175760269165],
[57.70050859451294,129.23177528381348],
[56.87229073047638,128.68599939346313],
[56.04213535785675,128.12210702896118],
[55.228628277778625,127.55216073989868],
[54.414631366729736,126.96357154846191],
[53.618014454841614,126.36866235733032],
[52.82238686084747,125.75457239151001],
[52.04488670825958,125.1338906288147],
[51.26988649368286,124.49347877502441],
[50.513776898384094,123.84619760513306],
[49.761709332466125,123.17862558364868],
[49.029311299324036,122.50390100479126],
[48.302528858184814,121.80831336975098],
[47.59621059894562,121.10528421401978],
[46.8971129655838,120.38080835342406],
[46.219289898872375,119.64859628677367],
[45.56274139881134,118.90864801406859],
[44.91545915603638,118.14650917053221],
[44.27869415283203,117.36172485351561],
[43.66487169265747,116.56859779357909],
[43.073991775512695,115.76712799072264],
[42.49575340747833,114.94224023818968],
[41.94131517410278,114.10869789123534],
[41.41067707538605,113.2665009498596],
[40.903839111328125,112.4156494140625],
[40.41223907470703,111.54043579101562],
[39.9453125,110.65624999999999],
[39.50305938720703,109.76309204101561],
[39.07824718952179,108.8447709083557],
[38.685781598091125,107.93350839614867],
[38.31164598464966,106.99675941467284],
[37.9689679145813,106.06739234924315],
[37.645508885383606,105.1122155189514],
[37.35261833667755,104.16474390029907],
[37.08440124988556,103.20830011367798],
[36.83673286437988,102.2255630493164],
[36.618303298950195,101.25101470947266],
[36.42454719543457,100.26749420166016],
[36.258267521858215,99.2928032875061],
[36.113417744636536,98.29149866104126],
[35.99517107009888,97.29934120178223],
[35.90222930908203,96.31680297851562],
[35.83115017414093,95.30749368667603],
[35.784518361091614,94.30811548233032],
[35.761059284210205,93.31913185119629],
[35.759700894355774,92.3221116065979],
[35.78044319152832,91.31705474853516],
[35.82227325439453,90.32315063476562],
[35.88537788391113,89.3215103149414],
[35.969757080078125,88.3121337890625],
[36.026872754096985,87.31841790676117],
[36.075668811798096,86.3170599937439],
[36.13523590564728,85.32451474666595],
[36.206598311662674,84.32754883170128],
[36.2890248298645,83.33963537216187],
[36.385159999132156,82.33479532599449],
[36.49295723438263,81.3394969701767],
[36.61404466629028,80.34100103378296],
[36.74709904193878,79.35229623317719],
[36.892120361328125,78.37338256835938],
[37.05329132080078,77.3795394897461],
[37.22705078125,76.39599609375],
[37.41339874267578,75.4227523803711],
[37.617469906806946,74.43586671352386],
[37.83476686477661,73.45980215072632],
[38.06528961658478,72.49455869197845],
[38.31514847278595,71.5169929265976],
[38.57888615131378,70.55078256130219],
[38.856502652168274,69.59592759609222],
[39.155107498168945,68.63010215759277],
[39.464535027742386,67.6872030198574],
[39.792065888643265,66.74504432082176],
[40.1382075548172,65.80404126644135],
[40.50347340106964,64.86461389064789],
[40.87956193089485,63.948112815618515],
[41.279701352119446,63.023146986961365],
[41.6906634569168,62.1211074590683],
[42.12672090530395,61.2114577293396],
[42.57360103726386,60.324734300374985],
[43.04134610295295,59.44101080298424],
[43.53048717975616,58.56072175502777],
[44.03027391433715,57.703214168548584],
[44.54599297046661,56.858742117881775],
[45.08963978290557,56.0089772939682],
[45.64993488788604,55.17283380031586],
[46.220517665147774,54.35917887091637],
[46.807388782501214,53.55885076522827],
[47.41726684570312,52.763275146484375],
[48.043793201446526,51.98132085800171],
[48.686967849731445,51.21298789978027],
[49.34679079055786,50.45827627182007],
[50.02326202392578,49.717185974121094],
[50.716381549835205,48.98971700668335],
[51.410542607307434,48.29124295711517],
[52.13659679889679,47.590720534324646],
[52.86297655105591,46.918607234954834],
[53.621965408325195,46.24503135681152],
[54.380563855171204,45.599278807640076],
[55.155094623565674,44.966561794281006],
[55.945557713508606,44.346880316734314],
[56.73386013507843,43.75357377529144],
[57.55583381652832,43.1596736907959],
[58.374946594238274,42.591575622558594],
[59.209291458129876,42.035940170288086],
[60.039390683174126,41.50497376918793],
[60.90385353565215,40.97398030757904],
[61.76338624954223,40.46709585189819],
[62.62721922993659,39.9778011739254],
[63.50526344776153,39.500133872032166],
[64.38693916797638,39.03950917720795],
[65.28249371051788,38.59023988246918],
[66.19192707538605,38.15232598781586],
[67.09309434890747,37.73579168319702],
[68.0074874162674,37.33007848262787],
[68.93510627746582,36.9351863861084],
[69.85284614562988,36.560354232788086],
[70.78317451477051,36.19582176208496],
[71.7260913848877,35.84158897399902],
[72.65755569934845,35.506128668785095],
[73.60098695755005,35.18045949935913],
[74.55638515949249,34.86458146572113],
[75.51127246022224,34.56235679984093],
[76.47782182693481,34.2696738243103],
[77.43047764897346,33.9937661588192],
[78.39419758319855,33.72691094875336],
[79.35591173171997,33.47248601913452],
[80.32839691638947,33.22687375545502],
[81.29829409718513,32.993215411901474],
[82.27867317199707,32.76813316345215],
[83.25588989257812,32.554534912109375],
[84.22951939702034,32.352073043584824],
[85.21306431293488,32.157723784446716],
[86.19246339797974,31.974053859710693],
[87.18150055408478,31.798269629478455],
[88.16584113240242,31.632714122533798],
[89.15954637527466,31.474820613861084],
[90.14801216125488,31.326711654663086],
[91.13083723187447,31.18805894255638],
[92.12249195575714,31.056630492210388],
[93.10797882080078,30.934226989746094],
[94.1020337343216,30.818833708763123],
[95.10465669631958,30.710450649261475],
[96.10046270489693,30.610561460256577],
[97.10457909107208,30.51747167110443],
[98.10136711597443,30.432457327842712],
[99.09044912457466,30.355209439992905],
[100.08733797073364,30.284348964691162],
[101.07602512836456,30.22084939479828],
[102.08840373158455,30.162662357091904],
[103.07608234882355,30.112408995628357],
[104.08745241165161,30.06746816635132],
[105.07339668273926,30.02986717224121],
[106.07674470543861,30],
[107.08785971999168,30],
[108.0756454646471,30],
[109.07874405058101,30],
[110.07948419684544,30],
[111.07830870858015,30],
[112.08210094971582,30],
[113.08306206390262,30],
[114.08056369423866,30],
[115.07993692268064,30],
[116.07124380335881,30],
[117.08768628910184,30],
[118.08177876629634,30],
[119.0796565375249,30],
[120.08901651477208,30],
[121.07061504071316,30],
[122.08115196228027,30],
[123.0817082169151,30],
[124.08207283349475,30],
[125.07998871692598,30],
[126.07329938933253,30],
[127.07398553518578,30],
[128.08090964804342,30],
[129.07837687578285,30],
[130.0793840144761,30],
[131.0829163654953,30],
[132.07264113350539,30],
[133.0780654435339,30],
[134.08316413491866,30],
[135.08704429137288,30],
[136.08884168408258,30],
[137.07124004887737,30],
[138.08287053439108,30],
[139.07351076142368,30],
[140.07597296629774,30],
[141.07282076079264,30],
[142.08080323163904,30],
[143.08211582354124,30],
[144.07606693862908,30],
[145.0800030063474,30],
[146.0756055544889,30],
[147.0805922714644,30],
[148.0763177689878,30],
[149.08085514232516,30],
[150.0752464805746,30],
[151.07790492880304,30],
[152.06957397842052,30],
[153.08828678838017,30],
[154.07605526930274,30],
[155.0710782279939,30],
[156.07325794169446,30],
[157.0824966876453,30],
[158.07870496191845,30],
[159.08151214321242,30],
[160.07057669392088,30],
[161.08617803800007,30],
[162.08760397075002,30],
[163.07439854029417,30],
[164.08759298408404,30],
[165.0857495344244,30],
[166.0789226366095,30],
[167.0774021795214,30],
[168.0705169226494,30],
[169.07931870549453,30],
[170.0717945122742,30],
[171.07980438789218,30],
[172.0710273558152,30],
[173.08848493173718,30],
[174.08880803739157,30],
[175.07163608993415,30],
[176.0805799230011,30],
[177.07170403378404,30],
[178.08898796961876,30],
[179.08813361453304,30],
[180.07997895382266,30],
[181.0755392082283,30],
[182.08599702996344,30],
[183.07754479033156,30],
[184.0725340514391,30],
[185.07090205902477,30],
[186.07258605882726,30],
[187.07752329658524,30],
[188.08565101803742,30],
[189.0738891152214,30],
[190.08814057966083,30],
[191.08224335959767,30],
[192.07915623666486,30],
[193.07882063879424,30],
[194.08117799391766,30],
[195.08616972996697,30],
[196.07027665511032,30],
[197.08030356164818,30],
[198.08100347807715,30],
[199.0840499915348,30],
[200.0775459660881,30],
[201.07322383876726,30],
[202.07102902963106,30],
[203.0709069587383,30],
[204.07280304614778,30],
[205.0766627119183,30],
[206.08243137610862,30],
[207.0780482518254,30],
[208.07542300224304,30],
[209.0865502625602,30],
[210.0752355120958,30],
[211.07756796398837,30],
[212.08144767549675,30],
[213.07470036174982,30],
[214.08149957162925,30],
[215.07753379250096,30],
[216.08704441555437,30],
[217.0856545928982,30],
[218.08546847276466,30],
[219.0864352816934,30],
[220.08850424622415,30],
[221.0793852878877,30],
[222.0712432861328,30],
[223.07629151408128,30],
[224.08224004216754,30],
[225.0890380969313,30],
[226.07205026751308,30],
[227.08037741592852,30],
[228.0894030090176,30],
[229.07444278817002,30],
[230.08469899582997,30],
[231.070842706009,30],
[232.08213135770256,30],
[233.08152633314683,30],
[234.0813085869886,30],
[235.08142918077203,30],
[236.08183917604129,30],
[237.07013465664295,30],
[238.08333161721384,30],
[239.071957680404,30],
[240.0853944028595,30],
[241.07415771152864,30],
[242.08763602533145,30],
[243.07634324237006,30],
[244.07730822404494,30],
[245.07812276528148,30],
[246.07873792762382,30],
[247.07910477261612,30],
[248.07917436180253,30],
[249.0788977567272,30],
[250.0782260189343,30],
[251.07711020996794,30],
[252.08782392244848,30],
[253.07335062469156,30],
[254.08291690529586,30],
[255.07952722218454,30],
[256.0754481714421,30],
[257.08291220123647,30],
[258.077297574977,30],
[259.07084616154043,30],
[260.07575837944023,30],
[261.07971209347943,30],
[262.0826565301977,30],
[263.08454091613476,30],
[264.08531447783025,30],
[265.0849264418239,30],
[266.08332603465533,30],
[267.0804624828643,30],
[268.0762850129904,30],
[269.0707428515734,30],
[270.08798766004566,30],
[271.079527398957,30],
[272.0816127396464,30],
[273.08208991997253,30],
[274.0809062861641,30],
[275.0780091844499,30],
[276.0853269876461,30],
[277.0788227548363,30],
[278.07044645842416,30],
[279.0720575791338,30],
[280.07164274440674,30],
[281.08100967738017,30],
[282.08818969103595,30],
[283.08131670951843,30],
[284.072198153277,30],
[285.0842886085784,30],
[286.0704584327241,30],
[287.07761065130035,30],
[288.08217752603105,30],
[289.084100484848,30],
[290.0833209556831,30],
[291.0797803664682,30],
[292.07342014513506,30],
[293.08718797065103,30],
[294.07494377484545,30],
[295.08256811700994,30],
[296.08699199184775,30],
[297.0881526450976,30],
[298.0859873224981,30],
[299.08043326978805,30],
[300.07142773270607,30],
[301.08130935548706,30],
[302.08744805536844,30],
[303.07866089273523,30],
[304.0882281626691,30],
[305.0827353082068,30],
[306.07323100708163,30],
[307.08152167027583,30],
[308.0854787651115,30],
[309.08503058506176,30],
[310.0801054235999,30],
[311.07063157419907,30],
[312.0779182140277,30],
[313.080304903684,30],
[314.07771515772856,30],
[315.07007249072194,30],
[316.07824897766113,30],
[317.08099399134517,30],
[318.0782260596752,30],
[319.06986371055245,30],
[320.0763054027275,30],
[321.076745575534,30],
[322.07109755804686,30],
[323.0793766569159,30],
[324.0811342012885,30],
[325.0762781048261,30],
[326.0844160138513,30],
[327.085479561566,30],
[328.079371025211,30],
[329.08526487697236,30],
[330.0834974518293,30],
[331.07396516517474,30],
[332.0753827757753,30],
[333.0871747661158,30],
[334.071755951496,30],
[335.0844971227534,30],
[336.0879566356889,30],
[337.08201192338583,30],
[338.084351991939,30],
[339.07668631550314,30],
[340.0763318919926,30],
[341.0825959897702,30],
[342.0777031071484,30],
[343.07837173903954,30],
[344.0838520750103,30],
[345.07692329801193,30],
[346.0736606121063,30],
[347.0732537378135,30],
[348.07486506737496,30],
[349.0776289796313,30],
[350.08065114384954,30],
[351.0830078125,30],
[352.0837451029829,30],
[353.08187826830454,30],
[354.0763909567031,30],
[355.0802346165174,30],
[356.07775520534324,30],
[357.08122555561295,30],
[358.0754467509687,30],
[359.08474539760937,30],
[360.08096871080943,30],
[361.07485079269827,30],
[362.0756519860661,30],
[363.0796511084927,30],
[364.0719640101961,30],
[365.08127427101135,30],
[366.0703455507755,30],
[367.07432280343346,30],
[368.08382464410533,30],
[369.0886672195629,30],
[370.07782124442747,30],
[371.08316084742546,30],
[372.07911651954055,30],
[373.08258776488947,30],
[374.0761750883539,30],
[375.0752791054547,30]];

export default PointsTraj2;
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import 'semantic-ui-css/semantic.min.css';
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyCYaVpPKV2ioLwyafufd7_YMpJzMKFMvbA',
  authDomain: 'questionando-cbaa2.firebaseapp.com',
  databaseURL: 'https://questionando-cbaa2-default-rtdb.firebaseio.com',
  projectId: 'questionando-cbaa2',
  storageBucket: 'questionando-cbaa2.appspot.com',
  messagingSenderId: '522015149392',
  appId: '1:522015149392:web:7f3ac57978b79d75d0f055',
  measurementId: 'G-CCE0D4BKQ8',
};

firebase.initializeApp(firebaseConfig);

ReactDOM.render(<App />, document.getElementById('root'));

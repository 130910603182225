import React from 'react';
import '../../../Common/kinematicsMenu.css';

import viagem from '../../../../images/Physics/Gravitation/viagem.png';
import treinamento from '../../../../images/Physics/Gravitation/treinamento.png';
import registros from '../../../../images/Physics/Gravitation/registros.png';
import comunicacao from '../../../../images/Physics/Gravitation/comunicacao.png';
import proximaMissao from '../../../../images/Physics/Gravitation/proximaMissao.png';
import pesquisas from '../../../../images/Physics/Gravitation/pesquisas.png';
import robo from '../../../../images/Physics/Gravitation/face_robo.PNG';
import bckgd from '../../../../images/Physics/Gravitation/menuBgdTwo.png';
import ChallengeMenuComponent from '../../../../components/MapComponents/challengeMenuComponent';

function ChallengeMenu(props) {
  let code =
    "if (acessMission === 'false') { \n" +
    "  missionMessage = 'Investigue os registros de viagem.'; \n" +
    "} else if (acessMission === 'true' && arrStars[0] === false) { \n" +
    "missionMessage = 'Aprenda novos conteúdos com as pesquisas.'; \n" +
    "} else if (acessMission === 'true' && arrStars[3] === false && acquiredStars <= 2) { \n" +
    "missionMessage = 'Realize a missão de viagem. Você conseguirá ajuda com o treinamento de astronautas.'; \n" +
    "} else if (acessMission === 'true' && arrStars[3] === false && acquiredStars === 3) { \n" +
    "missionMessage = 'Realize a missão de viagem.'; \n" +
    "} else if (acessMission === 'true' && arrStars[3] === true && acquiredStars <= 3) { \n" +
    'missionMessage = \n' +
    "'Veja seu desempenho na Próxima Missão. Você pode conseguir mais estrelas com o treinamento de astronautas.'; \n" +
    "} else if (acessMission === 'true' && acquiredStars === 4) { \n" +
    "missionMessage = 'Veja seu desempenho em Próxima Missão.'; \n" +
    '} else { \n' +
    " missionMessage = 'Bug';" +
    '}';

  return (
    <>
      <ChallengeMenuComponent
        background={bckgd}
        subject="physics"
        typeScoring="gravitation"
        arrayScoring="arrStarLawOfUniversalGravitation"
        acessMessage="acessMuseum"
        messagePosition={1}
        setIsMinimized={props.setIsMinimized}
        setPresentationItems={props.setPresentationItems}
        setActiveItem={props.setActiveItem}
        validadeMessage={code}
        title="Desafio 2: Gravitação Universal"
        titleColor="branco"
        emissor="Robo EE14:"
        imageEmissor={robo}
        acessPoints={[
          {
            position: 'position2',
            image: viagem,
            content: ['Explicação do Desafio'],
            start: 'Explicação do Desafio',
            setInfo: null,
            setValue: null,
          },
          {
            position: 'position3',
            image: treinamento,
            content: ['Treinamento de astronautas'],
            start: 'Treinamento de astronautas',
            setInfo: null,
            setValue: null,
          },
          {
            position: 'position4',
            image: registros,
            content: ['Registros', 'Conquistas', 'Sua primeira conquista'],
            start: 'Registros',
            setInfo: 'acessMuseum',
            setValue: ['true', 'true', 'false', 'false'],
          },
          {
            position: 'position5',
            image: comunicacao,
            content: ['Assistente de comunicação', 'Mensagem do Imperador'],
            start: 'Assistente de comunicação',
            setInfo: null,
            setValue: null,
          },
          {
            position: 'position1',
            image: pesquisas,
            content: [
              'Pesquisas',
              'A Gravidade',
              'Lei da Gravitação Universal',
              'A Constante G',
              'As Marés'
            ],
            start: 'Pesquisas',
            setInfo: null,
            setValue: null,
          },
          {
            position: 'position6',
            image: proximaMissao,
            content: ['Você conseguiu!'],
            start: 'Você conseguiu!',
            messageWithoutAcess: 'Você ainda não tem acesso à Próxima Missão.',
            validateAcess: 'arrStars[3] === true',
          },
        ]}
      />
    </>
  );
}

export default ChallengeMenu;

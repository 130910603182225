import React, { useState } from 'react';
import { Card, Image, Icon, List } from 'semantic-ui-react';

function CompSubject(props) {
  let checkTest = props.check ?? true;
  // if (props.check === undefined) {
  //     checkTest = true;
  // } else {
  //     checkTest = props.check;
  // }

  // let nAcquiredStars = props.subject.nAcquiredStars();
  // let nTotalStars = props.subject.nTotalStars;
  // let nSticker = props.subject.bSticker() === true ? 1 : 0;
  // let bFinished = props.subject.bFinished();

  let nAcquiredStars = props.subject ? props.subject.nAcquiredStars() : 0;
  let nTotalStars = props.subject ? props.subject.nTotalStars : 20;
  let nSticker = props.subject ? (props.subject.bSticker() === true ? 1 : 0) : 0;
  // let bFinished = props.subject ? props.subject.bFinished() : false;
  let bFinished;

  if (props.subject) {
    if (props.subject.bFinished() === false) {
      bFinished = nAcquiredStars === 0 ? 'Não inicializado' : 'Em andamento';
    } else {
      bFinished = 'Finalizado';
    }
  } else {
    bFinished = 'Não inicializado';
  }

  return (
    <Card>
      <Image src={props.image} wrapped ui={false} />
      <Card.Content>
        <Card.Header>{props.name}</Card.Header>
        <Card.Description>
          <List animated divided>
            {bFinished === 'Finalizado' && (
              <List.Item>
                <List.Icon name="check" color="green" />
                <List.Content>Desafio Completo</List.Content>
              </List.Item>
            )}

            {bFinished === 'Em andamento' && (
              <List.Item>
                <List.Icon name="spinner" color="blue" />
                <List.Content>Desafio em Andamento</List.Content>
              </List.Item>
            )}

            {bFinished === 'Não inicializado' && (
              <List.Item>
                <List.Icon name="stopwatch" color="red" />
                <List.Content>Desafio Não Inicializado</List.Content>
              </List.Item>
            )}

            <List.Item>
              <List.Icon name="star" color="yellow" />
              <List.Content>
                {nAcquiredStars}/{nTotalStars} estrelas
              </List.Content>
            </List.Item>

            <List.Item>
              <List.Icon name="fonticons" color="purple" />
              <List.Content>{nSticker} figurinhas</List.Content>
            </List.Item>
          </List>
        </Card.Description>
      </Card.Content>
    </Card>
  );
}

export default CompSubject;

import React, { useState, useEffect } from 'react';
import { Button, Icon, Image, Modal, Header, List } from 'semantic-ui-react';
import { MathComponent } from 'mathjax-react';
import Question from '../../pages/Common/compQuest';
import StarMessage from '../starMessage';
import './tipPage.css';
import scoreManager from '../../managers/ScoreManager';
import useSound from 'use-sound';
import soundTips from '../../sounds/tips.wav';
import soundButton from '../../sounds/button.wav';

/* Parametros de entrada
numberTips= number*
        presentation1 = string*
        presentation2 = string
        subject = string*
        typeScoring = string*
        arrayScoring = string*
        tips={[
          {
            number: number*,
            image: image*,
            intro1: string*,
            intro2: string*,
            icon: string*',
            title: string*,
            dica1: bool*,
            dica1HasCondition: bool,
            dica1ValidateCondition: string,
            dica1ConditionType: 'condition',
            dica1MessageCondition: string,
            dica1ResponsePt1: string*,
            dica1ResponsePt2: string,
            dica1ResponsePt3: string,
            dica1ResponsePt4: string,
            dica1ResponseEquation1: String.raw`string`,
            dica1ResponseEquation2: String.raw`string`,
            dica1ResponseEquation3: String.raw`string`,
            dica1ResponseEquation4: String.raw`string`,
            dica1ResponsePt5: string,
            dica1QuestionTittle: string,
            dica1QuestionSubtittle1: string,
            dica1QuestionSubtittle2: string,
            dica1QuestionSubtittle3: string,
            dica1QuestionSubtittle4: string,
            dica1QuestionSubtittle5: string,
            dica1QuestionImage = image,
            dica1OptionA: string,
            dica1OptionB: string,
            dica1OptionC: string,
            dica1OptionD: string,
            dica1OptionE: string,
            dica1CorrectAnswer: string,
            dica1MissionName: string,
            dica1Subject: string,
            dica1TypeScoring: string,
            dica1ArrayScoring: string,
            dica1IndexScoring: number,
            dica1XPAward: number,
            dica2: bool,
            dica2HasCondition: bool,
            dica2ValidateCondition: string,
            dica2ConditionType: 'condition',
            dica2MessageCondition: string,
            dica2ResponsePt1: string*,
            dica2ResponsePt2: string,
            dica2ResponsePt3: string,
            dica2ResponsePt4: string,
            dica2ResponseEquation1: String.raw`string`,
            dica2ResponseEquation2: String.raw`string`,
            dica2ResponseEquation3: String.raw`string`,
            dica2ResponseEquation4: String.raw`string`,
            dica2ResponsePt5: string,
            dica2QuestionTittle: string,
            dica2QuestionSubtittle1: string,
            dica2QuestionSubtittle2: string,
            dica2QuestionSubtittle3: string,
            dica2QuestionSubtittle4: string,
            dica2QuestionSubtittle5: string,
            dica2QuestionImage = image,
            dica2OptionA: string,
            dica2OptionB: string,
            dica2OptionC: string,
            dica2OptionD: string,
            dica2OptionE: string,
            dica2CorrectAnswer: string,
            dica2MissionName: string,
            dica2Subject: string,
            dica2TypeScoring: string,
            dica2ArrayScoring: string,
            dica2IndexScoring: number,
            dica2XPAward: number,
          },
          {...},
          {...}
        ]}

*/

function TipsComponent(props) {
  const [isMinimized, setIsMinimized] = useState(false);

  const [playSong] = useSound(soundTips);
  const [playSongButton] = useSound(soundButton);

  const popupStyle = {
    borderRadius: 0,
    opacity: 0.9,
  };
  const modalStyle = {
    borderRadius: 0,
    opacity: 0.9,
    color: 'white',
    backgroundColor: 'black',
  };

  const [openModal0, setOpenModal0] = useState(false);
  const [openModal1, setOpenModal1] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openModal3, setOpenModal3] = useState(false);

  const [dica0, setDica0] = useState(0);
  const [dica1, setDica1] = useState(0);
  const [dica2, setDica2] = useState(0);
  const [dica3, setDica3] = useState(0);

  const SIZEIMAGE = '90%';

  var arrStars =
    scoreManager.objSpecificScore[props.subject][props.typeScoring][props.arrayScoring];
  var numStars = [];
  arrStars.forEach((star) => {
    numStars.push(star ? 1 : 0);
  });
  var acquiredStars = numStars.reduce((a, b) => a + b, 0);

  function getContent(indexTip, dica, setDica) {
    return (
      <>
        {
          <div className=" ">
            {dica == 0 && (
              <div>
                <p className="presentationItem">
                  {props.tips[indexTip].intro1}
                  <br></br>
                  <br></br>
                  {props.tips[indexTip].intro2}
                </p>

                {props.tips[indexTip].dica1 === true && (
                  <Button
                    className="tipButton"
                    icon
                    labelPosition="left"
                    onClick={() => {
                      setDica(1);
                      playSongButton();
                    }}
                  >
                    <Icon name="angle right" /> DICA 1{' '}
                  </Button>
                )}

                {props.tips[indexTip].dica2 === true && (
                  <Button
                    className="tipButton"
                    icon
                    labelPosition="left"
                    onClick={() => {
                      setDica(5);
                      playSongButton();
                    }}
                  >
                    <Icon name="angle double right" /> DICA 2{' '}
                  </Button>
                )}
              </div>
            )}

            {dica == 1 &&
              (props.tips[indexTip].dica1HasCondition === false ||
                (props.tips[indexTip].dica1HasCondition === true &&
                  eval(props.tips[indexTip].dica1ValidateCondition) === true)) && (
                <div>
                  {props.tips[indexTip].dica1ResponsePt1 && (
                    <p className="presentationItem">{props.tips[indexTip].dica1ResponsePt1} </p>
                  )}
                  {props.tips[indexTip].dica1ResponsePt2 && (
                    <p className="presentationItem">{props.tips[indexTip].dica1ResponsePt2} </p>
                  )}
                  {props.tips[indexTip].dica1ResponsePt3 && (
                    <p className="presentationItem">{props.tips[indexTip].dica1ResponsePt3} </p>
                  )}
                  {props.tips[indexTip].dica1ResponsePt4 && (
                    <p className="presentationItem">{props.tips[indexTip].dica1ResponsePt4}</p>
                  )}

                  {props.tips[indexTip].dica1ResponseEquation1 && (
                    <MathComponent tex={props.tips[indexTip].dica1ResponseEquation1} />
                  )}
                  {props.tips[indexTip].dica1ResponseEquation2 && (
                    <MathComponent tex={props.tips[indexTip].dica1ResponseEquation2} />
                  )}
                  {props.tips[indexTip].dica1ResponseEquation3 && (
                    <MathComponent tex={props.tips[indexTip].dica1ResponseEquation3} />
                  )}
                  {props.tips[indexTip].dica1ResponseEquation4 && (
                    <MathComponent tex={props.tips[indexTip].dica1ResponseEquation4} />
                  )}

                  {props.tips[indexTip].dica1ResponsePt5 && (
                    <p className="presentationItem">{props.tips[indexTip].dica1ResponsePt5}</p>
                  )}
                  <Button
                    className="tipButton"
                    icon
                    labelPosition="left"
                    onClick={() => {
                      setDica(0);
                      playSongButton();
                    }}
                  >
                    <Icon name="undo" /> FECHAR DICA{' '}
                  </Button>
                </div>
              )}

            {dica == 1 &&
              props.tips[indexTip].dica1HasCondition === true &&
              eval(props.tips[indexTip].dica1ValidateCondition) === false && (
                <div>
                  <p className="presentationItem">
                    {props.tips[indexTip].dica1MessageCondition}
                    <br></br>
                    <br></br>
                  </p>
                  <Button
                    className="tipButton"
                    icon
                    labelPosition="left"
                    onClick={() => {
                      setDica(0);
                      playSongButton();
                    }}
                  >
                    <Icon name="undo" /> RETORNAR{' '}
                  </Button>

                  {props.tips[indexTip].dica1ConditionType === 'mission' && (
                    <Button
                      className="tipButton"
                      icon
                      labelPosition="left"
                      onClick={() => {
                        setDica(2);
                        playSongButton();
                      }}
                    >
                      <Icon name="star" /> INICIAR MISSÃO{' '}
                    </Button>
                  )}
                </div>
              )}

            {dica == 2 && (
              <div>
                <Question
                  title={props.tips[indexTip].dica1QuestionTittle}
                  subtitle1={props.tips[indexTip].dica1QuestionSubtittle1}
                  subtitle2={props.tips[indexTip].dica1QuestionSubtittle2}
                  subtitle3={props.tips[indexTip].dica1QuestionSubtittle3}
                  subtitle4={props.tips[indexTip].dica1QuestionSubtittle4}
                  subtitle5={props.tips[indexTip].dica1QuestionSubtittle5}
                  image={props.tips[indexTip].dica1QuestionImage}
                  valueA={props.tips[indexTip].dica1OptionA}
                  valueB={props.tips[indexTip].dica1OptionB}
                  valueC={props.tips[indexTip].dica1OptionC}
                  valueD={props.tips[indexTip].dica1OptionD}
                  valueE={props.tips[indexTip].dica1OptionE}
                  setStep={setDica}
                  valueStepReturn={0}
                  corretcAnswer={props.tips[indexTip].dica1CorrectAnswer}
                  valueStepCorrect={3}
                  valueStepWrong={4}
                />
              </div>
            )}

            {dica == 3 && (
              <div>
                <List divided relaxed>
                  <List.Item>
                    <List.Icon name="check" size="big" verticalAlign="top" color="green" />
                    <List.Content>
                      <p className="presentationItem">
                        Parabéns, você completou a missão e consquistou uma estrela!
                      </p>
                    </List.Content>
                  </List.Item>
                </List>

                <Button
                  className="tipButton"
                  icon
                  labelPosition="left"
                  onClick={() => {
                    setDica(1);
                    playSongButton();
                  }}
                >
                  <Icon name="check" /> VISUALIZAR DICA{' '}
                </Button>

                <StarMessage
                  show={true}
                  message={props.tips[indexTip].dica1MissionName}
                  subjectScoring={props.tips[indexTip].dica1Subject}
                  typeScoring={props.tips[indexTip].dica1TypeScoring}
                  arrayScoring={props.tips[indexTip].dica1ArrayScoring}
                  indexScoring={props.tips[indexTip].dica1IndexScoring}
                  xpAward={props.tips[indexTip].dica1XPAward}
                />
              </div>
            )}

            {dica == 4 && (
              <div>
                <List divided relaxed>
                  <List.Item>
                    <List.Icon name="times" size="big" verticalAlign="top" color="red" />
                    <List.Content>
                      <p className="presentationItem">
                        Infelizmente essa não é a resposta correta! Tente responder novamente!
                      </p>
                    </List.Content>
                  </List.Item>
                </List>

                <Button
                  className="tipButton"
                  icon
                  labelPosition="undo"
                  onClick={() => {
                    setDica(0);
                    playSongButton();
                  }}
                >
                  <Icon name="undo" /> RETORNAR{' '}
                </Button>
              </div>
            )}

            {dica == 5 &&
              (props.tips[indexTip].dica2HasCondition === false ||
                (props.tips[indexTip].dica2HasCondition === true &&
                  eval(props.tips[indexTip].dica2ValidateCondition) === true)) && (
                <div>
                  <p className="presentationItem">
                    {props.tips[indexTip].dica2ResponsePt1} <br></br>
                    <br></br>
                    {props.tips[indexTip].dica2ResponsePt2} <br></br>
                    <br></br>
                    {props.tips[indexTip].dica2ResponsePt3} <br></br>
                    <br></br>
                    {props.tips[indexTip].dica2ResponsePt4} <br></br>
                    <br></br>
                  </p>

                  {props.tips[indexTip].dica2ResponseEquation1 && (
                    <MathComponent tex={props.tips[indexTip].dica2ResponseEquation1} />
                  )}
                  {props.tips[indexTip].dica2ResponseEquation2 && (
                    <MathComponent tex={props.tips[indexTip].dica2ResponseEquation2} />
                  )}
                  {props.tips[indexTip].dica2ResponseEquation3 && (
                    <MathComponent tex={props.tips[indexTip].dica2ResponseEquation3} />
                  )}
                  {props.tips[indexTip].dica2ResponseEquation4 && (
                    <MathComponent tex={props.tips[indexTip].dica2ResponseEquation4} />
                  )}

                  <p className="presentationItem">
                    {props.tips[indexTip].dica2ResponsePt5} <br></br>
                  </p>
                  <Button
                    className="tipButton"
                    icon
                    labelPosition="left"
                    onClick={() => {
                      setDica(0);
                      playSongButton();
                    }}
                  >
                    <Icon name="undo" /> FECHAR DICA{' '}
                  </Button>
                </div>
              )}

            {dica == 5 &&
              props.tips[indexTip].dica2HasCondition === true &&
              eval(props.tips[indexTip].dica2ValidateCondition) === false && (
                <div>
                  <p className="presentationItem">
                    {props.tips[indexTip].dica2MessageCondition}
                    <br></br>
                    <br></br>
                  </p>
                  <Button
                    className="tipButton"
                    icon
                    labelPosition="left"
                    onClick={() => {
                      setDica(0);
                      playSongButton();
                    }}
                  >
                    <Icon name="undo" /> RETORNAR{' '}
                  </Button>

                  {props.tips[indexTip].dica2ConditionType === 'mission' && (
                    <Button
                      className="tipButton"
                      icon
                      labelPosition="left"
                      onClick={() => {
                        setDica(6);
                        playSongButton();
                      }}
                    >
                      <Icon name="star" /> INICIAR MISSÃO{' '}
                    </Button>
                  )}
                </div>
              )}

            {dica == 6 && (
              <div>
                <Question
                  title={props.tips[indexTip].dica2QuestionTittle}
                  subtitle1={props.tips[indexTip].dica2QuestionSubtittle1}
                  subtitle2={props.tips[indexTip].dica2QuestionSubtittle2}
                  subtitle3={props.tips[indexTip].dica2QuestionSubtittle3}
                  subtitle4={props.tips[indexTip].dica2QuestionSubtittle4}
                  subtitle5={props.tips[indexTip].dica2QuestionSubtittle5}
                  image={props.tips[indexTip].dica2QuestionImage}
                  valueA={props.tips[indexTip].dica2OptionA}
                  valueB={props.tips[indexTip].dica2OptionB}
                  valueC={props.tips[indexTip].dica2OptionC}
                  valueD={props.tips[indexTip].dica2OptionD}
                  valueE={props.tips[indexTip].dica2OptionE}
                  setStep={setDica}
                  valueStepReturn={0}
                  corretcAnswer={props.tips[indexTip].dica2CorrectAnswer}
                  valueStepCorrect={7}
                  valueStepWrong={8}
                />
              </div>
            )}

            {dica == 7 && (
              <div>
                <List divided relaxed>
                  <List.Item>
                    <List.Icon name="check" size="big" verticalAlign="top" color="green" />
                    <List.Content>
                      <p className="presentationItem">
                        Parabéns, você completou a missão e consquistou uma estrela!
                      </p>
                    </List.Content>
                  </List.Item>
                </List>

                <Button
                  className="tipButton"
                  icon
                  labelPosition="left"
                  onClick={() => {
                    setDica(5);
                    playSongButton();
                  }}
                >
                  <Icon name="check" /> VISUALIZAR DICA{' '}
                </Button>

                <StarMessage
                  show={true}
                  message={props.tips[indexTip].dica2MissionName}
                  subjectScoring={props.tips[indexTip].dica2Subject}
                  typeScoring={props.tips[indexTip].dica2TypeScoring}
                  arrayScoring={props.tips[indexTip].dica2ArrayScoring}
                  indexScoring={props.tips[indexTip].dica2IndexScoring}
                  xpAward={props.tips[indexTip].dica2XPAward}
                />
              </div>
            )}

            {dica == 8 && (
              <div>
                <List divided relaxed>
                  <List.Item>
                    <List.Icon name="times" size="big" verticalAlign="top" color="red" />
                    <List.Content>
                      <p className="presentationItem">
                        Infelizmente essa não é a resposta correta! Tente responder novamente!
                      </p>
                    </List.Content>
                  </List.Item>
                </List>

                <Button
                  className="tipButton"
                  icon
                  labelPosition="undo"
                  onClick={() => {
                    setDica(0);
                    playSongButton();
                  }}
                >
                  <Icon name="undo" /> RETORNAR{' '}
                </Button>
              </div>
            )}
          </div>
        }
      </>
    );
  }

  function getTips() {
    return (
      <>
        {props.numberTips >= 1 && (
          <div className={'tipIten tip1' + 'of' + props.numberTips}>
            <Modal
              closeIcon
              open={openModal0}
              onClose={() => {
                setOpenModal0(false);
              }}
              onOpen={() => {
                setOpenModal0(true);
              }}
              trigger={
                <Image
                  width={SIZEIMAGE}
                  src={props.tips[0].image}
                  onMouseOver={() => {
                    playSong();
                  }}
                />
              }
            >
              <Header icon={props.tips[0].icon} content={props.tips[0].title} style={modalStyle} />
              <Modal.Content image scrolling style={modalStyle}>
                {getContent(0, dica0, setDica0)}
              </Modal.Content>
            </Modal>
          </div>
        )}

        {props.numberTips >= 2 && (
          <div className={'tipIten tip2' + 'of' + props.numberTips}>
            <Modal
              closeIcon
              open={openModal1}
              onClose={() => {
                setOpenModal1(false);
              }}
              onOpen={() => {
                setOpenModal1(true);
              }}
              trigger={
                <Image
                  width={SIZEIMAGE}
                  src={props.tips[1].image}
                  onMouseOver={() => {
                    playSong();
                  }}
                />
              }
            >
              <Header icon={props.tips[1].icon} content={props.tips[1].title} style={modalStyle} />
              <Modal.Content image scrolling style={modalStyle}>
                {getContent(1, dica1, setDica1)}
              </Modal.Content>
            </Modal>
          </div>
        )}

        {props.numberTips >= 3 && (
          <div className={'tipIten tip3' + 'of' + props.numberTips}>
            <Modal
              closeIcon
              open={openModal2}
              onClose={() => {
                setOpenModal2(false);
              }}
              onOpen={() => {
                setOpenModal2(true);
              }}
              trigger={
                <Image
                  width={SIZEIMAGE}
                  src={props.tips[2].image}
                  onMouseOver={() => {
                    playSong();
                  }}
                />
              }
            >
              <Header icon={props.tips[2].icon} content={props.tips[2].title} style={modalStyle} />
              <Modal.Content image scrolling style={modalStyle}>
                {getContent(2, dica2, setDica2)}
              </Modal.Content>
            </Modal>
          </div>
        )}

        {props.numberTips >= 4 && (
          <div className={'tipIten tip4' + 'of' + props.numberTips}>
            <Modal
              closeIcon
              open={openModal3}
              onClose={() => {
                setOpenModal3(false);
              }}
              onOpen={() => {
                setOpenModal3(true);
              }}
              trigger={
                <Image
                  width={SIZEIMAGE}
                  src={props.tips[3].image}
                  onMouseOver={() => {
                    playSong();
                  }}
                />
              }
            >
              <Header icon={props.tips[3].icon} content={props.tips[3].title} style={modalStyle} />
              <Modal.Content image scrolling style={modalStyle}>
                {getContent(3, dica3, setDica3)}
              </Modal.Content>
            </Modal>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <div
        className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`}
        key={Math.random()}
      >
        {!isMinimized && (
          <>
            <p className="presentationItem">
              <br></br>
              <br></br>
              {props.presentation1}
              <br></br>
              <br></br>
              {props.presentation2}
            </p>
            {getTips()}
          </>
        )}
      </div>
    </>
  );
}

export default TipsComponent;

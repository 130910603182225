import * as BABYLON from '@babylonjs/core';
import '@babylonjs/loaders';

import { SkyMaterial } from '@babylonjs/materials/sky';
import '@babylonjs/core/Meshes/thinInstanceMesh';

function Scenario(scene) {
  BABYLON.SceneLoader.ImportMesh(
    '',
    './scenes/Física/Cinemática/MRUV/snowTrack.glb',
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let terrain = meshes[0];
      animationGroups.forEach((x) => {
        x.start(true);
      });
      terrain.position = new BABYLON.Vector3(0, 0, 830);
      terrain.rotation = new BABYLON.Vector3(0, Math.PI / 2, 0);
      terrain.scaling = new BABYLON.Vector3(1, 1, -1);
    }
  );

  var box;
  var skyMaterial = new SkyMaterial('sky', scene);
  skyMaterial.turbidity = 20;
  skyMaterial.luminance = 1;
  skyMaterial.inclination = -0.8;
  skyMaterial.azimuth = -0.24;
  skyMaterial.rayleigh = 3;
  box = BABYLON.Mesh.CreateBox('SkyBox', 5000, scene, false, BABYLON.Mesh.BACKSIDE);
  box.material = skyMaterial;
  box.position = new BABYLON.Vector3(0, 100, 0);

  scene.createDefaultEnvironment({ skyboxSize: 5000 });

  var numOfGrandStands = 13;

  BABYLON.SceneLoader.ImportMesh(
    '',
    './scenes/Física/Cinemática/Aceleração/grandstand.glb',
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let arquibancada = BABYLON.Mesh.MergeMeshes(
        meshes.filter((e, index) => index > 0),
        true,
        true,
        undefined,
        false,
        true
      );
      arquibancada.rotation = new BABYLON.Vector3(0, Math.PI / 2, 0);
      animationGroups.forEach((x) => {
        x.start(true);
      });
      arquibancada.alwaysSelectAsActiveMesh = true;
      arquibancada.position = new BABYLON.Vector3(33, 0, -26);
      arquibancada.scaling = new BABYLON.Vector3(0.7, 0.7, -0.7);

      var bufferMatrices = new Float32Array(16 * numOfGrandStands);
      let cont = 0;
      for (var x = 0; x < numOfGrandStands; x++) {
        var matrix = BABYLON.Matrix.Translation(-100 * x, 0, 0);
        matrix.copyToArray(bufferMatrices, cont);
        cont += 16;
      }
      arquibancada.thinInstanceSetBuffer('matrix', bufferMatrices, 16);
    }
  );

  BABYLON.SceneLoader.ImportMesh(
    '',
    './scenes/Física/Cinemática/Aceleração/yellowEgg.glb',
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let egg = BABYLON.Mesh.MergeMeshes(
        meshes.filter((e, index) => index > 0),
        true,
        true,
        undefined,
        false,
        true
      );
      egg.rotation = new BABYLON.Vector3(0, -Math.PI / 2, 0);
      animationGroups.forEach((x) => {
        x.start(true);
      });
      egg.alwaysSelectAsActiveMesh = true;
      egg.position = new BABYLON.Vector3(26.42, 2.5, -45.73);
      egg.scaling = new BABYLON.Vector3(0.7, 0.7, 0.7);

      var bufferMatrices = new Float32Array(16 * numOfGrandStands * 7 * 4);
      let cont = 0;
      for (var z = 0; z < numOfGrandStands; z++) {
        for (var x = 0; x < 7; x++) {
          for (var y = 0; y < 4; y++) {
            var matrix = BABYLON.Matrix.Translation(-2.71 * x + 100 * z, 1.85 * y, -4.22 * y);
            matrix.copyToArray(bufferMatrices, cont);
            cont += 16;
          }
        }
      }
      egg.thinInstanceSetBuffer('matrix', bufferMatrices, 16);

      // scene.teste = egg;
      // var utilLayer = new BABYLON.UtilityLayerRenderer(scene);
      // var gizmo = new BABYLON.PositionGizmo(utilLayer);
      // gizmo.attachedMesh = scene.teste;
      // gizmo.updateGizmoRotationToMatchAttachedMesh = false;
      // gizmo.updateGizmoPositionToMatchAttachedMesh = true;
    }
  );

  BABYLON.SceneLoader.ImportMesh(
    '',
    './scenes/Física/Cinemática/Aceleração/greenEgg.glb',
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let egg = BABYLON.Mesh.MergeMeshes(
        meshes.filter((e, index) => index > 0),
        true,
        true,
        undefined,
        false,
        true
      );
      egg.rotation = new BABYLON.Vector3(0, -Math.PI / 2, 0);
      animationGroups.forEach((x) => {
        x.start(true);
      });
      egg.alwaysSelectAsActiveMesh = true;
      egg.position = new BABYLON.Vector3(26.42, 2.5, -29.11);
      egg.scaling = new BABYLON.Vector3(0.7, 0.7, 0.7);

      var bufferMatrices = new Float32Array(16 * numOfGrandStands * 7 * 4);
      let cont = 0;
      for (var z = 0; z < numOfGrandStands; z++) {
        for (var x = 0; x < 7; x++) {
          for (var y = 0; y < 4; y++) {
            var matrix = BABYLON.Matrix.Translation(-2.71 * x + 100 * z, 1.85 * y, -4.22 * y);
            matrix.copyToArray(bufferMatrices, cont);
            cont += 16;
          }
        }
      }
      egg.thinInstanceSetBuffer('matrix', bufferMatrices, 16);
    }
  );

  BABYLON.SceneLoader.ImportMesh(
    '',
    './scenes/Física/Cinemática/Aceleração/redEgg.glb',
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let egg = BABYLON.Mesh.MergeMeshes(
        meshes.filter((e, index) => index > 0),
        true,
        true,
        undefined,
        false,
        true
      );
      egg.rotation = new BABYLON.Vector3(0, -Math.PI / 2, 0);
      animationGroups.forEach((x) => {
        x.start(true);
      });
      egg.alwaysSelectAsActiveMesh = true;
      egg.position = new BABYLON.Vector3(26.42, 2.5, -11.9);
      egg.scaling = new BABYLON.Vector3(0.7, 0.7, 0.7);

      var bufferMatrices = new Float32Array(16 * numOfGrandStands * 7 * 4);
      let cont = 0;
      for (var z = 0; z < numOfGrandStands; z++) {
        for (var x = 0; x < 7; x++) {
          for (var y = 0; y < 4; y++) {
            var matrix = BABYLON.Matrix.Translation(-2.71 * x + 100 * z, 1.85 * y, -4.22 * y);
            matrix.copyToArray(bufferMatrices, cont);
            cont += 16;
          }
        }
      }
      egg.thinInstanceSetBuffer('matrix', bufferMatrices, 16);
    }
  );

  BABYLON.SceneLoader.ImportMesh(
    '',
    './scenes/Física/Cinemática/Aceleração/blueEgg.glb',
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let egg = BABYLON.Mesh.MergeMeshes(
        meshes.filter((e, index) => index > 0),
        true,
        true,
        undefined,
        false,
        true
      );
      egg.rotation = new BABYLON.Vector3(0, -Math.PI / 2, 0);
      animationGroups.forEach((x) => {
        x.start(true);
      });
      egg.alwaysSelectAsActiveMesh = true;
      egg.position = new BABYLON.Vector3(26.42, 2.5, 5.6);
      egg.scaling = new BABYLON.Vector3(0.7, 0.7, 0.7);

      var bufferMatrices = new Float32Array(16 * numOfGrandStands * 7 * 4);
      let cont = 0;
      for (var z = 0; z < numOfGrandStands; z++) {
        for (var x = 0; x < 7; x++) {
          for (var y = 0; y < 4; y++) {
            var matrix = BABYLON.Matrix.Translation(-2.71 * x + 100 * z, 1.85 * y, -4.22 * y);
            matrix.copyToArray(bufferMatrices, cont);
            cont += 16;
          }
        }
      }
      egg.thinInstanceSetBuffer('matrix', bufferMatrices, 16);
    }
  );

  BABYLON.SceneLoader.ImportMesh(
    '',
    './scenes/Tree A.glb',
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let forest = BABYLON.Mesh.MergeMeshes(
        meshes.filter((e, index) => index > 0),
        true,
        true,
        undefined,
        false,
        true
      );
      forest.rotation = new BABYLON.Vector3(0, 0, 0);
      animationGroups.forEach((x) => {
        x.start(true);
      });
      forest.alwaysSelectAsActiveMesh = true;
      forest.position = new BABYLON.Vector3(-1.1, -2, 0);

      let count = 300;
      var bufferMatrices = new Float32Array(16 * count);
      for (var x = 0; x < count; x++) {
        let side = Math.floor(2 * Math.random());
        let scale = 1 + Math.random();
        let xPos, zPos;

        if (side === 0) {
          xPos = 70 + Math.random() * 310;
          zPos = -300 + Math.random() * 2200;
        } else if (side === 1) {
          xPos = -(70 + Math.random() * 310);
          zPos = -300 + Math.random() * 2200;
        }

        var matrix = BABYLON.Matrix.Compose(
          new BABYLON.Vector3(scale, scale, scale),
          new BABYLON.Quaternion.RotationYawPitchRoll(Math.random() * 2 * Math.PI, 0, 0),
          new BABYLON.Vector3(xPos, 0, zPos)
        );
        matrix.copyToArray(bufferMatrices, 16 * x);
      }
      forest.thinInstanceSetBuffer('matrix', bufferMatrices, 16);

      // scene.teste = forest;
      // var utilLayer = new BABYLON.UtilityLayerRenderer(scene);
      // var gizmo = new BABYLON.PositionGizmo(utilLayer);
      // gizmo.attachedMesh = scene.teste;
      // gizmo.updateGizmoRotationToMatchAttachedMesh = false;
      // gizmo.updateGizmoPositionToMatchAttachedMesh = true;
    }
  );

  BABYLON.SceneLoader.ImportMesh(
    '',
    './scenes/Tree B.glb',
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let forest = BABYLON.Mesh.MergeMeshes(
        meshes.filter((e, index) => index > 0),
        true,
        true,
        undefined,
        false,
        true
      );
      forest.rotation = new BABYLON.Vector3(0, 0, 0);
      animationGroups.forEach((x) => {
        x.start(true);
      });
      forest.alwaysSelectAsActiveMesh = true;
      forest.position = new BABYLON.Vector3(-1.1, -2, 0);

      let count = 300;
      var bufferMatrices = new Float32Array(16 * count);
      for (var x = 0; x < count; x++) {
        let side = Math.floor(2 * Math.random());
        let scale = 1 + Math.random();
        let xPos, zPos;

        if (side === 0) {
          xPos = 70 + Math.random() * 310;
          zPos = -300 + Math.random() * 2200;
        } else if (side === 1) {
          xPos = -(70 + Math.random() * 310);
          zPos = -300 + Math.random() * 2200;
        }

        var matrix = BABYLON.Matrix.Compose(
          new BABYLON.Vector3(scale, scale, scale),
          new BABYLON.Quaternion.RotationYawPitchRoll(Math.random() * 2 * Math.PI, 0, 0),
          new BABYLON.Vector3(xPos, 0, zPos)
        );
        matrix.copyToArray(bufferMatrices, 16 * x);
      }
      forest.thinInstanceSetBuffer('matrix', bufferMatrices, 16);
    }
  );

  BABYLON.SceneLoader.ImportMesh(
    '',
    './scenes/Tree C.glb',
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let forest = BABYLON.Mesh.MergeMeshes(
        meshes.filter((e, index) => index > 0),
        true,
        true,
        undefined,
        false,
        true
      );
      forest.rotation = new BABYLON.Vector3(0, 0, 0);
      animationGroups.forEach((x) => {
        x.start(true);
      });
      forest.alwaysSelectAsActiveMesh = true;
      forest.position = new BABYLON.Vector3(-1.1, -2, 0);

      let count = 300;
      var bufferMatrices = new Float32Array(16 * count);
      for (var x = 0; x < count; x++) {
        let side = Math.floor(2 * Math.random());
        let scale = 1 + Math.random();
        let xPos, zPos;

        if (side === 0) {
          xPos = 70 + Math.random() * 310;
          zPos = -300 + Math.random() * 2200;
        } else if (side === 1) {
          xPos = -(70 + Math.random() * 310);
          zPos = -300 + Math.random() * 2200;
        }

        var matrix = BABYLON.Matrix.Compose(
          new BABYLON.Vector3(scale, scale, scale),
          new BABYLON.Quaternion.RotationYawPitchRoll(Math.random() * 2 * Math.PI, 0, 0),
          new BABYLON.Vector3(xPos, 0, zPos)
        );
        matrix.copyToArray(bufferMatrices, 16 * x);
      }
      forest.thinInstanceSetBuffer('matrix', bufferMatrices, 16);
    }
  );
}

export default Scenario;

import species_octa from '../../../../images/Physics/Gravitation/species_octa.PNG';
import species_boter from '../../../../images/Physics/Gravitation/species_boter.PNG';
import species_levitoner from '../../../../images/Physics/Gravitation/species_levitoner.PNG';
import species_emierres from '../../../../images/Physics/Gravitation/species_emierres.PNG';
import scoreManager from '../../../../managers/ScoreManager';

export function getImageSpecie() {
  switch (scoreManager.objSpecificScore.physics.gravitation.additionalInfo.specie) {
    case 'Octawabeter':
      return species_octa;
    case 'Olhoboter':
      return species_boter;
    case 'Levitoner':
      return species_levitoner;
    case 'Emierres':
      return species_emierres;
    default:
      break;
  }
}

import React, { useEffect, useState } from 'react';
import ScaleGrid, {
  diffScaleCreator,
  inverseScale,
  CustomScale,
} from '../../libs/popUtils/Scalegrid';
import { tween, transform, styler, easing, listen, pointer, ColdSubscription } from 'popmotion';
import CVector from '../../libs/popUtils/CVector';
import { SVG } from '@svgdotjs/svg.js';
import './ballMoving.css';
import TrackableSVG from '../../libs/popUtils/TrackableSvg';

const { pipe } = transform;
const PLOT_SIZE = 250;
const BALL_SIZE = 15;
const cBallColor = '#58a';
const cVectorColor = '#5aa';
const nOpacityTransitionTime = 250;

interface StandardPoint {
  x: number;
  y: number;
}

interface Emmition {
  displacement: number;
  averageSpeed: number;
  diffTime: Date;
}

interface BallMovingPlotProps {
  type: string;
  fnTransform?: (x: StandardPoint, finalPoint: StandardPoint) => StandardPoint;
  nBallAnimationDuration?: number;
  objRenderThose: {
    averageSpeed?: boolean;
    displacement?: boolean;
    time?: boolean;
    distance?: boolean;
  };
  initialPosition?: StandardPoint;
  fnEmmit?: (obj: Emmition) => void;
  className?: string;
  bDrawBallPath?: boolean;
}

const objDefaultStroke = { width: 2, color: '#ccc' };

function BallMovingPlot(props: BallMovingPlotProps) {
  const {
    type,
    nBallAnimationDuration = 500,
    fnTransform = (x) => x,
    objRenderThose,
    initialPosition = { x: 0, y: 0 },
    fnEmmit = (obj: object) => {},
  } = props;
  const [bIsDrag, bIsClick] = ['drag', 'click'].map((x) => x === type);
  const ballDivID = 'id' + Math.random().toString(36).substr(2, 10);

  let initialTime: number;
  let diffTime = new Date(0);
  let displacement = 0;
  const [renderDisplacement, setRenderDisplacement] = useState(0);
  const [renderDistance, setRenderDistance] = useState(0);
  const setDisplacement = (value: number) => {
    displacement = value;
    setRenderDisplacement(value);
  };
  const [averageSpeed, setAverageSpeed] = useState(0);
  const [timeInSeconds, setTimeInSeconds] = useState(0);
  let arrVectorInitial = [0, 0];

  const updateTimeAndSpeed = () => {
    const newTimeInSeconds = diffTime.getSeconds() + diffTime.getMilliseconds() / 1000;
    setTimeInSeconds(newTimeInSeconds);
    setAverageSpeed(displacement / newTimeInSeconds);
  };

  //Mount
  useEffect(() => {
    const draw = SVG()
      .addTo('#' + ballDivID)
      .size(PLOT_SIZE * 1.65, PLOT_SIZE);
    const sg = new ScaleGrid(draw, { scaleX: [-2, 4], scaleY: [-1, 2], stroke: objDefaultStroke });
    const fnScale = pipe(diffScaleCreator, inverseScale)(sg.fnScaleX as CustomScale);

    sg.drawTicks({ stroke: objDefaultStroke }).drawTicksText(objDefaultStroke.color);
    const ball1 = draw.circle(BALL_SIZE).attr({ fill: cBallColor, cursor: 'pointer' });

    const [ox, oy] = [sg.fnScaleX(initialPosition.x), sg.fnScaleY(initialPosition.y)];
    ball1.center(ox, oy);

    const sBallSVG = styler(ball1.node).set({
      originX: 0,
      originY: 0,
      width: `${BALL_SIZE}px`,
      scale: 1,
      position: 'absolute',
    });

    const sBall1 = new TrackableSVG(draw, sBallSVG, { ox, oy }, 5, props.bDrawBallPath);

    const cvec = new CVector(draw, [100, 100], {
      ox,
      oy,
      stroke: { width: 2, color: cVectorColor },
    }).updatePos();
    cvec.group.opacity(0);

    const updateCreate = (fnTransform: Function, finalPoint: StandardPoint) => {
      return (v: { x: number; y: number }) => {
        const { x, y } = fnTransform(v, finalPoint);
        sBall1.set({ x, y });
        pipe(Math.sqrt, fnScale, setDisplacement)(x ** 2 + y ** 2);
        setRenderDistance(fnScale(sBall1.getCoveredSpace()));
        diffTime = new Date(Date.now() - initialTime);
        updateTimeAndSpeed();
      };
    };

    const playTween = (xFinal: number, yFinal: number) => {
      return new Promise((fnResolve) => {
        initialTime = Date.now();
        tween({
          from: { x: 0, y: 0 },
          to: { x: xFinal, y: -yFinal },
          ease: easing.easeOut,
          flip: 0,
          duration: nBallAnimationDuration,
        }).start({
          update: updateCreate(fnTransform, { x: xFinal, y: yFinal }),
          complete: () => {
            cvec
              .updatePos(xFinal, yFinal)
              .group.attr({ opacity: 0 })
              .animate({
                duration: nOpacityTransitionTime,
              })
              .attr({ opacity: 1 });
            fnResolve(true);
          },
        });
      });
    };

    if (bIsClick) {
      let bAlreadyClicked = false;
      listen(draw.node, 'mousedown').start((event: MouseEvent) => {
        if (bAlreadyClicked) return;
        bAlreadyClicked = true;
        const [x, y] = [event.offsetX - ox, event.offsetY - oy];
        cvec.group.opacity(0);
        playTween(x, -y).then(() => {
          bAlreadyClicked = false;
        });
      });
    }

    if (bIsDrag) {
      sBall1.startTracking();
      let ballPointer: ColdSubscription | null;
      let timerId: NodeJS.Timeout;
      listen(ball1.node, 'mousedown').start((event: MouseEvent) => {
        const origin = { x: sBall1.get('x'), y: sBall1.get('y') };
        arrVectorInitial = [origin.x, origin.y];
        initialTime = Date.now();

        timerId = setInterval(() => {
          diffTime = new Date(Date.now() - initialTime);
          updateTimeAndSpeed();
        }, 10);

        cvec.group.opacity(0);
        sBall1.startTracking();

        ballPointer = pointer(origin).start(({ x, y }: { x: number; y: number }) => {
          sBall1.set({ x, y });
          const [deltaX, deltaY] = [x - origin.x, y - origin.y];
          pipe(Math.sqrt, fnScale, setDisplacement)(deltaX ** 2 + deltaY ** 2);
          setRenderDistance(fnScale(sBall1.getCoveredSpace()));
        });
      });

      listen(document, 'mouseup').start((event: MouseEvent) => {
        if (ballPointer) {
          const [finalX, finalY] = [
            sBall1.get('x') - arrVectorInitial[0],
            sBall1.get('y') - arrVectorInitial[1],
          ];
          cvec
            .updatePos(finalX, -finalY)
            .moveTo(arrVectorInitial[0], arrVectorInitial[1])
            .group.animate({
              duration: nOpacityTransitionTime,
            })
            .attr({ opacity: 1 });
          ballPointer.stop();
          ballPointer = null;
          fnEmmit({ displacement, averageSpeed, diffTime });
          clearInterval(timerId);
          sBall1.smooth();
        }
      });
    }
  }, []);

  return (
    <div className={`ball-animation-container ${props.className}`}>
      <div className="svg-container" id={ballDivID}></div>
      <div className="results">
        {objRenderThose.displacement && (
          <h3 className="result">Deslocamento: {renderDisplacement.toFixed(2)}m</h3>
        )}
        {objRenderThose.distance && (
          <h3 className="result">Distância percorrida: {renderDistance.toFixed(2)}m</h3>
        )}
        {objRenderThose.time && <h3 className="result">Tempo: {timeInSeconds.toFixed(2)}s</h3>}
        {objRenderThose.averageSpeed && (
          <h3 className="result">Velocidade média: {averageSpeed.toFixed(2)}m/s</h3>
        )}
      </div>
    </div>
  );
}

export default BallMovingPlot;

export type { StandardPoint, Emmition };

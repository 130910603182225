/* eslint-disable react/display-name */
/* eslint-disable react/no-unknown-property */
import React, { useState } from 'react';
import { Image, List } from 'semantic-ui-react';
import PlayDialogue from '../../../Common/compPlayDialogue';
import cinematics_boss from '../../../../images/Physics/Kinematics/cinematics_boss.PNG';
import iconBlack from '../../../../images/Physics/Kinematics/iconBlack.PNG';
import iconBlue from '../../../../images/Physics/Kinematics/iconBlue.PNG';
import iconGreen from '../../../../images/Physics/Kinematics/iconGreen.PNG';
import iconPink from '../../../../images/Physics/Kinematics/iconPink.PNG';
import iconRed from '../../../../images/Physics/Kinematics/iconRed.PNG';
import iconYellow from '../../../../images/Physics/Kinematics/iconYellow.PNG';

const SIZEICONS = '4.5%';

function Introdutory(props) {
  const [animationStep, setAnimationStep] = useState(0);

  let listCars = [
    { name: 'Arara Real', logo: iconBlue },
    { name: 'Jacaré Impiedoso', logo: iconGreen },
    { name: 'Boto Veloz', logo: iconPink },
    { name: 'Falcão Negro', logo: iconBlack },
    { name: 'Guará Rubro', logo: iconRed },
    { name: 'Capivara Furiosa', logo: iconYellow },
  ];

  const additionalInfo = JSON.parse(sessionStorage.getItem("additional_info"));

  function getList(props) {
    return (
      <List.Item>
        <List.Content floated="right">{props.pontuation}</List.Content>
        <Image src={props.image} width={SIZEICONS} rounded />
        <List.Content>{props.title}</List.Content>
      </List.Item>
    );
  }

  const arrPage = [
    <PlayDialogue
      key={0}
      buttons="Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text="Você realizou mais uma bela corrida. Seus resultados são fantásticos!"
      image={cinematics_boss}
      extraContent={''}
    />,

    <PlayDialogue
      key={1}
      buttons="Anterior/Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text="Com mais 10 pontos no campeonato, vamos ver a classificação:"
      image={cinematics_boss}
      extraContent={''}
    />,

    <PlayDialogue
      key={2}
      buttons="Anterior/Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text=""
      image={cinematics_boss}
      extraContent={
        <p className="wheelsParagraph">
          <br></br>
          Tabela de Classificação
          <List celled animated>
            {additionalInfo.teams.map((team, index) => {
              return getList({
              title: team,
              image: listCars.find(x => x.name === team).logo,
              pontuation: (30 - 6*index),
            });
            })}
          </List>
        </p>
      }
    />,

    <PlayDialogue
      key={3}
      buttons="Anterior"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text="Esta é nossa última corrida. Você precisa ganhá-la para ser campeão!"
      image={cinematics_boss}
      extraContent={''}
    />,
  ];

  return arrPage[animationStep];
}

export default Introdutory;

import * as BABYLON from '@babylonjs/core';

import Scenario from './Scenario';

import '@babylonjs/loaders';
import '@babylonjs/inspector';
import scoreManager from '../../../../managers/ScoreManager';

function Setup(props) {
  let scene = props.scene;
  const CAMERA_INIT_POSITION = new BABYLON.Vector3(-155, 6, -5);

  scene.camera = new BABYLON.FreeCamera('camera', CAMERA_INIT_POSITION, scene);
  const canvas = scene.getEngine().getRenderingCanvas();
  scene.camera.attachControl(canvas, true);

  scene.cameraOrientation = Math.PI;

  scene.time = 0;
  scene.planets = [];
  scene.responses = [0, 0];
  scene.running = 0;
  scene.countStart = 0;
  scene.restart = 0;

  var auxPosXToMoon = 0;
  var auxPosZToMoon = 0;
  scene.rocket = null;

  Scenario(scene);

  // lista de planetas
  let listPlanets = [
    {
      name: './scenes/Física/Gravitação/sun.glb',
      dist: 0,
      angle: 0,
      size: 10,
      period: 1,
      isStarting: false,
      isEnding: false,
      hasMoon: false,
      isMoon: false
    },
    {
      name: './scenes/Física/Gravitação/planetD.glb',
      dist: 40,
      angle: (-1 * Math.PI) / 8,
      size: 1,
      period: 51,
      isStarting: false,
      isEnding: false,
      hasMoon: false,
      isMoon: false
    },
    {
      name: './scenes/Física/Gravitação/planetC.glb',
      dist: 100,
      angle: (11 * Math.PI) / 8,
      size: 1.5,
      period: 200,
      isStarting: true,
      isEnding: false,
      hasMoon: false,
      isMoon: false
    },
    {
      name: './scenes/Física/Gravitação/planetE.glb',
      dist: 208,
      angle: (7.6 * Math.PI) / 8,
      size: 6,
      period: 600,
      isStarting: false,
      isEnding: true,
      hasMoon: true,
      isMoon: false
    },
    {
      name: './scenes/Física/Gravitação/planetA.glb',
      dist: 300,
      angle: (8 * Math.PI) / 8,
      size: 1,
      period: 1040,
      isStarting: false,
      isEnding: false,
      hasMoon: false,
      isMoon: false
    },
    {
      name: './scenes/Física/Gravitação/moon.glb',
      dist: 30,
      angle: (13 * Math.PI) / 8,
      size: 2,
      period: 20,
      isStarting: false,
      isEnding: false,
      hasMoon: false,
      isMoon: true
    },
  ];

  listPlanets.length = 6;
  for (let i = 0; i < listPlanets.length; i++) {
    BABYLON.SceneLoader.ImportMesh(
      '',
      listPlanets[i].name,
      '',
      scene,
      function (meshes, particleSystems, skeleton, animationGroups) {
        let planet = meshes[0];

        // planet variables
        planet.active = true;

        planet.position.x = listPlanets[i].dist * Math.cos(listPlanets[i].angle);
        planet.position.z = listPlanets[i].dist * Math.sin(listPlanets[i].angle);
        planet.position.y = 0;

        if (listPlanets[i].hasMoon) {
          auxPosXToMoon = planet.position.x;
          auxPosZToMoon = planet.position.z;
        }
        if (listPlanets[i].isMoon) {
          planet.position.x += auxPosXToMoon;
          planet.position.z += auxPosZToMoon;
        }

        planet.rotation = new BABYLON.Vector3(0, 0, 0);
        planet.scaling = new BABYLON.Vector3(
          listPlanets[i].size,
          listPlanets[i].size,
          listPlanets[i].size
        );

        planet.dist = listPlanets[i].dist;
        planet.angle = listPlanets[i].angle;
        planet.period = listPlanets[i].period;
        planet.isStarting = listPlanets[i].isStarting;
        planet.isEnding = listPlanets[i].isEnding;
        planet.hasMoon = listPlanets[i].hasMoon;
        planet.isMoon = listPlanets[i].isMoon;

        // initial planet variables
        planet.initPosition = planet.position;
        planet.initAngle = planet.angle;
        planet.initPeriod = planet.period;

        scene.planets.push(planet);
      }
    );
  }

  let listRocket = {
    name: './scenes/Física/Gravitação/rocket.glb',
    position: new BABYLON.Vector3(
      -160,
      0,
      0
    ),
  };

  BABYLON.SceneLoader.ImportMesh(
    '',
    listRocket.name,
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let rocket = meshes[0];
      animationGroups.forEach((x) => {
        x.start(true);
      });
      rocket.active = true;
      rocket.position = listRocket.position;
      rocket.rotation = new BABYLON.Vector3(-Math.PI / 2, Math.PI / 2, 0);
      rocket.scaling = new BABYLON.Vector3(0.1, 0.1, 0.1);

      rocket.initPosition = listRocket.position;
      rocket.vel = listRocket.vel;

      scene.camera.setTarget(rocket.initPosition);

      scene.rocket = rocket;
    }
  );

  //
}


export default Setup;

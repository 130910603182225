/* eslint-disable react/display-name */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import { Button, Grid, Segment, Popup, Image, Divider } from 'semantic-ui-react';
import PlayDialogue from '../../../Common/compPlayDialogue';
import '../CSSs/SelectSpecies.scss';

import robo from '../../../../images/Physics/Gravitation/robo.PNG';
import species_octa from '../../../../images/Physics/Gravitation/species_octa.PNG';
import species_boter from '../../../../images/Physics/Gravitation/species_boter.PNG';
import species_levitoner from '../../../../images/Physics/Gravitation/species_levitoner.PNG';
import species_emierres from '../../../../images/Physics/Gravitation/species_emierres.PNG';

import api from '../../../../services/api';

const SIZEICONS = '10%';

function Introdutory(props) {
  const [animationStep, setAnimationStep] = useState(0);
  const [selectedTeam, setselectedTeam] = useState(null);

  useEffect(() => {
    if (animationStep === 11) {
      props.setNextItem('Tempo');
    }
  }, [animationStep]);

  async function updateSpecie(name){
    await api
      .post('updateAdditionalInfo', {
        challenge_id: sessionStorage.getItem('challenge_id'),
        client_id: localStorage.getItem('client_id'),
        additional_info: { specie: name },
      })
      .then((res) => {
        sessionStorage.setItem(
          'additional_info',
          JSON.stringify({ specie: name })
        );
      })
      .catch((err) => {});
  }

  function getPopup(props) {
    return (
      <Popup
        className="popupSelectCar"
        position="bottom center"
        flowing
        inverted
        hoverable
        trigger={<Image src={props.icon} width={SIZEICONS} circular />}
        content={
          <Segment placeholder className="segmentCar">
            <Grid columns={2} stackable textAlign="center">
              <Divider vertical></Divider>
              <Grid.Row verticalAlign="middle">
                <Grid.Column>
                  <Image src={props.icon} size="medium" rounded />
                </Grid.Column>
                <Grid.Column>
                  <p>{props.description} </p>
                  <Button
                    id="start-btn"
                    onClick={async () => {
                      setAnimationStep(animationStep + 1);
                      setselectedTeam(props.manager);
                      await updateSpecie(props.name);
                    }}
                    className={props.class}
                  >
                    {props.name}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        }
      />
    );
  }

  const arrPage = [
    <PlayDialogue
      key={0}
      buttons="Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text="Olá cosmonauta, como vai? Eu sou o EE14, um robô construído para auxiliar em viagens espaciais."
      image={robo}
      extraContent={''}
    />,

    <PlayDialogue
      key={1}
      buttons="Anterior"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text="Espere, não estou conseguindo identificar sua espécie. Identifique-se."
      image={robo}
      extraContent={
        <div className="iconCar">
          <Image.Group>
            {getPopup({
              icon: species_octa,
              description:
                'Nos fundos dos mares do planeta Bitor vivem os octawabeters. Sofrendo uma catástrofe ambiental devido às suas águas poluídas, procuram um novo planeta para fixar residência.',
              class: 'gravitationSelectSpecies__button__octawabeters',
              name: 'Octawabeter',
            })}

            {getPopup({
              icon: species_boter,
              description:
                'Os olhoboters, habitantes de Jaum, têm partes de seu corpo substituídas por próteses robóticas. Um grande cometa atingirá o planeta em alguns meses e eles necessitam deixar sua terra natal o mais rápido possível.',
              class: 'gravitationSelectSpecies__button__olhoboters',
              name: 'Olhoboter',
            })}

            {getPopup({
              icon: species_levitoner,
              description:
                'Os levitoners são uma raça de pequeno corpo e grande cabeça, o que acabou ajudando na evolução do povo para se locomoverem com pequenos discos voadores. A explosão do sol que ilumina o planeta Pétrique é iminente e eles precisam fugir imediatamente.',
              class: 'gravitationSelectSpecies__button__levitoners',
              name: 'Levitoner',
            })}

            {getPopup({
              icon: species_emierres,
              description:
                'Magros e altos, os emierres vivem no planeta Meleus. Após um longo período de guerra nuclear ter devastado suas regiçoes, os habitantes se uniram para buscar um novo planeta como morada.',
              class: 'gravitationSelectSpecies__button__emierres',
              name: 'Emierres',
            })}
          </Image.Group>
        </div>
      }
    />,

    <PlayDialogue
      key={2}
      buttons="Anterior"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text="Ah sim, seu planeta era realmente belo, mas que catástrofe vocês estão sofrendo... espere, te colocarei em contato com o seu líder."
      image={robo}
      extraContent={''}
    />,
  ];

  return arrPage[animationStep];
}

export default Introdutory;

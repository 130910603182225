import React, { useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { MathComponent } from 'mathjax-react';
import StarMessage from '../../../../components/starMessage';
import './../CSSs/physicsGravitation.scss';

function LawOfUniversalGravitation(props) {
  let scale = 10000000;
  const [step, setStep] = useState(0);
  const [isMinimized, setIsMinimized] = useState(false);
  const [univGravAnimationParams, setUnivGravAnimationParams] = useState({
    rM1: 5*scale,
    rM2: 5*scale,
    d: 100
  })

  function minimizeBar() {
    setIsMinimized(true);
  }

  function maximizeBar() {
    setIsMinimized(false);
  }

  const standardObj = [
    {
      name: 'Lei da Gravitação Universal',
      step: 1,
      comp: (
        <>
          <h1 className="presentationItem">A Lei da Gravitação Universal</h1>

          <p className="presentationItem">
            Através das observações realizadas, Newton conseguiu estabelecer uma expressão matemática que explicasse a atração entre os corpos: <b className="presentationItem">"dois corpos se atraem com força proporcional ao produto de suas massas e inversamente propocional ao quadrado de suas distâncias"</b>. Essa expressão ficou conhecida como Lei da Gravitação Universal, e pode ser mostrada por:
          </p>
          <p className="presentationItem">
            <MathComponent tex={String.raw`F = \frac{G*m_{a}*m_{b}}{d^2}`} />
          </p>
          <p className="presentationItem">
            Em que:
            <br></br>
            <b className="presentationItem">
              <i>F </i>
            </b>{'  '}
            representa a <b className="presentationItem">força</b> de atração entre os corpos
            <br></br>
            <b className="presentationItem">
              <i>G </i>
            </b>{'  '}
            é a <b className="presentationItem">constante</b> de Gravitação Universal (iremos conversar mais sobre ela a seguir)
            <br></br>
            <b className="presentationItem">
              <i>ma </i>
            </b>{'  '}
            é a <b className="presentationItem">massa</b> do corpo A
            <br></br>
            <b className="presentationItem">
              <i>mb </i>
            </b>{'  '}
            é a <b className="presentationItem">massa</b> do corpo B
            <br></br>
            <b className="presentationItem">
              <i>d </i>
            </b>{'  '}
            é a <b className="presentationItem">distância</b> entre os dois corpos.
          </p>

          <div className="presentationItem arrowBtnContainer">
            <Button
              icon
              labelPosition="right"
              onClick={() => {
                setStep(step + 1);
              }}
            >
              Próximo
              <Icon name="right arrow" />
            </Button>
          </div>
        </>
      ),
    },
    {
      name: 'Lei da Gravitação Universal',
      step: 2,
      comp: (
        <>
          <h1 className="presentationItem">A Lei da Gravitação Universal</h1>
          <p className="presentationItem">
            Altere os parâmetros de massa e distância dos dois planetas e veja o que acontece com a força de atração:
          </p>

          <div style={{ width: '100%', height: '250px', textAlign: 'center' }}>
            <div>
              <span style={{ display: 'inline-block', marginLeft: '30px' }} className="mainFont physicsGravitation__lawOfGravitation__f">F = {(6.67 * univGravAnimationParams.rM1 * univGravAnimationParams.rM2 / Math.pow(univGravAnimationParams.d, 2) * Math.pow(10, -11)).toExponential(2)} N       </span>
              <span style={{ display: 'inline-block', marginLeft: '30px' }} className="mainFont physicsGravitation__lawOfGravitation__m1">m1 = {univGravAnimationParams.rM1} kg      </span>
              <span style={{ display: 'inline-block', marginLeft: '30px' }} className="mainFont physicsGravitation__lawOfGravitation__m2">m2 = {univGravAnimationParams.rM2} kg      </span>
              <span style={{ display: 'inline-block', marginLeft: '30px' }} className="mainFont physicsGravitation__lawOfGravitation__d">d = {univGravAnimationParams.d} m  </span>
            </div>
            <svg height='200' width='300'>
              <line x1="100" y1="150" x2={100 + univGravAnimationParams.d} y2="150" stroke="white" />
              <circle cx="100" cy="150" r={univGravAnimationParams.rM1/scale} fill="aquamarine" />
              <circle cx={100 + univGravAnimationParams.d} cy="150" r={univGravAnimationParams.rM2/scale} fill="yellow" />
            </svg>
          </div>
          <div style={{ width: '100%', height: '100px', textAlign: 'center' }}>
            <div className="mainFont physicsGravitation__lawOfGravitation__m1ButtonA">
              <Button
                onClick={
                  () => {
                    setUnivGravAnimationParams(prevState => ({
                      ...prevState, ['rM1']: prevState.rM1 - 10*scale
                    })
                    )
                  }
                }>
                <b>-m1</b>
              </Button>
            </div>
            <div className="mainFont physicsGravitation__lawOfGravitation__m1ButtonB">
              <Button
                onClick={
                  () => {
                    setUnivGravAnimationParams(prevState => ({
                      ...prevState, ['rM1']: prevState.rM1 + 10*scale
                    })
                    )
                  }
                }>
                <b>+m1</b>
              </Button>
            </div>
            <div className="mainFont physicsGravitation__lawOfGravitation__dButtonA">
              <Button
                onClick={
                  () => {
                    setUnivGravAnimationParams(prevState => ({
                      ...prevState, ['d']: prevState.d - 10
                    })
                    )
                  }
                }>
                <b>-d</b>
              </Button>
            </div>
            <div className="mainFont physicsGravitation__lawOfGravitation__dButtonB">
              <Button
                onClick={
                  () => {
                    setUnivGravAnimationParams(prevState => ({
                      ...prevState, ['d']: prevState.d + 10
                    })
                    )
                  }
                }>
                <b>+d</b>
              </Button>
            </div>
            <div className="mainFont physicsGravitation__lawOfGravitation__m2ButtonA">
              <Button
                onClick={
                  () => {
                    setUnivGravAnimationParams(prevState => ({
                      ...prevState, ['rM2']: prevState.rM2 - 1*scale
                    })
                    )
                  }
                }>
                <b>-m2</b>
              </Button>
            </div>
            <div className="mainFont physicsGravitation__lawOfGravitation__m2ButtonB">
              <Button
                onClick={
                  () => {
                    setUnivGravAnimationParams(prevState => ({
                      ...prevState, ['rM2']: prevState.rM2 + 1*scale
                    })
                    )
                  }
                }>
                <b>+m2</b>
              </Button>
            </div>
          </div>


          <div className="presentationItem arrowBtnContainer">
            <Button
              icon
              labelPosition="left"
              onClick={() => {
                setStep(step - 1);
              }}
            >
              <Icon name="left arrow" />
              Anterior
            </Button>
          </div>

        </>
      ),
    },
  ];

  return (
    <div className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`}>
      {/* <div className="minMaxBar">
        <Icon className="icon" name='caret down' onClick={minimizeBar}/>
        <Icon className="icon" name='caret up' onClick={maximizeBar}/>
      </div> */}
      {!isMinimized && standardObj[step].comp}
    </div>
  );
}

export default LawOfUniversalGravitation;

import React, { useEffect, useState } from 'react';
import { Segment, Image, Button } from 'semantic-ui-react';
import LoadingSpinner from '../../components/loadingSpinner';
import CompAvatar from './compAvatar';
import './habilities.css';
import './myProfile.scss';
import './profile.scss';

import api from '../../services/api';

function Avatar() {
  const [pic, setPic] = useState(localStorage.getItem('avatar'));
  const [code, setCode] = useState('');
  const [avatars, setAvatars] = useState([]);
  const [isChanging, setIsChanging] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await api
        .post('getClientsAvatars', {
          client_id: localStorage.getItem('client_id'),
        })
        .then((res) => {
          setAvatars(res.data);
        })
        .catch((err) => {
          console.log(err.message);
        });
    };
    fetchData();
  }, []);

  const handleSelect = (code, image) => {
    setCode(code);
    setPic(image);
  };

  const handleChange = async () => {
    if (code && code !== '') {
      setIsChanging(true);
      await api
        .post('changeAvatar', {
          client_id: localStorage.getItem('client_id'),
          code: code,
        })
        .then((res) => {
          localStorage.setItem('avatar', pic);
          window.location.reload(false);
        })
        .catch((err) => {
          setIsChanging(false);
          console.log(err.message);
        });
    }
  };

  return (
    <>
      <div className="profileSubItem">
        <div>
          <h1 className="presentationItem">Avatar</h1>
        </div>
        {(isChanging || !avatars || avatars.length === 0) && (
          <LoadingSpinner loading={true} className="restricted" />
        )}
        <div className="profile__firstSquare">
          <Image
            src={`data:image/jpeg;base64,${pic}`}
            floated="left"
            className="profile__picAvatar"
          />
        </div>
        <div className="profile__secondSquare">
          <p className="presentationItem">Avatares Disponíveis:</p>
          <Segment
            style={{
              overflow: 'auto',
              maxHeight: '60vh',
              color: 'white',
              backgroundColor: '#303841',
            }}
          >
            {(avatars || []).map((avatar, index) => {
              let className = 'profile__firstAvatar';
              if (index % 4 === 1) {
                className = 'profile__secondAvatar';
              } else if (index % 4 === 2) {
                className = 'profile__thirdAvatar';
              } else if (index % 4 === 3) {
                className = 'profile__fourthAvatar';
              }
              return (
                <div className={className}>
                  {' '}
                  <CompAvatar
                    image={avatar.image}
                    check={!avatar.is_blocked}
                    setPic={handleSelect}
                    code={avatar.code}
                  />
                </div>
              );
            })}
          </Segment>
        </div>

        <div className="profile__buttomAvatar">
          <Button onClick={handleChange}>Selecionar avatar</Button>
        </div>
      </div>
    </>
  );
}

export default Avatar;

import React from 'react';
import AfterChallengeComponent from '../../../../components/MapComponents/afterChallenge';
import species_octa from '../../../../images/Physics/Gravitation/species_octa.PNG';
import species_boter from '../../../../images/Physics/Gravitation/species_boter.PNG';
import species_levitoner from '../../../../images/Physics/Gravitation/species_levitoner.PNG';
import species_emierres from '../../../../images/Physics/Gravitation/species_emierres.PNG';


function getImage() {
  const additionalInfo = JSON.parse(sessionStorage.getItem('additional_info'));
  switch (additionalInfo.specie) {
    case 'Octawabeter':
      return species_octa;
    case 'Olhoboter':
      return species_boter;
    case 'Levitoner':
      return species_levitoner;
    case 'Emierres':
      return species_emierres;
    default:
      break;
  }
}


function AfterTravel(props) {
  const additionalInfo = JSON.parse(sessionStorage.getItem('additional_info'));
  return (
    <AfterChallengeComponent
      subject="physics"
      typeScoring="gravitation"
      arrayScoring="arrStarKeplersLaws"
      search={null}
      image={getImage()}
      map={'/mapa-de-fases/' + sessionStorage.getItem('module_id')}
      speak={[
        {
          paragraph1:
            'Parabéns, grande astronauta! Você soube guiar nossa nave pelo espaço e gravou seu nome na história da raça ' +
            additionalInfo.specie +
            '!',
          paragraph2: 'Estamos orgulhosos de você.',
        },
        {
          paragraph1:
            'Além da viagem que realizou com nossa nave, você deve se orgulhar das premiações individuais que recebeu.',
          paragraph2: 'Além disso, em todo o módulo realizado você acumula um total de ',
        },
        {
          paragraph1:
            'Nossa raça está ansiosa para chegar ao planeta de destino e desfrutar da nova vida por lá. Quando estiver pronto, vá até o mapa de fases para continuar sua jornada rumo ao planeta!',
        },
      ]}
    />
  );
}

export default AfterTravel;

import React from 'react';
import { Button, Icon, Image, List, Comment } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import './gravitationComp.scss';
import flagFormulaQuest from '../../../../images/Physics/Gravitation/flagFormulaQuest.png';
import { getImageSpecie } from './../Gerais/functions';

import gameManager from '../../../../managers/GameManager';
import StarMessage from '../../../starMessage';

function FinalPanel(props) {
  const SIZEICONS = '6.5%';
  const fontColor = {
    color: 'white',
    fontSize: '0.8vw',
  };
  let specieImg = getImageSpecie();

  let history = useHistory();

  function mountPanel() {
    return (
      <>
        <div className="gravitation__finalPanel">
          <Image src={flagFormulaQuest} className="gravitation__finalPanel__image" />

          <p className="gravitation__finalPanel__resultsTable">
            Resultado Final:
            <br></br>
            <List celled animated>
              <List.Item>
                <Icon inverted color="grey" name={props.results[0].icon} />
                <List.Content>{props.results[0].text}</List.Content>
              </List.Item>
            </List>
            <List celled animated>
              <List.Item>
                <Icon inverted color="grey" name={props.results[1].icon} />
                <List.Content>{props.results[1].text}</List.Content>
              </List.Item>
            </List>
          </p>

          <div className="gravitation__finalPanel__message">
            <Comment.Group size="big">
              <Comment>
                <Comment.Avatar as="a" src={specieImg} />
                <Comment.Content>
                  <Comment.Author style={fontColor}>IMPERADOR GILIES:</Comment.Author>
                  <Comment.Text style={fontColor}>
                    {' '}
                    {gameManager.objSpecificGame.physics.gravitation.finalMessage}
                  </Comment.Text>
                </Comment.Content>
              </Comment>
            </Comment.Group>
          </div>

          <div className="gravitation__finalPanel__messageStar">
            {gameManager.objSpecificGame.physics.gravitation.win === 'true' && (
              <List divided relaxed>
                <List.Item>
                  <List.Icon name="star" size="large" verticalAlign="middle" color="yellow" />
                  <List.Content>
                    <p style={fontColor}>
                      Estrela Desbloqueada:
                      <br></br>
                      Parabéns, você conquistou a estrela do desafio '{props.challengeName}'
                    </p>
                  </List.Content>
                </List.Item>
              </List>
            )}
          </div>

          {gameManager.objSpecificGame.physics.gravitation.win === 'true' && (
            <div className="gravitation__finalPanel__button">
              <Button
                icon
                labelPosition="left"
                onClick={() => {
                  // window.location.reload(false);
                  // props.func();
                  history.push(props.func);
                  window.location.reload(false);
                }}
              >
                <Icon name="play" />
                Finalizar desafio
              </Button>
            </div>
          )}

          {gameManager.objSpecificGame.physics.gravitation.win === 'false' && (
            <div className="gravitation__finalPanel__button">
              <Button
                icon
                labelPosition="left"
                onClick={() => {
                  gameManager.updateInfo('physics', 'gravitation', 'running', '1');
                  gameManager.updateInfo('physics', 'gravitation', 'restart', '1');
                }}
              >
                <Icon name="play" />
                Reiniciar desafio
              </Button>
            </div>
          )}

          {gameManager.objSpecificGame.physics.gravitation.win === 'true' && (
            <StarMessage
              show={true}
              message={gameManager.objSpecificGame.physics.gravitation.missionMessage}
              subjectScoring={'physics'}
              typeScoring={'gravitation'}
              indexScoring={props.starIndex}
              xpAward={props.xpAward}
            />
          )}
        </div>
      </>
    );
  }

  return <>{props.condition && mountPanel()}</>;
}

export default FinalPanel;

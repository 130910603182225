import React, { useState, Component } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { MathComponent } from 'mathjax-react';
import Anime, { anime } from 'react-anime';
import StarMessage from '../../../../components/starMessage';

class OrbitsAndPlanetsMoving extends Component{

  constructor(props){
    super(props)

    this.state = {
      animationStarted: false,
      animationType: 1 
    }
    this.animation1 = null;
  }

  animate1(){

    this.animation1 = 
      anime({
        targets: '#planet-third-kepler-law-1',
        translateX: anime.path('#orbit-third-kepler-law-1')('x'),
        translateY: anime.path('#orbit-third-kepler-law-1')('y'),
        rotate: anime.path('#orbit-third-kepler-law-1')('angle'),
        easing: 'linear',
        duration: Math.sqrt(20*Math.pow(this.props.raio,3)),
        loop: true,
        autoplay: true
      })
  }

  componentDidMount(){      

    this.animate1()
  }

  componentWillUnmount(){

    this.animation1.pause()

  }

  render(){
    return(
      <svg className="orbitSvgHeliocentrism" style={{position: 'relative', left: '35%', bottom: '5%'}} id="orbit-svg-helio" height="350" width="350">
        <circle id="orbit-third-kepler-law-1" className="orbit1Heliocentrism" cx="160" cy="160" r={this.props.raio} stroke="white" strokeWidth="3" fill="none"/>
        <circle id="planet-third-kepler-law-1" className="mercuryHeliocentrism" cx="0" cy="0" r="5" stroke="dodgerBlue" strokeWidth="3" fill="dodgerBlue"/>
        <circle id="sun-third-kepler-law-1" className="mercuryHeliocentrism" cx="160" cy="160" r="5" stroke="yellow" strokeWidth="3" fill="yellow"/>
      </svg>
    )
  }
}

class OrbitsAndPlanetsMoving2 extends Component{

  constructor(props){
    super(props)

    this.state = {
      animationStarted: false,
      animationType: 1 
    }
    this.animation1 = null;
  }

  animate1(){

    this.animation1 = 
      anime({
        targets: '#planet-third-kepler-law-2',
        translateX: anime.path('#orbit-third-kepler-law-2')('x'),
        translateY: anime.path('#orbit-third-kepler-law-2')('y'),
        rotate: anime.path('#orbit-third-kepler-law-2')('angle'),
        easing: 'linear',
        duration: Math.sqrt(20*Math.pow(this.props.raio,3)),
        loop: true,
        autoplay: true
      })
  }

  componentDidMount(){

    this.animate1()
  }

  componentWillUnmount(){
    console.log('parou a 2')
    this.animation1.pause()

  }

  render(){
    return(
      <svg className="orbitSvgHeliocentrism" style={{position: 'relative', left: '35%', bottom: '5%'}} id="orbit-svg-helio" height="350" width="350">
        <circle id="orbit-third-kepler-law-2" className="orbit1Heliocentrism" cx="160" cy="160" r={this.props.raio} stroke="white" strokeWidth="3" fill="none"/>
        <circle id="planet-third-kepler-law-2" className="mercuryHeliocentrism" cx="0" cy="0" r="5" stroke="dodgerBlue" strokeWidth="3" fill="dodgerBlue"/>
        <circle id="sun-third-kepler-law-2" className="mercuryHeliocentrism" cx="160" cy="160" r="5" stroke="yellow" strokeWidth="3" fill="yellow"/>
      </svg>
    )
  }
}

class OrbitsAndPlanetsMoving3 extends Component{

  constructor(props){
    super(props)

    this.state = {
      animationStarted: false,
      animationType: 1 
    }
    this.animation1 = null;
  }

  animate1(){

    this.animation1 = 
      anime({
        targets: '#planet-third-kepler-law-3',
        translateX: anime.path('#orbit-third-kepler-law-3')('x'),
        translateY: anime.path('#orbit-third-kepler-law-3')('y'),
        rotate: anime.path('#orbit-third-kepler-law-3')('angle'),
        easing: 'linear',
        duration: Math.sqrt(20*Math.pow(this.props.raio,3)),
        loop: true,
        autoplay: true
      })
    console.log(this.animation1)
  }

  componentDidMount(){

    this.animate1()
      
  }

  componentWillUnmount(){

    this.animation1.pause()

  }

  render(){
    return(
      <svg className="orbitSvgHeliocentrism" style={{position: 'relative', left: '35%', bottom: '5%'}} id="orbit-svg-helio" height="350" width="350">
        <circle id="orbit-third-kepler-law-3" className="orbit1Heliocentrism" cx="160" cy="160" r={this.props.raio} stroke="white" strokeWidth="3" fill="none"/>
        <circle id="planet-third-kepler-law-3" className="mercuryHeliocentrism" cx="0" cy="0" r="5" stroke="dodgerBlue" strokeWidth="3" fill="dodgerBlue"/>
        <circle id="sun-third-kepler-law-3" className="mercuryHeliocentrism" cx="160" cy="160" r="5" stroke="yellow" strokeWidth="3" fill="yellow"/>
      </svg>
    )
  }
}




function ThirdKeplerLawPage(props) {
  const [keplerLaw, setKeplerLaw] = useState(0);
  const [isMinimized, setIsMinimized] = useState(false);
  const [r, setR] = useState(50);


  const standardObj = [
    {
      name: '3ª Lei de Kepler',
      keplerLaw: 1,
      comp: (
        <>
          <h1 className="presentationItem">3ª Lei de Kepler - Lei dos Períodos (1618)</h1>

          <p className="presentationItem">
            É intuitivo pensar que{' '}
            <b className="presentationItem">
              quanto mais distante a órbita de um planeta for de sua estrela, mais tempo durará para
              completar uma volta em si mesma
            </b>
            . Com base nisso, Kepler tentou por cerca de 10 anos encontrar uma relação entre o
            período de revolução de um planeta e o raio de sua órbita.
          </p>
          <p className="presentationItem">
            Ele descobriu que o quadrado do período de translação <i>T</i> é proporcional ao cubo da
            sua distância <i>R</i> até o sol. Dessa forma:
            <MathComponent tex={String.raw`\frac{T^2}{R^3} = k`} />
          </p>
          <p className="presentationItem">
            <b className="presentationItem">
              {' '}
              Essa constante k é a mesma para todos os planetas do mesmo sistema solar
            </b>
            . Isso faz com que os raios e períodos de revolução de diferentes planetas possam ser
            comparados segundo a variação da fórmula:
            <MathComponent tex={String.raw`\frac{T_{a}^2}{R_{a}^3} = \frac{T_{b}^2}{R_{b}^3}`} />
          </p>

          <div className="presentationItem arrowBtnContainer">
            <Button
              icon
              labelPosition="right"
              onClick={() => {
                setKeplerLaw(keplerLaw + 1);
              }}
            >
              Próximo
              <Icon name="right arrow" />
            </Button>
          </div>
        </>
      ),
    },
    {
      name: '3ª Lei de Kepler',
      keplerLaw: 2,
      comp: (
        <>
          <h1 className="presentationItem">3ª Lei de Kepler - Lei dos Períodos (1618)</h1>
          <p className="presentationItem">
            Podemos ver o funcionamento da Lei dos Períodos a seguir, variando a distância R do
            planeta ao seu respectivo sol e observando seu período de revolução :
          </p>
          {r===50 && <OrbitsAndPlanetsMoving raio={r} step={keplerLaw} restart={false} animationType={r/10}/>}
          {r===75 && <OrbitsAndPlanetsMoving2 raio={r} step={keplerLaw} restart={false} animationType={r/10}/>}
          {r===100 && <OrbitsAndPlanetsMoving3 raio={r} step={keplerLaw} restart={false} animationType={r/10}/>}
          {r===50 && <h1 style={{position: 'absolute', left: '2vw', top: '50vh'}} className="mainFont">Período: 0,24 anos</h1>}
          {r===75 && <h1 style={{position: 'absolute', left: '2vw', top: '50vh'}} className="mainFont">Período: 1 ano</h1>}
          {r===100 && <h1 style={{position: 'absolute', left: '2vw', top: '50vh'}} className="mainFont">Período: 11,86 anos</h1>}
          {/* <OrbitsAndPlanetsMoving raio={r} step={keplerLaw} restart={false} animationType={r/10}/> */}
          <div className="presentationItem arrowBtnContainer">
            <Button
              icon
              labelPosition="left"
              onClick={() => {
                setKeplerLaw(keplerLaw - 1);
              }}
            >
              <Icon name="left arrow" />
              Anterior
            </Button>
          </div>

          <Button
            onClick={() => {
              setR(50)
            }}
          >
            Raio 0,39 UA
          </Button>
          <Button
            onClick={() => {
              setR(75)
            }}
          >
            Raio 1 UA
          </Button>
          <Button
            onClick={() => {
              setR(100)
            }}
          >
            Raio 5,20 UA
          </Button>


          <StarMessage
            show={true}
            message={'As Leis de Kepler'}
            subjectScoring="physics"
            typeScoring="gravitation"
            arrayScoring={'arrStarKeplersLaws'}
            indexScoring={0}
            xpAward={30}
          />

        </>
      ),
    },
  ];

  return (
    <div className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`}>
      {/* <div className="minMaxBar">
        <Icon className="icon" name='caret down' onClick={minimizeBar}/>
        <Icon className="icon" name='caret up' onClick={maximizeBar}/>
      </div> */}
      {!isMinimized && standardObj[keplerLaw].comp}
    </div>
  );
}

export default ThirdKeplerLawPage;

import React from 'react';
import RememberVariablesComponent from '../../../../components/MapComponents/rememberVariablesComponent';

function RememberVariables(props) {
  return (
    <RememberVariablesComponent
      paragraph1="Este desafio é composto por duas etapas:"
      paragraph2={null}
      paragraph3={null}
      sub={[
        {
          paragraph:
            '1) Com o carro à 306 km/h, descubra qual a velocidade teremos após uma desaceleração de 8m/s² durante 10 segundos;',
        },
        {
          paragraph:
            '2) Com a velocidade encontrada na etapa 1, precisamos descobrir qual a aceleração necessária para percorrer 2000 metros em um tempo de 40 segundos.',
        },
        { paragraph: null },
        { paragraph: null },
      ]}
    />
  );
}

export default RememberVariables;

import React, { useEffect } from "react";
import "./homePage.scss";
import logo from "../images/Logos/mrQuest_logo.png";
import rocketBlue from "../images/Home/rocketBlue.png";
import game from "../images/Home/game.png";
import performance from "../images/Home/performance.png";
import xp from "../images/Home/xp.png";
import logoFooter from "../images/Logos/mrQuest_logo.png";
import polia from "../images/polia.gif";
import { Button, Icon } from "semantic-ui-react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import anime from 'animejs/lib/anime.es.js';

function HomePage(props){

    function moveRocket(){

        var path = anime.path('#rocket-svg');

        return anime({
            targets: '#rocket-animation',
            translateX: path('x'),
            translateY: path('y'),
            rotate: path('angle'),
            easing: 'linear',
            duration: 10000,
            loop: true
        })
    }


    useEffect(()=>{
        moveRocket();
    },[]);

    return(
        <>
        <div className="homePage">
            <div className="homePage__invisibleBar">
                <Button color='orange' size='huge' onClick={()=>{props.history.push('/entre-em-contato')}}>Entre em contato</Button>
                <Button color='orange' size='huge' onClick={()=>{props.history.push('/login')}}>Log in</Button>
            </div>
            <div className="homePage__mainContainer">
                <img className="homePage__logo" src={logo} />
                <p className="homePage__mainParagraph homePage__homeFonts__mainParagraph">Seus alunos no próximo nível.</p>
                <svg style={{fill:'#3ec1d3',stroke:'none',strokeWidth:'0.264583px', strokeLinecap:'butt', strokeLinejoin:'miter',strokeOpacity:'1', fillOpacity:'1'}}
                 width="100vw" height="100vh" viewBox="0 0 210 135" preserveAspectRatio="none">
                    <path d="M 210.04613,26.094531 C 177.50373,28.309658 142.72074,29.614154 109.5805,81.249934 85.135257,130.02583 40.463473,126.54984 0,133.94531 L 0.00104402,-0.00104402 210.00703,0 Z"/>
                </svg>
                
                
            </div>
            <div className="homePage__section1">
                
                <div className="homePage__section1__subsection">
                    <p className="homePage__sectionParagraph homePage__homeFonts__mainSection"> <b className="homePage__homeFonts__boldMainSection">Um game para cada disciplina.</b> Nossos alunos aprendem cinemática enquanto disputam uma corrida de carros, viajam entre planetas enquanto aprendem sobre gravitação... Cada aprendizado é uma nova fase!</p>                         
                    <img className="homePage__sectionImg" src={game} alt="game"/>
                </div>

                <div className="homePage__section1__subsection">
                    <img className="homePage__sectionImg" src={performance} alt="performance"/>
                    <p className="homePage__sectionParagraph homePage__homeFonts__mainSection"> <b className="homePage__homeFonts__boldMainSection"> Leve seus alunos à um outro nível. </b> Com games interativos, nosso modelo alinha aprendizado e diversão, aumentando o engajamento e performance dos alunos.</p>                    
                </div>
                <div className="homePage__section1__subsection">
                    <p className="homePage__sectionParagraph homePage__homeFonts__mainSection"> <b className="homePage__homeFonts__boldMainSection"> Avaliação dinâmica. </b> Temos um sistema de recompensas de estrelas, pontos de experiência e desbloqueio de itens, permitindo uma avaliação personalizada para cada estudante, de acordo com os seus maiores interesses.</p>                    
                    <img className="homePage__sectionImg" src={xp} alt="xp"/>
                </div>
            </div>
            <div className="homePage__section2">
                <div className="homePage__section2__headerContainer">
                    <h1 className="homePage__homeFonts__title">Nosso produto</h1>
                </div>
                <svg style={{fill:'#3ec1d3',fillOpacity:'1',stroke:'none',strokeWidth:'0.264583px',strokeLinecap:'butt', strokeLinejoin:'miter', strokeOpacity:'1'}}
                 width="100vw" height="120vh" viewBox="0 162 210 135" preserveAspectRatio="none">
                    <path d="M -0.12661724,293.79653 C 49.904021,290.38443 89.74374,273.92081 111.38958,234.15625 c 27.78465,-43.48608 62.70312,-49.07374 98.34285,-50.82974 l 0.602,113.7703 -210.41711229,-0.0855 z"/>
                </svg>
                <p className="homePage__section2__paragraph1 homePage__homeFonts__secondSection">O Mr. Quest é uma plataforma de apoio às escolas, onde os estudantes têm acesso à um modelo de ensino gamificado, ajudando professores à conseguirem elevar seus alunos ao próximo nível. </p>
                <p className="homePage__section2__paragraph2 homePage__homeFonts__secondSection">Estamos em constante evolução, ampliando nosso leque de disciplinas e produtos para melhor atender as escolas com as suas necessidades.</p>
            </div>
            
            <div className="homePage__section3">
                <h1 className="homePage__homeFonts__title__white">Vai ficar de fora dessa?</h1>
                <div className="homePage__section3__contactButton mainFont" onClick={()=>{props.history.push('/entre-em-contato')}}>Entre em contato</div>
                <svg  width="100%" height="100%" fill='none' stroke='none' strokeWidth='0.264583px'>
                    <path id="rocket-svg" d="M 902 201 C 1006 276 1018 262 1086 477 C 1125 602.5 1265.625 602.5 1335.9375 490 C 1359.375 452.5 1382.8125 415 1406.25 208.75 C 1406.25 171.25 1406.25 133.75 1406.25 96.25 C 1406.25 -16.25 1265.625 -16.25 1195.3125 96.25 C 1125 208.75 1054.6875 321.25 1021 471 C 1001 601 984.375 771.25 773.4375 715 C 656.25 677.5 539.0625 640 421.875 602.5 C 281.25 546.25 281.25 490 281.25 433.75 C 281.25 377.5 281.25 321.25 281.25 265 C 281.25 96.25 370 130 387 332 C 399 461 582 461 460 238 C 390 150 371 298 446 358 C 557 448 666 160 880 194 Z"/>
                </svg>
                <img id="rocket-animation" className="homePage__rocket" src={rocketBlue} alt="rocket"/>
                {/* <img className="homePage__section3__boy" src={boyPlay} alt="boy"/> */}
                <img className="homePage__section3__polia" src={polia} alt="polia"/>
                <div className="homePage__footer">
                    
                    <span className="secondaryFontBlack homePage__footer__text">© {new Date().getFullYear()} - Mr. Quest. Todos os direitos reservados.</span>
                    <img className="homePage__footer__logo" src={logoFooter} alt="logo"/>
                    <span className="homePage__footer__socialMedia">
                        <Icon name="facebook official" size="large"/>
                        <Icon name="instagram" size="large"/>
                        <Icon name="linkedin" size="large"/>
                    </span>
                </div>
            </div>
            
        </div>
        </>
    )
}

export default HomePage;
import { props } from 'ramda';
import React, { useState } from 'react';
import { Button, Confirm, Icon, Image, Popup, List, Comment, Reveal } from 'semantic-ui-react';
import './compMissionMessage.scss';

// import manager from './../../images/Physics/Kinematics/face_manager.PNG';

function Message(props) {
  const fontColor = {
    color: 'white',
    fontSize: '1.7vh',
  };

  return (
    <div className="">
      {/* <div style={comentStyle}> */}
      <div className="missionMessage__card">
        <Comment.Group size="massive">
          <Comment>
            <Comment.Avatar as="a" src={props.image} />
            <Comment.Content>
              <Comment.Author style={fontColor}>{props.name}</Comment.Author>
              <Comment.Text style={fontColor}>{props.mission}</Comment.Text>
            </Comment.Content>
          </Comment>
        </Comment.Group>
      </div>
    </div>
  );
}

export default Message;

import React from 'react';
import { Card, List, Image, Progress, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

function CompModule(props) {
  let nAcquiredStars = props.earned_stars;
  let nTotalStars = props.available_stars;
  let statusColor =
    props.status === 'Finalizado' ? 'green' : props.status === 'Em andamento' ? 'blue' : 'red';
  let statusIcon =
    props.status === 'Finalizado'
      ? 'check'
      : props.status === 'Em andamento'
      ? 'spinner'
      : 'stopwatch';
  let percentage = parseInt(((100 * nAcquiredStars) / nTotalStars).toFixed(1));

  return (
    <>
      <Card>
        <Image src={props.image} wrapped ui={false} />
        <Card.Content>
          <Card.Header>{props.name}</Card.Header>
          <Card.Description>
            <List animated divided>
              <List.Item>
                <List.Icon name={statusIcon} color={statusColor} />
                <List.Content>{props.status}</List.Content>
              </List.Item>

              <List.Item>
                <List.Icon name="star" color="yellow" />
                <List.Content>
                  {nAcquiredStars}/{nTotalStars} estrelas
                </List.Content>
              </List.Item>

              <List.Item>
                <List.Content style={{ textAlign: 'center', marginBottom: '50% 0' }}>
                  Progresso: <br></br>
                  <Progress
                    value={percentage.toFixed(0)}
                    total="100"
                    progress="percent"
                    active
                    color={percentage < 30 ? 'red' : percentage < 60 ? 'yellow' : 'green'}
                  />
                </List.Content>
              </List.Item>
              <List.Item>
                <Button basic color="green" as={Link} to={props.destiny}>
                  {percentage == 0 &&
                  props.status !== 'Finalizado' &&
                  props.status !== 'Em andamento'
                    ? 'Começar'
                    : percentage == 100 && props.status === 'Finalizado'
                    ? 'Refazer'
                    : 'Continuar'}
                </Button>
              </List.Item>
            </List>
          </Card.Description>
        </Card.Content>
      </Card>
    </>
  );
}

export default CompModule;

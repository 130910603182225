import cinematics_manager_black from '../../images/Physics/Kinematics/cinematics_manager_black.PNG';
import cinematics_manager_blue from '../../images/Physics/Kinematics/cinematics_manager_blue.PNG';
import cinematics_manager_green from '../../images/Physics/Kinematics/cinematics_manager_green.PNG';
import cinematics_manager_pink from '../../images/Physics/Kinematics/cinematics_manager_pink.PNG';
import cinematics_manager_red from '../../images/Physics/Kinematics/cinematics_manager_red.PNG';
import cinematics_manager_yellow from '../../images/Physics/Kinematics/cinematics_manager_yellow.PNG';

export function getImages(subject, search) {
  if (subject == 'physics.kinematics')
    switch (search) {
      case 'Arara Real':
        return cinematics_manager_blue;
      case 'Jacaré Impiedoso':
        return cinematics_manager_green;
      case 'Boto Veloz':
        return cinematics_manager_pink;
      case 'Falcão Negro':
        return cinematics_manager_black;
      case 'Guará Rubro':
        return cinematics_manager_red;
      case 'Capivara Furiosa':
        return cinematics_manager_yellow;
      default:
        break;
    }
}

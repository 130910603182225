/* eslint-disable react/display-name */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import PlayDialogue from '../../../Common/compPlayDialogue';
import species_octa from '../../../../images/Physics/Gravitation/species_octa.PNG';
import species_boter from '../../../../images/Physics/Gravitation/species_boter.PNG';
import species_levitoner from '../../../../images/Physics/Gravitation/species_levitoner.PNG';
import species_emierres from '../../../../images/Physics/Gravitation/species_emierres.PNG';

import api from '../../../../services/api';

function MapExplanation(props) {
  const [animationStep, setAnimationStep] = useState(0);
  //   const [selectedTeam, setselectedTeam] = useState(null);

  const additionalInfo = JSON.parse(sessionStorage.getItem('additional_info'));

  var presentationText =
    'Olá grande astronauta! Eu sou Giles, o Grande Imperados da raça ' +
    additionalInfo.specie +
    '!';

  function getImage() {
    switch (additionalInfo.specie) {
      case 'Octawabeter':
        return species_octa;
      case 'Olhoboter':
        return species_boter;
      case 'Levitoner':
        return species_levitoner;
      case 'Emierres':
        return species_emierres;
      default:
        break;
    }
  }

  function updateOfficeAcess() {
    new Promise(async (resolve, reject) => {
      await api
        .post('updateAccessOffice', {
          challenge_id: sessionStorage.getItem('challenge_id'),
          client_id: localStorage.getItem('client_id'),
          access_office: true,
        })
        .then((res) => {
          sessionStorage.setItem('access_office', true);
        })
        .catch((err) => {});
      resolve('Request done!');
    });
  }

  useEffect(() => {
    if (animationStep === 11) {
      props.setNextItem('Tempo');
    }
  }, [animationStep]);

  function goToMap() {
    props.setIsMinimized(true);
  }

  const arrPage = [
    <PlayDialogue
      key={0}
      buttons="Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text={presentationText}
      image={getImage()}
      extraContent={''}
    />,

    <PlayDialogue
      key={1}
      buttons="Anterior/Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text="Como nosso robô EE14 lhe mostrou, vivemos à beira do colapso de nosso planeta, logo sofreremos a destruição total. Identificamos que o paneta Quest pode ser nossa salvação e precisamos que você nos guie até lá!"
      image={getImage()}
      extraContent={''}
    />,

    <PlayDialogue
      key={2}
      buttons="Anterior/Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text="Será uma longa viagem em que sairemos de nosso planeta natal em busca dessa nova residência. Para isso, iremos lhe oferecer o treinamento adequado de um verdadeiro astronauta."
      image={getImage()}
      extraContent={''}
    />,

    <PlayDialogue
      key={3}
      buttons="Anterior/Mapa"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={goToMap}
      text="Primeiramente, você terá acesso ao nosso mapa, onde encontrará diferentes acessos referentes ao mundo das viagens espaciais. Como primeiro objetivo, acesse o mapa e vá aos Registros, queremos conversar com você lá!"
      image={getImage()}
      extraContent={updateOfficeAcess()}
    />,
  ];

  return arrPage[animationStep];
}

export default MapExplanation;

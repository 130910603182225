import React from 'react';
import MissionComponent from '../../../../components/MapComponents/missionComponent';

function HistoryPage(props) {
  return (
    <>
      <MissionComponent
        numberMissions={5}
        subject="physics"
        typeScoring="kinematics"
        arrayScoring="arrStarMRUV"
        missionName="Escorregando no Gelo"
        missionDescription="Descrição: No Autódromo, pense rápido para ultrapassar seu adversário e vencer a corrida."
        missionVectorNumber={4}
        submission={[
          {
            missionName: 'Comparação dos carros',
            missionDescription: 'Descrição: No Laboratório, compare o movimento dos dois carros.',
            missionVectorNumber: 0,
          },
          {
            missionName: 'Velocidade vs Tempo',
            missionDescription: 'Descrição: Investigue a bomba de Combustível.',
            missionVectorNumber: 1,
          },
          {
            missionName: 'Movimento retilíneo uniformemente variado',
            missionDescription: 'Descrição: Converse com o mecânico Luiz Câmera.',
            missionVectorNumber: 2,
          },
          {
            missionName: 'Partindo do Repouso',
            missionDescription: 'Descrição: Converse com a Engenheira Thaís Condida.',
            missionVectorNumber: 3,
          },
        ]}
      />
    </>
  );
}

export default HistoryPage;

/* eslint-disable react/display-name */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import PlayDialogue from '../../../Common/compPlayDialogue';
import species_octa from '../../../../images/Physics/Gravitation/species_octa.PNG';
import species_boter from '../../../../images/Physics/Gravitation/species_boter.PNG';
import species_levitoner from '../../../../images/Physics/Gravitation/species_levitoner.PNG';
import species_emierres from '../../../../images/Physics/Gravitation/species_emierres.PNG';

import api from '../../../../services/api';

function MapExplanation(props) {
  const [animationStep, setAnimationStep] = useState(0);
  //   const [selectedTeam, setselectedTeam] = useState(null);

  const additionalInfo = JSON.parse(sessionStorage.getItem('additional_info'));

  function getImage() {
    switch (additionalInfo.specie) {
      case 'Octawabeter':
        return species_octa;
      case 'Olhoboter':
        return species_boter;
      case 'Levitoner':
        return species_levitoner;
      case 'Emierres':
        return species_emierres;
      default:
        break;
    }
  }

  function updateOfficeAcess() {
    new Promise(async (resolve, reject) => {
      await api
        .post('updateAccessOffice', {
          challenge_id: sessionStorage.getItem('challenge_id'),
          client_id: localStorage.getItem('client_id'),
          access_office: true,
        })
        .then((res) => {
          sessionStorage.setItem('access_office', true);
        })
        .catch((err) => {});
      resolve('Request done!');
    });
  }

  useEffect(() => {
    if (animationStep === 11) {
      props.setNextItem('Tempo');
    }
  }, [animationStep]);

  function goToMap() {
    props.setIsMinimized(true);
  }

  const arrPage = [
    <PlayDialogue
      key={0}
      buttons="Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text="Olá grande astronauta, está curtindo nossa viagem pelo espaço? Você tem comandado essa missão muito bem, mas parece que estamos com algum problema e nossa nave está se distanciando do nosso alvo no planeta Quest."
      image={getImage()}
      extraContent={''}
    />,

    <PlayDialogue
      key={1}
      buttons="Anterior/Mapa"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={goToMap}
      text="Nos ajude a identificar o que está ocorrendo. Acesse o mapa de viagem e aprenda o funcionamento da Lei da Gravitação Universal, com ela, podemos descobrir nosso problema."
      image={getImage()}
      extraContent={updateOfficeAcess()}
    />,
  ];

  return arrPage[animationStep];
}

export default MapExplanation;

import React from 'react';
import RememberVariablesComponent from '../../../../components/MapComponents/rememberVariablesComponent';

function RememberVariables(props) {
  return (
    <RememberVariablesComponent
      paragraph1="Começamos partindo do repouso, com velocidade igual à zero."
      paragraph2="O nosso objetivo é descobrir qual aceleração devemos impor ao carro para que alcancemos a velocidade máxima de 300 km/h em um período de 15 segundos."
      paragraph3={null}
      sub={null}
    />
  );
}

export default RememberVariables;

import React from 'react';
import RememberVariablesComponent from '../../../../components/MapComponents/rememberVariablesComponent';

function RememberVariables(props) {
  return (
    <RememberVariablesComponent
      paragraph1="Começamos do ponto de partida com uma velocidade média de 210 km/h. Sabemos que a corrida tem 5 voltas no total, cada volta composta por 880m; a troca de pneus é realizada no início de cada volta e demora 2s para ser realizada."
      paragraph2="O nosso objetivo é descobrir qual troca de pneus trará uma velocidade média resultante superior à 216 km/h:"
      paragraph3={null}
      sub={[
        { paragraph: '1) Pneu Resistente: Velocidade de 220 km/h e troca no fim da 2ª volta;' },
        { paragraph: '2) Pneu Intermediário: Velocidade de 260 km/h e troca no fim da 3ª volta;' },
        { paragraph: '3) Pneu Macio: Velocidade de 63,88 m/s e troca no fim da 3ª volta;' },
        { paragraph: '4) Pneu Ultramacio: Velocidade de 72,22 m/s e troca no fim da 4ª volta' },
      ]}
    />
  );
}

export default RememberVariables;

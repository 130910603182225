let PointsTraj2 = [
  [-2, 0.005661137402057648],
  [-2, 1.993382317814394],
  [-2, 4.001410749633578],
  [-2, 5.991312011246919],
  [-2, 8.00356781631308],
  [-2, 10.006748313971912],
  [-2, 12.004004655755125],
  [-2, 14.005763139475675],
  [-2, 15.992274101243993],
  [-2, 18.008988716959493],
  [-2, 19.99867576705583],
  [-2, 22.003239931865437],
  [-2, 23.99973160888962],
  [-2, 26.009877160148562],
  [-2, 28.001753349003167],
  [-2, 30.00187773336549],
  [-2, 32.003962447997765],
  [-2, 34.00196457198945],
  [-2, 36.00342426975658],
  [-2, 38.00379526626784],
  [-2, 39.99868840444833],
  [-2, 41.9982100081053],
  [-2, 43.999213765243184],
  [-2, 45.99864468204373],
  [-2, 47.9935375102616],
  [-2, 49.996567960015454],
  [-2, 52.005797738502224],
  [-2, 54.00321289097931],
  [-2, 56.00253429601253],
  [-2, 58.00197005972123],
  [-2, 59.99976988568051],
  [-2, 61.99422446208658],
  [-2, 64.00109039121517],
  [-2, 66.00180451057369],
  [-2, 67.99476071848105],
  [-2, 69.99654381393952],
  [-2, 72.00632364337253],
  [-2, 74.00466363877058],
  [-2, 75.9900786598439],
  [-2, 77.99924877480407],
  [-2, 79.99349993336182],
  [-2, 81.99092530005814],
  [-2, 83.99078615665829],
  [-2, 85.99235536961146],
  [-2, 87.99491726089968],
  [-2, 89.99776748015293],
  [-2, 92.0002128780304],
  [-2, 94.00157138086797],
  [-2, 96.0011718665919],
  [-2, 97.99835404189864],
  [-2, 99.99246832070085],
  [-2, 102.00437231259897],
  [-2, 103.99062841109568],
  [-2, 105.99379237600814],
  [-2, 107.99175158417597],
  [-2, 110.00612517345829],
  [-2, 111.99204294940614],
  [-2, 113.99354117028437],
  [-2, 115.99918551690982],
  [-2, 117.99719780390873],
  [-2, 119.99855403475635],
  [-2, 121.9913177046692],
  [-2, 123.9983557054245],
  [-2, 125.99602982623637],
  [-2, 128.00754395721015],
  [-2, 130.00893921966963],
  [-2, 131.9996855486731],
  [-2, 134.00348171310839],
  [-2, 135.99590349042955],
  [-2, 138.00096889135034],
  [-2, 139.9939500445093],
  [-2, 141.9991780462442],
  [-2, 143.99162728339434],
  [-2, 145.9959358657943],
  [-2, 147.99942318941538],
  [-2, 150.00182707032795],
  [-2, 152.00288829477148],
  [-2, 154.00235059414723],
  [-2, 155.99996062016965],
  [-2, 158.0084608051027],
  [-2, 160.001686777115],
  [-2, 162.00544760836732],
  [-2, 164.00650642492207],
  [-2, 165.99135606972482],
  [-2, 167.99955612196666],
  [-2, 169.9910730164902],
  [-2, 172.00586909542534],
  [-2, 174.00351496598392],
  [-2, 175.99718027037213],
  [-2, 178.00029676139275],
  [-2, 179.9991084734508],
  [-2, 182.00717732124644],
  [-2, 183.9967741464477],
  [-2, 185.99531277318306],
  [-2, 188.0027616946643],
  [-2, 189.99102419353807],
  [-2, 192.00198322188507],
  [-2, 193.99332994573325],
  [-2, 196.00731277474665],
  [-2, 198.0012637982145],
  [-2, 200.00346077267636],
  [-2, 201.9994855762504],
  [-2, 204.00358207342566],
  [-2, 206.00121744288913],
  [-2, 208.0067531227847],
  [-2, 209.9909254631579],
  [-2, 211.99739098586792],
  [-2, 213.9968315185721],
  [-2, 216.00383827316267],
  [-2, 218.0035444149716],
  [-2, 219.99575917596871],
  [-2, 221.99524252636724],
  [-2, 224.00196907801728],
  [-2, 226.00085733850833],
  [-2, 228.00683126153007],
  [-2, 230.0047041399743],
  [-2, 232.00950780985255],
  [-2, 233.99068613585428],
  [-2, 236.0091744883266],
  [-2, 238.00378307366503],
  [-2, 240.0050205771538],
  [-2, 241.99739371653155],
  [-2, 243.9962487055247],
  [-2, 246.00156289321637],
  [-2, 247.99769396513318],
  [-2, 250.00014005195715],
  [-2, 251.99316141964044],
  [-2, 254.00812224706823],
  [-2, 255.99770253371275],
  [-2, 257.9933167668709],
  [-2, 259.994943839456],
  [-2, 262.0025626443815],
  [-2, 264.00014775632513],
  [-2, 266.0035884892568],
  [-2, 267.9967669296673],
  [-2, 269.99566727793535],
  [-2, 272.0002694162042],
  [-2, 273.99431858862135],
  [-2, 275.9939385277757],
  [-2, 277.9991095986181],
  [-2, 280.00981216609944],
  [-2, 282.00961243672754],
  [-2, 283.99835814144126],
  [-2, 285.99240037756203],
  [-2, 288.0082656961188],
  [-2, 289.9962996730987],
  [-2, 292.00611934705154],
  [-2, 294.00448619815256],
  [-2, 295.99125382414496],
  [-2, 297.99979517087917],
  [-2, 299.9966166434608],
  [-2, 301.9984172598913],
  [-2, 304.0051792391151],
  [-2, 305.99995916370693],
  [-2, 307.9995825971773],
  [-2, 310.004032202976],
  [-2, 311.99624287525256],
  [-2, 313.99316432346416],
  [-2, 315.9947796480958],
  [-2, 318.0010719496324],
  [-2, 319.9948170042717],
  [-2, 321.9931263473961],
  [-2, 323.99598350911833],
  [-2, 326.00337201955125],
  [-2, 327.9979121678696],
  [-2, 329.9968736401988],
  [-2, 332.00024038893577],
  [-2, 333.990518747097],
  [-2, 336.00260994734236],
  [-2, 338.001505352159],
  [-2, 340.0046669483272],
  [-2, 341.9944517905367],
  [-2, 344.006061791096],
  [-2, 346.00419020919674],
  [-2, 348.0064491327357],
  [-2, 349.9950498092221],
  [-2, 352.0054878522867],
  [-2, 354.00216535303855],
  [-2, 356.0028410149729],
  [-2, 358.0075000139084],
  [-2, 359.99817583659024],
  [-2, 361.9927351915186],
  [-2, 363.9911636480535],
  [-2, 365.9934467755548],
  [-2, 367.99957014338247],
  [-2, 369.9913946352787],
  [-2, 372.0051209195081],
  [-2, 374.00445148888383],
  [-2, 376.00749698489795],
  [-2, 377.995983587244],
  [-2, 380.00638335070107],
  [-2, 382.0021297830607],
  [-2, 384.0014689959277],
  [-2, 386.00438732472634],
  [-2, 387.99244683278744],
  [-2, 390.0024498766288],
  [-2, 391.9975022717779],
  [-2, 393.99601499840173],
  [-2, 395.9979747645741],
  [-2, 398.0033682783687],
  [-2, 399.99356647015003],
  [-2, 402.00575611656456],
  [-2, 404.0026614194092],
  [-2, 406.002885230279],
  [-2, 408.00641462305975],
  [-2, 409.99446612264893],
  [-2, 412.0045373090673],
  [-2, 413.9990440922093],
  [-2, 415.9967444539867],
  [-2, 417.99762582732325],
  [-2, 420.0016756451426],
  [-2, 422.00888134036836],
  [-2, 424.0002501411709],
  [-2, 425.9946910095658],
  [-2, 427.99219173080377],
  [-2, 429.9927400901356],
  [-2, 431.9963238728121],
  [-2, 434.00293086408414],
  [-2, 435.9933954670083],
  [-2, 438.0059837290342],
  [-2, 440.0023486712898],
  [-2, 442.0016318068995],
  [-2, 444.00382126679386],
  [-2, 446.00890518190323],
  [-2, 447.99755062511707],
  [-2, 450.00836029691317],
  [-2, 452.00265315708634],
  [-2, 453.99973873370357],
  [-2, 455.9996054967907],
  [-2, 458.0022419163737],
  [-2, 460.00763646247833],
  [-2, 461.9962679224083],
  [-2, 464.0071176330172],
  [-2, 466.00112867979516],
  [-2, 467.99779954806525],
  [-2, 469.9971190404278],
  [-2, 471.9990759594833],
  [-2, 474.0036591078325],
  [-2, 475.9911662174909],
  [-2, 478.0009427587906],
  [-2, 479.9935703874118],
  [-2, 482.0084959701016],
  [-2, 484.0062002009442],
  [-2, 486.0064138682094],
  [-2, 488.0091261006146],
  [-2, 489.9944604042264],
  [-2, 492.0021126833541],
  [-2, 493.9923168076565],
  [-2, 496.004866077857],
  [-2, 497.9998973934941],
  [-2, 499.9973144167628],
  [-2, 501.99710659610315],
  [-2, 503.99926337995583],
  [-2, 506.003774216761],
  [-2, 507.99054844589136],
  [-2, 509.9997124566546],
  [-2, 511.99107264431404],
  [-2, 514.0048480438043],
  [-2, 516.0007528226138],
  [-2, 517.998903069468],
  [-2, 519.9992885461994],
  [-2, 522.00189901464],
  [-2, 524.0067242366222],
  [-2, 525.9934699588272],
  [-2, 528.0026718601584],
  [-2, 529.9937299875519],
  [-2, 532.0072680281801],
  [-2, 534.0025984302052],
  [-2, 536.0000391789757],
  [-2, 537.9995803434481],
  [-2, 540.0012119925788],
  [-2, 542.0049241953243],
  [-2, 544.0004681963077],
  [-2, 545.9980519651488],
  [-2, 548.0079253665019],
  [-2, 549.9992996871559],
  [-2, 551.9926638693242],
  [-2, 554.00858912285],
  [-2, 556.0059244030016],
  [-2, 558.005220555017],
  [-2, 560.0064679487729],
  [-2, 562.0096569541454],
  [-2, 563.9940967672135],
  [-2, 566.0011203365216],
  [-2, 568.0100567263553],
  [-2, 570.0001563251003],
  [-2, 571.9921107401369],
  [-2, 574.006689064118],
  [-2, 576.0023441830924],
  [-2, 577.9998260150813],
  [-2, 579.99912522474],
  [-2, 582.0002324767239],
  [-2, 584.0031384356882],
  [-2, 586.0078337662884],
  [-2, 587.9933992055685],
  [-2, 590.0016268767199],
  [-2, 591.9906695770934],
  [-2, 594.0023926353772],
  [-2, 595.9948760283567],
  [-2, 598.0100575203539],
  [-2, 600.0059450374521],
  [-2, 602.0035120877106],
  [-2, 604.0027496244865],
  [-2, 606.0036486011359],
  [-2, 608.0061999710156],
  [-2, 610.0103946874821],
  [-2, 611.9951012344529],
  [-2, 614.002538443346],
  [-2, 616.0010137729838],
  [-2, 618.0010795310227],
  [-2, 619.9921319403431],
  [-2, 622.0059467140948],
  [-2, 624.0001189099612],
  [-2, 625.9958295531451],
  [-2, 627.9930698796902],
  [-2, 629.9918311256398],
  [-2, 631.9921045270374],
  [-2, 633.9938813199261],
  [-2, 635.9971527403496],
  [-2, 638.0019100243513],
  [-2, 640.0081444079743],
  [-2, 641.9944808910714],
  [-2, 644.00362770083],
  [-2, 645.9928284693524],
  [-2, 648.0048530213626],
  [-2, 649.996883760404],
  [-2, 652.0010302360968],
  [-2, 654.0065777654357],
  [-2, 656.0027817364197],
  [-2, 658.0003547965126],
  [-2, 659.9992884584935],
  [-2, 661.9995742351417],
  [-2, 664.0012036392365],
  [-2, 666.0041681835568],
  [-2, 668.0084593808817],
  [-2, 669.992496071136],
  [-2, 671.9994010753823],
  [-2, 674.0076073622312],
  [-2, 675.9954920816039],
  [-2, 678.0062617073698],
  [-2, 679.9966655910276],
  [-2, 682.009964970888],
  [-2, 684.0028547951351],
  [-2, 685.9969685017052],
  [-2, 687.9922978742252],
  [-2, 690.0105427868169],
  [-2, 692.0082918321223],
  [-2, 694.0072318049507],
  [-2, 696.0073544889291],
  [-2, 698.0086516676848],
  [-2, 700.0002290340265],
  [-2, 701.9929519531445],
  [-2, 703.9977108638413],
  [-2, 706.0036114931324],
  [-2, 708.0106456246449],
  [-2, 709.9969712023286],
  [-2, 712.0062355912745],
  [-2, 713.9947511065295],
  [-2, 716.0062132436801],
  [-2, 717.9968865406388],
  [-2, 720.0105139167658],
  [-2, 722.0033128395607],
  [-2, 723.9971567968402],
  [-2, 725.9920378372549],
  [-2, 728.0098867753716],
  [-2, 730.0068293056027],
  [-2, 732.0047849775437],
  [-2, 734.0037458398451],
  [-2, 736.0037039411575],
  [-2, 738.0046513301317],
  [-2, 740.0065800554182],
  [-2, 742.0094821656678],
  [-2, 743.9913239694304],
  [-2, 745.9961385169834],
  [-2, 748.001902682829],
  [-2, 750.0086085156177],
  [-2, 751.9941810456601],
  [-2, 754.0027362282623],
  [-2, 756.0011657568367],
  [-2, 758.0004953055766],
  [-2, 760.00071705348],
  [-2, 762.0018231795449],
  [-2, 763.9927427911952],
  [-2, 766.0066572821515],
  [-2, 767.9992970320018],
  [-2, 769.9927805183247],
  [-2, 772.0092638222628],
  [-2, 774.0044208659759],
  [-2, 776.0003984844332],
  [-2, 777.9971889855476],
  [-2, 779.9947846772325],
  [-2, 781.9931778674004],
  [-2, 783.9923608639646],
  [-2, 785.9923259748381],
  [-2, 787.9930655079338],
  [-2, 789.994571771165],
  [-2, 791.9968370724448],
  [-2, 793.999853719686],
  [-2, 796.0036140208016],
  [-2, 798.0081102837047],
  [-2, 799.9910505707521],
  [-2, 801.9969877168105],
  [-2, 804.003637833863],
  [-2, 805.9998393213028],
  [-2, 807.996730708389],
  [-2, 810.0054659626644],
  [-2, 811.992552923115],
  [-2, 814.002637608279],
  [-2, 815.9910439612694],
  [-2, 818.0024476498951],
  [-2, 819.9921433060808],
  [-2, 822.0048355707413],
  [-2, 823.995790440778],
  [-2, 826.0097408540461],
  [-2, 828.0019248485892],
  [-2, 829.9947087725038],
  [-2, 832.0104860127433],
  [-2, 834.0044540111962],
  [-2, 835.9989995398745],
  [-2, 837.9941151602554],
  [-2, 840.0010066848292],
  [-2, 842.008459637691],
  [-2, 844.0052470145747],
  [-2, 846.0025746454926],
  [-2, 848.0004350919223],
  [-2, 849.9988209153414],
  [-2, 851.9977246772271],
  [-2, 853.9971389390572],
  [-2, 855.9970562623093],
  [-2, 857.9974692084611],
  [-2, 859.9983703389898],
  [-2, 861.9997522153732],
  [-2, 864.0016073990889],
  [-2, 866.0039284516142],
  [-2, 868.0067079344269],
  [-2, 870.0099384090046],
  [-2, 872.0023546160388],
  [-2, 873.9952020927135],
  [-2, 875.9997361361845],
  [-2, 878.0046915009098],
  [-2, 880.0100607483665],
  [-2, 881.9932974041615],
  [-2, 883.9994676596241],
  [-2, 886.00602956583],
  [-2, 888.0016996466911],
  [-2, 889.997742321051],
  [-2, 892.0054304398382],
  [-2, 894.0021983612527],
  [-2, 895.9993168932735],
  [-2, 898.0080647784747],
  [-2, 899.9945765337062],
  [-2, 902.0039927843754],
  [-2, 904.0024424150397],
  [-2, 906.0012060457035],
  [-2, 908.0002763625099],
  [-2, 909.999646051602],
  [-2, 912.0106061389142],
  [-2, 913.9992542912145],
  [-2, 916.0107796883892],
  [-2, 917.9999722536843],
  [-2, 920.0007290963479],
  [-2, 922.0017414281548],
  [-2, 923.9916946952682],
  [-2, 926.0045033037691],
  [-2, 927.9949283406943],
  [-2, 930.008199369671],
  [-2, 931.9990670862643],
  [-2, 934.0014575869607],
  [-2, 936.0040524211217],
  [-2, 937.9955285269261],
  [-2, 940.0098258344098],
  [-2, 942.0016719766995],
  [-2, 943.9936912651867],
  [-2, 946.0085159039036],
  [-2, 948.0008616758364],
  [-2, 949.9933589404784],
  [-2, 952.0086449629166],
  [-2, 954.0014251579648],
  [-2, 955.994335192233],
  [-2, 958.0100166508817],
  [-2, 960.0031660625166],
  [-2, 961.9964236598832],
  [-2, 964.0011084282963],
  [-2, 966.0058880289242],
  [-2, 967.9994279828609],
  [-2, 969.9930472784486],
  [-2, 972.0093946966197],
  [-2, 974.0031518005985],
  [-2, 975.9969665927392],
  [-2, 978.0021607930441],
  [-2, 980.0073987525279],
  [-2, 982.001343834694],
  [-2, 983.995317760941],
  [-2, 986.0006429091463],
  [-2, 988.0059826437446],
  [-2, 990],
  [-2, 991.9940173562554],
  [-2, 994.0106866593235],
  [-2, 996.004682239059],
  [-2, 997.998656165306],
  [-2, 999.9926012474721],
  [-2, 1002.0091681175502],
  [-2, 1004.0030334072608],
  [-2, 1005.9968481994015],
  [-2, 1008.001933290191],
  [-2, 1010.0069527215514],
  [-2, 1012.0005720171391],
  [-2, 1013.9941119710759],
  [-2, 1016.0102177476108],
  [-2, 1018.0035763401168],
  [-2, 1019.9968339374834],
  [-2, 1022.0013077388576],
  [-2, 1024.0056648077668],
  [-2, 1025.9985748420352],
  [-2, 1028.0026772671358],
  [-2, 1030.0066410595216],
  [-2, 1031.9991383241636],
  [-2, 1034.0028037959707],
  [-2, 1036.0063087348133],
  [-2, 1037.9983280233005],
  [-2, 1040.0014909647946],
  [-2, 1042.0044714730739],
  [-2, 1043.9959475788783],
  [-2, 1046.00985593456],
  [-2, 1048.0009329137356],
  [-2, 1049.991800630329],
  [-2, 1052.0050716593057],
  [-2, 1053.995496696231],
  [-2, 1056.008305304732],
  [-2, 1057.9982585718453],
  [-2, 1060.0105753391574],
  [-2, 1062.0000277463157],
  [-2, 1064.0005217859791],
  [-2, 1066.0007457087854],
  [-2, 1068.0006922008774],
  [-2, 1070.000353948398],
  [-2, 1072.0110186678642],
  [-2, 1073.9987939542966],
  [-2, 1076.0088491497208],
  [-2, 1077.9960072156246],
  [-2, 1080.005423466294],
  [-2, 1081.9919352215252],
  [-2, 1084.0006831067265],
  [-2, 1086.009083805161],
  [-2, 1087.9945695601618],
  [-2, 1090.002257678949],
  [-2, 1092.0095763789814],
  [-2, 1093.99397043417],
  [-2, 1096.000532340376],
  [-2, 1098.0067025958385],
  [-2, 1100.0012065132491],
  [-2, 1101.9953084990902],
  [-2, 1104.0002638638155],
  [-2, 1106.0047979072865],
  [-2, 1108.008903190981],
  [-2, 1110.0013169406864],
  [-2, 1111.993292065573],
  [-2, 1113.9960715483858],
  [-2, 1115.998392600911],
  [-2, 1118.000247784627],
  [-2, 1120.0016296610102],
  [-2, 1122.002530791539],
  [-2, 1124.0029437376907],
  [-2, 1126.002861060943],
  [-2, 1128.002275322773],
  [-2, 1130.0011790846586],
  [-2, 1131.9995649080777],
  [-2, 1133.9974253545074],
  [-2, 1135.9947529854253],
  [-2, 1138.0027567287802],
  [-2, 1140.0102065661831],
  [-2, 1142.0058848397446],
  [-2, 1144.0010004601256],
  [-2, 1145.9955459888038],
  [-2, 1148.0119148127324],
  [-2, 1150.0052912274962],
  [-2, 1151.9980751514108],
  [-2, 1154.0014494821576],
  [-2, 1156.004209559222],
  [-2, 1157.9951644292587],
  [-2, 1160.0078566939192],
  [-2, 1161.997552350105],
  [-2, 1164.0089560387307],
  [-2, 1165.997362391721],
  [-2, 1168.007447076885],
  [-2, 1169.9945340373356],
  [-2, 1172.003269291611],
  [-2, 1174.0113145656856],
  [-2, 1175.996362166137],
  [-2, 1178.0030122831895],
  [-2, 1180.0089494292479],
  [-2, 1182.0030278254994],
  [-2, 1183.9963859791985],
  [-2, 1186.0001462803139],
  [-2, 1188.0031629275552],
  [-2, 1190.005428228835],
  [-2, 1192.0069344920662],
  [-2, 1194.007674025162],
  [-2, 1196.0076391360356],
  [-2, 1198.0068221325996],
  [-2, 1200.0052153227675],
  [-2, 1202.0028110144524],
  [-2, 1203.9996015155668],
  [-2, 1205.995579134024],
  [-2, 1208.0018180771344],
  [-2, 1210.0072194816753],
  [-2, 1212.0007029679982],
  [-2, 1213.9933427178485],
  [-2, 1216.0072572088047],
  [-2, 1217.9981768204552],
  [-2, 1220.0103363321412],
  [-2, 1221.9995046944234],
  [-2, 1224.0098777699914],
  [-2, 1225.9972637717378],
  [-2, 1228.00581895434],
  [-2, 1230.002419898946],
  [-2, 1231.998097317171],
  [-2, 1234.0038614830166],
  [-2, 1236.0086760305696],
  [-2, 1238.0015254359803],
  [-2, 1239.9934199445818],
  [-2, 1241.9953486698682],
  [-2, 1243.9962960588425],
  [-2, 1245.9962541601549],
  [-2, 1247.9952150224563],
  [-2, 1249.9931706943973],
  [-2, 1252.0120519905447],
  [-2, 1254.0079621627451],
  [-2, 1256.0028432031597],
  [-2, 1257.9966871604393],
  [-2, 1260.011379137842],
  [-2, 1262.0031134593612],
  [-2, 1263.9937867563199],
  [-2, 1266.0052488934705],
  [-2, 1267.9937644087254],
  [-2, 1270.0030287976713],
  [-2, 1272.0111760278332],
  [-2, 1273.9963885068676],
  [-2, 1276.0022891361587],
  [-2, 1278.0070480468555],
  [-2, 1280.0106570225853],
  [-2, 1282.0022281068698],
  [-2, 1283.9926455110708],
  [-2, 1285.9927681950494],
  [-2, 1288.0025687257157],
  [-2, 1290.0111653036777],
  [-2, 1292.0077021257748],
  [-2, 1294.0030314982948],
  [-2, 1295.9971452048649],
  [-2, 1298.0116901209376],
  [-2, 1300.0033344089723],
  [-2, 1301.99373829263],
  [-2, 1304.0045079183963],
  [-2, 1306.0031929099396],
  [-2, 1308.0005989246176],
  [-2, 1310.007503928864],
  [-2, 1312.002319910254],
  [-2, 1313.9958318164433],
  [-2, 1315.9987963607634],
  [-2, 1318.0004257648584],
  [-2, 1320.0007115415065],
  [-2, 1321.9996452034875],
  [-2, 1323.9972182635804],
  [-2, 1325.9934222345642],
  [-2, 1328.0096908585226],
  [-2, 1330.003116239596],
  [-2, 1331.9951469786374],
  [-2, 1334.0071715306476],
  [-2, 1335.9963722991702],
  [-2, 1338.0055191089286],
  [-2, 1340.0025309436583],
  [-2, 1341.9980899756488],
  [-2, 1344.0028472596503],
  [-2, 1346.006118680074],
  [-2, 1348.0078954729624],
  [-2, 1350.00816887436],
  [-2, 1352.0069301203098],
  [-2, 1354.004170446855],
  [-2, 1355.9998810900388],
  [-2, 1357.9940532859052],
  [-2, 1360.0078680596569],
  [-2, 1361.9989204689773],
  [-2, 1364.009564363632],
  [-2, 1365.9974615566541],
  [-2, 1368.004898765547],
  [-2, 1370.0107106259125],
  [-2, 1371.9938000289844],
  [-2, 1373.9963513988641],
  [-2, 1375.9972503755137],
  [-2, 1377.9964879122892],
  [-2, 1379.994054962548],
  [-2, 1382.0109428569885],
  [-2, 1384.0051239716433],
  [-2, 1385.997607364623],
  [-2, 1388.0093304229067],
  [-2, 1389.99837312328],
  [-2, 1392.0066007944315],
  [-2, 1394.0026119746963],
  [-2, 1395.9968615643115],
  [-2, 1397.9997675232762],
  [-2, 1400.00087477526],
  [-2, 1402.0001739849185],
  [-2, 1403.9976558169074],
  [-2, 1405.9933109358822],
  [-2, 1408.007889259863],
  [-2, 1409.9998436748997],
  [-2, 1412.010663683744],
  [-2, 1413.9988796634784],
  [-2, 1416.0059032327865],
  [-2, 1418.0110043514455],
  [-2, 1419.9935320512272],
  [-2, 1421.9947794449831],
  [-2, 1423.9940755969983],
  [-2, 1426.0119917171169],
  [-2, 1428.0073361306759],
  [-2, 1430.000700312844],
  [-2, 1432.0125938693025],
  [-2, 1434.0019480348512],
  [-2, 1436.0097705743829],
  [-2, 1437.9950758046757],
  [-2, 1439.9987880074214],
  [-2, 1442.000419656552],
  [-2, 1443.9999608210242],
  [-2, 1445.9974015697949],
  [-2, 1448.013081574885],
  [-2, 1450.006270012448],
  [-2, 1451.9973281398416],
  [-2, 1454.006530041173],
  [-2, 1456.0034066907467],
  [-2, 1457.99810098536],
  [-2, 1460.0007114538007],
  [-2, 1462.001096930532],
  [-2, 1463.9992471773862],
  [-2, 1465.9951519561957],
  [-2, 1468.008927355686],
  [-2, 1470.0002875433452],
  [-2, 1472.0094515541086],
  [-2, 1473.996225783239],
  [-2, 1476.0007366200443],
  [-2, 1478.002893403897],
  [-2, 1480.0026855832373],
  [-2, 1482.0001026065058],
  [-2, 1483.995133922143],
  [-2, 1486.0076831923434],
  [-2, 1487.997887316646],
  [-2, 1490.0055395957736],
  [-2, 1492.0107149446894],
  [-2, 1494.0134024921115],
  [-2, 1495.9937997990558],
  [-2, 1498.0112706973432],
  [-2, 1500.0064296125884],
  [-2, 1501.9990572412094],
  [-2, 1504.008833782509],
  [-2, 1505.9963408921676],
  [-2, 1508.0009240405166],
  [-2, 1510.0028809595724],
  [-2, 1512.0022004519346],
  [-2, 1513.9988713202047],
  [-2, 1516.0124182915922],
  [-2, 1518.0037320775916],
  [-2, 1520.0118466096856],
  [-2, 1521.9977580836262],
  [-2, 1524.0003945032095],
  [-2, 1526.0002612662965],
  [-2, 1527.9973468429137],
  [-2, 1530.0109880433354],
  [-2, 1532.0024493748829],
  [-2, 1534.0103882154774],
  [-2, 1535.996178733206],
  [-2, 1537.9983681931003],
  [-2, 1539.9976513287102],
  [-2, 1541.9940162709659],
  [-2, 1544.0066045329918],
  [-2, 1545.9970691359158],
  [-2, 1548.0036761271879],
  [-2, 1550.0072599098644],
  [-2, 1552.0078082691962],
  [-2, 1554.005308990434],
  [-2, 1555.999749858829],
  [-2, 1558.0100692692333],
  [-2, 1559.9983243548572],
  [-2, 1562.0023741726768],
  [-2, 1564.0032555460134],
  [-2, 1566.0009559077907],
  [-2, 1567.9954626909325],
  [-2, 1570.0055338773511],
  [-2, 1572.0123252132823],
  [-2, 1573.997114769721],
  [-2, 1575.9973385805908],
  [-2, 1578.01289085701],
  [-2, 1580.0064335298498],
  [-2, 1581.9966317216313],
  [-2, 1584.002025235426],
  [-2, 1586.0039850015983],
  [-2, 1588.0024977282221],
  [-2, 1589.9975501233712],
  [-2, 1592.0075531672126],
  [-2, 1594.0140042989105],
  [-2, 1595.9985310040724],
  [-2, 1597.9978702169392],
  [-2, 1600.011909575388],
  [-2, 1602.004016412756],
  [-2, 1604.0107292101527],
  [-2, 1606.0137409973631],
  [-2, 1608.0130377302612],
  [-2, 1610.0086053647215],
  [-2, 1612.0004298566175],
  [-2, 1614.0065532244453],
  [-2, 1616.0088363519465],
  [-2, 1618.0072648084815],
  [-2, 1620.0018241634098],
  [-2, 1622.010416307603],
  [-2, 1623.997158985027],
  [-2, 1625.9978346469616],
  [-2, 1628.012321572387],
  [-2, 1630.004950190778],
  [-2, 1632.011288043299],
  [-2, 1634.0135104804626],
  [-2, 1636.0116022774555],
  [-2, 1638.0055482094633],
  [-2, 1640.0129231467427],
  [-2, 1641.998494647841],
  [-2, 1643.9973900526577],
  [-2, 1646.009481252903],
  [-2, 1647.9997596110643],
  [-2, 1650.0031263598012],
  [-2, 1652.0020878321304],
  [-2, 1654.0139523703965],
  [-2, 1656.0040164908817],
  [-2, 1658.006873652604],
  [-2, 1660.0051829957283],
  [-2, 1661.9989280503676],
  [-2, 1664.0052203519042],
  [-2, 1666.006835676536],
  [-2, 1668.0037571247476],
  [-2, 1670.0129748863162],
  [-2, 1672.0004174028227],
  [-2, 1674.000040836293],
  [-2, 1676.0117049286332],
  [-2, 1678.0015827401087],
  [-2, 1680.0033833565392],
  [-2, 1682.0002048291208],
  [-2, 1684.0087461758549],
  [-2, 1686.0121880297932],
  [-2, 1688.0105121578404],
  [-2, 1690.0037003269013],
  [-2, 1692.0082795476415],
  [-2, 1694.007599622438],
  [-2, 1696.0016418585587],
  [-2, 1698.0068013586788],
  [-2, 1700.006557259629],
  [-2, 1702.000890401382],
  [-2, 1704.0060614722242],
  [-2, 1706.0056814113786],
  [-2, 1707.9997305837958],
  [-2, 1710.0043327220646],
  [-2, 1712.0032330703327],
  [-2, 1713.9964115107432],
  [-2, 1715.999852243675],
  [-2, 1717.9974373556183],
  [-2, 1720.005056160544],
  [-2, 1722.006683233129],
  [-2, 1724.0022974662872],
  [-2, 1726.007643881896],
  [-2, 1728.0068385803595],
  [-2, 1729.9998599480427],
  [-2, 1732.0023060348667],
  [-2, 1734.0140069617344],
  [-2, 1736.0037512944753],
  [-2, 1738.0026062834686],
  [-2, 1740.0103987984494],
  [-2, 1741.996216926335],
  [-2, 1744.0061429025486],
  [-2, 1746.0093138641457],
  [-2, 1748.00570609026],
  [-2, 1750.0104573453714],
  [-2, 1752.0082776251984],
  [-2, 1754.0141983651836],
  [-2, 1755.9980309219827],
  [-2, 1758.0047574736327],
  [-2, 1760.0042408240313],
  [-2, 1761.9964555850283],
  [-2, 1764.010946677562],
  [-2, 1766.003168481428],
  [-2, 1768.002609014132],
  [-2, 1770.009074536842],
  [-2, 1772.0078080950966],
  [-2, 1773.9987825571109],
  [-2, 1775.9964179265744],
  [-2, 1778.014903174384],
  [-2, 1779.9965392273239],
  [-2, 1781.9987362017855],
  [-2, 1784.0069000027058],
  [-2, 1786.0066700542668],
  [-2, 1788.0121096520543],
  [-2, 1790.0089758064619],
  [-2, 1791.997238305336],
  [-2, 1794.0046872268172],
  [-2, 1796.0032258535525],
  [-2, 1798.0066079861465],
  [-2, 1800.0008915265491],
  [-2, 1801.9997032386073],
  [-2, 1804.0028197296278],
  [-2, 1805.9964850340161],
  [-2, 1808.0075957341637],
  [-2, 1810.0089269835098],
  [-2, 1812.013776529248],
  [-2, 1814.0086439302754],
  [-2, 1816.0066922109759],
  [-2, 1818.0076829887528],
  [-2, 1820.0113750874093],
  [-2, 1822.0045320798247],
  [-2, 1824.012962131601],
  [-2, 1825.9976494058528],
  [-2, 1827.9971117052285],
  [-2, 1830.0108823863288],
  [-2, 1832.0132137355354],
  [-2, 1834.0040641342057],
  [-2, 1836.0083727166057],
  [-2, 1838.0008219537558],
  [-2, 1840.0060499554907],
  [-2, 1841.99903110865],
  [-2, 1844.0040965095704],
  [-2, 1846.008628322023],
  [-2, 1848.0003144513266],
  [-2, 1850.0149659178876],
  [-2, 1852.0162011692373],
  [-2, 1854.0039701737637],
  [-2, 1856.0016442945757],
  [-2, 1858.0086822953308],
  [-2, 1860.0014459652434],
  [-2, 1862.0028021960914],
  [-2, 1864.0121888060949],
  [-2, 1866.0064588297157],
  [-2, 1868.0079570505936],
  [-2, 1870.0160997686908],
  [-2, 1872.0082484158243],
  [-2, 1874.006207623992],
  [-2, 1876.0093715889043],
  [-2, 1878.01712429616],
  [-2, 1880.007531679299],
  [-2, 1882.001645958101],
  [-2, 1883.998828133408],
  [-2, 1885.998428619132],
  [-2, 1887.9997871219693],
  [-2, 1890.002232519847],
  [-2, 1892.0050827391],
  [-2, 1894.0076446303888],
  [-2, 1896.009213843342],
  [-2, 1898.009074699942],
  [-2, 1900.0065000666382],
  [-2, 1902.000751225196],
  [-2, 1904.0099213401559],
  [-2, 1906.013953261549],
  [-2, 1908.0120634606922],
  [-2, 1910.0034561860607],
  [-2, 1912.0052392815187],
  [-2, 1914.0158678569255],
  [-2, 1916.0163351409483],
  [-2, 1918.0057755379135],
  [-2, 1920.0002301143195],
  [-2, 1922.0146863910395],
  [-2, 1924.0138553674265],
  [-2, 1926.0129043087363],
  [-2, 1928.0100412587924],
  [-2, 1930.0034320399845],
  [-2, 1932.0064624897382],
  [-2, 1934.0013553179565],
  [-2, 1936.015442379004],
  [-2, 1938.0161313531516],
  [-2, 1940.0013115955517],
  [-2, 1942.0098909115104],
  [-2, 1944.0099189676462],
  [-2, 1946.0110245317744],
  [-2, 1948.0086611748955],
  [-2, 1950.0103665404472],
  [-2, 1952.010097503662],
  [-2, 1954.0130067468908],
  [-2, 1956.01128211891],
  [-2, 1958.0178917862213],
  [-2, 1960.0013242329442],
  [-2, 1962.0102113688063],
  [-2, 1964.0077258987562],
  [-2, 1966.0112496663928],
  [-2, 1968.011786563135],
  [-2, 1970.0077098671331],
  [-2, 1972.0094022396765],
  [-2, 1974.008687988753],
  [-2, 1976.0170571651543],
  [-2, 1978.0066176821856],
  [-1.9999885633587837, 1980.0146341323853],
  [-1.737548828125, 1981.9775390625],
  [-0.6234269142150879, 1983.5831451416016],
  [1.151702880859375, 1983.131103515625],
  [1.8971033692359924, 1981.2981605529785],
  [2, 1979.3039673702879],
  [2, 1977.2974947094917],
  [2, 1975.3058550406695],
  [2, 1973.2903374213402],
  [2, 1971.3078921711076],
  [2, 1969.3022875464353],
  [2, 1967.2912379705667],
  [2, 1965.3055552132628],
  [2, 1963.2927372697213],
  [2, 1961.2932718334937],
  [2, 1959.3091084797288],
  [2, 1957.2939821489217],
  [2, 1955.3015555218221],
  [2, 1953.2991606499613],
  [2, 1951.2948419937766],
  [2, 1949.2962952936068],
  [2, 1947.298122605975],
  [2, 1945.2931567827532],
  [2, 1943.2995277602458],
  [2, 1941.3089512358451],
  [2, 1939.2974787443504],
  [2, 1937.2962635922308],
  [2, 1935.309382523957],
  [2, 1933.2955643960665],
  [2, 1931.3020403851988],
  [2, 1929.301239401223],
  [2, 1927.2950790339382],
  [2, 1925.301648234099],
  [2, 1923.307105324749],
  [2, 1921.2964737166835],
  [2, 1919.3047232448644],
  [2, 1917.2997968603054],
  [2, 1915.3001098290154],
  [2, 1913.3072889035125],
  [2, 1911.3049240437763],
  [2, 1909.2938570468184],
  [2, 1907.2933871695736],
  [2, 1905.3050191922453],
  [2, 1903.2923791132635],
  [2, 1901.2938625608554],
  [2, 1899.2915333109163],
  [2, 1897.3057171187297],
  [2, 1895.2982113441792],
  [2, 1893.3090989983684],
  [2, 1891.2993098163142],
  [2, 1889.3097371023832],
  [2, 1887.300466873016],
  [2, 1885.2923803230792],
  [2, 1883.3071423794759],
  [2, 1881.3036153605935],
  [2, 1879.3032477706324],
  [2, 1877.306677823208],
  [2, 1875.2927866444224],
  [2, 1873.3055037181011],
  [2, 1871.3017651685018],
  [2, 1869.3039226904511],
  [2, 1867.3013398881296],
  [2, 1865.305660120242],
  [2, 1863.3060583726078],
  [2, 1861.2913603029879],
  [2, 1859.3079204098467],
  [2, 1857.310171224241],
  [2, 1855.2981664372328],
  [2, 1853.2956016875273],
  [2, 1851.3030175657102],
  [2, 1849.2969850655031],
  [2, 1847.3016736695627],
  [2, 1845.2933293021993],
  [2, 1843.2964306800382],
  [2, 1841.2991962693563],
  [2, 1839.301900924609],
  [2, 1837.3048164251759],
  [2, 1835.3082115010056],
  [2, 1833.2997623583015],
  [2, 1831.3048380006026],
  [2, 1829.2984474432783],
  [2, 1827.306245488653],
  [2, 1825.3029464104852],
  [2, 1823.3015379336302],
  [2, 1821.302270245803],
  [2, 1819.3053906653356],
  [2, 1817.297989316706],
  [2, 1815.306549042642],
  [2, 1813.2916438516468],
  [2, 1811.2931787760772],
  [2, 1809.2981668698035],
  [2, 1807.2933530242317],
  [2, 1805.2923252984524],
  [2, 1803.3089299305993],
  [2, 1801.3025422878607],
  [2, 1799.30049318687],
  [2, 1797.3030057846195],
  [2, 1795.296431104351],
  [2, 1793.3086645468456],
  [2, 1791.2981291422311],
  [2, 1789.3068337410446],
  [2, 1787.3069453224057],
  [2, 1785.2984940984697],
  [2, 1783.309977607672],
  [2, 1781.298903755593],
  [2, 1779.3081786468579],
  [2, 1777.2949540838],
  [2, 1775.3024860304474],
  [2, 1773.3020995817117],
  [2, 1771.293822467851],
  [2, 1769.3069578610796],
  [2, 1767.2977897472279],
  [2, 1765.2956757525244],
  [2, 1763.3008094091588],
  [2, 1761.2985228941006],
  [2, 1759.3037558117555],
  [2, 1757.3017290448079],
  [2, 1755.3074891967044],
  [2, 1753.2910729287994],
  [2, 1751.2977286221485],
  [2, 1749.2974377129315],
  [2, 1747.2902244693978],
  [2, 1745.3066813443131],
  [2, 1743.3011356905436],
  [2, 1741.30422985631],
  [2, 1739.300703128387],
  [2, 1737.3060663061028],
  [2, 1735.3049555356856],
  [2, 1733.2973934680524],
  [2, 1731.2990397514625],
  [2, 1729.2943787994193],
  [2, 1727.2991675586773],
  [2, 1725.2977906455294],
  [2, 1723.3061008680397],
  [2, 1721.3083845147653],
  [2, 1719.3046626927924],
  [2, 1717.2949565092079],
  [2, 1715.2953078683165],
  [2, 1713.305877988717],
  [2, 1711.2945997630504],
  [2, 1709.2936738962032],
  [2, 1707.3032585251804],
  [2, 1705.3072596166567],
  [2, 1703.305696805681],
  [2, 1701.298589727303],
  [2, 1699.3023430987146],
  [2, 1697.3006804640534],
  [2, 1695.2936209833474],
  [2, 1693.2977010105733],
  [2, 1691.2965098419954],
  [2, 1689.3066698237726],
  [2, 1687.2950355198077],
  [2, 1685.2948754438103],
  [2, 1683.306335838497],
  [2, 1681.296013976853],
  [2, 1679.2974331290206],
  [2, 1677.2938794130064],
  [2, 1675.3022694306594],
  [2, 1673.3058046266005],
  [2, 1671.3045023373797],
  [2, 1669.2983798995472],
  [2, 1667.3045165191397],
  [2, 1665.3059482848494],
  [2, 1663.3026920961913],
  [2, 1661.2947648526806],
  [2, 1659.2994046762751],
  [2, 1657.2994860326376],
  [2, 1655.2950253916556],
  [2, 1653.3033773612024],
  [2, 1651.3072975409102],
  [2, 1649.306801978382],
  [2, 1647.3019067212213],
  [2, 1645.2926278170312],
  [2, 1643.296510061538],
  [2, 1641.2961159672159],
  [2, 1639.3090643361727],
  [2, 1637.3002016315183],
  [2, 1635.3047863958877],
  [2, 1633.3052306845866],
  [2, 1631.3015497224294],
  [2, 1629.2937587342294],
  [2, 1627.2996951577534],
  [2, 1625.301623757905],
  [2, 1623.2995593588657],
  [2, 1621.2935167848173],
  [2, 1619.3014748334353],
  [2, 1617.3055544200315],
  [2, 1615.3057699752464],
  [2, 1613.3021359297197],
  [2, 1611.2946667140914],
  [2, 1609.3015135010637],
  [2, 1607.3046221247532],
  [2, 1605.3040066292856],
  [2, 1603.2996810587865],
  [2, 1601.3099308046458],
  [2, 1599.2982606258124],
  [2, 1597.3012601129567],
  [2, 1595.3006715833908],
  [2, 1593.29650870169],
  [2, 1591.3072207570842],
  [2, 1589.2959826442302],
  [2, 1587.2997112094179],
  [2, 1585.2999841199194],
  [2, 1583.2968146676612],
  [2, 1581.29021614457],
  [2, 1579.2988287130192],
  [2, 1577.3041013909926],
  [2, 1575.3060471046044],
  [2, 1573.304678779969],
  [2, 1571.3000093432004],
  [2, 1569.292051720413],
  [2, 1567.2996305289284],
  [2, 1565.304007578353],
  [2, 1563.3051954357634],
  [2, 1561.3032066682354],
  [2, 1559.2980538428455],
  [2, 1557.3087104801243],
  [2, 1555.2972967940086],
  [2, 1553.301776297999],
  [2, 1551.3032002533914],
  [2, 1549.301580874935],
  [2, 1547.2969303773789],
  [2, 1545.3083958370412],
  [2, 1543.297748323061],
  [2, 1541.3032978476804],
  [2, 1539.3059214184],
  [2, 1537.3056309042895],
  [2, 1535.3024381744187],
  [2, 1533.296355097857],
  [2, 1531.3067241499689],
  [2, 1529.2949234942635],
  [2, 1527.2996532283287],
  [2, 1525.3015942758439],
  [2, 1523.300758166783],
  [2, 1521.2971564311201],
  [2, 1519.2908005988295],
  [2, 1517.301221156985],
  [2, 1515.3089633410946],
  [2, 1513.2944670949523],
  [2, 1511.2968601395833],
  [2, 1509.2966085121452],
  [2, 1507.2937234100373],
  [2, 1505.3078905599332],
  [2, 1503.2997976451554],
  [2, 1501.3088299977394],
  [2, 1499.2955736218523],
  [2, 1497.2995150938652],
  [2, 1495.3009400254493],
  [2, 1493.299859287887],
  [2, 1491.2962837524606],
  [2, 1489.290224290453],
  [2, 1487.3015903199973],
  [2, 1485.2906199434478],
  [2, 1483.297144996045],
  [2, 1481.301277482497],
  [2, 1479.3030279543632],
  [2, 1477.3024069632038],
  [2, 1475.299425060578],
  [2, 1473.2940927980453],
  [2, 1471.3065089952795],
  [2, 1469.29653090795],
  [2, 1467.3043684223248],
  [2, 1465.2998649306674],
  [2, 1463.2930864943244],
  [2, 1461.2941448447282],
  [2, 1459.2929715848095],
  [2, 1457.2997006715323],
  [2, 1455.3042408513666],
  [2, 1453.2964563695714],
  [2, 1451.3067948256758],
  [2, 1449.294661034211],
  [2, 1447.3007141783455],
  [2, 1445.3046504901554],
  [2, 1443.3064799006836],
  [2, 1441.306212340974],
  [2, 1439.3038577420705],
  [2, 1437.299426035016],
  [2, 1435.2929271508542],
  [2, 1433.3048768471315],
  [2, 1431.294294242704],
  [2, 1429.3022213486481],
  [2, 1427.3081619685854],
  [2, 1425.2915377780619],
  [2, 1423.2935140858294],
  [2, 1421.29353289724],
  [2, 1419.2916038424178],
  [2, 1417.3084048324913],
  [2, 1415.3026287689813],
  [2, 1413.2949336303295],
  [2, 1411.3060563281347],
  [2, 1409.2945714661619],
  [2, 1407.3019623756409],
  [2, 1405.3075111104408],
  [2, 1403.2904227672639],
  [2, 1401.2922962130347],
  [2, 1399.2923555874036],
  [2, 1397.2906102257152],
  [2, 1395.307948901318],
  [2, 1393.3026406302815],
  [2, 1391.2955555319786],
  [2, 1389.3076375674536],
  [2, 1387.2970450866678],
  [2, 1385.3056745591375],
  [2, 1383.2916116492875],
  [2, 1381.2968251272398],
  [2, 1379.3003531679062],
  [2, 1377.3022048179305],
  [2, 1375.3023891239557],
  [2, 1373.3009151326257],
  [2, 1371.2977918905835],
  [2, 1369.2930284444728],
  [2, 1367.3077622475594],
  [2, 1365.2997625609794],
  [2, 1363.2901497150335],
  [2, 1361.3001117837778],
  [2, 1359.3085120968092],
  [2, 1357.2941473492854],
  [2, 1355.2994340619182],
  [2, 1353.3031854039414],
  [2, 1351.3054101393118],
  [2, 1349.3061170319859],
  [2, 1347.30531484592],
  [2, 1345.3030123450712],
  [2, 1343.2992182933958],
  [2, 1341.2939414548505],
  [2, 1339.3085467333653],
  [2, 1337.3003461546903],
  [2, 1335.2906889877816],
  [2, 1333.3009863192262],
  [2, 1331.2991662628772],
  [2, 1329.2959309472487],
  [2, 1327.3020127333086],
  [2, 1325.306711376152],
  [2, 1323.2992968458784],
  [2, 1321.290501463476],
  [2, 1319.291087047389],
  [2, 1317.2903235282292],
  [2, 1315.2989869632866],
  [2, 1313.3063325100304],
  [2, 1311.3015867925412],
  [2, 1309.2955258296022],
  [2, 1307.2989538868276],
  [2, 1305.3010975502807],
  [2, 1303.2911555978205],
  [2, 1301.3015651001292],
  [2, 1299.2999056886545],
  [2, 1297.2969952876667],
  [2, 1295.3036788333065],
  [2, 1293.3091413544923],
  [2, 1291.2916914969592],
  [2, 1289.294723570107],
  [2, 1287.2965593572276],
  [2, 1285.2972070746941],
  [2, 1283.2966749388795],
  [2, 1281.2949711661563],
  [2, 1279.2921039728974],
  [2, 1277.2989761088663],
  [2, 1275.3047136462555],
  [2, 1273.2984178234085],
  [2, 1271.290991356209],
  [2, 1269.3042805187984],
  [2, 1267.294629478839],
  [2, 1265.3057343267412],
  [2, 1263.2938912232512],
  [2, 1261.3028439123334],
  [2, 1259.2997898295907],
  [2, 1257.295673940671],
  [2, 1255.3014643883953],
  [2, 1253.3062205490633],
  [2, 1251.2989790361662],
  [2, 1249.2907079613003],
  [2, 1247.2923979094048],
  [2, 1245.2930854632277],
  [2, 1243.2927785741188],
  [2, 1241.2914851934268],
  [2, 1239.3002174256555],
  [2, 1237.3079896623008],
  [2, 1235.303795050182],
  [2, 1233.2986456645006],
  [2, 1231.2925494566055],
  [2, 1229.3075747816774],
  [2, 1227.299618970079],
  [2, 1225.290740102938],
  [2, 1223.3030367237839],
  [2, 1221.2923455251087],
  [2, 1219.3028652857358],
  [2, 1217.290393212374],
  [2, 1215.2991672239502],
  [2, 1213.3070847643764],
  [2, 1211.292005106443],
  [2, 1209.2982234786277],
  [2, 1207.3036085413912],
  [2, 1205.30816798682],
  [2, 1203.289724291673],
  [2, 1201.2926466193119],
  [2, 1199.2947664913447],
  [2, 1197.2960915998585],
  [2, 1195.2966296369405],
  [2, 1193.296388294677],
  [2, 1191.2953752651556],
  [2, 1189.293598240463],
  [2, 1187.2910649126864],
  [2, 1185.298914371813],
  [2, 1183.3060310793337],
  [2, 1181.3012830995322],
  [2, 1179.2958094995265],
  [2, 1177.289617971404],
  [2, 1175.3050189896398],
  [2, 1173.2974224453292],
  [2, 1171.3002900441238],
  [2, 1169.3024779702682],
  [2, 1167.2928271985788],
  [2, 1165.3048450629924],
  [2, 1163.2938654286754],
  [2, 1161.3045842402735],
  [2, 1159.2923061707552],
  [2, 1157.3017560188823],
  [2, 1155.299398045176],
  [2, 1153.296420915591],
  [2, 1151.3040271126438],
  [2, 1149.2998377185293],
  [2, 1147.295051820065],
  [2, 1145.300881815158],
  [2, 1143.3061368709716],
  [2, 1141.29961321654],
  [2, 1139.2925232316236],
  [2, 1137.2960919337381],
  [2, 1135.299115534242],
  [2, 1133.3016014716577],
  [2, 1131.3035571845078],
  [2, 1129.3049901113145],
  [2, 1127.3059076906006],
  [2, 1125.3063173608882],
  [2, 1123.3062265607],
  [2, 1121.305642728558],
  [2, 1119.304573302985],
  [2, 1117.3030257225037],
  [2, 1115.301007425636],
  [2, 1113.2985258509045],
  [2, 1111.2955884368316],
  [2, 1109.29220262194],
  [2, 1107.2996345164431],
  [2, 1105.306637703222],
  [2, 1103.301956063163],
  [2, 1101.2968556927963],
  [2, 1099.2913440306438],
  [2, 1097.30796910776],
  [2, 1095.301661590382],
  [2, 1093.2949650132068],
  [2, 1091.2991635871078],
  [2, 1089.3029921294262],
  [2, 1087.2951770833959],
  [2, 1085.2982855169657],
  [2, 1083.301045901845],
  [2, 1081.2921788331273],
  [2, 1079.3055517809617],
  [2, 1077.2960214995558],
  [2, 1075.3087532316017],
  [2, 1073.2985891582211],
  [2, 1071.2994131442854],
  [2, 1069.2999403199801],
  [2, 1067.3001779991623],
  [2, 1065.3001334956894],
  [2, 1063.299814123418],
  [2, 1061.2992271962055],
  [2, 1059.2983800279087],
  [2, 1057.297279932385],
  [2, 1055.3072419376406],
  [2, 1053.2943502150847],
  [2, 1051.3038455454125],
  [2, 1049.2904965551613],
  [2, 1047.2995543007046],
  [2, 1045.3084053167154],
  [2, 1043.2944267143737],
  [2, 1041.3028836986393],
  [2, 1039.2998384549394],
  [2, 1037.2966133399404],
  [2, 1035.304534769457],
  [2, 1033.289652751475],
  [2, 1031.2972527924298],
  [2, 1029.304703873725],
  [2, 1027.289368186636],
  [2, 1025.296541432409],
  [2, 1023.3035873720114],
  [2, 1021.2991885815031],
  [2, 1019.2946756204465],
  [2, 1017.3013816248838],
  [2, 1015.3079891672317],
  [2, 1013.2918517171095],
  [2, 1011.2982829929103],
  [2, 1009.3046374601101],
  [2, 1007.2995941971407],
  [2, 1005.2944878366585],
  [2, 1003.3006544352386],
  [2, 1001.3067730692983],
  [2, 999.2901925166964],
  [2, 997.2962364531845],
  [2, 995.3022540786415],
  [2, 993.3082525836592],
  [2, 991.2915798745162],
  [2, 989.297561697898],
  [2, 987.3035460543296],
  [2, 985.2982105968285],
  [2, 983.2928922876363],
  [2, 981.2989277020097],
  [2, 979.3049944935137],
  [2, 977.299771029843],
  [2, 975.2945938872673],
  [2, 973.30079859673],
  [2, 971.3070635274044],
  [2, 969.2907409706702],
  [2, 967.2971488046196],
  [2, 965.3036385132696],
  [2, 963.2988912984448],
  [2, 961.2942414777508],
  [2, 959.301021170904],
  [2, 957.307911582839],
  [2, 955.2922729766908],
  [2, 953.299407860876],
  [2, 951.3066751173312],
  [2, 949.2914396620077],
  [2, 947.298994943753],
  [2, 945.3067042512569],
  [2, 943.2919378942695],
  [2, 941.299978780103],
  [2, 939.3081953451833],
  [2, 937.2939640340437],
  [2, 935.3025557304934],
  [2, 933.288718523936],
  [2, 931.2977144418983],
  [2, 929.3069221554924],
  [2, 927.2937299902546],
  [2, 925.303385478401],
  [2, 923.2906608864191],
  [2, 921.3007932392609],
  [2, 919.2998695685654],
  [2, 917.2992039489291],
  [2, 915.298803694209],
  [2, 913.2986761182622],
  [2, 911.2988285349456],
  [2, 909.2992682581166],
  [2, 907.2887081662909],
  [2, 905.3010388793491],
  [2, 903.2910934643454],
  [2, 901.3040464928165],
  [2, 899.294745175348],
  [2, 897.308349609375],
  [2, 895.2997218101552],
  [2, 893.2914437886477],
  [2, 891.3060818796234],
  [2, 889.2985215905438],
  [2, 887.2913333111649],
  [2, 885.307070758671],
  [2, 883.300644425899],
  [2, 881.2946123348163],
  [2, 879.288981923945],
  [2, 877.3062889081374],
  [2, 875.3014794515184],
  [2, 873.2970939070995],
  [2, 871.293139713403],
  [2, 869.2896243089514],
  [2, 867.2978070696695],
  [2, 865.3064383723006],
  [2, 863.3042787444457],
  [2, 861.3025875763465],
  [2, 859.3013723065255],
  [2, 857.3006403735053],
  [2, 855.3003992158082],
  [2, 853.3006562719565],
  [2, 851.3014189804729],
  [2, 849.3026947798796],
  [2, 847.3044911086993],
  [2, 845.3068154054542],
  [2, 843.2984567458063],
  [2, 841.2906470899138],
  [2, 839.294606146829],
  [2, 837.299123009348],
  [2, 835.3042051159935],
  [2, 833.2986573504546],
  [2, 831.2936962848725],
  [2, 829.300525399085],
  [2, 827.3079495950926],
  [2, 825.2935980404387],
  [2, 823.3022416113329],
  [2, 821.2891383461206],
  [2, 819.2990313812456],
  [2, 817.2983818353201],
  [2, 815.298379421602],
  [2, 813.2990317046878],
  [2, 811.3003462491738],
  [2, 809.2911704168475],
  [2, 807.3049923807321],
  [2, 805.2971865475552],
  [2, 803.2900810361627],
  [2, 801.305972926448],
  [2, 799.3002831403383],
  [2, 797.2953166668062],
  [2, 795.291081197939],
  [2, 793.2987129087846],
  [2, 791.3070826960361],
  [2, 789.3050779727491],
  [2, 787.3038349370072],
  [2, 785.3033612808974],
  [2, 783.3036646965065],
  [2, 781.3047528759216],
  [2, 779.3066335112296],
  [2, 777.2982203259917],
  [2, 775.2906240249645],
  [2, 773.29493728515],
  [2, 771.3000738550439],
  [2, 769.3060414267331],
  [2, 767.2907058354031],
  [2, 765.2983679344411],
  [2, 763.3068841970032],
  [2, 761.2941491645205],
  [2, 759.304406535241],
  [2, 757.2934476849866],
  [2, 755.3054772750336],
  [2, 753.2963260638555],
  [2, 751.2880955490289],
  [2, 749.3028468691427],
  [2, 747.2964712655612],
  [2, 745.2910401250017],
  [2, 743.2975724015987],
  [2, 741.3050544762299],
  [2, 739.3024937487769],
  [2, 737.3009092023659],
  [2, 735.300308788346],
  [2, 733.300700458067],
  [2, 731.3020921628777],
  [2, 729.3044918541277],
  [2, 727.3079074831664],
  [2, 725.2904234824728],
  [2, 723.295906224212],
  [2, 721.3024288451652],
  [2, 719.2881103166644],
  [2, 717.2967481962619],
  [2, 715.3064498964986],
  [2, 713.2953697197288],
  [2, 711.3072348245533],
  [2, 709.2983580806621],
  [2, 707.2906013534237],
  [2, 705.3057779441576],
  [2, 703.3002735024638],
  [2, 701.2959136372331],
  [2, 699.2927065648382],
  [2, 697.2906605016522],
  [2, 695.2897836640477],
  [2, 693.2900842683978],
  [2, 691.2915705310752],
  [2, 689.2942506684528],
  [2, 687.2981328969036],
  [2, 685.3032254328002],
  [2, 683.2878726852075],
  [2, 681.2954238643866],
  [2, 679.3042100894389],
  [2, 677.292616323029],
  [2, 675.3039109371138],
  [2, 673.294869617246],
  [2, 671.2979149553572],
  [2, 669.3022505555564],
  [2, 667.2971079572113],
  [2, 665.2932865362446],
  [2, 663.2907947798772],
  [2, 661.2896411753297],
  [2, 659.2898342098232],
  [2, 657.2913823705785],
  [2, 655.2942941448165],
  [2, 653.2985780197578],
  [2, 651.3042424826235],
  [2, 649.289874068146],
  [2, 647.298340242196],
  [2, 645.2975167614868],
  [2, 643.2981232069124],
  [2, 641.2894878344249],
  [2, 639.3036603755628],
  [2, 637.2979435498962],
  [2, 635.2937069005593],
  [2, 633.2909591915086],
  [2, 631.2897091867007],
  [2, 629.2899656500924],
  [2, 627.2917373456402],
  [2, 625.2950330373005],
  [2, 623.2998614890301],
  [2, 621.3062314647857],
  [2, 619.2929677564177],
  [2, 617.3024637048663],
  [2, 615.2923771275528],
  [2, 613.3050344784472],
  [2, 611.2981608388526],
  [2, 609.292915690304],
  [2, 607.2893080794448],
  [2, 605.2978793638432],
  [2, 603.2975652307643],
  [2, 601.2884009394384],
  [2, 599.2914339457714],
  [2, 597.2961497230109],
  [2, 595.3025573178006],
  [2, 593.2897075757977],
  [2, 591.2995439927943],
  [2, 589.2901776425731],
  [2, 587.3034794502641],
  [2, 585.2976334452436],
  [2, 583.2935707827821],
  [2, 581.2913007982244],
  [2, 579.290832826915],
  [2, 577.2921762041987],
  [2, 575.29534026542],
  [2, 573.3003343459237],
  [2, 571.3071677810544],
  [2, 569.2951167734407],
  [2, 567.3056763274178],
  [2, 565.2974093145882],
  [2, 563.2910584977914],
  [2, 561.2969606404282],
  [2, 559.3047782884839],
  [2, 557.3042137389621],
  [2, 555.3056038066878],
  [2, 553.2883844086261],
  [2, 551.2937330006218],
  [2, 549.3010651995155],
  [2, 547.2898786311904],
  [2, 545.3012276325171],
  [2, 543.2941189368892],
  [2, 541.2890744795902],
  [2, 539.3065321562137],
  [2, 537.3056246522211],
  [2, 535.306811078351],
  [2, 533.2897376545554],
  [2, 531.2951633545545],
  [2, 529.3027128791437],
  [2, 527.292097836976],
  [2, 525.3039467294154],
  [2, 523.2976951962246],
  [2, 521.2936517979618],
  [2, 519.2918267727948],
  [2, 517.2922303588914],
  [2, 515.2948727944192],
  [2, 513.2997643175461],
  [2, 511.2968560232871],
  [2, 509.2962403279735],
  [2, 507.28789168634233],
  [2, 505.3019283104878],
  [2, 503.29824019197605],
  [2, 501.2969098194628],
  [2, 499.2979477445076],
  [2, 497.3013645186701],
  [2, 495.2872408574329],
  [2, 493.29547103731744],
  [2, 491.30611182651404],
  [2, 489.2993166586468],
  [2, 487.2950021882673],
  [2, 485.29317928665796],
  [2, 483.29385882510127],
  [2, 481.2970516748795],
  [2, 479.3027687072754],
  [2, 477.2913132276382],
  [2, 475.3021365006474],
  [2, 473.2958599942416],
  [2, 471.2922024717532],
  [2, 469.29117513058173],
  [2, 467.2927891681266],
  [2, 465.2970557817874],
  [2, 463.30398616896355],
  [2, 461.29409113238484],
  [2, 459.30640904920097],
  [2, 457.30197719778886],
  [2, 455.30030750279235],
  [2, 453.3014114941856],
  [2, 451.30530070194254],
  [2, 449.29264783859253],
  [2, 447.30216939033204],
  [2, 445.2952270643524],
  [2, 443.29117154716795],
  [2, 441.2900147078483],
  [2, 439.29176841546285],
  [2, 437.29644453908145],
  [2, 435.3040549477735],
  [2, 433.2954965857789],
  [2, 431.2899535218755],
  [2, 429.2874379708125],
  [2, 427.2879621473392],
  [2, 425.2915382662047],
  [2, 423.2981785421583],
  [2, 421.28895493864474],
  [2, 419.30178964685183],
  [2, 417.2988443677819],
  [2, 415.2990719198158],
  [2, 413.3024848700298],
  [2, 411.2903054952297],
  [2, 409.30015729091167],
  [2, 407.29450311658826],
  [2, 405.29214618864404],
  [2, 403.29309943319345],
  [2, 401.2973757763507],
  [2, 399.30498814422987],
  [2, 397.2973761527841],
  [2, 395.2931892938231],
  [2, 393.2924408592733],
  [2, 391.2951441410608],
  [2, 389.3013124311121],
  [2, 387.2925461170564],
  [2, 385.28733647894114],
  [2, 383.30404457595364],
  [2, 381.28764188883434],
  [2, 379.2931842659943],
  [2, 377.3023379773974],
  [2, 375.2969021229937],
  [2, 373.29517213038343],
  [2, 371.2971620436925],
  [2, 369.30288590704663],
  [2, 367.2942792885387],
  [2, 365.2895035499419],
  [2, 363.2885731218965],
  [2, 361.2915024350423],
  [2, 359.29830592001946],
  [2, 357.2910940325159],
  [2, 355.3057243811249],
  [2, 353.2886065032006],
  [2, 351.29336050975195],
  [2, 349.30213279601793],
  [2, 347.2972135875625],
  [2, 345.29641504904606],
  [2, 343.2997524052824],
  [2, 341.2896266115797],
  [2, 339.3013183700296],
  [2, 337.2996519028484],
  [2, 335.30225711214143],
  [2, 333.29168524679403],
  [2, 331.3029185211032],
  [2, 329.3010822222771],
  [2, 327.30365678163366],
  [2, 325.29334728664253],
  [2, 323.2875584917281],
  [2, 321.2863068667775],
  [2, 319.2896088816781],
  [2, 317.29748100631696],
  [2, 315.29282572200356],
  [2, 313.2928533366474],
  [2, 311.2975807497636],
  [2, 309.29003194054684],
  [2, 307.30425018008856],
  [2, 305.2893966779076],
  [2, 303.29634489758524],
  [2, 301.29133195721994],
  [2, 299.2912866553145],
  [2, 297.2962267729247],
  [2, 295.28946817465453],
  [2, 293.2878154507637],
  [2, 291.29128683434715],
  [2, 289.2999005585],
  [2, 287.2971447083249],
  [2, 285.29965449248],
  [2, 283.29100616088],
  [2, 281.2877490215457],
  [2, 279.2899022345057],
  [2, 277.2974849597883],
  [2, 275.29425229619113],
  [2, 273.29657762856755],
  [2, 271.2883075925947],
  [2, 269.2857263673602],
  [2, 267.28885407072084],
  [2, 265.29771082053344],
  [2, 263.29632891582963],
  [2, 261.30080988631124],
  [2, 259.29528103395],
  [2, 257.2957512846578],
  [2, 255.30224174552131],
  [2, 253.2990241865627],
  [2, 251.3019658329377],
  [2, 249.29543668016055],
  [2, 247.29520819352007],
  [2, 245.30130250124063],
  [2, 243.2982389498215],
  [2, 241.30164249858353],
  [2, 239.29613402300032],
  [2, 237.29723948657352],
  [2, 235.2896827979181],
  [2, 233.28888945334353],
  [2, 231.29488317371622],
  [2, 229.29254450350675],
  [2, 227.2971452656988],
  [2, 225.29367246242356],
  [2, 223.29729409406633],
  [2, 221.29310517749147],
  [2, 219.29616836696695],
  [2, 217.29168826812992],
  [2, 215.294620648899],
  [2, 213.2902812948169],
  [2, 211.29351753038026],
  [2, 209.28975792939053],
  [2, 207.29373979888308],
  [2, 205.29100612661793],
  [2, 203.296182611036],
  [2, 201.29492829702923],
  [2, 199.28744564328116],
  [2, 197.28819032757582],
  [2, 195.29719130339305],
  [2, 193.28621320824936],
  [2, 191.2978605841453],
  [2, 189.2899488380408],
  [2, 187.2907731408433],
  [2, 185.30036434749735],
  [2, 183.2911010034284],
  [2, 181.29091539797628],
  [2, 179.2861398471744],
  [2, 177.29063312779658],
  [2, 175.29085608251415],
  [2, 173.30054208031396],
  [2, 171.29284037781824],
  [2, 169.2949303073931],
  [2, 167.29353720568196],
  [2, 165.30213859400646],
  [2, 163.29441975743975],
  [2, 161.29703732997768],
  [2, 159.28395282208993],
  [2, 157.2945231252838],
  [2, 155.28988269268456],
  [2, 153.296146361563],
  [2, 151.2878406854448],
  [2, 149.3034877072385],
  [2, 147.29246393108886],
  [2, 145.2930815653326],
  [2, 143.29291883758742],
  [2, 141.29224121074336],
  [2, 139.2913171701214],
  [2, 137.29041824895563],
  [2, 135.28981905403313],
  [2, 133.30187994280564],
  [2, 131.30263787354238],
  [2, 129.2926125407746],
  [2, 127.2960206960363],
  [2, 125.28938535510648],
  [2, 123.29660832074296],
  [2, 121.29454363976606],
  [2, 119.28374267278159],
  [2, 117.28762468123932],
  [2, 115.28355666944663],
  [2, 113.29462550843346],
  [2, 111.29854743017731],
  [2, 109.29590683339896],
  [2, 107.28729808280946],
  [2, 105.29512608196555],
  [2, 103.2978356587573],
  [2, 101.29604357311678],
  [2, 99.29037691968006],
  [2, 97.30252459300459],
  [2, 95.29083731565038],
  [2, 93.29787829556949],
  [2, 91.28279444325926],
  [2, 89.28738459616056],
  [2, 87.29167052921],
  [2, 85.29634626921606],
  [2, 83.3021170528798],
  [2, 81.29027347368307],
  [2, 79.30061132581613],
  [2, 77.295242193848],
  [2, 75.29435252951444],
  [2, 73.29871476733501],
  [2, 71.29080742289275],
  [2, 69.29020196394546],
  [2, 67.29771702142898],
  [2, 65.29659579789993],
  [2, 63.28842393463674],
  [2, 61.291910000363714],
  [2, 59.2911160598635],
  [2, 57.28773012834722],
  [2, 55.283480885555036],
  [2, 53.2961577064124],
  [2, 51.2952526860424],
  [2, 49.283462888932945],
  [2, 47.2938819736828],
  [2, 45.28299840046543],
  [2, 43.28373995094694],
  [2, 41.298174259454754],
  [2, 39.30060235468204],
  [2, 37.29518022078082],
  [2, 35.28621640563756],
  [2, 33.29103546147067],
  [2, 31.300660062697716],
  [2, 29.283511488965814],
  [2, 27.283204385721547],
  [2, 25.282340367783718],
  [2, 23.288848716453003],
  [2, 21.29020120009841],
  [2, 19.29787899067037],
  [2, 17.286296661210372],
  [2, 15.292336023048847],
  [2, 13.299819068633951],
  [2, 11.288025285693948],
  [2, 9.296278500814878],
  [2, 7.2930364995499986],
  [2, 5.291536765507772],
  [2, 3.2954640271145763],
  [2, 1.2831564274529228],
];

export default PointsTraj2;

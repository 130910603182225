import BaseObserver from '../libs/classUtils/BaseObserver';
import firebase from 'firebase/app';
import { objDefaultProfile, ObjDefaultProfile } from './Types/LoginTypes';
export * from './Types/LoginTypes';

type NotifyCallBack = (obj: ProfileManagerClass) => void | '@REMOVE';

class ProfileManagerClass extends BaseObserver<NotifyCallBack> {
  objProfile: ObjDefaultProfile;
  constructor() {
    super();
    this.objProfile = objDefaultProfile;
  }

  loadDataFromServer() {
    const objServerProfile = firebase.auth().currentUser;
    if (!objServerProfile) {
      return null;
    }
    this.objProfile.name = objServerProfile.displayName ?? this.objProfile.name;
    this.objProfile.imgSRC = objServerProfile.photoURL ?? this.objProfile.imgSRC;
    this.notifyAll();
    return this.objProfile;
  }

  updateProfile(objUpdate: Partial<ObjDefaultProfile>) {
    return firebase
      .auth()
      .currentUser?.updateProfile({ displayName: objUpdate.name, photoURL: objUpdate.imgSRC });
  }

  updateProp<T extends keyof ObjDefaultProfile>(strProp: T, newValue: ObjDefaultProfile[T]) {
    this.objProfile[strProp] = newValue;
    this.updateProfile(this.objProfile);
    this.notifyAll();
  }
}

const ProfileManager = new ProfileManagerClass();

(window as any).profile = ProfileManager;
(window as any).firebase = firebase;

export default ProfileManager;

import React from 'react';
import MissionComponent from '../../../../components/MapComponents/missionComponent';

function HistoryPage(props) {
  return (
    <>
      <MissionComponent
        numberMissions={4}
        subject="physics"
        typeScoring="kinematics"
        arrayScoring="arrStartTorricelli"
        missionName="Correndo com Tubarões"
        missionDescription="Descrição: ganhe a corrida no Autódromo, mas cuidado para não cair no meio dos tubarões."
        missionVectorNumber={3}
        submission={[
          {
            missionName: 'Desvendando Torricelli',
            missionDescription: 'Descrição: No Laboratório, desvende a Equação de Torricelli.',
            missionVectorNumber: 0,
          },
          {
            missionName: 'Passando no radar',
            missionDescription: 'Descrição: Converse com o mecânico Luiz Câmera.',
            missionVectorNumber: 1,
          },
          {
            missionName: 'Passando na ponte',
            missionDescription: 'Descrição: Converse com a Engenheira Thaís Condida.',
            missionVectorNumber: 2,
          },
        ]}
      />
    </>
  );
}

export default HistoryPage;

/* eslint-disable react/display-name */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import PlayDialogue from '../../../Common/compPlayDialogue';
import '../CSSs/SelectSpecies.scss';

import robo from '../../../../images/Physics/Gravitation/robo.PNG';

function Introdutory(props) {
  const [animationStep, setAnimationStep] = useState(0);

  useEffect(() => {
    if (animationStep === 2) {
      props.setNextItem('Mensagem do Imperador');
    }
  }, [animationStep]);

  const arrPage = [
    <PlayDialogue
      key={0}
      buttons="Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text="Olá cosmonauta, que grande viagem estamos fazendo."
      image={robo}
      extraContent={''}
    />,

    <PlayDialogue
      key={1}
      buttons="Anterior"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text="Você soube acionar os motores de nossa nave na velocidade correta e agora estamos chegando ao planeta Quest! O Imperador Giles deseja falar com você."
      image={robo}
      extraContent={''}
    />,
  ];

  return arrPage[animationStep];
}

export default Introdutory;

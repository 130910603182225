import React from 'react';
import EscapeVelocityScene from '../../../../components/Física/Gravitação/Velocidade de Escape/EscapeVelocityScene';
import RememberVariables from './rememberVariables';
import Navbar from '../../../../components/navbar';
import { Button } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

function ChallengeGravitationEscapeVelocity(props) {

  let history = useHistory();
  let route = '/fisica-gravitacao-velocidadeDeEscape/'+sessionStorage.getItem('challenge_id');

  return (
    <div className="presentationPage">
      <Navbar />
      <div className="challengeContainer">
          <EscapeVelocityScene className="animationContent" map={route} />
          <div className="challengeBtnContainer">
              <Button.Group fluid>
              <Button
                  color="black"
                  onClick={() => {
                  history.push(route);
                  window.location.reload(false);
                  }}
              >
                  Voltar para o mapa
              </Button>
              <Button.Or text=" " />

              <RememberVariables />
              </Button.Group>
          </div>
      </div>
    </div>
  );
}

export default ChallengeGravitationEscapeVelocity;

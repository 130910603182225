import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import './babylonControls.scss';

function ControlPanel(props) {
  return (
    props.condition && (
      <div className="controlPanel__firstSquare">
        <div className="">
          <div className="controlPanel__buttonControl">
            <Button
              icon
              labelPosition="left"
              onClick={() => {
                props.playPauseFunction();
              }}
            >
              <Icon name={props.icon} />
              {props.button}
            </Button>
            <Button
              icon
              labelPosition="left"
              onClick={() => {
                props.restartFunction();
              }}
            >
              <Icon name="stop" />
              Reiniciar
            </Button>
          </div>
        </div>
      </div>
    )
  );
}

export default ControlPanel;

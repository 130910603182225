import { History } from 'history';

const name = localStorage.getItem('name');
const surname = localStorage.getItem('surname');

const objDefaultProfile = {
  name: name + ' ' + surname,
  age: 15,
  imgSRC: localStorage.getItem('avatar'),
};

export type ObjDefaultProfile = Omit<typeof objDefaultProfile, 'age'>;

export interface ProfileSummaryField {
  fnAction?: (history: History) => void;
  strTitle: string;
}

export interface ProfileSummaryProps extends ObjDefaultProfile {
  arrSummaryfields: ProfileSummaryField[][];
}

export interface NavBarProfileProps extends ProfileSummaryProps {
  name: string;
}

export { objDefaultProfile };

import React, { useState, useEffect } from 'react';
import Navbar from '../../components/navbar';
import { useHistory } from 'react-router-dom';
import { Form, Checkbox, Button, Icon, Image, Modal, Header, List, Progress } from 'semantic-ui-react';

import useSound from 'use-sound';
import correctSound from '../../sounds/correctAnswer.mp3';
import wrongSound from '../../sounds/wrongAnswer.mp3';

import '../../components/MapComponents/tipPage.css';
import './training.scss';


const db = [{
  subject: 'Physics',
  module: 'Eletrostática',
  subtitle1: '(PUC-RJ)  O que acontece com a força entre duas cargas elétricas (+Q) e (–q) colocadas a uma distância (d) se mudarmos a carga (+ Q) por (+ 4Q), a carga (–q) por (+3q) e a distância (d) por (2d)?',
  valueA: 'Mantém seu módulo e passa a ser atrativa.',
  valueB: 'Mantém seu módulo e passa a ser repulsiva.',
  valueC: 'Tem seu módulo dobrado e passa a ser repulsiva.',
  valueD: 'Tem seu módulo triplicado e passa a ser repulsiva.',
  valueE: 'Tem seu módulo triplicado e passa a ser atrativa.',
  corretcAnswer: 'd'
},
{
  subject: 'Physics',
  module: 'Calorimetria',
  subtitle1: '(PUC-RS) Um médico recomendou a um paciente que fizesse exercícios com uma toalha quente sobre os ombros, a qual poderá ser aquecida, a uma mesma temperatura, embebedando-a com água quente ou utilizando-se um ferro de passar roupa, que a manterá seca. Quando a temperatura da toalha tiver baixado 10oC, a toalha:',
  valueA: 'úmida terá liberado mais calor que a seca, devido ao grande calor específico da água.',
  valueB: 'úmida terá liberado menos calor que a seca, devido ao pequeno calor específico da água.',
  valueC: 'seca terá liberado a mesma quantidade de calor que a toalha úmida.',
  valueD: 'seca terá liberado mais calor que a úmida, devido à grande massa específica da água.',
  valueE: 'seca terá liberado menos calor que a úmida, devido à pequena massa específica da água.',
  corretcAnswer: 'a'
},
{
  subject: 'Physics',
  module: 'Calorimetria',
  subtitle1: '(UFG-GO) Um automóvel possui uma mistura aquosa em seu sistema de arrefecimento. Essa mistura é bombeada fazendo circular o calor do motor até o radiador, onde o calor é dissipado para o meio ambiente. Um motorista liga o motor desse automóvel e parte para sua viagem.',
  subtitle2: 'Decorridos 10 minutos, ele observa, no indicador de temperatura do painel, que a mistura chega ao radiador com 90 ºC e permanece em torno desse valor durante a viagem. Isso ocorre porque',
  valueA: 'o radiador dissipa mais calor do que o motor produz.',
  valueB: 'o radiador dissipa mais calor quanto maior a temperatura da mistura aquosa.',
  valueC: 'o motor libera menos calor quando aquecido acima dessa temperatura.',
  valueD: 'o motor para de produzir calor acima dessa temperatura.',
  valueE: 'o radiador dissipa menos calor acima dessa temperatura.',
  corretcAnswer: 'b'
},
{
  subject: 'Physics',
  module: 'Calorimetria',
  subtitle1: '(UFF-RJ) Uma bola de ferro e uma bola de madeira, ambas com a mesma massa e a mesma temperatura, são retiradas de um forno quente e colocadas sobre blocos de gelo.',
  subtitle2: 'Marque a opção que descreve o que acontece a seguir:',
  valueA: 'A bola de metal esfria mais rápido e derrete mais gelo.',
  valueB: 'A bola de madeira esfria mais rápido e derrete menos gelo.',
  valueC: 'A bola de metal esfria mais rápido e derrete menos gelo.',
  valueD: 'A bola de metal esfria mais rápido e ambas derretem a mesma quantidade de gelo.',
  valueE: 'Ambas levam o mesmo tempo para esfriar e derretem a mesma quantidade de gelo.',
  corretcAnswer: 'c'
},
{
  subject: 'Chemistry',
  module: 'Separação de Misturas',
  subtitle1: '(Fuvest) - Para a separação das misturas: gasolina-água e nitrogênio-oxigênio, os processos mais adequados são respectivamente: ',
  valueA: 'decantação e liquefação.',
  valueB: 'sedimentação e destilação.',
  valueC: 'filtração e sublimação.',
  valueD: 'destilação e condensação.',
  valueE: 'flotação e decantação.',
  corretcAnswer: 'a'
},
{
  subject: 'Chemistry',
  module: 'Átomo',
  subtitle1: '(AMAN-SP) - O elemento hipotético com nº atômico (Z = 116) apresenta na camada mais externa (camada de valência) um número de elétrons igual a:',
  valueA: '2;',
  valueB: '4;',
  valueC: '6;',
  valueD: '8;',
  valueE: '18;',
  corretcAnswer: 'c'
},
{
  subject: 'Chemistry',
  module: 'Átomo',
  subtitle1: '(Mackenzie) - Uma distribuição eletrônica possível para um elemento X, que pertence à mesma família do elemento bromo, cujo número atômico é igual a 35, é: ',
  valueA: '1s2 , 2s2 , 2p5 ',
  valueB: '1s2 , 2s2 , 2p6 , 3s2 , 3p1 ',
  valueC: '1s2 , 2s2 , 2p2',
  valueD: '1s2 , 2s2 , 2p6 , 3s1 ',
  valueE: '1s2 , 2s2 , 2p6 , 3s2 , 3p6 , 4s2 , 3d5',
  corretcAnswer: 'a'
},
{
  subject: 'Biology',
  module: 'Células',
  subtitle1: '(UFSCar) Em uma célula vegetal, o material genético concentra-se no interior do núcleo, o qual é delimitado por uma membrana. Além dessa região, material genético também é encontrado no interior do:',
  valueA: 'retículo endoplasmático e complexo Golgiense.',
  valueB: 'complexo Golgiense e cloroplasto.',
  valueC: 'lisossomo e retículo endoplasmático.',
  valueD: 'lisossomo e mitocôndria.',
  valueE: 'cloroplasto e mitocôndria.',
  corretcAnswer: 'e'
},
{
  subject: 'Biology',
  module: 'Células',
  subtitle1: '(UFSC) Assinale a alternativa correta.',
  valueA: 'A membrana citoplasmática é exclusiva de células de vegetais.',
  valueB: 'O cloroplasto é responsável, exclusivamente, pelo armazenamento de nutrientes na célula.',
  valueC: 'A parede celular está presente nas células de animais e vegetais.',
  valueD: 'A mitocôndria é responsável pela respiração celular e pode ser encontrada em células de animais e vegetais.',
  valueE: 'O núcleo está presente somente em células vegetais.',
  corretcAnswer: 'e'
},
{
  subject: 'Biology',
  module: 'Células',
  subtitle1: '(Enem) A estratégia de obtenção de plantas transgênicas pela inserção de transgenes em cloroplastos, em substituição à metodologia clássica de inserção do transgene no núcleo da célula hospedeira, resultou no aumento quantitativo da produção de proteínas recombinantes com diversas finalidades biotecnológicas. O mesmo tipo de estratégia poderia ser utilizada para produzir proteínas recombinantes em células de organismos eucarióticos não fotossintetizantes, como as leveduras, que são usadas para produção comercial de várias proteínas recombinantes e que podem ser cultivadas em grandes fermentadores.',
  subtitle2: 'Considerando a estratégia metodológica descrita, qual organela celular poderia ser utilizada para inserção de transgenes em leveduras?',
  valueA: 'Lisossomo.',
  valueB: 'Mitocôndria.',
  valueC: 'Peroxissomo.',
  valueD: 'Complexo golgiense.',
  valueE: 'Retículo endoplasmático.',
  corretcAnswer: 'b'
},];

// const questionDB = db[Math.floor(Math.random()*3)];
// const questionDB = db[Math.floor(Math.random()*10)];
// const initialQuest = Math.floor(Math.random()*10);
const initialQuest = 9;

function TrainingPresentation() {

  let history = useHistory();

  

  const [answer, setAnswer] = useState(0);
  const [playCorrect] = useSound(correctSound);
  const [playWrong] = useSound(wrongSound);
  const [openModal0, setOpenModal0] = useState(false);
  const [counter, setCounter] = React.useState(0);

  const [questionDB, setQuestionDB] = useState(db[initialQuest]);

  function greaterThanMediaQuery(){
    if(window.matchMedia("(max-width: 750px)").matches){
      return '1.5vh';
    } else{
      return '1.0vw';
    }
  }

  const checkboxStyle = {
    width: '75%',
    height: '10px',
    fontSize: greaterThanMediaQuery()
  };

  const modalStyle = {
    borderRadius: 0,
    opacity: 0.9,
    color: 'white',
    backgroundColor: 'black',
  };

  const SIZEIMAGE = '25%';
  
  function readAnswer(e, { value }) {
    setAnswer(value);
  }

  function getContent(answer) {
    return (
      <div>
        {answer === true && 
          <div>
            <List divided relaxed>
              <List.Item>
                <List.Icon name="check" size="big" verticalAlign="top" color="green" />
                <List.Content>
                  <p>Resposta Correta!</p>
                  <p>Você ganhou 20 pontos de xp!</p>
                </List.Content>
              </List.Item>
            </List>
          </div>
        }
        {answer === false && 
          <div>
            <List divided relaxed>
              <List.Item>
                <List.Icon name="times" size="big" verticalAlign="top" color="red" />
                <List.Content>
                  <p>Sua resposta está errada!</p>
                </List.Content>
              </List.Item>
            </List>
          </div>
        }
        <br></br>
        <br></br>
        <div className="presentationItem arrowBtnContainer">
          <Button
            className={"trainingChallenge__buttonAnswerNew"}
            onClick={() => {
              // window.location.reload(false);
              setQuestionDB(db[Math.floor(Math.random()*10)]);
              setCounter(0);
              setOpenModal0(false);
              setAnswer(0);
            }}
          >
            Novo Treinamento
          </Button>
        </div>
      </div>
    )
  }

  function getColor(subject){
    if(subject === 'Physics') return 'blue';
    if(subject === 'Biology') return 'green';
    if(subject === 'Chemistry') return 'yellow';
  }

  useEffect(() => {
    const timer = counter < 120 && setInterval(() => setCounter(counter + 1), 1000);
    if(counter === 120){
        setOpenModal0(true);
      }
    return () => {
      clearInterval(timer);
      
    }
  }, [counter]);


  return (
    <div>
        <Navbar />
        <div className={'trainingChallenge__bckgd'+questionDB.subject} >
            <div className='trainingChallenge__progressContainer' >
                <Progress
                    inverted
                    color={getColor(questionDB.subject)}
                    value={counter}
                    total={120}
                    active
                    size='small'
                  >
                    {' '}
                </Progress>
            </div>
            <div className='trainingChallenge__presentationContainer' >
                <div className='trainingChallenge__textContainer' >
                  <Form>
                    <Form.Field>
                      {questionDB.subject === 'Physics' && <h1 className='trainingChallenge__textContainer__titlePhysics'>Física</h1>}
                      {questionDB.subject === 'Biology' && <h1 className='trainingChallenge__textContainer__titleBiology'>Biologia</h1>}
                      {questionDB.subject === 'Chemistry' && <h1 className='trainingChallenge__textContainer__titleChemistry'>Química</h1>}
                      <p className="trainingChallenge__textContainer__paragraph">{'Módulo: '+questionDB.module}</p>
                      {questionDB.subtitle1 && <p className="trainingChallenge__textContainer__paragraph">{questionDB.subtitle1}</p>}
                      {questionDB.subtitle2 && <p className="trainingChallenge__textContainer__paragraph">{questionDB.subtitle2}</p>}
                      {questionDB.subtitle3 && <p className="trainingChallenge__textContainer__paragraph">{questionDB.subtitle3}</p>}
                      {questionDB.subtitle4 && <p className="trainingChallenge__textContainer__paragraph">{questionDB.subtitle4}</p>}
                      {questionDB.subtitle5 && <p className="trainingChallenge__textContainer__paragraph">{questionDB.subtitle5}</p>}
                      {questionDB.image && <Image src={questionDB.image} width={SIZEIMAGE} centered />}
                    </Form.Field>
                    <Form.Field>
                      <Checkbox
                        style={checkboxStyle}
                        radio
                        label={questionDB.valueA}
                        value="a"
                        checked={answer === 'a'}
                        onChange={readAnswer}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Checkbox
                        style={checkboxStyle}
                        radio
                        label={questionDB.valueB}
                        value="b"
                        checked={answer === 'b'}
                        onChange={readAnswer}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Checkbox
                        style={checkboxStyle}
                        radio
                        label={questionDB.valueC}
                        value="c"
                        checked={answer === 'c'}
                        onChange={readAnswer}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Checkbox
                        style={checkboxStyle}
                        radio
                        label={questionDB.valueD}
                        value="d"
                        checked={answer === 'd'}
                        onChange={readAnswer}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Checkbox
                        style={checkboxStyle}
                        radio
                        label={questionDB.valueE}
                        value="e"
                        checked={answer === 'e'}
                        onChange={readAnswer}
                      />
                    </Form.Field>
                    <br></br>
                  </Form>

                  <Modal
                    open={openModal0}
                    onClose={() => {
                      setOpenModal0(false);
                    }}
                    onOpen={() => {
                      setOpenModal0(true);
                    }}
                    trigger={
                      <div></div>
                    }
                  >
                    <Header content={'Resultado'} style={modalStyle} />
                    <Modal.Content image scrolling style={modalStyle}>
                      {getContent(answer === questionDB.corretcAnswer)}
                    </Modal.Content>
                  </Modal>
                  <div className="presentationItem arrowBtnContainer">
                        <Button
                          className={"trainingChallenge__buttonAnswer"+questionDB.subject}
                          onClick={() => {
                            setOpenModal0(true);
                          }}
                        >
                          <Icon name="check" />
                          RESPONDER
                        </Button>

                        <Button
                          className={"trainingChallenge__buttonSkip"}
                          onClick={() => {
                            // window.location.reload(false);
                            setQuestionDB(db[Math.floor(Math.random()*10)]);
                            setCounter(0);
                            setAnswer(0);
                          }}
                        >
                          <Icon name="redo" />
                          PULAR
                        </Button>
                      </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default TrainingPresentation;

import React from 'react';
import AfterChallengeComponent from '../../../../components/MapComponents/afterChallenge';

function AfterRace(props) {
  const additionalInfo = JSON.parse(sessionStorage.getItem('additional_info'));

  return (
    <AfterChallengeComponent
      subject="physics"
      typeScoring="kinematics"
      arrayScoring="arrStarMRUV"
      search={additionalInfo.choosed_team}
      map={'/mapa-de-fases/' + sessionStorage.getItem('module_id')}
      speak={[
        {
          paragraph1:
            'Parabéns! Você soube acelerar rumo à vitória e trouxe mais uma vez um excelente resultado para a equipe ' +
            additionalInfo.choosed_team +
            '!',
          paragraph2: 'Estamos ganhando mais pontos no campeonato!',
        },
        {
          paragraph1: 'Você deve se orgulhar das premiações individuais que recebeu.',
          paragraph2: 'Além disso, em todo o campeonato você acumula um total de ',
        },
        {
          paragraph1:
            'Nós da equipe e os patrocinadores estamos animados para seguir acompanhando seu desempenho nas corridas do campeonato. Quando estiver pronto, vá até o mapa de fases para continuar sua jornada rumo ao título!',
        },
      ]}
    />
  );
}

export default AfterRace;

import React from 'react';
import VelocidadeScene from '../components/Física/Cinemática/Velocidade Média/VelocidadeScene';

function Challenge1() {
  return (
    <div>
      <h1>Desafio 1</h1>
      <VelocidadeScene />
    </div>
  );
}

export default Challenge1;

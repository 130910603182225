import React from 'react';
import AfterChallengeComponent from '../../../../components/MapComponents/afterChallenge';

function AfterRace(props) {
  const additionalInfo = JSON.parse(sessionStorage.getItem('additional_info'));
  
  return (
    <AfterChallengeComponent
      subject="physics"
      typeScoring="kinematics"
      arrayScoring="arrStarAverageSpeed"
      search={additionalInfo.choosed_team}
      map={'/mapa-de-fases/' + sessionStorage.getItem('module_id')}
      speak={[
        {
          paragraph1:
            'Parabéns, grande piloto! Você soube escolher a melhor estratégia e já começou com tudo conquistando um excelente resultado para a equipe ' +
            additionalInfo.choosed_team +
            '!',
          paragraph2: 'Estamos orgulhosos de você.',
        },
        {
          paragraph1:
            'Além da vitória que trouxe à equipe, você deve se orgulhar das premiações individuais que recebeu.',
          paragraph2: 'Além disso, em todo o campeonato você acumula um total de ',
        },
        {
          paragraph1:
            'Nós da equipe e os patrocinadores estamos ansiosos para ver o seu desempenho nas próximas corridas do campeonato. Quando estiver pronto, vá até o mapa de fases para continuar sua jornada rumo ao título!',
        },
      ]}
    />
  );
}

export default AfterRace;

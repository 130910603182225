import React, { useState } from 'react';
import { Comment } from 'semantic-ui-react';
import './babylonReactComp.scss';

function AskMessage(props) {
  const fontColor = {
    color: 'white',
    fontSize: '1.7vh',
  };

  return (
    <>
      {props.condition && (
        <div className="">
          {props.position === 'top' && (
            <div className="askMessage__topMessage">
              <Comment.Group size="big">
                <Comment>
                  <Comment.Avatar as="a" src={props.image} />
                  <Comment.Content>
                    <Comment.Author style={fontColor}>{props.name}</Comment.Author>
                    <Comment.Text style={fontColor}>{props.message}</Comment.Text>
                  </Comment.Content>
                </Comment>
              </Comment.Group>
            </div>
          )}
          {props.position === 'bottom' && (
            <div className="askMessage__bottomMessage">
              <Comment.Group size="big">
                <Comment>
                  <Comment.Avatar as="a" src={props.image} />
                  <Comment.Content>
                    <Comment.Author style={fontColor}>{props.name}</Comment.Author>
                    <Comment.Text style={fontColor}>{props.message}</Comment.Text>
                  </Comment.Content>
                </Comment>
              </Comment.Group>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default AskMessage;

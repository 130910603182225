import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../../services/api';

import VelocidadeScene from '../../../../components/Física/Cinemática/Velocidade Média/VelocidadeScene';
import ItemsBar from '../../../../components/ItemsBar';
import Navbar from '../../../../components/navbar';
import AverageSpeedPage from './averageSpeedPage';
import Introdutory from './officeIntrudutory';
import MapExplanation from './officeMapExplanation';
import ChallengeExplanation from './racetrackChallengeExplanation';
import Displacement from './Displacement';
import SpeedPage from './SpeedPage';
import TimePage from './TimePage';
// import Solution from './solutionPage';
import Solution from './workshopTipPage';
import UnityConversion from './UnityConversion';
import '../../../Common/presentationPage.css';
import BeginChallengePage from './BeginChallengePage';
import HistoryPage from './museumHistoryPage';
import PresentationComp from '../../../Common/compMapPresentation';
import Challenge1Menu from './challengeMenu';
import { Button } from 'semantic-ui-react';
import RememberVariables from './rememberVariables';

import museuImg from '../../../../images/Physics/Kinematics/museu.png';
import laboratorioImg from '../../../../images/Physics/Kinematics/laboratorio.png';
import AfterRace from './afterRace';

function PresentationPage() {
  const { id } = useParams();

  const [isMinimized, setIsMinimized] = useState(true);
  const [presentationItems, setPresentationItems] = useState(['Apresentação', 'Vamos Começar!']);
  const [isChallengeOpened, setIsChallengeOpened] = useState(false);

  const [nextItem, setNextItem] = useState(null);
  const [challengeStarted, setChallengeStarted] = useState(false);
  const [challengeWon, setChallengeWon] = useState(false);
  const [activeItem, setActiveItem] = useState('Apresentação');


  useEffect(() => {
    const fetchData = async () => {
      sessionStorage.clear();
      await api
        .post('getChallengeInfo', {
          challenge_id: id,
          client_id: localStorage.getItem('client_id'),
        })
        .then((res) => {
          sessionStorage.setItem('access_museum', res.data.access_museum);
          sessionStorage.setItem('access_office', res.data.access_office);
          sessionStorage.setItem('additional_info', JSON.stringify(res.data.additional_info));
          sessionStorage.setItem('arr_stars', res.data.arr_stars);
          sessionStorage.setItem('challenge_id', res.data.challenge_id);
          sessionStorage.setItem('earned_stars', res.data.earned_stars);
          sessionStorage.setItem('finished', res.data.finished);
          sessionStorage.setItem('stickers', res.data.stickers);
          sessionStorage.setItem('module_id', res.data.module_id);
          sessionStorage.setItem('level', res.data.level);
          sessionStorage.setItem('xp', res.data.xp);

          setIsMinimized(sessionStorage.getItem('access_office') === 'true' ? true : false);
          setChallengeStarted(sessionStorage.getItem('access_museum') === 'true' ? true : false);
          setChallengeWon(sessionStorage.getItem('finished') === 'true' ? true : false);
        })
        .catch((err) => {});
    };
    fetchData();
  }, []);

  const arrPages = [
    {
      name: 'Laboratório',
      comp: (
        <PresentationComp
          title="Laboratório"
          text1="Bem vindo ao nosso Laboratório, o local onde aprendemos novos assuntos e evoluímos nosso conhecimento."
          text2="Navegue pelos itens à esquerda, aprenda novas habilidades e evolua para se tornar um grande piloto e ganharmos o campeonato!"
          img={laboratorioImg}
          minimizeFunction={setIsMinimized}
          setChallengeStarted={setChallengeStarted}
          setNextItem={setNextItem}
          key={1}
        />
      ),
    },
    {
      name: 'Tempo',
      comp: <TimePage setNextItem={setNextItem} key={2} />,
    },
    {
      name: 'Velocidade',
      comp: <SpeedPage setNextItem={setNextItem} key={3} />,
    },
    {
      name: 'Deslocamento',
      comp: <Displacement setNextItem={setNextItem} key={4} />,
    },
    {
      name: 'Velocidade média',
      comp: <AverageSpeedPage setNextItem={setNextItem} key={5} />,
    },
    {
      name: 'Apresentação',
      comp: <Introdutory setNextItem={setNextItem} setIsMinimized={setIsMinimized} key={6} />,
    },
    {
      name: 'Vamos Começar!',
      comp: <MapExplanation setNextItem={setNextItem} setIsMinimized={setIsMinimized} key={7} />,
    },
    {
      name: 'Explicação do Desafio',
      comp: (
        <ChallengeExplanation
          setChallengeStarted={setChallengeStarted}
          setIsChallengeOpened={setIsChallengeOpened}
          setNextItem={setNextItem}
          setIsMinimized={setIsMinimized}
          key={8}
          challenge = '/fisica-cinematica-velocidade-media-desafio'
        />
      ),
    },
    {
      name: 'Oficina',
      // comp: <Solution challengeStarted={challengeStarted} setNextItem={setNextItem} key={6} />,
      comp: <Solution challengeStarted={true} setNextItem={setNextItem} key={9} />,
    },
    {
      name: 'Conversão de unidades',
      comp: <UnityConversion setNextItem={setNextItem} key={10} />,
    },
    {
      name: 'Começar!',
      comp: (
        <BeginChallengePage
          minimizeFunction={setIsMinimized}
          setChallengeStarted={setChallengeStarted}
          setNextItem={setNextItem}
          key={11}
        />
      ),
    },
    {
      name: 'Museu',
      comp: (
        <PresentationComp
          title="Museu"
          text1="Bem vindo ao Museu, um local dedicado às suas conquistas."
          text2="Realize as missões e conquiste estrelas! Você pode gravar o seu nome na história do Fórmula Quest e desbloquear prêmios incríveis!"
          img={museuImg}
          minimizeFunction={setIsMinimized}
          setChallengeStarted={setChallengeStarted}
          setNextItem={setNextItem}
          key={12}
        />
      ),
    },
    {
      name: 'Conquistas',
      comp: (
        <HistoryPage
          minimizeFunction={setIsMinimized}
          setChallengeStarted={setChallengeStarted}
          setNextItem={setNextItem}
          key={13}
        />
      ),
    },
    {
      name: 'Sua primeira conquista',
      comp: (
        <PresentationComp
          title="Sua primeira conquista"
          text1="Conforme a página anterior, sua primeira estrela será 'Descubra novos conhecimentos'."
          text2="No mapa do desafio, procure por Laboratório e o investigue, aprendendo novos conhecimentos. Lembre-se, estes conhecimentos serão fundamentais para concluirmos o principal objetivo: ganharmos nossa primeira corrida!"
          img={laboratorioImg}
          minimizeFunction={setIsMinimized}
          setChallengeStarted={setChallengeStarted}
          setNextItem={setNextItem}
          key={14}
        />
      ),
    },
    {
      name: 'Você conseguiu!',
      comp: <AfterRace setIsMinimized={setIsMinimized} key={15} />,
    },
  ];

  return (
    <div className="presentationPage">
      <Navbar />
      <div className="backgroundContainer">
        {/* <VelocidadeScene className="animationContent"/> */}
        <Challenge1Menu
          setPresentationItems={setPresentationItems}
          setIsMinimized={setIsMinimized}
          setActiveItem={setActiveItem}
          challengeStarted={challengeStarted}
          setIsChallengeOpened={setIsChallengeOpened}
          setChallengeStarted={setChallengeStarted}
          challengeWon={challengeWon}
        />
      </div>
      {isChallengeOpened && (
        <div className="challengeContainer">
          <VelocidadeScene className="animationContent" map={setIsChallengeOpened} />
          <div className="challengeBtnContainer">
            <Button.Group fluid>
              <Button
                color="black"
                onClick={() => {
                  // setIsChallengeOpened(false);
                  window.location.reload(false);
                }}
              >
                Voltar para o mapa
              </Button>
              <Button.Or text=" " />

              <RememberVariables />
            </Button.Group>
          </div>
        </div>
      )}
      {!isMinimized && <div className="darkBackground"></div>}
      <ItemsBar
        isMinimized={isMinimized}
        minimizeFunction={setIsMinimized}
        items={presentationItems}
        firstActive="Apresentação"
        nextItem={nextItem}
        nextItemFunction={setNextItem}
        setActiveItem={setActiveItem}
        activeItem={activeItem}
      />
      {!isMinimized && arrPages.filter((item) => item.name === activeItem).map((item) => item.comp)}
    </div>
  );
}

export default PresentationPage;

import React, { useState } from 'react';
import { Card, Image, Form, Input, Button } from 'semantic-ui-react';
import questSmall from '../images/Logos/mrQuest_logoSmall.png';
import LoadingSpinner from '../components/loadingSpinner';
import { authenticate } from '../services/auth';
import { useHistory } from "react-router-dom";
import './home.css';

import api from '../services/api';

function Home() {
  const [bShowEnroll, fnFlip] = useState(false);
  return (
    <div className="flexContainer homeBackground">
      <HomeCard
        bIsEnroll={bShowEnroll}
        fnFlip={() => fnFlip((prev) => !prev)}
        className={bShowEnroll ? 'enroll-card' : 'login-card'}
      />
    </div>
  );
}

function greaterThanMediaQuery(){
  if(window.matchMedia("(max-width: 800px)").matches){
    return true;
  } else{
    return false;
  }
}

function HomeCard({ bIsEnroll, fnFlip, className }) {
  let history = useHistory();
  const [pageLoading, setPageLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  async function handleLogin() {
    if(!email || !password){
      return;
    }

    setErrorMessage('');
    setPageLoading(true);
    let isAuthenticated = await authenticate(email, password);
    if(isAuthenticated){
      setPageLoading(false);
      history.push("/disciplinas");
    } else{
      setPageLoading(false);
      setErrorMessage('Por favor, tente novamente!');
    }
  }

  const handleRegister = () => {
    history.push("/register");
  };

  const handleForgotPassword = async () => {
    setErrorMessage('');
    if(!email){
      setErrorMessage('Necessário informar o email!');
      return;
    }

    await api
    .post('resetPassword', {
      email: email,
    })
    .then((res) => {
      history.push('/');
    })
    .catch((err) => {
      setErrorMessage(err.response.data.message);
    });

  };

  return (
    <>
      <LoadingSpinner loading={pageLoading} />
      <Card fluid={greaterThanMediaQuery()} className={'cardBackground ' + className}>
        <Image src={questSmall} className="homeLogo"></Image>
        <Card.Content>
          <Form className="homeLoginForm">
            {bIsEnroll && (
              <Form.Field>
                <label className="homeLabel">Nome</label>
                <Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  id="inputEmail"
                  className="form-control"
                  placeholder="Nome"
                  required
                  autoFocus
                  value={name}
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                />
              </Form.Field>
            )}
            <Form.Field>
              <label className="homeLabel">Login</label>
              <Input
                fluid
                icon="user"
                iconPosition="left"
                id="inputEmail"
                className="form-control"
                placeholder="Email"
                required
                autoFocus
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
            </Form.Field>
            <Form.Field>
              <label className="homeLabel">Senha</label>
              <Input
                className="home inputField"
                fluid
                icon="lock"
                iconPosition="left"
                id="inputPassword"
                placeholder="Senha"
                type="password"
                required
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />
            </Form.Field>
            <Form.Field>
              <label className="errorLabel" style={{marginTop: '5%'}}>{errorMessage}</label>
              <Button
                style={{ marginTop: '1%', backgroundColor: '#ff5722' }}
                fluid
                color="orange"
                onClick={handleLogin}
              >
                Entrar
              </Button>
              <Button
                style={{ marginTop: '5%', marginBottom: '5%', backgroundColor: '#37adbd' }}
                fluid
                color="blue"
                // inverted
                onClick={handleRegister}
              >
                Cadastre-se
              </Button>
              <span className="forgot-password" onClick={handleForgotPassword}>
                Esqueci minha senha
              </span>
            </Form.Field>
          </Form>
        </Card.Content>
      </Card>
    </>
  );
}

export default Home;

import React from 'react';
import MissionComponent from '../../../../components/MapComponents/missionComponent';

function HistoryPage(props) {
  return (
    <>
      <MissionComponent
        numberMissions={5}
        subject="physics"
        typeScoring="gravitation"
        arrayScoring="arrStarKeplersLaws"
        missionName="Saindo do Planeta"
        missionDescription="Descrição: Na Viagem, realize o desafio proposto, saindo da órbita de nosso astro e partindo para o planeta Quest."
        missionVectorNumber={4}
        submission={[
          {
            missionName: 'As Leis de Kepler',
            missionDescription: 'Descrição: Investigue o Centro de Pesquisas.',
            missionVectorNumber: 0,
          },
          {
            missionName: 'Terra e Lua',
            missionDescription:
              'Descrição: No treinamento de astronautas, pesquise sobre a observação das estrelas.',
            missionVectorNumber: 1,
          },
          {
            missionName: 'As três leis',
            missionDescription:
              'Descrição: No treinamento de astronautas, aprenda sobre os planetas e suas leis.',
            missionVectorNumber: 2,
          },
          {
            missionName: 'Terra e Júpiter',
            missionDescription:
              'Descrição: No treinamento de astronautas, investigue as botas de propulsão.',
            missionVectorNumber: 3,
          },
        ]}
      />
    </>
  );
}

export default HistoryPage;

import React, { useEffect } from "react";
import anime from "animejs/lib/anime.es.js";
import "./xpChart.css";

function XpChart(props){


    useEffect(()=>{
        anime({
            targets: '.physicsXpBar',
            width: ['0', '' + 100*props.physicsXp/props.maxPhysicsXp + '%'],
            duration: 1000,
            easing: 'easeOutQuart'
        });
        anime({
            targets: '#physics-text',
            innerText: {
                value:['Física 0', 'Física ' + props.physicsXp + ' XP']
            },
            round: true,
            easing: 'easeOutQuart',
            duration: 1000
        });
        anime({
            targets: '.chemistryXpBar',
            width: ['0', '' + 100*props.chemistryXp/props.maxChemistryXp + '%'],
            easing: 'easeOutQuart',
            duration: 1000
        });
        anime({
            targets: '#chemistry-text',
            innerText: {
                value:['Química 0', 'Química ' + props.chemistryXp + ' XP']
            },
            round: true,
            easing: 'easeOutQuart',
            duration: 1000
        });
        anime({
            targets: '.biologyXpBar',
            width: ['0', '' + 100*props.biologyXp/props.maxBiologyXp + '%'],
            easing: 'easeOutQuart',
            duration: 1000
        });
        anime({
            targets: '#biology-text',
            innerText: {
                value:['Biologia 0', 'Biologia ' + props.biologyXp + ' XP']
            },
            round: true,
            easing: 'easeOutQuart',
            duration: 1000
        });
    },[]);

    return(
        <div className="xpChart">
            <div><span id='title-text' className="titleXpBar mainFont xpBarText">Pontos de XP</span></div>
            <div className="physicsXpBar"><span id='physics-text' className="secondaryFont xpBarText">Física : {props.physicsXp + ' XP'}</span><span className="xpLevelText mainFont">Nível {props.physicsLevel}</span></div>
            <div className="chemistryXpBar"><span id='chemistry-text' className="secondaryFont xpBarText">Química : {props.chemistryXp + ' XP'}</span><span className="xpLevelText mainFont">Nível {props.chemistryLevel}</span></div>
            <div className="biologyXpBar"><span id='biology-text' className="secondaryFont xpBarText">Física : {props.biologyXp + ' XP'}</span><span className="xpLevelText mainFont">Nível {props.biologyLevel}</span></div>
        </div>

    )
}

export default XpChart;
import React from 'react';
import firebase from 'firebase/app';

interface NamedComp {
  displayName?: string;
}

export function AuthPage<T extends NamedComp & ((...args: any[]) => JSX.Element)>(comp: T) {
  function composedComp(...props: Parameters<T>) {
    if (firebase.auth().currentUser) {
      return comp(...props);
    }
    return <div className="no-auth">Não autenticado</div>;
  }
  composedComp.displayName = comp.displayName;
  return composedComp;
}

import React from 'react';
import RememberVariablesComponent from '../../../../components/MapComponents/rememberVariablesComponent';

function RememberVariables(props) {
  return (
    <RememberVariablesComponent
      paragraph1="Com o planeta pesando 6E24 kg e estando a 316000000 km de distância, calcule o peso da nave, sabendo que a força exercida pelo planeta é de cerca de 801 N."
      paragraph2="Sabendo que a lua pesa 6E22 kg e que esta se encontra a 10000000 km da nave, em um ângulo de 45º da linha que une a nave ao planeta, calcule a força que deve ser exercida nos motores para que a força resultante seja em direção ao planeta Quest."
      paragraph3={null}
      sub={null}
    />
  );
}

export default RememberVariables;

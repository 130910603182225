import React, { useState, useEffect } from 'react';
import { Button, Popup, Icon } from 'semantic-ui-react';
import { MathComponent } from 'mathjax-react';
import Anime, { anime } from 'react-anime';
import torriBike from '../../../../images/torriccelliBike.png';
import torriGirls from '../../../../images/torriccelliGirls.png';
import torriBoard from '../../../../images/torriccelliBoard.png';

// import anime from "animejs/lib/anime.es.js";

function SpaceSpeedTimePage(props) {
  const [spaceSpeedTimeStep, setSpaceSpeedTimeStep] = useState(0);
  const [isMinimized, setIsMinimized] = useState(false);


  useEffect(() => {
    anime({
      targets: '.torriccelliBike',
      translateX: '25vw',
      loop: true,
      easing: 'easeOutSine',
      endDelay: 1000,
    });
  }, [spaceSpeedTimeStep]);

  const standardObj = [
    {
      name: 'Espaço, Velocidade e Tempo',
      spaceSpeedTimeStep: 1,
      comp: (
        <>
          <h1 className="presentationItem">Espaço, Velocidade e Tempo</h1>

          <p className="presentationItem">
            Nos desafios anteriores vimos a relação entre espaço, velocidade e tempo em um movimento
            retilíneo uniformemente variado através da
            <Popup
              trigger={<b className="presentationItem"> equação horária da posição </b>}
              content={<MathComponent tex={String.raw`S = S_{0} + V_{0}*t + \frac{a*t^2}{2}`} />}
              basic
              inverted
            />
            e a relação entre velocidade e tempo para o mesmo caso com a
            <Popup
              trigger={<b className="presentationItem"> equação horária da velocidade. </b>}
              content={<MathComponent tex={String.raw`V = V_{0} + a*t`} />}
              basic
              inverted
            />
          </p>
          <p className="presentationItem">
            Agora imagine a seguinte situação. Você está em sua bibicleta a 10 m/s e precisa parar
            as pressas pois estava distraído e não percebeu os pedestres atravessando na faixa 5
            metros a frente. Para isso você precisará frear e, portanto, deverá encontrar a
            aceleração negativa suficiente para que isso seja feito.
          </p>

          <div className="torriccelliAnimationContainer">
            <img className="torriccelliBike" src={torriBike} alt="torriBike" />
            <div className="distanceContainer mainFont">
              <span>5 m</span>
            </div>
            <img className="torriccelliBoard" src={torriBoard} alt="torriBoard" />
            <img className="torriccelliGirls" src={torriGirls} alt="torriGirls" />
          </div>

          <div className="presentationItem arrowBtnContainer">
            <Button
              icon
              labelPosition="right"
              onClick={() => {
                setSpaceSpeedTimeStep(spaceSpeedTimeStep + 1);
              }}
            >
              Próximo
              <Icon name="right arrow" />
            </Button>
          </div>
        </>
      ),
    },
    {
      name: 'Espaço, Velocidade e Tempo',
      spaceSpeedTimeStep: 2,
      comp: (
        <>
          <h1 className="presentationItem">Espaço, Velocidade e Tempo</h1>
          <p className="presentationItem">
            Note que neste caso <b className="presentationItem">não importa o tempo</b> em que você
            deve parar, apenas que sua{' '}
            <b className="presentationItem">
              distância percorrida para chegar a velocidade zero seja de 5 m
            </b>
            . Para isso, como você aprendeu no desafio anterior, seria utilizada a{' '}
            <b className="presentationItem">equação horária da posição</b> da seguinte maneira:
            <br></br>
            <br></br>
            <MathComponent tex={String.raw`5 = 0 + 10*t - \frac{a*t^2}{2}`} />
          </p>

          <p className="presentationItem">
            O problema é que você{' '}
            <b className="presentationItem">
              precisaria também do tempo para encontrar a aceleração
            </b>
            . Dessa forma, vpcê só resolveria este problema construindo um{' '}
            <b className="presentationItem">sistema de equações</b> adicionando também a{' '}
            <b className="presentationItem">equação da velocidade</b> :<br></br>
            <br></br>
            <MathComponent tex={String.raw`0 = 10 + a*t`} />
          </p>

          <p className="presentationItem">
            Mas será que não existe um jeito mais simples de resolver tudo isso? Afinal você precisa
            parar rapidamente e está "sem tempo" para fazer longos cálculos.
          </p>

          <div className="presentationItem arrowBtnContainer">
            <Button
              icon
              labelPosition="left"
              onClick={() => {
                setSpaceSpeedTimeStep(spaceSpeedTimeStep - 1);
              }}
            >
              <Icon name="left arrow" />
              Anterior
            </Button>
          </div>
        </>
      ),
    },
  ];

  return (
    <div className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`}>
      {/* <div className="minMaxBar">
        <Icon className="icon" name='caret down' onClick={minimizeBar}/>
        <Icon className="icon" name='caret up' onClick={maximizeBar}/>
      </div> */}
      {!isMinimized && standardObj[spaceSpeedTimeStep].comp}
    </div>
  );
}

export default SpaceSpeedTimePage;

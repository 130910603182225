import firebase from 'firebase/app';
import BaseObserver from '../libs/classUtils/BaseObserver';
import ProfileManager from './ProfileManager';
import scoreManager from './ScoreManager';

class LoginManager extends BaseObserver<Function> {
  constructor() {
    super();
  }

  login(email: string, password: string) {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        scoreManager.user = firebase.auth().currentUser;
        ProfileManager.loadDataFromServer();
        return scoreManager.readFromDatabase();
      });
  }

  enroll(email: string, password: string) {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  }
}

export default new LoginManager();

import React from 'react';
import TipsComponent from '../../../../components/MapComponents/tipsComponent';

import tip1 from '../../../../images/Physics/Gravitation/tip1.PNG';
import tip2 from '../../../../images/Physics/Gravitation/tip2.PNG';
import tip3 from '../../../../images/Physics/Gravitation/tip3.PNG';
import tip5 from '../../../../images/Physics/Gravitation/tip5.PNG';

function Solution(props) {
  return (
    <>
      <TipsComponent
        numberTips={4}
        presentation1="Bem vindo à Academia de Astronautas, um local onde conseguimos reparar algumas arestas em seu aprendizado e dar algumas dicas para você conseguir realizar nossa viagem."
        presentation2="Aqui temos que trabalhar duro, então algumas dicas necessitarão de um esforço seu. Vamos lá!"
        subject="physics"
        typeScoring="gravitation"
        arrayScoring="arrStarGravityAcceleration"
        tips={[
          {
            number: 0,
            image: tip1,
            intro1: 'Aprendendo a utilizar as vestimentas de astronauta, você encontra uma dica.',
            intro2: null,
            icon: 'angle right',
            title: 'Uso do macacão',
            dica1: true,
            dica1HasCondition: false,
            dica1ValidateCondition: null,
            dica1ConditionType: null,
            dica1MessageCondition: null,
            dica1ResponsePt1:
              'Tente descobrir a aceleração da gravidade na superfície do planeta utilizando a fórmula:',
            dica1ResponsePt2: null,
            dica1ResponsePt3: null,
            dica1ResponsePt4: null,
            dica1ResponseEquation1: String.raw`g = \frac{G*m_{planeta}}{d^2}`,
            dica1ResponsePt5: null,
            dica2: false,
          },
          {
            number: 1,
            image: tip2,
            intro1: 'Aprendendo a observar as estrelas você encontra mais uma dica.',
            intro2: null,
            icon: 'angle right',
            title: 'Técnicas de observação',
            dica1: true,
            dica1HasCondition: true,
            dica1ValidateCondition: 'arrStars[1] === true',
            dica1ConditionType: 'mission',
            dica1MessageCondition:
              'Essa dica está bloqueada para você! Para desbloqueá-la, realize a missão "Grandeza Peso".',
            dica1ResponsePt1:
              'Conforme o cálculo da gravidade para determinado planeta, podemos fazer:',
            dica1ResponsePt2: null,
            dica1ResponsePt3: null,
            dica1ResponsePt4: null,
            dica1ResponseEquation1: String.raw`g = \frac{G*m_{planeta}}{d^2}`,
            dica1ResponseEquation2: String.raw`g = \frac{6,67*10^{-11}*6*10^{24}}{6000000^2}`,
            dica1ResponseEquation3: String.raw`g = 11,11 m/s^2`,
            dica1ResponseEquation4: null,
            dica1ResponsePt5:
              "Assim, descobrimos que a gravidade na superfície do planeta é de 11,11 m/s².",
            dica1QuestionTittle:
              '(ENEM) O peso de um corpo é uma grandeza física:',
            dica1QuestionImage: null,
            dica1OptionA:
              'que não varia com o local onde o corpo se encontra;',
            dica1OptionB:
              'cuja unidade é medida em quilograma;',
            dica1OptionC:
              'caracterizada pela quantidade de matéria que o corpo encerra;',
            dica1OptionD:
              'que mede a intensidade da força de reação de apoio;',
            dica1OptionE:
              'cuja intensidade é o produto da massa do corpo pela aceleração da gravidade local;',
            dica1CorrectAnswer: 'e',
            dica1MissionName: 'Grandeza Peso',
            dica1Subject: 'physics',
            dica1TypeScoring: 'gravitation',
            dica1ArrayScoring: 'arrStarGravityAcceleration',
            dica1IndexScoring: 1,
            dica1XPAward: 30,
            dica2: false,
          },
          {
            number: 2,
            image: tip3,
            intro1:
              'Aprendendo sobre os planetas você descobre novos conceitos para as viagens interplanetárias',
            intro2: null,
            icon: 'angle right',
            title: 'Aprendendo sobre planetas',
            dica1: true,
            dica1HasCondition: true,
            dica1ValidateCondition: 'arrStars[2] === true',
            dica1ConditionType: 'mission',
            dica1MessageCondition:
              'Essa dica está bloqueada para você! Para desbloqueá-la, realize a missão "Peso e massa".',
            dica1ResponsePt1:
              'Sabendo qual a gravidade na superfície do planeta, podemos utilizar a fórmula da força peso:',
            dica1ResponsePt2: null,
            dica1ResponsePt3: null,
            dica1ResponsePt4: null,
            dica1ResponseEquation1: String.raw`P = m*g`,
            dica1ResponseEquation2: null,
            dica1ResponseEquation3: null,
            dica1ResponsePt5:
              'Aplique a fórmula com as variáveis que temos para descobrir o peso da nossa nave na superfície do planeta.',
            dica1QuestionTittle:
              '(Unitins-TO) Assinale a proposição correta:',
            dica1OptionA: 'a massa de um corpo na Terra é menor do que na Lua;',
            dica1OptionB:
              'o peso mede a inércia de um corpo;',
            dica1OptionC: 'Peso e massa são sinônimos;',
            dica1OptionD: 'A massa de um corpo na Terra é maior do que na Lua;',
            dica1OptionE: 'O sistema de propulsão a jato funciona baseado no princípio da ação e reação;',
            dica1CorrectAnswer: 'e',
            dica1MissionName: 'Peso e massa',
            dica1Subject: 'physics',
            dica1TypeScoring: 'gravitation',
            dica1ArrayScoring: 'arrStarGravityAcceleration',
            dica1IndexScoring: 2,
            dica1XPAward: 30,
            dica2: false,
          },
          {
            number: 3,
            image: tip5,
            intro1: 'Em seu caderno de anotações você se relembra de alguns conceitos.',
            intro2: null,
            icon: 'angle right',
            title: 'Caderno de anotações',
            dica1: true,
            dica1HasCondition: true,
            dica1ValidateCondition: 'arrStars[3] === true',
            dica1ConditionType: 'mission',
            dica1MessageCondition:
              'Essa dica está bloqueada para você! Para desbloqueá-la, realize a missão "Gravidade em Marte".',
            dica1ResponsePt1:
              'Sabemos que a massa de nossa nave é da ordem de 3000000 kg e, conforme as dicas anteriores, a gravidade no planeta é de 11,11 m/s². Assim, podemos fazer:',
            dica1ResponsePt2: null,
            dica1ResponsePt3: null,
            dica1ResponsePt4: null,
            dica1ResponseEquation1: String.raw`P = m*g`,
            dica1ResponseEquation2: String.raw`P = 3000000*11,11`,
            dica1ResponseEquation3: String.raw`P = 33000000 N`,
            dica1ResponseEquation4: null,
            dica1ResponsePt5:
              'Logo, precisamos acionar os motores com uma força de 33 M Newtons para realizar o pouso com segurança.',
            dica1QuestionTittle:
              '(UDESC) A aceleração da gravidade na superfície do planeta Marte é aproximadamente 4,0 m/s2. Calcule a que altura da superfície da Terra deve estar uma pessoa com massa de 100,0 kg, para ter o mesmo peso que teria na superfície de Marte.',
            dica1OptionA: '1,0 x 107 m;',
            dica1OptionB: '3,6 x 106 m;',
            dica1OptionC: '4,0 x 1014 m;',
            dica1OptionD: '6,4 x 106 m;',
            dica1OptionE: '1,36 x 107 m;',
            dica1CorrectAnswer: 'b',
            dica1MissionName: 'Gravidade em Marte',
            dica1Subject: 'physics',
            dica1TypeScoring: 'gravitation',
            dica1ArrayScoring: 'arrStarGravityAcceleration',
            dica1IndexScoring: 3,
            dica1XPAward: 30,
            dica2: false,
          },
        ]}
      />
    </>
  );
}

export default Solution;

import * as BABYLON from '@babylonjs/core';
import gameManager from '../../../../managers/GameManager';

const DELTA_TIME = 1 / 60;
const TOTAL_DIST = 1995; // voltar para 2000?
const CORRECT_ACCELERATION = 8.1;
const CORRECT_DIST = 270;
const RANGE_DIST = 10;

function Loop(props) {
  let scene = props.scene;
  if (scene.isReady() && gameManager.objSpecificGame.physics.kinematics.isLoading === 'true') {
    gameManager.updateInfo('physics', 'kinematics', 'isLoading', 'false');
    gameManager.updateInfo('physics', 'kinematics', 'missionMessage', 'Correndo com Tubarões');
    gameManager.updateInfo('physics', 'kinematics', 'missionIndexScoring', '3');
    gameManager.updateInfo('physics', 'kinematics', 'missionXpAward', '30');
  }

  playSounds(scene);
  // scene.soundRaceMusic.play();
  scene.running = Number(gameManager.objSpecificGame.physics.kinematics.running);
  scene.restart = Number(gameManager.objSpecificGame.physics.kinematics.restart);
  scene.countStart = 0;

  if (scene.running !== undefined && scene.countStart !== undefined) {
    simulationFlow(scene);
    restartFlow(scene);
  }
}

export default Loop;

/**
 * Função que reinicia a corrida
 */
function restartFlow(scene) {
  if (scene.restart === 3) {
    gameManager.updateInfo('physics', 'kinematics', 'running', '0');
    gameManager.updateInfo('physics', 'kinematics', 'restart', '0');

    gameManager.updateInfo('physics', 'kinematics', 'showMessage', 'false');
    gameManager.updateInfo('physics', 'kinematics', 'showSlider', 'false');
    gameManager.updateInfo('physics', 'kinematics', 'showButtons', 'false');
    gameManager.updateInfo('physics', 'kinematics', 'showFinalPanel', 'false');

    scene.responses = [0, 0];

    scene.countStart = 0;
    scene.time = 0;
    (scene.cars || []).forEach((car) => {
      car.explode = false;
    });
  }
  if (scene.restart <= 2 && scene.restart > 0) {
    gameManager.updateInfo('physics', 'kinematics', 'running', '1');
    gameManager.updateInfo('physics', 'kinematics', 'restart', '' + Number(scene.restart + 1));

    scene.time = 0;

    (scene.cars || []).forEach((car) => {
      car.explode = false;
      car.dist = car.initDist;
      car.position.x = car.initPosition.x;
      car.position.y = 0;
      car.position.z = car.initPosition.z;
      car.rotation = new BABYLON.Vector3(0, -Math.PI / 2, 0);
      car.vel = car.initVel;
      car.acc = car.initAcc;
      car.track = car.initTrack;
      car.desiredTrack = car.track;
      car.active = true;
      if (car.isStudent) scene.cameraOrientation = car.rotation.y;
    });
  }
}

/**
 * Função que determina o fluxo das escolhas do aluno na corrida
 */
function simulationFlow(scene) {
  updateClassificationTable(scene);
  if (scene.running === 1 && scene.countStart >= 0) {
    updateQuests(scene);
    updateFinalPanel(scene);
    if (scene.running === 1 || scene.restart !== 0) {
      scene.time += DELTA_TIME;
      carsMovement(scene);
      carsMovement(scene);
      cameraMovement(scene);
      createExplosion(scene);

      rotateRodas(
        (scene.rodas[0].rotation.y -
          5 * scene.cars.find((e) => e.isStudent).vel * DELTA_TIME * 0.2) %
          (2 * Math.PI),
        scene
      );
    }
  }
}

/**
 * Função para criar uma explosão durante a corrida
 */
function createExplosion(scene) {
  let car = scene.cars.find((e) => e.isStudent);
  if (car.explode && car.active && car.vel < 3) {
    BABYLON.ParticleHelper.CreateAsync('explosion', scene).then((set) => {
      var pos = new BABYLON.Vector3(car.position.x, car.position.y, car.position.z);
      set.systems.forEach((s) => {
        s.emitter = pos.clone();
        s.disposeOnStop = true;
      });
      set.systems[2].worldOffset.y = pos.y;
      set.start();
    });
    car.active = false;
  }
}

/**
 * Função para rotacionar as rodas de todos os carros
 */
function rotateRodas(value, scene) {
  scene.rodas.forEach((e) => {
    e.rotation.y = value;
  });
}

/**
 * Função para a movimentação da camera
 */
function cameraMovement(scene) {
  let car = scene.cars.find((e) => e.isStudent);
  if (car.isStudent) {
    if (
      car.rotation.y < 0 &&
      scene.cameraOrientation > 0 &&
      Math.abs(car.rotation.y - scene.cameraOrientation) > 2
    ) {
      scene.cameraOrientation -= 2 * Math.PI;
    }
    if (
      car.rotation.y > 0 &&
      scene.cameraOrientation < 0 &&
      Math.abs(car.rotation.y - scene.cameraOrientation) > 2
    ) {
      scene.cameraOrientation += 2 * Math.PI;
    }
    scene.cameraOrientation += 0.05 * (car.rotation.y - scene.cameraOrientation);
    scene.camera.position = new BABYLON.Vector3(
      car.position.x + 10 * Math.cos(scene.cameraOrientation),
      car.position.y + 5,
      car.position.z + 10 * Math.sin(scene.cameraOrientation)
    );
    scene.camera.setTarget(
      new BABYLON.Vector3(
        car.position.x + 2.5 * Math.cos(scene.cameraOrientation),
        car.position.y + 0.9,
        car.position.z - 2.5 * Math.sin(scene.cameraOrientation)
      )
    );
  }
}

/**
 * Função responsável para a movimentação de todos os carros do desafio
 */
function carsMovement(scene) {
  (scene.cars || []).forEach((car, indexCar) => {
    if (car.dist > TOTAL_DIST + 100) scene.running = 0;

    if (car.dist < TOTAL_DIST / 4) {
      car.acc = car.isStudent
        ? car.explode
          ? -20
          : scene.responses[0]
        : CORRECT_ACCELERATION - 0.1;
    } else {
      if (car.isStudent) {
        car.acc = TOTAL_DIST - car.dist <= scene.responses[1] ? -15 : 0;
      } else {
        if (scene.responses[1] > CORRECT_DIST + RANGE_DIST) {
          car.acc = TOTAL_DIST - car.dist <= 202.5 ? -20 : 0;
        } else if (scene.responses[1] < CORRECT_DIST - RANGE_DIST) {
          car.acc = TOTAL_DIST - car.dist <= 202.5 ? -20 : 0;
        } else {
          car.acc = TOTAL_DIST - car.dist <= 150 ? -20 : 0;
        }
      }
    }
    if (car.dist > TOTAL_DIST + RANGE_DIST) {
      car.position.y = -0.005 * Math.pow(car.dist - (TOTAL_DIST + RANGE_DIST), 2);
      car.acc = 0;
    }

    car.vel += (car.acc * DELTA_TIME) / 2;
    car.vel = Math.max(car.vel, 0.00001);
    car.dist += (car.vel * DELTA_TIME) / 2;

    if (car.acc > CORRECT_ACCELERATION + 0.15 && car.dist > TOTAL_DIST / 4 - 250)
      car.explode = true;

    car.position.z = car.dist;
  });
}

/////////////////////////////// Métodos para atualização GUI ///////////////////////////////

function updateFinalPanel(scene) {
  let car = scene.cars.find((e) => e.isStudent);

  let finish = null;
  let win;
  if (car.dist > TOTAL_DIST / 4 && car.vel < 321 / 3.6 && scene.responses[1] === 0) {
    finish = 'Que pena, o seu carro não foi capaz de chegar com velocidade maior que o adversário.';
    win = 'false';
  }
  if (car.explode && car.vel < 1) {
    finish = 'Que pena, você acelerou demais o seu carro e danificou o motor!';
    win = 'false';
  }
  if (car.dist > TOTAL_DIST + RANGE_DIST) {
    finish = 'Que pena, você não conseguiu frear o carro e caiu no mar!';
    win = 'false';
  }
  if (car.dist > TOTAL_DIST / 2 && car.vel < 1) {
    if (car.dist < TOTAL_DIST - RANGE_DIST) {
      finish =
        'Que pena, você freou o carro muito cedo e não conseguiu chegar no fim da pista antes que o adversário!';
      win = 'false';
    } else {
      finish = 'Parabéns! Você fez uma corrida perfeita e ganhou o campeonato!';
      win = 'true';
    }
  }

  if (finish) {
    gameManager.updateInfo('physics', 'kinematics', 'showFinalPanel', 'true');
    gameManager.updateInfo('physics', 'kinematics', 'finalMessage', finish);
    gameManager.updateInfo('physics', 'kinematics', 'win', win);
    scene.running = 0;
  }
}

function updateClassificationTable(scene) {
  let listCars = [
    { name: 'Arara Real' },
    { name: 'Jacaré Impiedoso' },
    { name: 'Boto Veloz' },
    { name: 'Falcão Negro' },
    { name: 'Guará Rubro' },
    { name: 'Capivara Furiosa' },
  ];

  let dists = listCars.map((car) => {
    let foundCar = scene.cars.find((e) => e.equipe === car.name);
    return foundCar && foundCar.equipe
      ? foundCar.dist > TOTAL_DIST + RANGE_DIST
        ? foundCar.dist - TOTAL_DIST / 2
        : foundCar.dist
      : -1;
  });

  gameManager.updateInfo('physics', 'kinematics', 'classification', dists);
}

/**
 * Primeira pergunta
 */
function updateQuests(scene) {
  if (scene.responses[0] === 0) {
    gameManager.updateInfo('physics', 'kinematics', 'showMessage', 'true');
    gameManager.updateInfo('physics', 'kinematics', 'showSlider', 'true');
    gameManager.updateInfo(
      'physics',
      'kinematics',
      'message',
      'Qual a aceleração do carro para chegar no radar com maior velocidade?'
    );
    if (
      Number(gameManager.objSpecificGame.physics.kinematics.responseValue) > 0 &&
      Number(gameManager.objSpecificGame.physics.kinematics.responseValue) < 15
    ) {
      scene.responses[0] = Number(gameManager.objSpecificGame.physics.kinematics.responseValue);
      gameManager.updateInfo('physics', 'kinematics', 'showMessage', 'false');
      gameManager.updateInfo('physics', 'kinematics', 'showSlider', 'false');
      gameManager.updateInfo('physics', 'kinematics', 'responseValue', '0');
    } else {
      scene.running = 0;
    }
  } else {
    let car = scene.cars.find((e) => e.isStudent);
    if (car.dist > 1100 && scene.responses[1] === 0) {
      gameManager.updateInfo('physics', 'kinematics', 'showMessage', 'true');
      gameManager.updateInfo('physics', 'kinematics', 'showSlider', 'true');
      gameManager.updateInfo(
        'physics',
        'kinematics',
        'message',
        'Em qual distância do fim o carro tem que começar a frear para ganhar a corrida?'
      );
      if (
        Number(gameManager.objSpecificGame.physics.kinematics.responseValue) > 0 &&
        Number(gameManager.objSpecificGame.physics.kinematics.responseValue) < 500
      ) {
        scene.responses[1] = Number(gameManager.objSpecificGame.physics.kinematics.responseValue);
        gameManager.updateInfo('physics', 'kinematics', 'showMessage', 'false');
        gameManager.updateInfo('physics', 'kinematics', 'showSlider', 'false');
        gameManager.updateInfo('physics', 'kinematics', 'responseValue', '0');
      } else {
        scene.running = 0;
      }
    }
  }
}

function playSounds(scene) {
  if (Number(gameManager.objSpecificGame.physics.kinematics.running) === 1 && scene.running === 0) {
    if (!scene.soundRaceMusic.isPlaying) {
      scene.soundRaceMusic.play();
    }
  } else if (
    Number(gameManager.objSpecificGame.physics.kinematics.running) === 0 &&
    scene.running === 1
  ) {
    scene.soundRaceMusic.stop();
  }
}

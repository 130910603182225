import * as BABYLON from '@babylonjs/core';
import gameManager from '../../../../managers/GameManager';

const DELTA_TIME = 1 / 60;
const HALF_DEGREE = Math.PI / 180 / 2;
const TOTAL_DIST = 1995; // voltar para 2000?
const CORRECT_ACCELERATION = 8.1;
const CORRECT_DIST = 270;
const RANGE_DIST = 10;
var greenLight = false;

var auxPosXToMoon = 0;
var auxPosZToMoon = 0;

function Loop(props) {
  let scene = props.scene;
  if (scene.isReady() && gameManager.objSpecificGame.physics.gravitation.isLoading === 'true') {
    gameManager.updateInfo('physics', 'gravitation', 'isLoading', 'false');
    gameManager.updateInfo('physics', 'gravitation', 'missionMessage', 'Saindo do Planeta');
    gameManager.updateInfo('physics', 'gravitation', 'missionIndexScoring', '4');
    gameManager.updateInfo('physics', 'gravitation', 'missionXpAward', '30');
  }

  // playSounds(scene);
  // scene.soundRaceMusic.play();
  scene.running = Number(gameManager.objSpecificGame.physics.gravitation.running);
  scene.restart = Number(gameManager.objSpecificGame.physics.gravitation.restart);
  scene.countStart = 0;

  if (scene.running !== undefined && scene.countStart !== undefined) {
    simulationFlow(scene);
    restartFlow(scene);
  }
}

export default Loop;

/**
 * Função que reinicia a corrida
 */
function restartFlow(scene) {
  if (scene.restart === 3) {
    gameManager.updateInfo('physics', 'gravitation', 'running', '0');
    gameManager.updateInfo('physics', 'gravitation', 'restart', '0');

    gameManager.updateInfo('physics', 'gravitation', 'showMessage', 'false');
    gameManager.updateInfo('physics', 'gravitation', 'showSlider', 'false');
    gameManager.updateInfo('physics', 'gravitation', 'showButtons', 'false');
    gameManager.updateInfo('physics', 'gravitation', 'showFinalPanel', 'false');

    scene.responses = [0, 0];

    scene.countStart = 0;
    scene.time = 0;
  }
  if (scene.restart <= 2 && scene.restart > 0) {
    gameManager.updateInfo('physics', 'gravitation', 'running', '1');
    gameManager.updateInfo('physics', 'gravitation', 'restart', '' + Number(scene.restart + 1));
    gameManager.updateInfo('physics', 'gravitation', 'responseValue', '0');
    greenLight = false;
    scene.time = 0;

    (scene.planets || []).forEach((planet) => {
      planet.position = planet.initPosition;
      planet.angle = planet.initAngle;
      planet.period = planet.initPeriod;
    });
  }
}

/**
 * Função que determina o fluxo das escolhas do aluno na corrida
 */
function simulationFlow(scene) {
  if (scene.running === 1 && scene.countStart >= 0) {
    updateQuests(scene);
    updateFinalPanel(scene);
    if (scene.running === 1 || scene.restart !== 0) {
      scene.time += DELTA_TIME;
      planetsMovement(scene);
      // cameraMovement(scene);
    }
  }
}


function planetsMovement(scene) {
  (scene.planets || []).forEach((planet, indexPlanet) => {
    planet.angle += HALF_DEGREE / (planet.period * DELTA_TIME);
    planet.position.x = (planet.dist * Math.cos(planet.angle));
    planet.position.z = (planet.dist * Math.sin(planet.angle));
    planet.rotation.y -= 4 * Math.PI / planet.period;

    if (planet.hasMoon) {
      auxPosXToMoon = planet.position.x;
      auxPosZToMoon = planet.position.z;
    }
    if (planet.isMoon) {
      planet.position.x += auxPosXToMoon;
      planet.position.z += auxPosZToMoon;
    }

    if (planet.isStarting) {
      if (planet.angle >= Math.PI) {
        let rocketVel;
        let responseValue = Number(gameManager.objSpecificGame.physics.gravitation.responseValue);
        if (responseValue > 112) {
          rocketVel = ((responseValue + 20) * DELTA_TIME) / 10;
        } else if (responseValue < 112 && responseValue > 50) {
          rocketVel = ((responseValue - 10) * DELTA_TIME) / 10;
        } else {
          rocketVel = (responseValue * DELTA_TIME) / 10;
        }
        // (Number(gameManager.objSpecificGame.physics.gravitation.responseValue) * DELTA_TIME) / 10;
        scene.rocket.position.x -= 1 * rocketVel;
        scene.camera.setTarget(
          new BABYLON.Vector3(
            scene.rocket.position.x,
            scene.rocket.position.y,
            scene.rocket.position.z
          )
        );
        startGreenLight(scene);
        // rocketMovement();
      } else {
        scene.rocket.position.x = planet.position.x;
        scene.rocket.position.y = planet.position.y;
        scene.rocket.position.z = planet.position.z;
        scene.camera.setTarget(
          new BABYLON.Vector3(planet.position.x, planet.position.y, planet.position.z)
        );
        // startGreenLight(scene);
      }
    }
  });
}

/////////////////////////////// Métodos para atualização GUI ///////////////////////////////

function updateFinalPanel(scene) {
  // let car = scene.cars.find((e) => e.isStudent);

  let finish = null;
  let win;

  if (scene.rocket.position.x <= -200) {
    if (Number(gameManager.objSpecificGame.physics.gravitation.responseValue) === 112) {
      finish =
        'Parabéns! Você ajudou no lançamento do foguete com a velocidade correta! Estamos chegando planeta Quest!';
      win = 'true';
    } else if (
      Number(gameManager.objSpecificGame.physics.gravitation.responseValue) > 112 &&
      scene.rocket.position.x <= -230
    ) {
      finish = 'Que pena, fomos muito rápidos e não conseguimos chegar no planeta Quest';
      win = 'false';
    } else if (
      Number(gameManager.objSpecificGame.physics.gravitation.responseValue) < 112 &&
      scene.rocket.position.x <= -230
    ) {
      finish = 'Que pena, fomos muito lentos e não conseguimos chegar no planeta Quest';
      win = 'false';
    }
  }

  if (finish) {
    gameManager.updateInfo('physics', 'gravitation', 'showFinalPanel', 'true');
    gameManager.updateInfo('physics', 'gravitation', 'finalMessage', finish);
    gameManager.updateInfo('physics', 'gravitation', 'win', win);
    scene.running = 0;
  }
}

/**
 * Atualiza a pergunta
 */
function updateQuests(scene) {
  let planetPosition = scene.planets.filter(planet => planet.isStarting)[0].position.x;
  if (planetPosition <= -40) {
  // if (scene.planets[2].angle >= (1.5 * Math.PI) / 4) {
    
    if (scene.responses[0] === 0) {
      gameManager.updateInfo('physics', 'gravitation', 'showMessage', 'true');
      gameManager.updateInfo('physics', 'gravitation', 'showSlider', 'true');
      gameManager.updateInfo(
        'physics',
        'gravitation',
        'message',
        'Qual a velocidade (em mil km/h) de lançamento do foguete para alcançar o planeta Quest nos 40 dias de viagem?'
      );
      if (
        Number(gameManager.objSpecificGame.physics.gravitation.responseValue) > 0 &&
        Number(gameManager.objSpecificGame.physics.gravitation.responseValue) < 200
      ) {
        scene.responses[0] = Number(gameManager.objSpecificGame.physics.gravitation.responseValue);
        gameManager.updateInfo('physics', 'gravitation', 'showMessage', 'false');
        gameManager.updateInfo('physics', 'gravitation', 'showSlider', 'false');
        // gameManager.updateInfo('physics', 'gravitation', 'responseValue', '0');
      } else {
        scene.running = 0;
      }
    }
  }
}

function playSounds(scene) {
  if (Number(gameManager.objSpecificGame.physics.kinematics.running) === 1 && scene.running === 0) {
    if (!scene.soundRaceMusic.isPlaying) {
      scene.soundRaceMusic.play();
    }
  } else if (
    Number(gameManager.objSpecificGame.physics.kinematics.running) === 0 &&
    scene.running === 1
  ) {
    scene.soundRaceMusic.stop();
  }
}

function startGreenLight(scene) {
  if (!greenLight) {
    BABYLON.Effect.ShadersStore['customVertexShader'] =
      '\r\n' +
      'precision highp float;\r\n' +
      '// Attributes\r\n' +
      'attribute vec3 position;\r\n' +
      'attribute vec3 normal;\r\n' +
      '// Uniforms\r\n' +
      'uniform mat4 worldViewProjection;\r\n' +
      'uniform float time;\r\n' +
      'void main(void) {\r\n' +
      '    vec3 p = position;\r\n' +
      '    vec3 j = vec3(0., -100.0, 0.);\r\n' +
      '    p = p + normal * log2(1. + time) * 3.0;\r\n' +
      '    gl_Position = worldViewProjection * vec4(p, 1.0);\r\n' +
      '}\r\n';

    BABYLON.Effect.ShadersStore['customFragmentShader'] =
      '\r\n' +
      'precision highp float;\r\n' +
      'uniform float time;\r\n' +
      'void main(void) {\r\n' +
      '    gl_FragColor = vec4(0. , 1. , 0., 1. );\r\n' +
      '}\r\n';

    var shaderMaterial = new BABYLON.ShaderMaterial(
      'shader',
      scene,
      {
        vertex: 'custom',
        fragment: 'custom',
      },
      {
        attributes: ['position', 'normal', 'uv'],
        uniforms: ['world', 'worldView', 'worldViewProjection', 'view', 'projection'],
        needAlphaBlending: true,
      }
    );

    shaderMaterial.backFaceCulling = false;

    var sphere = BABYLON.MeshBuilder.CreateSphere('sphere', { diameter: 3 }, scene);
    sphere.position = new BABYLON.Vector3(
      scene.rocket.position.x,
      scene.rocket.position.y,
      scene.rocket.position.z
    );
    sphere.convertToFlatShadedMesh();

    sphere.material = shaderMaterial;

    var t = 0;
    var time = 0;
    scene.registerBeforeRender(function () {
      if (time < 8) {
        sphere.material.setFloat('time', time);
        time += 0.1;
      } else {
        sphere.dispose();
      }
    });

    greenLight = true;
  }
}

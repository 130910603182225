import React from 'react';
import MissionComponent from '../../../../components/MapComponents/missionComponent';

function HistoryPage(props) {
  return (
    <>
      <MissionComponent
        numberMissions={4}
        subject="physics"
        typeScoring="gravitation"
        arrayScoring="arrStarLawOfUniversalGravitation"
        missionName="Desviando da lua"
        missionDescription="Descrição: Na Viagem, realize o desafio proposto, desviando da força gravitacional exercida pela lua do planeta Quest."
        missionVectorNumber={3}
        submission={[
          {
            missionName: 'A Lei da Gravitação Universal',
            missionDescription: 'Descrição: Investigue o Centro de Pesquisas.',
            missionVectorNumber: 0,
          },
          {
            missionName: 'Terra e Saturno',
            missionDescription:
              'Descrição: No treinamento de astronautas, pesquise sobre a utilização do capacete espacial.',
            missionVectorNumber: 1,
          },
          {
            missionName: 'As Marés',
            missionDescription:
              'Descrição: No treinamento de astronautas, pesquise sobre a utilização da antena de comunicação.',
            missionVectorNumber: 2,
          },
        ]}
      />
    </>
  );
}

export default HistoryPage;

import * as BABYLON from '@babylonjs/core';
import '@babylonjs/loaders';
import '@babylonjs/core/Meshes/thinInstanceMesh';

function Scenario(scene) {

  var light = new BABYLON.HemisphericLight('hemiLight', new BABYLON.Vector3(-1, 1, 0), scene);
  // light.diffuse = new BABYLON.Color3(1, 0, 0);

  // Skybox
  var skybox = BABYLON.MeshBuilder.CreateBox('skyBox', { size: 10000.0 }, scene);
  var skyboxMaterial = new BABYLON.StandardMaterial('skyBox', scene);
  skyboxMaterial.backFaceCulling = false;
  skyboxMaterial.reflectionTexture = new BABYLON.CubeTexture(
    './scenes/Física/Gravitação/skybox/nebula',
    scene
  );
  skyboxMaterial.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE;
  skyboxMaterial.diffuseColor = new BABYLON.Color3(0, 0, 0);
  skyboxMaterial.specularColor = new BABYLON.Color3(0, 0, 0);
  skybox.material = skyboxMaterial;
  skybox.position = new BABYLON.Vector3(0, 0, 0);

  // Orbit Material
  var orbitMaterial = new BABYLON.StandardMaterial('orbitMaterial', scene);
  orbitMaterial.diffuseColor = new BABYLON.Color3.Gray();
  orbitMaterial.specularColor = new BABYLON.Color3.Gray();
  orbitMaterial.emissiveColor = new BABYLON.Color3.Gray();
  orbitMaterial.ambientColor = new BABYLON.Color3.Gray();

  // Orbit Lines
  const torus1 = BABYLON.Mesh.CreateTorus('torus1', 80, 0.1, 256, scene);
  torus1.material = orbitMaterial;
  const torus2 = BABYLON.Mesh.CreateTorus('torus2', 200, 0.1, 256, scene);
  torus2.material = orbitMaterial;
  const torus3 = BABYLON.Mesh.CreateTorus('torus3', 416, 0.1, 256, scene);
  torus3.material = orbitMaterial;
  const torus4 = BABYLON.Mesh.CreateTorus('torus4', 600, 0.1, 256, scene);
  torus4.material = orbitMaterial;
}

export default Scenario;

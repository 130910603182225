import * as BABYLON from '@babylonjs/core';
import { A } from '@svgdotjs/svg.js';
import gameManager from '../../../../managers/GameManager';

const DELTA_TIME = 1 / 60;
const TOTAL_TIME = 3 * 60;
const ROCKET_INIT_POSITION = new BABYLON.Vector3(0, 5, 0);
const ASTRONAUT_ROCKET_START_SCALING = 0.01;
const EXAUST_ROCKET_START_SCALING = 0.01;
const EXAUST_ROCKET_INIT_POSITION = new BABYLON.Vector3(0, 0, 0);
const CAMERA_INIT_POSITION = new BABYLON.Vector3(-120, 15, 20);

let isAnswerCorrect = null;
let rocketOriented = false;
let simulationVel = 1;
let camMoveTax = 1;
gameManager.updateInfo('physics', 'gravitation', 'responseValue', 'Sem valor');

const HALF_DEGREE = Math.PI / 180 / 2;

function Loop(props) {
  let scene = props.scene;
  if (scene.isReady() && gameManager.objSpecificGame.physics.gravitation.isLoading === 'true') {
    gameManager.updateInfo('physics', 'gravitation', 'isLoading', 'false');
    gameManager.updateInfo('physics', 'gravitation', 'timeCount', '3:59');
    gameManager.updateInfo('physics', 'gravitation', 'missionMessage', 'Lançando o satélite');
    gameManager.updateInfo('physics', 'gravitation', 'missionIndexScoring', '4');
    gameManager.updateInfo('physics', 'gravitation', 'missionXpAward', '30');
  }
  // playSounds(scene);
  scene.running = Number(gameManager.objSpecificGame.physics.gravitation.running);
  scene.restart = Number(gameManager.objSpecificGame.physics.gravitation.restart);
  scene.countStart = 0;

  if (scene.running !== undefined && scene.countStart !== undefined) {
    simulationFlow(scene);
    restartFlow(scene);
  }
}

export default Loop;

/**
 * Função que reinicia o desafio
 */
function restartFlow(scene) {
  let GUI = scene.GUI;
  if (scene.restart === 3) {
    gameManager.updateInfo('physics', 'gravitation', 'running', '0');
    gameManager.updateInfo('physics', 'gravitation', 'restart', '0');

    gameManager.updateInfo('physics', 'gravitation', 'showMessage', 'false');
    gameManager.updateInfo('physics', 'gravitation', 'showSlider', 'false');
    gameManager.updateInfo('physics', 'gravitation', 'showButtons', 'false');
    gameManager.updateInfo('physics', 'gravitation', 'showFinalPanel', 'false');

    scene.responses = ['Sem resposta', 'Sem resposta'];
    scene.countStart = 0;
    scene.timeLeft = TOTAL_TIME;
    isAnswerCorrect = null;
    rocketOriented = false;
    isAnswerCorrect = null;
    rocketOriented = false;
    scene.rocket.rotation.x = 0;
    scene.astronaut.direction = 'up';
    gameManager.updateInfo('physics', 'gravitation', 'responseValue', 'Sem valor');

  }
  if (scene.restart <= 2 && scene.restart > 0) {
    gameManager.updateInfo('physics', 'gravitation', 'running', '1');
    gameManager.updateInfo('physics', 'gravitation', 'restart', '' + Number(scene.restart + 1));
    gameManager.updateInfo('physics', 'gravitation', 'responseValue', 'Sem valor');

    scene.timeLeft = TOTAL_TIME;
    scene.rocket.position.x = ROCKET_INIT_POSITION.x;
    scene.rocket.position.y = ROCKET_INIT_POSITION.y;
    scene.rocket.position.z = ROCKET_INIT_POSITION.z;

    scene.exaustRocket.position.x = EXAUST_ROCKET_INIT_POSITION.x;
    scene.exaustRocket.position.y = EXAUST_ROCKET_INIT_POSITION.y;
    scene.exaustRocket.position.z = EXAUST_ROCKET_INIT_POSITION.z;
    scene.exaustRocket.scaling.y = EXAUST_ROCKET_START_SCALING;

    scene.satellite.position.x = CAMERA_INIT_POSITION.x;
    scene.satellite.position.y = CAMERA_INIT_POSITION.y;
    scene.satellite.position.z = CAMERA_INIT_POSITION.z;

    scene.astronaut.scaling.x = ASTRONAUT_ROCKET_START_SCALING;
    scene.astronaut.scaling.y = ASTRONAUT_ROCKET_START_SCALING;
    scene.astronaut.scaling.z = ASTRONAUT_ROCKET_START_SCALING;

    scene.camera.position.x = CAMERA_INIT_POSITION.x;
    scene.camera.position.y = CAMERA_INIT_POSITION.y;
    scene.camera.position.z = CAMERA_INIT_POSITION.z;
  }
}

/**
 * Função que determina o fluxo das escolhas do aluno no desafio
 */
function simulationFlow(scene) {
  if (scene.running === 1 && scene.countStart >= 0) {
    updateQuests(scene);
    updateFinalPanel(scene);
    if (scene.running === 1 || scene.restart !== 0) {
      scene.timeLeft = scene.timeLeft > 0 ? scene.timeLeft - DELTA_TIME : 0;
      rocketMovement(scene);

      updateTimePanel(scene);

    }
  }
}

/**
 * Atualiza a pergunta
 */
function updateQuests(scene) {
  if (scene.rocket.position.y === ROCKET_INIT_POSITION.y && !rocketOriented) {
    if (scene.responses[0] === 'Sem resposta') {
      gameManager.updateInfo('physics', 'gravitation', 'showMessage', 'true');
      gameManager.updateInfo('physics', 'gravitation', 'showSlider', 'true');
      gameManager.updateInfo(
        'physics',
        'gravitation',
        'message',
        'Selecione o ângulo de lançamento de nosso foguete'
      );
      if (
        Number(gameManager.objSpecificGame.physics.gravitation.responseValue) >= -45 &&
        Number(gameManager.objSpecificGame.physics.gravitation.responseValue) <= 45
      ) {
        scene.responses[0] = Number(gameManager.objSpecificGame.physics.gravitation.responseValue);
        gameManager.updateInfo('physics', 'gravitation', 'showSlider', 'false');
        gameManager.updateInfo('physics', 'gravitation', 'responseValue', 'Sem resposta');
        gameManager.updateInfo(
          'physics',
          'gravitation',
          'message',
          'Orientando o foguete...'
        );
      }

    }
  }

  if (rocketOriented) {
    if (scene.responses[1] === 'Sem resposta') {
      gameManager.updateInfo('physics', 'gravitation', 'showMessage', 'true');
      gameManager.updateInfo('physics', 'gravitation', 'showSlider', 'true');
      gameManager.updateInfo(
        'physics',
        'gravitation',
        'message',
        'Selecione a velocidade de lançamento de nosso foguete'
      );
      if (
        Number(gameManager.objSpecificGame.physics.gravitation.responseValue) > 0 &&
        Number(gameManager.objSpecificGame.physics.gravitation.responseValue) <= 50
      ) {
        scene.responses[1] = Number(gameManager.objSpecificGame.physics.gravitation.responseValue);
        gameManager.updateInfo('physics', 'gravitation', 'showSlider', 'false');
        gameManager.updateInfo(
          'physics',
          'gravitation',
          'message',
          'Lançando o foguete...'
        );
      } else {
        scene.running = 0;
      }

    }
  }

  if ((scene.rocket.position.y >= 600 && scene.astronaut.direction === 'up') 
  || (scene.astronaut.position.y <= 2570 && scene.astronaut.direction === 'down')){
    let message = null;
    gameManager.updateInfo('physics', 'gravitation', 'showMessage', 'false');
    gameManager.updateInfo(
      'physics',
      'gravitation',
      'message',
      message
    );
  }

  if (scene.astronaut.position.y >= 2580 && scene.astronaut.direction === 'down') {
    gameManager.updateInfo('physics', 'gravitation', 'showMessage', 'true');
    gameManager.updateInfo(
      'physics',
      'gravitation',
      'message',
      'Nosso astronauta foi lançado em direção ao satélite...'
    );
  }

    if(scene.responses[0] === -17 && scene.responses[1] === 11.5) isAnswerCorrect = true;
  

}


/**
 * Função responsável para a movimentação do foguete do desafio
 */
function rocketMovement(scene) {
  if (!rocketOriented) {
    guideRocket(scene);
  } else {
    flyRocket(scene);

  }
  startEngine(scene);
  moveAstronaut(scene);
  orbitSatellite(scene);
}


/**
 * Função para atualizar os dados do painel final
 */
function updateFinalPanel(scene) {

  let finish = null;
  let win;


  if (scene.astronaut.position.y <= 2560 && scene.astronaut.direction === 'down') {
    if(isAnswerCorrect){
        finish = 'Parabéns, você conseguiu lançar o foguete corretamente e enviar o astronauta para reparar nosso satélite!';
        win = 'true';
    } else if((scene.astronaut.position.y <= 2560 && scene.astronaut.direction === 'down')) {
      finish ='Que pena, você não conseguiu lançar o foguete corretamente e nosso astronauta se encontra em queda livre rumo ao planeta!';
      win = 'false';
    }
  }

  if (finish) {
    gameManager.updateInfo('physics', 'gravitation', 'showFinalPanel', 'true');
    gameManager.updateInfo('physics', 'gravitation', 'finalMessage', finish);
    gameManager.updateInfo('physics', 'gravitation', 'win', win);
    gameManager.updateInfo('physics', 'gravitation', 'showMessage', 'false');
    scene.running = 0;
  }
}

/**
 * Função para atualizar o painel de tempo
 */
function updateTimePanel(scene) {
  let time = Math.floor(scene.timeLeft);
  let seconds = time % 60 > 9 ? (time % 60).toFixed(0) : '0' + (time % 60).toFixed(0);
  let minutes = Math.floor(time / 60) > 9 ? Math.floor(time / 60) : '0' + Math.floor(time / 60);
  gameManager.updateInfo('physics', 'gravitation', 'timeCount', minutes + ':' + seconds);
}


function playSounds(scene) {
  if (Number(gameManager.objSpecificGame.physics.gravitation.running) === 1 && scene.running === 0) {
    if (!scene.soundRaceMusic.isPlaying) {
      scene.soundRaceMusic.play();
    }
  } else if (
    Number(gameManager.objSpecificGame.physics.gravitation.running) === 0 &&
    scene.running === 1
  ) {
    scene.soundRaceMusic.stop();
  }
}

/**
 * Função para orientar a direção do foguete
 */
function guideRocket(scene) {
  let angle = scene.responses[0];
  if (angle <= 0) {
    scene.rocket.rotation.x -= 0.002;
    if (Math.abs(scene.responses[0] - (scene.rocket.rotation.x * 180 / Math.PI)) < 1) rocketOriented = true;
  } else if (angle > 0) {
    scene.rocket.rotation.x += 0.002;
    if (Math.abs((scene.rocket.rotation.x * 180 / Math.PI) - scene.responses[0]) < 1) rocketOriented = true;
  }
}

/**
 * Função para voar com o foguete
 */
function flyRocket(scene) {
  let angle = scene.responses[0];
  let velocity = scene.responses[1];

  if (angle !== 'Sem resposta' && velocity !== 'Sem resposta') {
    let vel = DELTA_TIME*simulationVel*velocity*10;
    scene.rocket.position.y += vel * Math.cos(angle * Math.PI / 180);
    scene.rocket.position.z += -vel * Math.sin(angle * Math.PI / 180);

    scene.camera.position.y += camMoveTax * vel * Math.cos(angle * Math.PI / 180);
    scene.camera.position.z += -camMoveTax * vel * Math.sin(angle * Math.PI / 180);
  }

  console.log("ALtura astronauta: "+scene.astronaut.position.y);


}

/**
 * Função para mover com o astronauta
 */
function moveAstronaut(scene) {
  if (scene.astronaut.position.y >= 2580 && scene.astronaut.direction === 'up'){
    scene.astronaut.direction = 'down';
    simulationVel = 0.2;
    scene.astronaut.scaling.x = 3;
    scene.astronaut.scaling.y = 3;
    scene.astronaut.scaling.z = 3;
  }

  if (scene.astronaut.direction === 'up'){
    camMoveTax = 0.97;
    scene.astronaut.position.x = scene.rocket.position.x;
    scene.astronaut.position.y = scene.rocket.position.y;
    scene.astronaut.position.z = scene.rocket.position.z;
    scene.camera.setTarget(
      new BABYLON.Vector3(
        scene.rocket.position.x,
        scene.rocket.position.y,
        scene.rocket.position.z
      )
    );
    
  } else if (scene.astronaut.direction === 'down') {
    camMoveTax = 0.6;
    scene.astronaut.position.y -= 0.1*simulationVel;
    if(scene.astronaut.scaling.x <= 5) {
      scene.astronaut.scaling.x += 0.01;
      scene.astronaut.scaling.y += 0.01;
      scene.astronaut.scaling.z += 0.01;
    }
     
    if(!isAnswerCorrect) scene.astronaut.rotation.x -= 0.002;
    
    scene.camera.setTarget(
      new BABYLON.Vector3(
        scene.astronaut.position.x,
        scene.astronaut.position.y,
        scene.astronaut.position.z
      )
    );
    
  }
  
}

/**
 * Função para orbitar com o satélite
 */
function orbitSatellite(scene) {
  let angle = scene.responses[0];
  let velocity = scene.responses[1];

  if (angle !== 'Sem resposta' && velocity !== 'Sem resposta') {
    scene.satellite.angle += (HALF_DEGREE*simulationVel) / (scene.satellite.period * DELTA_TIME);
    scene.satellite.position.z = (scene.satellite.dist * Math.cos(scene.satellite.angle));
    scene.satellite.position.y = (scene.satellite.dist * Math.sin(scene.satellite.angle)) - 1000;
    
  } else {
    scene.satellite.position.z = (scene.satellite.dist * Math.cos(scene.satellite.initAngle));
    scene.satellite.position.y = (scene.satellite.dist * Math.sin(scene.satellite.initAngle)) - 1000;
  }

}


/**
 * Função para movimentar a câmera
 */
function cameraGoTo(cameraPosition, x, y, z) {
  cameraPosition.x =
    Math.abs(cameraPosition.x - x) > 1
      ? (cameraPosition.x -= 0.1 * Math.sign(cameraPosition.x - x))
      : cameraPosition.x;
  cameraPosition.y =
    Math.abs(cameraPosition.y - y) > 1
      ? (cameraPosition.y -= 0.1 * Math.sign(cameraPosition.y - y))
      : cameraPosition.y;
  cameraPosition.z =
    Math.abs(cameraPosition.z - z) > 1
      ? (cameraPosition.z -= 0.1 * Math.sign(cameraPosition.z - z))
      : cameraPosition.z;
  return cameraPosition;
}



/**
 * Função para acionar os motores
 */
function startEngine(scene){
  let angle = scene.responses[0];
  let velocity = scene.responses[1];

  if (angle === 'Sem resposta' || velocity === 'Sem resposta') {
    scene.exaustRocket.scaling.y = 0.001;
    scene.exaustRocket.position.y = scene.rocket.position.y - 10;
  } else if (scene.exaustRocket.scaling.y < 1 && velocity !== 'Sem resposta') {
    scene.exaustRocket.scaling.y = 1;
    scene.exaustRocket.position.y = scene.rocket.position.y;
  } else if (scene.exaustRocket.scaling.y < 1.6 && velocity !== 'Sem resposta') {
    scene.exaustRocket.scaling.y += 0.008;
    scene.exaustRocket.position.y = scene.rocket.position.y;
  } else {
    scene.exaustRocket.scaling.y = 1.6;
    scene.exaustRocket.position.y = scene.rocket.position.y;
  }
  
  scene.exaustRocket.position.x = scene.rocket.position.x;
  scene.exaustRocket.position.z = scene.rocket.position.z;

  scene.exaustRocket.rotation.x = scene.rocket.rotation.x;
  scene.exaustRocket.rotation.y = scene.rocket.rotation.y;
  scene.exaustRocket.rotation.z = scene.rocket.rotation.z;
}



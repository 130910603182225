/* eslint-disable react/display-name */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import { Image } from 'semantic-ui-react';
import PlayDialogue from '../../../Common/compPlayDialogue';
import cinematics_manager_black from '../../../../images/Physics/Kinematics/cinematics_manager_black.PNG';
import cinematics_manager_blue from '../../../../images/Physics/Kinematics/cinematics_manager_blue.PNG';
import cinematics_manager_green from '../../../../images/Physics/Kinematics/cinematics_manager_green.PNG';
import cinematics_manager_pink from '../../../../images/Physics/Kinematics/cinematics_manager_pink.PNG';
import cinematics_manager_red from '../../../../images/Physics/Kinematics/cinematics_manager_red.PNG';
import cinematics_manager_yellow from '../../../../images/Physics/Kinematics/cinematics_manager_yellow.PNG';
import fig1 from '../../../../images/Physics/Kinematics/exChallengeMRUVA.PNG';
import fig2 from '../../../../images/Physics/Kinematics/exChallengeMRUVB.PNG';

function ChallengeExplanation(props) {
  const [animationStep, setAnimationStep] = useState(0);
  const additionalInfo = JSON.parse(sessionStorage.getItem('additional_info'));

  function getImage() {
    switch (additionalInfo.choosed_team) {
      case 'Arara Real':
        return cinematics_manager_blue;
      case 'Jacaré Impiedoso':
        return cinematics_manager_green;
      case 'Boto Veloz':
        return cinematics_manager_pink;
      case 'Falcão Negro':
        return cinematics_manager_black;
      case 'Guará Rubro':
        return cinematics_manager_red;
      case 'Capivara Furiosa':
        return cinematics_manager_yellow;
      default:
        break;
    }
  }

  useEffect(() => {
    if (animationStep === 11) {
      props.setNextItem('Tempo');
    }
  }, [animationStep]);

  const arrPage = [
    <PlayDialogue
      key={0}
      buttons="Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text={
        'Nessa corrida teremos um desafio especial contra nossos adversários da equipe ' +
        additionalInfo.teams[1] +
        '. Você estudou os conceitos do MRUV suficientemente para ganharmos? Precisamos do seu esforço para conseguir a vitória.'
      }
      image={getImage()}
      extraContent={''}
    />,

    <PlayDialogue
      key={1}
      buttons="Anterior/Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      // text= "Mas antes de pensarmos no resultado, temos um pequeno desafio. Isso porque você já está a 306 km/h, mas precisa descobrir a velocidade que chegará aos pneus, considerando uma taxa de frenagem de 8 m/s^2 durante 10 segundos."
      text="Como primeira parte do desafio, precisamos descobrir a velocidade em que devemos virar a primeira curva da pista. Estamos a 306 km/h, temos uma taxa de frenagem de 8 m/s² e chegaremos nos pneus que indicam a curva em 10 segundos."
      image={getImage()}
      extraContent={''}
    />,

    <PlayDialogue
      key={2}
      buttons="Anterior/Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text=""
      image={getImage()}
      extraContent={
        <>
          <p>Para melhor exemplificar a situação observe o mapa abaixo:</p>
          <Image src={fig1} centered fluid />
        </>
      }
    />,

    <PlayDialogue
      key={3}
      buttons="Anterior/Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text="Virando a curva corretamente, precisamos ultrapassar seu adversário. Sabe-se que quando você alcançar os pneus e finalizar a primeira parte, faltará apenas 40 segundos para que o outro piloto termine a corrida."
      image={getImage()}
      extraContent={''}
    />,

    <PlayDialogue
      key={4}
      buttons="Anterior/Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text="Temos mais 2000 metros do caminho de volta para realizar a ultrapassagem. Cabe a você definir a aceleração necessária para vencer. Mas cuidado! Nossos engenheiros exigiram que você acelere o mínimo possível para preservar os motores."
      image={getImage()}
      extraContent={''}
    />,

    <PlayDialogue
      key={3}
      buttons="Anterior/Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text=""
      image={getImage()}
      extraContent={
        <>
          <p>Para visualizar melhor a situação observe o mapa:</p>
          <Image src={fig2} centered fluid />
        </>
      }
    />,

    <PlayDialogue
      key={4}
      buttons="Anterior/Começar Desafio"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      setChallengeStarted={props.setChallengeStarted}
      setIsChallengeOpened={props.setIsChallengeOpened}
      text="Está preparado para esse desafio? Estudou bem os conceitos? Vamos em busca de mais uma vitória!"
      image={getImage()}
      extraContent={''}
      challenge = {props.challenge}
    />,
  ];

  return arrPage[animationStep];
}

export default ChallengeExplanation;

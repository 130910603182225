import * as BABYLON from '@babylonjs/core';
import gameManager from '../../../../managers/GameManager';

const DELTA_TIME = 1 / 60;
const TOTAL_DIST = 880;
const TOTAL_LAP = 5;

function Loop(props) {
  let scene = props.scene;
  if (scene.isReady() && gameManager.objSpecificGame.physics.kinematics.isLoading === 'true') {
    gameManager.updateInfo('physics', 'kinematics', 'isLoading', 'false');
    gameManager.updateInfo('physics', 'kinematics', 'missionMessage', 'Sua Primeira Corrida');
    gameManager.updateInfo('physics', 'kinematics', 'missionIndexScoring', '4');
    gameManager.updateInfo('physics', 'kinematics', 'missionXpAward', '30');
  }

  playSounds(scene);
  scene.running = Number(gameManager.objSpecificGame.physics.kinematics.running);
  scene.restart = Number(gameManager.objSpecificGame.physics.kinematics.restart);
  scene.countStart = 0;

  if (scene.running !== undefined && scene.countStart !== undefined) {
    simulationFlow(scene);
    restartFlow(scene);
  }

  if (scene.teste) {
    // scene.position.elements[0].text =
    //   'x: ' +
    //   scene.teste.position.x.toFixed(1) +
    //   '\ny: ' +
    //   scene.teste.position.y.toFixed(1) +
    //   '\nz: ' +
    //   scene.teste.position.z.toFixed(1);
    // scene.position.elements[0].text = scene.getEngine().getFps().toFixed();
    scene.position.elements[0].text = gameManager.objSpecificGame.physics.kinematics.responseValue;
  }
}

export default Loop;

/**
 * Função que reinicia a corrida
 */
function restartFlow(scene) {
  if (scene.restart === 3) {
    gameManager.updateInfo('physics', 'kinematics', 'running', '0');
    gameManager.updateInfo('physics', 'kinematics', 'restart', '0');

    gameManager.updateInfo('physics', 'kinematics', 'showMessage', 'false');
    gameManager.updateInfo('physics', 'kinematics', 'showSlider', 'false');
    gameManager.updateInfo('physics', 'kinematics', 'showButtons', 'false');
    gameManager.updateInfo('physics', 'kinematics', 'showFinalPanel', 'false');

    scene.lapTostopInPitstop = -3;
    scene.wheelsChosed = 0;
    scene.timeStoped = 0;

    scene.skyMaterial.inclination = scene.sunInclination;
    scene.countStart = 0;
    scene.time = 0;
    (scene.cars || []).forEach((car) => {
      car.explode = false;
    });
  }
  if (scene.restart <= 2 && scene.restart > 0) {
    gameManager.updateInfo('physics', 'kinematics', 'running', '1');
    gameManager.updateInfo('physics', 'kinematics', 'restart', '' + Number(scene.restart + 1));

    scene.time = 0;

    (scene.cars || []).forEach((car) => {
      car.explode = false;
      car.dist = car.initDist;
      car.position.x = car.initPosition.x;
      car.position.y = 0;
      car.position.z = car.initPosition.z;
      car.rotation = new BABYLON.Vector3(0, Math.PI, 0);
      car.vel = car.initVel;
      car.acc = car.initAcc;
      car.track = car.initTrack;
      car.desiredTrack = car.track;
      car.active = true;
      // if (car.isStudent) scene.cameraOrientation = car.rotation.z;
      if (car.isStudent)
        scene.camera.setTarget(new BABYLON.Vector3(car.position.x, car.position.y, car.position.z));
    });
  }
}

/**
 * Função que determina o fluxo das escolhas do aluno na corrida
 */
function simulationFlow(scene) {
  updateClassificationTable(scene);
  updateLapNumber(scene);
  if (scene.running === 1 && scene.countStart >= 0) {
    updateQuests(scene);
    updateFinalPanel(scene);
    if (scene.running === 1 || scene.restart !== 0) {
      scene.time += DELTA_TIME;
      scene.skyMaterial.inclination -= 4 / 54000;

      carsMovement(scene);
      carsMovement(scene);
      cameraMovement(scene);

      rotateRodas(
        (scene.rodas[0].rotation.y -
          5 * scene.cars.find((e) => e.isStudent).vel * DELTA_TIME * 0.2) %
          (2 * Math.PI),
        scene
      );
    }
  }
}

/**
 * Função para rotacionar as rodas de todos os carros
 */
function rotateRodas(value, scene) {
  scene.rodas.forEach((e) => {
    e.rotation.y = value;
  });
}

/**
 * Função responsável para a movimentação de todos os carros do desafio
 */
function carsMovement(scene) {
  (scene.cars || []).forEach((car, indexCar) => {
    car.vel = car.isStudent ? getCarVelocity(scene) : car.vel;
    car.vel = Math.max(car.vel, 0.00001);
    car.dist += (car.vel * DELTA_TIME) / 2;

    if (car.isStudent) {
      scene.actualLap =
        Math.ceil(car.dist / TOTAL_DIST) >= TOTAL_LAP
          ? TOTAL_LAP
          : Math.ceil(car.dist / TOTAL_DIST) >= 1
          ? Math.ceil(car.dist / TOTAL_DIST)
          : 1;
    }

    scene.cars.forEach((element, elementIndex) => {
      if (elementIndex !== indexCar && car.changeTrack === 0) {
        if (
          element.dist % TOTAL_DIST > car.dist % TOTAL_DIST &&
          element.dist % TOTAL_DIST < (car.dist % TOTAL_DIST) + 10 &&
          element.track === car.track &&
          car.vel > element.vel
        ) {
          car.desiredTrack = car.track <= 0.5 ? 1 : 0;
        }
      }
    });

    if (
      scene.actualLap === scene.lapTostopInPitstop &&
      car.isStudent &&
      car.dist % TOTAL_DIST > 700
    ) {
      car.desiredTrack = 1;
    }

    car.changeTrack =
      car.desiredTrack > car.track ? 0.005 : car.desiredTrack < car.track ? -0.005 : 0;
    car.track = car.track + car.changeTrack;
    if (Math.abs(car.track - car.desiredTrack) < 0.0001) car.track = Math.round(car.track);

    let poitsCar = [];
    let initPitstopMeters = 835;
    let endPistopMeters = 30;

    poitsCar.push(new BABYLON.Vector3(car.position.x, car.position.y, car.position.z));

    if (
      car.isStudent &&
      car.dist > initPitstopMeters + TOTAL_DIST * (scene.lapTostopInPitstop - 1) &&
      car.dist < TOTAL_DIST + endPistopMeters + TOTAL_DIST * (scene.lapTostopInPitstop - 1)
    ) {
      car.track = 1.05;
      let i1 = Math.floor(
        ((car.dist - initPitstopMeters - TOTAL_DIST * (scene.lapTostopInPitstop - 1)) *
          (scene.pointsStop.length - 1)) /
          (TOTAL_DIST - initPitstopMeters + endPistopMeters)
      );
      let i1_ =
        (((car.dist - initPitstopMeters - TOTAL_DIST * (scene.lapTostopInPitstop - 1)) *
          (scene.pointsStop.length - 1)) /
          (TOTAL_DIST - initPitstopMeters + endPistopMeters)) %
        (i1 > 0 ? i1 : 1);

      let pointxi1 =
        scene.pointsStop[i1].x + (scene.pointsStop[i1 + 1].x - scene.pointsStop[i1].x) * i1_;
      let pointzi1 =
        scene.pointsStop[i1].z + (scene.pointsStop[i1 + 1].z - scene.pointsStop[i1].z) * i1_;
      car.position.x = pointxi1;
      car.position.z = pointzi1;
    } else {
      let isNegative = car.dist < 0 ? TOTAL_DIST : 0;
      let i1 = Math.floor(
        (((isNegative + car.dist) % TOTAL_DIST) * (scene.points.length - 1)) / TOTAL_DIST
      );
      let i2 = Math.floor(
        (((isNegative + car.dist) % TOTAL_DIST) * (scene.points2.length - 1)) / TOTAL_DIST
      );
      let i1_ =
        ((((isNegative + car.dist) % TOTAL_DIST) * (scene.points.length - 1)) / TOTAL_DIST) %
        (i1 > 0 ? i1 : 1);
      let i2_ =
        ((((isNegative + car.dist) % TOTAL_DIST) * (scene.points2.length - 1)) / TOTAL_DIST) %
        (i2 > 0 ? i2 : 1);

      let pointxi1 = scene.points[i1].x + (scene.points[i1 + 1].x - scene.points[i1].x) * i1_;
      let pointzi1 = scene.points[i1].z + (scene.points[i1 + 1].z - scene.points[i1].z) * i1_;
      let pointxi2 = scene.points2[i2].x + (scene.points2[i2 + 1].x - scene.points2[i2].x) * i2_;
      let pointzi2 = scene.points2[i2].z + (scene.points2[i2 + 1].z - scene.points2[i2].z) * i2_;

      car.position.x = pointxi1 + (pointxi2 - pointxi1) * car.track;
      car.position.z = pointzi1 + (pointzi2 - pointzi1) * car.track;
    }

    poitsCar.push(new BABYLON.Vector3(car.position.x, car.position.y, car.position.z));

    if ((car.vel > 0 && scene.restart === 0) || scene.restart === undefined) {
      let normal = new BABYLON.Path3D(poitsCar).getNormals()[0];
      let theta = Math.acos(BABYLON.Vector3.Dot(new BABYLON.Vector3(0, 0, -1), normal));
      let dir = BABYLON.Vector3.Cross(new BABYLON.Vector3(0, 0, -1), normal).y;
      let newRotation = dir !== 0 ? (dir * theta) / Math.abs(dir) : -theta;
      car.rotation.y = controllerKRotation(car.rotation.y, newRotation, 0.1);
    }
  });
}

/**
 * Função para suavizar a rotação do carro e da câmera
 */
function controllerKRotation(value, desiredValue, k) {
  if (desiredValue < 0 && value > 0 && Math.abs(desiredValue - value) > 2) {
    value = value - 2 * Math.PI;
  }
  if (desiredValue > 0 && value < 0 && Math.abs(desiredValue - value) > 2) {
    value = value + 2 * Math.PI;
  }
  return value + k * (desiredValue - value);
}

/**
 * Função para movimentar a câmera
 */
function cameraMovement(scene) {
  let car = scene.cars.find((e) => e.isStudent);
  updateCameraPos(scene.camera, car);
  scene.camera.setTarget(new BABYLON.Vector3(car.position.x, car.position.y, car.position.z));
}

/**
 * Atualiza a tabela de classificação
 */
function updateClassificationTable(scene) {
  let listCars = [
    { name: 'Arara Real' },
    { name: 'Jacaré Impiedoso' },
    { name: 'Boto Veloz' },
    { name: 'Falcão Negro' },
    { name: 'Guará Rubro' },
    { name: 'Capivara Furiosa' },
  ];

  let dists = listCars.map((car) => {
    let foundCar = scene.cars.find((e) => e.equipe == car.name);
    return foundCar && foundCar.equipe ? foundCar.dist : -1;
  });

  gameManager.updateInfo('physics', 'kinematics', 'classification', dists);
}

/**
 * Atualiza o número da volta
 */
function updateLapNumber(scene) {
  gameManager.updateInfo(
    'physics',
    'kinematics',
    'lapsText',
    'Volta ' + scene.actualLap + '/' + TOTAL_LAP
  );
}

/**
 * Atualiza o painel final
 */
function updateFinalPanel(scene) {
  let car = scene.cars.find((e) => e.isStudent);
  let finish;
  let win;
  if (car.dist > TOTAL_DIST * TOTAL_LAP) {
    let orderedCars = scene.cars.sort((a, b) => (a.dist > b.dist ? -1 : 1));
    let studentPos = orderedCars.indexOf(scene.cars.find((e) => e.isStudent)) + 1;

    if (studentPos === 1) {
      finish =
        'Parabéns! Você conseguiu chegar em 1º lugar decidindo corretamente quando parar no pitstop!';
      win = 'true';
    } else {
      finish = 'Que pena! Você não conseguiu uma boa colocação nesta corrida!';
      win = 'false';
    }
  }

  if (finish) {
    gameManager.updateInfo('physics', 'kinematics', 'showFinalPanel', 'true');
    gameManager.updateInfo('physics', 'kinematics', 'finalMessage', finish);
    gameManager.updateInfo('physics', 'kinematics', 'win', win);
    scene.running = 0;
  }
}

/**
 * Atualiza as perguntas
 */
function updateQuests(scene) {
  let car = scene.cars.find((e) => e.isStudent);
  if (
    scene.actualLap === 2 &&
    car.dist % TOTAL_DIST > TOTAL_DIST * 0.8 &&
    scene.lapTostopInPitstop === -3 &&
    scene.wheelsChosed === 0
  ) {
    gameManager.updateInfo('physics', 'kinematics', 'showMessage', 'true');
    gameManager.updateInfo('physics', 'kinematics', 'showButtons', 'true');
    gameManager.updateInfo(
      'physics',
      'kinematics',
      'message',
      'Deseja trocar os pneus na segunda volta?'
    );
    if (Number(gameManager.objSpecificGame.physics.kinematics.responseValue) > 0) {
      if (Number(gameManager.objSpecificGame.physics.kinematics.responseValue) === 1) {
        scene.lapTostopInPitstop = 2;
        scene.wheelsChosed = 1;
      } else {
        scene.lapTostopInPitstop = -2;
        scene.wheelsChosed = 0;
      }
      gameManager.updateInfo('physics', 'kinematics', 'showMessage', 'false');
      gameManager.updateInfo('physics', 'kinematics', 'showButtons', 'false');
      gameManager.updateInfo('physics', 'kinematics', 'responseValue', '0');
    } else {
      scene.running = 0;
    }
  }
  if (
    scene.actualLap === 3 &&
    car.dist % TOTAL_DIST > TOTAL_DIST * 0.8 &&
    scene.lapTostopInPitstop === -2 &&
    scene.wheelsChosed === 0
  ) {
    gameManager.updateInfo('physics', 'kinematics', 'showMessage', 'true');
    gameManager.updateInfo('physics', 'kinematics', 'showButtons', 'true');
    gameManager.updateInfo(
      'physics',
      'kinematics',
      'message',
      'Deseja trocar os pneus na terceira volta?'
    );
    if (Number(gameManager.objSpecificGame.physics.kinematics.responseValue) > 0) {
      if (Number(gameManager.objSpecificGame.physics.kinematics.responseValue) === 1) {
        scene.lapTostopInPitstop = 3;
        scene.wheelsChosed = 2;
      } else if (Number(gameManager.objSpecificGame.physics.kinematics.responseValue) === 2) {
        scene.lapTostopInPitstop = 3;
        scene.wheelsChosed = 3;
      } else {
        scene.lapTostopInPitstop = -1;
        scene.wheelsChosed = 0;
      }
      gameManager.updateInfo('physics', 'kinematics', 'showMessage', 'false');
      gameManager.updateInfo('physics', 'kinematics', 'showButtons', 'false');
      gameManager.updateInfo('physics', 'kinematics', 'responseValue', '0');
    } else {
      scene.running = 0;
    }
  }
  if (
    scene.actualLap === 4 &&
    car.dist % TOTAL_DIST > TOTAL_DIST * 0.8 &&
    scene.lapTostopInPitstop === -1 &&
    scene.wheelsChosed === 0
  ) {
    gameManager.updateInfo('physics', 'kinematics', 'showMessage', 'true');
    gameManager.updateInfo('physics', 'kinematics', 'showButtons', 'true');
    gameManager.updateInfo(
      'physics',
      'kinematics',
      'message',
      'Deseja trocar os pneus na quarta volta?'
    );
    if (Number(gameManager.objSpecificGame.physics.kinematics.responseValue) > 0) {
      if (Number(gameManager.objSpecificGame.physics.kinematics.responseValue) === 1) {
        scene.lapTostopInPitstop = 4;
        scene.wheelsChosed = 4;
      } else {
        scene.lapTostopInPitstop = 0;
        scene.wheelsChosed = 0;
      }
      gameManager.updateInfo('physics', 'kinematics', 'showMessage', 'false');
      gameManager.updateInfo('physics', 'kinematics', 'showButtons', 'false');
      gameManager.updateInfo('physics', 'kinematics', 'responseValue', '0');
    } else {
      scene.running = 0;
    }
  }
}

function getCarVelocity(scene) {
  let car = scene.cars.find((e) => e.isStudent);
  if (scene.wheelsChosed === 0) {
    return 210 / 3.6;
  }
  if (
    scene.actualLap === scene.lapTostopInPitstop &&
    scene.timeStoped < 2 &&
    car.dist % TOTAL_DIST > TOTAL_DIST * 0.99
  ) {
    scene.timeStoped += DELTA_TIME / 2;
    return 0;
  }
  if (scene.actualLap > scene.lapTostopInPitstop) {
    if (scene.wheelsChosed === 1) {
      return 220 / 3.6;
    }
    if (scene.wheelsChosed === 2) {
      return 260 / 3.6;
    }
    if (scene.wheelsChosed === 3) {
      return 230 / 3.6;
    }
    if (scene.wheelsChosed === 4) {
      return 260 / 3.6;
    }
  }
  return 210 / 3.6;
}

function updateCameraPos(camera, car) {
  if (car.dist >= 3900) {
    cameraGoTo(camera.position, 110, 10, -30);
  } else if (car.dist >= 2500) {
    cameraGoTo(camera.position, 110, 15, 150);
  } else if (car.dist <= 2500) {
    cameraGoTo(camera.position, -50, 30, 60);
  }
}

function cameraGoTo(cameraPosition, x, y, z) {
  cameraPosition.x =
    Math.abs(cameraPosition.x - x) > 1
      ? (cameraPosition.x -= 0.1 * Math.sign(cameraPosition.x - x))
      : cameraPosition.x;
  cameraPosition.y =
    Math.abs(cameraPosition.y - y) > 1
      ? (cameraPosition.y -= 0.1 * Math.sign(cameraPosition.y - y))
      : cameraPosition.y;
  cameraPosition.z =
    Math.abs(cameraPosition.z - z) > 1
      ? (cameraPosition.z -= 0.1 * Math.sign(cameraPosition.z - z))
      : cameraPosition.z;
  return cameraPosition;
}

function playSounds(scene) {
  if (Number(gameManager.objSpecificGame.physics.kinematics.running) === 1 && scene.running === 0) {
    if (!scene.soundRaceMusic.isPlaying) {
      scene.soundRaceMusic.play();
    }
  } else if (
    Number(gameManager.objSpecificGame.physics.kinematics.running) === 0 &&
    scene.running === 1
  ) {
    scene.soundRaceMusic.stop();
  }
}

// eslint-disable-next-line no-use-before-define
import React, { useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';

/** Components */
import HomePage from '../pages/HomePage';
import ContactUs from '../pages/Presentation/ContactUs';
import Home from '../pages/home';
import Register from '../pages/RegisterUser'
import About from '../pages/about';
import Subjects from '../pages/subjects';
import DisciplinePage from '../pages/DisciplinePage';
import ModulesPresentation from '../pages/ModulesPresentation';
import ModulesStageMap from '../pages/ModulesStageMap';

/** Componentes não verificados na autenticação */
import ChallengeMenu from '../pages/Physics/Kinematics/AverageSpeed/challengeMenu';
import 'semantic-ui-css/semantic.min.css';
import KinematicsIntroductory from '../pages/kinematicsLearn/kinematicsIntrodutory';
import KinematicsAverageSpeed from '../pages/Physics/Kinematics/AverageSpeed/presentationPage';
import ChallengeKinematicsAverageSpeed from '../pages/Physics/Kinematics/AverageSpeed/challenge';
import KinematicsAceleration from '../pages/Physics/Kinematics/Aceleration/presentationPage';
import ChallengeKinematicsAceleration from '../pages/Physics/Kinematics/Aceleration/challenge';
import KinematicsMRUV from '../pages/Physics/Kinematics/Mruv/presentationPage';
import ChallengeKinematicsMRUV from '../pages/Physics/Kinematics/Mruv/challenge';
import KinematicsTorricelli from '../pages/Physics/Kinematics/Torricelli/presentationPage';
import ChallengeKinematicsTorricelli from '../pages/Physics/Kinematics/Torricelli/challenge';
import GravitationKeplersLaws from '../pages/Physics/Gravitation/KeplersLaws/presentationPage';
import ChallengeGravitationKeplersLaws from '../pages/Physics/Gravitation/KeplersLaws/challenge';
import GravitationLawOfUniversalGravitation from '../pages/Physics/Gravitation/LawOfUniversalGravitation/presentationPage';
import ChallengeGravitationLawOfUniversalGravitation from '../pages/Physics/Gravitation/LawOfUniversalGravitation/challenge';
import GravitationGravityAcceleration from '../pages/Physics/Gravitation/GravityAcceleration/presentationPage';
import ChallengeGravitationGravityAcceleration from '../pages/Physics/Gravitation/GravityAcceleration/challenge';
import GravitationEscapeVelocity from '../pages/Physics/Gravitation/EscapeVelocity/presentationPage';
import ChallengeGravitationEscapeVelocity from '../pages/Physics/Gravitation/EscapeVelocity/challenge';
import '../managers/LoginManager';
import MyProfile from '../pages/Profile/myProfile';
import Training from '../pages/Training/training';
import TrainingPresentation from '../pages/Training/trainingPresentation';
import Challenge1 from '../pages/challenge1';
import VelocidadeScene from './Física/Cinemática/Velocidade Média/VelocidadeScene.jsx';
import AccelerationScene from './Física/Cinemática/Aceleração/AccelerationScene.jsx';
import MRUVScene from './Física/Cinemática/MRUV/MRUVScene.jsx';
import TorricelliScene from './Física/Cinemática/Torricelli/TorricelliScene.jsx';
import KeplerScene from './Física/Gravitação/Leis de Kepler/KeplerScene.jsx';
import UniversalGravitationScene from './Física/Gravitação/Lei da Gravitação Universal/UniversalGravitationScene.jsx';
import GravityAccelerationScene from './Física/Gravitação/Aceleração  da Gravidade/GravityAccelerationScene.jsx';
import EscapeVelocityScene from './Física/Gravitação/Velocidade de Escape/EscapeVelocityScene.jsx';
import PhysicsInConstruction from '../pages/ConstructionViews/physicsInConstruction';
import ChemistryInConstruction from '../pages/ConstructionViews/chemistryInConstruction';
import BiologyInConstruction from '../pages/ConstructionViews/biologyInConstruction';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route path="/" component={HomePage} exact />
          <Route path="/entre-em-contato" component={ContactUs} exact />
          <Route path="/login" component={Home} exact />
          <Route path="/register" component={Register} exact />
          <Route path="/sobre" component={About} exact />
          <PrivateRoute path="/meu-perfil" component={MyProfile} exact />
          <PrivateRoute path="/disciplinas" component={Subjects} exact />
          <PrivateRoute path="/disciplina/:id" component={DisciplinePage} exact />
          <PrivateRoute path="/apresentacao/:id" component={ModulesPresentation} exact />
          <Route path="/apresentacao-treinamento" component={TrainingPresentation} exact />
          <Route path="/treinamento" component={Training} exact />
          <PrivateRoute path="/mapa-de-fases/:id" component={ModulesStageMap} exact />        
          {/* Cinemática */}
          <PrivateRoute path="/fisica-cinematica-velocidade-media/:id" component={KinematicsAverageSpeed} exact />
          <PrivateRoute path="/fisica-cinematica-velocidade-media-desafio" component={ChallengeKinematicsAverageSpeed} exact />
          <PrivateRoute path="/fisica-cinematica-aceleracao/:id" component={KinematicsAceleration} exact />
          <PrivateRoute path="/fisica-cinematica-aceleracao-desafio" component={ChallengeKinematicsAceleration} exact />
          <PrivateRoute path="/fisica-cinematica-mruv/:id" component={KinematicsMRUV} exact />
          <PrivateRoute path="/fisica-cinematica-mruv-desafio" component={ChallengeKinematicsMRUV} exact />
          <PrivateRoute path="/fisica-cinematica-torricelli/:id" component={KinematicsTorricelli} exact />
          <PrivateRoute path="/fisica-cinematica-torricelli-desafio" component={ChallengeKinematicsTorricelli} exact />
          {/* Gravitação */}
          <PrivateRoute path="/fisica-gravitacao-leisdekepler/:id" component={GravitationKeplersLaws} exact />
          <PrivateRoute path="/fisica-gravitacao-leisdekepler-desafio" component={ChallengeGravitationKeplersLaws} exact />
          <PrivateRoute path="/fisica-gravitacao-leiGravitacaoUniversal/:id" component={GravitationLawOfUniversalGravitation} exact />
          <PrivateRoute path="/fisica-gravitacao-leiGravitacaoUniversal-desafio" component={ChallengeGravitationLawOfUniversalGravitation} exact />
          <PrivateRoute path="/fisica-gravitacao-aceleracaoDaGravidade/:id" component={GravitationGravityAcceleration} exact />
          <PrivateRoute path="/fisica-gravitacao-aceleracaoDaGravidade-desafio" component={ChallengeGravitationGravityAcceleration} exact />
          <PrivateRoute path="/fisica-gravitacao-velocidadeDeEscape/:id" component={GravitationEscapeVelocity} exact />
          <PrivateRoute path="/fisica-gravitacao-velocidadeDeEscape-desafio" component={ChallengeGravitationEscapeVelocity} exact />
          {/* A partir daqui ainda não foram validadas as rotas */}
          <Route path="/kinematics-introdutory" component={KinematicsIntroductory} exact />
          <Route path="/menu-desafio1" component={ChallengeMenu} exact />
          <Route path="/desafio1" component={Challenge1} exact />
          <Route path="/velocidade" component={VelocidadeScene} exact />
          <Route path="/acceleration" component={AccelerationScene} exact />
          <Route path="/teste3D" component={MRUVScene} exact />
          <Route path="/torricelli" component={TorricelliScene} exact />
          <Route path="/leisdekepler" component={KeplerScene} exact />
          <Route path="/leidagravitacaouniversal" component={UniversalGravitationScene} exact />
          <Route path="/aceleracaodagravidade" component={GravityAccelerationScene} exact />
          <Route path="/velocidadedeescape" component={EscapeVelocityScene} exact />
          <Route path="/fisica-em-construcao" component={PhysicsInConstruction} exact />
          <Route path="/quimica-em-construcao" component={ChemistryInConstruction} exact />
          <Route path="/biologia-em-construcao" component={BiologyInConstruction} exact />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const getRoute = (props) => {
    let isAuthenticated = Cookies.get('loginstatus') === 'loggedin';
    if (!isAuthenticated) {
      localStorage.clear();
    }

    if (isAuthenticated) return <Component {...props} />;
    return <Redirect to={{ pathname: '/' }} />;
  };
  return <Route {...rest} render={(props) => getRoute(props)} />;
};

export default App;

import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import lock from '../images/lock.PNG';
import './stageMap.css';

function StageMap(props) {
  const [activeStage, setActiveStage] = useState(null);

  return (
    <div className="centralContainer stageMap">
      <h1 className="stageMapHeader">{props.title}</h1>
      <img className="stageImage" src={`data:image/png;base64,${props.startImg}`} alt="a" />
      {/* <div className="stageIcon">
        <Icon className="nextStageIcon green" size="big" color="green" name="angle double down" />
      </div> */}

      {props.stages.map((stage, nIndex) => {
        return (
          <div key={nIndex} style={{ display: 'contents' }}>
            <div className="stageIcon">
              <Icon
                className={!stage.isLocked ? 'nextStageIcon green' : 'nextStageIcon'}
                size="big"
                name="angle double down"
              />
            </div>
            <div style={{ position: 'relative' }}>
              <img
                className={
                  activeStage === stage.order ? 'stageImage stage active' : 'stageImage stage'
                }
                src={stage.isLocked ? lock : `data:image/png;base64,${stage.image}`}
                alt="stage-image"
                onClick={
                  !stage.isLocked
                    ? () => {
                        props.stageFunction(stage.order);
                        setActiveStage(stage.order);
                      }
                    : null
                }
              />
              <h1 className="stageIndex">{stage.order}</h1>
            </div>
            {/* <div className="stageIcon">
              <Icon
                className={stage.isReleased ? 'nextStageIcon green' : 'nextStageIcon'}
                size="big"
                name="angle double down"
              />
            </div> */}
          </div>
        );
      })}
      {/* <img
        className="stageImage"
        src={props.endImg}
        alt="a"
        style={{ width: '80px', marginBottom: '30px' }}
      /> */}
    </div>
  );
}

export default StageMap;

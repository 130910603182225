import React, { useEffect } from "react";
import "./ContactUs.scss";
import logo from "../../images/Logos/mrQuest_logo.png";
import logoFooter from "../../images/Logos/mrQuest_logo.png";
import { Button, Icon } from "semantic-ui-react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { List } from 'semantic-ui-react'

function ContactUs(props){

    return(
        <>
        <div className="contactUs">
            <div className="contactUs__invisibleBar">
                <Button color='orange' size='huge' onClick={()=>{props.history.push('/')}}>Página Inicial</Button>
                <Button color='orange' size='huge' onClick={()=>{props.history.push('/login')}}>Log in</Button>
            </div>
            <div className="contactUs__mainContainer">
                <img className="contactUs__logo" src={logo} />
            </div>
            <br></br>
            <br></br>
            <br></br>
            <div className="contactUs__square1">
                <p className="contactUs__square1__paragraph1">Revolucione sua escola!</p>
                <p className="contactUs__square1__paragraph2">Com nosso método didático e interativo, veja a transformação de suas aulas</p>
            </div>

            <div className="contactUs__square2">
                <p className="contactUs__square2__paragraph1">Entre em contato</p>
                <p className="contactUs__square2__paragraph2">Solicite um orçamento através das seguintes mídias:</p>
                <List className="contactUs__square2__paragraph2">
                    <List.Item icon='mail' content={'questionando.edu@gmail.com'} />
                    <List.Item icon='whatsapp' content='(32)984120532' />
                </List>
            </div>

            <div className="contactUs__square3">
                <p className="contactUs__square3__paragraph1">Quem somos nós</p>
                <p className="contactUs__square3__paragraph2">Fundado em 2020, na cidade de Juiz de Fora - MG, o Mr. Quest é uma startup focada em soluções digitais para escolas de todo o país.</p>
                <p className="contactUs__square3__paragraph2">Com grande investimento em tecnologia, trazemos um novo método de ensino para as salas de aula, auxiliando professores e escolas a revolucionar o bem mais importante: a educação de nossos alunos. </p>
            </div>
                
            <div className="contactUs__footer">    
                <span className="secondaryFontBlack homePage__footer__text">© {new Date().getFullYear()} - Mr. Quest. Todos os direitos reservados.</span>
                    <img className="homePage__footer__logo" src={logoFooter} alt="logo"/>
                    <span className="homePage__footer__socialMedia">
                        <Icon name="facebook official" size="large"/>
                        <Icon name="instagram" size="large"/>
                        <Icon name="linkedin" size="large"/>
                </span>
            </div>            
        </div>
        </>
    )
}

export default ContactUs;
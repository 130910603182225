import api from '../services/api';
import Cookies from 'js-cookie';

const authenticate = async (email, password) => {
  let returnAuth;
  await api
    .post('/auth', {
      email: email,
      password: password,
    })
    .then((res) => {
      Cookies.set('loginstatus', 'loggedin');
      localStorage.setItem('user_id', res.data.user_id);
      localStorage.setItem('client_id', res.data.client_id);
      localStorage.setItem('email', res.data.email);
      localStorage.setItem('name', res.data.name);
      localStorage.setItem('surname', res.data.surname);
      localStorage.setItem('avatar', res.data.avatar);
      localStorage.setItem('birth_date', res.data.birth_date);
      localStorage.setItem('city', res.data.address && res.data.address.localidade);

      returnAuth = true;
    })
    .catch((err) => {
      returnAuth = false;
    });

  return returnAuth;
};

export { authenticate };

import React, { useEffect } from 'react';
import { Icon } from 'semantic-ui-react';
import anime from 'animejs/lib/anime.es.js';
import './starMessage.css';
import scoreManager from '../../src/managers/ScoreManager';
import { Image } from 'semantic-ui-react';
import figSticker from '../../src/images/Stickers/GalileuGalilei.png';

import api from '../services/api';

/* props.params: {
                show:               indica que a animação será mostrada         ex:{true} 
                message:            indica o título da missão                   ex:{'Tamanho da Estrada'} 
                subjectScoring:     indica a matéria da pontuação               ex:{'physics'}
                typeScoring:        indica a submatéria da pontuação            ex:{'kinematics'}
                arrayScoring:       indica o vetor de estrelas                  ex:{'arrStarAverageSpeed'}|{'arrStarAceleration'}
                indexScoring:       indica a posição do vetor de estrelas       ex:{2}
                xpAward:            indica a recompensa em xp                   ex:{30}  
}*/

function StarMessage(props) {
  const arrStars = (sessionStorage.getItem('arr_stars') || '').split(',').map((e) => e === 'true');
  const [requesting, setRequesting] = React.useState(false);
  const [earned, setEarned] = React.useState(false);

  function newStar() {
    anime({
      targets: '.newStarContainer',
      opacity: [0, 1],
      easing: 'linear',
      duration: 1000,
    });
    anime({
      targets: '.newStarIcon',
      rotate: '2turn',
      scale: 2,
      duration: 3000,
    });
    anime({
      targets: '.newStarText',
      width: ['80%', '100%'],
      duration: 1000,
    });
    anime({
      targets: '.newStarContainer',
      translateX: 1000,
      delay: 3000,
      easing: 'easeInOutElastic',
    });
  }

  useEffect(() => {
    if (props.show && earned) {
      newStar();
    }
  }, [props.show, earned]);

  function update() {
    if(requesting || earned) return;

    setRequesting(true);
    new Promise(async (resolve, reject) => {
      await api
        .post('earnStar', {
          challenge_id: sessionStorage.getItem('challenge_id'),
          client_id: localStorage.getItem('client_id'),
          star_index: props.indexScoring,
        })
        .then(async (res) => {
          const oldStarsQuantity = arrStars.reduce((acc, cur) => acc + (cur ? 1 : 0), 0);
          const newStarsQuantity = res.data.clientChallenge.arr_stars.reduce(
            (acc, cur) => acc + (cur ? 1 : 0),
            0
          );

          sessionStorage.setItem('arr_stars', res.data.clientChallenge.arr_stars);
          setRequesting(false);

          if (newStarsQuantity > oldStarsQuantity) {
            setEarned(true);
            await api
              .post('earnXp', {
                challenge_id: sessionStorage.getItem('challenge_id'),
                client_id: localStorage.getItem('client_id'),
                xp: props.xpAward,
              })
              .then((res) => {
                sessionStorage.setItem('xp', res.data.xp);
                sessionStorage.setItem('level', res.data.level);
              })
              .catch((err) => {});
          }
        })
        .catch((err) => {
          setRequesting(false);
        });

      resolve('Request done!');
    });
  }

  return (
    <>
      {(arrStars[props.indexScoring] === false || earned) && props.show === true && (
        <>
          <div className="newStarContainer">
            <div className="newStarMessageHeader">
              <Icon className="newStarIcon" name="star" color="yellow" />
              <h1>Nova estrela desbloqueada!</h1>
            </div>
            {update()}
            <p className="newStarText">
              <br></br>
              <b>{props.message}</b>
              <br></br>+ {props.xpAward} xp
            </p>
            {scoreManager.objSpecificScore[props.subjectScoring][props.typeScoring].bSticker() ===
              true && (
              <p className="newStarText">
                <Image className="" src={figSticker} size="small" />
              </p>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default StarMessage;

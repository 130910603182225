import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';

function BeginChallengePage(props){
    
    const [isMinimized, setIsMinimized] = useState(false);

    function minimizeBar(){
        props.minimizeFunction(true);
        setIsMinimized(true);
    }

    return(
        <div className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`} key={Math.random()}>
            {/* <div className="minMaxBar">
                <Icon className="icon" name='caret down' onClick={minimizeBar}/>
                <Icon className="icon" name='caret up' onClick={maximizeBar}/>
            </div> */}
            {!isMinimized && 
            <>
                <h1 className="presentationItem">Chegou a hora!</h1>
                <p style={{padding: '10%'}} className="presentationItem">
                    Agora que você aprendeu os conceitos importantes e é quase um especialista em velocidade, prepare-se para pisar fundo e alcançar o primeiro lugar!
                    
                </p>
                <div className="presentationItem start">
                    <Button size='big' className="startBtn" onClick={()=>{props.setChallengeStarted(true); minimizeBar();}}>Iniciar a corrida</Button>
                </div>
                
            </>
            }
        </div>
    )
}

export default BeginChallengePage;
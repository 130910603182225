function generateID() {
  return Math.random().toString(36).substr(2);
}

type EventMap<T> = Map<string, T>;
type UnsubscribeFunction = () => void;
export default class BaseObserver<T extends Function> {
  mapEventsMap: Map<string, EventMap<T>>;
  constructor() {
    this.mapEventsMap = new Map();
  }

  notify(strEvent: string) {
    const mapCallBacks = this.mapEventsMap.get(strEvent);

    if (!mapCallBacks) return;

    mapCallBacks.forEach((fnCallback, key) => {
      if (fnCallback(this) === '@REMOVE') {
        mapCallBacks.delete(key);
      }
    });
  }

  notifyAll() {
    this.mapEventsMap.forEach((_value, strKey) => this.notify(strKey));
  }

  subscribe(strEvent: string, fnCallback: T): [UnsubscribeFunction, string] {
    const strCallbackID = generateID();
    if (!this.mapEventsMap.get(strEvent)) {
      this.mapEventsMap.set(strEvent, new Map());
    }
    const mapCallBacks = this.mapEventsMap.get(strEvent) as EventMap<T>;
    mapCallBacks.set(strCallbackID, fnCallback);
    return [() => this.unsubscribe(strEvent, strCallbackID), strCallbackID];
  }

  unsubscribe(strEvent: string, strCallbackID: string) {
    const mapEventMap = this.mapEventsMap.get(strEvent);
    if (mapEventMap) mapEventMap.delete(strCallbackID);
  }
}

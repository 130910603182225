import React from 'react';
import MissionComponent from '../../../../components/MapComponents/missionComponent';

function HistoryPage(props) {
  return (
    <>
      <MissionComponent
        numberMissions={4}
        subject="physics"
        typeScoring="kinematics"
        arrayScoring="arrStarAceleration"
        missionName="Acelerando no Deserto"
        missionDescription="Descrição: No Autódromo, assuma a liderança da corrida no início da partida, acelerando corretamente."
        missionVectorNumber={3}
        submission={[
          {
            missionName: 'Pare o carro',
            missionDescription:
              'Descrição: Na simulação do Laboratório, freie o carro à tempo no semáforo.',
            missionVectorNumber: 0,
          },
          {
            missionName: 'O carro mais rápido',
            missionDescription: 'Descrição: Converse com o mecânico Luiz Câmera.',
            missionVectorNumber: 1,
          },
          {
            missionName: 'A maior aceleração',
            missionDescription: 'Descrição: Converse com a Engenheira Thaís Condida.',
            missionVectorNumber: 2,
          },
        ]}
      />
    </>
  );
}

export default HistoryPage;

import React, { useEffect, useState } from 'react';

import { Icon } from 'semantic-ui-react';
import './itemsBar.css';

import useSound from 'use-sound';
import soundButton from '../sounds/button.wav';

function ItemsBar(props) {
  const [readItems, setReadItems] = useState([]);

  const [playSongButton] = useSound(soundButton);

  function initReadItems() {
    let readItemsObj = {};
    props.items.forEach((item) => {
      readItemsObj[item] = false;
      if (item === props.activeItem) {
        readItemsObj[item] = true;
      }
    });
    setReadItems(readItemsObj);
  }

  function minimizeBar() {
    props.minimizeFunction(true);
  }

  function maximizeBar() {
    props.minimizeFunction(false);
  }

  useEffect(() => {
    if (props.isMinimized) {
      minimizeBar();
    }
  }, [props.isMinimized]);

  useEffect(() => {
    initReadItems();
  }, [props.items]);

  return (
    <div className={`itemsBar ${props.isMinimized ? 'minimizedBar' : ''}`}>
      <div className="minMaxBar">
        {!props.blockMinMax &&
          <div>
            <Icon className="icon" name="times" circular color='red' size='small' inverted onClick={minimizeBar} />
            <Icon className="icon" name="square" circular color='green' size='small' inverted onClick={maximizeBar} />
          </div>}
      </div>
      {!props.isMinimized &&
        props.items.map((item, nIndex) => {
          if (item) {
            return (
              <div
                id={item}
                className={`barItem ${readItems[item] ? 'activeItem' : ''}`}
                onClick={() => {
                  props.setActiveItem(item);
                  setReadItems({ ...readItems, [item]: true });
                  playSongButton();
                }}
                key={nIndex}
              >
                <h1 className="itemsHeaderText">{item}</h1>
              </div>
            );
          }
        })}
    </div>
  );
}

export default ItemsBar;

import React, { useState, useEffect } from 'react';
import { Button, Popup, Icon, Input } from 'semantic-ui-react';
import { MathComponent } from 'mathjax-react';
import Draggable from 'react-draggable';
import earth from "../../../../images/earthT.png";
import moon from "../../../../images/moon.png";
import jupiter from "../../../../images/jupiter.png";
import emierre from "../../../../images/emierreT.png";
import StarMessage from '../../../../components/starMessage';
import "../CSSs/physicsGravitation.scss";

function WeightForcePage(props) {

  const [step, setStep] = useState(0);
  const [isMinimized, setIsMinimized] = useState(false);
  const [mass, setMass] = useState(null);
  const [planetWeight, setPlanetWeight] = useState({planet: 'Terra', weight:(9.8*(mass || 0))});
  const [winStar, setWinStar] = useState(null);


  const standardObj = [
    {
      name: 'Força Peso',
      step: 1,
      comp: (
        <>
          <h1 className="presentationItem">Força Peso</h1>

          <p className="presentationItem">
            Na última página, relembramos da Segunda Lei de Newton, substituindo a aceleração pela gravidade a partir daí:
            <MathComponent tex={String.raw`F = m*a`} />
            <MathComponent tex={String.raw`F = m*g`} />
          </p>
          <p className="presentationItem">
            Essa força é denominada <b className="presentationItem">Força Peso</b>, e é o resultado da atração gravitacional de um corpo com grande massa. Ela é responsável por atrair os objetos na superfície de um planeta em <b className="presentationItem">direção ao seu centro</b>, por exemplo. A Força Peso é representada por:
            <MathComponent tex={String.raw`P = m*g`} />
          </p>
          <p className="presentationItem">
            Como <b className="presentationItem">cada planeta tem sua própria aceleração da gravidade</b>, percebemos que <b className="presentationItem">um mesmo objeto de massa constante, terá seu peso diferente,</b> dependendo de em qual planeta se encontra.
          </p>

          <span className="white presentationItem">Unidades</span>
          <Popup
            wide
            inverted
            content={
              <div>
                <p className="presentationItem">
                  <br></br>A força Peso comumente representado pela letra{' '}
                  <b className="presentationItem">
                    <i>P</i>
                  </b>{' '}
                  e a unidade padrão no Sistema Internacional é{' '}
                  <b className="presentationItem">
                    'newtons' (representada por <i>N</i> ).
                  </b>
                </p>

                <p className="presentationItem">
                  <br></br>A massa do corpo é representada pela letra{' '}
                  <b className="presentationItem">
                    <i>m</i>
                  </b>{' '}
                  e a unidade padrão no Sistema Internacional é{' '}
                  <b className="presentationItem">
                    'kilogramas' (representada por <i>kg</i> ).
                  </b>
                </p>

                <p className="presentationItem">
                  <br></br>A gravidade do planeta é representada por{' '}
                  <b className="presentationItem">
                    <i>g</i>
                  </b>{' '}
                  e a unidade padrão no Sistema Internacional é{' '}
                  <b className="presentationItem">
                    'metro por segundo ao quadrado' (representada por <i>m/s²</i> ).
                  </b>
                </p>

              </div>
            }
            trigger={<Icon name="question circle outline" />}
          />

          <div className="presentationItem arrowBtnContainer">
            <Button
              icon
              labelPosition="right"
              onClick={() => {
                setStep(step + 1);
              }}
            >
              Próximo
              <Icon name="right arrow" />
            </Button>
          </div>
        </>
      ),
    },
    {
      name: 'Força Peso',
      step: 2,
      comp: (
        <>
          <h1 className="presentationItem">Força Peso</h1>
          <p className="presentationItem">
            Para entender melhor o conceito da força peso, digite a massa e arraste o personagem pelos diferentes planetas:
          </p>
          <div style={{display: 'flex', justifyContent: 'center', height: '20px', alignItems: 'center'}}>
            <Input focus type="text" placeholder="Massa (kg)" value={mass} onChange={(e)=>{
              if(e.target.value >= 0)
                setMass(e.target.value)
              if(e.target.value < 0)
                setMass(-1*e.target.value)
            }}/>
            <div style={{fontSize: '30px', marginLeft: '20px', display: 'inline-block'}} className="mainFont">{planetWeight.planet + ": " + planetWeight.weight.toFixed(2) + " N"}</div>
          </div>
          <div style={{cursor: 'grab', width:'100%', height: '350px', display: 'flex', justifyContent: 'center'}}>
            <div style={{width: '800px', height:'350px', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <div style={{width: '700px', height: '220px', display: 'flex', justifyContent: 'space-around', alignItems:'center', zIndex: '1'}}>
                <img width="50px" height="50px" src={moon} draggable="false"/>
                <img width="90px" height="90px" src={earth} draggable="false"/>
                <img width="150px" height="150px" src={jupiter} draggable="false"/>
              </div>
              <Draggable
                bounds='parent'
                onDrag={
                  (e, data)=>{
                    if(mass && mass > 0){
                      if(data.x < -147){
                        setPlanetWeight({planet: 'Lua', weight: (1.62*(mass || 0))})
                      }
                      else if(data.x >= -147 && data.x < 79){
                        setPlanetWeight({planet: 'Terra', weight: (9.8*(mass || 0))})
                      }
                      else if(data.x >= 79){
                        setPlanetWeight({planet: 'Júpiter', weight: (24.79*(mass || 0))});
                        setWinStar(true);
                      }
                    }
                    // console.log(e,data)
                  }}
                allowAnyClick={false}
              >
                <img className="physicsGravitation__draggableImage" style={{position: 'absolute', zIndex: '1'}} width="30px" src={emierre} draggable="false"/>
              </Draggable>

            </div>
          </div>

          { winStar && (
            <StarMessage
              show={true}
              message={'O peso em Júpiter'}
              subjectScoring="physics"
              typeScoring="gravitation"
              indexScoring={0}
              xpAward={30}
            />
          )}

          <div className="presentationItem arrowBtnContainer">
            <Button
              icon
              labelPosition="left"
              onClick={() => {
                setStep(step - 1);
              }}
            >
              <Icon name="left arrow" />
              Anterior
            </Button>
          </div>
        </>
      ),
    }
  ];


  useEffect(()=>{
    if(planetWeight && planetWeight.planet === "Lua"){
      setPlanetWeight({planet: 'Lua', weight: (1.62*(mass || 0))});
    }
    else if(planetWeight && planetWeight.planet === "Terra"){
      setPlanetWeight({planet: 'Terra', weight: (9.8*(mass || 0))})
    }
    else if(planetWeight && planetWeight.planet === "Júpiter"){
      setPlanetWeight({planet: 'Júpiter', weight: (24.79*(mass || 0))})
    }
  }, [mass])

  return (
    <div className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`}>
      {/* <div className="minMaxBar">
        <Icon className="icon" name='caret down' onClick={minimizeBar}/>
        <Icon className="icon" name='caret up' onClick={maximizeBar}/>
      </div> */}
      {!isMinimized && standardObj[step].comp}
    </div>
  );
}

export default WeightForcePage;

import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import './babylonReactComp.scss';

function ResponseButton(props) {
  function updateButtons() {
    return props.buttons.map((button, indexButton) => {
      return button.icon ? (
        <Button
          icon
          labelPosition="left"
          onClick={() => {
            props.function(indexButton + 1);
          }}
        >
          <Icon name={button.icon} />
          {button.text}
        </Button>
      ) : (
        <Button
          onClick={() => {
            props.function(indexButton + 1);
          }}
        >
          {button.text}
        </Button>
      );
    });
  }

  return (
    <>
      {props.condition && (
        <div className="buttonPanel__firstSquare">
          <div className="buttonPanel__button">{updateButtons()}</div>
        </div>
      )}
    </>
  );
}

export default ResponseButton;

import React, { useState } from 'react';
import ScalePlot from './speedGraph';

function VelGraph(props) {
  const [isMinimized, setIsMinimized] = useState(false);

  return (
    <div
      className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`}
      key={Math.random()}
    >
      {/* <div className="minMaxBar">
        <Icon className="icon" name='caret down' onClick={minimizeBar}/>
        <Icon className="icon" name='caret up' onClick={maximizeBar}/>
      </div> */}
      {!isMinimized && (
        <>
          <h1 className="presentationItem">Gráfico da Velocidade</h1>
          <p className="presentationItem">
            Quando um objeto está com <b className="presentationItem">aceleração nula</b>,
            observamos que sua <b className="presentationItem">velocidade não se altera</b>, o que
            ocorre no carro em vermelho.
            <br></br>
            <br></br>
            Já para o carro verde, observamos o{' '}
            <b className="presentationItem">aumento de velocidade de maneira linear</b>, visto que a
            taxa desse aumento não se altera, representando a{' '}
            <b className="presentationItem">aceleração constante</b>.<br></br>
          </p>
          <div className="aclGraphMruvContainer">
            <ScalePlot plotSizeX={600} plotSizeY={300} />
          </div>
        </>
      )}
    </div>
  );
}

export default VelGraph;

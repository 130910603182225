import React, { useEffect, useState } from 'react';
import Sticker from '../../components/sticker';
import LoadingSpinner from '../../components/loadingSpinner';
import api from '../../services/api';
import { List, Icon, Image } from 'semantic-ui-react'
import logoGold from '../../images/Stickers/gold.png';

import baseImage from './base64ImageSticker';

import './profile.scss';

function StickerAlbum() {
  const [stickers, setStickers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await api
        .post('getClientStickers', {
          client_id: localStorage.getItem('client_id'),
        })
        .then((res) => {
          let vectorStickers = [];

          for (var i = 0; i < res.data.totalStickers; i++) {
            let foundSticker = res.data.clientStickers.find((e) => e.order === i + 1);
            if (foundSticker) {
              vectorStickers.push({
                name: foundSticker.name,
                identifier: foundSticker.code,
                img: foundSticker.image,
                baseImg: baseImage,
                unlocked: true,
                challengeRoute: '/mapa-de-fases/' + foundSticker.module_id,
                module: foundSticker.module_name,
                description: foundSticker.description,
              });
            } else {
              vectorStickers.push({
                name: 'Blocked',
                identifier: 'Blocked',
                img: baseImage,
                baseImg: baseImage,
                unlocked: false,
                challengeRoute: '/',
                module: 'No module',
                description: 'No description',
              });
            }
          }

          setStickers(vectorStickers);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err.message);
        });
    };
    fetchData();
  }, []);

  return (
    <div className={'profileSubItem'}>
      <h1 className={'presentationItem'}>Álbum de figurinhas</h1>
      {isLoading && <LoadingSpinner loading={true} className="restricted" />}
      {/* <List className="">
                    <List.Item icon={<Icon name='connectdevelop' color='yellow' size='large'/>} content={'questionando.edu@gmail.com'} />
                    <List.Item icon={<Icon name='bookmark' color='yellow' size='large'/>} content={'questionando.edu@gmail.com'} />
                </List> */}
      
      {/* <div className={'stickerAlbum__starContainer'}>
        <List className="">
          <List.Item icon={<Icon name='star' color='yellow' size='large'/>} content={<p className={'stickerAlbum__goldStickerString'}>7</p>} />
        </List>
      </div> */}

      <div
        className={'stickerAlbum__container'}
        style={{ overflow: 'auto', height: '80%', color: 'white', backgroundColor: '#303841' }}
      >
        {stickers.map((sticker) => (
          <Sticker
            route={sticker.challengeRoute}
            name={sticker.name}
            key={sticker.identifier}
            identifier={sticker.identifier}
            img={sticker.img}
            baseImg={sticker.baseImg}
            stickerDescription={sticker.description}
            module={sticker.module}
            unlocked={sticker.unlocked}
          />
        ))}
      </div>

      <div className={'stickerAlbum__stats'}>
         <div className={'stickerAlbum__statsContainer'}>
            <List className="" size='big' animated>
              <List.Item icon={<Icon name='bookmark' color='yellow' size='large'/>} content={<p className={'stickerAlbum__goldStickerString'}>Ouro: 2</p>} />
            </List>
          </div>
          <div className={'stickerAlbum__statsContainer'}>
            <List className="" size='big' animated>
              <List.Item icon={<Icon name='bookmark' color='grey' size='large'/>} content={<p className={'stickerAlbum__silverStickerString'}>Prata: 0</p>} />
            </List>
          </div>
          <div className={'stickerAlbum__statsContainer'}>
            <List className="" size='big' animated>
              <List.Item icon={<Icon name='bookmark' color='orange' size='large'/>} content={<p className={'stickerAlbum__bronzeStickerString'}>Bronze: 12</p>} />
            </List>
          </div>
          <div className={'stickerAlbum__statsContainer'}>
            <List className="" size='big' animated>
              <List.Item icon={<Icon name='rocket' color='teal' size='large'/>} content={<p className={'stickerAlbum__physicsStickerString'}>Física: 10</p>} />
            </List>
          </div>
          <div className={'stickerAlbum__statsContainer'}>
            <List className="" size='big' animated>
              <List.Item icon={<Icon name='dna' color='green' size='large'/>} content={<p className={'stickerAlbum__biologyStickerString'}>Biologia: 4</p>} />
            </List>
          </div>
          <div className={'stickerAlbum__statsContainer'}>
            <List className="" size='big' animated>
              <List.Item icon={<Icon name='flask' color='yellow' size='large' inverted/>} content={<p className={'stickerAlbum__chemistryStickerString'}>Química: 0</p>} />
            </List>
          </div>

      </div>

      
    </div>
  );
}

export default StickerAlbum;

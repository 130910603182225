import Setup from './Setup';
import Loop from './Loop';

function BabylonjsFunctions() {
  /**
   * Setup da Babylonjs
   */
  let onSceneReady = (scene) => {
    Setup({ scene: scene });
  };

  /**
   * Loop interno da Babylonjs
   */
  let onRender = (scene) => {
    Loop({ scene: scene });
  };

  return [onSceneReady, onRender];
}

export default BabylonjsFunctions;

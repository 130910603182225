let PointsTraj = [[376.0045200586319,15],
[376.9958989706356,15],
[377.9999344202224,15],
[379.00337311366457,15],
[380.00353276729584,15],
[380.99429749141564,15],
[382.00685598677956,15],
[383.0009315686766,15],
[383.99681909775245,15],
[385.0040724838618,15],
[385.99157607532106,15],
[386.996809235221,15],
[387.99100069352426,15],
[388.9933839023579,15],
[390.000490608305,15],
[391.0090724226029,15],
[392.0023219997347,15],
[392.99055969467736,15],
[393.9999087918295,15],
[395.00031916753505,15],
[396.00433241398423,15],
[396.99535369873047,15],
[398.00203844709904,15],
[398.992588956753,15],
[399.9967173448822,15],
[400.9978671382305,15],
[401.9949102770879,15],
[403.003125965075,15],
[404.0054838867218,15],
[405.00099803946796,15],
[406.00555191762396,15],
[407.00172558473787,15],
[408.0057450104505,15],
[408.9999644229247,15],
[410.0009153622668,15],
[411.00817619936424,15],
[412.0038096921744,15],
[413.0047299657017,15],
[413.9928512573242,15],
[415.00306924888355,15],
[415.9995525285376,15],
[416.99960716530404,15],
[418.00285366013486,15],
[418.99092471413314,15],
[419.99937631189823,15],
[421.00988804735243,15],
[422.00399301865764,15],
[422.9993596120912,15],
[423.9956282980311,15],
[424.99243954685517,15],
[426.00756045314483,15],
[427.0043717019689,15],
[428.0006403879088,15],
[428.99600698134236,15],
[429.99011195264757,15],
[431.00062368810177,15],
[432.00907528586686,15],
[432.99714633986514,15],
[434.00039283469596,15],
[435.0004474714624,15],
[435.99693075111645,15],
[437.0071487426758,15],
[437.9952700342983,15],
[438.9961903078256,15],
[439.99182380063576,15],
[440.9990846377332,15],
[442.0000355770753,15],
[442.9942549895495,15],
[443.99827441526213,15],
[444.99444808237604,15],
[445.99900196053204,15],
[446.9945161132782,15],
[447.996874034925,15],
[449.0050897229121,15],
[450.0021328617695,15],
[451.0032826551178,15],
[452.007411043247,15],
[452.99796155290096,15],
[454.00464630126953,15],
[454.99566758601577,15],
[455.99968083246495,15],
[457.0000912081705,15],
[458.00944030532264,15],
[458.9976780002653,15],
[459.9909275773971,15],
[460.999509391695,15],
[462.0066160976421,15],
[463.00899930647574,15],
[464.003190764779,15],
[465.00842392467894,15],
[465.9959275161382,15],
[467.00318090224755,15],
[467.9990684313234,15],
[468.99314401322044,15],
[470.00570250858436,15],
[470.99646723270416,15],
[472.0101548524108,15],
[473.0000655797776,15],
[474.0041010293644,15],
[475.00793432071805,15.000000223517418],
[475.99579329788685,15.003548562526703],
[476.999278023839,15.014420449733734],
[478.01007080078125,15.032958984375],
[478.9970703125,15.05859375],
[480.00634747743607,15.092700719833374],
[481.00692677497864,15.134596824645996],
[481.9988082051277,15.184282064437866],
[482.9968215972185,15.242687165737152],
[483.985871553421,15.30911922454834],
[484.98051834106445,15.384750366210938],
[485.99477511644363,15.471242666244507],
[486.9849670678377,15.565074384212494],
[487.97979541122913,15.668965280056],
[488.9788408279419,15.78329086303711],
[489.95396119356155,15.904830694198608],
[490.946734726429,16.038984060287476],
[491.9427289515734,16.184464395046234],
[492.9280824661255,16.339473724365234],
[493.91607362031937,16.50633215904236],
[494.8931305408478,16.682982444763184],
[495.8852128982544,16.87459945678711],
[496.86576199531555,17.076544761657715],
[497.83477783203125,17.288818359375],
[498.81730157136917,17.517417669296265],
[499.78767704963684,17.756896018981934],
[500.74590426683426,18.007253408432007],
[501.7160819172859,18.275331258773804],
[502.67348033189774,18.554853200912476],
[503.62982638180256,18.84952872991562],
[504.57307171821594,19.155936241149902],
[505.53741455078125,19.486083984375],
[506.4652069211006,19.82054114341736],
[507.40170699357986,20.175622701644897],
[508.3241179585457,20.54332137107849],
[509.2538948059082,20.932846069335938],
[510.1689036488533,21.335595846176147],
[511.069144487381,21.75157070159912],
[511.9750339984894,22.190909385681152],
[512.8654606342316,22.644095420837402],
[513.7502783089876,23.116515576839447],
[514.6192796826363,23.603099584579468],
[515.4819612503052,24.109554290771484],
[516.3377838879824,24.636362493038177],
[517.1770713925362,25.17797827720642],
[518.0087731480598,25.740598440170288],
[518.8235743641853,26.318353414535522],
[519.6214750409126,26.911243200302124],
[520.4192657470703,27.53265380859375],
[521.1829979419708,28.156142234802246],
[521.9458773732185,28.80881667137146],
[522.6911134719849,29.477291107177734],
[523.4187062382698,30.16156554222107],
[524.1286556720734,30.861639976501465],
[524.8209617733955,31.57751441001892],
[525.5097873806953,32.32492804527283],
[526.166431427002,33.07273864746094],
[526.8054321408272,33.83634924888611],
[527.426789522171,34.61575984954834],
[528.0305035710335,35.41097044944763],
[528.6165742874146,36.221981048583984],
[529.1850016713142,37.0487916469574],
[529.7357857227325,37.89140224456787],
[530.2577667236328,38.73138427734375],
[530.7736395001411,39.60525870323181],
[531.2614520192146,40.475839376449585],
[531.7323639988899,41.361554861068726],
[532.176685333252,42.24266052246094],
[532.6094992905855,43.14828246831894],
[533.0257781147957,44.06871199607849],
[533.4126070141792,44.97288107872009],
[533.792233467102,45.911922454833984],
[534.1477851867676,46.84410095214844],
[534.4878740310669,47.79012680053711],
[534.8052938580513,48.72803092002869],
[535.1079456806183,49.67916011810303],
[535.3958294987679,50.64351439476013],
[535.6627615094185,51.59820914268494],
[535.9156044125557,52.565521001815796],
[536.1543582081795,53.54544997215271],
[536.3738374710083,54.514217376708984],
[536.5798905491829,55.49500823020935],
[536.7702492028475,56.47564262151718],
[536.9475071430206,57.46800899505615],
[537.1077688485384,58.44717353582382],
[537.2555688023567,59.43749785423279],
[537.389271736145,60.42623519897461],
[537.5108264088631,61.425851583480835],
[537.6175792813301,62.410300970077515],
[537.7127988934517,63.405078649520874],
[537.7964852452278,64.41018462181091],
[537.8668873310089,65.39876461029053],
[537.9263551831245,66.39713644981384],
[537.9748888015747,67.40530014038086],
[538.0116157531738,68.39561462402344],
[538.0379915237427,69.39519882202148],
[538.0540161132812,70.404052734375],
[538.0596717000008,71.39376997947693],
[538.0555431842804,72.39224910736084],
[538.0416305661201,73.39949011802673],
[538.0183412581682,74.40091699361801],
[538.011583045125,75.39610452950001],
[538.0355488806963,76.4044453650713],
[538.0513360053301,77.40460748970509],
[538.0589444190264,78.39659090340137],
[538.0583003163338,79.39570266008377],
[538.0490143299103,80.40155339241028],
[538.0310304760933,81.39870625734329],
[538.003877595067,82.40207095444202],
[537.9677612781525,83.39647221565247],
[537.9219408035278,84.39655017852783],
[537.8668873310089,85.38739514350891],
[537.8005703091621,86.39773827791214],
[537.7244691848755,87.39829730987549],
[537.638583958149,88.38907223939896],
[537.5414770394564,89.38400633633137],
[537.4343044757843,90.3688747882843],
[537.3136180192232,91.37099106609821],
[537.1822908520699,92.36246663331985],
[537.0383043289185,93.35666370391846],
[536.8833835721016,94.3399265408516],
[536.7152124196291,95.3253198415041],
[536.5358095169067,96.29948139190674],
[536.3399375230074,97.28793556988239],
[536.1322267651558,98.26455098390579],
[535.9097480773926,99.24177932739258],
[535.6751211285591,100.20685940980911],
[535.4218586087227,101.18406563997269],
[535.1558168530464,102.14849263429642],
[534.8769958615303,103.1001403927803],
[534.5779418945312,104.06231689453125],
[534.265461742878,105.01106721162796],
[533.9395554065704,105.94639134407043],
[533.591778755188,106.89060688018799],
[533.2299129962921,107.82073330879211],
[532.8493976145983,108.74759097397327],
[532.4544556736946,109.66002207994461],
[532.040185213089,110.56850552558899],
[531.6009933948517,111.48282933235168],
[531.156843483448,112.36143332719803],
[530.6870773434639,113.2451828122139],
[530.1906406879425,114.1330235004425],
[529.6895973682404,114.98549580574036],
[529.1611726880074,115.84134846925735],
[528.6165742874146,116.68102741241455],
[528.055802166462,117.50453263521194],
[527.4788563251495,118.31186413764954],
[526.8723726272583,119.1204195022583],
[526.2558551579714,119.90356023609638],
[525.6228025555611,120.67016583681107],
[524.9659846425056,121.42838698625565],
[524.292266190052,122.16970759630203],
[523.6016471982002,122.89412766695023],
[522.8941276669502,123.60164719820023],
[522.169707596302,124.29226619005203],
[521.4283869862556,124.96598464250565],
[520.6701658368111,125.62280255556107],
[519.9035602360964,126.25585515797138],
[519.1204195022583,126.8723726272583],
[518.3118641376495,127.47885632514954],
[517.504532635212,128.05580216646194],
[516.6810274124146,128.61657428741455],
[515.8413484692574,129.16117268800735],
[514.9854958057404,129.68959736824036],
[514.1330235004425,130.1906406879425],
[513.2451828122139,130.6870773434639],
[512.3715627938509,131.1515920907259],
[511.4828293323517,131.60099339485168],
[510.568505525589,132.040185213089],
[509.6600220799446,132.4544556736946],
[508.7584093213081,132.84483510255814],
[507.82073330879206,133.22991299629211],
[506.89060688018793,133.591778755188],
[505.9463913440704,133.93955540657043],
[505.0110672116279,134.26546174287796],
[504.0623168945312,134.57794189453125],
[503.10014039278025,134.8769958615303],
[502.14849263429636,135.15581685304642],
[501.18406563997263,135.4218586087227],
[500.2068594098091,135.6751211285591],
[499.2417793273925,135.90974807739258],
[498.2645509839058,136.1322267651558],
[497.2879355698824,136.3399375230074],
[496.29948139190674,136.53580951690674],
[495.3253198415041,136.7152124196291],
[494.3399265408516,136.8833835721016],
[493.35666370391846,137.03830432891846],
[492.36246663331985,137.18229085206985],
[491.3709910660982,137.3136180192232],
[490.3688747882843,137.4343044757843],
[489.38400633633137,137.54147703945637],
[488.38907223939896,137.63858395814896],
[487.3982973098755,137.7244691848755],
[486.39773827791214,137.80057030916214],
[485.3873951435089,137.8668873310089],
[484.39655017852783,137.92194080352783],
[483.39647221565247,137.96776127815247],
[482.402070954442,138.00387759506702],
[481.3987062573433,138.0310304760933],
[480.4015533924103,138.04901432991028],
[479.39570266008377,138.05830031633377],
[478.3965909034014,138.05894441902637],
[477.4046074897051,138.05133600533009],
[476.4044453650713,138.0355488806963],
[475.3961045295,138.011583045125],
[474.3948459625244,138.01729011535645],
[473.4064304828644,138.04553055763245],
[472.4046978051774,138.07415149128065],
[471.4082123315893,138.10262250481173],
[470.4071044921875,138.1312255859375],
[469.4046520537813,138.15986708417768],
[468.3988162080641,138.18860525119817],
[467.4089216371067,138.21688795322552],
[466.39909517223714,138.24574013793608],
[465.41357293725014,138.27389791607857],
[464.4065297045745,138.3026705798693],
[463.4016587375663,138.33138117892668],
[462.4045573733747,138.35986978933215],
[461.3995186923421,138.3885851802188],
[460.4109129868448,138.41683105751872],
[459.3995176340104,138.4457280675997],
[458.41097712516785,138.47397208213806],
[457.4038225048571,138.50274792843265],
[456.40211990539683,138.53136800270295],
[455.40801664814353,138.55977095291018],
[454.39990049024345,138.58857427170733],
[453.4026319763507,138.61706765781855],
[452.41797383641824,138.6452007475309],
[451.410974064529,138.67397216958489],
[450.41904805228114,138.70231291279197],
[449.40646533475956,138.7312438475783],
[448.4112265225849,138.75967924221186],
[447.4095140871941,138.78829959750874],
[446.4147348136976,138.81672186246578],
[445.41515896096826,138.84528117254376],
[444.41153986848803,138.87395600375748],
[443.4046308757388,138.90272483212175],
[442.4079758464359,138.93120069010183],
[441.40957315888954,138.95972648117458],
[440.41014790534973,138.98828148841858],
[439.41042517806636,139.0168449949124],
[438.41113006928936,139.0453962837346],
[437.41298767126864,139.07391463796375],
[436.41672307625413,139.10237934067845],
[435.42306137649575,139.13076967495726],
[434.40738469042117,139.1597890088451],
[433.4212175174616,139.1879652137868],
[432.4147532763891,139.2167213349603],
[431.4141172519885,139.24531093565747],
[430.42009175522253,139.2737116641365],
[429.4211762926352,139.3022521059247],
[428.4184787999038,139.33090060571703],
[427.41317194740986,139.35962365864543],
[426.4303310425021,139.38770482735708],
[425.4115264999564,139.41681352857267],
[424.4291720111505,139.4448807996814],
[423.41455482674064,139.47386986209312],
[422.41581679467345,139.5024052344379],
[421.4229433951914,139.53077304585167],
[420.4267752202577,139.55923499370692],
[419.4191693328321,139.58802373334765],
[418.4138794057071,139.61674630269408],
[417.4339363537729,139.6447446756065],
[416.4220315779676,139.6736562406295],
[415.4229985331767,139.70220004190924],
[414.42233261390356,139.7307904967456],
[413.4262319232221,139.75925051647937],
[412.42495520040393,139.7878584228456],
[411.4287498756312,139.81632143212482],
[410.4345349781215,139.84472757205367],
[409.431918207556,139.8733737654984],
[408.42363150790334,139.90218195691705],
[407.4298865837045,139.93057466903701],
[406.4299967698753,139.95914294943213],
[405.4288630001247,139.987746771425],
[404.43021953105927,140.00329166650772],
[403.419828414917,140.02596378326416],
[402.42384672164917,140.07081627845764],
[401.43208265304565,140.1395285129547],
[400.44642448425293,140.23367404937744],
[399.44788694381714,140.35777688026428],
[398.4607195854187,140.51169991493225],
[397.4674677848816,140.701242685318],
[396.4911937713623,140.92527866363525],
[395.534086227417,141.18563175201416],
[394.5811080932617,141.49011611938477],
[393.6368942260742,141.84259414672852],
[392.72188901901245,142.23975777626038],
[391.838538646698,142.68364548683167],
[390.9753680229187,143.18503975868225],
[390.13752698898315,143.74823212623596],
[389.3546748161316,144.357248544693],
[388.61552238464355,145.02302646636963],
[387.92277336120605,145.74781894683838],
[387.28861808776855,146.52143955230713],
[386.71305656433105,147.34388828277588],
[386.203715801239,148.20117592811584],
[385.7511520385742,149.10577774047852],
[385.3670573234558,150.0267469882965],
[385.0461959838867,150.95972061157227],
[384.7751307487488,151.93419098854065],
[384.560546875,152.9150390625],
[384.3975520133972,153.89818787574768],
[384.2814254760742,154.87970352172852],
[384.20654296875,155.875244140625],
[384.17067289352417,156.88295006752014],
[384.17125701904297,157.88034439086914],
[384.2051315307617,158.88513565063477],
[384.26852226257324,159.87383365631104],
[384.3610018491745,160.87659984827042],
[384.47859942913055,161.8697801232338],
[384.6184825897217,162.85101413726807],
[384.7821044921875,163.84185791015625],
[384.9661707878113,164.8293936252594],
[384.8726272583008,165.81377029418945],
[384.7396969795227,166.8125832080841],
[384.63597297668457,167.7909517288208],
[384.5580792427063,168.8029968738556],
[384.51282501220703,169.79219436645508],
[384.50038731098175,170.79723864793777],
[384.5243549346924,171.80267810821533],
[384.58653450012207,172.79430866241455],
[384.6898579597473,173.78301739692688],
[384.83926594257355,174.77828532457352],
[385.0367856025696,175.7657539844513],
[385.28247117996216,176.7324459552765],
[385.5814838409424,177.68768787384033],
[385.9287762641907,178.60913395881653],
[386.34305000305176,179.53545093536377],
[386.8047457933426,180.4156330227852],
[387.32959747314453,181.27748489379883],
[387.9018712043762,182.09320187568665],
[388.52681159973145,182.872052192688],
[389.19693291187286,183.606336414814],
[389.9186062812805,184.30453419685364],
[390.6746608018875,184.95278626680374],
[391.4618420600891,185.55337071418762],
[392.2962361574173,186.1209061741829],
[393.1575131416321,186.64374470710754],
[394.0318989753723,187.11858868598938],
[394.92603302001953,187.55373001098633],
[395.8369827270508,187.95122146606445],
[396.7860794067383,188.3220100402832],
[397.72305488586426,188.64952564239502],
[398.6684536933899,188.94526600837708],
[399.64649200439453,189.21815872192383],
[400.601704120636,189.4552719593048],
[401.5861225128174,189.67194080352783],
[402.5703811645508,189.86284255981445],
[403.55204820632935,190.02967953681946],
[404.5442181825638,190.17622500658035],
[405.5298924446106,190.30143857002258],
[406.5230178833008,190.40849685668945],
[407.5224858522415,190.49817591905594],
[408.5272055864334,190.5712392926216],
[409.51883137226105,190.62758952379227],
[410.51289558410645,190.6692934036255],
[411.52632534503937,190.69744139909744],
[412.522554397583,190.7117509841919],
[413.51821184158325,190.71352124214172],
[414.51233208179474,190.70342808961868],
[415.52319526672363,190.68161487579346],
[416.5117198228836,190.64955979585648],
[417.51574754714966,190.60665249824524],
[418.51513862609863,190.55405139923096],
[419.5089989900589,190.49238234758377],
[420.51717042922974,190.42069554328918],
[421.50814011693,190.3416407853365],
[422.5125581026077,190.2531722187996],
[423.4980756044388,190.15852481126785],
[424.4962054491043,190.05504876375198],
[425.4946655780077,190.03596432507038],
[426.4952516555786,190.11261463165283],
[427.48805709183216,190.1939333230257],
[428.47308188676834,190.279920399189],
[429.4668224453926,190.37215262651443],
[430.46887643635273,190.47087140381336],
[431.4626133441925,190.5745804309845],
[432.46411830186844,190.68510323762894],
[433.45703125,190.80078125],
[434.4413521885872,190.9216144680977],
[435.432748273015,191.049677208066],
[436.43079705536366,191.1852229386568],
[437.4196905642748,191.32626183331013],
[438.4146645665169,191.47512704133987],
[439.40019495785236,191.62965841591358],
[440.37628173828125,191.78985595703125],
[441.3577225804329,191.95831567049026],
[442.358720600605,192.137990295887],
[443.3348894864321,192.32108779251575],
[444.31536860764027,192.51307375729084],
[445.29970198869705,192.71422177553177],
[446.27339370548725,192.92175479233265],
[447.2503227740526,193.13882000744343],
[448.23001980781555,193.36569905281067],
[449.19844932854176,193.59933860599995],
[450.16901202499866,193.8431728631258],
[451.1412250995636,194.09749150276184],
[452.10152693092823,194.35895688831806],
[453.0628264695406,194.63129825890064],
[454.02462750673294,194.91481333971024],
[454.973855689168,195.2058721333742],
[455.93531638383865,195.5125406384468],
[456.871295273304,195.8230236172676],
[457.81848073005676,196.14973187446594],
[458.7639481574297,196.48894555866718],
[459.695465862751,196.8365290760994],
[460.6245592236519,197.19704180955887],
[461.56203389167786,197.5754749774933],
[462.4844792485237,197.96292573213577],
[463.39189529418945,198.35939407348633],
[464.30585980415344,198.77488255500793],
[465.21465070545673,199.20494981110096],
[466.1072924733162,199.64470654726028],
[466.99400067329407,200.09949803352356],
[467.88421750068665,200.575133562088],
[468.75712513923645,201.06115460395813],
[469.6127235889435,201.5575611591339],
[470.4698637127876,202.07599192857742],
[471.3273385167122,202.6171711087227],
[472.15688176453114,203.16363461315632],
[472.9855325818062,203.7335827946663],
[473.7862518429756,204.30881530046463],
[474.58483815193176,204.90827679634094],
[475.3800308704376,205.53272366523743],
[476.1546242237091,206.16953015327454],
[476.9086182117462,206.81869626045227],
[477.6570728421211,207.49413520097733],
[478.3840608596802,208.1824541091919],
[479.08958226442337,208.88365298509598],
[479.7736370563507,209.59773182868958],
[480.44952392578125,210.33966064453125],
[481.103059053421,211.0950005054474],
[481.74663811922073,211.87926322221756],
[482.35502272844315,212.6616933941841],
[482.95254826545715,213.4735882282257],
[483.5157823562622,214.28310871124268],
[484.0672543644905,215.1226356625557],
[484.5954716205597,215.97611546516418],
[485.10043412446976,216.84354811906815],
[485.5729201436043,217.70751744508743],
[486.03182911872864,218.60258221626282],
[486.4674833416939,219.51159983873367],
[486.87202006578445,220.41633874177933],
[487.25420504808426,221.33448868989944],
[487.6210069656372,222.28481769561768],
[487.95151978731155,223.21102172136307],
[488.26664954423904,224.16940480470657],
[488.5537910461426,225.12163162231445],
[488.81946593523026,226.08673840761185],
[489.0636742115021,227.06472516059875],
[489.28208470344543,228.03524136543274],
[489.4798958301544,229.01811718940735],
[489.6536257863045,229.99249249696732],
[489.8076057434082,230.97871780395508],
[489.9418357014656,231.97679311037064],
[490.0540855526924,232.96510726213455],
[490.1474168896675,233.9647725224495],
[490.22112257778645,234.96473856270313],
[490.2758128196001,235.96463926136494],
[490.31209111213684,236.9641125202179],
[490.33055424690247,237.96280026435852],
[490.3317081928253,238.9718735218048],
[490.3161123394966,239.96804744005203],
[490.2844537049532,240.96238441765308],
[490.23664593696594,241.96641087532043],
[490.17353534698486,242.96814441680908],
[490.0967147946358,243.95515471696854],
[490.00486493110657,244.95118260383606],
[490,245.94980285968632],
[490,246.9523587403819],
[490,247.9564005555585],
[490,248.94587908580434],
[490,249.94155197869986],
[490,250.9485028294148],
[490,251.9426217675209],
[490,252.9535094514722],
[490,253.94385806226637],
[490,254.95622154325247],
[490,255.95639203151222],
[490,256.9514395151782],
[490,257.9511798877502],
[490,258.9516853675741],
[490,259.94927224179264],
[490,260.9575091098668],
[490,261.95697067771107],
[490,262.94456583913416],
[490,263.95381927955896],
[490,264.9470375740202],
[490,265.95941454172134],
[490,266.9519543321803],
[490,267.96138402074575],
[490,268.9475174463587],
[490,269.9484872771427],
[490,270.9434638577659],
[490,271.95196293701883],
[490,272.952645541518],
[490,273.9443946045503],
[490,274.9476127466187],
[490,275.9511339452456],
[490,276.94334495812654],
[490,277.9564909049077],
[490,278.95708706251753],
[490,279.94423944204755],
[490,280.9511833304623],
[490,281.9435757560859],
[490,282.9552390626486],
[490,283.95130279881414],
[490,284.9544294889347],
[490,285.95259433669526],
[490,286.95718690229114],
[490,287.9560459032655],
[490,288.9607267646352],
[490,289.94688558937924],
[490,290.9502854979746],
[490,291.958714229404],
[490,292.959717899721],
[490,293.952917923516],
[490,294.9502908065915],
[490,295.95163354498254],
[490,296.9567431347241],
[490,297.9529429059039],
[490,298.95242349368345],
[490,299.95498932032206],
[490,300.96044480807905],
[490,301.943359375],
[490,302.95394737273455],
[490,303.9414942351141],
[490,304.9564518674015],
[490,305.9478872217005],
[490,306.9537096752447],
[490,307.9610316557955],
[490,308.9568899059668],
[490,309.9538450905675],
[490,310.95171593653504],
[490,311.950321170807],
[490,312.9494795203209],
[490,313.94900971201423],
[490,314.94873047282454],
[490,315.9484605296893],
[490,316.9480186095461],
[490,317.94722343933245],
[490,318.9458937459858],
[490,319.94384825644374],
[490,320.95368187155145],
[490,321.9496459686252],
[490,322.95709173537034],
[490,323.95033100096043],
[490,324.94194345701544],
[490,325.9571020454314],
[490,326.9448636705056],
[490,327.95569125097245],
[490,328.9514468269681],
[490,329.94459193861985],
[490,330.9474555323686],
[490,331.9472534450324],
[490,332.9437900988705],
[490,333.9492607083139],
[490,334.95098451618105],
[490,335.9610511826031],
[490,336.9423797113268],
[490,337.94383017288055],
[490,338.9527320695106],
[490,339.9566261954169],
[490,340.9432939562248],
[490,341.9485174724832],
[490,342.94794069079217],
[490,343.95312613961323],
[490,344.95190594949236],
[490,345.9556773066288],
[490,346.95240831126284],
[490,347.9418559977785],
[490,348.94652128219604],
[490,349.943045838736],
[490,350.95352703014214],
[490,351.95494250569027],
[490,352.9470137962926],
[490,353.95118319844187],
[490,354.9450000718207],
[490,355.9494196571177],
[490,356.94240391581843],
[490,357.94438283402997],
[490,358.9542415452888],
[490,359.9506144440238],
[490,360.9530469059973],
[490,361.9602802814916],
[490,362.95171223457146],
[490,363.94589472525695],
[490,364.960021303923],
[490,365.9550337644032],
[490,366.94832634122577],
[490,367.95574563780974],
[490,368.95728315641463],
[490,369.9510981468484],
[490,370.9514381108602],
[490,371.9548585638404],
[490,372.9425084218383],
[490,373.95598995964974],
[490,374.9596540775383],
[490,375.9489615383791],
[490,376.9446388602955],
[490,377.9614343121648],
[490,378.96192580868956],
[490,379.9553522915812],
[490,380.96105575561523],
[490,381.94570153951645],
[490,382.95335025235545],
[490,383.9535975456238],
[490,384.94277238845825],
[489.98926669359207,385.95059126615524],
[489.95439797639847,386.9452950358391],
[489.893981218338,387.9442012310028],
[489.8065948486328,388.9464569091797],
[489.69313740730286,389.9330699443817],
[489.55077916383743,390.92187374830246],
[489.37804490327835,391.911983191967],
[489.17343258857727,392.90249705314636],
[488.9441215991974,393.85866045951843],
[488.67706298828125,394.83123779296875],
[488.37992787361145,395.78576922416687],
[488.052716255188,396.7222547531128],
[487.6890042424202,397.6563712954521],
[487.29416966438293,398.57181429862976],
[486.8607246875763,399.4836223125458],
[486.39509320259094,400.37611842155457],
[485.90583592653275,401.23490780591965],
[485.37636786699295,402.08910197019577],
[484.82433795928955,402.91022777557373],
[484.2310333251953,403.7261199951172],
[483.6162307858467,404.509582221508],
[482.97030568122864,405.2743709087372],
[482.2932580113411,406.02048605680466],
[481.5973499417305,406.7357537150383],
[480.8841371536255,407.4211072921753],
[480.1285946369171,408.10059428215027],
[479.3705666065216,408.73952746391296],
[478.58450055122375,409.36163783073425],
[477.785179913044,409.9562641978264],
[476.95881366729736,410.5346632003784],
[476.1369088292122,411.0766765475273],
[475.27366667985916,411.6134187579155],
[474.4177559018135,412.1154972910881],
[473.53766947984695,412.6030704379082],
[472.651025056839,413.0671775341034],
[471.75919055938715,413.5086393356323],
[470.863507091999,413.92826050519943],
[469.946357011795,414.335001707077],
[469.027110338211,414.72095370292664],
[468.08726429939264,415.0945460796356],
[467.14703828096384,415.44837921857834],
[466.2076929211616,415.783209502697],
[465.27046203613276,416.0997772216797],
[464.3151262402534,416.40548199415207],
[463.36351424455637,416.69388979673386],
[462.3945930600166,416.97191208601],
[461.4309692382812,417.23358154296875],
[460.46230621635914,417.4824618548155],
[459.48917396366596,417.7188950031996],
[458.51213574409485,417.9432189464569],
[457.5317481160164,418.15576761960983],
[456.5607884526252,418.35444182157516],
[455.575523674488,418.54453295469284],
[454.60111737251276,418.7216079235077],
[453.613112270832,418.89052361249924],
[452.61150836944574,419.0512800216675],
[451.6225063800811,419.2000663280487],
[450.6471255421638,419.3374940752983],
[449.64607484638685,419.46928553283215],
[448.65964442491526,419.5903179049492],
[447.66130276024336,419.70420353114605],
[446.66496939957136,419.80952851474285],
[445.671127140522,419.9065825343132],
[444.6802520751953,419.9956512451172],
[443.67842465639114,420.0781485438347],
[442.68019028007984,420.1530360430479],
[441.6860116273165,420.2205913513899],
[440.6963446736336,420.28108805418015],
[439.6816471219063,420.33633202314377],
[438.68690967559814,420.3840208053589],
[437.68243581056595,420.42586773633957],
[436.69910222291946,420.4608675837517],
[435.6910330802202,420.49079172313213],
[434.6892458945513,420.51471941173077],
[433.694169819355,420.532908141613],
[432.690222337842,420.5457740277052],
[431.69354923069477,420.55323891341686],
[430.6882841140032,420.5555485934019],
[429.6908476948738,420.5527898669243],
[428.7016490101814,420.54520815610886],
[427.6877147704363,420.5325507372618],
[426.6991117596626,420.5155608057976],
[425.6857731938362,420.4934951663017],
[424.6985638141632,420.4675757884979],
[423.7038353085518,420.4371449351311],
[422.70158767700195,420.4022026062012],
[421.6918209195137,420.3627488017082],
[420.69213815033436,420.3195797652006],
[419.70292158424854,420.2729248255491],
[418.68884205818176,420.22111773490906],
[417.70345993340015,420.1669978350401],
[416.71131640672684,420.1088210940361],
[415.6941869854927,420.0454184412956],
[414.70741748809814,420.00030040740967],
[413.71358901262283,420.0058600306511],
[412.7048709988594,420.0188276171684],
[411.7056158185005,420.0391551852226],
[410.7158234715462,420.0668427348137],
[409.71226811408997,420.10281443595886],
[408.70713599026203,420.14705412089825],
[407.71215468645096,420.19918888807297],
[406.71606332063675,420.2599546313286],
[405.70807576179504,420.33045649528503],
[404.71095114946365,420.4094073176384],
[403.7246894836426,420.4968070983887],
[402.7277386188507,420.594881772995],
[401.73148818314075,420.70299558341503],
[400.7468369603157,420.82013100385666],
[399.74258929491043,420.95050781965256],
[398.7507012486458,421.09049767255783],
[397.77117282152176,421.2401005625725],
[396.78398609161377,421.40273571014404],
[395.7899296283722,421.57901644706726],
[394.8092842102051,421.7657279968262],
[393.8420498371124,421.9628703594208],
[392.85035252571106,422.17896342277527],
[391.89181596040726,422.4018594622612],
[390.91047406196594,422.6449954509735],
[389.9442008137703,422.899851500988],
[388.975193798542,423.1715700030327],
[388.0218294262886,423.4554550051689],
[387.066890001297,423.75710368156433],
[386.1281779408455,424.07137364149094],
[385.17245411872864,424.41038966178894],
[384.25047039985657,424.7566592693329],
[383.31329345703125,425.12908935546875],
[382.39416241645813,425.5155551433563],
[381.4776995778084,425.9230849146843],
[380.5799102783203,426.3451385498047],
[379.68605518341064,426.78924083709717],
[378.79709988832474,427.2561427950859],
[377.91402608156204,427.7466079592705],
[377.0653200149536,428.2448148727417],
[376.20974093675613,428.7756535410881],
[375.3754532337189,429.32305216789246],
[374.5497238636017,429.89609599113464],
[373.7459671497345,430.48622965812683],
[372.952142059803,431.10307663679123],
[372.18098163604736,431.73755168914795],
[371.42114758491516,432.3998248577118],
[370.69566518068314,433.0698272585869],
[369.98221188783646,433.7681743502617],
[369.281849861145,434.4956922531128],
[368.6154854297638,435.2306640148163],
[367.9629257321358,435.99536150693893],
[367.33444690704346,436.77878856658936],
[366.7388927936554,437.5688350200653],
[366.1582162976265,438.3894416689873],
[365.6016206741333,439.228777885437],
[365.0768768787384,440.0738990306854],
[364.57550048828125,440.93719482421875],
[364.09749150276184,441.818665266037],
[363.6495640873909,442.7045437693596],
[363.2243013381958,443.6080503463745],
[362.82170325517654,444.52918499708176],
[362.4474433064461,445.4533717036247],
[362.0951560139656,446.3946482539177],
[361.7698335647583,447.337908744812],
[361.4704620838165,448.2823646068573],
[361.19171142578125,449.24285888671875],
[360.93358159065247,450.21939158439636],
[360.6997397542,451.1958262324333],
[360.48920422792435,452.17139929533005],
[360.3010094165802,453.1453597545624],
[360.13148814439774,454.1338440775871],
[360.01702047884464,455.1195741444826],
[360.15496641397476,456.12306147813797],
[360.2809679508209,457.10195660591125],
[360.401291847229,458.1066942214966],
[360.5096713453531,459.08683963119984],
[360.6113624572754,460.0920295715332],
[360.7025185972452,461.0889024287462],
[360.7831397652626,462.0774582028389],
[360.854287147522,463.0736970901489],
[360.91543413698673,464.077203348279],
[360.9653440117836,465.0718382000923],
[361.0045400261879,466.07317715883255],
[361.0321393609047,467.0653608441353],
[361.0484552383423,468.0789613723755],
[361.0524383187294,469.08282548189163],
[361.04408860206604,470.0769531726837],
[361.0229978710413,471.0761845856905],
[360.9891977906227,472.0653823018074],
[360.9411046653986,473.0736208707094],
[360.87953209877014,474.07121777534485],
[360.80329328775406,475.0723984837532],
[360.7131814956665,476.0626268386841],
[360.6076107174158,477.05581314861774],
[360.48597790300846,478.05148132145405],
[360.34767150878906,479.0491485595703],
[360.1964807510376,480.02148151397705],
[360.0257959961891,481.00874930620193],
[359.836985245347,481.9968695193529],
[359.632248878479,482.9724168777466],
[359.4085371494293,483.9481461048126],
[359.16847229003906,484.9109649658203],
[358.9050856232643,485.88569670915604],
[358.6244738101959,486.84682965278625],
[358.3266368508339,487.79436379671097],
[358.00326973199844,488.75206738710403],
[357.6661632210016,489.6837616711855],
[357.3021760582924,490.6245592236519],
[356.9244495034218,491.53934746980667],
[356.518474817276,492.4621593952179],
[356.0934630036354,493.369942009449],
[355.6494140625,494.2626953125],
[355.1863279938698,495.1404193043709],
[354.6922078728675,496.023967564106],
[354.1905832290649,496.87126636505127],
[353.65698456764216,497.7236473560333],
[353.1034088134765,498.5602569580078],
[352.52985596656794,499.38109517097473],
[351.93632602691645,500.1861619949341],
[351.32281899452204,500.97545742988586],
[350.6893348693847,501.7489814758301],
[350.03587365150446,502.5067341327667],
[349.3624353408813,503.2487154006958],
[348.6773923784494,503.9663726836443],
[347.97284513711924,504.6686318516731],
[347.2399511933326,505.3636744618415],
[346.5052378177642,506.02695584297174],
[345.7421777397394,506.68302066624165],
[344.96915794909,507.31606014072895],
[344.18687492609024,507.92662411928177],
[343.3760130405426,508.5297882556915],
[342.5665819644928,509.10375714302063],
[341.7390310764313,509.66342091560364],
[340.89336037635803,510.20877957344055],
[340.0404790788889,510.7332832366228],
[339.1699281334877,511.24383717775345],
[338.3044121414423,511.7280412465334],
[337.42210984230036,512.1989929676056],
[336.5348052978515,512.6508331298828],
[335.6311476230621,513.0897629261017],
[334.72334839403624,513.510260656476],
[333.812044635415,513.9128292351961],
[332.8978648781776,514.2979648709297],
[331.9557631015777,514.6762192249298],
[331.02424263954157,515.032502412796],
[330.0912872701883,515.3724903613329],
[329.1440597176551,515.701210796833],
[328.19620132446283,516.0142707824707],
[327.2344699501991,516.3163784146309],
[326.28693252801895,516.5993669629097],
[325.3263035416603,516.8720200657845],
[324.3525829911232,517.1343377232552],
[323.3803776651621,517.3826891928911],
[322.41025663912296,517.6175237447023],
[321.44278049468994,517.8392839431763],
[320.46334244310856,518.0516005307436],
[319.4719424843788,518.2544735074043],
[318.49963165819645,518.4420920163393],
[317.5160837173462,518.6208391189575],
[316.53717666864395,518.7880876660347],
[315.5473864078522,518.9467442035675],
[314.54671293497086,519.0968087315559],
[313.5679569840431,519.2338517308235],
[312.5790086388588,519.3628481030464],
[311.5798678994179,519.4837978482246],
[310.5874405056238,519.5948851853609],
[309.58515763282776,519.6981918811798],
[308.5902562737465,519.792163670063],
[307.6032290607691,519.8771894723177],
[306.6070032119751,519.9549531936646],
[305.60157872736454,520.0254548341036],
[304.6048352867365,520.0876475125551],
[303.61724853515625,520.1419067382812],
[302.6029062271118,520.1902055740356],
[301.6163817048073,520.2301409840584],
[300.60310162603855,520.2641160041094],
[299.6185651421547,520.2904585003853],
[298.60727310180664,520.3108406066895],
[297.606680765748,520.3245074301958],
[296.6172382980585,520.3318143635988],
[295.6008902192116,520.3330424427986],
[294.6153865009546,520.3282964974642],
[293.60297717154026,520.3174716979265],
[292.60260105133057,520.3009843826294],
[291.61469131708145,520.2791765332222],
[290.61970353126526,520.2517807483673],
[289.617637693882,520.2187970280647],
[288.60849380493164,520.1802253723145],
[287.6126656681299,520.1370037347078],
[286.6100397706032,520.0884154438972],
[285.6212845444679,520.0356152653694],
[284.61497440352105,520.0098724511918],
[283.6239945644047,520.0352821906563],
[282.6222675952886,520.0609674975567],
[281.6154098510742,520.086784362793],
[280.61898597670734,520.1123336929049],
[279.6194265844497,520.1379634209115],
[278.6228657606989,520.1635162625462],
[277.62340900386334,520.1891433588753],
[276.62109375,520.21484375],
[275.62761770459474,520.240317494754],
[274.61928744378383,520.266172116826],
[273.61948990752353,520.2918079510891],
[272.61683753618854,520.3175169862516],
[271.6160648477853,520.3431778244158],
[270.6216123768536,520.3686766057217],
[269.61727172951214,520.3944289300125],
[268.6262281460222,520.4198403039481],
[267.630779568517,520.4453646264483],
[266.6329361240605,520.4709503557933],
[265.6234055794698,520.4968357543726],
[264.61477220824236,520.5226981485066],
[263.62049151284737,520.5481925253116],
[262.6310205459595,520.5735635757446],
[261.6237698022451,520.5993905178912],
[260.6236531581999,520.6250345344051],
[259.6321712583631,520.6504571472215],
[258.62540024327245,520.676271788634],
[257.62933804813656,520.7018118449196],
[256.6192530868102,520.7277114593126],
[255.6218204848301,520.7532866542351],
[254.62492368867743,520.7788481105467],
[253.62928288718877,520.804377361867],
[252.6355940006033,520.8298565640871],
[251.63068859640362,520.855623369323],
[250.62876374490543,520.8813137501306],
[249.63046020347974,520.9069112768339],
[248.6363963252188,520.9324000942252],
[247.63276451267302,520.9581342432648],
[246.63428416620036,520.9837363034308],
[245.62685940478332,521.0095677075697],
[244.62545426898942,521.0352447623336],
[243.63060818004442,521.0607536364091],
[242.62781591023668,521.0864662587119],
[241.63237720198595,521.1119903281542],
[240.62953598680906,521.1377042054664],
[239.63480296722764,521.1632101803275],
[238.63318453853935,521.18889270414],
[237.6403903229766,521.2143489660775],
[236.64120205639847,521.2399691780411],
[235.63575921367374,521.265749763752],
[234.62420126967118,521.2916871469315],
[233.63855468924157,521.3169601361733],
[232.6313967775809,521.3427846980107],
[231.63482931503268,521.368337709871],
[230.63303632275392,521.394024709673],
[229.64242458343506,521.4194250106812],
[228.63064012826726,521.4453682018393],
[227.63044358694287,521.4710142670015],
[226.6421835620372,521.4963542676401],
[225.6330371745571,521.522229816037],
[224.6362077165395,521.5477895457298],
[223.63530229617027,521.5734537872777],
[222.6304358371999,521.599219593918],
[221.63856603379145,521.6246521529797],
[220.64308652379623,521.6501772686206],
[219.64410658001498,521.675792138974],
[218.64173547524842,521.7014939621731],
[217.6360824822973,521.7272799363514],
[216.64438144219957,521.7527081681487],
[215.63254350199713,521.778652730718],
[214.6349735597505,521.8042314471859],
[213.63465085742064,521.8298807472456],
[212.63167920520573,521.8555979690973],
[211.64351989101533,521.8809353874099],
[210.63560298202674,521.9067794107173],
[209.64278421206473,521.9322363022548],
[208.64780463616853,521.9577485990726],
[207.65076278708875,521.98331377469],
[206.63421384719186,522.0093791321233],
[205.65088640038084,522.0345926564005],
[204.6482489282539,522.060301309532],
[203.64394331394578,522.0860527355399],
[202.63806809020707,522.1118444079434],
[201.6484066485532,522.1372203423448],
[200.63971103476433,522.1630843324419],
[199.64746891006143,522.1885264382036],
[198.65408885294528,522.2139977217194],
[197.64189779816888,522.2399513385085],
[196.64650605400539,522.2654742037435],
[195.65025835774804,522.291019016468],
[194.65324814666246,522.3165833808548],
[193.63774764700793,522.3426218552049],
[192.6394832879305,522.3682183772326],
[191.64073839434423,522.3938272206578],
[190.64160640351474,522.4194459896535],
[189.64218075270765,522.4450722883921],
[188.64255487918854,522.4707037210464],
[187.64282222022302,522.4963378917892],
[186.6430762130767,522.5219724047929],
[185.6434102950152,522.5476048642304],
[184.6439179033041,522.5732328742743],
[183.644692475209,522.5988540390972],
[182.64582744799554,522.6244659628719],
[181.6474162589293,522.650066249771],
[180.64955234527588,522.6756525039673],
[179.6523291443009,522.7012223296333],
[178.65584009326994,522.7267733309418],
[177.66017862944864,522.7523031120654],
[176.64768390941163,522.7782645151433],
[175.65397689635347,522.8037441821448],
[174.64366515136135,522.8296496115036],
[173.6522930420142,522.8550694091791],
[172.66221904324993,522.880455921968],
[171.65589476612922,522.9062591085608],
[170.6511107114784,522.9320228022698],
[169.64796541204805,522.9577444766142],
[168.6465574005888,522.9834216051131],
[167.66450518176498,523.0086024312368],
[166.6493473725859,523.0346321186516],
[165.65374242154348,523.0601604507297],
[164.66026888947454,523.0856341310391],
[163.65165556650027,523.1114960111154],
[162.6627822007208,523.136851738443],
[161.65905380487175,523.1625883639776],
[160.65794393178066,523.1882578479031],
[159.65955639164576,523.2138575284193],
[158.66399499466525,523.2393847437265],
[157.654275521636,523.2652749866247],
[156.66473105817204,523.2906479215853],
[155.66134711602672,523.3163757149737],
[154.66132099740207,523.342017410323],
[153.66476197509883,523.3675702057667],
[152.65498056251317,523.3934620368586],
[151.66574696149837,523.4188270009872],
[150.663641162555,523.444522021473],
[149.6655768488381,523.4701134141324],
[148.6716689440982,523.4955982322026],
[147.66557527141458,523.5213955058612],
[146.66401726193726,523.5470764804631],
[145.66711568190613,523.5726380594383],
[144.65876743889794,523.5984931425924],
[143.67162231705151,523.623804555973],
[142.6734400071473,523.6493989741757],
[141.6646013978857,523.6752666308234],
[140.66138208596385,523.700990202924],
[139.66391510998847,523.7265662792311],
[138.67233350856623,523.7519914484983],
[137.67117570429036,523.7776621614285],
[136.66087563221708,523.8035672914816],
[135.65727214314393,523.8293007142784],
[134.6757935754431,523.8544668313989],
[133.67073937575697,523.8802374519037],
[132.67304133041762,523.9058194530662],
[131.6679080797735,523.9315921005186],
[130.67067324214804,523.9571622245603],
[129.66679486211433,523.9829026958432],
[128.67138455776512,524.0084260369804],
[127.67016349532469,524.0340983719148],
[126.67800843715668,524.0595382452011],
[125.6667434684664,524.0854681161932],
[124.66545317847704,524.1111422261929],
[123.66044199219687,524.1369117437898],
[122.66608285850452,524.1624081318332],
[121.66899186231831,524.1879745676329],
[120.66983868242232,524.2135938799379],
[119.66931664050207,524.2392482912692],
[118.66814329172485,524.2649194027763],
[117.67997549774009,524.2902570385195],
[116.6795717131572,524.3159084176114],
[115.66826708380177,524.3418393055435],
[114.67216984614424,524.3673802603553],
[113.66723766710493,524.3931477521255],
[112.67886231444209,524.4184907098861],
[111.67211683042524,524.4443046966558],
[110.6838037725538,524.469646057114],
[109.68037711805664,524.4953749456909],
[108.67532055604897,524.521145626768],
[107.67046252340151,524.5469112173487],
[106.66771663250984,524.5726226504485],
[105.6690846082347,524.5982285997889],
[104.67665928868882,524.6236754028541],
[103.6732458298502,524.6494039530808],
[102.68173588861828,524.6748272849072],
[101.6864764422644,524.7003467578907],
[100.67466427912223,524.7262906595097],
[99.67008031904697,524.7520492225885],
[98.67806408123215,524.7774855363787],
[97.67423494035029,524.8032247451192],
[96.68410848811618,524.8286126028688],
[95.67791217123158,524.85441250843],
[94.67566083767451,524.8801112605724],
[93.67835659417324,524.905683164252],
[92.68237232230604,524.931221222505],
[91.68578624725342,524.9567747116089],
[90.68684294703417,524.9823886423837],
[89.68314099311829,524.9363219738007],
[88.71476101875305,524.7378623485565],
[87.73246479034424,524.5304822921753],
[86.76058888435364,524.3190944194794],
[85.77581334114075,524.0983951091766],
[84.80197262763977,523.8734900951385],
[83.82766944169998,523.6416265368462],
[82.86456298828125,523.4054565429688],
[81.8792610168457,523.1564140319824],
[80.92794728279114,522.9085719585419],
[79.94466018676758,522.6444816589355],
[78.995361328125,522.381591796875],
[78.03690075874329,522.1079528331757],
[77.07011532783508,521.8232429027557],
[76.11644673347473,521.533488035202],
[75.15559387207031,521.2322235107422],
[74.20843434333801,520.92569231987],
[73.2552559375763,520.607203245163],
[72.31635975837708,520.2832210063934],
[71.37263488769531,519.9468231201172],
[70.42499256134033,519.597659111023],
[69.49284744262695,519.2425346374512],
[68.57619953155518,518.8814496994019],
[67.63932514190674,518.4995985031128],
[66.71921253204346,518.1113004684448],
[65.79865384101868,517.7089011669159],
[64.8955078125,517.2998046875],
[63.99322986602783,516.8761014938354],
[63.09282422065735,516.4374053478241],
[62.195313692092896,515.9833228588104],
[61.31724286079407,515.5217635631561],
[60.44345569610596,515.0442838668823],
[59.560221910476685,514.5418918132782],
[58.69856643676758,514.0312004089355],
[57.85848927497864,513.5122096538544],
[57.01262283325195,512.9668998718262],
[56.17652606964111,512.4035596847534],
[55.3642578125,511.8310546875],
[54.56329655647278,511.23992800712585],
[53.77481389045715,510.6297290325165],
[53,510],
[52.24006366729736,509.35027599334717],
[51.49623227119446,508.68008494377136],
[50.76975178718567,507.9889476299286],
[50.06188678741455,507.2763776779175],
[49.37392044067383,506.5418815612793],
[48.716328620910645,505.79570293426514],
[48.080371141433716,505.0269305706024],
[47.467368364334106,504.23505663871765],
[46.87865924835205,503.41956615448],
[46.33037281036377,502.602801322937],
[45.80034255981445,501.7504692077637],
[45.3042573928833,500.88508129119873],
[44.83610463142395,499.99467730522156],
[44.4028205871582,499.0908622741699],
[44.00440526008606,498.1736361980438],
[43.64085865020752,497.24299907684326],
[43.31218075752258,496.29895091056824],
[43.01837158203125,495.34149169921875],
[42.762654304504395,494.3836545944214],
[42.5408821105957,493.4127616882324],
[42.353055000305176,492.42881298065186],
[42.19917297363281,491.4318084716797],
[42.08203125,490.44921875],
[41.99607014656067,489.4403636455536],
[41.94415283203125,488.44696044921875],
[41.923484086990356,487.4415385723114],
[41.9333381652832,486.4529228210449],
[41.97349262237549,485.43838024139404],
[41.97949397563934,484.44021075963974],
[41.95263671875,483.450927734375],
[41.93800687789917,482.4489438533783],
[41.93647429347038,481.44680462777615],
[41.948291659355164,480.4565081000328],
[41.9745911359787,479.44373697042465],
[42.01519954204559,478.44365924596786],
[42.07011687755585,477.45627492666245],
[42.141082763671875,476.45957946777344],
[42.22909498214722,475.45445799827576],
[42.332746505737305,474.463210105896],
[42.4548043012619,473.4647426009178],
[42.593188762664795,472.4807584285736],
[42.7513689994812,471.49078726768494],
[42.92657804489136,470.51592230796814],
[43.12300193309784,469.53632920980453],
[43.34172713756561,468.55297178030014],
[43.57892978191376,467.586005628109],
[43.83991193771362,466.6165864467621],
[44.12011802196503,465.6642207503319],
[44.42561137676239,464.7107395529747],
[44.75754499435425,463.7571656703949],
[45.11023986339569,462.82200902700424],
[45.48369598388672,461.90526962280273],
[45.88555097579956,460.9901750087738],
[46.317002177238464,460.07778733968735],
[46.77081108093262,459.1852331161499],
[47.24697768688202,458.31251233816147],
[47.754773020744324,457.44430154561996],
[48.285746455192566,456.5966519713402],
[48.839897990226746,455.7695636153221],
[49.42774772644043,454.94882106781006],
[50.028669357299805,454.16322231292725],
[50.66412436962128,453.384710252285],
[51.32359266281128,452.62750029563904],
[51.99487638473511,451.904319524765],
[52.7019499540329,451.18934005498886],
[53.43303680419922,450.495662689209],
[54.1746826171875,449.83489990234375],
[54.93950653076172,449.19469833374023],
[55.727508544921875,448.57505798339844],
[56.53868865966797,447.97597885131836],
[57.3579443693161,447.407611310482],
[58.19955778121948,446.8590772151947],
[59.04762077331543,446.33981227874756],
[59.91723585128784,445.83966612815857],
[60.791704058647156,445.3673729300499],
[61.686933517456055,444.91349697113037],
[62.58544921875,444.486083984375],
[63.503950119018555,444.0763998031616],
[64.42419993877411,443.69181483983994],
[65.34506785869598,443.3313259482384],
[66.28441321849823,442.98722833395],
[67.24223601818085,442.6595219969749],
[68.19882917404175,442.3542726039886],
[69.15310621261597,442.0705163478851],
[70.1040028333664,441.80730909109116],
[71.08160349726677,441.5560170263052],
[72.05477607250214,441.3243445754051],
[73.03320872783661,441.1091122031212],
[74.00550413131714,440.91198325157166],
[74.99276626110077,440.72813123464584],
[75.97256076335907,440.5612024664879],
[76.96666419506073,440.4069671034813],
[77.95199918746948,440.2685010433197],
[78.95100009441376,440.142157971859],
[79.93996131420135,440.0304564833641],
[80.94196021556854,439.93032068014145],
[81.93267774581909,439.8437249660492],
[82.9358195066452,439.76815074682236],
[83.92646777629852,439.7050413489342],
[84.92894160747528,439.65242236852646],
[85.9304892718792,439.6107556670904],
[86.9306728541851,439.5796527713537],
[87.9290599822998,439.55873012542725],
[88.92522382736206,439.54760909080505],
[89.93206024169922,439.545955657959],
[90.92265594005585,439.55344289541245],
[91.92336988449097,439.56990599632263],
[92.93420207500458,439.59534496068954],
[93.92742621898651,439.6287116408348],
[94.93022906780243,439.67057555913925],
[95.92848417162895,439.720179066062],
[96.92179796099663,439.7771730273962],
[97.92416569590569,439.8421987146139],
[98.92107474803925,439.91415590047836],
[99.91214263439178,439.99270528554916],
[100.90476632118225,439.9217388033867],
[101.90360218286514,439.82858054339886],
[102.89392471313477,439.7290372848511],
[103.88990044593811,439.6215268969536],
[104.89115387201309,439.5057677477598],
[105.88339328765869,439.383248090744],
[106.88040256500244,439.25209879875183],
[107.86814212799072,439.11399722099304],
[108.84661197662354,438.96894335746765],
[109.84260201454163,438.8126155734062],
[110.82880020141602,438.648943901062],
[111.8052065372467,438.477928340435],
[112.79780983924866,438.2946464419365],
[113.78008484840393,438.10361832380295],
[114.75203156471252,437.9048439860344],
[115.7388162612915,437.6927840709686],
[116.71472191810608,437.47256487607956],
[117.67974853515625,437.2441864013672],
[118.65821838378906,437.0014762878418],
[119.625244140625,436.75018310546875],
[120.58082580566406,436.49030685424805],
[121.54842019081116,436.21502608060837],
[122.50399112701416,435.9307277202606],
[123.47040176391602,435.630145072937],
[124.41291481256485,435.32384626567364],
[125.36537170410156,435.0005912780762],
[126.29393100738525,434.67162013053894],
[127.23152756690979,434.32501286268234],
[128.17723870277405,433.96007746458054],
[129.1091024875641,433.58475655317307],
[130.0271189212799,433.19905012845993],
[130.9312880039215,432.80295819044113],
[131.84168338775635,432.38712191581726],
[132.75733947753906,431.9508171081543],
[133.63844513893127,431.51301354169846],
[134.5432472229004,431.0441541671753],
[135.413498878479,430.57379603385925],
[136.28703713417053,430.0814887881279],
[137.144775390625,429.57733154296875],
[138.00445556640625,429.0502166748047],
[138.84765625,428.5107421875],
[139.67437744140625,427.9589080810547],
[140.50132393836975,427.3828288912773],
[141.31109714508057,426.7938697338104],
[142.1036970615387,426.192030608654],
[142.87912368774414,425.5773115158081],
[143.65267276763916,424.93677020072937],
[144.40834045410156,424.2828178405762],
[145.14612674713135,423.6154544353485],
[145.88024735450745,422.9209277033806],
[146.58191323280334,422.2264739871025],
[147.27919101715088,421.50431513786316],
[147.95786499977112,420.76820343732834],
[148.61793518066406,420.01813888549805],
[149.2594015598297,419.2541214823723],
[149.89429116249084,418.46075743436813],
[150.49818515777588,417.6685607433319],
[151.08347535133362,416.8624112010002],
[151.65016174316406,416.04230880737305],
[152.1982443332672,415.2082535624504],
[152.72772312164307,414.3602454662323],
[153.23859810829163,413.4982845187187],
[153.7308692932129,412.62237071990967],
[154.19542789459229,411.7500865459442],
[154.65085625648499,410.84654062986374],
[155.0792944431305,409.94716614484787],
[155.48985123634338,409.03438061475754],
[155.88252663612366,408.10818403959274],
[156.25,407.1875],
[156.60030007362366,406.25393599271774],
[156.93342685699463,405.307492017746],
[157.24938035011292,404.3481680750847],
[157.54223465919495,403.3959338068962],
[157.81860947608948,402.4313399195671],
[158.07325839996338,401.47486567497253],
[158.31210732460022,400.50654143095016],
[158.53515625,399.5263671875],
[158.73816013336182,398.55557322502136],
[158.92602920532227,397.5734281539917],
[159.09876346588135,396.579931974411],
[159.25309777259827,395.5970498919487],
[159.39294815063477,394.6033048629761],
[159.51700121164322,393.6099188774824],
[159.6268904209137,392.6059100031853],
[159.71961975097656,391.6256523132324],
[159.7999706864357,390.6239037960768],
[159.86609607934952,389.62369315326214],
[159.91845726966858,388.62536638975143],
[159.95751023292542,387.62926548719406],
[159.9839460849762,386.62370175123215],
[159.9975824356079,385.63295245170593],
[160,384.62130561470985],
[160,383.6342273768969],
[160,382.62873787403805],
[160,381.62105502793565],
[160,380.6323752528988],
[160,379.62826708058856],
[160,378.6266041113413],
[160,377.62540656363853],
[160,376.6274360884927],
[160,375.6310107495301],
[160,374.6307104616426],
[160,373.6333365007886],
[160,372.6292106244364],
[160,371.6225644364022],
[160,370.63436880303925],
[160,369.6348231358206],
[160,368.6259908202919],
[160,367.6282054787953],
[160,366.6258273547646],
[160,365.6206092122011],
[160,364.63376363713905],
[160,363.6281740278355],
[160,362.62452439637855],
[160,361.6242679061179],
[160,360.628788949507],
[160,359.61819993604877],
[160,358.63587506570184],
[160,357.6185374180204],
[160,356.63186640918866],
[160,355.63314829720184],
[160,354.6226814417969],
[160,353.62359573697177],
[160,352.63690719380975],
[160,351.62864421680297],
[160,350.63400494196685],
[160,349.6183118738918],
[160,348.62937263891126],
[160,347.6321469550021],
[160,346.6268688389255],
[160,345.62602389827134],
[160,344.6301129038693],
[160,343.6271934392471],
[160,342.6299890929704],
[160,341.6263699714227],
[160,340.6292058676263],
[160,339.6261924846249],
[160,338.6303339247979],
[160,337.62916416384087],
[160,336.63581010147027],
[160,335.62466248491546],
[160,334.63488951325417],
[160,333.6276966803189],
[160,332.62942647491855],
[160,331.6272060946494],
[160,330.6344756297767],
[160,329.62493996397825],
[160,328.6253404107674],
[160,327.62258920261957],
[160,326.6302875804831],
[160,325.6352517225969],
[160,324.6376474760473],
[160,323.6376406879208],
[160,322.6353972053039],
[160,321.631082875283],
[160,320.6248635449447],
[160,319.63053698221756],
[160,318.63467716681043],
[160,317.6237737768679],
[160,316.62530956212777],
[160,315.62579191773693],
[160,314.62538005770057],
[160,313.62423319602385],
[160,312.6362359523773],
[160,311.6203713237701],
[160,310.6317072276943],
[160,309.62921276840007],
[160,308.62677719647763],
[160,307.62455972593216],
[160,306.6227195707688],
[160,305.6351280963645],
[160,304.6208080626093],
[160,303.6347438953817],
[160,302.62231638404046],
[160,301.6247510158655],
[160,300.6285182471038],
[160,299.6201618032137],
[160,298.6270955975533],
[160,297.63584181433544],
[160,296.63302228869725],
[160,295.6323946340126],
[160,294.63412469736795],
[160,293.6249403376087],
[160,292.6185208701645],
[160,291.6283986410781],
[160,290.6279843386392],
[160,289.6308506120113],
[160,288.6239451612346],
[160,287.62076530925697],
[160,286.6214907169342],
[160,285.6263010451221],
[160,284.6353759546764],
[160,283.62299673859525],
[160,282.6283780197491],
[160,281.6258776971472],
[160,280.6286387753835],
[160,279.62417657141486],
[160,278.62551256124334],
[160,277.6328485322301],
[160,276.63394002389396],
[160,275.6292390532826],
[160,274.6314901629012],
[160,273.6287271976471],
[160,272.63353759622987],
[160,271.6221636535556],
[160,270.61921639175125],
[160,269.62493838833325],
[160,268.6278963292216],
[160,267.6286681155352],
[160,266.6278534941375],
[160,265.62607458559796],
[160,264.6351730823517],
[160,263.633292799168],
[160,262.62152020750364],
[160,261.62257165266055],
[160,260.6367658303134],
[160,259.6224558804897],
[160,258.62316546295915],
[160,257.61892473325133],
[160,256.6310984154916],
[160,255.62080038012937],
[160,254.62913566912903],
[160,253.61879209754989],
[160,252.62952844928805],
[160,251.62574410438538],
[160,250.62813529744744],
[160,249.62148965074448],
[160,248.62590211192583],
[160,247.6274073831155],
[160,246.62986974431988],
[160,245.622435544974],
[160,244.62569911735045],
[160,243.6307440782548],
[160,242.61882861115737],
[160,241.62668666100217],
[160,240.62900172226364],
[160,239.63297733076615],
[160,238.6335620097816],
[160,237.63527143979445],
[160,236.63344768807292],
[160,235.63616884872317],
[159.90812130272388,234.6342547237873],
[159.66242916882038,233.6731620132923],
[159.4083669781685,232.70414650440216],
[159.1457250714302,231.72750771045685],
[158.88222821056843,230.77197030186653],
[158.61001081764698,229.80901077389717],
[158.3329702168703,228.85294154286385],
[158.04692536592484,227.88985550403595],
[157.7559144049883,226.93386390805244],
[157.45993733406067,225.98496675491333],
[157.15459614992142,225.02956688404083],
[156.84414386749268,224.08146858215332],
[156.5240354090929,223.12728479504585],
[156.19866877794266,222.1806126832962],
[155.86804397404194,221.24145224690437],
[155.52739270031452,220.29673531651497],
[155.18133409321308,219.35974314808846],
[154.82494860887527,218.41762363910675],
[154.46800023317337,217.4961882829666],
[154.09550189971924,216.55720710754395],
[153.722440674901,215.63891008496284],
[153.3385957032442,214.71613839268684],
[152.9437354207039,213.78922283649445],
[152.54838943481445,212.88288116455078],
[152.136412858963,211.96053743362427],
[151.71841144561768,211.04680061340332],
[151.29438519477844,210.14167070388794],
[150.8643341064453,209.24514770507812],
[150.4282581806183,208.35723161697388],
[149.97444182634354,207.4548989534378],
[149.51427936553955,206.56163215637207],
[149.04777079820633,205.67743122577667],
[148.57491612434387,204.8022961616516],
[148.09571534395218,203.9362269639969],
[147.5976350903511,203.0573683977127],
[147.0928791165352,202.1880465745926],
[146.58144742250443,201.32826149463654],
[146.06334000825882,200.47801315784454],
[145.53855687379837,199.6373015642166],
[144.99372601509094,198.78546953201294],
[144.44865353405476,197.95386269688606],
[143.88988085091114,197.12194755673409],
[143.3240944892168,196.30005195736885],
[142.74426579475403,195.47833681106567],
[142.164366543293,194.67660248279572],
[141.56305104494095,193.86570274829865],
[140.95437556505203,193.06531727313995],
[140.34571714699268,192.28478744626045],
[139.7149446606636,191.4960891008377],
[139.0841892361641,190.72724640369415],
[138.44607383012772,189.96891796588898],
[137.7851402759552,189.20342683792114],
[137.13212966918945,188.46637725830078],
[136.45594626665115,187.72267162799835],
[135.77204823493958,186.9899868965149],
[135.08043557405472,186.2683230638504],
[134.38110828399658,185.55768013000488],
[133.66579957306385,184.84998777508736],
[132.94259577989578,184.15357410907745],
[132.21994519233704,183.47625017166138],
[131.47250294685364,182.79458284378052],
[130.73424369096756,182.13955700397491],
[129.97082978487015,181.48070633411407],
[129.19933825731277,180.83339512348175],
[128.4375762939453,180.21194458007812],
[127.65011310577393,179.58745002746582],
[126.85457229614258,178.97449493408203],
[126.05095386505127,178.37307929992676],
[125.2392578125,177.783203125],
[124.42884512245655,177.2113735973835],
[123.61053735017776,176.65082275867462],
[122.77479201555252,176.0953027009964],
[121.93096905946732,175.5513221025467],
[121.07906848192215,175.0188809633255],
[120.22890813648701,174.50383380055428],
[119.37085270881653,174.00006532669067],
[118.49490284919739,173.50198030471802],
[117.62096650898457,173.02089884877205],
[116.73913508653641,172.5510960817337],
[115.84940858185291,172.09257200360298],
[114.95178699493408,171.64532661437988],
[114.0567234158516,171.21430695056915],
[113.15394520759583,170.7943081855774],
[112.23281987011433,170.3806395828724],
[111.32524490356445,169.98737335205078],
[110.39932280778885,169.60043728351593],
[109.46568608283997,169.22452211380005],
[108.53532500565052,168.8638074696064],
[107.59742766618729,168.5138589143753],
[106.6631606221199,168.17860424518585],
[105.7102794200182,167.85006120800972],
[104.76120479404926,167.53596022725105],
[103.81619960069656,167.23592579364777],
[102.85249263048172,166.94272816181183],
[101.88142567873001,166.66004478931427],
[100.9263801574707,166.39423370361328],
[99.96432095766068,166.13844215869904],
[98.99524807929993,165.89267015457153],
[98.01916152238846,165.65691769123077],
[97.03606128692627,165.43118476867676],
[96.07017993927002,165.22061347961426],
[95.09762287139893,165.01957893371582],
[94.14544619619846,164.7137888520956],
[93.18704821169376,164.3895234912634],
[92.23470844328403,164.0638156980276],
[91.29914605990052,163.7403911910951],
[90.34820355474949,163.40807281434536],
[89.41403843462467,163.0780377238989],
[88.46490859985352,162.73900985717773],
[87.52211548388004,162.3984732478857],
[86.58565908670425,162.0564278960228],
[85.65553940832615,161.712873801589],
[84.7112999111414,161.36012576520443],
[83.77368189394474,161.00580118596554],
[82.84268535673618,160.64990006387234],
[81.91831029951572,160.29242239892483],
[80.98067909479141,159.92554515600204],
[80.04996038973331,159.5570220798254],
[79.12615418434143,159.1868531703949],
[78.18982720375061,158.8071095943451],
[77.27999307215214,158.43361400067806],
[76.35793544352055,158.05047295987606],
[75.42410492897034,157.6575791835785],
[74.50720200315118,157.26693293079734],
[73.59765984117985,156.87453411519527],
[72.66769327223301,156.46816454827785],
[71.763916015625,156.068115234375],
[70.84943920373917,155.6580576300621],
[69.9427855014801,155.24613738059998],
[69.02605809271336,154.82405982911587],
[68.1174698472023,154.4000443816185],
[67.21702076494694,153.97409103810787],
[66.30729593336582,153.53779040277004],
[65.42328476905823,153.10792326927185],
[64.51323069632053,152.65914522111416],
[63.62889029085636,152.2168006747961],
[62.73625046014786,151.76387637853622],
[61.835808753967285,151.30025386810303],
[60.96091486513615,150.84310434758663],
[60.078553929924965,150.37517689168453],
[59.189232885837555,149.89635080099106],
[58.30937780439854,149.41527090966702],
[57.43898868560791,148.9319372177124],
[56.5625,148.4375],
[55.69582462310791,147.9407262802124],
[54.83896255493164,147.4416160583496],
[53.976877108216286,146.9311936944723],
[53.12495857477188,146.41835063695908],
[52.26853013038635,145.89402556419373],
[51.422628462314606,145.36719411611557],
[50.5729428678751,144.82870779931545],
[49.73415017127991,144.28762793540955],
[48.89231204986572,143.7347173690796],
[48.06173920631409,143.17912459373474],
[47.242431640625,142.620849609375],
[46.42101742327213,142.0505204051733],
[45.61124712228775,141.4774188399315],
[44.80013370513916,140.89208126068115],
[43.988259211182594,140.2943690866232],
[43.18880468606949,139.6936997771263],
[42.389377653598785,139.08046811819077],
[41.60276800394058,138.46418470144272],
[40.82897573709488,137.84484952688217],
[40.056212320923805,137.21271358430386],
[39.28508795797825,136.56763158738613],
[38.527594581246376,135.9193041175604],
[37.77256637811661,135.25783389806747],
[37.03158535063267,134.5930191129446],
[36.29390813410282,133.91486190259457],
[35.56017309427261,133.2232102751732],
[34.85164791345596,132.53811210393906],
[34.13739800453186,131.82926535606384],
[33.43848139047623,131.11676782369614],
[32.745243683457375,130.39036236703396],
[32.06778049468994,129.66020107269287],
[31.39688491821289,128.91592025756836],
[30.74221134185791,128.1677770614624],
[30.086256563663483,127.3948273062706],
[29.455955997109413,126.62832520902157],
[28.834041133522987,125.84727071225643],
[28.221206665039062,125.05149841308594],
[27.62597419321537,124.25153516232967],
[27.04834371805191,123.44738095998764],
[26.4736220985651,122.61742256581783],
[25.91745376586914,121.78304672241211],
[25.379838719964027,120.94425342977047],
[24.85415793955326,120.09006284177303],
[24.3475154787302,119.23133932054043],
[23.85378360748291,118.3569860458374],
[23.379581347107887,117.47798286378384],
[22.924908697605133,116.59432977437973],
[22.484382688999176,115.69475263357162],
[22.06388384103775,114.79040712118149],
[21.658532992005348,113.86989913880825],
[21.27371311187744,112.94450283050537],
[20.913796424865723,112.02573299407959],
[20.565666258335114,111.07904523611069],
[20.238577127456665,110.12734770774841],
[19.932529032230377,109.17064040899277],
[19.65087093412876,108.22068177163601],
[19.38974380493164,107.26583480834961],
[19.14630562067032,106.29422038793564],
[18.923908472061157,105.3175961971283],
[18.722552359104156,104.33596223592758],
[18.542237281799316,103.3493185043335],
[18.382963240146637,102.35766500234604],
[18.244730234146118,101.36100172996521],
[18.130146265029907,100.38381934165955],
[18.033482134342194,99.37725871801376],
[17.95945316553116,98.39042037725449],
[17.904903069138527,97.3863884061575],
[17.87088394165039,96.37746810913086],
[17.857481613755226,95.38875065743923],
[17.86360889673233,94.39538329839706],
[17.88971573114395,93.38470309972763],
[17.934452295303345,92.39469885826111],
[17.998227179050446,91.40016168355942],
[18.081040382385254,90.40109157562256],
[18.18289190530777,89.39748853445053],
[18.300444185733795,88.41525882482529],
[18.437976017594337,87.41571627557278],
[18.591915145516396,86.42482303082943],
[18.761548101902008,85.44274896383286],
[18.948783054947853,84.45648394525051],
[19.15077306330204,83.47926162183285],
[19.366823434829712,82.51124739646912],
[19.6028533577919,81.52592033147812],
[19.848951399326324,80.56330770254135],
[20.095668137073517,79.58807724714279],
[20.327458381652832,78.6112470626831],
[20.56440383195877,77.64209240674973],
[20.806504487991333,76.68061327934265],
[21.057790592312813,75.71148864924908],
[21.314399540424347,74.7502891421318],
[21.580531895160675,73.78194731473923],
[21.852157413959503,72.82178419828415],
[22.13364966213703,71.85498993098736],
[22.42080807685852,70.89663195610046],
[22.71363265812397,69.94671027362347],
[23.01675982773304,68.99080638587475],
[23.325728848576546,68.04360036551952],
[23.645354509353638,67.09093928337097],
[23.971000388264656,66.14724163711071],
[24.3026664853096,65.21250742673874],
[24.645438492298126,64.27298706769943],
[24.99958999454975,63.329087898135185],
[25.360126495361328,62.39469528198242],
[25.727047994732857,61.46980921924114],
[26.105811670422554,60.541233226656914],
[26.491146758198738,59.622441336512566],
[26.888699531555176,58.70051860809326],
[27.29301281273365,57.788661524653435],
[27.709924653172493,56.874240681529045],
[28.133788779377937,55.97017101943493],
[28.570637702941895,55.06411266326904],
[29.008502960205078,54.18088912963867],
[29.465775787830353,53.28391808271408],
[29.930392503738403,52.397881269454956],
[30.40235310792923,51.5227786898613],
[30.894713699817657,50.6354061961174],
[31.381560564041138,49.78246760368347],
[31.889209747314453,48.9178581237793],
[32.40460515022278,48.06478190422058],
[32.941618263721466,47.201248824596405],
[33.47270995378494,46.3715426325798],
[34.025832414627075,45.531994581222534],
[34.58711415529251,44.704594790935516],
[35.15655517578125,43.88934326171875],
[35.74907302856445,43.0658073425293],
[36.33504182100296,42.27516382932663],
[36.944511234760284,41.47686690092087],
[37.562563717365265,40.69134920835495],
[38.1891992688179,39.918610751628876],
[38.84040832519531,39.13981628417969],
[39.48442459106445,38.3929557800293],
[40.15344887971878,37.640686213970184],
[40.83149075508118,36.90184283256531],
[41.51855021715164,36.17642563581467],
[42.214627265930176,35.46443462371826],
[42.92837627232075,34.757433578372],
[43.65136414766312,34.06418818235397],
[44.38359089195728,33.38469843566418],
[45.12505650520325,32.71896433830261],
[45.88486194610596,32.05921459197998],
[46.65413022041321,31.413553953170776],
[47.4235350638628,30.789418265223503],
[48.21161635220051,30.171768113970757],
[49.00916047394276,29.568207070231438],
[49.816167429089546,28.978735134005547],
[50.63263721764088,28.403352305293083],
[51.468459367752075,27.835461378097534],
[52.30396747589111,27.288424491882324],
[53.14893841743469,26.755476713180542],
[54.00337219238281,26.236618041992188],
[54.867268800735474,25.73184847831726],
[55.740628242492676,25.24116802215576],
[56.61288507282734,24.770167514681816],
[57.4943807721138,24.312922656536102],
[58.395903408527374,23.86417406797409],
[59.30688887834549,23.429514586925507],
[60.20531177520752,23.018794059753418],
[61.12387426197529,22.61673752963543],
[62.051675617694855,22.228436648845673],
[62.97737121582031,21.858322143554688],
[63.91208440065384,21.50163382291794],
[64.85581517219543,21.158371686935425],
[65.78521847724915,20.83642077445984],
[66.74676448106766,20.5196835398674],
[67.69354850053787,20.2236105799675],
[68.64891558885574,19.94031685590744],
[69.61286574602127,19.669802367687225],
[70.58539897203445,19.412067115306854],
[71.55419833958149,19.170094177126884],
[72.5313675403595,18.940582990646362],
[73.504379093647,18.72620314359665],
[74.48554992675781,18.523971557617188],
[75.47488003969193,18.333888232707977],
[76.44669085741043,18.160363852977753],
[77.43918843567371,17.996318146586418],
[78.4265936166048,17.84601204097271],
[79.408600628376,17.708990275859833],
[80.41139632463455,17.581598937511444],
[81.39524579048157,17.468641996383667],
[82.39988394081593,17.365315482020378],
[83.38497638702393,17.27553081512451],
[84.39085751771927,17.195376574993134],
[85.39034135639668,17.127019092440605],
[86.38313420116901,17.070021077990532],
[87.38287933170795,17.023374244570732],
[88.37554723024368,16.987511813640594],
[89.38910037279129,16.961423575878143],
[90.38116455078125,16.94598388671875],
[91.3798001408577,16.940328299999237],
[92.38500714302063,16.944456815719604],
[93.38228531181812,16.95810179412365],
[94.38594847917557,16.98125332593918],
[95.38353736698627,16.997270196676254],
[96.37938539683819,16.99018231034279],
[97.38688130886294,16.98301152093336],
[98.37638280398096,16.975968805665616],
[99.38559915896622,16.96878577111056],
[100.39346791405114,16.96161232801387],
[101.38022414359511,16.95458915200288],
[102.38961538683725,16.94740487269155],
[103.3906825591439,16.9402798394367],
[104.3895089898433,16.933170754520688],
[105.37620158397476,16.926148031430785],
[106.38547664906582,16.918964579010208],
[107.39120991158416,16.911806335148867],
[108.3877392315735,16.904713599775278],
[109.38193626894417,16.897637464277977],
[110.38246493555562,16.890516263803875],
[111.37383064904134,16.883460280077998],
[112.39219522557323,16.876212133625813],
[113.38296292422456,16.869160406233277],
[114.3835283392159,16.86203894420487],
[115.37848718828172,16.854957386560272],
[116.38021378841586,16.847827659868926],
[117.38756645980948,16.84065788996577],
[118.38442141523046,16.833562836902274],
[119.3841867884912,16.82644706912106],
[120.38584463508005,16.81931783177879],
[121.38840875509914,16.81218214409182],
[122.39092394572799,16.80504680465674],
[123.39246526518764,16.797918396689056],
[124.37563594607127,16.790920740597358],
[125.3890734929796,16.783707661971675],
[126.38243535964648,16.776637470749847],
[127.3885705661155,16.76947636607747],
[128.38995044505145,16.76234910715266],
[129.385798592848,16.75526121997973],
[130.37536455653026,16.748218045860995],
[131.37588489303016,16.741096904675942],
[132.38722495685215,16.7338987547555],
[133.39090673469263,16.726755112208593],
[134.38624388843482,16.719670862004023],
[135.3912728405271,16.712517631028277],
[136.38699771816027,16.70543062122306],
[137.3727856669575,16.698414336889982],
[138.38643925138376,16.69119972063072],
[139.3895978537762,16.684059801752483],
[140.38166358452872,16.676998835697304],
[141.3817459879906,16.669880811473377],
[142.38974336010506,16.66270645295299],
[143.3855616878427,16.655618778022472],
[144.38879404358522,16.648478334209358],
[145.37906248003173,16.64143016028447],
[146.37626910419203,16.634332604240626],
[147.38032371410327,16.627186308084674],
[148.3911361078026,16.61999191382347],
[149.38778513278157,16.61289832645707],
[150.39074757905837,16.60575980370777],
[151.37885088190478,16.598727039986443],
[152.37284639035352,16.591652338858694],
[153.3726545028917,16.584536266883333],
[154.37819561800643,16.57737939061917],
[155.38939013418485,16.57018227662502],
[156.38446759402586,16.563099874775617],
[157.38480956577723,16.555980003090554],
[158.39034142153514,16.548823192729287],
[159.3789640753821,16.541786732559558],
[160.3724121344053,16.534715927869],
[161.38173333391495,16.527532147089573],
[162.37350500436014,16.52047327399032],
[163.38101008348167,16.51330241933465],
[164.381791372667,16.50617942083511],
[165.38695058089024,16.499025262769464],
[166.38505409606796,16.49192132315966],
[167.38730633877594,16.48478785524003],
[168.38218367121402,16.477706877784954],
[169.38098903303035,16.470597942825407],
[170.37211264962252,16.463543682209092],
[171.3901314063446,16.456297997107868],
[172.38870957815016,16.449190679159074],
[173.39088003896677,16.442057793316962],
[174.37315376583547,16.435066521239605],
[175.38224591566177,16.427884370707034],
[176.38295623897733,16.420761877302652],
[177.38693967691916,16.41361608770876],
[178.38226953010553,16.40653188946544],
[179.3806816531128,16.399425753358628],
[180.38211924237248,16.39229808368418],
[181.38652549431617,16.385149284737963],
[182.38183481834716,16.37806523260963],
[183.3799322852683,16.370961336047912],
[184.38076309615468,16.36383798508075],
[185.3842724520814,16.356695569736075],
[186.3782680694021,16.349620867833437],
[187.37477174889182,16.342528314954507],
[188.37373064854182,16.33541828719899],
[189.37509192634343,16.328291160666595],
[190.37880274028794,16.321147311457025],
[191.38481024836665,16.31398711566999],
[192.38075255599603,16.306898558320313],
[193.37883338911342,16.29979478014866],
[194.37900181547406,16.292676143662106],
[195.38120690283313,16.285543011367736],
[196.37298840093854,16.278484068320722],
[197.3790904376986,16.271323199731683],
[198.3870769675159,16.26414891838067],
[199.38441917063392,16.257050397362036],
[200.38350087186336,16.249939495573926],
[201.38427300214607,16.242816562262306],
[202.3866864924239,16.23568194667314],
[203.3906922736387,16.228535998052394],
[204.37108413734086,16.221558120018926],
[205.37809053746378,16.214390814679973],
[206.38654324807567,16.207213215316187],
[207.3711303161399,16.20020547817694],
[208.3822953342751,16.193008574133273],
[209.3820970407615,16.185892547752587],
[210.38311952034064,16.17876783259544],
[211.3726223580852,16.171725107771636],
[212.38863779128224,16.164493681200838],
[213.38031859137436,16.157435454865663],
[214.37300578481518,16.150370065588504],
[215.37939901033639,16.14320712448159],
[216.38673069395782,16.136037503957596],
[217.3821858952033,16.128952413557272],
[218.37846440317435,16.121861463322603],
[219.3755207377544,16.114764976955485],
[220.37330941882692,16.107663278157816],
[221.3717849662753,16.100556690631493],
[222.37090189998298,16.093445538078413],
[223.3706147398334,16.086330144200474],
[224.37087800570998,16.079210832699573],
[225.37164621749616,16.072087927277607],
[226.37287389507537,16.064961751636474],
[227.37451555833104,16.05783262947807],
[228.3765257271466,16.050700884504295],
[229.37885892140548,16.043566840417043],
[230.38146966099112,16.036430820918213],
[231.38431246578693,16.0292931497097],
[232.38734185567637,16.022154150493407],
[233.37765048806733,16.01510569047639],
[234.38091566963607,16.0079650130275],
[235.37136842417613,16.000915527230063],
[236.3746898017962,15.99377444980928],
[237.37797153008387,15.98663365459015],
[238.38116812892258,15.979493465274572],
[239.38423411819576,15.972354205564443],
[240.38712401778685,15.96521619916166],
[241.38979234757926,15.95807976976812],
[242.37934420954576,15.951036696017468],
[243.38143725398794,15.94390436118158],
[244.37033255949746,15.936865960430623],
[245.37167072296143,15.929738998413086],
[246.37256108220072,15.92261522361423],
[247.37295815709876,15.915494959735952],
[248.372816467539,15.90837853048015],
[249.37209053340484,15.90126625954872],
[250.37073487457974,15.89415847064356],
[251.3814939052151,15.88696445619064],
[252.3787328218312,15.879866670307251],
[253.38797509836104,15.872683451257217],
[254.383624330163,15.865596979856491],
[255.37841527053433,15.85851661729157],
[256.37230243935846,15.851442687264353],
[257.3779639687439,15.844284953959118],
[258.38260449878135,15.837134487553158],
[259.3734802021354,15.830081991443876],
[260.38863355331705,15.822856700688135],
[261.3772604278697,15.815820210477796],
[262.37736005359665,15.808702063675469],
[263.37620301873176,15.801592861076642],
[264.38636058776683,15.794403127489204],
[265.3699299597315,15.787402633738566],
[266.37730253770087,15.780232722151595],
[267.38319200338765,15.773073366523931],
[268.3875492877337,15.76592491610154],
[269.3778005811732,15.758876864190938],
[270.3789669775046,15.75175112471527],
[271.3784545986841,15.744637333817195],
[272.3886752305475,15.737447151384004],
[273.3846355723176,15.730358465677455],
[274.3787693183981,15.72328278065197],
[275.3710273997308,15.716220445553517],
[276.38609399592315,15.70899577227101],
[277.3744035668169,15.701961540449702],
[278.3853198177335,15.69476640699122],
[279.36947966438174,15.687761710573795],
[280.3760393258708,15.680597584869247],
[281.3803151815205,15.67344971401053],
[282.38225438932204,15.666318474097352],
[283.3818041072668,15.659204241229418],
[284.3789114933461,15.652107391506433],
[285.3735237055512,15.645028301028105],
[286.37767030082676,15.637881350172051],
[287.37915155562223,15.630753369710874],
[288.37791267101267,15.623644749672508],
[289.37389884807317,15.616555880084888],
[290.3790035520574,15.60940210994977],
[291.38115311801675,15.602269372825504],
[292.38029074238284,15.595158073007951],
[293.3763596215872,15.588068614792974],
[294.3693029520614,15.581001402476431],
[295.38259041358833,15.573789392074104],
[296.3690344208044,15.56676843828609],
[297.3755487153935,15.559604635477626],
[298.37854585566674,15.552465865795966],
[299.3779648832865,15.545352563108281],
[300.37374483991516,15.538265161281743],
[301.38885188012864,15.531040200141433],
[302.3770826296309,15.52400652932647],
[303.38433722973923,15.516837457439578],
[304.3875232566238,15.509697343369226],
[305.3865753993682,15.502586651961792],
[306.38142834705604,15.495505848063658],
[307.3720167887709,15.488455396521204],
[308.380639549865,15.481276586833701],
[309.3846635818304,15.474130508314374],
[310.38401901876205,15.467017658229452],
[311.37863599475486,15.459938533845161],
[312.3794152449547,15.452815549857974],
[313.3752065022418,15.445728067599703],
[314.3767963223763,15.438599314431485],
[315.3731394764036,15.431507904082537],
[316.3856456338076,15.424301454563647],
[317.3711534404515,15.4172871641249],
[318.3724328656972,15.410160620172974],
[319.3784788748572,15.403000150356888],
[320.3785265390711,15.395882373387394],
[321.38292386887997,15.388733637943915],
[322.38102720873803,15.381629699581936],
[323.3727517673128,15.374571161798485],
[324.37846968488884,15.367413027153816],
[325.37736170699054,15.360303475395085],
[326.36933763165507,15.353243148529145],
[327.3743350934237,15.346090141683817],
[328.3719415283085,15.338989740012039],
[329.38178646546794,15.331802231562506],
[330.38374036434107,15.32467088708654],
[331.37770152087114,15.317596430456433],
[332.38281735305327,15.310442581117059],
[333.3794103927926,15.303349392222117],
[334.38628681958653,15.296183011960238],
[335.38408336678685,15.289081257175894],
[336.37268588979714,15.282044940286141],
[337.370367287891,15.274944005068392],
[338.376466598555,15.26778315588217],
[339.3722891808611,15.260695450670028],
[340.37552707220675,15.253554967457603],
[341.3854641311559,15.246366803336969],
[342.3839449826628,15.23926017805934],
[343.38803699354685,15.232113615704293],
[344.3799706916034,15.225053589383606],
[345.37637776945166,15.217961724060842],
[346.37644909902383,15.210843778654635],
[347.37934815254994,15.203705707099289],
[348.3842103125244,15.196553663256054],
[349.37429516002885,15.18950679601403],
[350.38062447155244,15.182344309811015],
[351.370812025707,15.175296711560804],
[352.3748239579327,15.168150719160622],
[353.37604447091826,15.161024594513037],
[354.38795057303287,15.1538224158503],
[355.38012327279876,15.146760688449831],
[356.3801894694152,15.139642779577116],
[357.3724497701005,15.132580428682559],
[358.3690018274647,15.12548753147712],
[359.38057961132245,15.118287689599128],
[360.37855549046844,15.11118465843083],
[361.3860423665028,15.1040139333345],
[362.3755912653978,15.096970880673325],
[363.38011605106294,15.089821238070726],
[364.3722151394468,15.082760034594685],
[365.3701432312082,15.07565734355012],
[366.36802828122745,15.068554958852474],
[367.3789325085636,15.061359910971078],
[368.3747495809512,15.054272244975436],
[369.3824338169543,15.04710011518182],
[370.3869184702635,15.039950758218765],
[371.38608885972644,15.032839225197677],
[372.3701726483123,15.025835070118774],
[373.3756045685441,15.018678971042391],
[374.384930486558,15.011495156679302],
[375.3811949491501,15.004404306411743]];

export default PointsTraj;
import { SVG } from '@svgdotjs/svg.js';
import { range, zip } from 'ramda';
import React, { useEffect } from 'react';
import ScaleGrid from '../../../../libs/popUtils/Scalegrid';

export interface ScalePlotProps {
  plotSizeX: number;
  plotSizeY: number;
}

function ScalePlot({ plotSizeX, plotSizeY }: ScalePlotProps) {
  let animate = () => {};
  useEffect(() => {
    const x = range(0, 50).map((n) => (n / 12.5));
    // const y = x.map((n) => Math.sin(n) + Math.sin(3 * n));
    const y = x.map((n) => n + 1);;
    const y2 = x.map((n) => 1);
    const scaleX: [number, number] = [0, 4];
    const scaleY: [number, number] = [0, 5];
    const draw = SVG().addTo('#plot').size(plotSizeX, plotSizeY);
    const sg = new ScaleGrid(draw, { scaleX, scaleY, xPaddingLeft: 50, xPaddingRight: 20, yPaddingBottom: 50, yPaddingTop: 30 });

    const [fx, fy] = [sg.fnScaleX, sg.fnScaleY];
    const linha1 = draw
      .polyline(zip(x.map(fx), y2.map(fy)))
      .stroke({ color: '#f55', width: 2 })

    const linha2 = draw
      .polyline(zip(x.map(fx), y.map(fy)))
      .stroke({ color: '#080', width: 2 })

    sg.addLabel('t (s)', 'v (m/s)');

    sg.drawTicks({}).drawTicksText();
    // sg.plot(x, y, { color: '#f55', width: 2 });
    // sg.plot(x, y2, { color: '#a55', width: 2 });

    animate = () => {
      const nRandomFactor = Math.random();
      const y = x.map((n) => Math.sin(n) + Math.sin(nRandomFactor * n * 10));
      sg.animatePlot(x, y);
    };
  }, []);
  return (
    <div className="scale-plot">
      <div id="plot"></div>
      {/* <button onClick={() => animate()}>Randomize</button> */}
    </div>
  );
}

export default React.memo(ScalePlot);

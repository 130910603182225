import SceneComponent from 'babylonjs-hook';
import BabylonjsFunctions from './BabylonjsFunctions';

import React, { useEffect, useState } from 'react';
import ControlPanel from '../../../Babylon React Components/ControlPanel';
import AskMessage from '../../../Babylon React Components/compAskMessage';
import ResponseSlider from '../../../Babylon React Components/compResponseSlider';
import LoadingSpinner from '../../../loadingSpinner';
import FinalPanel from '../Gerais/compFinalPanel';
import gameManager from '../../../../managers/GameManager';

import { getImageSpecie } from '../Gerais/functions';

function GravityAccelerationScene(props) {
  let [onSceneReady, onRender] = BabylonjsFunctions();

  // Atualização do tempo necessária para atualização dos valores na gameManager
  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 100);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // Para configuração do slider
  let stepValue = 1;
  let maxSlider = 1;
  let minSlider = 1;
  let unidade = '';

  if (
    gameManager.objSpecificGame.physics.gravitation.message ===
    'Selecione o ângulo de lançamento de nosso foguete'
  ) {
    stepValue = 1;
    maxSlider = 45;
    minSlider = -45;
    unidade = 'º';
  } else if (
    gameManager.objSpecificGame.physics.gravitation.message ===
    'Selecione a velocidade de lançamento de nosso foguete'
  ) {
    stepValue = 0.5;
    maxSlider = 50;
    minSlider = 0;
    unidade = 'km/s';
  }


  let specieImg = getImageSpecie();

  let nameIcon = 'play';
  let nameButton = 'Começar';
  gameManager.objSpecificGame.physics.gravitation.running === '1'
    ? (nameIcon = 'pause')
    : (nameIcon = 'play');
  gameManager.objSpecificGame.physics.gravitation.running === '1'
    ? (nameButton = 'Pausar')
    : (nameButton = 'Começar');

  const velocityResponse =
    'Aceleração Calculada: ' +
    Number(gameManager.objSpecificGame.physics.gravitation.responseValue) +
    ' m/s²';

  return (
    <div>
      <SceneComponent antialias onSceneReady={onSceneReady} onRender={onRender} id="my-canvas" />
      <LoadingSpinner
        loading={gameManager.objSpecificGame.physics.gravitation.isLoading === 'true'}
      />
      <ControlPanel
        condition={
          gameManager.objSpecificGame.physics.gravitation.showFinalPanel !== 'true' &&
          gameManager.objSpecificGame.physics.gravitation.isLoading !== 'true'
        }
        icon={nameIcon}
        button={nameButton}
        restartFunction={() => {
          gameManager.updateInfo('physics', 'gravitation', 'running', '1');
          gameManager.updateInfo('physics', 'gravitation', 'restart', '1');
        }}
        playPauseFunction={() => {
          gameManager.updateInfo(
            'physics',
            'gravitation',
            'running',
            (1 - parseInt(gameManager.objSpecificGame.physics.gravitation.running)).toString()
          );
        }}
      />
      <FinalPanel
        condition={
          gameManager.objSpecificGame.physics.gravitation.showFinalPanel === 'true' &&
          gameManager.objSpecificGame.physics.gravitation.isLoading !== 'true'
        }
        challengeName="Lançando o satélite"
        func={props.map}
        results={
          [{
            text: velocityResponse,
            icon: "bolt"
          },
          {
            text: 'Duração do pouso: 12 horas',
            icon: 'calendar'
          }]
        }
        starIndex={4}
        xpAward={30}
      />
      <AskMessage
        condition={
          gameManager.objSpecificGame.physics.gravitation.showMessage === 'true' &&
          gameManager.objSpecificGame.physics.gravitation.isLoading !== 'true'
        }
        image={specieImg}
        name="Imperador Giles"
        message={gameManager.objSpecificGame.physics.gravitation.message}
        position='top'
      />
      <ResponseSlider
        condition={
          gameManager.objSpecificGame.physics.gravitation.showSlider === 'true' &&
          gameManager.objSpecificGame.physics.gravitation.isLoading !== 'true'
        }
        min={minSlider}
        max={maxSlider}
        step={stepValue}
        unity={unidade}
        color={'white'}
        function={(value) => {
          gameManager.updateInfo('physics', 'gravitation', 'responseValue', '' + value);
        }}
      />
    </div>
  );
}

export default GravityAccelerationScene;

import React, { useEffect, useState } from 'react';
import anime from 'animejs/lib/anime.es.js';
import { Form, List } from 'semantic-ui-react';
import StarMessage from '../../../../components/starMessage';
import scoreManager from '../../../../managers/ScoreManager.ts';
import roadT from '../../../../images/Physics/Kinematics/roadT.png';
import sideCarT from '../../../../images/sideCarT.PNG';
import semaphore from '../../../../images/semaphore.png';

function NegativeAcelerationPage(props) {
  const [isMinimized, setIsMinimized] = useState(false);
  const [acceleration, setAcceleration] = useState(0);
  const [starWon, setStarWon] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorIcon, setErrorIcon] = useState('');
  const [errorColor, setErrorColor] = useState('');
  const [tempoTotal, setTempoTotal] = useState('');

  function readAcceleration(e, { value }) {
    let acceleration = value.replace(/[^0-9.]/, ''); // Permite apenas números
    setAcceleration(acceleration);
  }

  function moveCar() {
    if (acceleration) {
      let speed = 80 / 3.6;
      let time = speed / acceleration;

      anime({
        targets: '#side-car',
        translateX: '53vw',
        duration: time * 1000,
        easing: 'easeOutCubic',
        endDelay: 500,
        complete: function () {
          setTempoTotal('Tempo gasto: ' + (22.22 / acceleration).toFixed(2) + ' segundos');
          if (acceleration > 4.5) {
            setErrorMessage('Você parou muito rápido! Tente novamente');
            setErrorIcon('times');
            setErrorColor('red');
          } else if (acceleration < 4.4) {
            setErrorMessage('Você demorou demais para parar o carro! Tente novamente.');
            setErrorIcon('times');
            setErrorColor('red');
          } else {
            setErrorMessage('Muito bem! Você conseguiu.');
            setErrorIcon('check');
            setErrorColor('green');
            setStarWon(true);
          }
        },
      });
    }
  }


  useEffect(() => {
    // scoreManager.updateStars("physics", "kinematics", "arrStarAceleration", 0);
    // scoreManager.updateXP("physics", 30);
  }, []);

  return (
    <div
      className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`}
      key={Math.random()}
    >
      {/* <div className="minMaxBar">
        <Icon className="icon" name="caret down" onClick={minimizeBar} />
        <Icon className="icon" name="caret up" onClick={maximizeBar} />
      </div> */}
      {!isMinimized && (
        <>
          <h1 className="presentationItem">Aceleração Negativa</h1>
          <p className="presentationItem">
            {/* É importante saber que por aceleração trata de qualquer variação de velocidade, não
            necessariamente partindo de um objeto parado. Por exemplo, podemos ter um objeto
            acelerando de 15 m/s para 45 m/s em questão de um tempo de 10 segundos.
            <br></br>
            <br></br> */}
            Podemos encontrar situações em que a{' '}
            <b className="presentationItem">aceleração é negativa</b>, ou o que chamamos de
            desaceleração. Nesses casos, o corpo{' '}
            <b className="presentationItem">
              inicia com uma velocidade e termina com uma velocidade menor
            </b>
            .<br></br>
            <br></br>
            Para entendermos emlhor esse conceito, suponha que um carro tem uma velocidade incial de
            80 km/h e precisa parar no sinal vermelho em 5s. Você consegue nos dizer qual valor de
            desaceleração devemos inserir para frear o carro e pará-lo exatamente no tempo correto?
          </p>

          <Form size="small" className="presentationItem">
            <Form.Group>
              <Form.Input
                autoFocus
                placeholder="Digite sua distância"
                value={acceleration}
                onChange={readAcceleration}
              />
              <Form.Button className="presentationItemBtn" content="Percorrer" onClick={moveCar} />
              {/* <Form.Button
                className="presentationItemBtn"
                content="Reiniciar"
                onClick={moveCarBack}
              /> */}
            </Form.Group>
          </Form>
          <div className="container">
            <img id="road" className="presentationItem" src={roadT} alt="" />
            <img id="side-car" className="presentationItem" src={sideCarT} alt="" />
            <img id="semaphore" className="presentationItem" src={semaphore} alt="" />
          </div>

          <p className="presentationItem" id="time-car">
            {tempoTotal}
          </p>

          <p
            style={{ position: 'absolute', left: '2vw' }}
            id="error-message"
            className="secondaryFont"
          >
            <List divided relaxed>
              <List.Item>
                <List.Icon name={errorIcon} size="big" verticalAlign="top" color={errorColor} />
                <List.Content>{errorMessage}</List.Content>
              </List.Item>
            </List>
          </p>

          {starWon === true && (
            <>
              <StarMessage
                show={true}
                message={'Pare o carro'}
                subjectScoring="physics"
                typeScoring="kinematics"
                arrayScoring={'arrStarAceleration'}
                indexScoring={0}
                xpAward={30}
              />
            </>
          )}
        </>
      )}
    </div>
  );
}

export default NegativeAcelerationPage;

/* eslint-disable react/display-name */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import PlayDialogue from '../../../Common/compPlayDialogue';
import cinematics_manager_black from '../../../../images/Physics/Kinematics/cinematics_manager_black.PNG';
import cinematics_manager_blue from '../../../../images/Physics/Kinematics/cinematics_manager_blue.PNG';
import cinematics_manager_green from '../../../../images/Physics/Kinematics/cinematics_manager_green.PNG';
import cinematics_manager_pink from '../../../../images/Physics/Kinematics/cinematics_manager_pink.PNG';
import cinematics_manager_red from '../../../../images/Physics/Kinematics/cinematics_manager_red.PNG';
import cinematics_manager_yellow from '../../../../images/Physics/Kinematics/cinematics_manager_yellow.PNG';

import api from '../../../../services/api';

function MapExplanation(props) {
  const [animationStep, setAnimationStep] = useState(0);

  const additionalInfo = JSON.parse(sessionStorage.getItem('additional_info'));

  function getImage() {
    switch (additionalInfo.choosed_team) {
      case 'Arara Real':
        return cinematics_manager_blue;
      case 'Jacaré Impiedoso':
        return cinematics_manager_green;
      case 'Boto Veloz':
        return cinematics_manager_pink;
      case 'Falcão Negro':
        return cinematics_manager_black;
      case 'Guará Rubro':
        return cinematics_manager_red;
      case 'Capivara Furiosa':
        return cinematics_manager_yellow;
      default:
        break;
    }
  }

  function updateOfficeAcess() {
    new Promise(async (resolve, reject) => {
      await api
        .post('updateAccessOffice', {
          challenge_id: sessionStorage.getItem('challenge_id'),
          client_id: localStorage.getItem('client_id'),
          access_office: true,
        })
        .then((res) => {
          sessionStorage.setItem('access_office', true);
        })
        .catch((err) => {});
      resolve('Request done!');
    });
  }

  useEffect(() => {
    if (animationStep === 11) {
      props.setNextItem('Tempo');
    }
  }, [animationStep]);

  function goToMap() {
    props.setIsMinimized(true);
  }

  const arrPage = [
    <PlayDialogue
      key={0}
      buttons="Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text="Vamos para os treinos, tenho certeza que você aprenderá esse conceito de letra e nos trará mais uma vitória!"
      image={getImage()}
      extraContent={''}
    />,

    <PlayDialogue
      key={1}
      buttons="Anterior/Mapa"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={goToMap}
      text="Primeiramente, você terá acesso ao nosso mapa, onde encontrará diferentes locais referentes ao mundo das corridas. Como primeiro objetivo, acesse o mapa e vá ao Museu, queremos conversar com você lá!"
      image={getImage()}
      extraContent={updateOfficeAcess()}
    />,
  ];

  return arrPage[animationStep];
}

export default MapExplanation;

import React from 'react';
import TipsComponent from '../../../../components/MapComponents/tipsComponent';

import tip1 from '../../../../images/Physics/Kinematics/tip1.PNG';
import tip2 from '../../../../images/Physics/Kinematics/tip2.PNG';
import tip3 from '../../../../images/Physics/Kinematics/tip3.PNG';
import tip4 from '../../../../images/Physics/Kinematics/tip4.PNG';

function Solution(props) {
  return (
    <>
      <TipsComponent
        numberTips={4}
        presentation1="Bem vindo à Oficina, um local onde conseguimos reparar algumas arestas em seu aprendizado e dar algumas dicas para você conseguir ganhar sua primeira corrida!"
        presentation2="Mas aqui temos que trabalhar duro, então algumas dicas necessitarão de um esforço seu. Vamos lá!"
        subject="physics"
        typeScoring="kinematics"
        arrayScoring="arrStarAverageSpeed"
        tips={[
          {
            number: 0,
            image: tip1,
            intro1: 'Observando os jogos de pneus você encontra uma dica',
            intro2: null,
            icon: 'car',
            title: 'Jogos de Pneus',
            dica1: true,
            dica1HasCondition: false,
            dica1ValidateCondition: null,
            dica1ConditionType: null,
            dica1MessageCondition: null,
            dica1ResponsePt1:
              'Percebemos que uma boa ideia para ganharmos o desafio é descobrir quanto tempo é esperado para cada carro equipado com o seu jogo de pneus demora para terminar a corrida.',
            dica1ResponsePt2:
              'Sabemos que nosso adversário mais rápido segue em uma velocidade média de 216 km/h. Podemos converter esse valor para m/s:',
            dica1ResponsePt3: null,
            dica1ResponsePt4: null,
            dica1ResponseEquation1: String.raw`\frac{216 km}{1 h} = \frac{216 * 1000m}{1 * 3600 s} = 60,0 m/s`,
            dica1ResponseEquation2: null,
            dica1ResponseEquation3: null,
            dica1ResponseEquation4: null,
            dica1ResponsePt5:
              'Sabendo dessa velocidade, e que a pista tem 880 metros, você é capaz de descobrir quanto tempo nosso adversário gastará para terminar as 5 voltas na pista?',
            dica2: false,
          },
          {
            number: 1,
            image: tip2,
            intro1: 'Investigando as Ferramentas da Oficina, você, desocbriu duas dicas:',
            intro2: null,
            icon: 'box',
            title: 'Ferramentas',
            dica1: true,
            dica1HasCondition: false,
            dica1ValidateCondition: null,
            dica1ConditionType: null,
            dica1MessageCondition: null,
            dica1ResponsePt1:
              'Sabemos que a pista tem 880 metros, sendo necessário 5 voltas para completar a corrida.',
            dica1ResponsePt2:
              'Em nossa última dica, descobrimos que a velocidade do nosso adversário mais rápido é de 60,0 m/s. Caso não tenha visto essa dica, procure pelos pneus.',
            dica1ResponsePt3: 'Com essa informação, fica fácil calcularmos:',
            dica1ResponsePt4: null,
            dica1ResponseEquation1: String.raw`\Delta t = \frac{\Delta s}{Vm} = \frac{5 * 880m}{60,0 m/s} = 73,33 s`,
            dica1ResponseEquation2: null,
            dica1ResponseEquation3: null,
            dica1ResponseEquation4: null,
            dica1ResponsePt5:
              'Agora que sabemos quanto tempo nosso adversário demora para finalizar a corrida, consegue descobrir o tempo que gastaremos em cada uma das estratégias de troca de pneus?',
            dica2: true,
            dica2HasCondition: true,
            dica2ValidateCondition: 'arrStars[3] === true',
            dica2ConditionType: 'mission',
            dica2MessageCondition:
              'Essa dica está bloqueada para você! Para desbloqueá-la, realize a missão "Velocidade do Carro"',
            dica2ResponsePt1:
              'Podemos trocar nosso pneu inicial para o Pneu Macio ao fim da 3ª volta.',
            dica2ResponsePt2:
              'Dessa forma, o piloto irá percorrer três voltas completas com a velocidade inicial de 210 km, que equivale à 58,33 m/s.',
            dica2ResponsePt3:
              'Após a troca, o piloto irá percorrer duas voltas com a nova velocidade de 63,89 m/s.',
            dica2ResponsePt4:
              'Sabendo que a troca de pneus demora 2 segundos e que cada volta da pista tem 880 m, podemos calcular o tempo total:',
            dica2ResponseEquation1: String.raw`\Delta t = \frac{3 * 880 m}{58,33 m/s} + \frac{2 * 880 m}{63,88 m/s} + 2s= 74,80 s`,
            dica2ResponsePt5: null,
            dica2QuestionTittle:
              '( UEL ) Um carro percorreu a metade de uma estrada viajando a 30 km/h e a outra metade da estrada a 60 km/h. Sua velocidade média no percurso total foi, em km/h, de: ',
            dica2OptionA: '60',
            dica2OptionB: '54',
            dica2OptionC: '48',
            dica2OptionD: '40',
            dica2OptionE: '30',
            dica2CorrectAnswer: 'd',
            dica2MissionName: 'Velocidade do Carro',
            dica2Subject: 'physics',
            dica2TypeScoring: 'kinematics',
            dica2ArrayScoring: 'arrStarAverageSpeed',
            dica2IndexScoring: 3,
            dica2XPAward: 30,
          },
          {
            number: 2,
            image: tip3,
            intro1:
              'Olá, eu sou o mecânico Luiz Câmera, e te mostrarei que com trabalho duro conseguiremos ganhar nosso objetivo!',
            intro2: null,
            icon: 'wrench',
            title: 'Mecânico Luiz Câmera',
            dica1: true,
            dica1HasCondition: true,
            dica1ValidateCondition: 'arrStars[2] === true',
            dica1ConditionType: 'mission',
            dica1MessageCondition:
              'Essa dica está bloqueada para você! Para desbloqueá-la, realize a missão "Tamanho da Estrada".',
            dica1ResponsePt1: ' O Pneu Ultramacio pode ser trocado ao fim da 4º volta.',
            dica1ResponsePt2:
              'Com essa estratégia, o piloto irá percorrer quatro voltas com a velocidade inicial de 210 km/h, que equivale à 58,33 m/s.',
            dica1ResponsePt3:
              'Após a troca, o piloto irá percorrer a última volta com a nova velocidade de 72,22 m/s.',
            dica1ResponsePt4:
              'Sabendo que a troca de pneus demora 2 segundos e que cada volta da pista tem 880 m, podemos calcular:',
            dica1ResponseEquation1: String.raw`\Delta t = \frac{4* 880 m}{58,33 m/s} + \frac{880 m}{72,22 m/s} + 2s = 74,52 s`,
            dica1ResponseEquation2: null,
            dica1ResponseEquation3: null,
            dica1ResponseEquation4: null,
            dica1ResponsePt5: null,
            dica1QuestionTittle:
              '( Unitau ) Um carro mantém uma velocidade escalar constante de 72,0 km/h. Em uma hora e dez minutos ele percorre, em quilômetros, a distância de:',
            dica1OptionA: '79,2',
            dica1OptionB: '80',
            dica1OptionC: '82,4',
            dica1OptionD: '84',
            dica1OptionE: '90',
            dica1CorrectAnswer: 'd',
            dica1MissionName: 'Tamanho da Estrada',
            dica1Subject: 'physics',
            dica1TypeScoring: 'kinematics',
            dica1ArrayScoring: 'arrStarAverageSpeed',
            dica1IndexScoring: 2,
            dica1XPAward: 30,
            dica2: false,
          },
          {
            number: 3,
            image: tip4,
            intro1:
              'Oi, tudo bem com você? Eu sou a Engenheira Thais Condida e concordo que o trabalho duro é importante, mas comigo você irá aprender a focar os estudos para que tudo fique mais simples.',
            intro2: 'Vamos lá, eu tenho duas dicas para você:',
            icon: 'clipboard',
            title: 'Eng. Thais Condida',
            dica1: true,
            dica1HasCondition: true,
            dica1ValidateCondition: 'acquiredStars >= 3',
            dica1ConditionType: 'condition',
            dica1MessageCondition:
              'Essa dica está bloqueada para você! Para desbloqueá-la, conquiste 3 estrelas.',
            dica1ResponsePt1: ' O Pneu Resistente pode ser trocado ao fim da 2º volta.',
            dica1ResponsePt2:
              'Assim, o piloto irá percorrer duas voltas com a velocidade inicial de 210 km/h, que equivale à 58,33 m/s.',
            dica1ResponsePt3:
              'Após a troca, o piloto irá percorrer outras três voltas com a nova velocidade de 220 km/m, que equivale à 61,11 m/s.',
            dica1ResponsePt4:
              'Sabendo que a troca de pneus demora 2 segundos e que cada volta da pista tem 880 m, podemos calcular o tempo total:',
            dica1ResponseEquation1: String.raw`\Delta t = \frac{2 * 880 m}{58,33 m/s} + \frac{3 * 880 m}{61,11 m/s} + 2s = 75,37 s`,
            dica1ResponseEquation2: null,
            dica1ResponseEquation3: null,
            dica1ResponseEquation4: null,
            dica1ResponsePt5: null,
            dica1QuestionTittle: null,
            dica1QuestionSubtittle1: null,
            dica1QuestionSubtittle2: null,
            dica1QuestionSubtittle3: null,
            dica1QuestionSubtittle4: null,
            dica1QuestionSubtittle5: null,
            dica1QuestionImage: null,
            dica1OptionA: null,
            dica1OptionB: null,
            dica1OptionC: null,
            dica1OptionD: null,
            dica1OptionE: null,
            dica1CorrectAnswer: null,
            dica1MissionName: null,
            dica1Subject: null,
            dica1TypeScoring: null,
            dica1ArrayScoring: null,
            dica1IndexScoring: null,
            dica1XPAward: null,
            dica2: true,
            dica2HasCondition: true,
            dica2ValidateCondition: 'arrStars[1] === true',
            dica2ConditionType: 'mission',
            dica2MessageCondition:
              'Essa dica está bloqueada para você! Para desbloqueá-la, realize a missão "Tamanho da Pista"',
            dica2ResponsePt1:
              'O Pneu Intermediário tem possibilidade de ser trocado ao fim da 3º volta.',
            dica2ResponsePt2:
              'Assim, o piloto irá percorrer três voltas completas com a velocidade inicial de 210 km/h, que equivale à 58,33 m/s.',
            dica2ResponsePt3:
              'Após a troca, o piloto irá percorrer mais duas voltas com a nova velocidade de 260 km/m, que equivale à 72,22 m/s.',
            dica2ResponsePt4:
              'Sabendo que a troca de pneus demora 2 segundos e que cada volta da pista tem 880 m, podemos calcular o tempo total gasto:',
            dica2ResponseEquation1: String.raw`\Delta t = \frac{3* 880 m}{58,33 m/s} + \frac{2 * 880 m}{72,22 m/s} + 2s = 71,62 s`,
            dica2ResponseEquation2: null,
            dica2ResponseEquation3: null,
            dica2ResponseEquation4: null,
            dica2ResponsePt5: 'Percebemos que este é o único pneu que pode nos trazer a vitória!',
            dica2QuestionTittle:
              '( FGV ) Numa corrida de fórmula 1 a volta mais rápida foi feita em 1 min e 20 s a uma velocidade média de 180 km/h. Pode-se afirmar que o comprimento da pista, em metros, é de?',
            dica2QuestionSubtittle1: null,
            dica2QuestionSubtittle2: null,
            dica2QuestionSubtittle3: null,
            dica2QuestionSubtittle4: null,
            dica2QuestionSubtittle5: null,
            dica2QuestionImage: null,
            dica2OptionA: '180',
            dica2OptionB: '4000',
            dica2OptionC: '1800',
            dica2OptionD: '14400',
            dica2OptionE: '2160',
            dica2CorrectAnswer: 'b',
            dica2MissionName: 'Tamanho da pista',
            dica2Subject: 'physics',
            dica2TypeScoring: 'kinematics',
            dica2ArrayScoring: 'arrStarAverageSpeed',
            dica2IndexScoring: 1,
            dica2XPAward: 30,
          },
        ]}
      />
    </>
  );
}

export default Solution;

import React, { useState, Component } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import Anime, { anime } from 'react-anime';
import './keplerLaws.scss';

class OrbitsAndPlanetsMoving extends Component{

  constructor(props){
    super(props)

    // this.animation = null;
  }

  animateH(){
    
    this.animationH = [anime({
      targets: '.mercuryHeliocentrism',
      translateX: anime.path('.orbit1Heliocentrism')('x'),
      translateY: anime.path('.orbit1Heliocentrism')('y'),
      rotate: anime.path('.orbit1Heliocentrism')('angle'),
      easing: 'linear',
      duration: 2000,
      loop: true,
      autoplay: false
    }),
    anime({
      targets: ['.venusHeliocentrism'],
      translateX: anime.path('.orbit2Heliocentrism')('x'),
      translateY: anime.path('.orbit2Heliocentrism')('y'),
      rotate: anime.path('.orbit2Heliocentrism')('angle'),
      easing: 'linear',
      duration: 3000,
      loop: true,
      autoplay: false
    }),
    anime({
      targets: '.earthHeliocentrism',
      translateX: anime.path('.orbit3Heliocentrism')('x'),
      translateY: anime.path('.orbit3Heliocentrism')('y'),
      rotate: anime.path('.orbit3Heliocentrism')('angle'),
      easing: 'linear',
      duration: 3500,
      loop: true,
      autoplay: false
    }),
    anime({
      targets: '.marsHeliocentrism',
      translateX: anime.path('.orbit4Heliocentrism')('x'),
      translateY: anime.path('.orbit4Heliocentrism')('y'),
      rotate: anime.path('.orbit4Heliocentrism')('angle'),
      easing: 'linear',
      duration: 6800,
      loop: true,
      autoplay: false
    }),
    anime({
      targets: '.jupiterHeliocentrism',
      translateX: anime.path('.orbit5Heliocentrism')('x'),
      translateY: anime.path('.orbit5Heliocentrism')('y'),
      rotate: anime.path('.orbit5Heliocentrism')('angle'),
      easing: 'linear',
      duration: 10000,
      loop: true,
      autoplay: false
    }),
    anime({
      targets: ['.saturnHeliocentrism', '.saturnRingHeliocentrism'],
      translateX: anime.path('.orbit6Heliocentrism')('x'),
      translateY: anime.path('.orbit6Heliocentrism')('y'),
      rotate: anime.path('.orbit6Heliocentrism')('angle'),
      easing: 'linear',
      duration: 15000,
      loop: true,
      autoplay: false
    }),
    anime({
      targets: '.uranusHeliocentrism',
      translateX: anime.path('.orbit7Heliocentrism')('x'),
      translateY: anime.path('.orbit7Heliocentrism')('y'),
      rotate: anime.path('.orbit7Heliocentrism')('angle'),
      easing: 'linear',
      duration: 30000,
      loop: true,
      autoplay: false
    }),
    anime({
      targets: '.neptuneHeliocentrism',
      translateX: anime.path('.orbit8Heliocentrism')('x'),
      translateY: anime.path('.orbit8Heliocentrism')('y'),
      rotate: anime.path('.orbit8Heliocentrism')('angle'),
      easing: 'linear',
      duration: 42000,
      loop: true,
      autoplay: false
    }),
    anime({
      targets: '.plutoHeliocentrism',
      translateX: anime.path('.orbit9Heliocentrism')('x'),
      translateY: anime.path('.orbit9Heliocentrism')('y'),
      rotate: anime.path('.orbit9Heliocentrism')('angle'),
      easing: 'linear',
      duration: 84000,
      loop: true,
      autoplay: false
    })
  ]
    this.animationH.forEach(item => item.play());
    // this.animationH.play()
  }

  componentDidMount(){
    this.animateH();
    // this.animation.forEach(item => item.play());
  }

  componentWillUnmount(){
    this.animationH.forEach(item => item.pause());
    // this.animationH.pause()
  }

  render(){
    return(
      <svg className="orbitSvgHeliocentrism" style={{position: 'relative', left: '35%', bottom: '5%'}} id="orbit-svg-helio" height="350" width="350">

            <circle id="orbit-mercury-id" className="orbit1Heliocentrism" cx="160" cy="160" r="30" stroke="white" strokeWidth="3" fill="none"/>
            <circle className="orbit2Heliocentrism" cx="160" cy="160" r="45" stroke="white" strokeWidth="3" fill="none"/>
            <circle className="orbit3Heliocentrism" cx="160" cy="160" r="60" stroke="white" strokeWidth="3" fill="none"/>
            <circle className="orbit4Heliocentrism" cx="160" cy="160" r="75" stroke="white" strokeWidth="3" fill="none"/>
            <circle className="orbit5Heliocentrism" cx="160" cy="160" r="90" stroke="white" strokeWidth="3" fill="none"/>
            <circle className="orbit6Heliocentrism" cx="160" cy="160" r="105" stroke="white" strokeWidth="3" fill="none"/>
            <circle className="orbit7Heliocentrism" cx="160" cy="160" r="120" stroke="white" strokeWidth="3" fill="none"/>
            <circle className="orbit8Heliocentrism" cx="160" cy="160" r="135" stroke="white" strokeWidth="3" fill="none"/>
            <circle className="orbit9Heliocentrism" cx="160" cy="160" r="150" stroke="white" strokeWidth="3" fill="none"/>

            <circle id="mercury-id" className="mercuryHeliocentrism" cx="22" cy="8" r="5" stroke="gray" strokeWidth="3" fill="gray"/>
            <circle className="venusHeliocentrism" cx="27" cy="8" r="6" stroke="orange" strokeWidth="3" fill="orange"/>
            <circle className="earthHeliocentrism" cx="30" cy="8" r="6" stroke="DodgerBlue " strokeWidth="3" fill="DodgerBlue "/>
            <circle className="marsHeliocentrism" cx="33" cy="8" r="6" stroke="red" strokeWidth="3" fill="red"/>
            <circle className="jupiterHeliocentrism" cx="36" cy="8" r="8" stroke="GoldenRod" strokeWidth="3" fill="GoldenRod"/>
            <ellipse className="saturnRingHeliocentrism" cx="39" cy="8" rx="12" ry="5" style={{fill:'none', stroke: 'white', strokeWidth:2}} />
            <circle className="saturnHeliocentrism" cx="39" cy="8" r="7" stroke="gold" strokeWidth="3" fill="gold"/>
            <circle className="uranusHeliocentrism" cx="42" cy="8" r="7" stroke="aquamarine" strokeWidth="3" fill="aquamarine"/>
            <circle className="neptuneHeliocentrism" cx="45" cy="8" r="7" stroke="blue" strokeWidth="3" fill="blue"/>
            <circle className="plutoHeliocentrism" cx="48" cy="8" r="4" stroke="DarkSeaGreen " strokeWidth="3" fill="DarkSeaGreen "/>
            <circle className="sunHeliocentrism" cx="160" cy="160" r="12" stroke="yellow" strokeWidth="3" fill="yellow"/>
          </svg>
    )
  }
}

function HeliocentrismPage(props) {
  const [heliocentrismStep, setHeliocentrismStep] = useState(0);
  const [isMinimized, setIsMinimized] = useState(false);


  const standardObj = [
    {
      name: 'Geocentrismo',
      heliocentrismStep: 1,
      comp: (
        <>
          <h1 className="presentationItem">Geocentrismo</h1>

          <p className="presentationItem">
            Durante muito tempo a humanidade{' '}
            <b className="presentationItem">
              acreditou que a Terra era o centro de todo o universo
            </b>
            . Tem-se registros dessa ideia desde Aristóteles, por volta de 350 AC, na Grécia Antiga.
          </p>
          <p className="presentationItem">
            No século II DC, o matemático e astrônomo grego Claudio Ptolomeu reforçou esse
            pensamento com a puplicação do livro Almagesto, o qual apresentava a teoria do
            <b className="presentationItem"> Geocentrismo</b>.
          </p>
          <p className="presentationItem">
            Através do Geocentrismo acreditava-se que que a Lua, o Sol e os cinco planetas -
            Mercúrio, Vênus, Marte, Júpiter e Saturno - orbitavam ao seu redor. Naquela época os
            planetas Urano, Netuno e Plutão ainda não haviam sido descobertos.
          </p>
          <svg className="orbitSvgGeocentrism" style={{marginTop: '6%', position: 'relative', left: '35%', bottom: '10%'}} id="orbit-svg-geo" height="255" width="350">

            <circle className="orbit1Geocentrism" cx="130" cy="130" r="30" stroke="white" strokeWidth="1" fill="none"/>
            <circle className="orbit2Geocentrism" cx="130" cy="130" r="45" stroke="white" strokeWidth="1" fill="none"/>
            <circle className="orbit3Geocentrism" cx="130" cy="130" r="60" stroke="white" strokeWidth="1" fill="none"/>
            <circle className="orbit4Geocentrism" cx="130" cy="130" r="75" stroke="white" strokeWidth="1" fill="none"/>
            <circle className="orbit5Geocentrism" cx="130" cy="130" r="90" stroke="white" strokeWidth="1" fill="none"/>
            <circle className="orbit6Geocentrism" cx="130" cy="130" r="105" stroke="white" strokeWidth="1" fill="none"/>
            <circle className="orbit7Geocentrism" cx="130" cy="130" r="120" stroke="white" strokeWidth="1" fill="none"/>

            <circle className="mercuryGeocentrism" cx="130" cy="101" r="4" stroke="white" strokeWidth="3" fill="white"/>
            <text x="95" y="90" id="mercury-text" className="secondaryFont orbitSvgGeocentrism__mercuryText" fill="white">Lua</text>

            <circle className="venusGeocentrism" cx="161" cy="79" r="6" stroke="orange" strokeWidth="3" fill="orange"/>
            <text x="120" y="65" id="venus-text" className="secondaryFont orbitSvgGeocentrism__venusText" fill="white">Vênus</text>

            <circle className="sunGeocentrism" cx="198" cy="101" r="8" stroke="yellow" strokeWidth="3" fill="yellow"/>
            <text x="205" y="90" id="sun-text" className="secondaryFont orbitSvgGeocentrism__sunText" fill="white">Sol</text>

            <circle className="marsGeocentrism" cx="202" cy="182" r="6" stroke="red" strokeWidth="3" fill="red"/>
            <text x="200" y="210" id="mars-text" className="secondaryFont orbitSvgGeocentrism__marsText" fill="white">Marte</text>

            <circle className="jupiterGeocentrism" cx="199" cy="51" r="8" stroke="GoldenRod" strokeWidth="3" fill="GoldenRod"/>
            <text x="210" y="35" id="jupiter-text" className="secondaryFont orbitSvgGeocentrism__jupiterText" fill="white">Júpiter</text>

            <ellipse className="saturnRingGeocentrism" cx="248" cy="148" rx="12" ry="5" style={{fill:'none', stroke: 'white', strokeWidth:2}} />
            <circle className="saturnGeocentrism" cx="248" cy="148" r="7" stroke="gold" strokeWidth="3" fill="gold"/>
            <text x="260" y="135" id="saturn-text" className="secondaryFont orbitSvgGeocentrism__saturnText" fill="white">Saturno</text>

            <circle className="moonGeocentrism" cx="159" cy="165" r="6" stroke="gray" strokeWidth="3" fill="gray"/>
            <text x="90" y="180" id="moon-text" className="secondaryFont orbitSvgGeocentrism__moonText" fill="white">Mercúrio</text>

            <circle className="earthGeocentrism" cx="130" cy="130" r="6" stroke="aquamarine" strokeWidth="3" fill="aquamarine"/>
            <text x="136" y="126" id="earth-text" className="secondaryFont orbitSvgGeocentrism__earthText" fill="white">Terra</text>
          </svg>
          <div className="presentationItem arrowBtnContainer">
            <Button
              icon
              labelPosition="right"
              onClick={() => {
                setHeliocentrismStep(heliocentrismStep + 1);
              }}
            >
              Próximo
              <Icon name="right arrow" />
            </Button>
          </div>
        </>
      ),
    },
    {
      name: 'Heliocentrismo',
      heliocentrismStep: 2,
      comp: (
        <>
          <h1 className="presentationItem">Heliocentrismo</h1>
          <p className="presentationItem">
            No século XVI <b className="presentationItem"> Nicolau Copérnico</b> sistematizou um
            modelo que ficou conhecido como
            <b className="presentationItem"> Heliocentrismo</b>. Neste modelo, o{' '}
            <b className="presentationItem"> Sol ficava no centro do Sistema Solar</b>, com os
            outros planetas movendo ao seu redor em órbitas circulares - que no futuro viriam a ser
            descobertas como elípitcas.
          </p>

          <p className="presentationItem">
            Copérnico é considerado o pai da astronomia moderna e após suas teorias, outros
            cientistas como Tycho Brahe, Johannes Kepler e Galileu Galilei viram a realizar novas
            descobertas.
          </p>

          <OrbitsAndPlanetsMoving />

          <div className="presentationItem arrowBtnContainer">
            <Button
              icon
              labelPosition="left"
              onClick={() => {
                setHeliocentrismStep(heliocentrismStep - 1);
              }}
            >
              <Icon name="left arrow" />
              Anterior
            </Button>
          </div>
        </>
      ),
    },
  ];

  return (
    <div className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`}>
      {/* <div className="minMaxBar">
        <Icon className="icon" name='caret down' onClick={minimizeBar}/>
        <Icon className="icon" name='caret up' onClick={maximizeBar}/>
      </div> */}
      {!isMinimized && standardObj[heliocentrismStep].comp}
    </div>
  );
}

export default HeliocentrismPage;

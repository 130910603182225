import React, { useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import anime from 'animejs/lib/anime.es.js';
import './sticker.scss';
import { Link } from 'react-router-dom';

function Sticker(props) {
  const [clicked, setClicked] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [id, setId] = useState('');

  function openCloseSticker(e) {
    if (props.unlocked) {
      let id = e.target.id;
      setId(id);
      setClicked(!clicked);
    }
  }

  useEffect(() => {
    if (clicked && id) {
      anime({
        targets: ['#' + id],
        zIndex: { value: ['1', '1000'] },
        duration: 0,
      });
      anime({
        targets: ['#' + id],
        rotateY: '1turn',
        translateY: 100,
        scale: 2,
        duration: 1000,
        easing: 'linear',
      });
      anime({
        targets: '#description-' + props.identifier,
        backgroundColor: '#303841',
        opacity: [0, 1],
        zIndex: { value: ['-1', '2'] },
        easing: 'linear',
      });
    } else if (id && !clicked) {
      anime({
        targets: '#' + id,
        zIndex: { value: ['1000', '1'] },
        duration: 0,
      });
      anime({
        targets: '#' + id,
        rotateY: '-1turn',
        translateX: 0,
        translateY: 0,
        scale: 1,
        duration: 1000,
        easing: 'linear',
      });
      anime({
        targets: '#description-' + props.identifier,
        opacity: [1, 0],
        zIndex: { value: ['2', '-1'] },
        easing: 'linear',
      });
    }
  }, [clicked]);

  return (
    <>
      <div className="sticker__container">
        <img
          style={{ position: 'relative', zIndex: props.unlocked ? 1 : 0 }}
          id={props.identifier}
          src={`data:image/jpeg;base64,${
            clicked && props.identifier === id
              ? showDescription
                ? props.baseImg
                : props.img
              : props.img
          }`}
          alt={props.identifier}
          className={!props.unlocked ? 'sticker__locked' : 'sticker__img'}
          onClick={(e) => openCloseSticker(e)}
        />

        {/* <p id={props.identifier+"-desc"} className="sticker__description mainFont" style={{opacity: (showDescription ? 1 : 0), zIndex: (showDescription ? 1001 : 999)}}>
                {props.stickerDescription}
            </p> */}
        {!props.unlocked && <Icon size="huge" className="sticker__icon" name="lock" />}
      </div>
      <div id={'description-' + props.identifier} className="sticker__description">
        <h1 style={{ textAlign: 'left' }} className="presentationItem">
          {props.name}
        </h1>
        <p>
          Módulo:{' '}
          <Link className="__internalLink" to={props.route}>
            {props.module}
          </Link>
        </p>
        <p>{props.stickerDescription}</p>
      </div>
    </>
  );
}

export default Sticker;

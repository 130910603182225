import React from 'react';
import '../../../Common/kinematicsMenu.css';

import autodromo from '../../../../images/Physics/Kinematics/autodromo.png';
import oficina from '../../../../images/Physics/Kinematics/oficina.png';
import museu from '../../../../images/Physics/Kinematics/museu.png';
import escritorio from '../../../../images/Physics/Kinematics/escritorio.png';
import hall from '../../../../images/Physics/Kinematics/hall.png';
import laboratorio from '../../../../images/Physics/Kinematics/laboratorio.png';
import manager from '../../../../images/Physics/Kinematics/face_manager.PNG';
import bckgd from '../../../../images/Physics/Kinematics/menuBgdFour.png';
import ChallengeMenuComponent from '../../../../components/MapComponents/challengeMenuComponent';

function ChallengeMenu(props) {
  let code =
    "if (acessMission === 'false') { \n" +
    "  missionMessage = 'Investigue o Museu.'; \n" +
    "} else if (acessMission === 'true' && arrStars[0] === false) { \n" +
    "missionMessage = 'Aprenda novos conteúdos no Laboratório.'; \n" +
    "} else if (acessMission === 'true' && arrStars[3] === false && acquiredStars <= 2) { \n" +
    "missionMessage = 'Ganhe a corrida no Autódromo. Você conseguirá ajuda na Oficina.'; \n" +
    "} else if (acessMission === 'true' && arrStars[3] === false && acquiredStars === 3) { \n" +
    "missionMessage = 'Ganhe a corrida no Autódromo.'; \n" +
    "} else if (acessMission === 'true' && arrStars[3] === true && acquiredStars <= 3) { \n" +
    'missionMessage = \n' +
    "'Veja seu desempenho no Hall da Fama. Você pode conseguir mais estrelas na Oficina.'; \n" +
    "} else if (acessMission === 'true' && acquiredStars === 4) { \n" +
    "missionMessage = 'Veja seu desempenho no Hall da Fama.'; \n" +
    '} else { \n' +
    " missionMessage = 'Bug';" +
    '}';

  return (
    <>
      <ChallengeMenuComponent
        background={bckgd}
        subject="physics"
        typeScoring="kinematics"
        arrayScoring="arrStartTorricelli"
        acessMessage="acessMuseum"
        messagePosition={3}
        setIsMinimized={props.setIsMinimized}
        setPresentationItems={props.setPresentationItems}
        setActiveItem={props.setActiveItem}
        validadeMessage={code}
        title="Desafio 4: Eq. de Torricelli"
        titleColor="preto"
        emissor="Marcelo Cura:"
        imageEmissor={manager}
        acessPoints={[
          {
            position: 'position6',
            image: autodromo,
            content: ['Explicação do Desafio'],
            start: 'Explicação do Desafio',
            setInfo: null,
            setValue: null,
          },
          {
            position: 'position2',
            image: oficina,
            content: ['Oficina'],
            start: 'Oficina',
            setInfo: null,
            setValue: null,
          },
          {
            position: 'position4',
            image: museu,
            content: ['Museu', 'Conquistas', 'Sua primeira conquista'],
            start: 'Museu',
            setInfo: 'acessMuseum',
            setValue: ['true', 'true', 'true', 'true'],
          },
          {
            position: 'position3',
            image: escritorio,
            content: ['Apresentação', 'Vamos Começar!'],
            start: 'Apresentação',
            setInfo: null,
            setValue: null,
          },
          {
            position: 'position1',
            image: laboratorio,
            content: [
              'Laboratório',
              'Espaço, Velocidade e Tempo',
              'Torricelli',
              'Desvendando Torricelli',
            ],
            start: 'Laboratório',
            setInfo: null,
            setValue: null,
          },
          {
            position: 'position5',
            image: hall,
            content: ['Você conseguiu!'],
            start: 'Você conseguiu!',
            messageWithoutAcess:
              'O hall da fama está fechado. Deixe para comemorar quando vencer o desafio.',
            validateAcess: 'arrStars[3] === true',
          },
        ]}
      />
    </>
  );
}

export default ChallengeMenu;

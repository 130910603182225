import React, { useState, useEffect } from 'react';
import Anime, { anime } from 'react-anime';
import StarMessage from '../../../../components/starMessage';
import earth from "../../../../images/earthT2.png";
import planet from "../../../../images/planetT.png";
import rocket from "../../../../images/rocketT.png";
import astronaut from "../../../../images/astronautT.png"
import blackHole from "../../../../images/blackHole.png";

function BlackHole(props) {
  const [step, setStep] = useState(0);
  const [isMinimized, setIsMinimized] = useState(false);


  const textZIndexStyle = {
    position: 'relative',
    zIndex: 10,
  }

  const standardObj = [
    {
      name: 'Gravidade e Aceleração',
      step: 1,
      comp: (
        <>
          <h1 id="titulo-black-hole" className="presentationItem">Buracos Negros</h1>

          <p id="texto" style={textZIndexStyle} className="presentationItem">
            Em uma estrela, como nosso Sol, estão sempre ocorrendo dois fenômenos: seus milhões de átomos estão em constante fusão nuclear, <b className="presentationItem">gerando grande pressão para o exterior</b>; ao mesmo tempo em que a <b className="presentationItem">força gravitacional atrai suas partículas em direção ao seu interior</b>. Quando acaba esse combustível nuclear, a pressão em direção ao exterior cessa e a <b className="presentationItem">força gravitacional passa a ser a única força resultante</b>, atraindo as partículas e fazendo a estrela tornar-se uma anã branca.
          </p>

          <p id="texto2" style={textZIndexStyle} className="presentationItem">
            Para casos de <b className="presentationItem">astros com muita massa</b>, a <b className="presentationItem">força gravitacional é tão grande</b> que colapsa a estrela, gerando um buraco negro. Nesses casos, temos um objeto de <b className="presentationItem">enorme densidade</b>, com grande massa e tamanho reduzido, em que <b className="presentationItem">nem mesmo a luz é capaz de escapar de sua gravidade </b>. Esse objetos são encontrados com frequência nos centros das grandes galáxias.
          </p>

          <p id="texto3" style={textZIndexStyle} className="presentationItem">
            Para se ter ideia, caso quiséssemos tornar a Terra um buraco negro, seria necessário comprimí-la para o tamanho de uma uva. O nosso Sol não se tornará um buraco negro quando 'morrer' daqui a 5 bilhões de anos, visto que sua massa não é tão grande. Mas caso sua masse fosse 10 vezes maior, ele poderia colapsar em um buraco negro do tamanho da cidade de Nova York.
          </p>
          <img id="the-black-hole" width="100px" style={{position: 'absolute', bottom: '4vh', right: '4vh'}} src={blackHole}/>
          {/* <svg id="planet-1-black-hole" height="50" width="50">
            <circle r="10" cx="25" cy="25" stroke="dodgerblue" fill="dodgerblue"/>
          </svg> */}
          <img id="planet-black-hole1" width="100px" style={{position: 'absolute', bottom: '4vh', left: '0'}} src={earth}/>
          <img id="rocket-black-hole" width="150px" style={{position: 'absolute', bottom: '3vh', left: '150px'}} src={rocket}/>
          <img id="planet-black-hole3" width="90px" style={{position: 'absolute', bottom: '5vh', left: '320px'}} src={planet}/>
          <img id="astronaut-black-hole" width="100px" style={{position: 'absolute', bottom: '3vh', left: '420px'}} src={astronaut}/> 
        </>
      ),
    },
  ];

  const animationDelay = 50000;

  useEffect(()=>{
    anime({
      // targets: '#planet-1-black-hole',
      targets: '#titulo-black-hole',
      // translateX: {value: '55vw', easing: 'easeInCubic', duration: 20000},
      translateX: {value: '30vw', easing: 'easeInCubic', duration: 10000},
      translateY: {value: '30vw', easing: 'easeInCubic', duration: 10000},
      scale: {value: 0, easing: 'easeInCubic', duration: 10000},
      delay: animationDelay,
      opacity: {value: [1,0], easing: 'easeInCubic', duration: 1000, delay: animationDelay + 9000},
      // loop: true
    })
    anime({
      // targets: '#planet-1-black-hole',
      targets: '#texto',
      // translateX: {value: '55vw', easing: 'easeInCubic', duration: 20000},
      translateX: {value: '30vw', easing: 'easeInCubic', duration: 10000},
      translateY: {value: '25vw', easing: 'easeInCubic', duration: 10000},
      scale: {value: 0, easing: 'easeInCubic', duration: 10000},
      delay: animationDelay,
      opacity: {value: [1,0], easing: 'easeInCubic', duration: 1000, delay: animationDelay + 9000},
      // loop: true
    })
    anime({
      // targets: '#planet-1-black-hole',
      targets: '#texto2',
      // translateX: {value: '55vw', easing: 'easeInCubic', duration: 20000},
      translateX: {value: '30vw', easing: 'easeInCubic', duration: 10000},
      translateY: {value: '20vw', easing: 'easeInCubic', duration: 10000},
      scale: {value: 0, easing: 'easeInCubic', duration: 10000},
      delay: animationDelay,
      opacity: {value: [1,0], easing: 'easeInCubic', duration: 1000, delay: animationDelay + 9000},
      // loop: true
    })
    anime({
      // targets: '#planet-1-black-hole',
      targets: '#texto3',
      // translateX: {value: '55vw', easing: 'easeInCubic', duration: 20000},
      translateX: {value: '30vw', easing: 'easeInCubic', duration: 10000},
      translateY: {value: '5vw', easing: 'easeInCubic', duration: 10000},
      scale: {value: 0, easing: 'easeInCubic', duration: 10000},
      delay: animationDelay,
      opacity: {value: [1,0], easing: 'easeInCubic', duration: 1000, delay: animationDelay + 9000},
      // loop: true
    })
    anime({
      targets: '#planet-black-hole1',
      translateX: {value: '55vw', easing: 'easeInCubic', duration: 10000},
      translateY: {value: '-1vw', easing: 'easeInCubic', duration: 10000},
      scale: {value: 0, easing: 'easeInCubic', duration: 10000},
      delay: animationDelay,
      opacity: {value: [1,0], easing: 'easeInCubic', duration: 1000, delay: animationDelay + 9000},
      // loop: true
    })
    anime({
      targets: '#rocket-black-hole',
      translateX: {value: '45vw', easing: 'easeInCubic', duration: 10000},
      // translateY: {value: '-1vw', easing: 'easeInCubic', duration: 10000},
      scale: {value: 0, easing: 'easeInCubic', duration: 10000},
      delay: animationDelay,
      opacity: {value: [1,0], easing: 'easeInCubic', duration: 1000, delay: animationDelay + 9000},
      // loop: true
    })
    anime({
      targets: '#planet-black-hole3',
      translateX: {value: '35vw', easing: 'easeInCubic', duration: 10000},
      translateY: {value: '-2vw', easing: 'easeInCubic', duration: 10000},
      scale: {value: 0, easing: 'easeInCubic', duration: 10000},
      delay: animationDelay,
      opacity: {value: [1,0], easing: 'easeInCubic', duration: 1000, delay: animationDelay + 9000},
      // loop: true
    })
    anime({
      targets: '#astronaut-black-hole',
      translateX: {value: '20vw', easing: 'easeInCubic', duration: 10000},
      translateY: {value: '-2vw', easing: 'easeInCubic', duration: 10000},
      scale: {value: 0, easing: 'easeInCubic', duration: 10000},
      delay: animationDelay,
      opacity: {value: [1,0], easing: 'easeInCubic', duration: 1000, delay: animationDelay + 9000},
      // loop: true
    })
    anime({
      targets: '#the-black-hole',
      rotate: '360deg',
      loop: true,
      easing:'linear'
    })
  })

  return (
    <div className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`}>
      {/* <div className="minMaxBar">
        <Icon className="icon" name='caret down' onClick={minimizeBar}/>
        <Icon className="icon" name='caret up' onClick={maximizeBar}/>
      </div> */}
      {!isMinimized && standardObj[step].comp}
    </div>
  );
}

export default BlackHole;

import React, { useState, useEffect, useRef } from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import anime from 'animejs/lib/anime.es.js';
import { MathComponent } from 'mathjax-react';
import roadT from '../../../../images/Physics/Kinematics/roadT.png';
import sideCarT from '../../../../images/Physics/Kinematics/carT.png';

function SpeedPage(props) {
  const [boardwidth, setBoardWidth] = useState(0);
  const [isMinimized, setIsMinimized] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    props.setNextItem('Velocidade média');
    if (ref.current) {
      setBoardWidth(ref.current.clientWidth);
    }
  }, []);

  useEffect(() => {
    moveCar();
  }, [boardwidth]);



  function moveCar() {
    anime({
      targets: '#speed-thirty-seconds',
      innerText: {
        value: [30] + ' segundos',
      },
      round: 1,
      easing: 'linear',
      duration: 30000,
    });
    anime({
      targets: '#speed-fifty-seconds',
      innerText: {
        value: [50] + ' segundos',
      },
      round: 1,
      easing: 'linear',
      duration: 50000,
    });
    anime({
      targets: '#car-speed1, #speed-thirty-seconds',
      translateX: boardwidth - 100,
      easing: 'linear',
      duration: 30000,
    });
    anime({
      targets: '#car-speed2, #speed-fifty-seconds',
      translateX: boardwidth - 100,
      easing: 'linear',
      duration: 50000,
    });
    anime({
      targets: '#car-speed1, #speed-thirty-seconds',
      translateX: boardwidth - 100,
      easing: 'linear',
      duration: 30000,
    });
    anime({
      targets: '#car-speed2, #speed-fifty-seconds',
      translateX: boardwidth - 100,
      easing: 'linear',
      duration: 50000,
    });
  }

  return (
    <div
      ref={ref}
      className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`}
      key={Math.random()}
    >
      {/* <div className="minMaxBar">
        <Icon className="icon" name="caret down" onClick={minimizeBar} />
        <Icon className="icon" name="caret up" onClick={maximizeBar} />
      </div> */}
      {!isMinimized && (
        <>
          <h1 className="presentationItem">Velocidade</h1>
          <p className="presentationItem">
            Por velocidade entendemos a{' '}
            <b className="presentationItem">
              distância percorrida em determinada variação de tempo
            </b>
            , nos dando a sensação de rapidez. Em uma viagem entre o Rio de Janeiro e São Paulo
            percorreremos os mesmos 432 km, porém, podemos realizar essa viagem em 4 ou 6 horas,
            dependendo da velocidade do carro.
            <br></br>
            Pelo conceito de velocidade média, temos a fórmula:
            <MathComponent
              tex={String.raw`Vm = \frac{\Delta S}{\Delta t} = \frac{deslocamento}{tempo}`}
            />
            <br></br>
            Veja só o que acontece quando dois carros percorrem a distância de 600 metros com
            velocidades de 20 e 12 metros/segundo respectivamente:
          </p>

          <div className="container" style={{height: '15%'}}>
            <h1 id="speed-thirty-seconds" className="highlightText presentationItem"></h1>
            <img id="road-speed1" className="presentationItem speed" src={roadT} />
            <img id="car-speed1" className="presentationItem speed" src={sideCarT} />
          </div>

          <div className="container" style={{height: '15%'}}>
            <h1 id="speed-fifty-seconds" className="highlightText presentationItem"></h1>
            <img id="road-speed2" className="presentationItem speed" src={roadT} />
            <img id="car-speed2" className="presentationItem speed" src={sideCarT} />
          </div>
          <span className="white presentationItem">Unidades</span>

          <Popup
            wide
            inverted
            content={
              <p className="presentationItem">
                <br></br>A velocidade é comumente representado pela letra{' '}
                <b className="presentationItem">
                  <i>v</i>
                </b>{' '}
                e a unidade padrão de tempo no Sistema Internacional é{' '}
                <b className="presentationItem">
                  'metros por segundo' (representada por <i>m/s</i> )
                </b>
                .<br></br>
                Outra unidade bastante utilizada é km/h.
              </p>
            }
            trigger={<Icon name="question circle outline" />}
          />
        </>
      )}
    </div>
  );
}

export default SpeedPage;

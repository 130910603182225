import React from 'react';
import TipsComponent from '../../../../components/MapComponents/tipsComponent';

import tip3 from '../../../../images/Physics/Kinematics/tip3.PNG';
import tip4 from '../../../../images/Physics/Kinematics/tip4.PNG';
import tip5 from '../../../../images/Physics/Kinematics/tip5.PNG';

function Solution(props) {
  return (
    <>
      <TipsComponent
        numberTips={3}
        presentation1="Bem vindo à Oficina, um local onde conseguimos reparar algumas arestas em seu aprendizado e dar algumas dicas para você conseguir ganhar mais uma corrida!"
        presentation2="Aqui temos que trabalhar duro, então algumas dicas necessitarão de um esforço seu. Vamos lá!"
        subject="physics"
        typeScoring="kinematics"
        arrayScoring="arrStarAceleration"
        tips={[
          {
            number: 0,
            image: tip5,
            intro1: 'Observando os motores você encontra uma dica',
            intro2: null,
            icon: 'cogs',
            title: 'Motor',
            dica1: true,
            dica1HasCondition: false,
            dica1ValidateCondition: null,
            dica1ConditionType: null,
            dica1MessageCondition: null,
            dica1ResponsePt1:
              'Pelos motores você identifica qual a fórmula deve utilizar para ganhar esse desafio.',
            dica1ResponsePt2:
              'Temos a informação do tempo disponível para a aceleração do carro, da velocidade inicial e da velocidade final. Assim, para descobrir a aceleração necessária para a vitória, utilize:',
            dica1ResponsePt3: null,
            dica1ResponsePt4: null,
            dica1ResponseEquation1: String.raw`V_{Final} =  V_{Inicial} + a \cdot t_{Final}`,
            dica1ResponsePt5: null,
            dica2: false,
          },
          {
            number: 1,
            image: tip3,
            intro1: 'Olá caro amigo, lembra-se de mim? Tenho mais uma dica para você!',
            intro2: null,
            icon: 'wrench',
            title: 'Mecânico Luiz Câmera',
            dica1: true,
            dica1HasCondition: true,
            dica1ValidateCondition: 'arrStars[1] === true',
            dica1ConditionType: 'mission',
            dica1MessageCondition:
              'Essa dica está bloqueada para você! Para desbloqueá-la, realize a missão "O carro mais rápido".',
            dica1ResponsePt1:
              'Um dos problemas que você pode encontrar para a solução do desafio será a conversão de unidades, visto que temos uma velocidade final de 300 km/h e o tempo disponível para aceleração em 15 segundos.',
            dica1ResponsePt2:
              'Que tal transformar a nossa velocidade para a unidade de m/s? Podemos fazer:',
            dica1ResponsePt3: null,
            dica1ResponsePt4: null,
            dica1ResponseEquation1: String.raw`\frac{300 km}{1 h} = \frac{300 * 1000m}{1 * 3600 s} = 83,33 m/s`,
            dica1ResponsePt5: null,
            dica1QuestionTittle:
              '(IFSP) Um dos carros mais rápidos do mundo é o Bugatti Veyron, que alcança a velocidade máxima de aproximadamente 410 km/h, conseguindo chegar de zero a 99 km/h em aproximadamente 2,5 s. Nesse intervalo de tempo, podemos concluir que a aceleração escalar média do carro é, em m/s2, aproximadamente de:',
            dica1OptionA: '9',
            dica1OptionB: '11',
            dica1OptionC: '13',
            dica1OptionD: '15',
            dica1OptionE: '17',
            dica1CorrectAnswer: 'b',
            dica1MissionName: 'O carro mais rápido',
            dica1Subject: 'physics',
            dica1TypeScoring: 'kinematics',
            dica1ArrayScoring: 'arrStarAceleration',
            dica1IndexScoring: 1,
            dica1XPAward: 30,
            dica2: false,
          },
          {
            number: 2,
            image: tip4,
            intro1:
              'Mais um desafio difícil não é? Que nada, vou te dar uma dica infalível para a vitória em nossa segunda corrida.',
            intro2: null,
            icon: 'clipboard',
            title: 'Eng. Thais Condida',
            dica1: true,
            dica1HasCondition: true,
            dica1ValidateCondition: 'arrStars[2] === true',
            dica1ConditionType: 'mission',
            dica1MessageCondition:
              'Essa dica está bloqueada para você! Para desbloqueá-la, realize a missão "A maior aceleração".',
            dica1ResponsePt1:
              'Sabemos que a nossa velocidade inicial será de 0 km/h, enquanto a final será de 300 km/h. Junto com o mecânico Luiz Câmera, convertemos a velocidade para 83,33 m/s, unidade que fica mais adequada para nossos cálculos. Caso não tenha visto esse cálculo, converse com o Luiz!',
            dica1ResponsePt2:
              'Também sabemos que o tempo de aceleração será de 15 segundos. Assim, podemos calcular:',
            dica1ResponsePt3: null,
            dica1ResponsePt4: null,
            dica1ResponseEquation1: String.raw`V_{Final} =  V_{Inicial} + a \cdot t_{Final} `,
            dica1ResponseEquation2: String.raw`83.33 m/s =  0 + a \cdot 15 s `,
            dica1ResponseEquation3: String.raw`a =  \frac{83.33 m/s}{15 s} `,
            dica1ResponseEquation4: String.raw`a =  5.55 m/s^2 `,
            dica1ResponsePt5: null,
            dica1QuestionTittle:
              '(FMTM-MG) Um cientista, estudando a aceleração média de três diferentes carros, obteve os seguintes resultados:',
            dica1QuestionSubtittle1:
              'O carro I variou sua velocidade de v para 2v em um intervalo de tempo igual a t;',
            dica1QuestionSubtittle2:
              'O carro II variou sua velocidade de v para 3v em um intervalo de tempo igual a 2t;',
            dica1QuestionSubtittle3:
              'O carro III variou sua velocidade de v para 5v em um intervalo de tempo igual a 5t.',
            dica1QuestionSubtittle4:
              'Sendo, respectivamente, a1, a2 e a3 as acelerações dos carros I, II e III, pode-se afirmar que:',
            dica1OptionA: 'a1 = a2 = a3',
            dica1OptionB: 'a1 > a2 > a3',
            dica1OptionC: 'a1 < a2 < a3',
            dica1OptionD: 'a1 = a2 > a3',
            dica1OptionE: 'a1 = a2 < a3',
            dica1CorrectAnswer: 'd',
            dica1MissionName: 'A maior aceleração',
            dica1Subject: 'physics',
            dica1TypeScoring: 'kinematics',
            dica1ArrayScoring: 'arrStarAceleration',
            dica1IndexScoring: 2,
            dica1XPAward: 30,
            dica2: false,
          },
        ]}
      />
    </>
  );
}

export default Solution;

import { SVG } from '@svgdotjs/svg.js';
import { compose, pipe, range } from 'ramda';
import React, { useEffect } from 'react';
import { AuthPage } from '../../components/HOComponents/AuthPage';
import ScaleGrid from '../../libs/popUtils/Scalegrid';

export interface ScalePlotProps {
  plotSizeX: number;
  plotSizeY: number;
}

function ScalePlot({ plotSizeX, plotSizeY }: ScalePlotProps) {
  let animate = () => {};
  useEffect(() => {
    const x = range(-50, 50).map((n) => (n / 100) * Math.PI);
    const y = x.map((n) => Math.sin(n) + Math.sin(3 * n));
    const scaleX: [number, number] = [-1, 2];
    const scaleY: [number, number] = [-1, 2];
    const draw = SVG().addTo('#plot').size(plotSizeX, plotSizeY);
    const sg = new ScaleGrid(draw, { scaleX, scaleY, stroke: { width: 2, color: 'black' } });

    sg.drawTicks({ stroke: { color: 'black', width: 2 } })
      .drawTicksText('black')
      .addLabel('amplitude(A)', 'tempo(s)', { color: '#000' })
      .trackPlot();
    sg.plotFn((x) => x ** 2);
    sg.plotFn((x) => x ** 3, { name: 'plot2', stroke: { color: 'red', width: 2 } });

    animate = () => {
      const nRandomFactor1 = Math.random();
      const nRandomFactor2 = Math.random();
      sg.animateFn((x) => Math.sin(x * nRandomFactor1 * 10));
      sg.animateFn((x) => Math.sin(x * nRandomFactor2 * 10), { name: 'plot2' });
    };
  }, []);
  return (
    <div className="scale-plot">
      <div id="plot"></div>
      <button onClick={() => animate()}>Randomize</button>
    </div>
  );
}

export default React.memo(AuthPage(ScalePlot));

import React, { useState, useEffect } from 'react';
import { Button, Icon, Image, List, Divider } from 'semantic-ui-react';

function MissionComponent(props) {
  var arrStars = (sessionStorage.getItem('arr_stars') || '')
    .split(',')
    .map((e) => (e === 'true' ? true : false));

  var numStars = [];
  var colorStars = [];
  var styleStars = [];
  arrStars.forEach((star) => {
    numStars.push(star ? 1 : 0);
    colorStars.push(star ? 'yellow' : 'grey');
    styleStars.push(star ? { color: 'white' } : { color: 'grey' });
  });

  var totalStars = numStars.length;
  var acquiredStars = numStars.reduce((a, b) => a + b, 0);

  const [isMinimized, setIsMinimized] = useState(false);

  function minimizeBar() {
    props.minimizeFunction(true);
    setIsMinimized(true);
  }

  function maximizeBar() {
    setIsMinimized(false);
  }

  return (
    <div
      className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`}
      key={Math.random()}
    >
      {!isMinimized && (
        <>
          <h1 className="presentationItem">Conquistas</h1>
          <p className="presentationItem">
            Aqui você pode olhar seu histórico de conquistas:
            <br></br>
            <br></br>
            <span>
              Estrelas Conquistadas: {acquiredStars}/{totalStars}
            </span>
          </p>

          <p className="presentationItem">
            <List divided relaxed>
              <p>Missão:</p>
              <List.Item>
                <List.Icon
                  name="star"
                  size="large"
                  verticalAlign="middle"
                  color={colorStars[props.missionVectorNumber]}
                />
                <List.Content>
                  <p style={styleStars[props.missionVectorNumber]}>
                    {props.missionName}
                    <br></br>
                    {arrStars[props.missionVectorNumber] == true && 'Missão Realizada'}
                    {arrStars[props.missionVectorNumber] == false && props.missionDescription}
                  </p>
                </List.Content>
              </List.Item>

              <br></br>
              <br></br>
              <p>Missões secundárias:</p>

              {props.numberMissions > 1 && (
                <List.Item>
                  <List.Icon
                    name="star"
                    size="large"
                    verticalAlign="middle"
                    color={colorStars[props.submission[0].missionVectorNumber]}
                  />
                  <List.Content>
                    <p style={styleStars[props.submission[0].missionVectorNumber]}>
                      {props.submission[0].missionName}
                      <br></br>
                      {arrStars[props.submission[0].missionVectorNumber] == true &&
                        'Conquista Realizada'}
                      {arrStars[props.submission[0].missionVectorNumber] == false &&
                        props.submission[0].missionDescription}
                    </p>
                  </List.Content>
                </List.Item>
              )}

              {props.numberMissions > 2 && (
                <List.Item>
                  <List.Icon
                    name="star"
                    size="large"
                    verticalAlign="middle"
                    color={colorStars[props.submission[1].missionVectorNumber]}
                  />
                  <List.Content>
                    <p style={styleStars[props.submission[1].missionVectorNumber]}>
                      {props.submission[1].missionName}
                      <br></br>
                      {arrStars[props.submission[1].missionVectorNumber] == true &&
                        'Conquista Realizada'}
                      {arrStars[props.submission[1].missionVectorNumber] == false &&
                        props.submission[1].missionDescription}
                    </p>
                  </List.Content>
                </List.Item>
              )}

              {props.numberMissions > 3 && (
                <List.Item>
                  <List.Icon
                    name="star"
                    size="large"
                    verticalAlign="middle"
                    color={colorStars[props.submission[2].missionVectorNumber]}
                  />
                  <List.Content>
                    <p style={styleStars[props.submission[2].missionVectorNumber]}>
                      {props.submission[2].missionName}
                      <br></br>
                      {arrStars[props.submission[2].missionVectorNumber] == true &&
                        'Conquista Realizada'}
                      {arrStars[props.submission[2].missionVectorNumber] == false &&
                        props.submission[2].missionDescription}
                    </p>
                  </List.Content>
                </List.Item>
              )}

              {props.numberMissions > 4 && (
                <List.Item>
                  <List.Icon
                    name="star"
                    size="large"
                    verticalAlign="middle"
                    color={colorStars[props.submission[3].missionVectorNumber]}
                  />
                  <List.Content>
                    <p style={styleStars[props.submission[3].missionVectorNumber]}>
                      {props.submission[3].missionName}
                      <br></br>
                      {arrStars[props.submission[3].missionVectorNumber] == true &&
                        'Conquista Realizada'}
                      {arrStars[props.submission[3].missionVectorNumber] == false &&
                        props.submission[3].missionDescription}
                    </p>
                  </List.Content>
                </List.Item>
              )}
            </List>
          </p>
        </>
      )}
    </div>
  );
}

export default MissionComponent;

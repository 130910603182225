import React from "react";
import { Container, Header, Reveal } from "semantic-ui-react";
import Navbar from "../components/navbar";
import brain from "../images/brain.png";
import "./about.css";


function About(){

    return(
        <div className="aboutBackground">
            <Navbar/>
            <Container fluid>
                <section className="aboutSection">
                    <div className="halfContainer">
                        <h1 className="aboutHeader">
                            CONHECIMENTO SEM LIMITES
                        </h1>
                        <img className="aboutPage icon" src={brain} alt="idea"/>
                        <p className="aboutParagraph">
                            Aprenda de forma natural, divertida e eficiente<br/> 
                            com nossas animações interativas, que vão te levar<br/>
                            além dos limites da sala de aula 
                        </p>
                    </div>
                </section>
                <section className="aboutSection">
                    <div className="halfContainer">
                        <h1 className="aboutHeader">
                            CONHECIMENTO SEM LIMITES
                        </h1>
                        <img className="icon" src={brain} alt="idea"/>
                        <p className="aboutParagraph">
                            Aprenda de forma natural, divertida e eficiente<br/> 
                            com nossas animações interativas, que vão te levar<br/>
                            além dos limites da sala de aula 
                        </p>
                    </div>
                </section>
            </Container>
        </div>
    )
}

export default About;
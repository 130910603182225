import * as BABYLON from '@babylonjs/core';
import '@babylonjs/loaders';
import { SkyMaterial } from '@babylonjs/materials/sky';
import '@babylonjs/core/Meshes/thinInstanceMesh';

function Scenario(scene) {
  BABYLON.SceneLoader.ImportMesh(
    '',
    './scenes/Física/Cinemática/Torricelli/beachTrack.glb',
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let terrain = meshes[0];
      animationGroups.forEach((x) => {
        x.start(true);
      });
      terrain.position = new BABYLON.Vector3(23.5, -19, 140);
      terrain.rotation = new BABYLON.Vector3(0, -0.6925 * Math.PI, 0);
      terrain.scaling = new BABYLON.Vector3(1, 1, -1);
    }
  );

  var box;
  var skyMaterial = new SkyMaterial('sky', scene);
  skyMaterial.turbidity = 20;
  skyMaterial.luminance = 1;
  skyMaterial.inclination = -0.49;
  skyMaterial.azimuth = +0.25;
  skyMaterial.rayleigh = 3;
  box = BABYLON.Mesh.CreateBox('SkyBox', 5000, scene, false, BABYLON.Mesh.BACKSIDE);
  box.material = skyMaterial;
  box.position = new BABYLON.Vector3(0, 100, 0);

  scene.createDefaultEnvironment({ skyboxSize: 5000 });

  //   BABYLON.SceneLoader.ImportMesh(
  //     '',
  //     './scenes/Física/Cinemática/Torricelli/palmtree.glb',
  //     '',
  //     scene,
  //     function (meshes, particleSystems, skeleton, animationGroups) {
  //       let tree = meshes[0];
  //       animationGroups.forEach((x) => {
  //         x.start(true);
  //       });
  //       tree.position = new BABYLON.Vector3(0, 0, 830);
  //       tree.rotation = new BABYLON.Vector3(0, Math.PI / 2, 0);
  //       tree.scaling = new BABYLON.Vector3(1, 1, -1);

  //       scene.teste = tree;
  //       var utilLayer = new BABYLON.UtilityLayerRenderer(scene);
  //       var gizmo = new BABYLON.PositionGizmo(utilLayer);
  //       gizmo.attachedMesh = scene.teste;
  //       gizmo.updateGizmoRotationToMatchAttachedMesh = false;
  //       gizmo.updateGizmoPositionToMatchAttachedMesh = true;
  //     }
  //   );

  //   BABYLON.SceneLoader.ImportMesh(
  //     '',
  //     './scenes/Física/Cinemática/Torricelli/palmtree2.glb',
  //     '',
  //     scene,
  //     function (meshes, particleSystems, skeleton, animationGroups) {
  //       let tree = meshes[0];
  //       animationGroups.forEach((x) => {
  //         x.start(true);
  //       });
  //       tree.position = new BABYLON.Vector3(0, 0, 830);
  //       tree.rotation = new BABYLON.Vector3(0, Math.PI / 2, 0);
  //       tree.scaling = new BABYLON.Vector3(1, 1, -1);
  //     }
  //   );
}

export default Scenario;

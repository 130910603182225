import * as BABYLON from '@babylonjs/core';
import gameManager from '../../../../managers/GameManager';

const DELTA_TIME = 1 / 60;
let rocket_vel = (50 * DELTA_TIME);

const CORRECT_ANSWER = 33;
const TOTAL_TIME = 3 * 60;

const ROCKET_INIT_POSITION = new BABYLON.Vector3(0, 4300, 0);
const CAMERA_INIT_POSITION = new BABYLON.Vector3(-120, 4200, 40);
const EXAUST_ROCKET_INIT_POSITION = new BABYLON.Vector3(0, 4295, 0);
const EXAUST_ROCKET_START_SCALING = 0.01;
const GROUND_POSITION_Y = -5000;

let correctStartEngine = null;

function Loop(props) {
  let scene = props.scene;
  if (scene.isReady() && gameManager.objSpecificGame.physics.gravitation.isLoading === 'true') {
    gameManager.updateInfo('physics', 'gravitation', 'isLoading', 'false');
    gameManager.updateInfo('physics', 'gravitation', 'timeCount', '3:59');
    gameManager.updateInfo('physics', 'gravitation', 'missionMessage', 'Realizando o pouso');
    gameManager.updateInfo('physics', 'gravitation', 'missionIndexScoring', '4');
    gameManager.updateInfo('physics', 'gravitation', 'missionXpAward', '30');
  }
  // playSounds(scene);
  scene.running = Number(gameManager.objSpecificGame.physics.gravitation.running);
  scene.restart = Number(gameManager.objSpecificGame.physics.gravitation.restart);
  scene.countStart = 0;

  if (scene.running !== undefined && scene.countStart !== undefined) {
    simulationFlow(scene);
    restartFlow(scene);
  }
}

export default Loop;

/**
 * Função que reinicia a corrida
 */
function restartFlow(scene) {
  if (scene.restart === 3) {
    gameManager.updateInfo('physics', 'gravitation', 'running', '0');
    gameManager.updateInfo('physics', 'gravitation', 'restart', '0');

    gameManager.updateInfo('physics', 'gravitation', 'showMessage', 'false');
    gameManager.updateInfo('physics', 'gravitation', 'showSlider', 'false');
    gameManager.updateInfo('physics', 'gravitation', 'showButtons', 'false');
    gameManager.updateInfo('physics', 'gravitation', 'showFinalPanel', 'false');

    scene.responses = [0, 0];
    scene.countStart = 0;
    scene.timeLeft = TOTAL_TIME;
    correctStartEngine = null;
    rocket_vel = (50 * DELTA_TIME);
    scene.rocket.rotation.x = 0;
    scene.rocket.explode = false;

  }
  if (scene.restart <= 2 && scene.restart > 0) {
    gameManager.updateInfo('physics', 'gravitation', 'running', '1');
    gameManager.updateInfo('physics', 'gravitation', 'restart', '' + Number(scene.restart + 1));
    gameManager.updateInfo('physics', 'gravitation', 'responseValue', '0');

    scene.timeLeft = TOTAL_TIME;
    scene.rocket.position.x = ROCKET_INIT_POSITION.x;
    scene.rocket.position.y = ROCKET_INIT_POSITION.y;
    scene.rocket.position.z = ROCKET_INIT_POSITION.z;

    scene.exaustRocket.position.x = EXAUST_ROCKET_INIT_POSITION.x;
    scene.exaustRocket.position.y = EXAUST_ROCKET_INIT_POSITION.y;
    scene.exaustRocket.position.z = EXAUST_ROCKET_INIT_POSITION.z;
    scene.exaustRocket.scaling.y = EXAUST_ROCKET_START_SCALING;

    scene.rocket.explode = false;

    scene.camera.position.x = CAMERA_INIT_POSITION.x;
    scene.camera.position.y = CAMERA_INIT_POSITION.y;
    scene.camera.position.z = CAMERA_INIT_POSITION.z;
    scene.camera.setTarget(
      new BABYLON.Vector3(
        scene.rocket.position.x,
        scene.rocket.position.y,
        scene.rocket.position.z
      )
    );
    // (scene.cars || []).forEach((car) => {
    //   car.explode = false;
    //   car.dist = car.initDist;
    //   car.position = car.initPosition;
    //   console.log("Init Position: " + car.initPosition);
    //   car.rotation = new BABYLON.Vector3(0, -Math.PI / 2, 0);
    //   car.vel = car.initVel;
    //   car.acc = car.initAcc;
    //   car.track = car.initTrack;
    //   car.desiredTrack = car.track;
    //   car.active = true;
    //   if (car.isStudent) scene.cameraOrientation = car.rotation.y;
    // });
  }
}

/**
 * Função que determina o fluxo das escolhas do aluno na corrida
 */
function simulationFlow(scene) {
  if (scene.running === 1 && scene.countStart >= 0) {
    updateQuests(scene);
    updateFinalPanel(scene);
    if (scene.running === 1 || scene.restart !== 0) {
      scene.timeLeft = scene.timeLeft > 0 ? scene.timeLeft - DELTA_TIME : 0;
      rocketMovement(scene);
      createExplosion(scene);
      // cameraMovement(scene);

      updateTimePanel(scene);

    }
  }
}

/**
 * Atualiza a pergunta
 */
function updateQuests(scene) {
  if (scene.responses[0] === 0) {
    gameManager.updateInfo('physics', 'gravitation', 'showMessage', 'true');
    gameManager.updateInfo('physics', 'gravitation', 'showSlider', 'true');
    gameManager.updateInfo(
      'physics',
      'gravitation',
      'message',
      'Estamos chegando ao solo e para aterrisarmos com segurança precisamos acionar os motores de pouso com uma força igual ao peso de nossa nave na superfície. Você consegue fazer esse acionamento?'
    );
    //Pensar na alteração aqui
    if (
      Number(gameManager.objSpecificGame.physics.gravitation.responseValue) > 0 &&
      Number(gameManager.objSpecificGame.physics.gravitation.responseValue) < 40
    ) {
      scene.responses[0] = Number(gameManager.objSpecificGame.physics.gravitation.responseValue);
      // gameManager.updateInfo('physics', 'gravitation', 'showMessage', 'false');
      gameManager.updateInfo('physics', 'gravitation', 'showSlider', 'false');
      // gameManager.updateInfo('physics', 'gravitation', 'responseValue', '0');
      gameManager.updateInfo(
        'physics',
        'gravitation',
        'message',
        'Esperamos que você esteja certo, capitão! Iremos acionar o motor com a força de ' +
        Number(gameManager.objSpecificGame.physics.gravitation.responseValue) +
        ' M Newtons, conforme indicado.'
      );
    }

    // else {
    //   scene.running = 0;
    // }
  }
  if (gameManager.objSpecificGame.physics.gravitation.timeCount === '00:00') {
    let message = null;
    if (scene.responses[0] > CORRECT_ANSWER) {
      message = 'Acionando os motores... Você acionou os motores com muita força, estamos sem estabilidade no pouso!';
      correctStartEngine = false;
    } else if (scene.responses[0] < CORRECT_ANSWER && scene.responses[0] !== 0) {
      message = 'Acionando os motores... Você acionou os motores com pouca força, estamos sem estabilidade no pouso!'
      correctStartEngine = false;
    } else if (scene.responses[0] === CORRECT_ANSWER) {
      message = 'Acionando os motores... Estamos estabilizando pouso!'
      correctStartEngine = true;
    } else {
      message = 'Você não acionou os motores, estamos sem estabilidade no pouso!'
      correctStartEngine = false;
    }
    gameManager.updateInfo('physics', 'gravitation', 'showSlider', 'false');
    gameManager.updateInfo(
      'physics',
      'gravitation',
      'message',
      message
    );
  }
}


/**
 * Função responsável para a movimentação do foguete do desafio
 */
function rocketMovement(scene) {

  if (correctStartEngine === false) {
    scene.rocket.rotation.x += 0.002;
    scene.rocket.position.z -= 0.03;
    scene.rocket.explode = true;
  } else {
    if (scene.rocket.position.y < GROUND_POSITION_Y + 10) {
      rocket_vel = 0;
    } else if (scene.rocket.position.y < GROUND_POSITION_Y + 40) {
      rocket_vel = (6 * DELTA_TIME);
    } else if (scene.rocket.position.y < GROUND_POSITION_Y + 80) {
      rocket_vel = (8 * DELTA_TIME);
    }
  }


  scene.rocket.position.y -= 1 * rocket_vel;



  scene.camera.position.y = scene.camera.position.y >= GROUND_POSITION_Y + 400 ? scene.camera.position.y - 0.99 * rocket_vel : scene.camera.position.y - 0.93 * rocket_vel;
  scene.camera.setTarget(
    new BABYLON.Vector3(
      scene.rocket.position.x,
      scene.rocket.position.y,
      scene.rocket.position.z
    )
  );

  // Acionamento dos motores
  if (correctStartEngine === null) {
    scene.exaustRocket.position.y = scene.rocket.position.y - 5;
  } else if (scene.exaustRocket.scaling.y <= 1.6 && correctStartEngine !== null) {
    scene.exaustRocket.scaling.y += 0.008;
    scene.exaustRocket.position.y = scene.rocket.position.y - 5 + 5 * (scene.exaustRocket.scaling.y / 1.6);
  } else {
    scene.exaustRocket.position.y = scene.rocket.position.y;
  }
  scene.exaustRocket.position.x = scene.rocket.position.x;
  scene.exaustRocket.position.z = scene.rocket.position.z;

  scene.exaustRocket.rotation.x = scene.rocket.rotation.x;
  scene.exaustRocket.rotation.y = scene.rocket.rotation.y;
  scene.exaustRocket.rotation.z = scene.rocket.rotation.z;
}


/**
 * Função para atualizar os dados do painel final
 */
function updateFinalPanel(scene) {

  let finish = null;
  let win;


  if ((scene.rocket.position.y <= GROUND_POSITION_Y + 20 && scene.responses[0] !== CORRECT_ANSWER)) {
    finish =
      'Que pena, você não conseguiu estabilizar os motores para pousarmos e nossa nave colapsou';
    win = 'false';
  } else if (scene.rocket.position.y <= GROUND_POSITION_Y + 15) {
    finish = 'Parabéns, você conseguiu estabilizar os motores e pousar com segurança no planeta Quest!';
    win = 'true';
  }

  if (finish) {
    gameManager.updateInfo('physics', 'gravitation', 'showFinalPanel', 'true');
    gameManager.updateInfo('physics', 'gravitation', 'finalMessage', finish);
    gameManager.updateInfo('physics', 'gravitation', 'win', win);
    gameManager.updateInfo('physics', 'gravitation', 'showMessage', 'false');
    scene.running = 0;
  }
}

/**
 * Função para atualizar o painel de tempo
 */
function updateTimePanel(scene) {
  let time = Math.floor(scene.timeLeft);
  let seconds = time % 60 > 9 ? (time % 60).toFixed(0) : '0' + (time % 60).toFixed(0);
  let minutes = Math.floor(time / 60) > 9 ? Math.floor(time / 60) : '0' + Math.floor(time / 60);
  gameManager.updateInfo('physics', 'gravitation', 'timeCount', minutes + ':' + seconds);
}


function playSounds(scene) {
  if (Number(gameManager.objSpecificGame.physics.gravitation.running) === 1 && scene.running === 0) {
    if (!scene.soundRaceMusic.isPlaying) {
      scene.soundRaceMusic.play();
    }
  } else if (
    Number(gameManager.objSpecificGame.physics.gravitation.running) === 0 &&
    scene.running === 1
  ) {
    scene.soundRaceMusic.stop();
  }
}

/**
 * Função para criar uma explosão durante o pouso
 */
function createExplosion(scene) {
  let rocket = scene.rocket;
  if (rocket.explode && rocket.position.y <= GROUND_POSITION_Y + 50) {
    BABYLON.ParticleHelper.CreateAsync('explosion', scene).then((set) => {
      let pos = new BABYLON.Vector3(rocket.position.x, rocket.position.y, rocket.position.z);
      set.systems.forEach((s) => {
        s.emitter = pos.clone();
        s.disposeOnStop = true;
      });
      set.systems[2].worldOffset.y = pos.y;
      set.start();
    });
  }
}

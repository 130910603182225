/* eslint-disable react/display-name */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import { Image } from 'semantic-ui-react';
import PlayDialogue from '../../../Common/compPlayDialogue';
import species_octa from '../../../../images/Physics/Gravitation/species_octa.PNG';
import species_boter from '../../../../images/Physics/Gravitation/species_boter.PNG';
import species_levitoner from '../../../../images/Physics/Gravitation/species_levitoner.PNG';
import species_emierres from '../../../../images/Physics/Gravitation/species_emierres.PNG';
import fig1 from '../../../../images/Physics/Gravitation/exChallengKeplerA.PNG';

function ChallengeExplanation(props) {
  const [animationStep, setAnimationStep] = useState(0);
  const additionalInfo = JSON.parse(sessionStorage.getItem('additional_info'));

  function getImage() {
    switch (additionalInfo.specie) {
      case 'Octawabeter':
        return species_octa;
      case 'Olhoboter':
        return species_boter;
      case 'Levitoner':
        return species_levitoner;
      case 'Emierres':
        return species_emierres;
      default:
        break;
    }
  }

  useEffect(() => {
    if (animationStep === 11) {
      props.setNextItem('Tempo');
    }
  }, [animationStep]);

  const arrPage = [
    <PlayDialogue
      key={0}
      buttons="Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text={
        'Como lhe explicamos no início da missão, nosso planeta está à beira da catástrofe, trazendo a extinção para todos os seus habitantes. Encontramos o planeta Quest, uma nova moradia para nossa espécie e precisamos que você nos guie até lá.'
      }
      image={getImage()}
      extraContent={''}
    />,

    <PlayDialogue
      key={1}
      buttons="Anterior/Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text="Nosso planeta se encontra à 100.000.000 km de nosso sol, demorando 200 dias para completar uma ano, ou seja, uma volta completa. Estamos na data ideal para o lançamento de nossa espaçonave rumo ao planeta Quest, já que nos encontramos no ponto de menor distância entre as órbitas dos dois astros."
      image={getImage()}
      extraContent={''}
    />,

    <PlayDialogue
      key={2}
      buttons="Anterior/Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text="Para que o planeta Quest chegue no ponto de encontro de nossa nave, ainda precisa percorrer 15% de sua órbita, o que corresponde a 40 dias. O seu objetivo será descobrir qual velocidade nossa nave deve atingir para alcançar o planeta de destino no ponto de encontro na data correta."
      image={getImage()}
      extraContent={''}
    />,

    <PlayDialogue
      key={3}
      buttons="Anterior/Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text=""
      image={getImage()}
      extraContent={
        <>
          <p>Para melhor exemplificar a situação observe o mapa:</p>
          <Image src={fig1} centered fluid />
        </>
      }
    />,

    <PlayDialogue
      key={4}
      buttons="Anterior/Começar Desafio"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      setChallengeStarted={props.setChallengeStarted}
      setIsChallengeOpened={props.setIsChallengeOpened}
      text="Está preparado para essa viagem? Vamos enfrentar esse desafio!"
      image={getImage()}
      extraContent={''}
      challenge = {props.challenge}
    />,
  ];

  return arrPage[animationStep];
}

export default ChallengeExplanation;

import React, { useState, useEffect } from 'react';
import Pentagon from '../../components/Pentagon';
import XpChart from '../../components/XpChart';
import api from '../../services/api';
import './habilities.css';

function Habilities() {
  const [clientInfo, setClientInfo] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      await api
        .post('getClientsInfo', {
          client_id: localStorage.getItem('client_id'),
        })
        .then((res) => {
          setClientInfo(res.data);
        })
        .catch((err) => {
          console.log(err.message);
        });
    };
    fetchData();
  }, []);

  return (
    <div className="profileSubItem">
      {clientInfo && clientInfo.id && (
        <>
          <Pentagon
            patience={clientInfo.patience}
            persistence={clientInfo.persistence}
            impulse={clientInfo.impulse}
            fastness={clientInfo.fastness}
            devotion={clientInfo.devotion}
          />
          <XpChart
            physicsXp={70}
            maxPhysicsXp={100}
            physicsLevel={3}
            chemistryXp={50}
            maxChemistryXp={100}
            chemistryLevel={2}
            biologyXp={20}
            maxBiologyXp={100}
            biologyLevel={1}
          />
        </>
      )}
    </div>
  );
}

export default Habilities;

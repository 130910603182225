let PointsPitStop = [[411.99706968672035,216.00658974075486],
[404.97542845341377,222.6449750927277],
[395.19255297235213,224.66163402283564],
[387.86138345114887,231.04629169777036],
[384.97911741025746,240.57594807073474],
[384.00658785729684,250.53009580066282],
[383.9069064427167,260.5390092842281],
[384,270.5297666587867],
[384,280.5275274403393],
[384,290.5277214348316],
[384,300.53219462983543],
[384,310.5363311767578],
[384,320.53790697455406],
[384,330.51955410838127],
[384,340.5368223376572],
[384.9247627586592,350.457774421724],
[387.85052106692456,359.99432656512363],
[393.81672191619873,367.91735434532166],
[402.7510996218771,372.24078294215724],
[411.6911002546549,376.4358424730599]];

export default PointsPitStop;
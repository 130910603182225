import React from 'react';
import './kinematicsComp.scss';

import gameManager from '../../../../managers/GameManager';

function NumberLap(props) {
  return (
    props.condition && (
      <div className="kinematics__numberLap">
        <p className="kinematics__numberLap__paragraph">
          {gameManager.objSpecificGame.physics.kinematics.lapsText}
        </p>
      </div>
    )
  );
}

export default NumberLap;

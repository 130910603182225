import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import gameManager from '../../../../managers/GameManager';
import './gravitationComp.scss';

function PhysicsInfo(props) {

  return (
    <>
      <div>
        {props.info !== '' && (
          <div>
            < p className="gravitation__physicsInfo__forcePlanetRocket">
              Força do planeta:
              <br></br>
              800N
            </p>
            < p className="gravitation__physicsInfo__forceMoonRocket">
              Força da lua:
              <br></br>
              Valor desconhecido
            </p>
          </div>
        )}

        {props.info === 'Força de 8000N e direção de 0º' && (
          <div>
            < p className="gravitation__physicsInfo__forceByRocketOptA">
              Força do foguete:
              <br></br>
              8000N
            </p>
            < p className="gravitation__physicsInfo__finalForce">
              Força Resultante
            </p>
          </div>
        )}

        {props.info === 'Força de 800N e direção de -45º' && (
          <div>
            < p className="gravitation__physicsInfo__forceByRocketOptB">
              Força do foguete:
              <br></br>
              800N
            </p>
            < p className="gravitation__physicsInfo__finalForce">
              Força Resultante
            </p>
          </div>
        )}

        {props.info === 'Força de 8000N e direção de -45º' && (
          <div>
            < p className="gravitation__physicsInfo__forceByRocketOptC">
              Força do foguete:
              <br></br>
              8000N
            </p>
            < p className="gravitation__physicsInfo__finalForce">
              Força Resultante
            </p>
          </div>
        )}

        {props.info === 'Força de 16000N e direção de -70º' && (
          <div>
            < p className="gravitation__physicsInfo__forceByRocketOptD">
              Força do foguete:
              <br></br>
              16000N
            </p>
            < p className="gravitation__physicsInfo__finalForce">
              Força Resultante
            </p>
          </div>
        )}

      </div>
      {/* {props.condition && (
        <div className="gravitation__buttonsSquare">
          <div className="gravitation__firstOption">
            <Button
              circular
              icon='arrow right'
              onClick={() => {
                props.function('Força de 8000N e direção de 0º');
              }}
            >
            </Button>
            <p>
              Força de 8000N e direção de 0º
            </p>
          </div>

          <div className="gravitation__secondOption">
            <Button
              circular
              icon='arrow right'
              onClick={() => {
                props.function('Força de 800N e direção de -45º');
              }}
            >
            </Button>
            <p>
              Força de 800N e direção de -45º
            </p>
          </div>

          <div className="gravitation__thirdOption">
            <Button
              circular
              icon='arrow right'
              onClick={() => {
                props.function('Força de 8000N e direção de -45º');
              }}
            >
            </Button>
            <p>
              Força de 8000N e direção de -45º
            </p>
          </div>

          <div className="gravitation__fourthOption">
            <Button
              circular
              icon='arrow right'
              onClick={() => {
                props.function('Força de 16000N e direção de -70º');
              }}
            >
            </Button>
            <p>
              Força de 16000N e direção de -70º
            </p>
          </div>
        </div>
      )} */}
    </>
  );
}

export default PhysicsInfo;

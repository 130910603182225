import React from 'react';
import { Segment, Card } from 'semantic-ui-react';

import './habilities.css';
import './myProfile.scss';
import './profile.scss';

import scoreManager from '../../managers/ScoreManager';
import CompSubject from './compSubject';

import cinematica from '../../images/Physics/Common/cinematica.png';
import gravitacao from '../../images/Physics/Common/gravitacao.png';
import dinamica from '../../images/Physics/Common/dinamica.png';

function Physics() {
  var sm = scoreManager.objSpecificScore.physics;

  return (
    <>
      <div className="profileSubItem">
        <br></br>
        <h1 className="presentationItem">Física</h1>
        <Segment
          style={{
            overflow: 'auto',
            maxHeight: '60vh',
            color: 'white',
            backgroundColor: '#303841',
          }}
        >
          <Card.Group itemsPerRow={4}>
            <CompSubject name="Cinemática" image={cinematica} subject={sm.kinematics} />
            <CompSubject name="Gravitação" image={gravitacao} />
            <CompSubject name="Dinâmica" image={dinamica} />
          </Card.Group>
        </Segment>
      </div>
    </>
  );
}

export default Physics;

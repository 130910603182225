let PointsTraj = [
  [-4, 0.005661137402057648],
  [-4, 1.993382317814394],
  [-4, 4.001410749633578],
  [-4, 5.991312011246919],
  [-4, 8.00356781631308],
  [-4, 10.006748313971912],
  [-4, 12.004004655755125],
  [-4, 14.005763139475675],
  [-4, 15.992274101243993],
  [-4, 18.008988716959493],
  [-4, 19.99867576705583],
  [-4, 22.003239931865437],
  [-4, 23.99973160888962],
  [-4, 26.009877160148562],
  [-4, 28.001753349003167],
  [-4, 30.00187773336549],
  [-4, 32.003962447997765],
  [-4, 34.00196457198945],
  [-4, 36.00342426975658],
  [-4, 38.00379526626784],
  [-4, 39.99868840444833],
  [-4, 41.9982100081053],
  [-4, 43.999213765243184],
  [-4, 45.99864468204373],
  [-4, 47.9935375102616],
  [-4, 49.996567960015454],
  [-4, 52.005797738502224],
  [-4, 54.00321289097931],
  [-4, 56.00253429601253],
  [-4, 58.00197005972123],
  [-4, 59.99976988568051],
  [-4, 61.99422446208658],
  [-4, 64.00109039121517],
  [-4, 66.00180451057369],
  [-4, 67.99476071848105],
  [-4, 69.99654381393952],
  [-4, 72.00632364337253],
  [-4, 74.00466363877058],
  [-4, 75.9900786598439],
  [-4, 77.99924877480407],
  [-4, 79.99349993336182],
  [-4, 81.99092530005814],
  [-4, 83.99078615665829],
  [-4, 85.99235536961146],
  [-4, 87.99491726089968],
  [-4, 89.99776748015293],
  [-4, 92.0002128780304],
  [-4, 94.00157138086797],
  [-4, 96.0011718665919],
  [-4, 97.99835404189864],
  [-4, 99.99246832070085],
  [-4, 102.00437231259897],
  [-4, 103.99062841109568],
  [-4, 105.99379237600814],
  [-4, 107.99175158417597],
  [-4, 110.00612517345829],
  [-4, 111.99204294940614],
  [-4, 113.99354117028437],
  [-4, 115.99918551690982],
  [-4, 117.99719780390873],
  [-4, 119.99855403475635],
  [-4, 121.9913177046692],
  [-4, 123.9983557054245],
  [-4, 125.99602982623637],
  [-4, 128.00754395721015],
  [-4, 130.00893921966963],
  [-4, 131.9996855486731],
  [-4, 134.00348171310839],
  [-4, 135.99590349042955],
  [-4, 138.00096889135034],
  [-4, 139.9939500445093],
  [-4, 141.9991780462442],
  [-4, 143.99162728339434],
  [-4, 145.9959358657943],
  [-4, 147.99942318941538],
  [-4, 150.00182707032795],
  [-4, 152.00288829477148],
  [-4, 154.00235059414723],
  [-4, 155.99996062016965],
  [-4, 158.0084608051027],
  [-4, 160.001686777115],
  [-4, 162.00544760836732],
  [-4, 164.00650642492207],
  [-4, 165.99135606972482],
  [-4, 167.99955612196666],
  [-4, 169.9910730164902],
  [-4, 172.00586909542534],
  [-4, 174.00351496598392],
  [-4, 175.99718027037213],
  [-4, 178.00029676139275],
  [-4, 179.9991084734508],
  [-4, 182.00717732124644],
  [-4, 183.9967741464477],
  [-4, 185.99531277318306],
  [-4, 188.0027616946643],
  [-4, 189.99102419353807],
  [-4, 192.00198322188507],
  [-4, 193.99332994573325],
  [-4, 196.00731277474665],
  [-4, 198.0012637982145],
  [-4, 200.00346077267636],
  [-4, 201.9994855762504],
  [-4, 204.00358207342566],
  [-4, 206.00121744288913],
  [-4, 208.0067531227847],
  [-4, 209.9909254631579],
  [-4, 211.99739098586792],
  [-4, 213.9968315185721],
  [-4, 216.00383827316267],
  [-4, 218.0035444149716],
  [-4, 219.99575917596871],
  [-4, 221.99524252636724],
  [-4, 224.00196907801728],
  [-4, 226.00085733850833],
  [-4, 228.00683126153007],
  [-4, 230.0047041399743],
  [-4, 232.00950780985255],
  [-4, 233.99068613585428],
  [-4, 236.0091744883266],
  [-4, 238.00378307366503],
  [-4, 240.0050205771538],
  [-4, 241.99739371653155],
  [-4, 243.9962487055247],
  [-4, 246.00156289321637],
  [-4, 247.99769396513318],
  [-4, 250.00014005195715],
  [-4, 251.99316141964044],
  [-4, 254.00812224706823],
  [-4, 255.99770253371275],
  [-4, 257.9933167668709],
  [-4, 259.994943839456],
  [-4, 262.0025626443815],
  [-4, 264.00014775632513],
  [-4, 266.0035884892568],
  [-4, 267.9967669296673],
  [-4, 269.99566727793535],
  [-4, 272.0002694162042],
  [-4, 273.99431858862135],
  [-4, 275.9939385277757],
  [-4, 277.9991095986181],
  [-4, 280.00981216609944],
  [-4, 282.00961243672754],
  [-4, 283.99835814144126],
  [-4, 285.99240037756203],
  [-4, 288.0082656961188],
  [-4, 289.9962996730987],
  [-4, 292.00611934705154],
  [-4, 294.00448619815256],
  [-4, 295.99125382414496],
  [-4, 297.99979517087917],
  [-4, 299.9966166434608],
  [-4, 301.9984172598913],
  [-4, 304.0051792391151],
  [-4, 305.99995916370693],
  [-4, 307.9995825971773],
  [-4, 310.004032202976],
  [-4, 311.99624287525256],
  [-4, 313.99316432346416],
  [-4, 315.9947796480958],
  [-4, 318.0010719496324],
  [-4, 319.9948170042717],
  [-4, 321.9931263473961],
  [-4, 323.99598350911833],
  [-4, 326.00337201955125],
  [-4, 327.9979121678696],
  [-4, 329.9968736401988],
  [-4, 332.00024038893577],
  [-4, 333.990518747097],
  [-4, 336.00260994734236],
  [-4, 338.001505352159],
  [-4, 340.0046669483272],
  [-4, 341.9944517905367],
  [-4, 344.006061791096],
  [-4, 346.00419020919674],
  [-4, 348.0064491327357],
  [-4, 349.9950498092221],
  [-4, 352.0054878522867],
  [-4, 354.00216535303855],
  [-4, 356.0028410149729],
  [-4, 358.0075000139084],
  [-4, 359.99817583659024],
  [-4, 361.9927351915186],
  [-4, 363.9911636480535],
  [-4, 365.9934467755548],
  [-4, 367.99957014338247],
  [-4, 369.9913946352787],
  [-4, 372.0051209195081],
  [-4, 374.00445148888383],
  [-4, 376.00749698489795],
  [-4, 377.995983587244],
  [-4, 380.00638335070107],
  [-4, 382.0021297830607],
  [-4, 384.0014689959277],
  [-4, 386.00438732472634],
  [-4, 387.99244683278744],
  [-4, 390.0024498766288],
  [-4, 391.9975022717779],
  [-4, 393.99601499840173],
  [-4, 395.9979747645741],
  [-4, 398.0033682783687],
  [-4, 399.99356647015003],
  [-4, 402.00575611656456],
  [-4, 404.0026614194092],
  [-4, 406.002885230279],
  [-4, 408.00641462305975],
  [-4, 409.99446612264893],
  [-4, 412.0045373090673],
  [-4, 413.9990440922093],
  [-4, 415.9967444539867],
  [-4, 417.99762582732325],
  [-4, 420.0016756451426],
  [-4, 422.00888134036836],
  [-4, 424.0002501411709],
  [-4, 425.9946910095658],
  [-4, 427.99219173080377],
  [-4, 429.9927400901356],
  [-4, 431.9963238728121],
  [-4, 434.00293086408414],
  [-4, 435.9933954670083],
  [-4, 438.0059837290342],
  [-4, 440.0023486712898],
  [-4, 442.0016318068995],
  [-4, 444.00382126679386],
  [-4, 446.00890518190323],
  [-4, 447.99755062511707],
  [-4, 450.00836029691317],
  [-4, 452.00265315708634],
  [-4, 453.99973873370357],
  [-4, 455.9996054967907],
  [-4, 458.0022419163737],
  [-4, 460.00763646247833],
  [-4, 461.9962679224083],
  [-4, 464.0071176330172],
  [-4, 466.00112867979516],
  [-4, 467.99779954806525],
  [-4, 469.9971190404278],
  [-4, 471.9990759594833],
  [-4, 474.0036591078325],
  [-4, 475.9911662174909],
  [-4, 478.0009427587906],
  [-4, 479.9935703874118],
  [-4, 482.0084959701016],
  [-4, 484.0062002009442],
  [-4, 486.0064138682094],
  [-4, 488.0091261006146],
  [-4, 489.9944604042264],
  [-4, 492.0021126833541],
  [-4, 493.9923168076565],
  [-4, 496.004866077857],
  [-4, 497.9998973934941],
  [-4, 499.9973144167628],
  [-4, 501.99710659610315],
  [-4, 503.99926337995583],
  [-4, 506.003774216761],
  [-4, 507.99054844589136],
  [-4, 509.9997124566546],
  [-4, 511.99107264431404],
  [-4, 514.0048480438043],
  [-4, 516.0007528226138],
  [-4, 517.998903069468],
  [-4, 519.9992885461994],
  [-4, 522.00189901464],
  [-4, 524.0067242366222],
  [-4, 525.9934699588272],
  [-4, 528.0026718601584],
  [-4, 529.9937299875519],
  [-4, 532.0072680281801],
  [-4, 534.0025984302052],
  [-4, 536.0000391789757],
  [-4, 537.9995803434481],
  [-4, 540.0012119925788],
  [-4, 542.0049241953243],
  [-4, 544.0004681963077],
  [-4, 545.9980519651488],
  [-4, 548.0079253665019],
  [-4, 549.9992996871559],
  [-4, 551.9926638693242],
  [-4, 554.00858912285],
  [-4, 556.0059244030016],
  [-4, 558.005220555017],
  [-4, 560.0064679487729],
  [-4, 562.0096569541454],
  [-4, 563.9940967672135],
  [-4, 566.0011203365216],
  [-4, 568.0100567263553],
  [-4, 570.0001563251003],
  [-4, 571.9921107401369],
  [-4, 574.006689064118],
  [-4, 576.0023441830924],
  [-4, 577.9998260150813],
  [-4, 579.99912522474],
  [-4, 582.0002324767239],
  [-4, 584.0031384356882],
  [-4, 586.0078337662884],
  [-4, 587.9933992055685],
  [-4, 590.0016268767199],
  [-4, 591.9906695770934],
  [-4, 594.0023926353772],
  [-4, 595.9948760283567],
  [-4, 598.0100575203539],
  [-4, 600.0059450374521],
  [-4, 602.0035120877106],
  [-4, 604.0027496244865],
  [-4, 606.0036486011359],
  [-4, 608.0061999710156],
  [-4, 610.0103946874821],
  [-4, 611.9951012344529],
  [-4, 614.002538443346],
  [-4, 616.0010137729838],
  [-4, 618.0010795310227],
  [-4, 619.9921319403431],
  [-4, 622.0059467140948],
  [-4, 624.0001189099612],
  [-4, 625.9958295531451],
  [-4, 627.9930698796902],
  [-4, 629.9918311256398],
  [-4, 631.9921045270374],
  [-4, 633.9938813199261],
  [-4, 635.9971527403496],
  [-4, 638.0019100243513],
  [-4, 640.0081444079743],
  [-4, 641.9944808910714],
  [-4, 644.00362770083],
  [-4, 645.9928284693524],
  [-4, 648.0048530213626],
  [-4, 649.996883760404],
  [-4, 652.0010302360968],
  [-4, 654.0065777654357],
  [-4, 656.0027817364197],
  [-4, 658.0003547965126],
  [-4, 659.9992884584935],
  [-4, 661.9995742351417],
  [-4, 664.0012036392365],
  [-4, 666.0041681835568],
  [-4, 668.0084593808817],
  [-4, 669.992496071136],
  [-4, 671.9994010753823],
  [-4, 674.0076073622312],
  [-4, 675.9954920816039],
  [-4, 678.0062617073698],
  [-4, 679.9966655910276],
  [-4, 682.009964970888],
  [-4, 684.0028547951351],
  [-4, 685.9969685017052],
  [-4, 687.9922978742252],
  [-4, 690.0105427868169],
  [-4, 692.0082918321223],
  [-4, 694.0072318049507],
  [-4, 696.0073544889291],
  [-4, 698.0086516676848],
  [-4, 700.0002290340265],
  [-4, 701.9929519531445],
  [-4, 703.9977108638413],
  [-4, 706.0036114931324],
  [-4, 708.0106456246449],
  [-4, 709.9969712023286],
  [-4, 712.0062355912745],
  [-4, 713.9947511065295],
  [-4, 716.0062132436801],
  [-4, 717.9968865406388],
  [-4, 720.0105139167658],
  [-4, 722.0033128395607],
  [-4, 723.9971567968402],
  [-4, 725.9920378372549],
  [-4, 728.0098867753716],
  [-4, 730.0068293056027],
  [-4, 732.0047849775437],
  [-4, 734.0037458398451],
  [-4, 736.0037039411575],
  [-4, 738.0046513301317],
  [-4, 740.0065800554182],
  [-4, 742.0094821656678],
  [-4, 743.9913239694304],
  [-4, 745.9961385169834],
  [-4, 748.001902682829],
  [-4, 750.0086085156177],
  [-4, 751.9941810456601],
  [-4, 754.0027362282623],
  [-4, 756.0011657568367],
  [-4, 758.0004953055766],
  [-4, 760.00071705348],
  [-4, 762.0018231795449],
  [-4, 763.9927427911952],
  [-4, 766.0066572821515],
  [-4, 767.9992970320018],
  [-4, 769.9927805183247],
  [-4, 772.0092638222628],
  [-4, 774.0044208659759],
  [-4, 776.0003984844332],
  [-4, 777.9971889855476],
  [-4, 779.9947846772325],
  [-4, 781.9931778674004],
  [-4, 783.9923608639646],
  [-4, 785.9923259748381],
  [-4, 787.9930655079338],
  [-4, 789.994571771165],
  [-4, 791.9968370724448],
  [-4, 793.999853719686],
  [-4, 796.0036140208016],
  [-4, 798.0081102837047],
  [-4, 799.9910505707521],
  [-4, 801.9969877168105],
  [-4, 804.003637833863],
  [-4, 805.9998393213028],
  [-4, 807.996730708389],
  [-4, 810.0054659626644],
  [-4, 811.992552923115],
  [-4, 814.002637608279],
  [-4, 815.9910439612694],
  [-4, 818.0024476498951],
  [-4, 819.9921433060808],
  [-4, 822.0048355707413],
  [-4, 823.995790440778],
  [-4, 826.0097408540461],
  [-4, 828.0019248485892],
  [-4, 829.9947087725038],
  [-4, 832.0104860127433],
  [-4, 834.0044540111962],
  [-4, 835.9989995398745],
  [-4, 837.9941151602554],
  [-4, 840.0010066848292],
  [-4, 842.008459637691],
  [-4, 844.0052470145747],
  [-4, 846.0025746454926],
  [-4, 848.0004350919223],
  [-4, 849.9988209153414],
  [-4, 851.9977246772271],
  [-4, 853.9971389390572],
  [-4, 855.9970562623093],
  [-4, 857.9974692084611],
  [-4, 859.9983703389898],
  [-4, 861.9997522153732],
  [-4, 864.0016073990889],
  [-4, 866.0039284516142],
  [-4, 868.0067079344269],
  [-4, 870.0099384090046],
  [-4, 872.0023546160388],
  [-4, 873.9952020927135],
  [-4, 875.9997361361845],
  [-4, 878.0046915009098],
  [-4, 880.0100607483665],
  [-4, 881.9932974041615],
  [-4, 883.9994676596241],
  [-4, 886.00602956583],
  [-4, 888.0016996466911],
  [-4, 889.997742321051],
  [-4, 892.0054304398382],
  [-4, 894.0021983612527],
  [-4, 895.9993168932735],
  [-4, 898.0080647784747],
  [-4, 899.9945765337062],
  [-4, 902.0039927843754],
  [-4, 904.0024424150397],
  [-4, 906.0012060457035],
  [-4, 908.0002763625099],
  [-4, 909.999646051602],
  [-4, 912.0106061389142],
  [-4, 913.9992542912145],
  [-4, 916.0107796883892],
  [-4, 917.9999722536843],
  [-4, 920.0007290963479],
  [-4, 922.0017414281548],
  [-4, 923.9916946952682],
  [-4, 926.0045033037691],
  [-4, 927.9949283406943],
  [-4, 930.008199369671],
  [-4, 931.9990670862643],
  [-4, 934.0014575869607],
  [-4, 936.0040524211217],
  [-4, 937.9955285269261],
  [-4, 940.0098258344098],
  [-4, 942.0016719766995],
  [-4, 943.9936912651867],
  [-4, 946.0085159039036],
  [-4, 948.0008616758364],
  [-4, 949.9933589404784],
  [-4, 952.0086449629166],
  [-4, 954.0014251579648],
  [-4, 955.994335192233],
  [-4, 958.0100166508817],
  [-4, 960.0031660625166],
  [-4, 961.9964236598832],
  [-4, 964.0011084282963],
  [-4, 966.0058880289242],
  [-4, 967.9994279828609],
  [-4, 969.9930472784486],
  [-4, 972.0093946966197],
  [-4, 974.0031518005985],
  [-4, 975.9969665927392],
  [-4, 978.0021607930441],
  [-4, 980.0073987525279],
  [-4, 982.001343834694],
  [-4, 983.995317760941],
  [-4, 986.0006429091463],
  [-4, 988.0059826437446],
  [-4, 990],
  [-4, 991.9940173562554],
  [-4, 994.0106866593235],
  [-4, 996.004682239059],
  [-4, 997.998656165306],
  [-4, 999.9926012474721],
  [-4, 1002.0091681175502],
  [-4, 1004.0030334072608],
  [-4, 1005.9968481994015],
  [-4, 1008.001933290191],
  [-4, 1010.0069527215514],
  [-4, 1012.0005720171391],
  [-4, 1013.9941119710759],
  [-4, 1016.0102177476108],
  [-4, 1018.0035763401168],
  [-4, 1019.9968339374834],
  [-4, 1022.0013077388576],
  [-4, 1024.0056648077668],
  [-4, 1025.9985748420352],
  [-4, 1028.0026772671358],
  [-4, 1030.0066410595216],
  [-4, 1031.9991383241636],
  [-4, 1034.0028037959707],
  [-4, 1036.0063087348133],
  [-4, 1037.9983280233005],
  [-4, 1040.0014909647946],
  [-4, 1042.0044714730739],
  [-4, 1043.9959475788783],
  [-4, 1046.00985593456],
  [-4, 1048.0009329137356],
  [-4, 1049.991800630329],
  [-4, 1052.0050716593057],
  [-4, 1053.995496696231],
  [-4, 1056.008305304732],
  [-4, 1057.9982585718453],
  [-4, 1060.0105753391574],
  [-4, 1062.0000277463157],
  [-4, 1064.0005217859791],
  [-4, 1066.0007457087854],
  [-4, 1068.0006922008774],
  [-4, 1070.000353948398],
  [-4, 1072.0110186678642],
  [-4, 1073.9987939542966],
  [-4, 1076.0088491497208],
  [-4, 1077.9960072156246],
  [-4, 1080.005423466294],
  [-4, 1081.9919352215252],
  [-4, 1084.0006831067265],
  [-4, 1086.009083805161],
  [-4, 1087.9945695601618],
  [-4, 1090.002257678949],
  [-4, 1092.0095763789814],
  [-4, 1093.99397043417],
  [-4, 1096.000532340376],
  [-4, 1098.0067025958385],
  [-4, 1100.0012065132491],
  [-4, 1101.9953084990902],
  [-4, 1104.0002638638155],
  [-4, 1106.0047979072865],
  [-4, 1108.008903190981],
  [-4, 1110.0013169406864],
  [-4, 1111.993292065573],
  [-4, 1113.9960715483858],
  [-4, 1115.998392600911],
  [-4, 1118.000247784627],
  [-4, 1120.0016296610102],
  [-4, 1122.002530791539],
  [-4, 1124.0029437376907],
  [-4, 1126.002861060943],
  [-4, 1128.002275322773],
  [-4, 1130.0011790846586],
  [-4, 1131.9995649080777],
  [-4, 1133.9974253545074],
  [-4, 1135.9947529854253],
  [-4, 1138.0027567287802],
  [-4, 1140.0102065661831],
  [-4, 1142.0058848397446],
  [-4, 1144.0010004601256],
  [-4, 1145.9955459888038],
  [-4, 1148.0119148127324],
  [-4, 1150.0052912274962],
  [-4, 1151.9980751514108],
  [-4, 1154.0014494821576],
  [-4, 1156.004209559222],
  [-4, 1157.9951644292587],
  [-4, 1160.0078566939192],
  [-4, 1161.997552350105],
  [-4, 1164.0089560387307],
  [-4, 1165.997362391721],
  [-4, 1168.007447076885],
  [-4, 1169.9945340373356],
  [-4, 1172.003269291611],
  [-4, 1174.0113145656856],
  [-4, 1175.996362166137],
  [-4, 1178.0030122831895],
  [-4, 1180.0089494292479],
  [-4, 1182.0030278254994],
  [-4, 1183.9963859791985],
  [-4, 1186.0001462803139],
  [-4, 1188.0031629275552],
  [-4, 1190.005428228835],
  [-4, 1192.0069344920662],
  [-4, 1194.007674025162],
  [-4, 1196.0076391360356],
  [-4, 1198.0068221325996],
  [-4, 1200.0052153227675],
  [-4, 1202.0028110144524],
  [-4, 1203.9996015155668],
  [-4, 1205.995579134024],
  [-4, 1208.0018180771344],
  [-4, 1210.0072194816753],
  [-4, 1212.0007029679982],
  [-4, 1213.9933427178485],
  [-4, 1216.0072572088047],
  [-4, 1217.9981768204552],
  [-4, 1220.0103363321412],
  [-4, 1221.9995046944234],
  [-4, 1224.0098777699914],
  [-4, 1225.9972637717378],
  [-4, 1228.00581895434],
  [-4, 1230.002419898946],
  [-4, 1231.998097317171],
  [-4, 1234.0038614830166],
  [-4, 1236.0086760305696],
  [-4, 1238.0015254359803],
  [-4, 1239.9934199445818],
  [-4, 1241.9953486698682],
  [-4, 1243.9962960588425],
  [-4, 1245.9962541601549],
  [-4, 1247.9952150224563],
  [-4, 1249.9931706943973],
  [-4, 1252.0120519905447],
  [-4, 1254.0079621627451],
  [-4, 1256.0028432031597],
  [-4, 1257.9966871604393],
  [-4, 1260.011379137842],
  [-4, 1262.0031134593612],
  [-4, 1263.9937867563199],
  [-4, 1266.0052488934705],
  [-4, 1267.9937644087254],
  [-4, 1270.0030287976713],
  [-4, 1272.0111760278332],
  [-4, 1273.9963885068676],
  [-4, 1276.0022891361587],
  [-4, 1278.0070480468555],
  [-4, 1280.0106570225853],
  [-4, 1282.0022281068698],
  [-4, 1283.9926455110708],
  [-4, 1285.9927681950494],
  [-4, 1288.0025687257157],
  [-4, 1290.0111653036777],
  [-4, 1292.0077021257748],
  [-4, 1294.0030314982948],
  [-4, 1295.9971452048649],
  [-4, 1298.0116901209376],
  [-4, 1300.0033344089723],
  [-4, 1301.99373829263],
  [-4, 1304.0045079183963],
  [-4, 1306.0031929099396],
  [-4, 1308.0005989246176],
  [-4, 1310.007503928864],
  [-4, 1312.002319910254],
  [-4, 1313.9958318164433],
  [-4, 1315.9987963607634],
  [-4, 1318.0004257648584],
  [-4, 1320.0007115415065],
  [-4, 1321.9996452034875],
  [-4, 1323.9972182635804],
  [-4, 1325.9934222345642],
  [-4, 1328.0096908585226],
  [-4, 1330.003116239596],
  [-4, 1331.9951469786374],
  [-4, 1334.0071715306476],
  [-4, 1335.9963722991702],
  [-4, 1338.0055191089286],
  [-4, 1340.0025309436583],
  [-4, 1341.9980899756488],
  [-4, 1344.0028472596503],
  [-4, 1346.006118680074],
  [-4, 1348.0078954729624],
  [-4, 1350.00816887436],
  [-4, 1352.0069301203098],
  [-4, 1354.004170446855],
  [-4, 1355.9998810900388],
  [-4, 1357.9940532859052],
  [-4, 1360.0078680596569],
  [-4, 1361.9989204689773],
  [-4, 1364.009564363632],
  [-4, 1365.9974615566541],
  [-4, 1368.004898765547],
  [-4, 1370.0107106259125],
  [-4, 1371.9938000289844],
  [-4, 1373.9963513988641],
  [-4, 1375.9972503755137],
  [-4, 1377.9964879122892],
  [-4, 1379.994054962548],
  [-4, 1382.0109428569885],
  [-4, 1384.0051239716433],
  [-4, 1385.997607364623],
  [-4, 1388.0093304229067],
  [-4, 1389.99837312328],
  [-4, 1392.0066007944315],
  [-4, 1394.0026119746963],
  [-4, 1395.9968615643115],
  [-4, 1397.9997675232762],
  [-4, 1400.00087477526],
  [-4, 1402.0001739849185],
  [-4, 1403.9976558169074],
  [-4, 1405.9933109358822],
  [-4, 1408.007889259863],
  [-4, 1409.9998436748997],
  [-4, 1412.010663683744],
  [-4, 1413.9988796634784],
  [-4, 1416.0059032327865],
  [-4, 1418.0110043514455],
  [-4, 1419.9935320512272],
  [-4, 1421.9947794449831],
  [-4, 1423.9940755969983],
  [-4, 1426.0119917171169],
  [-4, 1428.0073361306759],
  [-4, 1430.000700312844],
  [-4, 1432.0125938693025],
  [-4, 1434.0019480348512],
  [-4, 1436.0097705743829],
  [-4, 1437.9950758046757],
  [-4, 1439.9987880074214],
  [-4, 1442.000419656552],
  [-4, 1443.9999608210242],
  [-4, 1445.9974015697949],
  [-4, 1448.013081574885],
  [-4, 1450.006270012448],
  [-4, 1451.9973281398416],
  [-4, 1454.006530041173],
  [-4, 1456.0034066907467],
  [-4, 1457.99810098536],
  [-4, 1460.0007114538007],
  [-4, 1462.001096930532],
  [-4, 1463.9992471773862],
  [-4, 1465.9951519561957],
  [-4, 1468.008927355686],
  [-4, 1470.0002875433452],
  [-4, 1472.0094515541086],
  [-4, 1473.996225783239],
  [-4, 1476.0007366200443],
  [-4, 1478.002893403897],
  [-4, 1480.0026855832373],
  [-4, 1482.0001026065058],
  [-4, 1483.995133922143],
  [-4, 1486.0076831923434],
  [-4, 1487.997887316646],
  [-4, 1490.0055395957736],
  [-4, 1492.0107149446894],
  [-4, 1494.0134024921115],
  [-4, 1495.9937997990558],
  [-4, 1498.0112706973432],
  [-4, 1500.0064296125884],
  [-4, 1501.9990572412094],
  [-4, 1504.008833782509],
  [-4, 1505.9963408921676],
  [-4, 1508.0009240405166],
  [-4, 1510.0028809595724],
  [-4, 1512.0022004519346],
  [-4, 1513.9988713202047],
  [-4, 1516.0124182915922],
  [-4, 1518.0037320775916],
  [-4, 1520.0118466096856],
  [-4, 1521.9977580836262],
  [-4, 1524.0003945032095],
  [-4, 1526.0002612662965],
  [-4, 1527.9973468429137],
  [-4, 1530.0109880433354],
  [-4, 1532.0024493748829],
  [-4, 1534.0103882154774],
  [-4, 1535.996178733206],
  [-4, 1537.9983681931003],
  [-4, 1539.9976513287102],
  [-4, 1541.9940162709659],
  [-4, 1544.0066045329918],
  [-4, 1545.9970691359158],
  [-4, 1548.0036761271879],
  [-4, 1550.0072599098644],
  [-4, 1552.0078082691962],
  [-4, 1554.005308990434],
  [-4, 1555.999749858829],
  [-4, 1558.0100692692333],
  [-4, 1559.9983243548572],
  [-4, 1562.0023741726768],
  [-4, 1564.0032555460134],
  [-4, 1566.0009559077907],
  [-4, 1567.9954626909325],
  [-4, 1570.0055338773511],
  [-4, 1572.0123252132823],
  [-4, 1573.997114769721],
  [-4, 1575.9973385805908],
  [-4, 1578.01289085701],
  [-4, 1580.0064335298498],
  [-4, 1581.9966317216313],
  [-4, 1584.002025235426],
  [-4, 1586.0039850015983],
  [-4, 1588.0024977282221],
  [-4, 1589.9975501233712],
  [-4, 1592.0075531672126],
  [-4, 1594.0140042989105],
  [-4, 1595.9985310040724],
  [-4, 1597.9978702169392],
  [-4, 1600.011909575388],
  [-4, 1602.004016412756],
  [-4, 1604.0107292101527],
  [-4, 1606.0137409973631],
  [-4, 1608.0130377302612],
  [-4, 1610.0086053647215],
  [-4, 1612.0004298566175],
  [-4, 1614.0065532244453],
  [-4, 1616.0088363519465],
  [-4, 1618.0072648084815],
  [-4, 1620.0018241634098],
  [-4, 1622.010416307603],
  [-4, 1623.997158985027],
  [-4, 1625.9978346469616],
  [-4, 1628.012321572387],
  [-4, 1630.004950190778],
  [-4, 1632.011288043299],
  [-4, 1634.0135104804626],
  [-4, 1636.0116022774555],
  [-4, 1638.0055482094633],
  [-4, 1640.0129231467427],
  [-4, 1641.998494647841],
  [-4, 1643.9973900526577],
  [-4, 1646.009481252903],
  [-4, 1647.9997596110643],
  [-4, 1650.0031263598012],
  [-4, 1652.0020878321304],
  [-4, 1654.0139523703965],
  [-4, 1656.0040164908817],
  [-4, 1658.006873652604],
  [-4, 1660.0051829957283],
  [-4, 1661.9989280503676],
  [-4, 1664.0052203519042],
  [-4, 1666.006835676536],
  [-4, 1668.0037571247476],
  [-4, 1670.0129748863162],
  [-4, 1672.0004174028227],
  [-4, 1674.000040836293],
  [-4, 1676.0117049286332],
  [-4, 1678.0015827401087],
  [-4, 1680.0033833565392],
  [-4, 1682.0002048291208],
  [-4, 1684.0087461758549],
  [-4, 1686.0121880297932],
  [-4, 1688.0105121578404],
  [-4, 1690.0037003269013],
  [-4, 1692.0082795476415],
  [-4, 1694.007599622438],
  [-4, 1696.0016418585587],
  [-4, 1698.0068013586788],
  [-4, 1700.006557259629],
  [-4, 1702.000890401382],
  [-4, 1704.0060614722242],
  [-4, 1706.0056814113786],
  [-4, 1707.9997305837958],
  [-4, 1710.0043327220646],
  [-4, 1712.0032330703327],
  [-4, 1713.9964115107432],
  [-4, 1715.999852243675],
  [-4, 1717.9974373556183],
  [-4, 1720.005056160544],
  [-4, 1722.006683233129],
  [-4, 1724.0022974662872],
  [-4, 1726.007643881896],
  [-4, 1728.0068385803595],
  [-4, 1729.9998599480427],
  [-4, 1732.0023060348667],
  [-4, 1734.0140069617344],
  [-4, 1736.0037512944753],
  [-4, 1738.0026062834686],
  [-4, 1740.0103987984494],
  [-4, 1741.996216926335],
  [-4, 1744.0061429025486],
  [-4, 1746.0093138641457],
  [-4, 1748.00570609026],
  [-4, 1750.0104573453714],
  [-4, 1752.0082776251984],
  [-4, 1754.0141983651836],
  [-4, 1755.9980309219827],
  [-4, 1758.0047574736327],
  [-4, 1760.0042408240313],
  [-4, 1761.9964555850283],
  [-4, 1764.010946677562],
  [-4, 1766.003168481428],
  [-4, 1768.002609014132],
  [-4, 1770.009074536842],
  [-4, 1772.0078080950966],
  [-4, 1773.9987825571109],
  [-4, 1775.9964179265744],
  [-4, 1778.014903174384],
  [-4, 1779.9965392273239],
  [-4, 1781.9987362017855],
  [-4, 1784.0069000027058],
  [-4, 1786.0066700542668],
  [-4, 1788.0121096520543],
  [-4, 1790.0089758064619],
  [-4, 1791.997238305336],
  [-4, 1794.0046872268172],
  [-4, 1796.0032258535525],
  [-4, 1798.0066079861465],
  [-4, 1800.0008915265491],
  [-4, 1801.9997032386073],
  [-4, 1804.0028197296278],
  [-4, 1805.9964850340161],
  [-4, 1808.0075957341637],
  [-4, 1810.0089269835098],
  [-4, 1812.013776529248],
  [-4, 1814.0086439302754],
  [-4, 1816.0066922109759],
  [-4, 1818.0076829887528],
  [-4, 1820.0113750874093],
  [-4, 1822.0045320798247],
  [-4, 1824.012962131601],
  [-4, 1825.9976494058528],
  [-4, 1827.9971117052285],
  [-4, 1830.0108823863288],
  [-4, 1832.0132137355354],
  [-4, 1834.0040641342057],
  [-4, 1836.0083727166057],
  [-4, 1838.0008219537558],
  [-4, 1840.0060499554907],
  [-4, 1841.99903110865],
  [-4, 1844.0040965095704],
  [-4, 1846.008628322023],
  [-4, 1848.0003144513266],
  [-4, 1850.0149659178876],
  [-4, 1852.0162011692373],
  [-4, 1854.0039701737637],
  [-4, 1856.0016442945757],
  [-4, 1858.0086822953308],
  [-4, 1860.0014459652434],
  [-4, 1862.0028021960914],
  [-4, 1864.0121888060949],
  [-4, 1866.0064588297157],
  [-4, 1868.0079570505936],
  [-4, 1870.0160997686908],
  [-4, 1872.0082484158243],
  [-4, 1874.006207623992],
  [-4, 1876.0093715889043],
  [-4, 1878.01712429616],
  [-4, 1880.007531679299],
  [-4, 1882.001645958101],
  [-4, 1883.998828133408],
  [-4, 1885.998428619132],
  [-4, 1887.9997871219693],
  [-4, 1890.002232519847],
  [-4, 1892.0050827391],
  [-4, 1894.0076446303888],
  [-4, 1896.009213843342],
  [-4, 1898.009074699942],
  [-4, 1900.0065000666382],
  [-4, 1902.000751225196],
  [-4, 1904.0099213401559],
  [-4, 1906.013953261549],
  [-4, 1908.0120634606922],
  [-4, 1910.0034561860607],
  [-4, 1912.0052392815187],
  [-4, 1914.0158678569255],
  [-4, 1916.0163351409483],
  [-4, 1918.0057755379135],
  [-4, 1920.0002301143195],
  [-4, 1922.0146863910395],
  [-4, 1924.0138553674265],
  [-4, 1926.0129043087363],
  [-4, 1928.0100412587924],
  [-4, 1930.0034320399845],
  [-4, 1932.0064624897382],
  [-4, 1934.0013553179565],
  [-4, 1936.015442379004],
  [-4, 1938.0161313531516],
  [-4, 1940.0013115955517],
  [-4, 1942.0098909115104],
  [-4, 1944.0099189676462],
  [-4, 1946.0110245317744],
  [-4, 1948.0086611748955],
  [-4, 1950.0103665404472],
  [-4, 1952.010097503662],
  [-4, 1954.0130067468908],
  [-4, 1956.01128211891],
  [-4, 1958.0178917862213],
  [-4, 1960.0013242329442],
  [-4, 1962.0102113688063],
  [-4, 1964.0077258987562],
  [-4, 1966.0112496663928],
  [-4, 1968.011786563135],
  [-4, 1970.0077098671331],
  [-4, 1972.0094022396765],
  [-4, 1974.008687988753],
  [-4, 1976.0170571651543],
  [-4, 1978.0066176821856],
  [-3.9999942798167467, 1980.0131771564484],
  [-3.850843593478203, 1982.0110902786255],
  [-3.3385202437639236, 1983.9288911819458],
  [-2.30340576171875, 1985.635986328125],
  [-0.630466103553772, 1986.6749153137207],
  [1.3138607740402222, 1986.4153633117676],
  [2.75, 1985.0625],
  [3.575533613562584, 1983.2439622879028],
  [3.9424444884061813, 1981.2777528762817],
  [4, 1979.288296661689],
  [4, 1977.2974947094917],
  [4, 1975.2858389875473],
  [4, 1973.2903374213402],
  [4, 1971.280858105165],
  [4, 1969.287343262913],
  [4, 1967.2912379705667],
  [4, 1965.2881341305797],
  [4, 1963.2927372697213],
  [4, 1961.2932718334937],
  [4, 1959.2885787691434],
  [4, 1957.2939821489217],
  [4, 1955.2792179715016],
  [4, 1953.287571779438],
  [4, 1951.2948419937766],
  [4, 1949.2962952936068],
  [4, 1947.2853675484657],
  [4, 1945.2931567827532],
  [4, 1943.2860615006057],
  [4, 1941.2813410796207],
  [4, 1939.2974787443504],
  [4, 1937.2962635922308],
  [4, 1935.2798497385083],
  [4, 1933.2804933015236],
  [4, 1931.2866739858446],
  [4, 1929.2855836211252],
  [4, 1927.2950790339382],
  [4, 1925.2854334209767],
  [4, 1923.2906207110325],
  [4, 1921.2797227548435],
  [4, 1919.2877137288738],
  [4, 1917.2825321417613],
  [4, 1915.282595396417],
  [4, 1913.289530187114],
  [4, 1911.286924268979],
  [4, 1909.2938570468184],
  [4, 1907.2933871695736],
  [4, 1905.2863216718483],
  [4, 1903.2923791132635],
  [4, 1901.2938625608554],
  [4, 1899.2915333109163],
  [4, 1897.2861408223253],
  [4, 1895.2982113441792],
  [4, 1893.2891033162136],
  [4, 1891.2791077920806],
  [4, 1889.2893336818815],
  [4, 1887.279862866943],
  [4, 1885.2923803230792],
  [4, 1883.2861477394713],
  [4, 1881.2824285889287],
  [4, 1879.2818716395805],
  [4, 1877.2851150812403],
  [4, 1875.2927866444224],
  [4, 1873.2835739186066],
  [4, 1871.2796548913325],
  [4, 1869.2816346064187],
  [4, 1867.290108021151],
  [4, 1865.2830223871526],
  [4, 1863.283248767274],
  [4, 1861.2913603029879],
  [4, 1859.2847728903093],
  [4, 1857.2868576333685],
  [4, 1855.2981664372328],
  [4, 1853.2956016875273],
  [4, 1851.279215224064],
  [4, 1849.2969850655031],
  [4, 1847.2896141805168],
  [4, 1845.2933293021993],
  [4, 1843.2964306800382],
  [4, 1841.2869048943721],
  [4, 1839.289533911624],
  [4, 1837.2799321413775],
  [4, 1835.2831788977321],
  [4, 1833.2871723975302],
  [4, 1831.2795122162515],
  [4, 1829.2984474432783],
  [4, 1827.280632304973],
  [4, 1825.2900690404447],
  [4, 1823.288590266884],
  [4, 1821.289252986931],
  [4, 1819.279217913287],
  [4, 1817.284834519995],
  [4, 1815.280103991137],
  [4, 1813.2916438516468],
  [4, 1811.2931787760772],
  [4, 1809.2847443759415],
  [4, 1807.2933530242317],
  [4, 1805.2923252984524],
  [4, 1803.2816954045652],
  [4, 1801.288861143354],
  [4, 1799.2867486042023],
  [4, 1797.289198420911],
  [4, 1795.2825611814478],
  [4, 1793.2808013138128],
  [4, 1791.2981291422311],
  [4, 1789.2927800283533],
  [4, 1787.2928312026372],
  [4, 1785.2984940984697],
  [4, 1783.2815102813904],
  [4, 1781.2846109516836],
  [4, 1779.2794760115107],
  [4, 1777.2949540838],
  [4, 1775.288018924923],
  [4, 1773.2875751977108],
  [4, 1771.293822467851],
  [4, 1769.2923203458204],
  [4, 1767.283095963403],
  [4, 1765.280926306732],
  [4, 1763.286004903693],
  [4, 1761.2836635212632],
  [4, 1759.2888421681425],
  [4, 1757.2867613198832],
  [4, 1755.2924679814078],
  [4, 1753.2910729287994],
  [4, 1751.2977286221485],
  [4, 1749.2974377129315],
  [4, 1747.2902244693978],
  [4, 1745.2913974493113],
  [4, 1743.2858002042817],
  [4, 1741.2888433516218],
  [4, 1739.2852657835942],
  [4, 1737.2905786885517],
  [4, 1735.2894178209647],
  [4, 1733.2973934680524],
  [4, 1731.2834027541207],
  [4, 1729.2943787994193],
  [4, 1727.2834327401472],
  [4, 1725.2977906455294],
  [4, 1723.2902696736112],
  [4, 1721.2925055747933],
  [4, 1719.2887361747935],
  [4, 1717.2949565092079],
  [4, 1715.2792870710982],
  [4, 1713.2898108606344],
  [4, 1711.2945997630504],
  [4, 1709.2936738962032],
  [4, 1707.2870533779496],
  [4, 1705.291009155278],
  [4, 1703.2894011897897],
  [4, 1701.298589727303],
  [4, 1699.2859580165714],
  [4, 1697.284251067586],
  [4, 1695.2936209833474],
  [4, 1693.2811838166253],
  [4, 1691.2965098419954],
  [4, 1689.290066170345],
  [4, 1687.2950355198077],
  [4, 1685.2948754438103],
  [4, 1683.2896041754473],
  [4, 1681.296013976853],
  [4, 1679.280617237091],
  [4, 1677.2938794130064],
  [4, 1675.2853706098654],
  [4, 1673.288864737332],
  [4, 1671.2875215265567],
  [4, 1669.2983798995472],
  [4, 1667.287454649653],
  [4, 1665.2888462757737],
  [4, 1663.285550091967],
  [4, 1661.2947648526806],
  [4, 1659.2821834538322],
  [4, 1657.2822255846663],
  [4, 1655.2950253916556],
  [4, 1653.2860392232162],
  [4, 1651.2899209359998],
  [4, 1649.289387046092],
  [4, 1647.2844536010962],
  [4, 1645.2926278170312],
  [4, 1643.278981313415],
  [4, 1641.2961159672159],
  [4, 1639.2914611666652],
  [4, 1637.2825612924853],
  [4, 1635.287109348707],
  [4, 1633.287517063991],
  [4, 1631.2837996631515],
  [4, 1629.2937587342294],
  [4, 1627.2818729448004],
  [4, 1625.283765827585],
  [4, 1623.2816658435377],
  [4, 1621.2935167848173],
  [4, 1619.283510859941],
  [4, 1617.2875555710266],
  [4, 1615.2877363807365],
  [4, 1613.2840677197105],
  [4, 1611.2946667140914],
  [4, 1609.2833767590018],
  [4, 1607.2864514638059],
  [4, 1605.2858021771267],
  [4, 1603.2814429430903],
  [4, 1601.2916594573821],
  [4, 1599.2982606258124],
  [4, 1597.2829223766519],
  [4, 1595.2823009927579],
  [4, 1593.29650870169],
  [4, 1591.2887851324308],
  [4, 1589.2959826442302],
  [4, 1587.281211047606],
  [4, 1585.2814520234115],
  [4, 1583.2968146676612],
  [4, 1581.29021614457],
  [4, 1579.2802018425714],
  [4, 1577.285443367582],
  [4, 1575.287358049036],
  [4, 1573.2859588130473],
  [4, 1571.2812585857303],
  [4, 1569.292051720413],
  [4, 1567.2808188377207],
  [4, 1565.285165741709],
  [4, 1563.28632357224],
  [4, 1561.28430489639],
  [4, 1559.2980538428455],
  [4, 1557.2897495266704],
  [4, 1555.2972967940086],
  [4, 1553.2827566327023],
  [4, 1551.2841515463515],
  [4, 1549.2825032424826],
  [4, 1547.2969303773789],
  [4, 1545.2892609754722],
  [4, 1543.297748323061],
  [4, 1541.2841062178973],
  [4, 1539.2867017120566],
  [4, 1537.2863832355113],
  [4, 1535.283162657331],
  [4, 1533.296355097857],
  [4, 1531.2873935436737],
  [4, 1529.2949234942635],
  [4, 1527.2802679849501],
  [4, 1525.2821820143527],
  [4, 1523.2813189991207],
  [4, 1521.2971564311201],
  [4, 1519.2908005988295],
  [4, 1517.2817021998849],
  [4, 1515.2894181808529],
  [4, 1513.2944670949523],
  [4, 1511.2968601395833],
  [4, 1509.2966085121452],
  [4, 1507.2937234100373],
  [4, 1505.288216030659],
  [4, 1503.2800975714104],
  [4, 1501.2891047381281],
  [4, 1499.2955736218523],
  [4, 1497.2797395387363],
  [4, 1495.2811396084296],
  [4, 1493.280034116613],
  [4, 1491.2962837524606],
  [4, 1489.290224290453],
  [4, 1487.2816917731461],
  [4, 1485.2906199434478],
  [4, 1483.297144996045],
  [4, 1481.2813067606037],
  [4, 1479.2830334656096],
  [4, 1477.2823888131052],
  [4, 1475.2793833546498],
  [4, 1473.2940927980453],
  [4, 1471.2864207271655],
  [4, 1469.29653090795],
  [4, 1467.284234009877],
  [4, 1465.2897862954298],
  [4, 1463.2930864943244],
  [4, 1461.2941448447282],
  [4, 1459.2929715848095],
  [4, 1457.2895769527358],
  [4, 1455.2839711866745],
  [4, 1453.2964563695714],
  [4, 1451.2864811267086],
  [4, 1449.294661034211],
  [4, 1447.2803568545987],
  [4, 1445.284271617264],
  [4, 1443.2860795799854],
  [4, 1441.285790673806],
  [4, 1439.2834148297698],
  [4, 1437.2789619789198],
  [4, 1435.2929271508542],
  [4, 1433.284371020629],
  [4, 1431.294294242704],
  [4, 1429.2816741529623],
  [4, 1427.287594343814],
  [4, 1425.2915377780619],
  [4, 1423.2935140858294],
  [4, 1421.29353289724],
  [4, 1419.2916038424178],
  [4, 1417.287736551486],
  [4, 1415.2819406545682],
  [4, 1413.2949336303295],
  [4, 1411.2853290466592],
  [4, 1409.2945714661619],
  [4, 1407.2811963201384],
  [4, 1405.286725914747],
  [4, 1403.2904227672639],
  [4, 1401.2922962130347],
  [4, 1399.2923555874036],
  [4, 1397.2906102257152],
  [4, 1395.2870694633139],
  [4, 1393.281742635545],
  [4, 1391.2955555319786],
  [4, 1389.286702941754],
  [4, 1387.2970450866678],
  [4, 1385.284703687406],
  [4, 1383.2916116492875],
  [4, 1381.2968251272398],
  [4, 1379.2793287417444],
  [4, 1377.2811627936665],
  [4, 1375.2813295968176],
  [4, 1373.2798381978407],
  [4, 1371.2977918905835],
  [4, 1369.2930284444728],
  [4, 1367.2866338409372],
  [4, 1365.2786171266196],
  [4, 1363.2901497150335],
  [4, 1361.2789327563648],
  [4, 1359.2873165020865],
  [4, 1357.2941473492854],
  [4, 1355.2888197719356],
  [4, 1353.2819404939196],
  [4, 1351.2841490359065],
  [4, 1349.2848398284304],
  [4, 1347.2840216354482],
  [4, 1345.2817032209164],
  [4, 1343.2885558420696],
  [4, 1341.2939414548505],
  [4, 1339.2871905933919],
  [4, 1337.2789744729764],
  [4, 1335.2906889877816],
  [4, 1333.2795839965959],
  [4, 1331.2884575307514],
  [4, 1329.2959309472487],
  [4, 1327.2805652210916],
  [4, 1325.285249037548],
  [4, 1323.2885582892693],
  [4, 1321.290501463476],
  [4, 1319.291087047389],
  [4, 1317.2903235282292],
  [4, 1315.2882193932178],
  [4, 1313.2847831295749],
  [4, 1311.280023224522],
  [4, 1309.2955258296022],
  [4, 1307.2881581084341],
  [4, 1305.2794921162579],
  [4, 1303.2911555978205],
  [4, 1301.2799322575336],
  [4, 1299.2890825012],
  [4, 1297.2969952876667],
  [4, 1295.2820056270912],
  [4, 1293.2874549214116],
  [4, 1291.2916914969592],
  [4, 1289.294723570107],
  [4, 1287.2965593572276],
  [4, 1285.2972070746941],
  [4, 1283.2966749388795],
  [4, 1281.2949711661563],
  [4, 1279.2921039728974],
  [4, 1277.2880815754759],
  [4, 1275.2829121902644],
  [4, 1273.2875109452195],
  [4, 1271.290991356209],
  [4, 1269.2824424610296],
  [4, 1267.294629478839],
  [4, 1265.2838723543377],
  [4, 1263.2938912232512],
  [4, 1261.280958378656],
  [4, 1259.2888412545408],
  [4, 1257.295673940671],
  [4, 1255.2795442378044],
  [4, 1253.2842890768493],
  [4, 1251.288007667565],
  [4, 1249.2907079613003],
  [4, 1247.2923979094048],
  [4, 1245.2930854632277],
  [4, 1243.2927785741188],
  [4, 1241.2914851934268],
  [4, 1239.2892132725012],
  [4, 1237.2859707626917],
  [4, 1235.2817656153472],
  [4, 1233.2876257374503],
  [4, 1231.2925494566055],
  [4, 1229.285514377846],
  [4, 1227.288583688758],
  [4, 1225.290740102938],
  [4, 1223.280946131603],
  [4, 1221.2923455251087],
  [4, 1219.2807550375608],
  [4, 1217.290393212374],
  [4, 1215.2881024579222],
  [4, 1213.2849457327497],
  [4, 1211.292005106443],
  [4, 1209.2871445792987],
  [4, 1207.28144150123],
  [4, 1205.2859918163413],
  [4, 1203.289724291673],
  [4, 1201.2926466193119],
  [4, 1199.2947664913447],
  [4, 1197.2960915998585],
  [4, 1195.2966296369405],
  [4, 1193.296388294677],
  [4, 1191.2953752651556],
  [4, 1189.293598240463],
  [4, 1187.2910649126864],
  [4, 1185.2877829739125],
  [4, 1183.2837601162287],
  [4, 1181.2790040317211],
  [4, 1179.2958094995265],
  [4, 1177.289617971404],
  [4, 1175.2827162072515],
  [4, 1173.2974224453292],
  [4, 1171.2891309636948],
  [4, 1169.2801522368236],
  [4, 1167.2928271985788],
  [4, 1165.2825044803592],
  [4, 1163.2938654286754],
  [4, 1161.2822291465338],
  [4, 1159.2923061707552],
  [4, 1157.279386752119],
  [4, 1155.2882099415901],
  [4, 1153.296420915591],
  [4, 1151.2816372579518],
  [4, 1149.2886394471705],
  [4, 1147.295051820065],
  [4, 1145.2784721303924],
  [4, 1143.2837207684734],
  [4, 1141.288401989376],
  [4, 1139.2925232316236],
  [4, 1137.2960919337381],
  [4, 1135.2878950399663],
  [4, 1133.279154467239],
  [4, 1131.2811042647688],
  [4, 1129.2825313598341],
  [4, 1127.2834431909575],
  [4, 1125.2838471966616],
  [4, 1123.2837508154685],
  [4, 1121.2831614859008],
  [4, 1119.2820866464808],
  [4, 1117.2805337357313],
  [4, 1115.2785101921743],
  [4, 1113.2872746598114],
  [4, 1111.2955884368316],
  [4, 1109.29220262194],
  [4, 1107.288375844752],
  [4, 1105.28411554379],
  [4, 1103.2794291575763],
  [4, 1101.2968556927963],
  [4, 1099.2913440306438],
  [4, 1097.2854285152282],
  [4, 1095.279116585072],
  [4, 1093.2949650132068],
  [4, 1091.2878868147568],
  [4, 1089.2804344335548],
  [4, 1087.2951770833959],
  [4, 1085.2870026480791],
  [4, 1083.2784762619988],
  [4, 1081.2921788331273],
  [4, 1079.2829746071116],
  [4, 1077.2960214995558],
  [4, 1075.286168854318],
  [4, 1073.287295225849],
  [4, 1071.2881175144746],
  [4, 1069.288643034052],
  [4, 1067.288879098438],
  [4, 1065.28883302149],
  [4, 1063.288512117065],
  [4, 1061.28792369902],
  [4, 1059.287075081212],
  [4, 1057.297279932385],
  [4, 1055.2846265017356],
  [4, 1053.2943502150847],
  [4, 1051.2812248894927],
  [4, 1049.2904965551613],
  [4, 1047.2882415313586],
  [4, 1045.2857774634713],
  [4, 1043.2944267143737],
  [4, 1041.2802514508712],
  [4, 1039.288521297276],
  [4, 1037.2966133399404],
  [4, 1035.2818965602685],
  [4, 1033.289652751475],
  [4, 1031.2972527924298],
  [4, 1029.2820604440997],
  [4, 1027.289368186636],
  [4, 1025.296541432409],
  [4, 1023.2809394629325],
  [4, 1021.2878639633268],
  [4, 1019.2946756204465],
  [4, 1017.2787299773347],
  [4, 1015.2853364421151],
  [4, 1013.2918517171095],
  [4, 1011.2869556716314],
  [4, 1009.2819819835688],
  [4, 1007.2882660829661],
  [4, 1005.2944878366585],
  [4, 1003.2779969482558],
  [4, 1001.2841150785841],
  [4, 999.2901925166964],
  [4, 997.2962364531845],
  [4, 995.2795950645313],
  [4, 993.2855933935914],
  [4, 991.2915798745162],
  [4, 989.297561697898],
  [4, 987.2808868279117],
  [4, 985.2868810598011],
  [4, 983.2928922876363],
  [4, 981.28759844061],
  [4, 979.2823363696073],
  [4, 977.2884422084237],
  [4, 975.2945938872673],
  [4, 973.2781421645022],
  [4, 971.2844078208284],
  [4, 969.2907409706702],
  [4, 967.2971488046196],
  [4, 965.280985485418],
  [4, 963.2875653127861],
  [4, 961.2942414777508],
  [4, 959.2783715627604],
  [4, 957.2852632741831],
  [4, 955.2922729766908],
  [4, 953.2880851350124],
  [4, 951.2840312154287],
  [4, 949.2914396620077],
  [4, 947.2876746683769],
  [4, 945.284065497091],
  [4, 943.2919378942695],
  [4, 941.288661325706],
  [4, 939.2855624797375],
  [4, 937.2939640340437],
  [4, 935.2799272110133],
  [4, 933.288718523936],
  [4, 931.2864025170068],
  [4, 929.2843007606364],
  [4, 927.2937299902546],
  [4, 925.2807692550014],
  [4, 923.2906608864191],
  [4, 921.2781825178878],
  [4, 919.2885656412484],
  [4, 917.287901500305],
  [4, 915.2875027655989],
  [4, 913.2873767509874],
  [4, 911.2875307703275],
  [4, 909.2879721374763],
  [4, 907.2887081662909],
  [4, 905.2784534717795],
  [4, 903.2910934643454],
  [4, 901.2814682401222],
  [4, 899.294745175348],
  [4, 897.285778842126],
  [4, 895.2884383653935],
  [4, 893.2914437886477],
  [4, 891.283522983375],
  [4, 889.2872442058303],
  [4, 887.2913333111649],
  [4, 885.2845244800092],
  [4, 883.2781025355991],
  [4, 881.2946123348163],
  [4, 879.288981923945],
  [4, 877.283760631808],
  [4, 875.2789558969275],
  [4, 873.2970939070995],
  [4, 871.293139713403],
  [4, 869.2896243089514],
  [4, 867.2865551322673],
  [4, 865.2839396218728],
  [4, 863.2817852162909],
  [4, 861.2800993540435],
  [4, 859.2788894736532],
  [4, 857.2781630136424],
  [4, 855.2779274125337],
  [4, 853.2781901088496],
  [4, 851.2789585411122],
  [4, 849.2802401478441],
  [4, 847.2820423675678],
  [4, 845.2843726388057],
  [4, 843.2872384000802],
  [4, 841.2906470899138],
  [4, 839.294606146829],
  [4, 837.2879140196437],
  [4, 835.2817935477427],
  [4, 833.2874548139325],
  [4, 831.2936962848725],
  [4, 829.2781335873847],
  [4, 827.2855645119635],
  [4, 825.2935980404387],
  [4, 823.279870314038],
  [4, 821.2891383461206],
  [4, 819.2878527845754],
  [4, 817.2872068329016],
  [4, 815.2872080556954],
  [4, 813.2878640175534],
  [4, 811.2780183378768],
  [4, 809.2911704168475],
  [4, 807.2826796075994],
  [4, 805.2860340197278],
  [4, 803.2900810361627],
  [4, 801.283683538642],
  [4, 799.2780017470795],
  [4, 797.2953166668062],
  [4, 795.291081197939],
  [4, 793.2875844258236],
  [4, 791.284834042547],
  [4, 789.2828377401961],
  [4, 787.2816032108578],
  [4, 785.2811381466191],
  [4, 783.2814502395671],
  [4, 781.2825471817887],
  [4, 779.2844366653708],
  [4, 777.2871263824004],
  [4, 775.2906240249645],
  [4, 773.29493728515],
  [4, 771.2779133595507],
  [4, 769.283890207802],
  [4, 767.2907058354031],
  [4, 765.287301769283],
  [4, 763.2847614269655],
  [4, 761.2941491645205],
  [4, 759.2823031977466],
  [4, 757.2934476849866],
  [4, 755.2833937157627],
  [4, 753.2963260638555],
  [4, 751.2880955490289],
  [4, 749.280793681903],
  [4, 747.2964712655612],
  [4, 745.2910401250017],
  [4, 743.2865613988135],
  [4, 741.283043038346],
  [4, 739.2804929949488],
  [4, 737.2789192199707],
  [4, 735.2783296647614],
  [4, 733.2787322806703],
  [4, 731.2801350190464],
  [4, 729.2825458312393],
  [4, 727.2859726685983],
  [4, 725.2904234824728],
  [4, 723.295906224212],
  [4, 721.2805283063574],
  [4, 719.2881103166644],
  [4, 717.2858095775108],
  [4, 715.2845844256199],
  [4, 713.2953697197288],
  [4, 711.2853932161743],
  [4, 709.2874433248935],
  [4, 707.2906013534237],
  [4, 705.283972859211],
  [4, 703.2784808143967],
  [4, 701.2959136372331],
  [4, 699.2927065648382],
  [4, 697.2906605016522],
  [4, 695.2897836640477],
  [4, 693.2900842683978],
  [4, 691.2915705310752],
  [4, 689.2942506684528],
  [4, 687.2872877300649],
  [4, 685.2815483355283],
  [4, 683.2878726852075],
  [4, 681.2954238643866],
  [4, 679.2825732768715],
  [4, 677.292616323029],
  [4, 675.2823014804143],
  [4, 673.294869617246],
  [4, 671.2871241043547],
  [4, 669.2806828856603],
  [4, 667.2863311837027],
  [4, 665.2932865362446],
  [4, 663.2907947798772],
  [4, 661.2896411753297],
  [4, 659.2898342098232],
  [4, 657.2913823705785],
  [4, 655.2942941448165],
  [4, 653.2771261937045],
  [4, 651.2828055477224],
  [4, 649.289874068146],
  [4, 647.2769335259593],
  [4, 645.2868210067716],
  [4, 643.2767470783176],
  [4, 641.2894878344249],
  [4, 639.2823152035875],
  [4, 637.2766140783566],
  [4, 635.2937069005593],
  [4, 633.2909591915086],
  [4, 631.2897091867007],
  [4, 629.2899656500924],
  [4, 627.2917373456402],
  [4, 625.2950330373005],
  [4, 623.2786445310817],
  [4, 621.2850309531418],
  [4, 619.2929677564177],
  [4, 617.2812965429778],
  [4, 615.2923771275528],
  [4, 613.283901043226],
  [4, 611.2770444985297],
  [4, 609.292915690304],
  [4, 607.2893080794448],
  [4, 605.2873470529186],
  [4, 603.2870416573686],
  [4, 601.2884009394384],
  [4, 599.2914339457714],
  [4, 597.2856526947501],
  [4, 595.2815811648658],
  [4, 593.2897075757977],
  [4, 591.2786040294616],
  [4, 589.2901776425731],
  [4, 587.2825760614649],
  [4, 585.2767485848142],
  [4, 583.2935707827821],
  [4, 581.2913007982244],
  [4, 579.290832826915],
  [4, 577.2921762041987],
  [4, 575.29534026542],
  [4, 573.2795626978202],
  [4, 571.286415342023],
  [4, 569.2951167734407],
  [4, 567.2849628008923],
  [4, 565.2767154935328],
  [4, 563.2910584977914],
  [4, 561.2866335071511],
  [4, 559.2841439727908],
  [4, 557.2835995248342],
  [4, 555.2850097934048],
  [4, 553.2883844086261],
  [4, 551.2937330006218],
  [4, 549.2805325962884],
  [4, 547.2898786311904],
  [4, 545.2807365404445],
  [4, 543.2941189368892],
  [4, 541.2890744795902],
  [4, 539.2861041916639],
  [4, 537.2852180041536],
  [4, 535.286425848103],
  [4, 533.2897376545554],
  [4, 531.2951633545545],
  [4, 529.2823927325509],
  [4, 527.292097836976],
  [4, 525.2836705568167],
  [4, 523.2774412768335],
  [4, 521.2936517979618],
  [4, 519.2918267727948],
  [4, 517.2922303588914],
  [4, 515.2948727944192],
  [4, 513.2796232154305],
  [4, 511.2867969380268],
  [4, 509.2761453092987],
  [4, 507.28789168634233],
  [4, 505.28187979310604],
  [4, 503.27821519982405],
  [4, 501.2769084580562],
  [4, 499.2779701193619],
  [4, 497.281410735301],
  [4, 495.2872408574329],
  [4, 493.29547103731744],
  [4, 491.28623044368425],
  [4, 489.2794597838929],
  [4, 487.2950021882673],
  [4, 485.29317928665796],
  [4, 483.29385882510127],
  [4, 481.2772939087938],
  [4, 479.28303598744776],
  [4, 477.2913132276382],
  [4, 475.2824544468979],
  [4, 473.2762035624539],
  [4, 471.2922024717532],
  [4, 469.29117513058173],
  [4, 467.2927891681266],
  [4, 465.2775029356313],
  [4, 463.2844594936614],
  [4, 461.29409113238484],
  [4, 459.286935304215],
  [4, 457.2825302144281],
  [4, 455.28088739299835],
  [4, 453.28201836989973],
  [4, 451.2859346751065],
  [4, 449.29264783859253],
  [4, 447.28285816002426],
  [4, 445.2759435353073],
  [4, 443.29117154716795],
  [4, 441.2900147078483],
  [4, 439.29176841546285],
  [4, 437.27727295634395],
  [4, 435.28491163692706],
  [4, 433.27638193444557],
  [4, 431.2899535218755],
  [4, 429.2874379708125],
  [4, 427.2879621473392],
  [4, 425.2915382662047],
  [4, 423.27920893335505],
  [4, 421.28895493864474],
  [4, 419.2828791506395],
  [4, 417.2799637452191],
  [4, 415.28022128945975],
  [4, 413.2836643504379],
  [4, 411.2903054952297],
  [4, 409.28139763538525],
  [4, 407.29450311658826],
  [4, 405.29214618864404],
  [4, 403.29309943319345],
  [4, 401.27873986784834],
  [4, 399.28638347449566],
  [4, 397.27880313627264],
  [4, 395.2931892938231],
  [4, 393.2924408592733],
  [4, 391.276666822658],
  [4, 389.2828672582255],
  [4, 387.2925461170564],
  [4, 385.28733647894114],
  [4, 383.2856971813419],
  [4, 381.28764188883434],
  [4, 379.2749031864787],
  [4, 377.2840900926452],
  [4, 375.27868786463756],
  [4, 373.27699162609724],
  [4, 371.2790154211501],
  [4, 369.28477329392194],
  [4, 367.27620112342834],
  [4, 365.2895035499419],
  [4, 363.2885731218965],
  [4, 361.2915024350423],
  [4, 359.2803668470242],
  [4, 357.2910940325159],
  [4, 355.2878559521825],
  [4, 353.2886065032006],
  [4, 351.27556389023994],
  [4, 349.2843721207737],
  [4, 347.27948931165366],
  [4, 345.27872730720543],
  [4, 343.2821013322429],
  [4, 341.2896266115797],
  [4, 339.2837413662797],
  [4, 337.2821123019821],
  [4, 335.28475505128694],
  [4, 333.29168524679403],
  [4, 331.28549228396713],
  [4, 329.2836942712641],
  [4, 327.2863072562882],
  [4, 325.29334728664253],
  [4, 323.2875584917281],
  [4, 321.2863068667775],
  [4, 319.2896088816781],
  [4, 317.28032740665674],
  [4, 315.2757120755179],
  [4, 313.2928533366474],
  [4, 311.28054744294786],
  [4, 309.29003194054684],
  [4, 307.2872981377448],
  [4, 305.2893966779076],
  [4, 303.2794754014958],
  [4, 301.29133195721994],
  [4, 299.2745006502027],
  [4, 297.27948273743266],
  [4, 295.28946817465453],
  [4, 293.2878154507637],
  [4, 291.29128683434715],
  [4, 289.2833273291228],
  [4, 287.2806149199825],
  [4, 285.28316829965775],
  [4, 283.29100616088],
  [4, 281.2877490215457],
  [4, 279.2899022345057],
  [4, 277.2811761581404],
  [4, 275.277988602334],
  [4, 273.2803592021358],
  [4, 271.2883075925947],
  [4, 269.2857263673602],
  [4, 267.28885407072084],
  [4, 265.2816765557054],
  [4, 263.2803414722285],
  [4, 261.2848694288051],
  [4, 259.27938810527945],
  [4, 257.2799060523394],
  [4, 255.28644437707163],
  [4, 253.28327523136977],
  [4, 251.2862654611876],
  [4, 249.27978544660158],
  [4, 247.27960627102897],
  [4, 245.28575006269415],
  [4, 243.28273655672092],
  [4, 241.28619032651733],
  [4, 239.28073263891437],
  [4, 237.28188906878913],
  [4, 235.2743839188679],
  [4, 233.28888945334353],
  [4, 231.2796879153605],
  [4, 229.27740172537978],
  [4, 227.28205515165104],
  [4, 225.27863559737014],
  [4, 223.28231066465378],
  [4, 221.27817577424838],
  [4, 219.28129317935714],
  [4, 217.29168826812992],
  [4, 215.27985527722632],
  [4, 213.2755715291459],
  [4, 211.27886356587265],
  [4, 209.28975792939053],
  [4, 207.2791988499887],
  [4, 205.2765223979556],
  [4, 203.28175630354963],
  [4, 201.28056002994768],
  [4, 199.28744564328116],
  [4, 197.28819032757582],
  [4, 195.28299964884405],
  [4, 193.28621320824936],
  [4, 191.2837889824139],
  [4, 189.2899488380408],
  [4, 187.27682372312302],
  [4, 185.28647634543583],
  [4, 183.27727549630254],
  [4, 181.29091539797628],
  [4, 179.2861398471744],
  [4, 177.29063312779658],
  [4, 175.27728452521842],
  [4, 173.27352838365073],
  [4, 171.2793989090551],
  [4, 169.28155467219509],
  [4, 167.2802280825039],
  [4, 165.27565429230305],
  [4, 163.28124526529342],
  [4, 161.28393096321636],
  [4, 159.28395282208993],
  [4, 157.28155509729845],
  [4, 155.28988269268456],
  [4, 153.28331903329666],
  [4, 151.2878406854448],
  [4, 149.2781196897215],
  [4, 147.279852776803],
  [4, 145.2805437616802],
  [4, 143.28045511516896],
  [4, 141.2798523052241],
  [4, 139.27900382226312],
  [4, 137.29041824895563],
  [4, 135.28981905403313],
  [4, 133.27771511262654],
  [4, 131.27863018622136],
  [4, 129.28068827323625],
  [4, 127.27233286075901],
  [4, 125.28938535510648],
  [4, 123.2732465704423],
  [4, 121.28294546796694],
  [4, 119.28374267278159],
  [4, 117.28762468123932],
  [4, 115.28355666944663],
  [4, 113.27211122175399],
  [4, 111.2762081070116],
  [4, 109.27374511957169],
  [4, 107.28729808280946],
  [4, 105.27332562306697],
  [4, 103.27621887617511],
  [4, 101.27461318949102],
  [4, 99.29037691968006],
  [4, 97.28147324554257],
  [4, 95.29083731565038],
  [4, 93.2772171282376],
  [4, 91.28279444325926],
  [4, 89.28738459616056],
  [4, 87.27161492094979],
  [4, 85.27649760236386],
  [4, 83.28247825367853],
  [4, 81.29027347368307],
  [4, 79.28140324271382],
  [4, 77.27625502874957],
  [4, 75.27558933038563],
  [4, 73.2801786089847],
  [4, 71.27250354277749],
  [4, 69.27213350980651],
  [4, 67.27988716886372],
  [4, 65.27900991436013],
  [4, 63.28842393463674],
  [4, 61.274826203841],
  [4, 59.27429047882882],
  [4, 57.28773012834722],
  [4, 55.283480885555036],
  [4, 53.28013828352596],
  [4, 51.279514164497186],
  [4, 49.283462888932945],
  [4, 47.27872506904518],
  [4, 45.28299840046543],
  [4, 43.28373995094694],
  [4, 41.2839450557054],
  [4, 39.27279859540249],
  [4, 37.2816143248734],
  [4, 35.28621640563756],
  [4, 33.278174978686366],
  [4, 31.275678569758156],
  [4, 29.283511488965814],
  [4, 27.283204385721547],
  [4, 25.282340367783718],
  [4, 23.288848716453003],
  [4, 21.279799032928537],
  [4, 19.278032085858285],
  [4, 17.286296661210372],
  [4, 15.274587151860715],
  [4, 13.283225674598498],
  [4, 11.288025285693948],
  [4, 9.282328330879182],
  [4, 7.280641050771237],
  [4, 5.270354064423373],
  [4, 3.278678095084615],
  [4, 1.2831564274529228],
];

export default PointsTraj;

import React, { useState } from 'react';
import { List, Image, Button, Input, Modal, Header, Form } from 'semantic-ui-react';
import moment from 'moment';

import api from '../../services/api';

import './habilities.css';
import './myProfile.scss';
import './profile.scss';

function MyAccount() {
  const [openModalData, setOpenModalData] = useState(false);
  const [openModalPassword, setOpenModalPassword] = useState(false);
  
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChangePassword = async () => {
    if(!oldPassword || !newPassword || !confirmNewPassword) {
      return;
    }

    if(newPassword !== confirmNewPassword){
      setErrorMessage('Confirmação de senha deve ser igual à nova senha!');
      return;
    }

    
    await api
    .post('changePassword', {
      email: localStorage.getItem('email'),
      password: oldPassword,
      newPassword: newPassword
    })
    .then((res) => {
      setOpenModalPassword(false);
    })
    .catch((err) => {
      setErrorMessage(err.response.data.message);
    });

  };

  const modalStyle = {
    borderRadius: 0,
    opacity: 0.9,
    color: 'white',
    backgroundColor: 'black',
  };

  const listStyle ={
    marginTop: '10%',
    
  }
  
  const imageStyle ={
    marginTop: '10%',
    left: '40%'
  }

  const inputStyle = {
    width: '400%'
  }

  const inputFont = {
    color: 'white'
  }

  const errorFont = {
    color: 'red'
  }

  return (
    <>
      <div className="profileSubItem">
        <div>
          <br></br>
          <h1 className="presentationItem">Minha Conta</h1>
        </div>

        <div className="profile__firstSquare profile__password">
          <div className="presentationItem">
            <List className="" size='big' animated style={listStyle}>
              <List.Item className="profile__config" icon='user' content={localStorage.getItem('name') + ' ' + localStorage.getItem('surname')} />
              <List.Item className="profile__config" icon='calendar' content={moment(localStorage.getItem('birth_date')).format('DD/MM/YYYY')} />
              <List.Item className="profile__config" icon='marker' content={localStorage.getItem('city') !== 'undefined' && localStorage.getItem('city')} />
              <List.Item className="profile__config" icon='university' content={localStorage.getItem('school') ? localStorage.getItem('school') : 'Sem Escola'} />
              <List.Item className="profile__config" icon='mail' content={localStorage.getItem('email')} />
            </List>
            
            <Modal
              open={openModalData}
              onClose={() => setOpenModalData(false)}
              onOpen={() => setOpenModalData(true)}
              trigger={
                <div>
                  <Button
                    style={{ marginTop: '5%', backgroundColor: '#ff5722' }}
                    fluid
                    color="blue"
                  >
                    Alterar Dados
                  </Button>
                </div>
              }
            >
              <Header icon="user" content="Alterar Dados" style={modalStyle} />
              <Modal.Content image scrolling style={modalStyle}>
                {
                  <div className="">
                    <Form>
                      <Form.Field>
                        <label style={inputFont}>Nome</label>
                        <input placeholder='Nome' fluid defaultValue={localStorage.getItem('name') + ' ' + localStorage.getItem('surname')} style={inputStyle}/>
                      </Form.Field>
                      <Form.Field>
                        <label style={inputFont}>Data de nascimento</label>
                        <input placeholder='Data de nascimento' fluid defaultValue={moment(localStorage.getItem('birth_date')).format('DD/MM/YYYY')} style={inputStyle}/>
                      </Form.Field>
                      <Form.Field>
                        <label style={inputFont}>Cidade</label>
                        <input placeholder='Cidade' fluid defaultValue={localStorage.getItem('city') !== 'undefined' && localStorage.getItem('city')} style={inputStyle}/>
                      </Form.Field>
                      <Form.Field>
                        <label style={inputFont}>Escola</label>
                        <input placeholder='Escola' fluid defaultValue={localStorage.getItem('school') ? localStorage.getItem('school') : 'Sem Escola'} style={inputStyle}/>
                      </Form.Field>
                      <Form.Field>
                        <label style={inputFont}>Email</label>
                        <input placeholder='Email' fluid defaultValue={localStorage.getItem('email')} style={inputStyle}/>
                      </Form.Field>
                        <Button 
                          style={{ marginTop: '5%', backgroundColor: '#ff5722' }}
                          fluid
                          color="blue" onClick={() => setOpenModalData(false)}>
                          Confirmar Dados
                        </Button>
                    </Form>
                  </div>
                }
              </Modal.Content>
            </Modal>

            <Modal
              open={openModalPassword}
              onClose={() => setOpenModalPassword(false)}
              onOpen={() => setOpenModalPassword(true)}
              trigger={
                <div>
                  <Button
                    style={{ marginTop: '5%', backgroundColor: '#ff5722' }}
                    fluid
                    color="blue"
                  >
                    Alterar Senha
                  </Button>
                </div>
              }
            >
              <Header icon="user" content="Alterar Senha" style={modalStyle} />
              <Modal.Content image scrolling style={modalStyle}>
                {
                  <div className="">
                    <Form>
                      <Form.Field>
                        <label style={inputFont}>Digite a senha atual</label>
                        <Input
                          fluid
                          icon="lock"
                          iconPosition="left"
                          id="inputOldPassword"
                          className="form-control"
                          placeholder="Senha atual"
                          required
                          autoFocus
                          type="password"
                          value={oldPassword}
                          onChange={(event) => {
                            setOldPassword(event.target.value);
                          }}
                        />
                      </Form.Field>
                      <Form.Field>
                        <label style={inputFont}>Digite a nova senha</label>
                        <Input
                          fluid
                          icon="lock"
                          iconPosition="left"
                          id="inputNewPassword"
                          className="form-control"
                          placeholder="Nova senha"
                          required
                          autoFocus
                          type="password"
                          value={newPassword}
                          onChange={(event) => {
                            setNewPassword(event.target.value);
                          }}
                        />
                      </Form.Field>
                      <Form.Field>
                        <label style={inputFont}>Confirme a nova senha</label>
                        <Input
                          fluid
                          icon="lock"
                          iconPosition="left"
                          id="inputConfirmNewPassword"
                          className="form-control"
                          placeholder="Confirme a nova senha"
                          required
                          autoFocus
                          type="password"
                          value={confirmNewPassword}
                          onChange={(event) => {
                            setConfirmNewPassword(event.target.value);
                          }}
                        />
                      </Form.Field>
                      <Form.Field>
                        <label style={errorFont}>{errorMessage}</label>
                        <Button 
                          style={{ marginTop: '5%', backgroundColor: '#ff5722' }}
                          fluid
                          color="blue" onClick={handleChangePassword}>
                          Confirmar Senha
                        </Button>
                      </Form.Field>
                    </Form>
                  </div>
                }
              </Modal.Content>
            </Modal>
            
          </div>
        </div>
        <div className="profile__secondSquare" >
            <Image style={imageStyle}
              src={`data:image/jpeg;base64,${localStorage.getItem('avatar')}`}
              className="profile__squareImg"
              size="medium"
              floated="left"
            /> 
        </div>
      </div>
    </>
  );
}

export default MyAccount;

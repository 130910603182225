import axios from 'axios';

const apiSite = axios.create({
  baseURL: 'https://vast-brook-64800.herokuapp.com/',
  // baseURL: 'http://localhost:3333/',
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
    'Access-Control-Allow-Origin': '*',
    // Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`
  },
});

export default apiSite;

export const api = apiSite;

import React from 'react';
import TipsComponent from '../../../../components/MapComponents/tipsComponent';

import tip1 from '../../../../images/Physics/Gravitation/tip1.PNG';
import tip6 from '../../../../images/Physics/Gravitation/tip6.PNG';
import tip7 from '../../../../images/Physics/Gravitation/tip7.PNG';

function Solution(props) {
  return (
    <>
      <TipsComponent
        numberTips={3}
        presentation1="Bem vindo à Academia de Astronautas, um local onde conseguimos reparar algumas arestas em seu aprendizado e dar algumas dicas para você conseguir realizar nossa viagem."
        presentation2="Aqui temos que trabalhar duro, então algumas dicas necessitarão de um esforço seu. Vamos lá!"
        subject="physics"
        typeScoring="gravitation"
        arrayScoring="arrStarLawOfUniversalGravitation"
        tips={[
          {
            number: 0,
            image: tip1,
            intro1: 'Aprendendo a utilizar as vestimentas de astronauta, você encontra uma dica',
            intro2: null,
            icon: 'angle right',
            title: 'Uso do macacão',
            dica1: true,
            dica1HasCondition: false,
            dica1ValidateCondition: null,
            dica1ConditionType: null,
            dica1MessageCondition: null,
            dica1ResponsePt1:
              'Aplique a Lei da Gravitação Universal para descobrir a massa da nave e a força exercida pela Lua:',
            dica1ResponsePt2: null,
            dica1ResponsePt3: null,
            dica1ResponsePt4: null,
            dica1ResponsePt5: null,
            dica1ResponseEquation1: String.raw`F = \frac{G*m_{a}*m_{b}}{d^2}`,
            dica2: false,
          },
          {
            number: 1,
            image: tip6,
            intro1: 'Aprendendo a utilizar o capacete espacial, você encontra mais uma dica.',
            intro2: null,
            icon: 'angle right',
            title: 'Capacete Espacial',
            dica1: true,
            dica1HasCondition: true,
            dica1ValidateCondition: 'arrStars[1] === true',
            dica1ConditionType: 'mission',
            dica1MessageCondition:
              'Essa dica está bloqueada para você! Para desbloqueá-la, realize a missão "Terra e Saturno".',
            dica1ResponsePt1:
              'Primeiramente, iremos descobrir qual a massa de nossa nave. Sabendo que a força entre a nave e o planeta é de 801 N, que a distância entre ambos é de 316000000 km e que a massa do planeta é 6E24 kg, podemos aplicar a Lei da Gravitação Universal.',
            dica1ResponsePt2: 'Sabendo que a constante G é igual a 6,67E-11, temos:',
            dica1ResponsePt3: null,
            dica1ResponsePt4: null,
            dica1ResponseEquation1: String.raw`F = \frac{G*m_{a}*m_{b}}{d^2}`,
            dica1ResponseEquation2: String.raw`801 = \frac{6,67*10^{-11}*6*10^{24}*m_{b}}{316000000^2}`,
            dica1ResponseEquation3: String.raw`m_{b} = \frac{801*316000000^2}{6,67*10^{-11}*6*10^{24}}`,
            dica1ResponseEquation4: String.raw`m_{b} = 200000`,
            dica1ResponsePt5:
              'Com o peso da nave com um valor de 200000 kg, você consegue descobrir a força exercida pela lua?.',
            dica1QuestionTittle:
              '(FUVEST-SP) No sistema solar, o planeta Saturno tem massa  cerca de 100 vezes maior do que a da Terra e descreve uma órbita, em torno do Sol, a uma distância média 10 vezes maior do que a distância média da Terra ao Sol (valores aproximados). A razão FSat/FT entre a força gravitacional com que o Sol atrai Saturno e a força gravitacional com que o Sol atrai a Terra é de aproximadamente:',
            dica1QuestionImage: null,
            dica1OptionA:
              '1000',
            dica1OptionB:
              '10',
            dica1OptionC:
              '1',
            dica1OptionD:
              '0,1',
            dica1OptionE:
              '0,001',
            dica1CorrectAnswer: 'c',
            dica1MissionName: 'Terra e Saturno',
            dica1Subject: 'physics',
            dica1TypeScoring: 'gravitation',
            dica1ArrayScoring: 'arrStarLawOfUniversalGravitation',
            dica1IndexScoring: 1,
            dica1XPAward: 30,
            dica2: false,
          },
          {
            number: 2,
            image: tip7,
            intro1:
              'Aprendendo a utilizar a antena de comunicação, você encontra mais uma dica.',
            intro2: null,
            icon: 'angle right',
            title: 'Antena de Comunicação',
            dica1: true,
            dica1HasCondition: true,
            dica1ValidateCondition: 'arrStars[2] === true',
            dica1ConditionType: 'mission',
            dica1MessageCondition:
              'Essa dica está bloqueada para você! Para desbloqueá-la, realize a missão "As Marés".',
            dica1ResponsePt1:
              'Agora que sabemos que nossa nave pesa 200000 kg e que nos encontramos a 10000000 km da lua, podemos calcular sua força de atrção pela Lei da Gravitação Universal.',
            dica1ResponsePt2: 'Sabendo que a massa da lua é de 6E22 kg e que a constante G é igual a 6,67E-11, temos:',
            dica1ResponsePt3: null,
            dica1ResponsePt4: null,
            dica1ResponseEquation1: String.raw`F = \frac{G*m_{a}*m_{b}}{d^2}`,
            dica1ResponseEquation2: String.raw`F = \frac{6,67*10^{-11}*6*10^{22}*200000}{10000000^2}`,
            dica1ResponseEquation3: String.raw`F = 8000`,
            dica1ResponsePt5:
              'Assim, descobrimos que a força exercida pela lua na nave é de cerca de 8000 N. Como a lua se encontra a 45º da linha que une o foguete ao planeta, para que a força resultante seja em direção ao planeta Quest, devemos aplicar na nave uma força de módulo 8000 N e na direção de -45º dessa mesma linha.',
            dica1QuestionTittle:
              '(UDESC-SC)  A maré é o fenômeno natural de subida e descida do nível das águas, percebido principalmente nos oceanos, causado pela atração gravitacional do Sol e da Lua. De acordo com a variação do nível das águas ao longo de uma rotação completa da Terra, considere as seguintes proposições sobre a maré, e assinale a alternativa incorreta:',
            dica1OptionA: 'As marés de maior amplitude ocorrem próximo das situações de Lua Nova ou Lua Cheia, quando as forças atrativas, devido ao Sol e à Lua, se reforçam mutuamente.',
            dica1OptionB:
              'A influência da Lua é maior do que a do Sol, pois, embora a sua massa seja muito menor do que a do Sol, esse fato é compensado pela menor distância à Terra.',
            dica1OptionC: 'A maré cheia é vista por um observador quando a Lua passa por cima dele, ou quando a Lua passa por baixo dele.',
            dica1OptionD: 'As massas de água que estão mais próximas da Lua ou do Sol sofrem atração maior do que as massas de água que estão mais afastadas, devido à rotação da Terra.',
            dica1OptionE: 'As marés alta e baixa sucedem-se em intervalos de aproximadamente 6 horas.',
            dica1CorrectAnswer: 'd',
            dica1MissionName: 'As Marés',
            dica1Subject: 'physics',
            dica1TypeScoring: 'gravitation',
            dica1ArrayScoring: 'arrStarLawOfUniversalGravitation',
            dica1IndexScoring: 2,
            dica1XPAward: 30,
            dica2: false,
          },
        ]}
      />
    </>
  );
}

export default Solution;

import React, { useState } from 'react';
import { Form, Checkbox, Button, Icon, Image } from 'semantic-ui-react';
import useSound from 'use-sound';
import correctSound from '../../sounds/correctAnswer.mp3';
import wrongSound from '../../sounds/wrongAnswer.mp3';

import '../../components/MapComponents/tipPage.css';

function Question(props) {
  const [answer, setAnswer] = useState(0);
  const [playCorrect] = useSound(correctSound);
  const [playWrong] = useSound(wrongSound);

  const checkboxStyle = {
    width: '75%',
    height: '10px',
  };
  const SIZEIMAGE = '25%';

  function readAnswer(e, { value }) {
    setAnswer(value);
  }

  return (
    <div>
      <Form>
        <Form.Field>
          <p className="presentationItemQuest">{props.title}</p>
          {props.subtitle1 && <p className="presentationSubItemQuest">{props.subtitle1}</p>}
          {props.subtitle2 && <p className="presentationSubItemQuest">{props.subtitle2}</p>}
          {props.subtitle3 && <p className="presentationSubItemQuest">{props.subtitle3}</p>}
          {props.subtitle4 && <p className="presentationSubItemQuest">{props.subtitle4}</p>}
          {props.subtitle5 && <p className="presentationSubItemQuest">{props.subtitle5}</p>}
          <Image src={props.image} width={SIZEIMAGE} centered />
        </Form.Field>
        <Form.Field>
          <Checkbox
            style={checkboxStyle}
            radio
            label={props.valueA}
            value="a"
            checked={answer === 'a'}
            onChange={readAnswer}
          />
        </Form.Field>
        <Form.Field>
          <Checkbox
            style={checkboxStyle}
            radio
            label={props.valueB}
            value="b"
            checked={answer === 'b'}
            onChange={readAnswer}
          />
        </Form.Field>
        <Form.Field>
          <Checkbox
            style={checkboxStyle}
            radio
            label={props.valueC}
            value="c"
            checked={answer === 'c'}
            onChange={readAnswer}
          />
        </Form.Field>
        <Form.Field>
          <Checkbox
            style={checkboxStyle}
            radio
            label={props.valueD}
            value="d"
            checked={answer === 'd'}
            onChange={readAnswer}
          />
        </Form.Field>
        <Form.Field>
          <Checkbox
            style={checkboxStyle}
            radio
            label={props.valueE}
            value="e"
            checked={answer === 'e'}
            onChange={readAnswer}
          />
        </Form.Field>
        <br></br>
      </Form>

      <Button
        className="tipButton"
        icon
        labelPosition="left"
        onClick={() => {
          props.setStep(props.valueStepReturn);
        }}
      >
        <Icon name="undo" />
        RETORNAR
      </Button>

      <Button
        className="tipButton"
        icon
        labelPosition="left"
        onClick={() => {
          if (answer === props.corretcAnswer) {
            props.setStep(props.valueStepCorrect);
            playCorrect();

            // scoreManager.updateStars(props.subjectScoring, props.typeScoring, props.arrayScoring, props.indexScoring);
            // scoreManager.updateXP(props.subjectScoring, props.xpAward);

            // scoreManager.updateRespect((value) => value + 1000);
          } else {
            // scoreManager.updateRespect((value) => value - 200);
            props.setStep(props.valueStepWrong);
            playWrong();
          }
        }}
      >
        <Icon name="check" />
        RESPONDER
      </Button>
    </div>
  );
}

export default Question;

import React, { useState, useEffect } from 'react';
import { MathComponent } from 'mathjax-react';
import Anime, { anime } from 'react-anime';
// import anime from "animejs/lib/anime.es.js";

function TorricelliPage(props) {
  const [torricelliStep, setTorricelliStep] = useState(0);
  const [isMinimized, setIsMinimized] = useState(false);

  useEffect(() => {
    anime({
      targets: '#torri-eq1',
      translateX: '20vw',
      loop: true,
    });
    anime({
      targets: '#torri-eq2',
      translateX: '-20vw',
      loop: true,
    });
  }, [torricelliStep]);

  const standardObj = [
    {
      name: 'Equação de Torricelli',
      torricelliStep: 1,
      comp: (
        <>
          <h1 className="presentationItem">Equação de Torricelli</h1>

          <p className="presentationItem">
            Graças ao físico e matemático italiano Evangelista Torricelli, há uma forma muito mais
            fácil de acabar com esse problema. Ele combinou as duas equações que vimos anteriormente
            para criar a <b className="presentationItem">equação de Torricceli</b>, que nos dá a
            relação entre{' '}
            <b className="presentationItem">
              Velocidade, Posição e aceleração, sem necessidade do tempo envolvido
            </b>
            :
          </p>

          <p className="presentationItem">
            <div className="torriccelliEqComponents">
              <Anime
                translateX="27vw"
                loop={true}
                opacity={{ value: [1, 0], easing: 'easeInCubic' }}
                duration={4000}
                endDelay={3000}
              >
                <MathComponent tex={String.raw`S = S_{0} + V_{0}*t + \frac{a*t^2}{2}`} />
              </Anime>
              <Anime
                loop={true}
                opacity={{ value: [0, 1], easing: 'easeInCubic' }}
                duration={4000}
                endDelay={3000}
              >
                <MathComponent tex={String.raw`V^2 = V_{0}^2 + 2*a*\Delta S`} />
              </Anime>
              <Anime
                translateX="-27vw"
                loop={true}
                opacity={{ value: [1, 0], easing: 'easeInCubic' }}
                duration={4000}
                endDelay={3000}
              >
                <MathComponent tex={String.raw`V = V_{0} + a*t`} />
              </Anime>
            </div>
          </p>

          <p className="presentationItem">
            Assim, a aceleração necessária para freiar e evitar um grande acidente no exemplo
            anteriorpode ser:
            <MathComponent tex={String.raw`0^2 = 10^2 + 2*a*5`} />
            <MathComponent tex={String.raw`a = -10 \frac{m}{s^2}`} />
          </p>
        </>
      ),
    },
  ];

  return (
    <div className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`}>
      {/* <div className="minMaxBar">
        <Icon className="icon" name='caret down' onClick={minimizeBar}/>
        <Icon className="icon" name='caret up' onClick={maximizeBar}/>
      </div> */}
      {!isMinimized && standardObj[torricelliStep].comp}
    </div>
  );
}

export default TorricelliPage;

// import CoolNavBar from './CoolNavBar/CoolNavBar.tsx';
import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import "./newNavbar.scss";
import noPhoto from "../images/noPhoto.png";
import logo from "../images/Logos/oficialSpinnerLogoWhite.png";
import Cookies from 'js-cookie';

function NavBar(props){

    let history = useHistory();

    const logout = () => {
        Cookies.remove("loginstatus")
        history.push('/')
    };

    const [isBoxActive, setBoxActive] = useState(false);
    const boxOptions = [
        {name: "Configurações", destination: "meu-perfil", func: () => {}},
        {name: "Sair", destination: "", func: logout}
    ]

    let profilePhoto = localStorage.getItem('avatar') ? `data:image/jpeg;base64,${localStorage.getItem('avatar')}` : noPhoto;

    return (
        <>
        <div className="newNavbar__container">
            <img className="newNavbar__profilePhoto" src={profilePhoto} onClick={()=>{setBoxActive(!isBoxActive)}}/>
            <div className="newNavbar__navbarItemsContainer">
                <span className="secondaryFont newNavbar__navbarItem" onClick={()=>{history.push('/disciplinas')}}>Disciplinas</span>
                <span className="secondaryFont newNavbar__navbarItem" onClick={()=>{history.push('/apresentacao-treinamento')}}>Treinamento</span>
                <span className="secondaryFont newNavbar__navbarItem" onClick={()=>{history.push('/meu-perfil')}}>Espaço do aluno</span>
            </div>
            <img className="newNavbar__logo" src={logo} onClick={()=>{history.push('/')}}/>
        </div>
        {isBoxActive && 
        <div className="newNavbar__optionsBox">
            <ul>
                {(boxOptions || []).map((item, index) => <li key={'option_' + index} className="secondaryFont"> <div onClick={item.func}>{item.name}</div> </li>)}
            </ul>
        </div>}
        </>
    )
}

export default NavBar;

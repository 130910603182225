import React, { useEffect } from 'react';
import { Button, Container, Grid, Rail, Segment } from 'semantic-ui-react';
import anime from 'animejs/lib/anime.es.js';
import './compSubjectPresentation.css';
import { Link } from 'react-router-dom';

function CompSubjetPresentation(props) {
  return (
    <>
      <Grid className={"subjectPage"} 
      style={{
        'background-image': `linear-gradient(rgba(${props.color}), rgba(${props.color})),
          url(data:image/png;base64,${props.background})`,
      }}
      >
        <Grid.Row>
          <Container className="subjectCont" centered="true">
            <Segment className="subjectTextBlockLeft">
              <div className="innersubjectTextBlock">
                <h1 className="subjectHeader">{props.title}</h1>
                <p className="subjectParagraph">{props.text1}</p>
                <p className="subjectParagraph">{props.text2}</p>
              </div>
            </Segment>
            <div className="imageRight">{props.image1}</div>
          </Container>
        </Grid.Row>
        <Grid.Row>
          <Container className="subjectCont" centered="true">
            <Segment className="subjectTextBlockRight">
              <div className="innersubjectTextBlock">
                <p className="subjectParagraph">{props.text3}</p>
                <p className="subjectParagraph">{props.text4}</p>
              </div>
            </Segment>
            <div className="imageLeft">
              <img style={props.styleImg2} src={`data:image/png;base64,${props.image2}`} alt="img2" />
              <Button
                id="start-btn"
                as={Link}
                size="huge"
                to={props.route}
                className="subjectPresentationBtn btnLeft"
              >
                Começar desafio
              </Button>
            </div>
          </Container>
        </Grid.Row>
      </Grid>
    </>
  );
}

export default CompSubjetPresentation;

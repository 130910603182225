import React, { useEffect, useState } from 'react';
import { Menu } from 'semantic-ui-react';
import Navbar from '../../components/navbar';
import ItemsBar from '../../components/ItemsBar';
import './myProfile.scss';

import Overview from './overview';
import Habilities from './habilities';
import Physics from './physics';
import Chemistry from './biology';
import Biology from './chemistry';
import MyAccount from './myAccount';
import Avatar from './avatar';
import StickerAlbum from './stickerAlbum';
import YourCareer from './yourCareer';

function MyProfile() {
  const subItems = {
    //Páginas de 'Meus dados',  'Lista de amigos' e 'Mensagens' ocultas pelo momento  
    // Perfil: ['Visão Geral', 'Álbum de Figurinhas', 'Habilidades', 'Meus dados'],
    Perfil: ['Visão Geral', 'Álbum de Figurinhas', 'Habilidades'],
    Histórico: ['Física', 'Química', 'Biologia'],
    'Sua Carreira': ['Sua carreira', 'Plano de ensino'],
    // Amigos: ['Lista de amigos', 'Mensagens'],
    Configurações: ['Minha conta', 'Avatar'],
  };
  // const items = ['Perfil', 'Histórico', 'Sua Carreira', 'Amigos', 'Configurações'];
  const items = ['Perfil', 'Histórico', 'Sua Carreira', 'Configurações'];
  const [isMinimized, setIsMinimized] = useState(false);
  const [activeItem, setActiveItem] = useState('Perfil');
  const [activeSubItem, setActiveSubItem] = useState(subItems[activeItem][0]);

  const arrPages = [
    {
      name: 'Visão Geral',
      comp: Overview,
    },
    {
      name: 'Habilidades',
      comp: Habilities,
    },
    {
      name: 'Física',
      comp: Physics,
    },
    {
      name: 'Química',
      comp: Chemistry,
    },
    {
      name: 'Biologia',
      comp: Biology,
    },
    {
      name: 'Minha conta',
      comp: MyAccount,
    },
    {
      name: 'Avatar',
      comp: Avatar,
    },
    {
      name: 'Álbum de Figurinhas',
      comp: StickerAlbum,
    },
    {
      name: 'Sua carreira',
      comp: YourCareer,
    },
  ];

  function handleClickSubItems(e, { name }) {
    setActiveSubItem(name);
  }

  useEffect(() => {
    setActiveSubItem(subItems[activeItem][0]);
  }, [activeItem]);

  return (
    <div>
      <Navbar />
      <ItemsBar
        isMinimized={isMinimized}
        minimizeFunction={setIsMinimized}
        items={items}
        firstActive="Perfil"
        setActiveItem={setActiveItem}
        activeItem={activeItem}
        blockMinMax = {true}
      />
      <div className="myProfilePage">
        <Menu
          inverted
          fluid
          widths={subItems[activeItem].length}
          className={isMinimized ? 'secondaryFont minimized' : 'secondaryFont'}
        >
          {subItems[activeItem].map((subItem) => (
            <Menu.Item
              name={subItem}
              key={subItem}
              active={activeSubItem === subItem}
              onClick={handleClickSubItems}
              color="orange"
            />
          ))}
        </Menu>
        {arrPages
          .filter((item) => item.name === activeSubItem)
          .map((item, nIndex) => {
            return <item.comp key={nIndex} {...item.props} />;
          })}
      </div>
    </div>
  );
}

export default MyProfile;

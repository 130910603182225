import React from 'react';
import MissionComponent from '../../../../components/MapComponents/missionComponent';

function HistoryPage(props) {
  return (
    <>
      <MissionComponent
        numberMissions={5}
        subject="physics"
        typeScoring="kinematics"
        arrayScoring="arrStarAverageSpeed"
        missionName="Sua Primeira Corrida"
        missionDescription="Descrição: Converse com Marcelo Cura no Escritório, entenda os requisitos para a troca de pneus na volta correta e vença a primeira corrida no Autódromo."
        missionVectorNumber={4}
        submission={[
          {
            missionName: 'Descubra novos conhecimentos',
            missionDescription: 'Descrição: Investigue o Laboratório.',
            missionVectorNumber: 0,
          },
          {
            missionName: 'Tamanho da pista',
            missionDescription: 'Descrição: Converse com a Engenheira Thaís Condida.',
            missionVectorNumber: 1,
          },
          {
            missionName: 'Tamanho da Estrada',
            missionDescription: 'Descrição: Converse com o mecânico Luiz Câmera.',
            missionVectorNumber: 2,
          },
          {
            missionName: 'Velocidade do Carro',
            missionDescription: 'Descrição: Investigue as ferramentas da Oficina.',
            missionVectorNumber: 3,
          },
        ]}
      />
    </>
  );
}

export default HistoryPage;

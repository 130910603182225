import React, { useState, useEffect } from 'react';
import { List } from 'semantic-ui-react';
import Pentagon from '../../components/Pentagon';
import XpChart from '../../components/XpChart';
import api from '../../services/api';
import moment from 'moment';

import './habilities.css';
import './myProfile.scss';
import './profile.scss';

function Overview() {
  const [clientInfo, setClientInfo] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      await api
        .post('getClientsInfo', {
          client_id: localStorage.getItem('client_id'),
        })
        .then((res) => {
          setClientInfo(res.data);
        })
        .catch((err) => {
          console.log(err.message);
        });
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="profileSubItem">
        <div>
          <h1 className="presentationItem">Visão Geral</h1>
        </div>
        <div className="profile__firstSquare">
          <img
            className="profile__squareImg"
            src={`data:image/jpeg;base64,${localStorage.getItem('avatar')}`}
          />
          <div className="profile__list">
            <div className="presentationItem">
              <br></br>
              <List animated>
                <List.Item>
                  <List.Icon name="user" />
                  <List.Content>
                    {localStorage.getItem('name') + ' ' + localStorage.getItem('surname')}
                  </List.Content>
                </List.Item>

                <List.Item>
                  <List.Icon name="hourglass" />
                  <List.Content>
                    {moment.utc().diff(moment(localStorage.getItem('birth_date')), 'years')} anos
                  </List.Content>
                </List.Item>

                <List.Item>
                  <List.Icon name="marker" />
                  <List.Content>
                    {localStorage.getItem('city') !== 'undefined' && localStorage.getItem('city')}
                  </List.Content>
                </List.Item>

                <List.Item>
                  <List.Icon name="university" />
                  <List.Content>
                    {localStorage.getItem('school') ? localStorage.getItem('school') : 'Sem Escola'}
                  </List.Content>
                </List.Item>

                <List.Item>
                  <List.Icon name="graduation" />
                  <List.Content>
                    {localStorage.getItem('dream')
                      ? localStorage.getItem('dream')
                      : 'Sem Carreira Definida'}
                  </List.Content>
                </List.Item>

                <List.Item>
                  <List.Icon name="fonticons" />
                  <List.Content>
                    {clientInfo && clientInfo.unlocked_stickers
                      ? clientInfo.unlocked_stickers.filter((e) => e !== '').length
                      : 0}{' '}
                    figurinhas
                  </List.Content>
                </List.Item>
              </List>
            </div>
          </div>
        </div>
        {clientInfo && clientInfo.id && (
          <div className="profile__secondSquare">
            <Pentagon
              patience={clientInfo.patience}
              persistence={clientInfo.persistence}
              impulse={clientInfo.impulse}
              fastness={clientInfo.fastness}
              devotion={clientInfo.devotion}
            />
            <br></br>
            <XpChart
              physicsXp={70}
              maxPhysicsXp={100}
              physicsLevel={3}
              chemistryXp={50}
              maxChemistryXp={100}
              chemistryLevel={2}
              biologyXp={20}
              maxBiologyXp={100}
              biologyLevel={1}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default Overview;

import React from 'react';
import './gravitationComp.scss';
import { Button, Icon, Grid } from 'semantic-ui-react';

import gameManager from '../../../../managers/GameManager';

function TimeCount(props) {
  return (
    props.condition && (
      <div className="gravitation__timeCount">
        <div className="gravitation__timeCount__icon">
          <Icon name="stopwatch" />
        </div>
        <div className="gravitation__timeCount__paragraph">
          <p>{gameManager.objSpecificGame.physics.gravitation.timeCount}</p>
        </div>
      </div>
    )
  );
}

export default TimeCount;

import React, { useState } from 'react';
import { Button, Confirm, Icon, Image, Progress, List, Popup } from 'semantic-ui-react';

import Message from '../../pages/Common/compMissionMessage';
import '../../pages/Common/kinematicsMenu.css';
// import scoreManager from '../../managers/ScoreManager';

import estrela from '../../images/estrela.png';

import useSound from 'use-sound';
import map from '../../sounds/map.wav';
import api from '../../services/api';

import { useHistory } from 'react-router-dom';

function ChallengeMenuComponent(props) {
  const history = useHistory();

  var styleBckgd = {
    backgroundImage: 'url(' + props.background + ')',
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
  };

  const [messages, setMessages] = useState({ oficina: false, hall: false });
  const [playSong] = useSound(map);

  // Variáveis para cálculo da pontuação do aluno
  var xp = sessionStorage.getItem('xp');
  var level = sessionStorage.getItem('level');
  var arrStars = (sessionStorage.getItem('arr_stars') || '')
    .split(',')
    .map((e) => (e === 'true' ? true : false));

  var numStars = [];
  var colorStars = [];
  var styleStars = [];
  arrStars.forEach((star) => {
    numStars.push(star ? 1 : 0);
    colorStars.push(star ? 'yellow' : 'grey');
    styleStars.push(star ? { color: 'white' } : { color: 'grey' });
  });

  var totalStars = numStars.length;
  var acquiredStars = numStars.reduce((a, b) => a + b, 0);

  // Variáveis para exibição da mensagem de ajuda do aluno
  var acessMission = sessionStorage.getItem('access_museum') === 'true' ? 'true' : 'false';
  var missionMessage;
  eval(props.validadeMessage);

  async function updateAccessMuseum() {
    await api
      .post('updateAccessMuseum', {
        challenge_id: sessionStorage.getItem('challenge_id'),
        client_id: localStorage.getItem('client_id'),
        access_museum: true,
      })
      .then((res) => {
        sessionStorage.setItem('access_museum', true);
      })
      .catch((err) => {});
  }

  function openItemsBar(items, firstActive) {
    props.setIsMinimized(false);
    props.setPresentationItems(items);
    props.setActiveItem(firstActive);
  }

  let titleHeader = 'titleHeader';
  let xpHeader = 'xpHeader';
  let starsHeader = 'starsHeader';
  if (props.titleColor === 'preto') {
    titleHeader += ' fontBlack';
    xpHeader += ' fontBlack';
    starsHeader += ' fontBlack';
  }

  return (
    <>
      <div style={styleBckgd}>
        <div className="mapHeaderContainer">
          <div className="title">
            <h1 className={titleHeader}> {props.title}</h1>
          </div>
          <div className="xp">
            <h1 className={xpHeader}>
              {' '}
              {props.titleColor === 'branco' && (
                <Progress
                  progress="value"
                  inverted
                  color="blue"
                  value={xp}
                  total={(xp * 10) / ((xp % 1000) / 100)}
                  size="large"
                  active
                >
                  {' '}
                  Level {level}{' '}
                </Progress>
              )}
              {props.titleColor === 'preto' && (
                <Progress
                  progress="value"
                  color="blue"
                  value={xp}
                  total={(xp * 10) / ((xp % 1000) / 100)}
                  size="large"
                  active
                >
                  {' '}
                  Level {level}{' '}
                </Progress>
              )}
            </h1>
          </div>
          <div className="stars">
            <h1 className={starsHeader}>
              {' '}
              {acquiredStars}/{totalStars}
            </h1>
            <Image className="starImg" src={estrela} />
          </div>

          <Message name={props.emissor} mission={missionMessage} image={props.imageEmissor} />
        </div>

        <div className={props.acessPoints[0].position}>
          <Image
            className="mapItem"
            src={props.acessPoints[0].image}
            onClick={async () => {
              openItemsBar(props.acessPoints[0].content, props.acessPoints[0].start);
              if (props.acessPoints[0].setInfo && props.acessPoints[0].setValue)
                // scoreManager.updateAdditionalInfo(
                //   props.subject,
                //   props.typeScoring,
                //   props.acessPoints[0].setInfo,
                //   props.acessPoints[0].setValue
                // );
                await updateAccessMuseum();
              sessionStorage.setItem('access_museum', true);
            }}
            onMouseOver={() => {
              playSong();
            }}
          />
        </div>

        <div className={props.acessPoints[1].position}>
          <Image
            className="mapItem"
            src={props.acessPoints[1].image}
            onClick={async () => {
              openItemsBar(props.acessPoints[1].content, props.acessPoints[1].start);
              if (props.acessPoints[1].setInfo && props.acessPoints[1].setValue)
                // scoreManager.updateAdditionalInfo(
                //   props.subject,
                //   props.typeScoring,
                //   props.acessPoints[1].setInfo,
                //   props.acessPoints[1].setValue
                // );
                await updateAccessMuseum();
              sessionStorage.setItem('access_museum', true);
            }}
            onMouseOver={() => {
              playSong();
            }}
          />
        </div>

        <div className={props.acessPoints[2].position}>
          <Image
            className="mapItem"
            src={props.acessPoints[2].image}
            onClick={async () => {
              openItemsBar(props.acessPoints[2].content, props.acessPoints[2].start);
              if (props.acessPoints[2].setInfo && props.acessPoints[2].setValue)
                // scoreManager.updateAdditionalInfo(
                //   props.subject,
                //   props.typeScoring,
                //   props.acessPoints[2].setInfo,
                //   props.acessPoints[2].setValue
                // );
                await updateAccessMuseum();
              sessionStorage.setItem('access_museum', true);
            }}
            onMouseOver={() => {
              playSong();
            }}
          />
        </div>

        <div className={props.acessPoints[3].position}>
          <Image
            className="mapItem"
            src={props.acessPoints[3].image}
            onClick={async () => {
              openItemsBar(props.acessPoints[3].content, props.acessPoints[3].start);
              if (props.acessPoints[3].setInfo && props.acessPoints[3].setValue)
                // scoreManager.updateAdditionalInfo(
                //   props.subject,
                //   props.typeScoring,
                //   props.acessPoints[3].setInfo,
                //   props.acessPoints[3].setValue
                // );
                await updateAccessMuseum();
              sessionStorage.setItem('access_museum', true);
            }}
            onMouseOver={() => {
              playSong();
            }}
          />
        </div>

        <div className={props.acessPoints[4].position}>
          <Image
            className="mapItem"
            src={props.acessPoints[4].image}
            onClick={async () => {
              openItemsBar(props.acessPoints[4].content, props.acessPoints[4].start);
              if (props.acessPoints[4].setInfo && props.acessPoints[4].setValue)
                // scoreManager.updateAdditionalInfo(
                //   props.subject,
                //   props.typeScoring,
                //   props.acessPoints[4].setInfo,
                //   props.acessPoints[4].setValue
                // );
                await updateAccessMuseum();
              sessionStorage.setItem('access_museum', true);
            }}
            onMouseOver={() => {
              playSong();
            }}
          />
        </div>

        <div className={props.acessPoints[5].position}>
          <Image
            className="mapItem"
            src={props.acessPoints[5].image}
            onClick={() => {
              if (eval(props.acessPoints[5].validateAcess)) {
                openItemsBar(props.acessPoints[5].content, props.acessPoints[5].start);
              } else {
                setMessages({ ...messages, ['hall']: true });
              }
            }}
            onMouseOver={() => {
              playSong();
            }}
          />
          <Confirm
            open={messages.hall}
            content={props.acessPoints[5].messageWithoutAcess}
            confirmButton="Ok"
            cancelButton={false}
            onConfirm={() => {
              setMessages({ ...messages, ['hall']: false });
            }}
          />
        </div>
        <Popup
          position="top right"
          content="Voltar para o mapa de fases"
          trigger={
            <Icon
              onClick={(props) => {
                history.push('/mapa-de-fases-cinematica');
              }}
              name="reply"
              size="big"
              style={{ position: 'absolute', bottom: '60px', right: '50px' }}
            />
          }
        />
        {/* <Icon name='reply' size='big' style={{position: 'absolute', bottom: '60px', right: '50px'}}/> */}
      </div>
    </>
  );
}

export default ChallengeMenuComponent;

import SceneComponent from 'babylonjs-hook';
import BabylonjsFunctions from './BabylonjsFunctions';

import React, { useEffect, useState } from 'react';
import ControlPanel from '../../../Babylon React Components/ControlPanel';
import ClassificationTable from '../Gerais/compClassificationTable';
import AskMessage from '../../../Babylon React Components/compAskMessage';
import ResponseSlider from '../../../Babylon React Components/compResponseSlider';
import FinalPanel from '../Gerais/compFinalPanel';
import manager from '../../../../images/Physics/Kinematics/face_manager.PNG';

import LoadingSpinner from '../../../loadingSpinner';

import gameManager from '../../../../managers/GameManager';

function TorricelliScene(props) {
  let [onSceneReady, onRender] = BabylonjsFunctions();

  // Atualização do tempo necessária para atualização dos valores na gameManager
  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 100);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // Para configuração do slider
  let stepValue = 1;
  let maxSlider = 1;
  let unidade = '';

  if (
    gameManager.objSpecificGame.physics.kinematics.message ===
    'Qual a aceleração do carro para chegar no radar com maior velocidade?'
  ) {
    stepValue = 0.1;
    maxSlider = 10;
    unidade = 'm/s²';
  } else if (
    gameManager.objSpecificGame.physics.kinematics.message ===
    'Em qual distância do fim o carro tem que começar a frear para ganhar a corrida?'
  ) {
    stepValue = 5;
    maxSlider = 500;
    unidade = 'metros';
  }

  let nameIcon = 'play';
  let nameButton = 'Começar';
  gameManager.objSpecificGame.physics.kinematics.running === '1'
    ? (nameIcon = 'pause')
    : (nameIcon = 'play');
  gameManager.objSpecificGame.physics.kinematics.running === '1'
    ? (nameButton = 'Pausar')
    : (nameButton = 'Começar');

  return (
    <div>
      <SceneComponent antialias onSceneReady={onSceneReady} onRender={onRender} id="my-canvas" />
      <LoadingSpinner
        loading={gameManager.objSpecificGame.physics.kinematics.isLoading === 'true'}
      />
      <ControlPanel
        condition={
          gameManager.objSpecificGame.physics.kinematics.showFinalPanel !== 'true' &&
          gameManager.objSpecificGame.physics.kinematics.isLoading !== 'true'
        }
        icon={nameIcon}
        button={nameButton}
        restartFunction={() => {
          gameManager.updateInfo('physics', 'kinematics', 'running', '1');
          gameManager.updateInfo('physics', 'kinematics', 'restart', '1');
        }}
        playPauseFunction={() => {
          gameManager.updateInfo(
            'physics',
            'kinematics',
            'running',
            (1 - parseInt(gameManager.objSpecificGame.physics.kinematics.running)).toString()
          );
        }}
      />
      <ClassificationTable
        condition={
          gameManager.objSpecificGame.physics.kinematics.showFinalPanel !== 'true' &&
          gameManager.objSpecificGame.physics.kinematics.isLoading !== 'true'
        }
        numCars={2}
      />
      <AskMessage
        condition={
          gameManager.objSpecificGame.physics.kinematics.showMessage === 'true' &&
          gameManager.objSpecificGame.physics.kinematics.isLoading !== 'true'
        }
        image={manager}
        name="Marcelo Cura"
        message={gameManager.objSpecificGame.physics.kinematics.message}
        position='top'
      />
      <ResponseSlider
        condition={
          gameManager.objSpecificGame.physics.kinematics.showSlider === 'true' &&
          gameManager.objSpecificGame.physics.kinematics.isLoading !== 'true'
        }
        min={0}
        max={maxSlider}
        step={stepValue}
        unity={unidade}
        function={(value) => {
          gameManager.updateInfo('physics', 'kinematics', 'responseValue', '' + value);
        }}
      />
      <FinalPanel
        condition={
          gameManager.objSpecificGame.physics.kinematics.showFinalPanel === 'true' &&
          gameManager.objSpecificGame.physics.kinematics.isLoading !== 'true'
        }
        challengeName="Correndo com Tubarões"
        func={props.map}
        starIndex={3}
        xpAward={30}
      />
    </div>
  );
}

export default TorricelliScene;

import React from 'react';
import { useParams } from 'react-router-dom';
import NavBar from '../components/navbar';
import './Common/compModule.scss';
import CompModule from './Common/compModule';
import { Card } from 'semantic-ui-react';
import LoadingSpinner from '../components/loadingSpinner';
import api from '../services/api';

function DisciplinePage() {
  const { id } = useParams();
  const [disciplineInfo, setDisciplineInfo] = React.useState({});

  const windowSizeRequired = window.matchMedia("(min-width: 700px)").matches;

  React.useEffect(() => {
    const fetchData = async () => {
      await api
        .post('getDisciplineInfo', { id: id, client_id: localStorage.getItem('client_id') })
        .then((res) => {
          setDisciplineInfo(res.data);
        })
        .catch((err) => {
          window.location.replace('/disciplinas');
        });
    };
    fetchData();
  }, []);

  return (
    <>
      {!windowSizeRequired && 
        <div className="subjectBackground">
          <NavBar />
          <p id="midia-not-suport">Formato de mídia não suportado</p>
        </div> }
      {windowSizeRequired && <>
        <div
          className="modulesPage__container"
          style={{
            'background-image': `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(data:image/png;base64,${disciplineInfo.background})`,
          }}
        >
          <NavBar />
          {!disciplineInfo.id && <LoadingSpinner loading={true} className="restricted" />}
          {disciplineInfo.id && (
            <>
              <div
                className="modulesPage__detail"
                style={{ 'background-color': disciplineInfo.color }}
              >
                <h1 className="modulesPage__header mainFont">{disciplineInfo.name}</h1>
                <p className="modulesPage__description secondaryFont">
                  {disciplineInfo.inner_description}
                </p>
                <img
                  className="modulesPage__image"
                  src={`data:image/gif;base64,${disciplineInfo.gif}`}
                  alt={'Gif'}
                />
              </div>
              <div className="modulesPage__cardsContainer">
                <div
                  style={{ overflow: 'auto', maxHeight: '90vh', padding: '1% 5%', marginTop: '8vh' }}
                >
                  <Card.Group itemsPerRow={3}>
                    {(disciplineInfo.modules || []).map((module, nIndex) => (
                      <CompModule
                        image={`data:image/jpeg;base64,${module.image}`}
                        name={module.name}
                        destiny={'/apresentacao/' + module.module_id}
                        available_stars={module.available_stars}
                        earned_stars={module.earned_stars}
                        status={module.status}
                        key={nIndex}
                      />
                    ))}
                  </Card.Group>
                </div>
              </div>
            </>
          )}
        </div>
      </>}  
    </>
  );
}

export default DisciplinePage;

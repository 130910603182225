/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Form, Icon, Popup, Button, Image } from 'semantic-ui-react';
import anime from 'animejs/lib/anime.es.js';
import roadT from '../../../../images/Physics/Kinematics/roadT.png';
import sideCarT from '../../../../images/Physics/Kinematics/carT.png';
import distanciaPercorrida from '../../../../images/Physics/Kinematics/distanciaPercorrida.PNG';
import deslocamento from '../../../../images/Physics/Kinematics/deslocamento.PNG';
import BallPlot from '../../../kinematicsLearn/ballMovingPlot.tsx';
import { MathComponent } from 'mathjax-react';
import './displacement.scss';
import scoreManager from '../../../../managers/ScoreManager';

function CustomMathComp({ className, tex }) {
  return (
    <div className={className}>
      <MathComponent tex={tex} />
    </div>
  );
}


function Arrows(props) {
  const ArrButtons = [
    <Button
      icon
      labelPosition="left"
      onClick={() => {
        props.setPageState((prevState) => prevState - 1);
      }}
      key={1}
    >
      <Icon name="left arrow" />
      Anterior
    </Button>,
    <Button
      icon
      labelPosition="right"
      onClick={() => {
        props.setPageState((prevState) => prevState + 1);
      }}
      key={2}
    >
      Próximo
      <Icon name="right arrow" />
    </Button>,
  ];

  return (
    <div className="presentationItem arrowBtnContainer">
      {ArrButtons.filter((item, nIndex) => props.arrsToShow[nIndex])}
    </div>
  );
}


function Displacement(props) {
  const [distance, setDistance] = useState(0);
  const [nPageState, setPageState] = useState(0);
  const [isMinimized, setIsMinimized] = useState(false);
  const { setNextItem } = props;

  function readDistance(e, { value }) {
    let distance = value.replace(/[^0-9]/, ''); // Permite apenas números
    setDistance(distance);
  }

  function moveCar() {
    if (distance < 800) {
      anime({
        targets: '#car',
        translateX: distance,
        easing: 'linear',
      });
    } else {
      anime({
        targets: '#car',
        translateX: 800,
        easing: 'linear',
      });
    }
  }

  useEffect(() => {
    setNextItem('Velocidade');
  }, [setNextItem]);

  const arrPages = [
    <div
      className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`}
      key={Math.random()}
    >
      {!isMinimized && (
        <>
          <h1 className="presentationItem">Deslocamento</h1>
          <p className="presentationItem">
            O <b className="presentationItem">deslocamento</b> é definido como o comprimento entre o
            ponto onde se inica e o ponto onde termina o movimento de um corpo. Por exemplo, em uma
            viagem de carro entre as cidades do Rio de Janeiro e de São Paulo, o deslocamento é de
            cerca de 424 km.
            <br></br>
            <br></br>
            Através desse conceito conseguimos determinar o{' '}
            <b className="presentationItem">quão longe ou perto fisicamente</b> um corpo se moveu ao
            longo do espaço. Para melhor expressar esse conteito, podemos inserir o deslocamento a
            ser percorrido para que o carro alcance o fim da pista:
          </p>
          <Form size="small" className="presentationItem">
            <Form.Group>
              <Form.Input
                autoFocus
                placeholder="Digite sua distância"
                value={distance}
                onChange={readDistance}
              />
              <Form.Button className="presentationItemBtn" content="Percorrer" onClick={moveCar} />
            </Form.Group>
          </Form>
          <div className="container">
            <img id="road" className="presentationItem" src={roadT} />
            <img id="car" className="presentationItem" src={sideCarT} />
          </div>
          <br></br>
          <span className="white presentationItem">Unidades</span>
          <Popup
            wide
            inverted
            content={
              <p className="presentationItem">
                <br></br>O deslocamento é comumente representado pela letra{' '}
                <b className="presentationItem">
                  <i>S</i>
                </b>{' '}
                e a unidade padrão de tempo no Sistema Internacional é{' '}
                <b className="presentationItem">
                  metros (representada por <i>m</i> )
                </b>
                .<br></br>
                Outras unidades bastante utilizadas são quilômetros (km), decímetro (dm), ano-luz,
                etc...
                <br></br>
                <br></br>O símbolo{' '}
                <b className="presentationItem">
                  <i>ΔS</i>
                </b>{' '}
                representa a <b className="presentationItem">variação do deslocamento</b>. Por
                exemplo, para um carro que começou seu movimento no marco de 100 km e finalizou em
                um marco de 300 km, temos um ΔS de 200 km, ou 2000 metros.
              </p>
            }
            trigger={<Icon name="question circle outline" />}
          />
          <Arrows setPageState={setPageState} arrsToShow={[false, true]} />
        </>
      )}
    </div>,

    <div
      className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`}
      key={Math.random()}
    >
      <h1 className="presentationItem">Distância Percorrida vs Deslocamento</h1>
      <p className="presentationItem">
        Existe uma diferença entre o Deslocamento e a Distância Percorrida. O{' '}
        <b className="presentationItem">
          Deslocamento é definido pela distância entre o ponto inicial e o ponto final
        </b>
        , enquanto a <b className="presentationItem">Distância Percorrida é a soma dos módulos de cada deslocamento</b>,
        desconsiderando-se o sentido do movimento. Podemos entender melhor vendo o exemplo:
      </p>

      <div class="displacement__grid displacement__grid-template-rows">
	      <div class="displacement__gridItem">
          <Image src={distanciaPercorrida} class="displacement__gridImage" centered />
        </div>
	      <div class="displacement__gridItem">
          <div class="displacement__gridItem__paragraph">
            <p>Distância percorrida = 75km + 50 km</p>
            <p>125 km</p>
          </div>    
        </div>
	      <div class="displacement__gridItem">
          <Image src={deslocamento} class="displacement__gridImage" centered />
        </div>
	      <div class="displacement__gridItem">
          <div class="displacement__gridItem__paragraph">
            <p>Deslocamento = 75km - 50 km</p>
            <p>25 km</p>
          </div>
        </div>
      </div>     

      <Arrows setPageState={setPageState} arrsToShow={[true, true]} />
    </div>,

    <div className="presentation-base" key={Math.random()}>
      <h1 className="presentationItem">Distância e deslocamento</h1>
      <p className="presentationItem">
        Para melhor avaliarmos a diferença entre o Deslocamento e a Distância Percorrida, arraste a
        bolinha ao longo gráfico e visualize a relação entre as variáveis:
      </p>
      <BallPlot
        className="presentation-base__plot"
        type={'drag'}
        objRenderThose={{ displacement: true, distance: true }}
        bDrawBallPath={true}
      />

      <Arrows setPageState={setPageState} arrsToShow={[true, false]} />
    </div>,
  ];

  return arrPages[nPageState];
}

export default Displacement;

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, Image, Button } from 'semantic-ui-react';
import './subjects.css';
import anime from 'animejs/lib/anime.es.js';
import Navbar from '../components/navbar.jsx';
import LoadingSpinner from '../components/loadingSpinner';
import api from '../services/api';

function Subjects() {
  const [disciplines, setDisciplines] = React.useState([]);

  function greaterThanMediaQuery(){
    if(window.matchMedia("(max-width: 800px)").matches){
      return true;
    } else{
      return false;
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      await api
        .post('getAllDisciplines', {
          client_id: localStorage.getItem('client_id'),
        })
        .then((res) => {
          setDisciplines(res.data.disciplines);
        })
        .catch((err) => {
          console.log(err.message);
        });
    };
    fetchData();
  }, []);

  return (
    
    <div className="subjectBackground">
        <Navbar />
        {disciplines.length === 0 && <LoadingSpinner loading={true} className="restricted" />}
        <h1 className="mainHeader">O QUE VOCÊ QUER APRENDER HOJE?</h1>
        <div className="subjectsContainer">
          {disciplines.map((discipline, index) => {
            anime({
              targets: '#card' + index,
              scale: 1.1,
              duration: 350,
              delay: 1000,
              direction: 'alternate',
              easing: 'easeOutCubic',
            });
            return (
              <Card fluid={greaterThanMediaQuery()} id={'card' + index} color={discipline.color}>
                <Image
                  height="180vw"
                  className="img"
                  src={`data:image/jpeg;base64,${discipline.image}`}
                ></Image>
                <Card.Header>
                  <h2>{discipline.name}</h2>
                </Card.Header>
                <Card.Content className="mainParagraph">{discipline.description}</Card.Content>
                <Card.Content>
                  <Button
                    as={Link}
                    to={'/disciplina/' + discipline.id}
                    style={{ 'background-color': discipline.color }}
                    color={'blue'}
                  >
                    Começar
                  </Button>
                </Card.Content>
              </Card>
            );
          })}
        </div>
    </div>
  );
}

export default Subjects;

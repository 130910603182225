import React, { useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import Anime, { anime } from 'react-anime';


class EccentricityEllipse extends React.Component{

  constructor(props){
    super(props)
    
    this.state = {
      eccentricity: 0.5
    }
  }

  animate(){

    this.animation =     anime({
      targets: '#planeta',
      translateX: anime.path('#orbita-kepler-1')('x'),
      translateY: anime.path('#orbita-kepler-1')('y'),
      rotate: anime.path('#orbita-kepler-1')('angle'),
      easing: 'linear',
      duration: 4000,
      loop: true,
      autoplay: true
    });

    // this.animation.play()
  }

  componentDidMount(){
    this.animate();
  }

  componentDidUpdate(){
    this.animation.restart()
  }

  componentShouldUpdate(){
    this.animation.pause()
    return true
  }

  componentWillUnmount(){
    this.animation.pause()
  }

  render(){

    return(
      <>
      <div style={{width: '100%', height: '20%', textAlign: 'center'}}>
        <h1 style={{position: 'absolute', left: '2vw'}} className="mainFont">Excentricidade: {this.state.eccentricity.toFixed(2)}</h1>
        <svg  height="250" width="600" style={{position: 'absolute', bottom: '8vh', right: '3vw'}}>
          <ellipse id="orbita-kepler-1" cx="350" cy="110" rx={Math.sqrt(Math.pow(this.props.ry, 2)/(1 - Math.pow(this.state.eccentricity, 2)))} ry={this.props.ry}
          style={{fill:'none', stroke:'white', strokeWidth:2}} />
          <circle id="planeta" r="7" cx="10" cy="0" fill="dodgerBlue" stroke="dodgerBlue"/>
          <circle id="foco2" r="4" cx={350 + Math.sqrt(Math.pow(Math.sqrt(Math.pow(this.props.ry, 2)/(1 - Math.pow(this.state.eccentricity, 2))), 2) - Math.pow(this.props.ry, 2))} cy="110" fill="black" stroke="black"/>
          {this.state.eccentricity >= 0.05 && <text x={350 + Math.sqrt(Math.pow(Math.sqrt(Math.pow(this.props.ry, 2)/(1 - Math.pow(this.state.eccentricity, 2))), 2) - Math.pow(this.props.ry, 2))} y="140" fill="white">f2</text>}
          <circle id="sol" r="11" cx={350 - Math.sqrt(Math.pow(Math.sqrt(Math.pow(this.props.ry, 2)/(1 - Math.pow(this.state.eccentricity, 2))), 2) - Math.pow(this.props.ry, 2))} cy="110" fill="yellow" stroke="yellow"/>
          <text x={350 - Math.sqrt(Math.pow(Math.sqrt(Math.pow(this.props.ry, 2)/(1 - Math.pow(this.state.eccentricity, 2))), 2) - Math.pow(this.props.ry, 2))} y="90" fill="white">Sol</text>
          {this.state.eccentricity >= 0.05 && <text x={350 - Math.sqrt(Math.pow(Math.sqrt(Math.pow(this.props.ry, 2)/(1 - Math.pow(this.state.eccentricity, 2))), 2) - Math.pow(this.props.ry, 2))} y="140" fill="white">f1</text>}
        </svg>
      </div>
      <div className="presentationItem arrowBtnContainer">
      <Button
        icon
        labelPosition="left"
        onClick={() => {
          if(this.state.eccentricity >= 0.1){
            this.setState((prevState) => ({eccentricity: prevState.eccentricity - 0.1}))
            // setRx(Math.sqrt(Math.pow(ry, 2)/(1 - Math.pow(eccentricity, 2))));
          }
          // this.animate()
          // this.animation.restart()
        }}
      >
        Excentricidade
        <Icon name="minus" />
      </Button>
      <Button
        icon
        labelPosition="right"
        onClick={() => {
          // if(this.state.eccentricity === 0.95){ 
            // this.setState({eccentricity: 0.99})
            // setRx(Math.sqrt(Math.pow(ry, 2)/(1 - Math.pow(eccentricity, 2))));
          // };
          if(this.state.eccentricity < 0.850){ 
            this.setState((prevState) => ({eccentricity: prevState.eccentricity + 0.1}))
            // setRx(Math.sqrt(Math.pow(ry, 2)/(1 - Math.pow(eccentricity, 2))));
          };
          // this.animate()
          // this.animation.restart()
        }}
      >
        Excentricidade
        <Icon name="plus" />
      </Button>
    </div>
    </>
    )
  }

}


function FirstKeplerLawPage(props) {
  const [keplerLaw, setKeplerLaw] = useState(0);
  const [isMinimized, setIsMinimized] = useState(false);
  // const [eccentricity, setEccentricity] = useState(0.5);
  const [rx, setRx] = useState(100);
  const [ry, setRy] = useState(100)


  const standardObj = [
    {
      name: '1ª Lei de Kepler',
      keplerLaw: 1,
      comp: (
        <>
          <h1 className="presentationItem">1ª Lei de Kepler - Lei das Órbitas (1609)</h1>

          <p className="presentationItem">
            Johannes Kepler foi um astrônomo e matemático alemão que ficou bastante conhecido no
            século XVII pela formulação de três leis fundamentais da mecânica celeste, as quais
            viriam a ser conhecidas como as Três Leis de Kepler.
          </p>
          <p className="presentationItem">
            A Primeira Lei de Kepler diz que os{' '}
            <b className="presentationItem">planetas têm uma órbita em formato elíptico</b> ao redor
            do sol, sendo que esse <b className="presentationItem">ocupa um dos focos da elipse</b>{' '}
            - representados por <i>f1</i> e <i>f2</i>.
          </p>
          <p className="presentationItem">
            As elipses têm o valor de excentricidade entre 0 e 1, onde 0 significa uma elipse idêntica a um círculo e, 
            quanto mais próxima de 1, mais intensa é a forma elíptica. O mesmo acontece para as órbitas dos planetas. 
            Cada planeta tem seu próprio valor de excentricidade, porém, observa-se que os planetas do sistema solar 
            têm baixa excentricidade, com órbitas quase circulares.
          </p>
          <br></br>
          <EccentricityEllipse ry={ry} />


        </>
      ),
    },
  ];

  // useEffect(()=>{

  //   anime({
  //     targets: '#planeta',
  //     translateX: anime.path('#orbita-kepler-1')('x'),
  //     translateY: anime.path('#orbita-kepler-1')('y'),
  //     rotate: anime.path('#orbita-kepler-1')('angle'),
  //     easing: 'linear',
  //     duration: 4000,
  //     loop: true,
  //     autoplay: true
  //   })
  // })

  return (
    <div className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`}>
      {/* <div className="minMaxBar">
        <Icon className="icon" name='caret down' onClick={minimizeBar}/>
        <Icon className="icon" name='caret up' onClick={maximizeBar}/>
      </div> */}
      {!isMinimized && standardObj[keplerLaw].comp}
    </div>
  );
}

export default FirstKeplerLawPage;

/* eslint-disable react/display-name */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import { Button, Grid, Segment, Popup, Image, Divider } from 'semantic-ui-react';
import PlayDialogue from '../../../Common/compPlayDialogue';
import cinematics_boss from '../../../../images/Physics/Kinematics/cinematics_boss.PNG';
import cinematics_manager_black from '../../../../images/Physics/Kinematics/cinematics_manager_black.PNG';
import cinematics_manager_blue from '../../../../images/Physics/Kinematics/cinematics_manager_blue.PNG';
import cinematics_manager_green from '../../../../images/Physics/Kinematics/cinematics_manager_green.PNG';
import cinematics_manager_pink from '../../../../images/Physics/Kinematics/cinematics_manager_pink.PNG';
import cinematics_manager_red from '../../../../images/Physics/Kinematics/cinematics_manager_red.PNG';
import cinematics_manager_yellow from '../../../../images/Physics/Kinematics/cinematics_manager_yellow.PNG';
import SelectCar from '../../../../components/SelectCar';
import iconBlack from '../../../../images/Physics/Kinematics/iconBlack.PNG';
import iconBlue from '../../../../images/Physics/Kinematics/iconBlue.PNG';
import iconGreen from '../../../../images/Physics/Kinematics/iconGreen.PNG';
import iconPink from '../../../../images/Physics/Kinematics/iconPink.PNG';
import iconRed from '../../../../images/Physics/Kinematics/iconRed.PNG';
import iconYellow from '../../../../images/Physics/Kinematics/iconYellow.PNG';

import api from '../../../../services/api';

const SIZEICONS = '10%';

function Introdutory(props) {
  const [animationStep, setAnimationStep] = useState(0);
  const [selectedTeam, setselectedTeam] = useState(null);

  const additional_info = JSON.parse(sessionStorage.getItem('additional_info') || '');

  useEffect(() => {
    if (animationStep === 11) {
      props.setNextItem('Tempo');
    }
  }, [animationStep]);

  async function updateCars(name) {
    let listCars = [
      'Arara Real',
      'Jacaré Impiedoso',
      'Boto Veloz',
      'Falcão Negro',
      'Guará Rubro',
      'Capivara Furiosa',
    ];
    let storageCar = listCars.indexOf(name); // seleciona o carro conforme a posição do vetor
    let sortCar = [name];
    listCars.splice(storageCar, 1); // remove o carro selecionado pelo aluno da lista de carros possíveis
    listCars.sort(() => {
      return 0.5 - Math.random();
    }); // embaralha a lista de carros possíveis
    listCars.forEach((car) => {
      sortCar.push(car);
    });

    await api
      .post('updateAdditionalInfo', {
        challenge_id: sessionStorage.getItem('challenge_id'),
        client_id: localStorage.getItem('client_id'),
        additional_info: { teams: sortCar, choosed_team: sortCar[0] },
      })
      .then(async (res) => {
        sessionStorage.setItem(
          'additional_info',
          JSON.stringify({ teams: sortCar, choosed_team: sortCar[0] })
        );
        await api
          .post('earnAvatar', {
            client_id: localStorage.getItem('client_id'),
            avatar_code: getAvatarCode(sortCar[0]),
          })
          .then((res) => {});
      })
      .catch((err) => {});
  }

  function getAvatarCode(team) {
    switch (team) {
      case 'Guará Rubro':
        return 'KINEMATICS2';
      case 'Capivara Furiosa':
        return 'KINEMATICS1';
      case 'Jacaré Impiedoso':
        return 'KINEMATICS4';
      case 'Falcão Negro':
        return 'KINEMATICS5';
      case 'Arara Real':
        return 'KINEMATICS3';
      case 'Boto Veloz':
        return 'KINEMATICS6';
      default:
        return 'INVALID';
    }
  }

  function getPopup(props) {
    return (
      <Popup
        className="popupSelectCar"
        position="bottom center"
        flowing
        inverted
        hoverable
        trigger={<Image src={props.icon} width={SIZEICONS} circular />}
        content={
          <Segment placeholder className="segmentCar">
            <Grid columns={2} stackable textAlign="center">
              <Divider vertical></Divider>
              <Grid.Row verticalAlign="middle">
                <Grid.Column>
                  <SelectCar car={props.color} />
                </Grid.Column>
                <Grid.Column>
                  <p>{props.description} </p>
                  <Button
                    id="start-btn"
                    onClick={async () => {
                      setAnimationStep(animationStep + 1);
                      setselectedTeam(props.manager);
                      await updateCars(props.name);
                    }}
                    className={props.class}
                  >
                    {props.name}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        }
      />
    );
  }

  const arrPage = [
    <PlayDialogue
      key={0}
      buttons="Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text="Olá grande aluno, tudo bem com você? Meu nome é Isadora Corridas e sou a presidente do Fórmula Quest, a tradicional corrida automobilística do continente!"
      image={cinematics_boss}
      extraContent={''}
    />,

    <PlayDialogue
      key={1}
      buttons="Anterior/Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text="Ouvimos falar bastante dos seus conhecimentos e habilidade. Diferentes equipes lhe ofereceram um contrato como piloto, um importante cargo para disputar nosso troféu."
      image={cinematics_boss}
      extraContent={''}
    />,
  ];

  if (!additional_info || !additional_info.choosed_team) {
    console.log(arrPage.length);
    arrPage.push(
      <PlayDialogue
        key={2}
        buttons="Anterior"
        set={setAnimationStep}
        step={animationStep}
        mapAcess={''}
        text="Pronto para mais um desafio? Selecione sua equipe e vamos brigar por esse título!"
        image={cinematics_boss}
        extraContent={
          <div className="iconCar">
            <Image.Group>
              {getPopup({
                icon: iconRed,
                color: 'red',
                description:
                  'No Guará Rubro você tem que enfrentar as dificuldades. É ter uma mentalidade de CORAGEM, não tendo medo de arriscar com confiança para vencer e conquistar seus objetivos.',
                manager: cinematics_manager_red,
                class: 'selectCarButton selectCarButtonRed',
                name: 'Guará Rubro',
              })}

              {getPopup({
                icon: iconYellow,
                color: 'yellow',
                description:
                  'Os membros da Capivara Furiosa acreditam que tudo leva tempo, sendo necessário PERSISTÊNCIA. Se têm vontade de algo, continuam tentando, uma hora vai acontecer.',
                manager: cinematics_manager_yellow,
                class: 'selectCarButton selectCarButtonYellow',
                name: 'Capivara Furiosa',
              })}

              {getPopup({
                icon: iconBlack,
                color: 'black',
                description:
                  'A equipe do Falcão Negro tem CONFIANÇA que irá conseguir vencer, de um jeito ou de outro. Seus membros sempre encaram o que vem pela frente com positividade.',
                manager: cinematics_manager_black,
                class: 'selectCarButton selectCarButtonBlack',
                name: 'Falcão Negro',
              })}

              {getPopup({
                icon: iconPink,
                color: 'pink',
                description:
                  'É na CRIATIVIDADE que a equipe de Boto Veloz se diferencia. Seus membros sempre mostram grandes ideias e inovam ao buscar soluções para seus desafios.',
                manager: cinematics_manager_pink,
                class: 'selectCarButton selectCarButtonPink',
                name: 'Boto Veloz',
              })}

              {getPopup({
                icon: iconGreen,
                color: 'green',
                description:
                  'No Jacaré Impiedoso, você tem que ser ASSERTIVO. É falar o que pensa, com clareza, objetividade e respeito. Papo reto, olho no olho. É também saber ouvir e considerar a opinião dos outros.',
                manager: cinematics_manager_green,
                class: 'selectCarButton selectCarButtonGreen',
                name: 'Jacaré Impiedoso',
              })}

              {getPopup({
                icon: iconBlue,
                color: 'blue',
                description:
                  "'Tudo que vale a pena é difícil de conquistar', dizem os membros da Arara Real. Eles não desistem ao se deparar com obstáculos, sendo a DETERMINAÇÃO sua característica.",
                manager: cinematics_manager_blue,
                class: 'selectCarButton selectCarButtonBlue',
                name: 'Arara Real',
              })}

              <Popup
                position="bottom center"
                inverted
                hoverable
                size="mini"
                trigger={<Image src="" width="60" circular />}
                content="Hello. This is a huge popup"
              />
            </Image.Group>
          </div>
        }
      />
    );
    arrPage.push(
      <PlayDialogue
        key={3}
        buttons="Anterior"
        set={setAnimationStep}
        step={animationStep}
        mapAcess={''}
        text="Ótima escolha! Vamos conhecer sua nova equipe."
        image={cinematics_boss}
        extraContent={''}
      />
    );
    console.log(arrPage.length);
  } else {
    arrPage.push(
      <PlayDialogue
        key={2}
        buttons="Anterior"
        set={setAnimationStep}
        step={animationStep}
        mapAcess={''}
        text={'A equipe ' + additional_info.choosed_team + ' está muito ansiosa para recebê-lo!'}
        image={cinematics_boss}
        extraContent={''}
      />
    );
  }

  return arrPage[animationStep];
}

export default Introdutory;

import React, { useState, useEffect } from 'react';
import { Button, Icon, Image, Segment } from 'semantic-ui-react';

function PresentationComp(props) {
  const [isMinimized, setIsMinimized] = useState(false);

  function minimizeBar() {
    props.minimizeFunction(true);
    setIsMinimized(true);
  }

  function maximizeBar() {
    setIsMinimized(false);
  }

  return (
    <div
      className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`}
      key={Math.random()}
    >
      {/* <div className="minMaxBar">
        <Icon className="icon" name="caret down" onClick={minimizeBar} />
        <Icon className="icon" name="caret up" onClick={maximizeBar} />
      </div> */}
      {!isMinimized && (
        <>
          <div className="presentationItem imgParagraph">
            <p className="presentationItem ">{props.text1}</p>
            <img width="25%" src={props.img} alt="" />
          </div>
          <div className="presentationItem imgParagraph">
            <p className="presentationItem ">{props.text2}</p>
          </div>
        </>
      )}
    </div>
  );
}

export default PresentationComp;

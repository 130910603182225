import * as BABYLON from '@babylonjs/core';
import '@babylonjs/loaders';
import '@babylonjs/inspector';

import Scenario from './Scenario';
import PointsTraj from './PointsTraj';
import PointsTraj2 from './PointsTraj2';
import scoreManager from '../../../../managers/ScoreManager';

function Setup(props) {
  let scene = props.scene;

  const TOTAL_DIST = 4000;
  const ACC_CAR = 8;
  const CAMERA_INIT_POSITION = new BABYLON.Vector3(120, 10, 80);

  // lista de carros
  let localCars = [
    {
      name: './scenes/Física/Cinemática/CarBlue.glb',
      color: 'blue',
      logo: 'textures/Física/Cinemática/Velocidade Média/logoBlue.PNG',
      equipe: 'Arara Real',
    },
    {
      name: './scenes/Física/Cinemática/CarGreen.glb',
      color: 'green',
      logo: 'textures/Física/Cinemática/Velocidade Média/logoGreen.PNG',
      equipe: 'Jacaré Impiedoso',
    },
    {
      name: './scenes/Física/Cinemática/CarPink.glb',
      color: 'pink',
      logo: 'textures/Física/Cinemática/Velocidade Média/logoPink.PNG',
      equipe: 'Boto Veloz',
    },
    {
      name: './scenes/Física/Cinemática/CarBlack.glb',
      color: 'black',
      logo: 'textures/Física/Cinemática/Velocidade Média/logoBlack.PNG',
      equipe: 'Falcão Negro',
    },
    {
      name: './scenes/Física/Cinemática/CarRed.glb',
      color: 'red',
      logo: 'textures/Física/Cinemática/Velocidade Média/logoRed.PNG',
      equipe: 'Guará Rubro',
    },
    {
      name: './scenes/Física/Cinemática/CarYellow.glb',
      color: 'yellow',
      logo: 'textures/Física/Cinemática/Velocidade Média/logoYellow.PNG',
      equipe: 'Capivara Furiosa',
    },
  ];

  let orderCars = scoreManager.objSpecificScore.physics.kinematics.additionalInfo.teams;
  let listCars = [];
  orderCars.forEach((car) => {
    let atualCar = localCars.filter((el) => el.equipe === car)[0];
    listCars.push(atualCar);
  });

  scene.time = 0;
  scene.cars = [];
  scene.points = [];
  scene.points2 = [];
  scene.pointsExplosion = [];
  scene.cameraOrientation = 0;
  scene.responses = [0, 0];
  scene.volante = [];
  // scene.acelerador = [];
  scene.rodas = [];
  // BABYLON.DebugLayer.InspectorURL = 'https://preview.babylonjs.com/inspector/babylon.inspector.bundle.js';
  // scene.debugLayer.show({
  // });

  scene.camera = new BABYLON.FreeCamera('camera', CAMERA_INIT_POSITION, scene);
  const canvas = scene.getEngine().getRenderingCanvas();
  scene.camera.attachControl(canvas, true);

  let light = new BABYLON.HemisphericLight('light', new BABYLON.Vector3(0, 1, 0), scene);
  light.position = new BABYLON.Vector3(0, 5, 0);
  light.intensity = 0.5;

  // scene.soundCrowd = new BABYLON.Sound("crowdSound", "sounds/crowdVoices.mp3", scene, null, { loop: true, autoplay: true, volume:0.4 });
  scene.soundRaceMusic = new BABYLON.Sound('raceMusic', 'sounds/musicStage3.mp3', scene, null, {
    loop: true,
    autoplay: false,
    volume: 0.4,
  });

  Scenario(scene);

  // Trajetória
  PointsTraj.forEach((e) => {
    scene.points.push(new BABYLON.Vector3(3 * e[0], 1, e[1] - 142));
  });
  PointsTraj2.forEach((e) => {
    scene.points2.push(new BABYLON.Vector3(3 * e[0], 1, e[1] - 142));
  });
  scene.points.reverse();
  scene.points2.reverse();

  let path3d = new BABYLON.Path3D(scene.points);
  let normals = path3d.getNormals();

  // Draw the curve
  // var track = BABYLON.MeshBuilder.CreateLines('track', {points: scene.points}, scene);
  // track.color = new BABYLON.Color3(0, 0, 0);
  // var track = BABYLON.MeshBuilder.CreateLines('track', {points: scene.points2}, scene);
  // track.color = new BABYLON.Color3(0, 0, 0);

  let storageCar = 0;
  let initPosition = 1;
  let carLocalFile = listCars[storageCar];
  listCars.splice(storageCar, 1);
  listCars.splice(initPosition, 0, carLocalFile);
  // listCars[initPosition].name = './scenes/Física/Cinemática/MRUV/cockpit.glb';

  listCars.length = 2;
  for (let i = 0; i < listCars.length; i++) {
    BABYLON.SceneLoader.ImportMesh(
      '',
      listCars[i].name,
      '',
      scene,
      function (meshes, particleSystems, skeleton, animationGroups) {
        let car = meshes[0];
        car.active = true;
        car.dist = 0;
        car.track = i % 2;
        car.changeTrack = 0;
        car.isStudent = listCars[i].color === carLocalFile.color ? 1 : 0;
        car.vel = 0;
        car.acc = ACC_CAR - 2 * car.isStudent;
        car.color = listCars[i].color;
        car.logo = listCars[i].logo;
        car.equipe = listCars[i].equipe;

        let track = [
          Math.floor(
            (((TOTAL_DIST + car.dist) % TOTAL_DIST) * (scene.points.length - 1)) / TOTAL_DIST
          ),
          Math.floor(
            (((TOTAL_DIST + car.dist) % TOTAL_DIST) * (scene.points2.length - 1)) / TOTAL_DIST
          ),
        ];

        let pointsTrack = [
          scene.points[track[0]].x,
          scene.points2[track[1]].x,
          scene.points[track[0]].z,
          scene.points2[track[1]].z,
        ];

        car.position.x = pointsTrack[0] + (pointsTrack[1] - pointsTrack[0]) * car.track;
        car.position.y = 0;
        car.position.z = pointsTrack[2] + (pointsTrack[3] - pointsTrack[2]) * car.track;

        let theta = Math.acos(BABYLON.Vector3.Dot(new BABYLON.Vector3(0, 0, -1), normals[0]));
        var dir = BABYLON.Vector3.Cross(new BABYLON.Vector3(0, 0, -1), normals[0]).y;
        car.rotation = new BABYLON.Vector3(0, dir !== 0 ? (dir * theta) / Math.abs(dir) : theta, 0);
        let scaling = 1;
        car.scaling = new BABYLON.Vector3(scaling, scaling, car.isStudent ? -scaling : scaling);

        if (car.isStudent) {
          scene.cameraOrientation = car.rotation.y;
          scene.camera.position = new BABYLON.Vector3(
            car.position.x - 20,
            car.position.y + 20,
            car.position.z + 50 * Math.sin(scene.cameraOrientation)
          );
          scene.camera.setTarget(
            new BABYLON.Vector3(
              car.position.x + 2.5 * Math.cos(scene.cameraOrientation),
              car.position.y + 0.2,
              car.position.z - 2.5 * Math.sin(scene.cameraOrientation)
            )
          );

          // for (var j = 0; j < 11; j++) {
          //   let m = scene.getMeshByName('Steering Wheel.001_primitive' + j);
          //   // scene.volante.push(m);
          // }

          // for (var j = 0; j < 2; j++){
          //     let m = scene.getMeshByID('Turbo Handle_primitive' + j);
          //     scene.acelerador.push(m);
          // }
        } else {
          for (var j = 0; j < 3; j++) {
            let m = scene.getMeshesByID('Front tire + Wheel.002_primitive' + j);
            m.forEach((e) => {
              scene.rodas.push(e);
            });
          }
          for (var k = 0; k < 6; k++) {
            let m = scene.getMeshesByID('Rear Tire + Wheel.002_primitive' + k);
            m.forEach((e) => {
              scene.rodas.push(e);
            });
          }
        }
        car.initDist = car.dist;
        car.initPosition = car.position;
        car.initRotation = car.rotation;
        car.initVel = car.vel;
        car.initAcc = car.acc;
        car.initTrack = car.track;
        car.explode = false;

        scene.cars.push(car);
      }
    );
  }
}

export default Setup;

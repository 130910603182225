/* eslint-disable react/display-name */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import { Image } from 'semantic-ui-react';
import PlayDialogue from '../../../Common/compPlayDialogue';
import species_octa from '../../../../images/Physics/Gravitation/species_octa.PNG';
import species_boter from '../../../../images/Physics/Gravitation/species_boter.PNG';
import species_levitoner from '../../../../images/Physics/Gravitation/species_levitoner.PNG';
import species_emierres from '../../../../images/Physics/Gravitation/species_emierres.PNG';
import fig1 from '../../../../images/Physics/Gravitation/exChallengUnivGravA.PNG';

function ChallengeExplanation(props) {
  const [animationStep, setAnimationStep] = useState(0);
  const additionalInfo = JSON.parse(sessionStorage.getItem('additional_info'));

  function getImage() {
    switch (additionalInfo.specie) {
      case 'Octawabeter':
        return species_octa;
      case 'Olhoboter':
        return species_boter;
      case 'Levitoner':
        return species_levitoner;
      case 'Emierres':
        return species_emierres;
      default:
        break;
    }
  }

  useEffect(() => {
    if (animationStep === 11) {
      props.setNextItem('Tempo');
    }
  }, [animationStep]);

  const arrPage = [
    <PlayDialogue
      key={0}
      buttons="Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text={
        'Estamos chegando próximo ao planeta Quest, porém, percebemos que estamos sendo atraídos por sua lua, fazendo com que percamos o destino original.'
      }
      image={getImage()}
      extraContent={''}
    />,

    <PlayDialogue
      key={1}
      buttons="Anterior/Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text="Nossos computadores indicam que estamos a 316000000 km do planeta, que tem uma massa de 6E24 kg, porém, este exerce uma força de apenas 801 N sobre nossa nave."
      image={getImage()}
      extraContent={''}
    />,

    <PlayDialogue
      key={2}
      buttons="Anterior/Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text="Ao mesmo tempo, a lua do planeta Quest nos atrai com uma força de valor desconhecido. Mesmo com sua massa sendo de valor 6E22, sua distância está próxima, a apenas 10000000 km."
      image={getImage()}
      extraContent={''}
    />,

    <PlayDialogue
      key={3}
      buttons="Anterior/Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text="Sabendo que a lua se encontra à 45º da reta que une nossa nave ao planeta, precisamos descobrir qual o módulo e direção da força que devemos aplicar nos motores de nossa nave para que a força resultante nos leve ao planeta Quest."
      image={getImage()}
      extraContent={''}
    />,

    <PlayDialogue
      key={4}
      buttons="Anterior/Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text=""
      image={getImage()}
      extraContent={
        <>
          <p>Para melhor exemplificar a situação observe o mapa:</p>
          <Image src={fig1} centered fluid />
        </>
      }
    />,

    <PlayDialogue
      key={5}
      buttons="Anterior/Começar Desafio"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      setChallengeStarted={props.setChallengeStarted}
      setIsChallengeOpened={props.setIsChallengeOpened}
      text="Está preparado para esse desafio? Consegue nos salvar da gravidade da lua e nos levar em segurança ao nosso destino?"
      image={getImage()}
      extraContent={''}
      challenge = {props.challenge}
    />,
  ];

  return arrPage[animationStep];
}

export default ChallengeExplanation;

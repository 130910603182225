import * as BABYLON from '@babylonjs/core';

import Scenario from './Scenario';

import '@babylonjs/loaders';
import '@babylonjs/inspector';

function Setup(props) {
  let scene = props.scene;

  const TOTAL_TIME = 3 * 60;
  const ROCKET_INIT_POSITION_Y = 5;
  const EXAUST_ROCKET_START_SCALING = 0.01;

  const CAMERA_INIT_POSITION = new BABYLON.Vector3(-120, 15, 20);
  scene.camera = new BABYLON.FreeCamera('camera', CAMERA_INIT_POSITION, scene);
  const canvas = scene.getEngine().getRenderingCanvas();
  scene.camera.attachControl(canvas, true);
  scene.cameraOrientation = -Math.PI / 2;

  scene.timeLeft = TOTAL_TIME;
  scene.planets = [];
  scene.responses = ['Sem resposta', 'Sem resposta', 0, 0];
  scene.running = 0;
  scene.countStart = 0;
  scene.restart = 0;

  let rocketPosition;
  scene.rocket = null;

  Scenario(scene);

  // importação de planetas
  let listPlanets = [
    {
      name: './scenes/Física/Gravitação/sun.glb',
      dist: 3000,
      angle: 0,
      height: 200,
      size: 50,
      period: 1,
      isStarting: false,
      isEnding: false,
      hasMoon: false,
      isMoon: false
    },
    {
      name: './scenes/Física/Gravitação/planetD.glb',
      dist: 4000,
      angle: (-1 * Math.PI) / 8,
      height: 10,
      size: 30,
      period: 51,
      isStarting: false,
      isEnding: false,
      hasMoon: false,
      isMoon: false
    },
    {
      name: './scenes/Física/Gravitação/planetA.glb',
      dist: 4000,
      angle: (0 * Math.PI) / 8,
      height: 5060,
      size: 40,
      period: 1040,
      isStarting: false,
      isEnding: false,
      hasMoon: false,
      isMoon: false
    },
    {
      name: './scenes/Física/Gravitação/moon.glb',
      dist: 3000,
      angle: (-2 * Math.PI) / 8,
      height: 2000,
      size: 150,
      period: 20,
      isStarting: false,
      isEnding: false,
      hasMoon: false,
      isMoon: true
    },
  ];

  // listPlanets.length = 6;
  for (let i = 0; i < listPlanets.length; i++) {
    BABYLON.SceneLoader.ImportMesh(
      '',
      listPlanets[i].name,
      '',
      scene,
      function (meshes, particleSystems, skeleton, animationGroups) {
        let planet = meshes[0];

        // planet variables
        planet.active = true;

        planet.position.x = listPlanets[i].dist * Math.cos(listPlanets[i].angle);
        planet.position.z = listPlanets[i].dist * Math.sin(listPlanets[i].angle);
        planet.position.y = listPlanets[i].height;

        planet.rotation = new BABYLON.Vector3(0, 0, 0);
        planet.scaling = new BABYLON.Vector3(
          listPlanets[i].size,
          listPlanets[i].size,
          listPlanets[i].size
        );

        planet.dist = listPlanets[i].dist;
        planet.angle = listPlanets[i].angle;
        planet.period = listPlanets[i].period;
        planet.isStarting = listPlanets[i].isStarting;
        planet.isEnding = listPlanets[i].isEnding;
        planet.hasMoon = listPlanets[i].hasMoon;
        planet.isMoon = listPlanets[i].isMoon;

        // initial planet variables
        planet.initPosition = planet.position;
        planet.initAngle = planet.angle;
        planet.initPeriod = planet.period;

        scene.planets.push(planet);

      }
    );
  }

// importação do foguete
  let listRocket = {
    name: './scenes/Física/Gravitação/rocket.glb',
    position: new BABYLON.Vector3(
      0,
      5,
      0
    ),
  };

  BABYLON.SceneLoader.ImportMesh(
    '',
    listRocket.name,
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let rocket = meshes[0];
      // animationGroups.forEach((x) => {
      //   x.start(true);
      // });
      rocket.active = true;
      rocket.position = listRocket.position;
      rocket.rotation = new BABYLON.Vector3(0, -Math.PI, 0);
      rocket.scaling = new BABYLON.Vector3(1.6, 1.6, 1.6);

      rocket.initPosition = new BABYLON.Vector3(0, ROCKET_INIT_POSITION_Y, 0);
      rocket.initRotation = new BABYLON.Vector3(0, -Math.PI, 0);
      rocket.vel = listRocket.vel;
      rocket.explode = false;

      scene.rocket = rocket;
      scene.camera.setTarget(rocket.position);
    }
  );

  // importação do satélite
  let listSatellite = {
    name: './scenes/Física/Gravitação/satellite.glb',
    dist: 3600,
    angle: (60 * Math.PI) / 180,
    size: 10,
    period: 2800,
  };

  BABYLON.SceneLoader.ImportMesh(
    '',
    listSatellite.name,
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let satellite = meshes[0];

      satellite.position.z = listSatellite.dist * Math.cos(listSatellite.angle);
      satellite.position.y = listSatellite.dist * Math.sin(listSatellite.angle) - 1000;

      satellite.rotation = new BABYLON.Vector3(0, 0, 0);
      satellite.scaling = new BABYLON.Vector3(
        listSatellite.size,
        listSatellite.size,
        listSatellite.size
      );

      satellite.dist = listSatellite.dist;
      satellite.angle = listSatellite.angle;
      satellite.period = listSatellite.period;

      // initial satellite variables
      satellite.initPosition = satellite.position;
      satellite.initAngle = satellite.angle;
      satellite.initPeriod = satellite.period;

      scene.satellite = satellite;
    }
  );

  // importação do astronauta
  let listAstronaut = {
    name: './scenes/Física/Gravitação/eggnault.glb',
    position: new BABYLON.Vector3(
      0,
      5,
      0
    ),
    scaling: 0.01
  };

  BABYLON.SceneLoader.ImportMesh(
    '',
    listAstronaut.name,
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let astronaut = meshes[0];
      animationGroups.forEach((x) => {
        x.start(true);
      });
      astronaut.active = true;
      astronaut.position = listAstronaut.position;
      astronaut.rotation = new BABYLON.Vector3(0, -Math.PI/2, 0);
      astronaut.scaling = new BABYLON.Vector3(listAstronaut.scaling, listAstronaut.scaling, listAstronaut.scaling);

      astronaut.initPosition = new BABYLON.Vector3(0, ROCKET_INIT_POSITION_Y, 0);
      astronaut.initRotation = new BABYLON.Vector3(0, -Math.PI/2, 0);
      astronaut.initScaling = astronaut.scaling;
      astronaut.direction = 'up';

      scene.astronaut = astronaut;
    }
  );

  // importação do motor
  let listExaustRocket = {
    name: './scenes/Física/Gravitação/exaustRocket.gltf',
    position: new BABYLON.Vector3(
      0,
      ROCKET_INIT_POSITION_Y - 5,
      0
    ),
  };

  BABYLON.SceneLoader.ImportMesh(
    '',
    listExaustRocket.name,
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let exaustRocket = meshes[0];
      // animationGroups.forEach((x) => {
      //   x.start(true);
      // });
      exaustRocket.active = true;
      exaustRocket.position = listExaustRocket.position;
      exaustRocket.rotation = new BABYLON.Vector3(0, -Math.PI, 0);
      exaustRocket.scaling = new BABYLON.Vector3(1.6, EXAUST_ROCKET_START_SCALING, 1.6);

      exaustRocket.initPosition = new BABYLON.Vector3(0, ROCKET_INIT_POSITION_Y - 5, 0);
      exaustRocket.initRotation = new BABYLON.Vector3(0, -Math.PI, 0);
      exaustRocket.vel = listExaustRocket.vel;
      exaustRocket.explode = false;

      scene.exaustRocket = exaustRocket;
    }
  );
}


export default Setup;

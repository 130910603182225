import React from 'react';
import { Segment, Card } from 'semantic-ui-react';

import './habilities.css';
import './myProfile.scss';
import './profile.scss';

import scoreManager from '../../managers/ScoreManager';
import CompSubject from './compSubject';

import citologia from '../../images/Biology/Common/citologia.png';
import genetica from '../../images/Biology/Common/genetica.png';
import reinoAnimal from '../../images/Biology/Common/reinoAnimal.png';

function Biology() {
  // var sm = scoreManager.objSpecificScore.physics;

  return (
    <>
      <div className="profileSubItem">
        <br></br>
        <h1 className="presentationItem">Biologia</h1>
        <Segment
          style={{
            overflow: 'auto',
            maxHeight: '60vh',
            color: 'white',
            backgroundColor: '#303841',
          }}
        >
          <Card.Group itemsPerRow={4}>
            <CompSubject name="Citologia" image={citologia} />
            <CompSubject name="Genética" image={genetica} />
            <CompSubject name="Reino Animal" image={reinoAnimal} />
          </Card.Group>
        </Segment>
      </div>
    </>
  );
}

export default Biology;

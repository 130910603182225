import React, { useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { Slider } from 'react-semantic-ui-range';

import './babylonReactComp.scss';

function ResponseSlider(props) {
  const [value, setValue] = useState(0);

  const settings = {
    start: 0,
    min: props.min,
    max: props.max,
    step: props.step,
    onChange: (value) => {
      setValue(value);
    },
  };

  const textColor = props.color === 'white' ? 'sliderPanel__valueWhite' : 'sliderPanel__value';

  return (
    <>
      {props.condition && (
        <div className="sliderPanel__firstSquare">
          <Slider value={value > props.max ? props.max : value} color="black" settings={settings} />
          <p className={textColor}>
            {' '}
            {value} {props.unity}{' '}
          </p>
          <div className="sliderPanel__button">
            <Button
              icon
              labelPosition="left"
              onClick={() => {
                props.function(value);
              }}
            >
              <Icon name="check" />
              Responder
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default ResponseSlider;

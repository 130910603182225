import React from 'react';
import RememberVariablesComponent from '../../../../components/MapComponents/rememberVariablesComponent';

function RememberVariables(props) {
  return (
    <RememberVariablesComponent
      paragraph1="Este desafio é composto por duas etapas:"
      paragraph2={null}
      paragraph3={null}
      sub={[
        {
          paragraph:
            '1) Iniciando a corrida com o carro parado, descubra qual a a aceleração para alcançar a velocidade máxima de 288 km/h percorrendo uma distância de 500 metros.',
        },
        {
          paragraph:
            '2) Com uma velocidade de 288 km/h, devemos descobrir em quantos metros paramos o carro com uma desaceleração de 16 m/s². Assim, devemos descobir em que momento da corrida iremos frear para que chguemos na linha de chegada com velocidade igual à 0.',
        },
        { paragraph: null },
        { paragraph: null },
      ]}
    />
  );
}

export default RememberVariables;

import React, { useState, useEffect } from 'react';
import { Button, Divider, Icon } from 'semantic-ui-react';
import { MathComponent } from 'mathjax-react';
import Anime, { anime } from 'react-anime';
// import anime from "animejs/lib/anime.es.js";
import roadT from '../../../../images/Physics/Kinematics/roadT.png';
import sideCarRedT from '../../../../images/sideCarRedT.PNG';
import sideCarGreenT from '../../../../images/sideCarGreenT.PNG';
import board1ms from '../../../../images/board1ms.png';
import board2ms from '../../../../images/board2ms.png';

function MRUVPage(props) {
  const [mruvStep, setMruvStep] = useState(0);
  const [isMinimized, setIsMinimized] = useState(false);


  useEffect(() => {
    // anime({
    //   targets: ['#side-car-mruv', ' .velTextMruv'],
    //   translateX:
    //     {value: '40vw', duration: 2250, easing: 'easeInCubic'},
    //   loop: true,
    //   endDelay: 2300
    // })
    anime({
      targets: '.velTextMruv',
      loop: true,
      endDelay: 2300,
      innerText: {
        value: ['1 m/s', '2 m/s'],
        duration: 1000,
        duration: 1000,
        easing: 'linear',
        delay: 1000,
        endDelay: 2550,
        round: 10,
      },
      translateX: { value: '40vw', duration: 2250, easing: 'easeInCubic' },
    });

    anime({
      targets: '#distance-mruv2-1',
      opacity: [0, 1],
      easing: 'easeInQuint',
      delay: 500,
      duration: 1000,
      endDelay: 4000,
      loop: true,
    });
    anime({
      targets: '#distance-mruv2-2',
      opacity: [0, 1],
      easing: 'easeInQuint',
      delay: 2000,
      duration: 1000,
      endDelay: 2500,
      loop: true,
    });
    anime({
      targets: '#distance-mruv2-3',
      opacity: [0, 1],
      easing: 'easeInQuint',
      delay: 3500,
      duration: 1000,
      endDelay: 1000,
      loop: true,
    });
    anime({
      targets: '#distance-mruv2-4',
      opacity: [0, 1],
      easing: 'easeInQuint',
      delay: 500,
      duration: 1000,
      endDelay: 4000,
      loop: true,
    });
    anime({
      targets: '#distance-mruv2-5',
      opacity: [0, 1],
      easing: 'easeInQuint',
      delay: 2000,
      duration: 1000,
      endDelay: 2500,
      loop: true,
    });
    anime({
      targets: '#distance-mruv2-6',
      opacity: [0, 1],
      easing: 'easeInQuint',
      delay: 3500,
      duration: 1000,
      endDelay: 1000,
      loop: true,
    });

    anime({
      targets: '#vel-text-mruv-2',
      endDelay: 2300,
      loop: true,
      innerText: [
        {
          value: ['v=1.0 m/s', 'v=2.0 m/s'],
          delay: 500,
          duration: 1000,
          easing: 'linear',
          round: 10,
        },
        {
          value: ['v=2.0 m/s', 'v=3.0 m/s'],
          delay: 500,
          duration: 1000,
          easing: 'linear',
          round: 10,
        },
        {
          value: ['v=3.0 m/s', 'v=4.0 m/s'],
          delay: 500,
          duration: 1000,
          easing: 'linear',
          round: 10,
          endDelay: 1000,
        },
      ],
    });
    anime({
      targets: '#time-text-mruv-2',
      endDelay: 2300,
      loop: true,
      innerText: [
        {
          value: ['Tempo: 0 s', 'Tempo: 1 s'],
          delay: 500,
          duration: 1000,
          easing: 'linear',
          round: 10,
        },
        {
          value: ['Tempo: 1 s', 'Tempo: 2 s'],
          delay: 500,
          duration: 1000,
          easing: 'linear',
          round: 10,
        },
        {
          value: ['Tempo: 2 s', 'Tempo: 3 s'],
          delay: 500,
          duration: 1000,
          easing: 'linear',
          round: 10,
          endDelay: 1000,
        },
      ],
    });
  }, [mruvStep]);

  const standardObj = [
    {
      name: 'MRUV',
      mruvStep: 1,
      comp: (
        <>
          <h1 className="presentationItem">MRUV</h1>

          <p className="presentationItem">
            Na seção anterior observamos os{' '}
            <b className="presentationItem">efeitos da aceleração para a velocidade</b> de um corpo.
            Porém, quais são os <b className="presentationItem">impactos para o deslocamento</b>?
            <br></br>
            Vamos estudar uma situação em que um carro tenha uma velocidade de 1m/s e uma acaleração
            de 1m/s². É fácil perceber que após um segundo ele terá uma velocidade de 2 m/s, pois
            sabemos que:
            <MathComponent tex={String.raw`V = V_{0} + a*t`} />
            <MathComponent tex={String.raw`V = 1 m/s + 1 m/s^2*1s`} />
            <MathComponent tex={String.raw`V = 2 m/s`} />
            Vamos assistir em câmera lenta o que ocorre nesse 1 segundo:
          </p>
          <div className="container">
            <img id="road-mruv" className="presentationItem" src={roadT} />
            <div style={{ width: '100vw', height: '10vw', zIndex: 150, position: 'absolute' }}>
              <Anime
                loop={true}
                endDelay={2300}
                translateX={{ value: '40vw', duration: 2250, easing: 'easeInCubic' }}
              >
                <img id="side-car-mruv" className="presentationItem" src={sideCarGreenT} />
              </Anime>
              <p style={{ color: 'white' }} className="velTextMruv mainFont"></p>
            </div>
            <img id="board1-mruv" className="presentationItem" src={board1ms} />
            <Anime
              opacity={[0, 1]}
              easing="easeInQuint"
              delay={1500}
              duration={1050}
              endDelay={2000}
              loop={true}
            >
              <div className="distanceMruv">
                <Divider horizontal>
                  <span className="mainFont">1,5 m</span>
                </Divider>
              </div>
            </Anime>
            <img id="board2-mruv" className="presentationItem" src={board2ms} />
          </div>

          <p
            style={{ position: 'absolute', left: '2vw' }}
            id="error-message"
            className="secondaryFont"
          >
            {}
          </p>
          <p className="presentationItem">
            <br></br>
            Podemos perceber que enquanto o carro se locomove, sua velocidade varia de 1m/s para
            2m/s, tudo ocorrendo num tempo de 1 segundo. Também percebemos que o carro deslocou uma
            distância de 1,5 metros. Mas como chegamos nesse resultado de{' '}
            <b className="presentationItem">deslocamento</b>?
          </p>

          <div className="presentationItem arrowBtnContainer">
            <Button
              icon
              labelPosition="right"
              onClick={() => {
                setMruvStep(mruvStep + 1);
              }}
            >
              Próximo
              <Icon name="right arrow" />
            </Button>
          </div>
        </>
      ),
    },
    {
      name: 'Aceleração',
      mruvStep: 2,
      comp: (
        <>
          <h1 className="presentationItem">MRUV</h1>
          <p className="presentationItem">
            Conforme a situação anterior, conseguimos perceber que à medida que o tempo passa, o
            <b className="presentationItem">
              {' '}
              carro com a aceleração passará a andar com uma velocidade maior
            </b>
            , o que resultará em um
            <b className="presentationItem"> maior deslocamento</b> naquele intervalo de tempo.
            <br></br>
            <br></br>
            Vamos observar dois carros com uma velocidade inicial de 1m/s; um deles com uma
            aceleração de 1 m/s² e outro com aceleração nula. Abaixo podemos ver o deslocamento de
            cada um deles por instante de tempo:
          </p>
          <div className="container">
            {/* <img id="road-mruv" className="presentationItem" src={roadT} /> */}
            <Anime
              loop={true}
              endDelay={1000}
              easing="linear"
              translateX={[
                { value: '12vw', duration: 1000, delay: 500 },
                { value: '24vw', duration: 1000, delay: 500 },
                { value: '36vw', duration: 1000, delay: 500 },
              ]}
            >
              <img id="side-car-mruv2-1" className="presentationItem" src={sideCarRedT} />
              <p style={{ color: 'white', position: 'absolute', left: '5%' }} className="mainFont">
                v=1 m/s
              </p>
              <p style={{ color: 'white', position: 'absolute', left: '12%' }} className="mainFont">
                a=0
              </p>
            </Anime>
            <Anime
              loop={true}
              endDelay={1000}
              easing="cubicBezier(0.150, 0.305, 0.850, 1.040)"
              translateX={[
                { value: '15vw', duration: 1000, delay: 500 },
                { value: '32vw', duration: 1000, delay: 500 },
                { value: '52vw', duration: 1000, delay: 500 },
              ]}
            >
              <img id="side-car-mruv2-2" className="presentationItem" src={sideCarGreenT} />
              <p
                id="vel-text-mruv-2"
                style={{
                  color: 'white',
                  position: 'absolute',
                  left: '5%',
                  bottom: '1px',
                  top: '7vh',
                }}
                className="mainFont"
              >
                v=1 m/s
              </p>
              <p
                style={{
                  color: 'white',
                  position: 'absolute',
                  left: '12%',
                  bottom: '0%',
                  top: '7vh',
                }}
                className="mainFont"
              >
                a=1m/s²
              </p>
            </Anime>
            <p className="velTextMruv2 mainFont"></p>
            <div id="distance-mruv2-1" className="distanceMruv2">
              <Divider horizontal>
                <span className="mainFont">1 m</span>
              </Divider>
            </div>
            <div id="distance-mruv2-2" className="distanceMruv2">
              <Divider horizontal>
                <span className="mainFont">1 m</span>
              </Divider>
            </div>
            <div id="distance-mruv2-3" className="distanceMruv2">
              <Divider horizontal>
                <span className="mainFont">1 m</span>
              </Divider>
            </div>
            <br></br>

            <div
              id="distance-mruv2-4"
              style={{ width: '13vw', top: '7vh' }}
              className="distanceMruv2"
            >
              <Divider horizontal>
                <span className="mainFont">1,5 m</span>
              </Divider>
            </div>
            <div
              id="distance-mruv2-5"
              style={{ width: '15vw', top: '7vh' }}
              className="distanceMruv2"
            >
              <Divider horizontal>
                <span className="mainFont">2,5 m</span>
              </Divider>
            </div>
            <div
              id="distance-mruv2-6"
              style={{ width: '18vw', top: '7vh' }}
              className="distanceMruv2"
            >
              <Divider horizontal>
                <span className="mainFont">3,5 m</span>
              </Divider>
            </div>
          </div>
          <br></br>
          <br></br>
          <p
            style={{ fontSize: 'large', position: 'relative', left: '2vw', top: '4vw' }}
            id="time-text-mruv-2"
            className="mainFont"
          ></p>
          <br></br>

          <div className="presentationItem arrowBtnContainer">
            <Button
              icon
              labelPosition="left"
              onClick={() => {
                setMruvStep(mruvStep - 1);
              }}
            >
              <Icon name="left arrow" />
              Anterior
            </Button>
            <Button
              icon
              labelPosition="right"
              onClick={() => {
                setMruvStep(mruvStep + 1);
              }}
            >
              Próximo
              <Icon name="right arrow" />
            </Button>
          </div>
        </>
      ),
    },
    {
      name: 'MRUV',
      mruvStep: 3,
      comp: (
        <>
          <h1 className="presentationItem">MRUV</h1>
          <p className="presentationItem">
            Observamos que o carro verde estava em movimento com uma{' '}
            <b className="presentationItem">aceleração constante e não nula</b>. Quando isso ocorre,
            dizemos que o objeto está em{' '}
            <b className="presentationItem">Movimento Retilíneo Uniformemente Variado</b>, ou MRUV.
            <br></br>
            <br></br>
            Conseguimos exemplificar todo esse comportamento em uma única fórmula, a qual temos:
          </p>

          <p className="presentationItem">
            <MathComponent tex={String.raw`S = S_{0} + V_{0}*t + \frac{a*t^2}{2}`} />
          </p>
          <br></br>

          <p className="presentationItem">
            Em que:
            <br></br>
            <b className="presentationItem">
              <i>S</i>
            </b>{' '}
            representa qual o <b className="presentationItem">deslocamento total</b> ao fim do
            movimento
            <br></br>
            <b className="presentationItem">
              <i>So</i>
            </b>{' '}
            representa o <b className="presentationItem">deslocamento inicial</b>, caso já exista
            <br></br>
            <b className="presentationItem">
              <i>Vo</i>
            </b>{' '}
            representa a <b className="presentationItem">velocidade inicial</b> do movimento
            <br></br>
            <b className="presentationItem">
              <i>t</i>
            </b>{' '}
            representa o <b className="presentationItem">tempo</b> total do deslocamento
            <br></br>
            <b className="presentationItem">
              <i>a</i>
            </b>{' '}
            representa uma <b className="presentationItem">aceleração</b> constante.
            <br></br>
            <br></br>
            No momento, pode ser que a demonstração dessa fórmula esteja em um nível mais levado
            para você. Em níveis futuros, lhe ensinaremos como chegar nesses resultados!
          </p>

          <div className="presentationItem arrowBtnContainer">
            <Button
              icon
              labelPosition="left"
              onClick={() => {
                setMruvStep(mruvStep - 1);
              }}
            >
              <Icon name="left arrow" />
              Anterior
            </Button>
          </div>
        </>
      ),
    },
  ];

  return (
    <div className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`}>
      {/* <div className="minMaxBar">
        <Icon className="icon" name='caret down' onClick={minimizeBar}/>
        <Icon className="icon" name='caret up' onClick={maximizeBar}/>
      </div> */}
      {!isMinimized && standardObj[mruvStep].comp}
    </div>
  );
}

export default MRUVPage;

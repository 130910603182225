import SceneComponent from 'babylonjs-hook';
import BabylonjsFunctions from './BabylonjsFunctions';

import React, { useEffect, useState } from 'react';
import ControlPanel from '../../../Babylon React Components/ControlPanel';
import AskMessage from '../../../Babylon React Components/compAskMessage';
import DirectionRocket from '../Gerais/compDirectionRocket';
import PhysicsInfo from '../Gerais/compPhysicsInfo';
import LoadingSpinner from '../../../loadingSpinner';
import FinalPanel from '../Gerais/compFinalPanel';
import gameManager from '../../../../managers/GameManager';

import { getImageSpecie } from '../Gerais/functions';

function UniversalGravitationScene(props) {
  let [onSceneReady, onRender] = BabylonjsFunctions();

  // Atualização do tempo necessária para atualização dos valores na gameManager
  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 100);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // Para configuração do slider
  let specieImg = getImageSpecie();

  let nameIcon = 'play';
  let nameButton = 'Começar';
  gameManager.objSpecificGame.physics.gravitation.running === '1'
    ? (nameIcon = 'pause')
    : (nameIcon = 'play');
  gameManager.objSpecificGame.physics.gravitation.running === '1'
    ? (nameButton = 'Pausar')
    : (nameButton = 'Começar');

  let textResponse = gameManager.objSpecificGame.physics.gravitation.responseValue;

  return (
    <div>
      <SceneComponent antialias onSceneReady={onSceneReady} onRender={onRender} id="my-canvas" />
      <LoadingSpinner
        loading={gameManager.objSpecificGame.physics.gravitation.isLoading === 'true'}
      />
      <ControlPanel
        condition={
          gameManager.objSpecificGame.physics.gravitation.showFinalPanel !== 'true' &&
          gameManager.objSpecificGame.physics.gravitation.isLoading !== 'true'
        }
        icon={nameIcon}
        button={nameButton}
        restartFunction={() => {
          gameManager.updateInfo('physics', 'gravitation', 'running', '1');
          gameManager.updateInfo('physics', 'gravitation', 'restart', '1');
        }}
        playPauseFunction={() => {
          gameManager.updateInfo(
            'physics',
            'gravitation',
            'running',
            (1 - parseInt(gameManager.objSpecificGame.physics.gravitation.running)).toString()
          );
        }}
      />
      <FinalPanel
        condition={
          gameManager.objSpecificGame.physics.gravitation.showFinalPanel === 'true' &&
          gameManager.objSpecificGame.physics.gravitation.isLoading !== 'true'
        }
        challengeName="Desviando da lua"
        func={props.map}
        results={
          [{
            text: 'Força da nave: ' + textResponse.split(" ")[2],
            icon: "bolt"
          },
          {
            text: 'Direção navegada: ' + textResponse.split(" ")[6],
            icon: 'arrow right'
          }]
        }
        starIndex={3}
        xpAward={30}
      />
      <AskMessage
        condition={
          gameManager.objSpecificGame.physics.gravitation.showMessage === 'true' &&
          gameManager.objSpecificGame.physics.gravitation.isLoading !== 'true'
        }
        image={specieImg}
        name="Imperador Giles"
        message={gameManager.objSpecificGame.physics.gravitation.message}
        position='bottom'
      />
      <DirectionRocket
        condition={
          gameManager.objSpecificGame.physics.gravitation.showSlider === 'true' &&
          gameManager.objSpecificGame.physics.gravitation.isLoading !== 'true'
        }
        function={(value) => {
          gameManager.updateInfo('physics', 'gravitation', 'responseValue', '' + value);
        }}
      />
      <PhysicsInfo
        info={gameManager.objSpecificGame.physics.gravitation.physicsInfo} />
    </div>
  );
}

export default UniversalGravitationScene;

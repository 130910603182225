import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../../services/api';

import ItemsBar from '../../../../components/ItemsBar';
import Navbar from '../../../../components/navbar';
import Introdutory from './comunicationIntrudutory';
import MapExplanation from './comunicationMapExplanation';
import ChallengeExplanation from './travelChallengeExplanation';
import HeliocentrismPage from './researchHeliocentrismPage';
import FirstKeplerLawPage from './researchFirstKeplerLaw';
import SecondKeplerLawPage from './researchSecondKeplerLaw';
import ThirdKeplerLawPage from './researchThirdKeplerLaw';
import Solution from './trainingTipPage';
import '../../../Common/presentationPage.css';
import HistoryPage from './recordsHistoryPage';
import PresentationComp from '../../../Common/compMapPresentation';
import ChallengeMenu from './challengeMenu';

import registros from '../../../../images/Physics/Gravitation/registros.png';
import pesquisas from '../../../../images/Physics/Gravitation/pesquisas.png';
import AfterTravel from './afterTravel';

function PresentationPage() {
  const { id } = useParams();

  const [isMinimized, setIsMinimized] = useState(true);
  const [presentationItems, setPresentationItems] = useState([
    'Assistente de comunicação',
    'Mensagem do Imperador',
  ]);
  const [isChallengeOpened, setIsChallengeOpened] = useState(false);

  const [nextItem, setNextItem] = useState(null);
  const [challengeStarted, setChallengeStarted] = useState(false);
  const [challengeWon, setChallengeWon] = useState(false);
  const [activeItem, setActiveItem] = useState('Assistente de comunicação');

  useEffect(() => {
    const fetchData = async () => {
      sessionStorage.clear();
      await api
        .post('getChallengeInfo', {
          challenge_id: id,
          client_id: localStorage.getItem('client_id'),
        })
        .then((res) => {
          sessionStorage.setItem('access_museum', res.data.access_museum);
          sessionStorage.setItem('access_office', res.data.access_office);
          sessionStorage.setItem('additional_info', JSON.stringify(res.data.additional_info));
          sessionStorage.setItem('arr_stars', res.data.arr_stars);
          sessionStorage.setItem('challenge_id', res.data.challenge_id);
          sessionStorage.setItem('earned_stars', res.data.earned_stars);
          sessionStorage.setItem('finished', res.data.finished);
          sessionStorage.setItem('stickers', res.data.stickers);
          sessionStorage.setItem('module_id', res.data.module_id);
          sessionStorage.setItem('level', res.data.level);
          sessionStorage.setItem('xp', res.data.xp);

          setIsMinimized(sessionStorage.getItem('access_office') === 'true' ? true : false);
          setChallengeStarted(sessionStorage.getItem('access_museum') === 'true' ? true : false);
          setChallengeWon(sessionStorage.getItem('finished') === 'true' ? true : false);
        })
        .catch((err) => {});
    };
    fetchData();
  }, []);

  const arrPages = [
    {
      name: 'Pesquisas',
      comp: (
        <PresentationComp
          title="Pesquisas"
          text1="Bem vindo ao nosso centro de Pesquisas, o local onde aprendemos novos assuntos e evoluímos nosso conhecimento."
          text2="Navegue pelos itens à esquerda, aprenda novas habilidades e evolua para se tornar um grande astronauta nessa epopeia cósmica!"
          img={pesquisas}
          minimizeFunction={setIsMinimized}
          setChallengeStarted={setChallengeStarted}
          setNextItem={setNextItem}
          key={1}
        />
      ),
    },
    {
      name: 'Heliocentrismo',
      comp: <HeliocentrismPage setNextItem={setNextItem} key={2} />,
    },
    {
      name: '1ª Lei de Kepler',
      comp: <FirstKeplerLawPage setNextItem={setNextItem} key={3} />,
    },
    {
      name: '2ª Lei de Kepler',
      comp: <SecondKeplerLawPage setNextItem={setNextItem} key={4} />,
    },
    {
      name: '3ª Lei de Kepler',
      comp: <ThirdKeplerLawPage setNextItem={setNextItem} key={5} />,
    },
    {
      name: 'Assistente de comunicação',
      comp: <Introdutory setNextItem={setNextItem} setIsMinimized={setIsMinimized} key={6} />,
    },
    {
      name: 'Mensagem do Imperador',
      comp: <MapExplanation setNextItem={setNextItem} setIsMinimized={setIsMinimized} key={7} />,
    },
    {
      name: 'Explicação do Desafio',
      comp: (
        <ChallengeExplanation
          setChallengeStarted={setChallengeStarted}
          setIsChallengeOpened={setIsChallengeOpened}
          setNextItem={setNextItem}
          setIsMinimized={setIsMinimized}
          key={8}
          challenge = '/fisica-gravitacao-leisdekepler-desafio'
        />
      ),
    },
    {
      name: 'Treinamento de astronautas',
      // comp: <Solution challengeStarted={challengeStarted} setNextItem={setNextItem} key={6} />,
      comp: <Solution challengeStarted={true} setNextItem={setNextItem} key={9} />,
    },
    {
      name: 'Registros',
      comp: (
        <PresentationComp
          title="Registros"
          text1="Bem vindo aos registros, um local dedicado às suas conquistas."
          text2="Realize as missões e conquiste estrelas! Você pode gravar o seu nome na história do planeta Quest e de nossa raça!"
          img={registros}
          minimizeFunction={setIsMinimized}
          setChallengeStarted={setChallengeStarted}
          setNextItem={setNextItem}
          key={12}
        />
      ),
    },
    {
      name: 'Conquistas',
      comp: (
        <HistoryPage
          minimizeFunction={setIsMinimized}
          setChallengeStarted={setChallengeStarted}
          setNextItem={setNextItem}
          key={13}
        />
      ),
    },
    {
      name: 'Sua primeira conquista',
      comp: (
        <PresentationComp
          title="Sua primeira conquista"
          text1="Conforme a página de registros, sua primeira estrela será 'Saindo do Planeta'."
          text2="No mapa do desafio, procure pelo centro de pesquisas e o investigue, aprendendo os novos conhecimentos."
          img={pesquisas}
          minimizeFunction={setIsMinimized}
          setChallengeStarted={setChallengeStarted}
          setNextItem={setNextItem}
          key={14}
        />
      ),
    },
    {
      name: 'Você conseguiu!',
      comp: <AfterTravel setIsMinimized={setIsMinimized} key={15} />,
    },
  ];

  return (
    <div className="presentationPage">
      <Navbar />
      <div className="backgroundContainer">
        {/* <VelocidadeScene className="animationContent"/> */}
        <ChallengeMenu
          setPresentationItems={setPresentationItems}
          setIsMinimized={setIsMinimized}
          setActiveItem={setActiveItem}
          challengeStarted={challengeStarted}
          setIsChallengeOpened={setIsChallengeOpened}
          setChallengeStarted={setChallengeStarted}
          challengeWon={challengeWon}
        />
      </div>
      {!isMinimized && <div className="darkBackground"></div>}
      <ItemsBar
        isMinimized={isMinimized}
        minimizeFunction={setIsMinimized}
        items={presentationItems}
        firstActive="Assistente de comunicação"
        nextItem={nextItem}
        nextItemFunction={setNextItem}
        setActiveItem={setActiveItem}
        activeItem={activeItem}
      />
      {!isMinimized && arrPages.filter((item) => item.name === activeItem).map((item) => item.comp)}
    </div>
  );
}

export default PresentationPage;

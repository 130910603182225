import * as BABYLON from '@babylonjs/core';

import '@babylonjs/loaders';
import '@babylonjs/inspector';
import Scenario from './Scenario';

import PointsTraj from './PointsTraj';
import PointsTraj2 from './PointsTraj2';
import PointsPitStop from './PointsPitStop';
import scoreManager from '../../../../managers/ScoreManager';

function Setup(props) {
  let scene = props.scene;

  const TOTAL_DIST = 880;
  const CAMERA_INIT_POSITION = new BABYLON.Vector3(-50, 5, 110);

  let localCars = [
    { name: 'Arara Real', location: './scenes/Física/Cinemática/CarBlue.glb' },
    { name: 'Jacaré Impiedoso', location: './scenes/Física/Cinemática/CarGreen.glb' },
    { name: 'Boto Veloz', location: './scenes/Física/Cinemática/CarPink.glb' },
    { name: 'Falcão Negro', location: './scenes/Física/Cinemática/CarBlack.glb' },
    { name: 'Guará Rubro', location: './scenes/Física/Cinemática/CarRed.glb' },
    { name: 'Capivara Furiosa', location: './scenes/Física/Cinemática/CarYellow.glb' },
  ];

  const additional_info = JSON.parse(sessionStorage.getItem('additional_info') || '');
  // let additionalInfoStorage = sessionStorage.getItem('additional_info');
  // console.log('teste '+additionalInfoStorage);
  // if(!additionalInfoStorage) additionalInfoStorage = ''; 
  // const additional_info = JSON.parse(additionalInfoStorage);

  let listCars = additional_info.teams.map((car) => {
    let atualCar = localCars.filter((el) => el.name === car)[0];
    return atualCar;
  });

  scene.time = 0;
  scene.actualLap = 1;
  scene.lapTostopInPitstop = -3;
  scene.wheelsChosed = 0;
  scene.timeStoped = 0;
  scene.sunInclination = -0.51;
  scene.cars = [];
  scene.points = [];
  scene.points2 = [];
  scene.pointsStop = [];
  scene.rodas = [];

  scene.camera = new BABYLON.FreeCamera('camera', CAMERA_INIT_POSITION, scene);
  const canvas = scene.getEngine().getRenderingCanvas();
  scene.camera.attachControl(canvas, true);
  scene.camera.setTarget(new BABYLON.Vector3(100, 0, 60));

  let light = new BABYLON.HemisphericLight('light', new BABYLON.Vector3(0, 1, 0), scene);
  light.position = new BABYLON.Vector3(0, 5, 0);
  light.intensity = 0.5;

  // scene.soundCrowd = new BABYLON.Sound('crowdSound', 'sounds/crowdVoices.mp3', scene, null, {
  //   loop: true,
  //   autoplay: true,
  //   volume: 0.4,
  // });
  scene.soundRaceMusic = new BABYLON.Sound('raceMusic', 'sounds/musicStage1.mp3', scene, null, {
    loop: true,
    autoplay: false,
    volume: 0.4,
  });

  Scenario(scene);
  // scene.debugLayer.show({});
  // BABYLON.DebugLayer.InspectorURL = 'http://myurl/babylon.inspector.bundle.js';

  // Trajetória
  PointsTraj.forEach((e) => {
    scene.points.push(new BABYLON.Vector3(-e[1] * 0.394 + 145, 1, -e[0] * 0.393 + 178));
  });
  scene.points = scene.points.reverse();
  PointsTraj2.forEach((e) => {
    scene.points2.push(new BABYLON.Vector3(-e[1] * 0.394 + 145, 1, -e[0] * 0.393 + 178));
  });
  scene.points2 = scene.points2.reverse();
  PointsPitStop.forEach((e) => {
    scene.pointsStop.push(new BABYLON.Vector3(-e[1] * 0.42 + 146, 1, e[0] * 0.44 - 72));
  });

  scene.points = scene.points.slice(725, scene.points.length).concat(scene.points.slice(0, 725));
  scene.points2 = scene.points2
    .slice(700, scene.points2.length)
    .concat(scene.points2.slice(0, 700));

  // Draw the curve
  // var track = BABYLON.MeshBuilder.CreateLines('track', { points: scene.points }, scene);
  // track.color = new BABYLON.Color3(0, 0, 0);
  // var track = BABYLON.MeshBuilder.CreateLines('track', { points: scene.points2 }, scene);
  // track.color = new BABYLON.Color3(0, 0, 0);
  // var track = BABYLON.MeshBuilder.CreateLines('track', { points: scene.pointsStop }, scene);
  // track.color = new BABYLON.Color3(0, 0, 0);

  let storageCar = 0;
  let initPosition = 5;
  let carLocalFile = listCars[storageCar];
  listCars.splice(storageCar, 1);
  listCars.splice(initPosition, 0, carLocalFile);

  for (let i = 0; i < listCars.length; i++) {
    BABYLON.SceneLoader.ImportMesh(
      '',
      listCars[i].location,
      '',
      scene,
      function (meshes, particleSystems, skeleton, animationGroups) {
        let car = meshes[0];
        car.active = true;
        car.dist = -2 - 7 * Math.abs(i) + ((5 * (i + 1)) % 2);
        car.track = i % 2;
        car.changeTrack = 0;
        car.isStudent = listCars[i].name === carLocalFile.name ? 1 : 0;
        car.vel = (216 - i) / 3.6;
        car.acc = 0;
        car.equipe = listCars[i].name;

        let track = [
          Math.floor(
            (((TOTAL_DIST + car.dist) % TOTAL_DIST) * (scene.points.length - 1)) / TOTAL_DIST
          ),
          Math.floor(
            (((TOTAL_DIST + car.dist) % TOTAL_DIST) * (scene.points2.length - 1)) / TOTAL_DIST
          ),
        ];

        let pointsTrack = [
          scene.points[track[0]].x,
          scene.points2[track[1]].x,
          scene.points[track[0]].z,
          scene.points2[track[1]].z,
        ];

        car.position.x = pointsTrack[0] + (pointsTrack[1] - pointsTrack[0]) * car.track;
        car.position.y = 0;
        car.position.z = pointsTrack[2] + (pointsTrack[3] - pointsTrack[2]) * car.track;
        car.rotation = new BABYLON.Vector3(0, Math.PI, 0);
        car.scaling = new BABYLON.Vector3(0.35, 0.35, 0.35);

        for (let j = 0; j < 3; j++) {
          let m = scene.getMeshesByID('Front tire + Wheel.002_primitive' + j);
          m.forEach((e) => {
            scene.rodas.push(e);
          });
        }
        for (let j = 0; j < 6; j++) {
          let m = scene.getMeshesByID('Rear Tire + Wheel.002_primitive' + j);
          m.forEach((e) => {
            scene.rodas.push(e);
          });
        }

        car.initDist = car.dist;
        car.initPosition = car.position;
        car.initRotation = car.rotation;
        car.initVel = car.vel;
        car.initAcc = car.acc;
        car.initTrack = car.track;
        car.explode = false;

        scene.cars.push(car);
      }
    );
  }
  console.log('teste');
  // scene.registerAfterRender(() => {
  //   gameManager.updateInfo('physics', 'kinematics', 'isLoading', 'false');
  // });
}

export default Setup;

import React from 'react';
import { Reveal, Image } from 'semantic-ui-react';

import check from '../../images/avatars/check.png';
import block from '../../images/avatars/block.png';

function CompAvatar(props) {
  let checkTest = props.check ?? true;
  return (
    <>
      <Reveal animated="fade" className={props.className}>
        <Reveal.Content visible>
          <Image
            src={`data:image/jpeg;base64,${props.image}`}
            fluid
            onClick={() => {
              if (checkTest === true) props.setPic(props.code, props.image);
            }}
          />
        </Reveal.Content>
        <Reveal.Content hidden>
          {checkTest === true && <Image src={check} fluid />}
          {checkTest === false && <Image src={block} fluid />}
        </Reveal.Content>
      </Reveal>
    </>
  );
}

export default CompAvatar;

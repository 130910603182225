import React, { useState } from 'react';
import { Button, Popup, Icon } from 'semantic-ui-react';
import { MathComponent } from 'mathjax-react';


function GravityAccelerationPage(props) {
  const [step, setStep] = useState(0);
  const [isMinimized, setIsMinimized] = useState(false);


  const standardObj = [
    {
      name: 'Aceleração da Gravidade',
      step: 1,
      comp: (
        <>
          <h1 className="presentationItem">Aceleração da Gravidade</h1>

          <p className="presentationItem">
            Pela Lei da Gravitação Universal, estudamos que os corpos se atraem de acordo com o valor de suas massas e distância. Também podemos lembrar em nosso módulo sobre Queda Livre que independente do peso, <b className="presentationItem"> os corpos caem na Terra sujeitos à mesma aceleração da gravidade.</b> Porém, como podemos descobrir o valor dessa aceleração?
          </p>
          <p className="presentationItem">
            Suponha que tenhamos um objeto de massa pequena, e nosso planeta, de grande massa. A força entre eles será dada por:
          </p>
          <p className="presentationItem">
            <MathComponent tex={String.raw`F = \frac{G*m_{planeta}*m_{objeto}}{d^2}`} />
          </p>
          <p className="presentationItem">
            Em que G é a constante de gravitação Universal.
          </p>

          <div className="presentationItem arrowBtnContainer">
            <Button
              icon
              labelPosition="right"
              onClick={() => {
                setStep(step + 1);
              }}
            >
              Próximo
              <Icon name="right arrow" />
            </Button>
          </div>
        </>
      ),
    },
    {
      name: 'Aceleração da Gravidade',
      step: 2,
      comp: (
        <>
          <h1 className="presentationItem">Aceleração da Gravidade</h1>
          <p className="presentationItem">
            Pela Princípio Fundamental da Dinâmica, estudado em nosso módulo sobre as Leis de Newton, temos a definição de <b className="presentationItem">Força como o produto entre a massa e a aceleração</b>. Isolando a variável de aceleração, temos:
            <MathComponent tex={String.raw`\frac{F}{m} = a`} />
          </p>
          <p className="presentationItem">
            Na Lei da Gravitação Universal, podemos utilizar o mesmo princípio, isolando a massa do objeto:
            <MathComponent tex={String.raw`\frac{F}{m_{objeto}} = \frac{G*m_{planeta}}{d^2}`} />
          </p>
          <p className="presentationItem">
            Assim, percebemos que para determinado planeta, o valor de aceleração de sua gravidade será dado por:
            <MathComponent tex={String.raw`g = \frac{G*m_{planeta}}{d^2}`} />
          </p>

          <span className="white presentationItem">Unidades</span>
          <Popup
            wide
            inverted
            content={
              <div>
                <p className="presentationItem">
                  <br></br>A gravidade é comumente representado pela letra{' '}
                  <b className="presentationItem">
                    <i>g</i>
                  </b>{' '}
                  e a unidade padrão no Sistema Internacional é{' '}
                  <b className="presentationItem">
                    'metros por segundo ao quadrado' (representada por <i>m/s²</i> ).
                  </b>
                </p>

                <p className="presentationItem">
                  <br></br>A constante de Gravidatação Universal representada pela letra{' '}
                  <b className="presentationItem">
                    <i>G</i>
                  </b>
                </p>

                <p className="presentationItem">
                  <br></br>A massa do planeta é representada pela letra{' '}
                  <b className="presentationItem">
                    <i>m</i>
                  </b>{' '}
                  e a unidade padrão no Sistema Internacional é{' '}
                  <b className="presentationItem">
                    'kilogramas' (representada por <i>kg</i> ).
                  </b>
                </p>

                <p className="presentationItem">
                  <br></br>A distância entre o objeto e o centro do planeta é representada por{' '}
                  <b className="presentationItem">
                    <i>d</i>
                  </b>{' '}
                  e a unidade padrão no Sistema Internacional é{' '}
                  <b className="presentationItem">
                    'metros' (representada por <i>m</i> ).
                  </b>
                </p>

              </div>
            }
            trigger={<Icon name="question circle outline" />}
          />
          <div className="presentationItem arrowBtnContainer">
            <Button
              icon
              labelPosition="left"
              onClick={() => {
                setStep(step - 1);
              }}
            >
              <Icon name="left arrow" />
              Anterior
            </Button>
          </div>
        </>
      ),
    }
  ];


  return (
    <div className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`}>
      {/* <div className="minMaxBar">
        <Icon className="icon" name='caret down' onClick={minimizeBar}/>
        <Icon className="icon" name='caret up' onClick={maximizeBar}/>
      </div> */}
      {!isMinimized && standardObj[step].comp}
    </div>
  );
}

export default GravityAccelerationPage;

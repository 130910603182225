import * as BABYLON from '@babylonjs/core';
import gameManager from '../../../../managers/GameManager';

const DELTA_TIME = 1 / 30;
const TOTAL_DIST = 4000;
const V_MAX_CAR = 85;
const CORRECT_ACCELERATION = 2.25;
const CORRECT_VELOCITY = 5;

function Loop(props) {
  let scene = props.scene;
  if (scene.isReady() && gameManager.objSpecificGame.physics.kinematics.isLoading === 'true') {
    gameManager.updateInfo('physics', 'kinematics', 'isLoading', 'false');
    gameManager.updateInfo('physics', 'kinematics', 'missionMessage', 'Escorregando no Gelo');
    gameManager.updateInfo('physics', 'kinematics', 'missionIndexScoring', '4');
    gameManager.updateInfo('physics', 'kinematics', 'missionXpAward', '30');
  }
  playSounds(scene);
  scene.running = Number(gameManager.objSpecificGame.physics.kinematics.running);
  scene.restart = Number(gameManager.objSpecificGame.physics.kinematics.restart);
  scene.countStart = 0;

  if (scene.running !== undefined && scene.countStart !== undefined) {
    restartFlow(scene);
    simulationFlow(scene);
  }
}

export default Loop;

/**
 * Função para rotacionar o volante
 */
function rotateVolante(value, scene) {
  scene.volante.forEach((e) => {
    e.rotation.y = value;
  });
}

function volanteRotation(value) {
  if (value < 2000) {
    return 1 / (1 + Math.exp(-2 * (value - (2000 - 5))));
  } else {
    return 1 / (1 + Math.exp(2 * (value - (2000 + 5))));
  }
}

/**
 * Função para rotacionar as rodas de todos os carros
 */
function rotateRodas(value, scene) {
  scene.rodas.forEach((e) => {
    e.rotation.y = value;
  });
}

/**
 * Função que reinicia a corrida
 */
function restartFlow(scene) {
  if (scene.restart === 3) {
    gameManager.updateInfo('physics', 'kinematics', 'running', '0');
    gameManager.updateInfo('physics', 'kinematics', 'restart', '0');

    gameManager.updateInfo('physics', 'kinematics', 'showMessage', 'false');
    gameManager.updateInfo('physics', 'kinematics', 'showSlider', 'false');
    gameManager.updateInfo('physics', 'kinematics', 'showButtons', 'false');
    gameManager.updateInfo('physics', 'kinematics', 'showFinalPanel', 'false');

    scene.time = 0;
    (scene.cars || []).forEach((car) => {
      car.explode = false;
    });
  }
  if (scene.restart <= 2 && scene.restart > 0) {
    gameManager.updateInfo('physics', 'kinematics', 'running', '1');
    gameManager.updateInfo('physics', 'kinematics', 'restart', '' + Number(scene.restart + 1));

    scene.responses = [0, 0];
    scene.time = 0;
    (scene.cars || []).forEach((car) => {
      car.explode = false;
      car.dist = car.initDist;
      car.position = car.initPosition;
      car.rotation = new BABYLON.Vector3(0, -Math.PI / 2, 0);
      car.vel = car.initVel;
      car.acc = car.initAcc;
      car.track = car.initTrack;
      car.desiredTrack = car.track;
      car.active = true;
      if (car.isStudent) scene.cameraOrientation = car.rotation.y;
    });
  }
}

/**
 * Função que determina o fluxo das escolhas do aluno na corrida
 */
function simulationFlow(scene) {
  updateClassificationTable(scene);
  if (scene.running === 1 && scene.countStart >= 0) {
    updateQuests(scene);
    updateFinalPanel(scene);
    if (scene.running === 1 || scene.restart !== 0) {
      carsMovement(scene);
      carsMovement(scene);
      createExplosion(scene);
      cameraMovement(scene);

      updateTimePanel(scene);

      rotateRodas(
        (scene.rodas[0].rotation.y -
          5 * scene.cars.find((e) => e.isStudent).vel * DELTA_TIME * 0.2) %
          (2 * Math.PI),
        scene
      );
    }
  }
}

/**
 * Função para atualizar os dados da tabela de classificação
 */
function updateClassificationTable(scene) {
  let listCars = [
    { name: 'Arara Real' },
    { name: 'Jacaré Impiedoso' },
    { name: 'Boto Veloz' },
    { name: 'Falcão Negro' },
    { name: 'Guará Rubro' },
    { name: 'Capivara Furiosa' },
  ];

  let dists = listCars.map((car) => {
    let foundCar = scene.cars.find((e) => e.equipe === car.name);
    return foundCar && foundCar.equipe
      ? foundCar.dist > TOTAL_DIST
        ? foundCar.dist - TOTAL_DIST / 2
        : foundCar.dist
      : -1;
  });

  gameManager.updateInfo('physics', 'kinematics', 'classification', dists);
}

/**
 * Função para a movimentação da camera
 */
function cameraMovement(scene) {
  let car = scene.cars.find((e) => e.isStudent);
  if (car.isStudent) {
    // scene.cameraOrientation = controllerKRotation(car.rotation.y, scene.cameraOrientation, 0.05);
    scene.cameraOrientation = car.rotation.y;

    scene.camera.position = new BABYLON.Vector3(
      car.position.x - 20,
      car.position.y + 20,
      car.position.z + 50 * Math.sin(scene.cameraOrientation)
    );
    scene.camera.setTarget(
      new BABYLON.Vector3(
        car.position.x + 2.5 * Math.cos(scene.cameraOrientation),
        car.position.y + 0.2,
        car.position.z - 2.5 * Math.sin(scene.cameraOrientation)
      )
    );
  }
}

/**
 * Função para criar uma explosão durante a corrida
 */
function createExplosion(scene) {
  let car = scene.cars.find((e) => e.isStudent);
  if (car.explode && car.active && car.vel < 15) {
    BABYLON.ParticleHelper.CreateAsync('explosion', scene).then((set) => {
      var pos = new BABYLON.Vector3(car.position.x, car.position.y, car.position.z);
      set.systems.forEach((s) => {
        s.emitter = pos.clone();
        s.disposeOnStop = true;
      });
      set.systems[2].worldOffset.y = pos.y;
      set.start();
    });
    car.active = false;
  }
}

/**
 * Função responsável para a movimentação de todos os carros do desafio
 */
function carsMovement(scene) {
  (scene.cars || []).forEach((car, indexCar) => {
    if (car.dist > TOTAL_DIST - 5) gameManager.updateInfo('physics', 'kinematics', 'running', '0');

    car.acc =
      car.dist > TOTAL_DIST / 2 - 450
        ? car.dist > TOTAL_DIST / 2
          ? car.isStudent
            ? car.explode
              ? -5
              : scene.responses[1]
            : 2.0
          : car.isStudent
          ? (Math.pow(scene.responses[0] < 1.8 ? 1.8 : scene.responses[0], 2) - 85 * 85) / (2 * 450)
          : -8
        : car.acc;
    car.vel += (car.acc * DELTA_TIME) / 2;
    car.vel = Math.max(
      Math.min(car.vel, car.dist < TOTAL_DIST / 2 ? V_MAX_CAR : V_MAX_CAR * 3),
      0.00001
    );
    car.dist += (car.vel * DELTA_TIME) / 2;
    if (car.acc > CORRECT_ACCELERATION + 0.1 && car.dist > TOTAL_DIST / 2 + 200) car.explode = true;
    if (car.dist > TOTAL_DIST / 2 && scene.responses[0] > CORRECT_VELOCITY + 0.1)
      car.explode = true;

    scene.cars.forEach((element, elementIndex) => {
      if (elementIndex !== indexCar && car.changeTrack === 0) {
        if (
          element.dist % TOTAL_DIST > car.dist % TOTAL_DIST &&
          element.dist % TOTAL_DIST < (car.dist % TOTAL_DIST) + 25 &&
          element.track === car.track &&
          car.vel > element.vel
        ) {
          car.desiredTrack = car.track <= 0.5 ? 1 : 0;
        }
      }
    });

    car.changeTrack =
      car.desiredTrack > car.track ? 0.005 : car.desiredTrack < car.track ? -0.005 : 0;
    car.track = car.track + car.changeTrack;
    if (Math.abs(car.track - car.desiredTrack) < 0.0001) car.track = Math.round(car.track);

    let poitsCar = [];
    poitsCar.push(new BABYLON.Vector3(car.position.x, car.position.y, car.position.z));

    let isNegative = car.dist < 0 ? TOTAL_DIST : 0;
    let i1 = Math.floor(
      (((isNegative + car.dist) % TOTAL_DIST) * (scene.points.length - 1)) / TOTAL_DIST
    );
    let i2 = Math.floor(
      (((isNegative + car.dist) % TOTAL_DIST) * (scene.points2.length - 1)) / TOTAL_DIST
    );
    let i1_ =
      ((((isNegative + car.dist) % TOTAL_DIST) * (scene.points.length - 1)) / TOTAL_DIST) %
      (i1 > 0 ? i1 : 1);
    let i2_ =
      ((((isNegative + car.dist) % TOTAL_DIST) * (scene.points2.length - 1)) / TOTAL_DIST) %
      (i2 > 0 ? i2 : 1);

    let pointxi1 = scene.points[i1].x + (scene.points[i1 + 1].x - scene.points[i1].x) * i1_;
    let pointzi1 = scene.points[i1].z + (scene.points[i1 + 1].z - scene.points[i1].z) * i1_;
    let pointxi2 = scene.points2[i2].x + (scene.points2[i2 + 1].x - scene.points2[i2].x) * i2_;
    let pointzi2 = scene.points2[i2].z + (scene.points2[i2 + 1].z - scene.points2[i2].z) * i2_;

    car.position.x = pointxi1 + (pointxi2 - pointxi1) * car.track;
    car.position.z = pointzi1 + (pointzi2 - pointzi1) * car.track;

    poitsCar.push(new BABYLON.Vector3(car.position.x, car.position.y, car.position.z));

    if ((car.vel > 0 && scene.restart === 0) || scene.restart === undefined) {
      let normal = new BABYLON.Path3D(poitsCar).getNormals()[0];
      let theta = Math.acos(BABYLON.Vector3.Dot(new BABYLON.Vector3(0, 0, -1), normal));
      let dir = BABYLON.Vector3.Cross(new BABYLON.Vector3(0, 0, -1), normal).y;
      let newRotation = dir !== 0 ? (dir * theta) / Math.abs(dir) : -theta;
      car.rotation.y = controllerKRotation(car.rotation.y, newRotation, 0.1);

      if (car.isStudent) rotateVolante(volanteRotation(car.dist), scene);
    }
  });
}

/**
 * Função para suavizar a rotação do carro e da câmera
 */
function controllerKRotation(value, desiredValue, k) {
  if (desiredValue < 0 && value > 0 && Math.abs(desiredValue - value) > 2) {
    value = value - 2 * Math.PI;
  }
  if (desiredValue > 0 && value < 0 && Math.abs(desiredValue - value) > 2) {
    value = value + 2 * Math.PI;
  }
  return value + k * (desiredValue - value);
}

/////////////////////////////// Métodos para atualização GUI ///////////////////////////////

/**
 * Função para atualizar o painel de tempo
 */
function updateTimePanel(scene) {
  scene.time += DELTA_TIME;
  let time = Math.floor(scene.time);
  let seconds = time % 60 > 9 ? (time % 60).toFixed(0) : '0' + (time % 60).toFixed(0);
  let minutes = Math.floor(time / 60) > 9 ? Math.floor(time / 60) : '0' + Math.floor(time / 60);
  gameManager.updateInfo('physics', 'kinematics', 'timeCount', minutes + ':' + seconds);
}

/**
 * Função para mostrar as GUIs de perguntas
 */
function updateQuests(scene) {
  let car = scene.cars.find((e) => e.isStudent);
  if (car.dist > TOTAL_DIST / 2 - 450 && scene.responses[0] === 0) {
    gameManager.updateInfo('physics', 'kinematics', 'showMessage', 'true');
    gameManager.updateInfo('physics', 'kinematics', 'showSlider', 'true');
    gameManager.updateInfo(
      'physics',
      'kinematics',
      'message',
      'Qual a velocidade do carro na virada da curva?'
    );
    if (
      Number(gameManager.objSpecificGame.physics.kinematics.responseValue) > 0 &&
      Number(gameManager.objSpecificGame.physics.kinematics.responseValue) < 15
    ) {
      scene.responses[0] = Number(gameManager.objSpecificGame.physics.kinematics.responseValue);
      gameManager.updateInfo('physics', 'kinematics', 'showMessage', 'false');
      gameManager.updateInfo('physics', 'kinematics', 'showSlider', 'false');
      gameManager.updateInfo('physics', 'kinematics', 'responseValue', '0');
    } else {
      scene.running = 0;
    }
  } else {
    if (car.dist > TOTAL_DIST / 2 && scene.responses[1] === 0) {
      gameManager.updateInfo('physics', 'kinematics', 'showMessage', 'true');
      gameManager.updateInfo('physics', 'kinematics', 'showSlider', 'true');
      gameManager.updateInfo(
        'physics',
        'kinematics',
        'message',
        'Qual a aceleração para o carro chegar ao final em 40 segundos?'
      );
      if (
        Number(gameManager.objSpecificGame.physics.kinematics.responseValue) > 0 &&
        Number(gameManager.objSpecificGame.physics.kinematics.responseValue) < 5
      ) {
        scene.responses[1] = Number(gameManager.objSpecificGame.physics.kinematics.responseValue);
        gameManager.updateInfo('physics', 'kinematics', 'showMessage', 'false');
        gameManager.updateInfo('physics', 'kinematics', 'showSlider', 'false');
        gameManager.updateInfo('physics', 'kinematics', 'responseValue', '0');
      } else {
        scene.running = 0;
      }
    }
  }
}

/**
 * Função para atualizar os dados do painel final
 */
function updateFinalPanel(scene) {
  let car = scene.cars.find((e) => e.isStudent);
  let maxDist = scene.cars.reduce((acc, v) => {
    if (v.dist > acc) return v.dist;
    else {
      return acc;
    }
  }, 0);

  let finish = null;
  let win = null;
  // if (car.dist > TOTAL_DIST / 2 - 100 && Math.abs(scene.responses[0] - CORRECT_VELOCITY) > 0.15) {
  //   finish = 'Que pena, o carro não será capaz de fazer a curva com a velocidade escolhida!';
  // }

  if (maxDist > TOTAL_DIST - 10 && car.dist > TOTAL_DIST - 10) {
    finish = 'Parabéns! Você conseguiu frear e acelerar corretamente para vencer o adversário!';
    win = 'true';
    console.log("teste2");
  } else if (maxDist > TOTAL_DIST - 5) {
    finish = 'Que pena, o adversário chegou primeiro do que você!';
    win = 'false';
  } else if (car.explode && car.vel < 5 && car.dist > TOTAL_DIST / 2 + 200) {
    finish = 'Que pena, você acelerou demais o seu carro e danificou o motor!';
    win = 'false';
  } else if (car.explode && car.dist < TOTAL_DIST / 2 + 200) {
    finish = 'Você tentou fazer a curva muito rápido e não conseguiu!';
    win = 'false';
  }

  if (finish) {
    gameManager.updateInfo('physics', 'kinematics', 'showFinalPanel', 'true');
    gameManager.updateInfo('physics', 'kinematics', 'finalMessage', finish);
    gameManager.updateInfo('physics', 'kinematics', 'win', win);
    scene.running = 0;
  }
}

function playSounds(scene) {
  if (Number(gameManager.objSpecificGame.physics.kinematics.running) === 1 && scene.running === 0) {
    if (!scene.soundRaceMusic.isPlaying) {
      scene.soundRaceMusic.play();
    }
  } else if (
    Number(gameManager.objSpecificGame.physics.kinematics.running) === 0 &&
    scene.running === 1
  ) {
    scene.soundRaceMusic.stop();
  }
}

import React, { useEffect, useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import eq1 from '../../../../images/averageSpeedEq1.png';
import eq2 from '../../../../images/averageSpeedEq2.png';
import eq3 from '../../../../images/averageSpeedEq3.png';
import eq4 from '../../../../images/averageSpeedEq4.png';
import eq5 from '../../../../images/averageSpeedEq5.png';
import idea from '../../../../images/idea.png';
import movingCarBlue from '../../../../images/movingCarBlue.gif';
import movingCarFusca from '../../../../images/movingCarFusca.gif';
import question from '../../../../images/question.png';

function AverageSpeed(props) {
  const [averageSpeedStep, setAverageSpeedStep] = useState(0);
  const [isMinimized, setIsMinimized] = useState(false);

  useEffect(() => {
    props.setNextItem('Conversão de unidades');
  }, []);

  const standardObj = [
    {
      name: 'Velocidade média',
      averageSpeedStep: 1,
      comp: (
        <>
          <h1 className="presentationItem">Velocidade média</h1>
          <p className="presentationItem">
            Agora que você aprendeu o que é a velocidade, vamos um pouco mais a fundo. Imagine que
            você você e seu amigo querem saber quem é mais veloz na hora de ir para a escola.
          </p>
          <br></br>
          <p className="presentationItem">
            Você sabe que durante o seu trajeto, você percorre com seu carro 500 metros em em uma
            velocidade de 20 m/s e depois disso mais 1000 metros a 25 m/s, pois é um trecho sem
            engarrafamentos e por isso você pode andar mais rápido, chegando bem a tempo para sua
            aula de física.
          </p>

          <div className="averageSpeed container">
            <img
              id="moving-car-fusca"
              className="presentationItem movingCar"
              src={movingCarFusca}
              alt=""
            />
          </div>

          <div className="presentationItem arrowBtnContainer">
            <Button
              icon
              labelPosition="right"
              onClick={() => {
                setAverageSpeedStep(averageSpeedStep + 1);
              }}
            >
              Próximo
              <Icon name="right arrow" />
            </Button>
          </div>
        </>
      ),
    },
    {
      name: 'Velocidade média',
      averageSpeedStep: 2,
      comp: (
        <>
          <h1 className="presentationItem">Velocidade média</h1>
          <p className="presentationItem">
            Seu amigo, que mora em outro bairro, tem um trajeto mais simples. Ele percorre todo o
            caminho com uma velocidade de 22 m/s.
          </p>
          <br></br>

          <div className="averageSpeed container">
            <img
              id="moving-car-blue"
              className="presentationItem movingCar"
              src={movingCarBlue}
              alt=""
            />
          </div>

          <div className="presentationItem arrowBtnContainer">
            <Button
              icon
              labelPosition="left"
              onClick={() => {
                setAverageSpeedStep(averageSpeedStep - 1);
              }}
            >
              <Icon name="left arrow" />
              Anterior
            </Button>
            <Button
              icon
              labelPosition="right"
              onClick={() => {
                setAverageSpeedStep(averageSpeedStep + 1);
              }}
            >
              Próximo
              <Icon name="right arrow" />
            </Button>
          </div>
        </>
      ),
    },
    {
      name: 'Velocidade média',
      averageSpeedStep: 3,
      comp: (
        <>
          <div className="presentationItem imgParagraph">
            <p className="presentationItem">
              Mas afinal de contas, você foi mais rápido ou mais devagar que os 22 m/s do seu amigo?
            </p>
            <img width="20%" src={question} alt="" />
          </div>

          <div className="presentationItem imgParagraph">
            <img width="20%" src={idea} alt="" />
            <p style={{ textAlign: 'right' }} className="presentationItem">
              Para resolver esse problema surge o conceito de velocidade média. Assim é possível
              representar um percurso com um único valor de velocidade, deixando tudo mais simples!
            </p>
          </div>

          <br></br>

          <div className="presentationItem arrowBtnContainer">
            <Button
              icon
              labelPosition="left"
              onClick={() => {
                setAverageSpeedStep(averageSpeedStep - 1);
              }}
            >
              <Icon name="left arrow" />
              Anterior
            </Button>
            <Button
              icon
              labelPosition="right"
              onClick={() => {
                setAverageSpeedStep(averageSpeedStep + 1);
              }}
            >
              Próximo
              <Icon name="right arrow" />
            </Button>
          </div>
        </>
      ),
    },
    {
      name: 'Velocidade média',
      averageSpeedStep: 4,
      comp: (
        <>
          <div className="presentationItem imgParagraph">
            <p className="presentationItem">
              Se você fizer o cálculo para o primeiro trecho, verá que o tempo necessário para
              realizá-lo é de 25 segundos.
            </p>
            <img width="20%" src={eq1} alt="" />
          </div>

          <div className="presentationItem imgParagraph">
            <img width="20%" src={eq2} alt="" />
            <p style={{ textAlign: 'right' }} className="presentationItem">
              Para o segundo, o raciocínio é idêntico e o tempo é de 40 segundos.
            </p>
          </div>

          <br></br>

          <div className="presentationItem arrowBtnContainer">
            <Button
              icon
              labelPosition="left"
              onClick={() => {
                setAverageSpeedStep(averageSpeedStep - 1);
              }}
            >
              <Icon name="left arrow" />
              Anterior
            </Button>
            <Button
              icon
              labelPosition="right"
              onClick={() => {
                setAverageSpeedStep(averageSpeedStep + 1);
              }}
            >
              Próximo
              <Icon name="right arrow" />
            </Button>
          </div>
        </>
      ),
    },
    {
      name: 'Velocidade média',
      averageSpeedStep: 5,
      comp: (
        <>
          <p style={{ paddingTop: '4%', textAlign: 'center' }} className="presentationItem">
            Como você aprendeu no início dessa aula, velocidade é o mesmo que deslocamento dividido
            pelo tempo, lembra?
          </p>
          <div style={{ padding: '0' }} className="presentationItem imgParagraph">
            <p className="presentationItem">
              Então para saber sua velocidade média, basta somar todo o deslocamento percorrido...
            </p>
            <img width="30%" src={eq3} alt="" />
          </div>

          <div style={{ padding: '0' }} className="presentationItem imgParagraph">
            <p className="presentationItem">Some também o seu tempo total de viagem...</p>
            <img width="30%" src={eq4} alt="" />
          </div>

          <br></br>

          <div className="presentationItem arrowBtnContainer">
            <Button
              icon
              labelPosition="left"
              onClick={() => {
                setAverageSpeedStep(averageSpeedStep - 1);
              }}
            >
              <Icon name="left arrow" />
              Anterior
            </Button>
            <Button
              icon
              labelPosition="right"
              onClick={() => {
                setAverageSpeedStep(averageSpeedStep + 1);
              }}
            >
              Próximo
              <Icon name="right arrow" />
            </Button>
          </div>
        </>
      ),
    },
    {
      name: 'Velocidade média',
      averageSpeedStep: 6,
      comp: (
        <div style={{ textAlign: 'center' }} className="presentationItem">
          <p style={{ paddingTop: '4%', textAlign: 'center' }} className="presentationItem">
            Agora é só calcular a velocidade média do seu percurso e...
          </p>

          <div className="averageSpeed container">
            <img width="25%" src={eq5} alt="" />
          </div>
          <br></br>
          <p
            style={{ top: '8%', textAlign: 'center', position: 'relative' }}
            className="presentationItem"
          >
            ...pronto! Você venceu a disputa! <br></br>
            Mas foi por pouco hein !?
          </p>
          <div style={{ position: 'absolute', bottom: '15%' }} className="averageSpeed container">
            <img
              id="moving-car-blue"
              className="presentationItem movingCar"
              src={movingCarBlue}
              alt=""
            />
          </div>
          <div
            style={{ position: 'absolute', bottom: '15%', left: '10%' }}
            className="averageSpeed container"
          >
            <img
              id="moving-car-fusca"
              className="presentationItem movingCar"
              src={movingCarFusca}
              alt=""
            />
          </div>

          <div className="presentationItem arrowBtnContainer">
            <Button
              icon
              labelPosition="left"
              onClick={() => {
                setAverageSpeedStep(averageSpeedStep - 1);
              }}
            >
              <Icon name="left arrow" />
              Anterior
            </Button>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`}>
      {/* <div className="minMaxBar">
        <Icon className="icon" name="caret down" onClick={minimizeBar} />
        <Icon className="icon" name="caret up" onClick={maximizeBar} />
      </div> */}
      {!isMinimized && standardObj[averageSpeedStep].comp}
    </div>
  );
}

export default AverageSpeed;

import * as BABYLON from '@babylonjs/core';
import '@babylonjs/loaders';
import { SkyMaterial } from '@babylonjs/materials/sky';
import '@babylonjs/core/Meshes/thinInstanceMesh';
import { mathMod } from 'ramda';

function Scenario(scene) {
  BABYLON.SceneLoader.ImportMesh(
    '',
    './scenes/Física/Cinemática/Aceleração/trackCompressed.glb',
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let terrain = meshes[0];
      animationGroups.forEach((x) => {
        x.start(true);
      });
      terrain.position = new BABYLON.Vector3(55, 0.22, 0);
      terrain.rotation = new BABYLON.Vector3(0, Math.PI, 0);
      terrain.scaling = new BABYLON.Vector3(1, 1, -1);
    }
  );

  var box;
  var skyMaterial = new SkyMaterial('sky', scene);
  skyMaterial.turbidity = 20;
  skyMaterial.luminance = 1;
  skyMaterial.inclination = -0.8;
  skyMaterial.azimuth = -0.24;
  skyMaterial.rayleigh = 3;
  var box = BABYLON.Mesh.CreateBox('SkyBox', 1000, scene, false, BABYLON.Mesh.BACKSIDE);
  box.material = skyMaterial;
  box.position = new BABYLON.Vector3(0, 100, 0);
  scene.createDefaultEnvironment({ skyboxSize: 1000 });

  BABYLON.SceneLoader.ImportMesh(
    '',
    './scenes/Física/Cinemática/Aceleração/cactus.glb',
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let cactus = meshes[1];
      cactus.alwaysSelectAsActiveMesh = true;
      cactus.position = new BABYLON.Vector3(-65.5, 0, -6);

      let count = 50;
      var bufferMatrices = new Float32Array(16 * count);
      for (var x = 0; x < count; x++) {
        let side = Math.floor(4 * Math.random());
        let scale = 0.2 + Math.random() * 0.4;
        let xPos, zPos;

        if (side === 0) {
          xPos = 70 + Math.random() * 40;
          zPos = -6 + (Math.random() - 0.5) * 180;
        } else if (side === 1) {
          xPos = -(65 + Math.random() * 40);
          zPos = -6 + (Math.random() - 0.5) * 180;
        } else if (side === 2) {
          xPos = 5 + (Math.random() - 0.5) * 130;
          zPos = 60 + Math.random() * 40;
        } else if (side === 3) {
          xPos = 5 + (Math.random() - 0.5) * 130;
          zPos = -(80 + Math.random() * 30);
        }

        var matrix = BABYLON.Matrix.Compose(
          new BABYLON.Vector3(scale, scale, scale),
          new BABYLON.Quaternion.RotationYawPitchRoll(Math.random() * 2 * Math.PI, 0, 0),
          new BABYLON.Vector3(xPos, 0, zPos)
        );
        matrix.copyToArray(bufferMatrices, 16 * x);
      }
      cactus.thinInstanceSetBuffer('matrix', bufferMatrices, 16);
    }
  );

  /**
   * Arquibancada 1
   */

  BABYLON.SceneLoader.ImportMesh(
    '',
    './scenes/Física/Cinemática/Aceleração/grandstand.glb',
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let arquibancada = meshes[0];
      arquibancada.rotation = new BABYLON.Vector3(0, -Math.PI / 2, 0);
      arquibancada.position = new BABYLON.Vector3(144, 0, 10);
      arquibancada.scaling = new BABYLON.Vector3(0.5, 0.5, -0.5);
    }
  );

  BABYLON.SceneLoader.ImportMesh(
    '',
    './scenes/Física/Cinemática/Aceleração/yellowEgg.glb',
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let egg = BABYLON.Mesh.MergeMeshes(
        meshes.filter((e, index) => index > 0),
        true,
        true,
        undefined,
        false,
        true
      );
      egg.rotation = new BABYLON.Vector3(0, Math.PI / 2, 0);
      animationGroups.forEach((x) => {
        x.start(true);
      });
      egg.alwaysSelectAsActiveMesh = true;
      egg.position = new BABYLON.Vector3(148.77, 1.8, -12.23);
      egg.scaling = new BABYLON.Vector3(0.5, 0.5, 0.5);

      var bufferMatrices = new Float32Array(16 * 7 * 4);
      let cont = 0;
      for (var x = 0; x < 7; x++) {
        for (var y = 0; y < 4; y++) {
          var matrix = BABYLON.Matrix.Translation(-2.71 * x, 1.85 * y, -4.22 * y);
          matrix.copyToArray(bufferMatrices, cont);
          cont += 16;
        }
      }
      egg.thinInstanceSetBuffer('matrix', bufferMatrices, 16);
    }
  );

  BABYLON.SceneLoader.ImportMesh(
    '',
    './scenes/Física/Cinemática/Aceleração/greenEgg.glb',
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let egg = BABYLON.Mesh.MergeMeshes(
        meshes.filter((e, index) => index > 0),
        true,
        true,
        undefined,
        false,
        true
      );
      egg.rotation = new BABYLON.Vector3(0, Math.PI / 2, 0);
      animationGroups.forEach((x) => {
        x.start(true);
      });
      egg.alwaysSelectAsActiveMesh = true;
      egg.position = new BABYLON.Vector3(148.77, 1.8, -0.36);
      egg.scaling = new BABYLON.Vector3(0.5, 0.5, 0.5);

      var bufferMatrices = new Float32Array(16 * 7 * 4);
      let cont = 0;
      for (var x = 0; x < 7; x++) {
        for (var y = 0; y < 4; y++) {
          var matrix = BABYLON.Matrix.Translation(-2.71 * x, 1.85 * y, -4.22 * y);
          matrix.copyToArray(bufferMatrices, cont);
          cont += 16;
        }
      }
      egg.thinInstanceSetBuffer('matrix', bufferMatrices, 16);
    }
  );

  BABYLON.SceneLoader.ImportMesh(
    '',
    './scenes/Física/Cinemática/Aceleração/redEgg.glb',
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let egg = BABYLON.Mesh.MergeMeshes(
        meshes.filter((e, index) => index > 0),
        true,
        true,
        undefined,
        false,
        true
      );
      egg.rotation = new BABYLON.Vector3(0, Math.PI / 2, 0);
      animationGroups.forEach((x) => {
        x.start(true);
      });
      egg.alwaysSelectAsActiveMesh = true;
      egg.position = new BABYLON.Vector3(148.77, 1.8, 11.92);
      egg.scaling = new BABYLON.Vector3(0.5, 0.5, 0.5);

      var bufferMatrices = new Float32Array(16 * 7 * 4);
      let cont = 0;
      for (var x = 0; x < 7; x++) {
        for (var y = 0; y < 4; y++) {
          var matrix = BABYLON.Matrix.Translation(-2.71 * x, 1.85 * y, -4.22 * y);
          matrix.copyToArray(bufferMatrices, cont);
          cont += 16;
        }
      }
      egg.thinInstanceSetBuffer('matrix', bufferMatrices, 16);
    }
  );

  BABYLON.SceneLoader.ImportMesh(
    '',
    './scenes/Física/Cinemática/Aceleração/blueEgg.glb',
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let egg = BABYLON.Mesh.MergeMeshes(
        meshes.filter((e, index) => index > 0),
        true,
        true,
        undefined,
        false,
        true
      );
      egg.rotation = new BABYLON.Vector3(0, Math.PI / 2, 0);
      animationGroups.forEach((x) => {
        x.start(true);
      });
      egg.alwaysSelectAsActiveMesh = true;
      egg.position = new BABYLON.Vector3(148.77, 1.8, 24.38);
      egg.scaling = new BABYLON.Vector3(0.5, 0.5, 0.5);

      var bufferMatrices = new Float32Array(16 * 7 * 4);
      let cont = 0;
      for (var x = 0; x < 7; x++) {
        for (var y = 0; y < 4; y++) {
          var matrix = BABYLON.Matrix.Translation(-2.71 * x, 1.85 * y, -4.22 * y);
          matrix.copyToArray(bufferMatrices, cont);
          cont += 16;
        }
      }
      egg.thinInstanceSetBuffer('matrix', bufferMatrices, 16);
    }
  );

  /**
   * Arquibancada 2
   */

  BABYLON.SceneLoader.ImportMesh(
    '',
    './scenes/Física/Cinemática/Aceleração/grandstand.glb',
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let arquibancada = meshes[0];
      arquibancada.rotation = new BABYLON.Vector3(0, -Math.PI / 2, 0);
      arquibancada.position = new BABYLON.Vector3(144, 0, 60);
      arquibancada.scaling = new BABYLON.Vector3(0.5, 0.5, -0.5);
    }
  );

  BABYLON.SceneLoader.ImportMesh(
    '',
    './scenes/Física/Cinemática/Aceleração/yellowEgg.glb',
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let egg = BABYLON.Mesh.MergeMeshes(
        meshes.filter((e, index) => index > 0),
        true,
        true,
        undefined,
        false,
        true
      );
      egg.rotation = new BABYLON.Vector3(0, Math.PI / 2, 0);
      animationGroups.forEach((x) => {
        x.start(true);
      });
      egg.alwaysSelectAsActiveMesh = true;
      egg.position = new BABYLON.Vector3(148.77, 1.8, 37.77); //-12.23
      egg.scaling = new BABYLON.Vector3(0.5, 0.5, 0.5);

      var bufferMatrices = new Float32Array(16 * 7 * 4);
      let cont = 0;
      for (var x = 0; x < 7; x++) {
        for (var y = 0; y < 4; y++) {
          var matrix = BABYLON.Matrix.Translation(-2.71 * x, 1.85 * y, -4.22 * y);
          matrix.copyToArray(bufferMatrices, cont);
          cont += 16;
        }
      }
      egg.thinInstanceSetBuffer('matrix', bufferMatrices, 16);
    }
  );

  BABYLON.SceneLoader.ImportMesh(
    '',
    './scenes/Física/Cinemática/Aceleração/greenEgg.glb',
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let egg = BABYLON.Mesh.MergeMeshes(
        meshes.filter((e, index) => index > 0),
        true,
        true,
        undefined,
        false,
        true
      );
      egg.rotation = new BABYLON.Vector3(0, Math.PI / 2, 0);
      animationGroups.forEach((x) => {
        x.start(true);
      });
      egg.alwaysSelectAsActiveMesh = true;
      egg.position = new BABYLON.Vector3(148.77, 1.8, 49.73);
      egg.scaling = new BABYLON.Vector3(0.5, 0.5, 0.5);

      var bufferMatrices = new Float32Array(16 * 7 * 4);
      let cont = 0;
      for (var x = 0; x < 7; x++) {
        for (var y = 0; y < 4; y++) {
          var matrix = BABYLON.Matrix.Translation(-2.71 * x, 1.85 * y, -4.22 * y);
          matrix.copyToArray(bufferMatrices, cont);
          cont += 16;
        }
      }
      egg.thinInstanceSetBuffer('matrix', bufferMatrices, 16);
    }
  );

  BABYLON.SceneLoader.ImportMesh(
    '',
    './scenes/Física/Cinemática/Aceleração/redEgg.glb',
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let egg = BABYLON.Mesh.MergeMeshes(
        meshes.filter((e, index) => index > 0),
        true,
        true,
        undefined,
        false,
        true
      );
      egg.rotation = new BABYLON.Vector3(0, Math.PI / 2, 0);
      animationGroups.forEach((x) => {
        x.start(true);
      });
      egg.alwaysSelectAsActiveMesh = true;
      egg.position = new BABYLON.Vector3(148.77, 1.8, 62.01);
      egg.scaling = new BABYLON.Vector3(0.5, 0.5, 0.5);

      var bufferMatrices = new Float32Array(16 * 7 * 4);
      let cont = 0;
      for (var x = 0; x < 7; x++) {
        for (var y = 0; y < 4; y++) {
          var matrix = BABYLON.Matrix.Translation(-2.71 * x, 1.85 * y, -4.22 * y);
          matrix.copyToArray(bufferMatrices, cont);
          cont += 16;
        }
      }
      egg.thinInstanceSetBuffer('matrix', bufferMatrices, 16);
    }
  );

  BABYLON.SceneLoader.ImportMesh(
    '',
    './scenes/Física/Cinemática/Aceleração/blueEgg.glb',
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let egg = BABYLON.Mesh.MergeMeshes(
        meshes.filter((e, index) => index > 0),
        true,
        true,
        undefined,
        false,
        true
      );
      egg.rotation = new BABYLON.Vector3(0, Math.PI / 2, 0);
      animationGroups.forEach((x) => {
        x.start(true);
      });
      egg.alwaysSelectAsActiveMesh = true;
      egg.position = new BABYLON.Vector3(148.77, 1.8, 74.47);
      egg.scaling = new BABYLON.Vector3(0.5, 0.5, 0.5);

      var bufferMatrices = new Float32Array(16 * 7 * 4);
      let cont = 0;
      for (var x = 0; x < 7; x++) {
        for (var y = 0; y < 4; y++) {
          var matrix = BABYLON.Matrix.Translation(-2.71 * x, 1.85 * y, -4.22 * y);
          matrix.copyToArray(bufferMatrices, cont);
          cont += 16;
        }
      }
      egg.thinInstanceSetBuffer('matrix', bufferMatrices, 16);
    }
  );

  BABYLON.SceneLoader.ImportMesh(
    '',
    './scenes/Física/Cinemática/Aceleração/blackTireCollum.glb',
    '',
    scene,
    function (meshes, particleSystems, skeleton, animationGroups) {
      let column = BABYLON.Mesh.MergeMeshes(
        meshes.filter((e, index) => index > 0),
        true,
        true,
        undefined,
        false,
        true
      );
      column.alwaysSelectAsActiveMesh = true;
      column.position = new BABYLON.Vector3(174.5, 0, 0);
      column.scaling = new BABYLON.Vector3(0.45, 0.45, 0.45);

      let count = 60;
      var bufferMatrices = new Float32Array(16 * count);
      for (var x = 0; x < 20; x++) {
        var matrix = BABYLON.Matrix.Translation(0, 0, 3 * x);
        matrix.copyToArray(bufferMatrices, 16 * x);
      }

      for (var x = 0; x < 20; x++) {
        var matrix = BABYLON.Matrix.Translation(-50 - 3 * x, 0, 280);
        matrix.copyToArray(bufferMatrices, 20 * 16 + 16 * x);
      }

      for (var x = 0; x < 20; x++) {
        var matrix = BABYLON.Matrix.Translation(-45, 0, 3 * x + 145);
        matrix.copyToArray(bufferMatrices, 40 * 16 + 16 * x);
      }
      column.thinInstanceSetBuffer('matrix', bufferMatrices, 16);
    }
  );
}

export default Scenario;

import React, { useState } from 'react';
import { Segment, Button, Icon } from 'semantic-ui-react';
import './presentationPage.css';
import { useHistory } from 'react-router-dom';



function PlayDialogue(props) {
  let button;

  let history = useHistory();

  function getButtons() {
    if (props.buttons === 'Próximo') {
      button = (
        <div className="presentationItem arrowBtnContainer playDialogue">
          <Button
            icon
            labelPosition="right"
            onClick={() => {
              props.set(props.step + 1);
            }}
          >
            Próximo
            <Icon name="right arrow" />
          </Button>
        </div>
      );
    } else if (props.buttons === 'Anterior') {
      button = (
        <div className="presentationItem arrowBtnContainer playDialogue">
          <Button
            icon
            labelPosition="left"
            onClick={() => {
              props.set(props.step - 1);
            }}
          >
            <Icon name="left arrow" />
            Anterior
          </Button>
        </div>
      );
    } else if (props.buttons === 'Anterior/Próximo') {
      button = (
        <div className="presentationItem arrowBtnContainer playDialogue">
          <Button
            icon
            labelPosition="left"
            onClick={() => {
              props.set(props.step - 1);
            }}
          >
            <Icon name="left arrow" />
            Anterior
          </Button>

          <Button
            icon
            labelPosition="right"
            onClick={() => {
              props.set(props.step + 1);
            }}
          >
            Próximo
            <Icon name="right arrow" />
          </Button>
        </div>
      );
    } else if (props.buttons === 'Anterior/Mapa') {
      button = (
        <div className="presentationItem arrowBtnContainer playDialogue">
          <Button
            icon
            labelPosition="left"
            onClick={() => {
              props.set(props.step - 1);
            }}
          >
            <Icon name="left arrow" />
            Anterior
          </Button>

          <Button
            icon
            labelPosition="right"
            onClick={() => {
              props.mapAcess();
            }}
          >
            Ir para o mapa
            <Icon name="map" />
          </Button>
        </div>
      );
    } else if (props.buttons === 'Anterior/Começar Desafio') {
      button = (
        <div className="presentationItem arrowBtnContainer playDialogue">
          <Button
            icon
            labelPosition="left"
            onClick={() => {
              props.set(props.step - 1);
            }}
          >
            <Icon name="left arrow" />
            Anterior
          </Button>

          <Button
            icon
            labelPosition="right"
            onClick={() => {
              props.setChallengeStarted(true);
              props.setIsChallengeOpened(true);
              history.push(props.challenge);
            }}
          >
            Começar Desafio
            <Icon name="bolt" />
          </Button>
        </div>
      );
    } else if (props.buttons === 'Mapa') {
      button = (
        <div className="presentationItem arrowBtnContainer playDialogue">
          <Button
            icon
            labelPosition="right"
            onClick={() => {
              props.mapAcess();
            }}
          >
            Ir para o mapa
            <Icon name="map" />
          </Button>
        </div>
      );
    }
    return button;
  }

  return (
    <div className="board presentationItem transparentItem">
      {getButtons()}
      <div>
        <Segment className="speech-bubble">
          <p className="challengeParagraph">{props.text}</p>
          {props.extraContent}
        </Segment>
        <div className="imageBoss">
          <img className="imageBoss" src={props.image} alt="cinematics_boss" />
        </div>
      </div>
    </div>
  );
}

export default PlayDialogue;

/* eslint-disable react/display-name */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import { Image, List } from 'semantic-ui-react';
import PlayDialogue from '../../../Common/compPlayDialogue';
import cinematics_manager_black from '../../../../images/Physics/Kinematics/cinematics_manager_black.PNG';
import cinematics_manager_blue from '../../../../images/Physics/Kinematics/cinematics_manager_blue.PNG';
import cinematics_manager_green from '../../../../images/Physics/Kinematics/cinematics_manager_green.PNG';
import cinematics_manager_pink from '../../../../images/Physics/Kinematics/cinematics_manager_pink.PNG';
import cinematics_manager_red from '../../../../images/Physics/Kinematics/cinematics_manager_red.PNG';
import cinematics_manager_yellow from '../../../../images/Physics/Kinematics/cinematics_manager_yellow.PNG';
import wheel_1 from '../../../../images/Physics/Kinematics/wheel_1.PNG';
import wheel_2 from '../../../../images/Physics/Kinematics/wheel_2.PNG';
import wheel_3 from '../../../../images/Physics/Kinematics/wheel_3.PNG';
import wheel_4 from '../../../../images/Physics/Kinematics/wheel_4.PNG';

const SIZEICONS = '10%';

function ChallengeExplanation(props) {
  const [animationStep, setAnimationStep] = useState(0);
  const additionalInfo = JSON.parse(sessionStorage.getItem('additional_info'));

  function getImage() {
    switch (additionalInfo.choosed_team) {
      case 'Arara Real':
        return cinematics_manager_blue;
      case 'Jacaré Impiedoso':
        return cinematics_manager_green;
      case 'Boto Veloz':
        return cinematics_manager_pink;
      case 'Falcão Negro':
        return cinematics_manager_black;
      case 'Guará Rubro':
        return cinematics_manager_red;
      case 'Capivara Furiosa':
        return cinematics_manager_yellow;
      default:
        break;
    }
  }

  useEffect(() => {
    if (animationStep === 11) {
      props.setNextItem('Tempo');
    }
  }, [animationStep]);

  const arrPage = [
    <PlayDialogue
      key={0}
      buttons="Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text={
        'Logo disputaremos a nossa primeira corrida. Nosso carro começará com um jogo de pneus que nos dá uma velocidade média de 210 km/h, porém, nossos informantes revelaram que o carro mais rápido, da equipe ' +
        additionalInfo.teams[1] +
        ', consegue alcançar 216 km/h.'
      }
      image={getImage()}
      extraContent={''}
    />,

    <PlayDialogue
      key={1}
      buttons="Anterior/Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text="Durante a corrida, poderemos trocar de pneus uma única vez. Cada pneu tem características únicas e pode ser trocado em um momento diferente da corrida. Veja eles:"
      image={getImage()}
      extraContent={''}
    />,

    // <PlayDialogue
    //   key={2}
    //   buttons="Anterior/Próximo"
    //   set={setAnimationStep}
    //   step={animationStep}
    //   mapAcess={''}
    //   text="Dado o nosso pneu inicial de 200 km/h, nos ajude a planejar qual a melhor troca pneus durante a corrida devemos escolher para ganhar esse desafio."
    //   image={getImage()}
    //   extraContent={''}
    // />,

    <PlayDialogue
      key={2}
      buttons="Anterior/Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text=""
      image={getImage()}
      extraContent={
        <div>
          <div className="wheelsParagraph">
            <List>
              <br></br>
              <List.Item>
                <Image src={wheel_1} width={SIZEICONS} circular className="rotateWheels" />
                <List.Content>
                  <p className="wheelsParagraph">
                    {' '}
                    Pneu Resistente
                    <br></br>
                    Velocidade de 220 km/h e troca ao fim da 2ª volta{' '}
                  </p>
                </List.Content>
              </List.Item>
              <List.Item>
                <Image src={wheel_2} width={SIZEICONS} circular className="rotateWheels" />
                <List.Content>
                  <p className="wheelsParagraph">
                    {' '}
                    Pneu Intermediário
                    <br></br>
                    Velocidade de 260 km/h e troca ao fim da 3ª volta{' '}
                  </p>
                </List.Content>
              </List.Item>
              <List.Item>
                <Image src={wheel_3} width={SIZEICONS} circular className="rotateWheels" />
                <List.Content>
                  <p className="wheelsParagraph">
                    {' '}
                    Pneu Macio
                    <br></br>
                    Velocidade de 63,88 m/s e troca ao fim da 3ª volta{' '}
                  </p>
                </List.Content>
              </List.Item>
              <List.Item>
                <Image src={wheel_4} width={SIZEICONS} circular className="rotateWheels" />
                <List.Content>
                  <p className="wheelsParagraph">
                    {' '}
                    Pneu Ultramacio
                    <br></br>
                    Velocidade de 72,22 m/s e troca ao fim da 4ª volta{' '}
                  </p>
                </List.Content>
              </List.Item>
            </List>
          </div>
        </div>
      }
    />,

    <PlayDialogue
      key={3}
      buttons="Anterior/Começar Desafio"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      setChallengeStarted={props.setChallengeStarted}
      setIsChallengeOpened={props.setIsChallengeOpened}
      text="Sabendo que começaremos a corrida com 210 km/h e que a pista tem 800 m, precisamos planejar qual a melhor troca de pneus para que possamos completar as 5 voltas no menor tempo. O pitstop para a troca de pneus se encontra no início de cada volta, demorando 2 segundos para a troca ser realizada. Planeje bem sua troca e boa sorte!"
      image={getImage()}
      extraContent={''}
      challenge = {props.challenge}
    />,
  ];

  return arrPage[animationStep];
}

export default ChallengeExplanation;

import React, { useState, useEffect } from 'react';
import { Button, Popup, Icon } from 'semantic-ui-react';
import { MathComponent } from 'mathjax-react';
import Anime, { anime } from 'react-anime';
import earth from "../../../../images/earthT.png";
import stone from "../../../../images/stone.png";
import rocket from "../../../../images/rocketEarth.png";

function EscapeVelocityPage(props) {
  const [step, setStep] = useState(0);
  const [isMinimized, setIsMinimized] = useState(false);

  const standardObj = [
    {
      name: 'Velocidade de Escape',
      step: 1,
      comp: (
        <>
          <h1 className="presentationItem">Velocidade de Escape</h1>

          <p className="presentationItem">
            Imagine que você tenha uma pedra na mão e deseje lançá-la para o alto. Você <b className="presentationItem">arremessa com bastante força, ela sobe por alguns metros e logo depois cai ao chão</b>. Após isso você respira fundo e faz uma força maior ainda, a pedra vai até um pouco mais alto, mas cai novamente. Você percebe que por mais rápido que lance a pedra, não consegue fazê-la sumir de vista, jogá-la para fora do planeta Terra.
          </p>
          <p className="presentationItem">
            Mas como os foguetes que passam na televisão conseguem voar, sair de nosso planeta e viajar pelo espaço? <b className="presentationItem">Qual velocidade eles têm que alcançar para realizar tal feito?</b>
          </p>
          <div style={{width: '100%', height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div style={{width: '300px', height: '200px', position: 'relative'}}>
              <img id='stone-earth' width='10px' src={stone} style={{position: 'absolute', left: '60px', top: '62px'}}/>
              <img id='rocket-earth' width='20px' src={rocket} style={{position: 'absolute', right: '56px', top: '47px'}}/>
              <img width='75px' src={earth} style={{position: 'absolute', left: '30px', bottom: '50px'}}/>
              <img width='75px' src={earth} style={{position: 'absolute', right: '30px', bottom: '50px'}}/>
            </div>
          </div>
          <div className="presentationItem arrowBtnContainer">
            <Button
              icon
              labelPosition="right"
              onClick={() => {
                setStep(step + 1);
              }}
            >
              Próximo
              <Icon name="right arrow" />
            </Button>
          </div>
        </>
      ),
    },
    {
      name: 'Velocidade de Escape',
      step: 2,
      comp: (
        <>
          <h1 className="presentationItem">Velocidade de Escape</h1>
          <p className="presentationItem">
            Todos os objetos que são lançados para fora da Terra são <b className="presentationItem">puxados pela sua enorme gravidade</b>. Para escapar disso, eles precisam de uma altíssima velocidade, chamada de <b className="presentationItem">'velocidade de escape'</b>. Para descobrir como calcular essa velocidade de escape, podemos utilizar a lei da conservação de energia do nosso módulo de <b className="presentationItem">'Energia'</b> e igualar a enrgia cinética à energia potencial gravitacional:
            <MathComponent tex={String.raw`\frac{m * v^{2}}{2} = m * g * R`} />
            <MathComponent tex={String.raw`v^{2} = 2 * g * R`} />
            <MathComponent tex={String.raw`v = \sqrt{2 * g * R}`} />
            Como vimos que a gravitação de um planeta é calculada por:
            <MathComponent tex={String.raw`g = \frac{G*M}{R^{2}}`} />
            Assim, chegamos à:
            <MathComponent tex={String.raw`v = \sqrt{\frac{2 * G * M}{R}}`} />
          </p>

          <span className="white presentationItem">Unidades</span>
          <Popup
            wide='very'
            inverted
            position='right center'
            content={
              <div>
                <p className="presentationItem">
                  <br></br>A gravidade é comumente representado pela letra{' '}
                  <b className="presentationItem">
                    <i>g</i>
                  </b>{' '}
                  e a unidade padrão no Sistema Internacional é{' '}
                  <b className="presentationItem">
                    'metros por segundo ao quadrado' (representada por <i>m/s²</i> ).
                  </b>
                </p>

                <p className="presentationItem">
                  <br></br>A constante de Gravidatação Universal representada pela letra{' '}
                  <b className="presentationItem">
                    <i>G</i>
                  </b>
                </p>

                <p className="presentationItem">
                  <br></br>A massa do planeta é representada pela letra{' '}
                  <b className="presentationItem">
                    <i>M</i>
                  </b>{' '}
                  e a unidade padrão no Sistema Internacional é{' '}
                  <b className="presentationItem">
                    'kilogramas' (representada por <i>kg</i> ).
                  </b>
                </p>

                <p className="presentationItem">
                  <br></br>A massa do objeto é representada pela letra{' '}
                  <b className="presentationItem">
                    <i>m</i>
                  </b>{' '}
                  e a unidade padrão no Sistema Internacional é{' '}
                  <b className="presentationItem">
                    'kilogramas' (representada por <i>kg</i> ).
                  </b>
                </p>

                <p className="presentationItem">
                  <br></br>O raio do planeta é representada por{' '}
                  <b className="presentationItem">
                    <i>R</i>
                  </b>{' '}
                  e a unidade padrão no Sistema Internacional é{' '}
                  <b className="presentationItem">
                    'metros' (representada por <i>m</i> ).
                  </b>
                </p>

                <p className="presentationItem">
                  <br></br>A velocidade de escape do planeta é representada por{' '}
                  <b className="presentationItem">
                    <i>v</i>
                  </b>{' '}
                  e a unidade padrão no Sistema Internacional é{' '}
                  <b className="presentationItem">
                    'metros por segundo' (representada por <i>m/s</i> ).
                  </b>
                </p>

              </div>
            }
            trigger={<Icon name="question circle outline" />}
          />
          <div className="presentationItem arrowBtnContainer">
            <Button
              icon
              labelPosition="left"
              onClick={() => {
                setStep(step - 1);
              }}
            >
              <Icon name="left arrow" />
              Anterior
            </Button>
          </div>
        </>
      ),
    }
  ];

  useEffect(()=>{
    anime({
      targets: '#stone-earth',
      translateY: [
        {value: '-30px', duration: 700, easing: 'easeOutCubic'},
        {value: '0', duration: 1000, easing: 'easeOutBounce'}
      ],
      loop: true
    })

    anime({
      targets: '#rocket-earth',
      translateY: [
        {value: '-75px', duration: 6000, easing: 'linear'}
      ],
      loop: true
    })
  })

  return (
    <div className={`board presentationItem ${isMinimized ? 'minimizedBar' : ''}`}>
      {/* <div className="minMaxBar">
        <Icon className="icon" name='caret down' onClick={minimizeBar}/>
        <Icon className="icon" name='caret up' onClick={maximizeBar}/>
      </div> */}
      {!isMinimized && standardObj[step].comp}
    </div>
  );
}

export default EscapeVelocityPage;

/* eslint-disable react/display-name */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Segment, Image, List, Icon } from 'semantic-ui-react';
import { getImages } from './afterChallengeImages';

import api from '../../services/api';

const SIZEICONS = '10%';

function AfterChallengeComponent(props) {
  const [animationStep, setAnimationStep] = useState(0);

  var arrStars = (sessionStorage.getItem('arr_stars') || '')
    .split(',')
    .map((e) => (e === 'true' ? true : false));

  var numStars = [];
  var colorStars = [];
  var styleStars = [];
  arrStars.forEach((star) => {
    numStars.push(star ? 1 : 0);
    colorStars.push(star ? 'yellow' : 'grey');
    styleStars.push(star ? { color: 'white' } : { color: 'grey' });
  });

  var totalStars = numStars.length;
  var acquiredStars = numStars.reduce((a, b) => a + b, 0);

  var image = props.image
    ? props.image
    : getImages(props.subject + '.' + props.typeScoring, props.search);

  useEffect(() => {
    const fetchData = async () => {
      await api
        .post('updateFinished', {
          challenge_id: sessionStorage.getItem('challenge_id'),
          client_id: localStorage.getItem('client_id'),
          finished: true,
        })
        .then((res) => {
          sessionStorage.setItem('finished', true);
        })
        .catch((err) => {});
    };
    fetchData();
  }, []);

  function getButtons(animationStep) {
    return (
      <div className="presentationItem arrowBtnContainer playDialogue">
        <Button
          icon
          labelPosition="left"
          onClick={() => {
            setAnimationStep(animationStep - 1);
          }}
        >
          <Icon name="left arrow" />
          Anterior
        </Button>
        <Button
          icon
          labelPosition="right"
          onClick={() => {
            setAnimationStep(animationStep + 1);
          }}
        >
          Próximo
          <Icon name="right arrow" />
        </Button>
      </div>
    );
  }

  function goToMap() {
    props.setIsMinimized(true);
  }

  let history = useHistory();

  const arrPage = [
    <div className="board presentationItem transparentItem" key={0}>
      <div className="presentationItem arrowBtnContainer playDialogue">
        <Button
          icon
          labelPosition="right"
          onClick={() => {
            setAnimationStep(animationStep + 1);
          }}
        >
          Próximo
          <Icon name="right arrow" />
        </Button>
      </div>
      <div>
        <Segment className="speech-bubble">
          <p className="challengeParagraph">
            {props.speak[0].paragraph1}
            <br></br>
            <br></br>
            {props.speak[0].paragraph2}
          </p>
        </Segment>
        <div className="imageBoss">
          <img className="imageBoss" src={image} alt="cinematics_boss" />
        </div>
      </div>
    </div>,

    <div className="board presentationItem transparentItem" key={1}>
      {getButtons(animationStep)}
      <div>
        <Segment className="speech-bubble">
          <p className="challengeParagraph">
            {props.speak[1].paragraph1}
            <br></br>
            <br></br>
            Você conquistou {acquiredStars} das {totalStars} estrelas disputadas nesse desafio.
            <br></br>
            <br></br>
            {props.speak[1].paragraph2}
            {acquiredStars} das {totalStars} estrelas disponíveis.
          </p>
        </Segment>
        <div className="imageBoss">
          <img className="imageBoss" src={image} alt="cinematics_boss" />
        </div>
      </div>
    </div>,

    <div className="board presentationItem transparentItem" key={2}>
      <div className="presentationItem arrowBtnContainer playDialogue">
        <Button
          icon
          labelPosition="left"
          onClick={() => {
            setAnimationStep(animationStep - 1);
          }}
        >
          <Icon name="left arrow" />
          Anterior
        </Button>
        <Button
          icon
          labelPosition="right"
          onClick={() => {
            history.push(props.map);
          }}
        >
          <Icon name="map outline" />
          Mapa de fases
        </Button>
      </div>
      <div>
        <Segment className="speech-bubble">
          <p className="challengeParagraph">{props.speak[2].paragraph1}</p>
        </Segment>
        <div className="imageBoss">
          <img className="imageBoss" src={image} alt="cinematics_boss" />
        </div>
      </div>
    </div>,
  ];

  return arrPage[animationStep];
}

export default AfterChallengeComponent;

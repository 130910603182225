import React from 'react';
import TipsComponent from '../../../../components/MapComponents/tipsComponent';

import tip1 from '../../../../images/Physics/Gravitation/tip1.PNG';
import tip2 from '../../../../images/Physics/Gravitation/tip2.PNG';
import tip3 from '../../../../images/Physics/Gravitation/tip3.PNG';
import tip4 from '../../../../images/Physics/Gravitation/tip4.PNG';

function Solution(props) {
  return (
    <>
      <TipsComponent
        numberTips={4}
        presentation1="Bem vindo à Academia de Astronautas, um local onde conseguimos reparar algumas arestas em seu aprendizado e dar algumas dicas para você conseguir realizar nossa viagem."
        presentation2="Aqui temos que trabalhar duro, então algumas dicas necessitarão de um esforço seu. Vamos lá!"
        subject="physics"
        typeScoring="gravitation"
        arrayScoring="arrStarKeplersLaws"
        tips={[
          {
            number: 0,
            image: tip1,
            intro1: 'Aprendendo a utilizar as vestimentas de astronauta, você encontra uma dica',
            intro2: null,
            icon: 'angle right',
            title: 'Uso do macacão',
            dica1: true,
            dica1HasCondition: false,
            dica1ValidateCondition: null,
            dica1ConditionType: null,
            dica1MessageCondition: null,
            dica1ResponsePt1:
              'Utilize a Terceira Lei de Kepler para calcular a distância entre os dois planetas!',
            dica1ResponsePt2: null,
            dica1ResponsePt3: null,
            dica1ResponsePt4: null,
            dica1ResponseEquation1: null,
            dica1ResponsePt5: null,
            dica2: false,
          },
          {
            number: 1,
            image: tip2,
            intro1: 'Aprendendo a observar as estrelas você encontra mais uma dica.',
            intro2: null,
            icon: 'angle right',
            title: 'Técnicas de observação',
            dica1: true,
            dica1HasCondition: true,
            dica1ValidateCondition: 'arrStars[1] === true',
            dica1ConditionType: 'mission',
            dica1MessageCondition:
              'Essa dica está bloqueada para você! Para desbloqueá-la, realize a missão "Terra e Lua".',
            dica1ResponsePt1:
              'Sabemos que o planeta Quest chegará ao ponto de encontro em 40 dias, o que corresponde a 15% de seu período orbital.',
            dica1ResponsePt2: 'Assim, podemos utilizar a "regra de três":',
            dica1ResponsePt3: null,
            dica1ResponsePt4: null,
            dica1ResponseEquation1: String.raw`40 \left ( dias \right  ) = 15 \%`,
            dica1ResponseEquation2: String.raw`x \left ( dias \right  ) = 100 %`,
            dica1ResponseEquation3: String.raw`x  = 600\left ( dias \right  ) `,
            dica1ResponseEquation4: null,
            dica1ResponsePt5:
              'Assim, concluímos que o período orbital é de 600 dias para o planeta Quest.',
            dica1QuestionTittle:
              '(UNIFESP-SP) A Massa da Terra é aproximadamente 80 vezes a massa da Lua e a distância entre os centros de massa desses astros é aproximadamente 60 vezes o raio da Terra. A respeito do sistema Terra-Lua pode-se afirmar que',
            dica1QuestionImage: null,
            dica1OptionA:
              'a Lua gira em torno da Terra com órbita elíptica e em um dos focos dessa órbita está o centro de massa da Terra.',
            dica1OptionB:
              'a Lua gira em torno da Terra com órbita circular e o centro de massa da Terra está no centro dessa órbita.',
            dica1OptionC:
              'a Terra e a Lua giram em torno de um ponto comum, o centro de massa do sistema Terra-Lua, localizado no interior da Terra.',
            dica1OptionD:
              'a Terra e a Lua giram em torno de um ponto comum, o centro de massa do sistema Terra=Lua, localizado no meio da distância entre os centros  de massa da Terra e da Lua.',
            dica1OptionE:
              'a Terra e a Lua giram em torno de um ponto comum, o centro de massa do sistema Terra-Lua, localizado no interior da Lua.',
            dica1CorrectAnswer: 'c',
            dica1MissionName: 'Terra e Lua',
            dica1Subject: 'physics',
            dica1TypeScoring: 'gravitation',
            dica1ArrayScoring: 'arrStarKeplersLaws',
            dica1IndexScoring: 1,
            dica1XPAward: 30,
            dica2: false,
          },
          {
            number: 2,
            image: tip3,
            intro1:
              'Aprendendo sobre os planetas você descobres novos conceitos para as viagens interplanetárias',
            intro2: null,
            icon: 'angle right',
            title: 'Aprendendo sobre planetas',
            dica1: true,
            dica1HasCondition: true,
            dica1ValidateCondition: 'arrStars[2] === true',
            dica1ConditionType: 'mission',
            dica1MessageCondition:
              'Essa dica está bloqueada para você! Para desbloqueá-la, realize a missão "As três leis".',
            dica1ResponsePt1:
              'Como sabemos o período orbital de ambos os planetas, além da distância de nosso planeta natal ao sol (100.000.000 km), conseguimos descobrir a distância entre os dois planetas:',
            dica1ResponsePt2: null,
            dica1ResponsePt3: null,
            dica1ResponsePt4: null,
            dica1ResponseEquation1: String.raw`\frac{{T_{a}}^{2}}{{R_{a}}^{3}} = \frac{{T_{b}}^{2}}{{R_{b}}^{3}}`,
            dica1ResponseEquation2: String.raw`\frac{{200}^{2}}{{100000000}^{3}} = \frac{{600}^{2}}{{R_{b}}^{3}}`,
            dica1ResponseEquation3: String.raw`{{R_{b}}} \approx 208000000`,
            dica1ResponsePt5:
              'Assim, descobrimos que o raio da órbita do planeta Quest é de 208M km. Como o raio da órbita de nosso planeta natal é de 100M km, descobrimos que a distância entre ambos planetas é 108M km.',
            dica1QuestionTittle:
              '(UPE) Com base nas Leis de Kepler acerca do movimento planetário no Sistema Solar, assinale a alternativa correta:',
            dica1OptionA: 'Todo planeta, onde o Sol ocupa um dos focos, tem trajetória circular;',
            dica1OptionB:
              'A razão entre o quadrado do período de translação de um planeta e a distância média a um dos focos é igual para a Terra, para Marte e Vênus, apenas;',
            dica1OptionC: 'Quanto mais afastado for um planeta dos focos, menor será o seu ano',
            dica1OptionD: 'A linha que liga um planeta ao Sol varre áreas iguais em tempos iguais',
            dica1OptionE: 'A velocidade de um planeta é sempre constante na translação',
            dica1CorrectAnswer: 'd',
            dica1MissionName: 'As três leis',
            dica1Subject: 'physics',
            dica1TypeScoring: 'gravitation',
            dica1ArrayScoring: 'arrStarKeplersLaws',
            dica1IndexScoring: 2,
            dica1XPAward: 30,
            dica2: false,
          },
          {
            number: 3,
            image: tip4,
            intro1: 'Nas botas de propulsão você encontra um novo conhecimento...',
            intro2: null,
            icon: 'angle right',
            title: 'Botas de propulsão',
            dica1: true,
            dica1HasCondition: true,
            dica1ValidateCondition: 'arrStars[3] === true',
            dica1ConditionType: 'mission',
            dica1MessageCondition:
              'Essa dica está bloqueada para você! Para desbloqueá-la, realize a missão "Terra e Júpiter".',
            dica1ResponsePt1:
              'Nesse momento, temos o tempo que a nave e planeta levarão para se encontrar (40 dias), além de termos descoberto qual a distância a ser percorrida pela nave.',
            dica1ResponsePt2: 'Podemos nos lembrar do conceito de velocidade média para:',
            dica1ResponsePt3: null,
            dica1ResponsePt4: null,
            dica1ResponseEquation1: String.raw`V_{media} = \frac{\Delta S}{\Delta T} `,
            dica1ResponseEquation2: String.raw`V_{media} = \frac{108000000 \left ( km \right )}{40 \left ( dias \right )}`,
            dica1ResponseEquation3: String.raw`V_{media} = \frac{108000000 \left ( km \right )}{960 \left ( h \right )}`,
            dica1ResponseEquation4: String.raw`V_{media} = 112500\left ( km/h \right )`,
            dica1ResponsePt5:
              'Logo, nossa nave deve atingir uma velocidade média de 112,5k km/h para alcançar o planeta na data estipulada.',
            dica1QuestionTittle:
              '(UFRGS) Considere o raio médio da órbita de Júpiter em tomo do Sol igual a 5 vezes o raio médio da órbita da Terra. Segundo a 3ª Lei de Kepler, o período de revolução de Júpiter em tomo do Sol é de aproximadamente:',
            dica1OptionA: '5 anos;',
            dica1OptionB: '11 anos;',
            dica1OptionC: '25 anos;',
            dica1OptionD: '110 anos;',
            dica1OptionE: '125 anos;',
            dica1CorrectAnswer: 'b',
            dica1MissionName: 'Terra e Júpiter',
            dica1Subject: 'physics',
            dica1TypeScoring: 'gravitation',
            dica1ArrayScoring: 'arrStarKeplersLaws',
            dica1IndexScoring: 3,
            dica1XPAward: 30,
            dica2: false,
          },
        ]}
      />
    </>
  );
}

export default Solution;

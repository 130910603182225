import React, { PropsWithChildren, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Navbar from '../../components/navbar';
import './about.css';
import ScalePlot from './PlotGrid';

function Modal(props: PropsWithChildren<object>) {
  const el = document.createElement('div');
  const root = document.body as HTMLElement;
  root.appendChild(el);

  useEffect(() => {
    return () => {
      el.remove();
    };
  }, []);

  return ReactDOM.createPortal(props.children, el);
}

function About() {
  return (
    <div>
      <Navbar />
      <div className="learning-container">
        <ScalePlot plotSizeX={900} plotSizeY={600} />
      </div>
    </div>
  );
}

export default About;

/* eslint-disable react/display-name */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import PlayDialogue from '../../../Common/compPlayDialogue';
import cinematics_manager_black from '../../../../images/Physics/Kinematics/cinematics_manager_black.PNG';
import cinematics_manager_blue from '../../../../images/Physics/Kinematics/cinematics_manager_blue.PNG';
import cinematics_manager_green from '../../../../images/Physics/Kinematics/cinematics_manager_green.PNG';
import cinematics_manager_pink from '../../../../images/Physics/Kinematics/cinematics_manager_pink.PNG';
import cinematics_manager_red from '../../../../images/Physics/Kinematics/cinematics_manager_red.PNG';
import cinematics_manager_yellow from '../../../../images/Physics/Kinematics/cinematics_manager_yellow.PNG';

function ChallengeExplanation(props) {
  const [animationStep, setAnimationStep] = useState(0);
  const additionalInfo = JSON.parse(sessionStorage.getItem('additional_info'));

  function getImage() {
    switch (additionalInfo.choosed_team) {
      case 'Arara Real':
        return cinematics_manager_blue;
      case 'Jacaré Impiedoso':
        return cinematics_manager_green;
      case 'Boto Veloz':
        return cinematics_manager_pink;
      case 'Falcão Negro':
        return cinematics_manager_black;
      case 'Guará Rubro':
        return cinematics_manager_red;
      case 'Capivara Furiosa':
        return cinematics_manager_yellow;
      default:
        break;
    }
  }

  useEffect(() => {
    if (animationStep === 11) {
      props.setNextItem('Tempo');
    }
  }, [animationStep]);

  const arrPage = [
    <PlayDialogue
      key={0}
      buttons="Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text="Que grande vitória você proporcionou à nossa equipe, Parabéns!"
      image={getImage()}
      extraContent={''}
    />,

    <PlayDialogue
      key={1}
      buttons="Anterior/Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text="Nossa primeira corrida foi muito difícil, saímos por último e tivemos que lutar pela primeira posição. Queremos seguir com uma nova estratégia para a próxima, nosso desejo é já assumir a liderança logo no início."
      image={getImage()}
      extraContent={''}
    />,

    <PlayDialogue
      key={2}
      buttons="Anterior/Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text={
        'Para isso, usaremos um grande conceito: a Aceleraçãp. No início da corrida todos saímos da linha de largada com velocidade igual a zero, o nosso segredo será atingir as mais altas velocidades antes do nosso principal adversário, a equipe ' +
        additionalInfo.teams[1] +
        '.'
      }
      image={getImage()}
      extraContent={''}
    />,

    <PlayDialogue
      key={2}
      buttons="Anterior/Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text="Com o desenho do próximo circuito, sabemos que podemos alcançar até 300 km/h de velocidade máxima. Iremos partir do repouso, com o carro parado, e dadas as especificações do nosso motor, poderemos acelerar por até 15 segundos."
      image={getImage()}
      extraContent={''}
    />,

    <PlayDialogue
      key={3}
      buttons="Anterior/Próximo"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      text="O seu objetivo será descobrir qual valor de aceleração devemos utilizar para que alcancemos esse valor de velocidade, no tempo especificado."
      image={getImage()}
      extraContent={''}
    />,

    <PlayDialogue
      key={4}
      buttons="Anterior/Começar Desafio"
      set={setAnimationStep}
      step={animationStep}
      mapAcess={''}
      setChallengeStarted={props.setChallengeStarted}
      setIsChallengeOpened={props.setIsChallengeOpened}
      text="Lembre-se, uma aceleração baixa nos trará a derrota na corrida. Já uma aceleração muito alta pode forçar o motor, fazendo-o atingir uma alta velocidade muito rápido; você pode perder o controle do carro!"
      image={getImage()}
      extraContent={''}
      challenge = {props.challenge}
    />,
  ];

  return arrPage[animationStep];
}

export default ChallengeExplanation;

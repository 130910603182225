import React from 'react';
import TipsComponent from '../../../../components/MapComponents/tipsComponent';

import tip3 from '../../../../images/Physics/Kinematics/tip3.PNG';
import tip4 from '../../../../images/Physics/Kinematics/tip4.PNG';
import tip5 from '../../../../images/Physics/Kinematics/tip5.PNG';
import tip6 from '../../../../images/Physics/Kinematics/tip6.PNG';
import mruvQuest from '../../../../images/Physics/Kinematics/mruvQuest.PNG';

function Solution(props) {
  return (
    <>
      <TipsComponent
        numberTips={4}
        presentation1="Bem vindo à Oficina, um local onde conseguimos reparar algumas arestas em seu aprendizado e dar algumas dicas para você conseguir ganhar mais uma corrida!"
        presentation2="Aqui temos que trabalhar duro, então algumas dicas necessitarão de um esforço seu. Vamos lá!"
        subject="physics"
        typeScoring="kinematics"
        arrayScoring="arrStarMRUV"
        tips={[
          {
            number: 0,
            image: tip5,
            intro1: 'Observando o motor você encontra uma dica',
            intro2: null,
            icon: 'cogs',
            title: 'Motor',
            dica1: true,
            dica1HasCondition: false,
            dica1ValidateCondition: null,
            dica1ConditionType: null,
            dica1MessageCondition: null,
            dica1ResponsePt1:
              'Para solucionar a primeira parte do desafio precisamos calcular a velocidade com que o carro chegará nos pneus para iniciar o percurso de volta.',
            dica1ResponsePt2:
              'Temos a informação do tempo disponível para a desaceleração do carro, a velocidade inicial e a taxa de desaleração. Assim, para descobrir a velocidade final, podemos utilizar a mesma fórmula do desafio anterior:',
            dica1ResponsePt3: null,
            dica1ResponsePt4: null,
            dica1ResponseEquation1: String.raw`V_{Final} =  V_{Inicial} + a \cdot t `,
            dica1ResponsePt5: null,
            dica2: false,
          },
          {
            number: 1,
            image: tip6,
            intro1: 'Investigando a Bomba de Combustível você encontra mais uma dica.',
            intro2: null,
            icon: 'tint',
            title: 'Combustível',
            dica1: true,
            dica1HasCondition: true,
            dica1ValidateCondition: 'arrStars[1] === true',
            dica1ConditionType: 'mission',
            dica1MessageCondition:
              'Essa dica está bloqueada para você! Para desbloqueá-la, realize a missão "Velocidade vs Tempo".',
            dica1ResponsePt1:
              'Sabemos que a nossa velocidade atual na primeira parte do desafio é de 306 km/h. Usando os ensinamentos aprendidos no desafio anterior, convertemos essa velocidade para 85 m/s, unidade que fica mais adequada para nossos cálculos.',
            dica1ResponsePt2:
              'Também sabemos que o tempo de desaceleração deve ser de 10 segundos com uma taxa de frenagem de 8 m/s². Assim, a velocidade de chegada do carro nos pneus é dada por:',
            dica1ResponsePt3: null,
            dica1ResponsePt4: null,
            dica1ResponseEquation1: String.raw`V_{Final} =  V_{Inicial} + a \cdot t `,
            dica1ResponseEquation2: String.raw`V_{Final} =  85 m/s - 8 m/s^2 \cdot 10 s `,
            dica1ResponseEquation3: String.raw`V_{Final} =  85 m/s - 80 m/s `,
            dica1ResponseEquation4: String.raw`V_{Final} =  5 m/s `,
            dica1ResponsePt5: null,
            dica1QuestionTittle:
              '(PUC-RJ) - O movimento de um objeto pode ser descrito pelo gráfico velocidade versus tempo, apresentado na figura a seguir. Com base nela, podemos afirmar que:',
            dica1QuestionImage: mruvQuest,
            dica1OptionA:
              'a aceleração do objeto é 2,0 m/s2, e a distância percorrida em 5,0 s é 10,0 m.',
            dica1OptionB:
              'a aceleração do objeto é 4,0 m/s2, e a distância percorrida em 5,0 s é 20,0 m.',
            dica1OptionC:
              'a aceleração do objeto é 2,0 m/s2, e a distância percorrida em 5,0 s é 25,0 m.',
            dica1OptionD:
              'a aceleração do objeto é 2,0 m/s2, e a distância percorrida em 5,0 s é 10,0 m.',
            dica1OptionE:
              'a aceleração do objeto é 2,0 m/s2, e a distância percorrida em 5,0 s é 20,0 m.',
            dica1CorrectAnswer: 'c',
            dica1MissionName: 'Velocidade vs Tempo',
            dica1Subject: 'physics',
            dica1TypeScoring: 'kinematics',
            dica1ArrayScoring: 'arrStarMRUV',
            dica1IndexScoring: 1,
            dica1XPAward: 30,
            dica2: false,
          },
          {
            number: 2,
            image: tip3,
            intro1: 'Olá grande estudante, estamos aqui novamente, não é?',
            intro2: null,
            icon: 'wrench',
            title: 'Mecânico Luiz Câmera',
            dica1: true,
            dica1HasCondition: true,
            dica1ValidateCondition: 'arrStars[2] === true',
            dica1ConditionType: 'mission',
            dica1MessageCondition:
              'Essa dica está bloqueada para você! Para desbloqueá-la, realize a missão "Movimento retilíneo uniformemente variado".',
            dica1ResponsePt1:
              'Agora que já sabemos a velocidade inicial, precisamos calcular a aceleração mínima para conseguir realizar a ultrapassagem.',
            dica1ResponsePt2:
              'Já temos as informações do tempo disponível até o fim da corrida, da velocidade inicial e da distância a ser percorrida. Assim, para descobrir o valor da aceleração, podemos utilizar a seguinte fórmula:',
            dica1ResponsePt3: null,
            dica1ResponsePt4: null,
            dica1ResponseEquation1: String.raw`S_{Final} =  S_{Inicial} + V_{Inicial} \cdot t + \frac{a \cdot t^2}{2} `,
            dica1ResponsePt5: null,
            dica1QuestionTittle:
              '(FEI-SP) - No movimento retilíneo uniformemente variado, com velocidade inicial nula, a distância percorrida é:',
            dica1OptionA: 'diretamente proporcional ao tempo de percurso',
            dica1OptionB: 'inversamente proporcional ao tempo de percurso',
            dica1OptionC: 'diretamente proporcional ao quadrado do tempo de percurso',
            dica1OptionD: 'inversamente proporcional ao quadrado do tempo de percurso',
            dica1OptionE: 'diretamente proporcional à velocidade',
            dica1CorrectAnswer: 'c',
            dica1MissionName: 'Mov. retilíneo unif. variado',
            dica1Subject: 'physics',
            dica1TypeScoring: 'kinematics',
            dica1ArrayScoring: 'arrStarMRUV',
            dica1IndexScoring: 2,
            dica1XPAward: 30,
            dica2: false,
          },
          {
            number: 3,
            image: tip4,
            intro1: 'Anda treinando bastante? Irei te dar mais uma dica.',
            intro2: null,
            icon: 'clipboard',
            title: 'Eng. Thais Condida',
            dica1: true,
            dica1HasCondition: true,
            dica1ValidateCondition: 'arrStars[3] === true',
            dica1ConditionType: 'mission',
            dica1MessageCondition:
              'Essa dica está bloqueada para você! Para desbloqueá-la, realize a missão "Partindo do Repouso".',
            dica1ResponsePt1:
              'Para facilitar o cálculo, podemos considerar que nossa posição inicial é 0 e a posição final 2000 m. Além disso, a velocidade inicial, que é de 5 m/s, foi calculada na primeira parte do desafio.',
            dica1ResponsePt2:
              'Por fim, também sabemos que o tempo de aceleração deve ser de 40 segundos para alcançarmos o adversário. Assim, a mínima aceleração necessária será:',
            dica1ResponsePt3: null,
            dica1ResponsePt4: null,
            dica1ResponseEquation1: String.raw`S_{Final} =  S_{Inicial} + V_{Inicial} \cdot t + \frac{a \cdot t^2}{2} `,
            dica1ResponseEquation2: String.raw`2000 m =  0 + 5 m/s \cdot 40 s + \frac{a \cdot (40 s)^2}{2} `,
            dica1ResponseEquation3: String.raw`a =  2 \cdot \frac{2000 m - 5 m/s \cdot 40 s}{(40 s)^2} `,
            dica1ResponseEquation4: String.raw`a =  2,25 m/s^2 `,
            dica1ResponsePt5: null,
            dica1QuestionTittle:
              '(FUVEST) Um veículo parte do repouso em movimento retilíneo e acelera com aceleração escalar constante e igual a 2,0 m/s2. Pode-se dizer que sua velocidade escalar e a distância percorrida após 3,0 segundos, valem, respectivamente:',
            dica1OptionA: '6,0 m/s e 9,0m;',
            dica1OptionB: '6,0m/s e 18m;',
            dica1OptionC: '3,0 m/s e 12m;',
            dica1OptionD: '12 m/s e 35m;',
            dica1OptionE: '2,0 m/s e 12 m.',
            dica1CorrectAnswer: 'a',
            dica1MissionName: 'Partindo do Repouso',
            dica1Subject: 'physics',
            dica1TypeScoring: 'kinematics',
            dica1ArrayScoring: 'arrStarMRUV',
            dica1IndexScoring: 3,
            dica1XPAward: 30,
            dica2: false,
          },
        ]}
      />
    </>
  );
}

export default Solution;

import React from 'react';
import MissionComponent from '../../../../components/MapComponents/missionComponent';

function HistoryPage(props) {
  return (
    <>
      <MissionComponent
        numberMissions={5}
        subject="physics"
        typeScoring="gravitation"
        arrayScoring="arrStarGravityAcceleration"
        missionName="Lançando o satélite"
        missionDescription="Descrição: Estamos lançando nosso primeiro satélite. Ajude indicando sua direção e velocidade."
        missionVectorNumber={4}
        submission={[
          {
            missionName: 'Satélites',
            missionDescription: 'Descrição: Descubra no Centro de Pesquisas qual o funcionamento de satélites geoestacionários.',
            missionVectorNumber: 0,
          },
          {
            missionName: 'Velocidade de escape',
            missionDescription:
              'Descrição: No treinamento de astronautas, pesquise sobre as botas de propulsão.',
            missionVectorNumber: 1,
          },
          {
            missionName: 'Velocidade de escape de um buraco negro',
            missionDescription:
              'Descrição: No treinamento de astronautas, investigue o capacete espacial.',
            missionVectorNumber: 2,
          },
          {
            missionName: 'Variando a massa',
            missionDescription:
              'Descrição: No treinamento de astronautas, investigue a antena de comunicações.',
            missionVectorNumber: 3,
          },
        ]}
      />
    </>
  );
}

export default HistoryPage;

import React, { useEffect } from 'react';
import anime from 'animejs/lib/anime.es.js';
import track from '../../images/trackT.PNG';
import car from '../../images/carT.PNG';

function SvgsPresentation(props){
  useEffect(() => {
    let data = [];
    switch(props.id){
      case "60f341b5ef5668240c45a6eb": //Gravitação 
        data = JSON.parse('[{"targets":".mercury","easing":"linear","duration":2000,"loop":true,"path":"#orbit-svg .orbit1"},{"targets":".venus","easing":"linear","duration":3000,"loop":true,"path":"#orbit-svg .orbit2"},{"targets":".earth","easing":"linear","duration":2500,"loop":true,"path":"#orbit-svg .orbit3"}]');
        break;
      case "60f3416fef5668240c45a6e9": //Cinemática
        data = JSON.parse('[{\"targets\":\"#kinematicsCar\",\"easing\":\"linear\",\"duration\":2000,\"loop\":true,\"path\":\"#track-svg path\"}]');
        break;
      default:
        break;
    }
    data.forEach(e => {
      let trackPath = anime.path(e.path);
      if(trackPath){
        anime({
          ...e,
          translateX: trackPath('x'),
          translateY: trackPath('y'),
          rotate: trackPath('angle'),
        });
      }
    });
  });

  switch(props.id){
    case "60f341b5ef5668240c45a6eb": //Gravitação 
      return (
        <>
          <svg className="orbitSvg" style={{position: 'relative', left: '40%'}} id="orbit-svg" height="300" width="300">

            <circle className="orbit1" cx="100" cy="100" r="40" stroke="white" stroke-width="3" fill="none"/>
            <circle className="orbit2" cx="100" cy="100" r="60" stroke="white" stroke-width="3" fill="none"/>
            <circle className="orbit3" cx="100" cy="100" r="80" stroke="white" stroke-width="3" fill="none"/>

            <circle className="mercury" cx="25" cy="10" r="7" stroke="gray" stroke-width="3" fill="gray"/>
            <circle className="venus" cx="35" cy="10" r="8" stroke="orange" stroke-width="3" fill="orange"/>
            <circle className="earth" cx="45" cy="10" r="8" stroke="aquamarine" stroke-width="3" fill="aquamarine"/>
            <circle className="sun" cx="100" cy="100" r="15" stroke="yellow" stroke-width="3" fill="yellow"/>
          </svg>
        </>
      ); 
      break;
    case "60f3416fef5668240c45a6e9": //Cinemática
      const carStyle = {
        position: 'absolute',
        right: '300px',
      };
    
      const trackStyle = {
        position: 'absolute',
        right: '80px',
        top: '4px',
      };
      return (
        <>
          <img style={trackStyle} src={track} alt="track" />
          <img id="kinematicsCar" style={carStyle} width="55px" height="30px" src={car} alt="car" />
          <svg style={{ visibility: 'hidden' }} id="track-svg" height="215" width="258">
            <path
              fill="none"
              stroke="black"
              d="M 180 150 L 120 170 C 20 210 10 110 80 90 L 150 60 C 230 30 240 140 180 150 Z"
            />
          </svg>
        </>
      );
    default:
      return (<>
      </>)
      break;
  }
}

export default SvgsPresentation;
import * as BABYLON from '@babylonjs/core';

import Scenario from './Scenario';

import '@babylonjs/loaders';
import '@babylonjs/inspector';
import scoreManager from '../../../../managers/ScoreManager';

function Setup(props) {
  let scene = props.scene;

  const ACC_CAR = 8;
  const CAMERA_INIT_POSITION = new BABYLON.Vector3(120, 10, 80);

  // lista de carros
  let localCars = [
    {
      name: './scenes/Física/Cinemática/CarBlue.glb',
      color: 'blue',
      logo: 'textures/Física/Cinemática/Velocidade Média/logoBlue.PNG',
      equipe: 'Arara Real',
    },
    {
      name: './scenes/Física/Cinemática/CarGreen.glb',
      color: 'green',
      logo: 'textures/Física/Cinemática/Velocidade Média/logoGreen.PNG',
      equipe: 'Jacaré Impiedoso',
    },
    {
      name: './scenes/Física/Cinemática/CarPink.glb',
      color: 'pink',
      logo: 'textures/Física/Cinemática/Velocidade Média/logoPink.PNG',
      equipe: 'Boto Veloz',
    },
    {
      name: './scenes/Física/Cinemática/CarBlack.glb',
      color: 'black',
      logo: 'textures/Física/Cinemática/Velocidade Média/logoBlack.PNG',
      equipe: 'Falcão Negro',
    },
    {
      name: './scenes/Física/Cinemática/CarRed.glb',
      color: 'red',
      logo: 'textures/Física/Cinemática/Velocidade Média/logoRed.PNG',
      equipe: 'Guará Rubro',
    },
    {
      name: './scenes/Física/Cinemática/CarYellow.glb',
      color: 'yellow',
      logo: 'textures/Física/Cinemática/Velocidade Média/logoYellow.PNG',
      equipe: 'Capivara Furiosa',
    },
  ];

  let orderCars = scoreManager.objSpecificScore.physics.kinematics.additionalInfo.teams;
  let listCars = [];
  orderCars.forEach((car) => {
    let atualCar = localCars.filter((el) => el.equipe === car)[0];
    listCars.push(atualCar);
  });

  scene.time = 0;
  scene.cars = [];
  scene.points = [];
  scene.points2 = [];
  scene.cameraOrientation = 0;
  scene.responses = [0, 0];
  scene.running = 0;
  scene.countStart = 0;
  scene.restart = 0;

  scene.rodas = [];
  // BABYLON.DebugLayer.InspectorURL = 'https://preview.babylonjs.com/inspector/babylon.inspector.bundle.js';
  // scene.debugLayer.show({
  // });

  scene.camera = new BABYLON.FreeCamera('camera', CAMERA_INIT_POSITION, scene);
  const canvas = scene.getEngine().getRenderingCanvas();
  scene.camera.attachControl(canvas, true);

  let light = new BABYLON.HemisphericLight('light', new BABYLON.Vector3(0, 1, 0), scene);
  light.position = new BABYLON.Vector3(0, 5, 0);
  light.intensity = 0.5;

  // scene.soundCrowd = new BABYLON.Sound('crowdSound', 'sounds/crowdVoices.mp3', scene, null, {
  //   loop: true,
  //   autoplay: true,
  //   volume: 0.4,
  // });
  scene.soundRaceMusic = new BABYLON.Sound('raceMusic', 'sounds/musicStage4.mp3', scene, null, {
    loop: true,
    autoplay: false,
    volume: 0.4,
  });

  Scenario(scene);
  // var sphere = BABYLON.Mesh.CreateSphere('origin', 15, 5.0, scene);
  // sphere.position = new BABYLON.Vector3(0, 0, 2000);

  let storageCar = 0;
  let initPosition = 1;
  let carLocalFile = listCars[storageCar];
  listCars.splice(storageCar, 1);
  listCars.splice(initPosition, 0, carLocalFile);

  listCars.length = 2;
  for (let i = 0; i < listCars.length; i++) {
    BABYLON.SceneLoader.ImportMesh(
      '',
      listCars[i].name,
      '',
      scene,
      function (meshes, particleSystems, skeleton, animationGroups) {
        let car = meshes[0];
        car.active = true;
        car.dist = 0;
        car.track = i % 2;
        car.changeTrack = 0;
        car.isStudent = listCars[i].color === carLocalFile.color ? 1 : 0;
        car.vel = 0;
        car.acc = ACC_CAR - 2 * car.isStudent;
        car.color = listCars[i].color;
        car.logo = listCars[i].logo;
        car.equipe = listCars[i].equipe;

        car.position.x = -5 * i + 3;
        car.position.y = 0;
        car.position.z = 0;

        car.rotation = new BABYLON.Vector3(0, -Math.PI / 2, 0);
        car.scaling = new BABYLON.Vector3(0.7, 0.7, 0.7);

        if (car.isStudent) {
          scene.camera.position = new BABYLON.Vector3(
            car.position.x - 8 * Math.cos(car.rotation.y),
            car.position.y + 5,
            car.position.z + 10 * Math.sin(car.rotation.y)
          );
          scene.camera.setTarget(
            new BABYLON.Vector3(
              car.position.x + 2.5 * Math.cos(car.rotation.y),
              0.9,
              car.position.z - 2.5 * Math.sin(car.rotation.y)
            )
          );
          scene.cameraOrientation = car.rotation.y;
        }

        for (var j = 0; j < 3; j++) {
          let m = scene.getMeshesByID('Front tire + Wheel.002_primitive' + j);
          m.forEach((e) => {
            scene.rodas.push(e);
          });
        }
        for (var k = 0; k < 6; k++) {
          let m = scene.getMeshesByID('Rear Tire + Wheel.002_primitive' + k);
          m.forEach((e) => {
            scene.rodas.push(e);
          });
        }

        car.initDist = car.dist;
        car.initPosition = car.position;
        car.initRotation = car.rotation;
        car.initVel = car.vel;
        car.initAcc = car.acc;
        car.initTrack = car.track;
        car.explode = false;

        scene.cars.push(car);
      }
    );
  }
}

export default Setup;

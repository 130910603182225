let PointsExplosion = [[135.00410914421082,300],
[137.00596461072564,300],
[138.99793925666017,300],
[141.00983339594677,300],
[143.00667030009208,300],
[144.99709930474637,300],
[146.99782441137359,300],
[149.00075223664317,300],
[150.99943992852786,300],
[152.99653485417366,300],
[155.0018531337264,300],
[157.00208109428786,300],
[159.00012144818902,300],
[161.00152861123206,300],
[162.99860361348692,300],
[164.99996765720425,300],
[166.99989594835642,300],
[168.9930717999232,300],
[170.9907534820377,300],
[173.00504862010712,300],
[174.99943301081657,300],
[177.0027062841109,300],
[178.99482529424859,300],
[181.00517470575141,300],
[182.9972937158891,300],
[185.00056698918343,300],
[186.99495137989288,300],
[189.0092465179623,300],
[191.0069282000768,300],
[193.00010405164358,300],
[195.00003234279575,300],
[197.00139638651308,300],
[198.99847138876794,300],
[200.99987855181098,300],
[202.99791890571214,300],
[204.9981468662736,300],
[207.00346514582634,300],
[209.00056007147214,300],
[210.99924776335683,300],
[213.00217558862641,300],
[215.00290069525363,300],
[216.99332969990792,300],
[219.0076687853434,300],
[221.00206074333983,300],
[222.99403538927436,300],
[225.00549310818315,299.99999994412065],
[226.99210792779922,299.9925962090492],
[228.99105459451675,299.97005850076675],
[230.99634647369385,299.93189334869385],
[233.00748467445374,299.87760186195374],
[235.00282287597656,299.80751037597656],
[237.00333505868912,299.7206202149391],
[238.98770928382874,299.61759209632874],
[240.99720865488052,299.4957438111305],
[242.98988342285156,299.35707092285156],
[244.9860155582428,299.1998827457428],
[246.96497440338135,299.02552127838135],
[248.96661758422852,298.8298988342285],
[250.95037937164307,298.61639499664307],
[252.93582826852798,298.382605612278],
[254.92242246866226,298.12798887491226],
[256.90961480140686,297.85199761390686],
[258.8778367638588,297.5570359826088],
[260.8459201455116,297.2399631142616],
[262.83194929361343,296.89689069986343],
[264.79788690805435,296.53372675180435],
[266.76199436187744,296.14675998687744],
[268.7056294083595,295.7393208146095],
[270.6645441055298,295.3032159805298],
[272.60221391916275,294.84586626291275],
[274.55360412597656,294.35829162597656],
[276.48296266794205,293.84868532419205],
[278.40737253427505,293.31215769052505],
[280.32623291015625,292.74810791015625],
[282.23893761634827,292.15592980384827],
[284.1448751091957,291.5350118279457],
[286.0271644592285,290.8904457092285],
[287.91792064905167,290.21040111780167],
[289.7841989994049,289.5058786869049],
[291.6416358947754,288.7705421447754],
[293.4895983338356,288.0037584900856],
[295.32744795084,287.20488935709],
[297.154541015625,286.373291015625],
[298.9554539322853,285.5155125260353],
[300.76223373413086,284.6529006958008],
[302.5926085561514,283.8576205074787],
[304.44488905370235,283.10733273625374],
[306.3192307949066,282.4033856391907],
[308.21579068899155,281.74713909626007],
[310.11857509613037,281.1448383331299],
[312.0275840163231,280.5964833498001],
[313.97533267736435,280.094159245491],
[315.92951864004135,279.6476250886917],
[317.8901419043541,279.25688087940216],
[319.8572024703026,278.9219266176224],
[321.8307003378868,278.64276230335236],
[323.82716201245785,278.41776087880135],
[325.81358812749386,278.2506413757801],
[327.8064515441656,278.13931182026863],
[329.8057522624731,278.0837722122669],
[331.81149028241634,278.084022551775],
[333.80687087774277,278.1393653154373],
[335.8085819333792,278.24957206845284],
[337.7997227758169,278.4130270779133],
[339.7801351547241,278.6283588409424],
[341.7666669934988,278.89672592282295],
[343.7422611564398,279.2151565849781],
[345.7067620754242,279.582302570343],
[347.6600155234337,279.9968272447586],
[349.61907863616943,280.46170234680176],
[351.54942989349365,280.9674549102783],
[353.4680785983801,281.51664212346077],
[355.3748758882284,282.10797384381294],
[357.2696742415428,282.7401715517044],
[359.16978381574154,283.41839984059334],
[361.0401949286461,284.1289550065994],
[362.88062058389187,284.8693497478962],
[364.7259747982025,285.6524586677551],
[366.55861258506775,286.47021532058716],
[368.36070388555527,287.3129492998123],
[370.1674461364746,288.1959915161133],
[371.9432771205902,289.1008496284485],
[373.70575331151485,290.03482297062874],
[375.4726108908653,291.0067683458328],
[377.2080238908529,291.99590757489204],
[378.92968736588955,293.0107410252094],
[380.6374725699425,294.0501528978348],
[382.3312520980835,295.11303901672363],
[384.0108998864889,296.19830682873726],
[385.67629121243954,297.30487540364265],
[387.3273026943207,298.43167543411255],
[388.96381229162216,299.5776492357254],
[390.5856993049383,300.7417507469654],
[392.192844375968,301.9229455292225],
[393.8034512847662,303.1341429054737],
[395.3807982057333,304.3468005955219],
[396.9614513963461,305.58811262249947],
[398.5177553258836,306.8356828764081],
[400.0587769597769,308.0956633388996],
[401.60291543230414,309.3826564028859],
[403.13162229955196,310.680765658617],
[404.63549464941025,311.9809275865555],
[406.1422999948263,313.3065088093281],
[407.63338170945644,314.6406753361225],
[409.10863250494003,315.9824973344803],
[410.58667324483395,317.34849885106087],
[412.0393609069288,318.71205884963274],
[413.4853354655206,320.08986700326204],
[414.92454528808594,321.4814758300781],
[416.3569390773773,322.88644075393677],
[417.7730169147253,324.29485753178596],
[419.17267821729183,325.7058544456959],
[420.5747874826193,327.13806703686714],
[421.9698462449014,328.58160365372896],
[423.34829054772854,330.02600505948067],
[424.7195538133383,331.4805991947651],
[426.0835877619684,332.9449676349759],
[427.4403444491327,334.41869486123323],
[428.7897762656212,335.9013682603836],
[430.1222420297563,337.38185930997133],
[431.4568670839071,338.88106420636177],
[432.7744018100202,340.3770096972585],
[434.09403413534164,341.89113438129425],
[435.3964536450803,343.40093810111284],
[436.6912398301065,344.9170147255063],
[437.97834776341915,346.43897488713264],
[439.2674325965345,347.97806818038225],
[440.54877972230315,349.52252972871065],
[441.81261574849486,351.06008518487215],
[443.0783404968679,352.6140095666051],
[444.3361958861351,354.1721612215042]];

export default PointsExplosion;
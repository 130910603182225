import React, { useState } from 'react';
import { Header, Button, Modal, List } from 'semantic-ui-react';
import '../../pages/Common/presentationPage.css';

function RememberVariablesComponent(props) {
  const [openModal1, setOpenModal1] = useState(false);
  const modalStyle = {
    borderRadius: 0,
    opacity: 0.9,
    color: 'white',
    backgroundColor: 'black',
  };

  return (
    <Modal
      closeIcon
      open={openModal1}
      onClose={() => setOpenModal1(false)}
      onOpen={() => setOpenModal1(true)}
      trigger={<Button color="teal">Lembrar variáveis</Button>}
    >
      <Header icon="subscript" content="Lembrando..." style={modalStyle} />
      <Modal.Content image scrolling style={modalStyle}>
        {
          <div className="">
            {props.paragraph1 && <p className="presentationItem">{props.paragraph1}</p>}
            {props.paragraph2 && <p className="presentationItem">{props.paragraph2}</p>}
            {props.paragraph3 && <p className="presentationItem">{props.paragraph3}</p>}
            {props.sub && (
              <p className="presentationItem">
                {props.sub[0].paragraph}
                <br></br>
                <br></br>
                {props.sub[1].paragraph}
                <br></br>
                <br></br>
                {props.sub[2].paragraph}
                <br></br>
                <br></br>
                {props.sub[3].paragraph}
                <br></br>
                <br></br>
              </p>
            )}
          </div>
        }
      </Modal.Content>
    </Modal>
  );
}

export default RememberVariablesComponent;
